import { OPTION_DROPDOWN_TYPES } from '../Menus/OptionDropdown/types';

export const FILTERS_ID = 'FILTERS';

export enum PETS_FILTER_TYPES {
  DAYCARE = 'DAYCARE',
  BREED = 'BREED',
  AGE = 'AGE',
  BIRTHDATE = 'BIRTHDATE',
  NEUTERING = 'NEUTERING',
  VACCINATION = 'VACCINATION',
  TREATMENT = 'TREATMENT',
  WEIGHT = 'WEIGHT',
  SEARCH = 'SEARCH',
  TAGS = 'TAGS',
  QUICK_TAGS = 'QUICK_TAGS'
}

export enum BOOKINGS_FILTER_TYPES {
  BOOKING_TAGS = 'BOOKING_TAGS',
  BOOKING_QUICK_TAGS = 'BOOKING_QUICK_TAGS',
  BOOKING_PETS_TAGS = 'BOOKING_PETS_TAGS',
  BOOKING_BUS_USERS = 'BOOKING_BUS_USERS',
  BOOKING_PRODUCT_NAME = 'BOOKING_PRODUCT_NAME',
  BOOKING_SEARCH = 'BOOKING_SEARCH',
  BOOKING_WEEKDAY = 'BOOKING_WEEKDAY'
}

export enum PRODUCTS_FILTER_TYPES {
  SEARCH = 'SEARCH'
}

export enum SUBSCRIPTIONS_FILTER_TYPES {
  SUBSCRIPTION_SEARCH = 'SUBSCRIPTION_SEARCH',
  SUBSCRIPTION_PRODUCT_NAME = 'SUBSCRIPTION_PRODUCT_NAME',
  SUBSCRIPTION_TRIAL_END = 'SUBSCRIPTION_TRIAL_END'
}

export enum CHATS_FILTER_TYPES {
  CHAT_SEARCH = 'CHAT_SEARCH',
  CHAT_TAGS = 'CHAT_TAGS',
  CHAT_QUICK_TAGS = 'CHAT_QUICK_TAGS'
}

export const HIDDEN_PETS_FILTERS = {
  [PETS_FILTER_TYPES.DAYCARE]: false,
  [PETS_FILTER_TYPES.BREED]: false,
  [PETS_FILTER_TYPES.AGE]: false,
  [PETS_FILTER_TYPES.NEUTERING]: false,
  [PETS_FILTER_TYPES.VACCINATION]: false,
  [PETS_FILTER_TYPES.TREATMENT]: false,
  [PETS_FILTER_TYPES.WEIGHT]: false,
  [PETS_FILTER_TYPES.SEARCH]: true,
  [PETS_FILTER_TYPES.TAGS]: false,
  [PETS_FILTER_TYPES.BIRTHDATE]: false,
  [PETS_FILTER_TYPES.QUICK_TAGS]: true
};

export const HIDDEN_BOOKINGS_FILTERS = {
  [BOOKINGS_FILTER_TYPES.BOOKING_TAGS]: false,
  [BOOKINGS_FILTER_TYPES.BOOKING_QUICK_TAGS]: true,
  [BOOKINGS_FILTER_TYPES.BOOKING_PETS_TAGS]: false,
  [BOOKINGS_FILTER_TYPES.BOOKING_BUS_USERS]: false,
  [BOOKINGS_FILTER_TYPES.BOOKING_PRODUCT_NAME]: false,
  [BOOKINGS_FILTER_TYPES.BOOKING_SEARCH]: true,
  [BOOKINGS_FILTER_TYPES.BOOKING_WEEKDAY]: false
};

export const HIDDEN_PRODUCTS_FILTERS = {
  [PRODUCTS_FILTER_TYPES.SEARCH]: true
};

export const HIDDEN_SUBSCRIPTIONS_FILTERS = {
  [SUBSCRIPTIONS_FILTER_TYPES.SUBSCRIPTION_SEARCH]: true,
  [SUBSCRIPTIONS_FILTER_TYPES.SUBSCRIPTION_PRODUCT_NAME]: false,
  [SUBSCRIPTIONS_FILTER_TYPES.SUBSCRIPTION_TRIAL_END]: false
};

export const HIDDEN_CHATS_FILTERS = {
  [CHATS_FILTER_TYPES.CHAT_SEARCH]: true,
  [CHATS_FILTER_TYPES.CHAT_TAGS]: false,
  [CHATS_FILTER_TYPES.CHAT_QUICK_TAGS]: true
};

export const PETS_FILTERS_QUERIES = {
  [PETS_FILTER_TYPES.DAYCARE]: ['branchPetRecordItem_BranchId', 'branchPetRecordItem_days', 'branchPetRecordItem_type'],
  [PETS_FILTER_TYPES.BREED]: ['pet_BreedId'],
  [PETS_FILTER_TYPES.AGE]: ['pet_birthdate_from', 'pet_birthdate_to'],
  [PETS_FILTER_TYPES.NEUTERING]: ['petRecord_neutred'],
  [PETS_FILTER_TYPES.VACCINATION]: ['vaccRecordType_name', 'vaccRecord_nextdate_from', 'vaccRecord_nextdate_to'],
  [PETS_FILTER_TYPES.TREATMENT]: ['treatment_productKind_name', 'treatment_product_name', 'treatment_nextdate_from', 'treatment_nextdate_to'],
  [PETS_FILTER_TYPES.WEIGHT]: ['measurements_weight_from', 'measurements_weight_to'],
  [PETS_FILTER_TYPES.SEARCH]: ['pet_name'],
  [PETS_FILTER_TYPES.TAGS]: ['branchPetRecordTag_name'],
  [PETS_FILTER_TYPES.QUICK_TAGS]: ['branchPetRecordTag_name'],
  [PETS_FILTER_TYPES.BIRTHDATE]: ['pet_birthdate_day_from', 'pet_birthdate_day_to', 'pet_birthdate_month_from', 'pet_birthdate_month_to']
};

export const BOOKINGS_FILTERS_QUERIES = {
  [BOOKINGS_FILTER_TYPES.BOOKING_TAGS]: ['branchAppointmentTag_name'],
  [BOOKINGS_FILTER_TYPES.BOOKING_QUICK_TAGS]: ['branchAppointmentTag_name'],
  [BOOKINGS_FILTER_TYPES.BOOKING_PETS_TAGS]: ['appointment_branchPetRecordTag_name'],
  [BOOKINGS_FILTER_TYPES.BOOKING_BUS_USERS]: ['appointment_busUserAssigned_name'],
  [BOOKINGS_FILTER_TYPES.BOOKING_PRODUCT_NAME]: ['appointment_product_name'],
  [BOOKINGS_FILTER_TYPES.BOOKING_SEARCH]: ['appointment_search'],
  [BOOKINGS_FILTER_TYPES.BOOKING_WEEKDAY]: ['appointment_timestamp_day_of_week']
};

export const PRODUCTS_FILTERS_QUERIES = {
  [PRODUCTS_FILTER_TYPES.SEARCH]: ['name']
};

export const SUBSCRIPTIONS_FILTERS_QUERIES = {
  [SUBSCRIPTIONS_FILTER_TYPES.SUBSCRIPTION_SEARCH]: ['subscription_search'],
  [SUBSCRIPTIONS_FILTER_TYPES.SUBSCRIPTION_PRODUCT_NAME]: ['subscription_product_name'],
  [SUBSCRIPTIONS_FILTER_TYPES.SUBSCRIPTION_TRIAL_END]: ['subscription_trial_end_from', 'subscription_trial_end_to', 'subscription_trial_ended']
};

export const CHATS_FILTERS_QUERIES = {
  [CHATS_FILTER_TYPES.CHAT_SEARCH]: ['chat_search'],
  [CHATS_FILTER_TYPES.CHAT_TAGS]: ['chat_branchAppUserTag_name'],
  [CHATS_FILTER_TYPES.CHAT_QUICK_TAGS]: ['chat_branchAppUserTag_name']
};

export enum PETS_MORE_OPTIONS_TYPES {
  DUE = 'DUE',
  DATE = 'DATE',
  VACC_TYPE = 'VACC_TYPE',
  DAY_CARE = 'DAY_CARE',
  DAY = 'DAY',
  BREED = 'BREED',
  NEUTERED = 'NEUTERED',
  FROM_NUMBER = 'FROM_NUMBER',
  TO_NUMBER = 'TO_NUMBER',
  TREATMENT_NAME = 'TREATMENT_NAME',
  TREATMENT_TYPE = 'TREATMENT_TYPE',
  SEARCH = 'SEARCH',
  TAGS = 'TAGS',
  BIRTHDATE = 'BIRTHDATE'
}

export enum BOOKINGS_MORE_OPTIONS_TYPES {
  BOOKING_TAGS = 'BOOKING_TAGS',
  BOOKING_PETS_TAGS = 'BOOKING_PETS_TAGS',
  BOOKING_BUS_USERS = 'BOOKING_BUS_USERS',
  BOOKING_PRODUCT_NAME = 'BOOKING_PRODUCT_NAME',
  BOOKING_SEARCH = 'BOOKING_SEARCH',
  BOOKING_WEEKDAY = 'BOOKING_WEEKDAY'
}

export enum PRODUCTS_MORE_OPTIONS_TYPES {
  SEARCH = 'SEARCH'
}

export enum SUBSCRIPTIONS_MORE_OPTIONS_TYPES {
  SUBSCRIPTION_SEARCH = 'SUBSCRIPTION_SEARCH',
  SUBSCRIPTION_PRODUCT_NAME = 'SUBSCRIPTION_PRODUCT_NAME',
  SUBSCRIPTION_TRIAL_ACTIVE = 'SUBSCRIPTION_TRIAL_ACTIVE',
  SUBSCRIPTION_TRIAL_END_FROM = 'SUBSCRIPTION_TRIAL_END_FROM',
  SUBSCRIPTION_TRIAL_END_TO = 'SUBSCRIPTION_TRIAL_END_TO'
}

export enum CHATS_MORE_OPTIONS_TYPES {
  CHAT_SEARCH = 'CHAT_SEARCH',
  CHAT_TAGS = 'CHAT_TAGS'
}

type MoreOption = {
  values:
    | string
    | {
        name: string;
      }[]
    | {
        name: string;
      };
  type: PETS_MORE_OPTIONS_TYPES | BOOKINGS_MORE_OPTIONS_TYPES | PRODUCTS_MORE_OPTIONS_TYPES | SUBSCRIPTIONS_MORE_OPTIONS_TYPES | CHATS_MORE_OPTIONS_TYPES;
  filterType: PETS_FILTER_TYPES | BOOKINGS_FILTER_TYPES | PRODUCTS_FILTER_TYPES | SUBSCRIPTIONS_FILTER_TYPES | CHATS_FILTER_TYPES;
  id: string;
  title: string;
  optionType: OPTION_DROPDOWN_TYPES;
  items: any[];
};

export type SelectedPetsFilter = {
  moreOptions: MoreOption[];
  name: string;
  value: PETS_FILTER_TYPES;
  requisite: string;
};

export type SelectedBookingsFilter = {
  moreOptions: MoreOption[];
  name: string;
  value: BOOKINGS_FILTER_TYPES;
  requisite: string;
};

export type SelectedProductsFilter = {
  moreOptions: MoreOption[];
  name: string;
  value: PRODUCTS_FILTER_TYPES;
  requisite: string;
};

export type SelectedSubscriptionsFilter = {
  moreOptions: MoreOption[];
  name: string;
  value: SUBSCRIPTIONS_FILTER_TYPES;
  requisite: string;
};

export type SelectedChatsFilter = {
  moreOptions: MoreOption[];
  name: string;
  value: CHATS_FILTER_TYPES;
  requisite: string;
};

export const FILTER_TYPES = {
  ...PETS_FILTER_TYPES,
  ...BOOKINGS_FILTER_TYPES,
  ...PRODUCTS_FILTER_TYPES,
  ...SUBSCRIPTIONS_FILTER_TYPES,
  ...CHATS_FILTER_TYPES
};
export const MORE_OPTIONS_TYPES = {
  ...PETS_MORE_OPTIONS_TYPES,
  ...BOOKINGS_FILTER_TYPES,
  ...PRODUCTS_MORE_OPTIONS_TYPES,
  ...SUBSCRIPTIONS_MORE_OPTIONS_TYPES,
  ...CHATS_MORE_OPTIONS_TYPES
};
export const HIDDEN_FILTERS = {
  ...HIDDEN_PETS_FILTERS,
  ...HIDDEN_BOOKINGS_FILTERS,
  ...HIDDEN_PRODUCTS_FILTERS,
  ...HIDDEN_SUBSCRIPTIONS_FILTERS,
  ...HIDDEN_CHATS_FILTERS
};

export type SelectedFilter = SelectedPetsFilter | SelectedBookingsFilter | SelectedProductsFilter | SelectedSubscriptionsFilter | SelectedChatsFilter;
