import React, { forwardRef, useId, useImperativeHandle, useRef } from 'react';
import useCheckBranchAvailability from '../../hooks/useCheckBranchAvailability';
import useMediaQuery from '../../hooks/useMediaQuery';
import { logout } from '../../utils/auth';
import { formatDateDay, getWeekDays, isToday, toDay, toThreeLetterWeekDay } from '../../utils/dates';
import { isUserSuperVisorOrAdminOrSuper } from '../../utils/helpers';
import { Booking } from '../../views/Bookings/types';
import SlotAvailability from '../BookingsDrawer/SlotAvailability';
import { BusUserProfile } from '../Profile/types';
import OptionDropdown from '../Shared/Menus/OptionDropdown/OptionDropdown';
import { OPTION_DROPDOWN_MENU_BUTTON_TYPES, OPTION_DROPDOWN_TYPES } from '../Shared/Menus/OptionDropdown/types';
import Slider from '../Shared/Slider/Slider';
import { CenteredLoader } from '../Shared/Spinner';
import CalendarNavigation from './CalendarNavigation';
import CalendarRange, { CalendarRangeRef, getRange } from './CalendarRange';
import {
  CalendarHeaderContainer,
  CalendarHeaderOptions,
  CalendarNavigationContainer,
  CalendarNavigationControlsContainer,
  CalendarNavigationWrapper,
  CollapseCalendarContainer,
  DayColumn,
  DayDiv,
  HeaderDaysContainer,
  MonthLabel,
  TagCategoryDayDiv,
  WeekDay,
  WeekDayTitle
} from './styled';
import { CALENDAR_VIEWS, CALENDAR_VIEWS_NAMES, CALENDAR_VIEWS_TO_SHOW, CALENDAR_VIEW_LENGTH } from './types';
import { CalendarNavigation as CalendarNavigationType } from './useCalendarNavigation';

const CalendarHeader = forwardRef(
  (
    {
      calendarNavigation,
      selectedBookingTypeState,
      profile,
      BusUsers,
      currentCalendarLengthState,
      defaultCalendarLength
    }: {
      calendarNavigation: CalendarNavigationType;
      selectedBookingTypeState: [CALENDAR_VIEWS, (value: CALENDAR_VIEWS) => void];
      profile: BusUserProfile;
      BusUsers: Booking['BusUsers'];
      currentCalendarLengthState: [number, React.Dispatch<React.SetStateAction<number>>];
      defaultCalendarLength: number;
    },
    ref
  ) => {
    const bookingTypeId = useId();
    const [firstDayOfView, _nextView, _prevView, resetView, setUTCDate] = calendarNavigation;
    const [selectedBookingType, setSelectedBookingType] = selectedBookingTypeState;
    const [currentCalendarLength, setCurrentCalendarLength] = currentCalendarLengthState;
    const multiDayBookingType = selectedBookingType === CALENDAR_VIEWS.MULTI_DAY;
    const multiStaffBookingType = selectedBookingType === CALENDAR_VIEWS.MULTI_STAFF;
    const sliderRef = useRef<{ setSliderValue: (value: number) => void }>(null);
    const calendarRangeRef = useRef<CalendarRangeRef>(null);
    const { mobile } = useMediaQuery({ mobile: true });

    const bookingTypeOptions = {
      id: bookingTypeId,
      optionType: OPTION_DROPDOWN_TYPES.BUTTONS,
      items: mobile
        ? [
            ...CALENDAR_VIEWS_TO_SHOW.map(view => ({
              name: CALENDAR_VIEWS_NAMES[view],
              value: CALENDAR_VIEWS_NAMES[view],
              onClick: () => {
                setSelectedBookingType(view);
                setCurrentCalendarLength(mobile ? 1 : CALENDAR_VIEW_LENGTH[view]);
                calendarRangeRef.current?.clearRange();
                if (view === CALENDAR_VIEWS.MULTI_STAFF) {
                  resetView({ goToToday: true });
                  return;
                }
                resetView({ goToToday: true });
              },
              green: view === selectedBookingType,
              disabled: [CALENDAR_VIEWS.MULTI_STAFF, CALENDAR_VIEWS.ALL].includes(view) && (!isUserSuperVisorOrAdminOrSuper(profile) || !BusUsers?.length)
            })),
            {
              name: 'Log Out',
              value: 'logout',
              onClick: logout,
              danger: true
            }
          ]
        : [
            ...CALENDAR_VIEWS_TO_SHOW.map(view => ({
              name: CALENDAR_VIEWS_NAMES[view],
              value: CALENDAR_VIEWS_NAMES[view],
              onClick: () => {
                setSelectedBookingType(view);
                setCurrentCalendarLength(mobile ? 1 : CALENDAR_VIEW_LENGTH[view]);
                calendarRangeRef.current?.clearRange();
                if (view === CALENDAR_VIEWS.MULTI_STAFF) {
                  resetView({ goToToday: true });
                  return;
                }
                resetView({ goToToday: true });
              },
              green: view === selectedBookingType,
              disabled: [CALENDAR_VIEWS.MULTI_STAFF, CALENDAR_VIEWS.ALL].includes(view) && (!isUserSuperVisorOrAdminOrSuper(profile) || !BusUsers?.length)
            }))
          ]
    };

    const weekDays = getWeekDays(currentCalendarLength, firstDayOfView);

    const setCalendarLength = (length: number) => {
      setCurrentCalendarLength(length);
    };

    const firstDateUTC = new Date(Date.UTC(firstDayOfView.getFullYear(), firstDayOfView.getMonth(), firstDayOfView.getDate()));
    const {
      refetch: refetchAvailability,
      loading: loadingAvailability,
      firstSlotOfEachBookingDate,
      bookingDates
    } = useCheckBranchAvailability({
      branch: profile?.Branch,
      start_date: firstDateUTC.toISOString(),
      slots_length: currentCalendarLength,
      skip: multiStaffBookingType || new Date().setUTCHours(0, 0, 0, 0) > firstDateUTC.getTime()
    });

    useImperativeHandle(ref, () => ({
      refetchAvailability
    }));

    const slotWithMaxAvailability = firstSlotOfEachBookingDate?.map(slot => (slot?.hasMaxSlotsPerDay ? slot : null));

    const loading = loadingAvailability;

    const firstDayUTC = new Date(Date.UTC(firstDayOfView.getFullYear(), firstDayOfView.getMonth(), firstDayOfView.getDate()));

    return (
      <CalendarHeaderContainer>
        <CalendarHeaderOptions>
          <CalendarNavigationControlsContainer>
            <CollapseCalendarContainer>
              <CalendarNavigation
                date={firstDayOfView}
                step={currentCalendarLength}
                key={firstDayOfView.toISOString()}
                setUTCDate={date => {
                  setUTCDate(date);
                  calendarRangeRef.current?.setRange(getRange(date, currentCalendarLength));
                }}
              >
                {date => (
                  <CalendarNavigationWrapper onClick={() => calendarRangeRef.current?.toggleRangePicker()}>
                    <MonthLabel>{formatDateDay(date, { alwaysIncludeMonth: true })}</MonthLabel>
                  </CalendarNavigationWrapper>
                )}
              </CalendarNavigation>
            </CollapseCalendarContainer>
            {!multiStaffBookingType && !mobile && (
              <Slider ref={sliderRef} defaultLength={currentCalendarLength} length={!mobile ? defaultCalendarLength * 2 : 3} onScrollEnd={length => setCalendarLength(length)} />
            )}
          </CalendarNavigationControlsContainer>
          <CalendarNavigationContainer>
            {loading && <CenteredLoader size={30} />}
            <OptionDropdown menuButtonType={OPTION_DROPDOWN_MENU_BUTTON_TYPES.MENU} options={[bookingTypeOptions]} noApplyButton />
          </CalendarNavigationContainer>
          <CalendarRange
            currentCalendarLength={currentCalendarLength}
            setUTCDate={setUTCDate}
            ref={calendarRangeRef}
            useDatePicker={multiStaffBookingType}
            defaultValues={getRange(firstDayUTC, currentCalendarLength)}
          />
        </CalendarHeaderOptions>
        {!mobile && (
          <HeaderDaysContainer>
            {!multiDayBookingType && !multiStaffBookingType && (
              <DayColumn maxWidth={120} minWidth={120}>
                <DayDiv showOverFlow padding="16px 0 0 0" height={116}>
                  <TagCategoryDayDiv>Time</TagCategoryDayDiv>
                  {/* <TagCategorySelector /> */}
                </DayDiv>
              </DayColumn>
            )}
            {!multiStaffBookingType &&
              weekDays.map((weekDay, index) => {
                const isDayToday = isToday(weekDay);
                const inThePast = new Date(weekDay).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0);
                return (
                  <DayColumn key={index}>
                    <DayDiv isToday={isDayToday}>
                      {mobile && (
                        <WeekDayTitle>
                          {toThreeLetterWeekDay(weekDay).toUpperCase()}
                          <WeekDay>{toDay(weekDay)}</WeekDay>
                        </WeekDayTitle>
                      )}
                      {!mobile && (
                        <>
                          <WeekDayTitle>{toThreeLetterWeekDay(weekDay).toUpperCase()}</WeekDayTitle>
                          <WeekDay>{toDay(weekDay)}</WeekDay>
                        </>
                      )}
                      {!inThePast && (
                        <SlotAvailability
                          index={index}
                          selectedSlotForEachDate={slotWithMaxAvailability}
                          options={{ includeMainSlot: false, emptySlotText: 'Availability', hover: true }}
                          style={{
                            background: '#fff',
                            borderTop: '2px #e6e6e6 solid',
                            borderRadius: '0',
                            textAlign: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer'
                          }}
                          bookingDates={bookingDates}
                        />
                      )}
                    </DayDiv>
                  </DayColumn>
                );
              })}
          </HeaderDaysContainer>
        )}
      </CalendarHeaderContainer>
    );
  }
);

export default CalendarHeader;
