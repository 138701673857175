import { useMutation } from '@apollo/client';
import React, { FC, useEffect } from 'react';
import { UseFormMethods, useForm } from 'react-hook-form';
import { EditBranchOrderItems } from '../../queries';
import { toReadableDate } from '../../utils/dates';
import { Booking, BookingOrder } from '../../views/Bookings/types';
import ModalDialog from '../Modal/ModalDialog';
import { ModalBody, ModalFooter } from '../Modal/styled';
import DurationSelector, { convertDurationToMinutes } from '../Shared/DurationSelector';
import { FormButtonsContainer, FormLabel, FormSubmitButton, InputsWrapper, SectionSpan, WideInputGroup } from '../Shared/Forms/Forms';
import { Container } from '../Shared/Shared';

interface BoookingDurationModalProps {
  appointments: BookingOrder;
  formOptions: UseFormMethods;
  loadingAdd: boolean;
  onSubmit: () => void;
}

const BoookingDurationModal: FC<BoookingDurationModalProps> = ({ appointments, formOptions, loadingAdd, onSubmit }) => {
  const petsIds = [...new Set(appointments.map(appointment => appointment.PetRecord.Pet.id))];
  const pets = petsIds.map(petId => appointments.find(appointment => appointment.PetRecord.Pet.id === petId)?.PetRecord.Pet.name);
  const appUsersIds = [...new Set(appointments.map(appointment => appointment.OrderItem.Order.AppUser.id))];
  const appUsersLength = appUsersIds.length;
  const appUsers = appUsersIds.map(appUserId => appointments.find(appointment => appointment.OrderItem.Order.AppUser.id === appUserId)?.OrderItem.Order.AppUser.name);

  const appUsersNames = appUsersLength > 1 ? `${appUsersLength} Clients` : appUsers.join(' & ');

  const petsNames = appUsersLength > 1 ? `${petsIds.length} Pets` : pets.join(' & ');

  return (
    <>
      <ModalBody>
        <Container>
          <FormLabel>Service</FormLabel>
          <SectionSpan>{appointments[0].OrderItem.item.name}</SectionSpan>
          <FormLabel>Booking Slot</FormLabel>
          <SectionSpan>{toReadableDate(appointments[0].timestamp)}</SectionSpan>
          <FormLabel>Booked by</FormLabel>
          <SectionSpan>{appUsersNames}</SectionSpan>
          <FormLabel>Booked for</FormLabel>
          <SectionSpan>{petsNames}</SectionSpan>
          <InputsWrapper noWrap>
            <WideInputGroup>
              <FormLabel>Duration</FormLabel>
              <DurationSelector
                formOptions={formOptions}
                defaultDuration={appointments[0]?.OrderItem?.item?.duration || 0}
                options={{
                  title: 'New duration'
                }}
              />
            </WideInputGroup>
          </InputsWrapper>
        </Container>
      </ModalBody>
      <ModalFooter>
        <FormButtonsContainer>
          <FormSubmitButton loading={loadingAdd} onClick={onSubmit}>
            Save
          </FormSubmitButton>
        </FormButtonsContainer>
      </ModalFooter>
    </>
  );
};

export const BookingDurationModal = ({ appointments }: { appointments: BookingOrder }) => {
  const formOptions = useForm();
  const { handleSubmit } = formOptions;

  const [updateOrderItems, { data: updatedItems, loading: loadingAdd }] = useMutation<{ editBranchOrderItems: Booking['OrderItem'][] }>(EditBranchOrderItems, {
    refetchQueries: ['getBranchCalendarAppointmentsViews'],
    awaitRefetchQueries: true
  });

  const onSubmit = handleSubmit(({ duration }) => {
    if (!duration) {
      return;
    }

    updateOrderItems({
      variables: {
        id: appointments.map(appointment => appointment.OrderItem.id),
        item_duration: convertDurationToMinutes(duration)
      }
    });
  });

  useEffect(() => {
    if (updatedItems?.editBranchOrderItems?.length) {
      ModalDialog.closeModal();
    }
  }, [updatedItems]);

  return <BoookingDurationModal appointments={appointments} formOptions={formOptions} loadingAdd={loadingAdd} onSubmit={onSubmit} />;
};

export default BookingDurationModal;
