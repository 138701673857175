import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import React, { useCallback, useEffect, useRef } from 'react';
import AppUserTagsModal from '../components/Chat/AppUserTagsModal';
import { checkAddAppUserTagCapacity } from '../components/Chat/utils';
import ModalDialog from '../components/Modal/ModalDialog';
import { OptionDropDownItem } from '../components/Shared/Menus/OptionDropdown/OptionDropdown';
import PetDetailsModal from '../components/UserDrawer/PetDetailsModal';
import { AddBranchAppUserItemTag, GetBranchAppUserTags, GetBusUserProfile } from '../queries';
import { RequireAtLeastOne, isUserSuperVisorOrAdminOrSuper } from '../utils/helpers';
import { BranchAppUser } from '../views/Store/BranchBilling/types';
import { BranchAppUserTag } from '../views/Store/BranchTags/types';

type useAppUserOptionsProps = RequireAtLeastOne<
  {
    appUsers: BranchAppUser[];
    quickTags?: BranchAppUserTag[];
    loadTags?: boolean;
    afterTag?: (newAppUsers: BranchAppUser[]) => void;
  },
  'quickTags' | 'loadTags'
>;

export default function useAppUserOptions({ quickTags, loadTags = false, afterTag, appUsers }: useAppUserOptionsProps) {
  const { data: { getBusUserProfile: currentBusUserProfile = {} } = {} } = useQuery(GetBusUserProfile, {
    fetchPolicy: 'cache-only'
  });
  const isAdminOrSuperAdmin = isUserSuperVisorOrAdminOrSuper(currentBusUserProfile);
  const uniqueAppUserIds = [...new Set(appUsers?.map(appUser => appUser?.id))].filter(Boolean);
  const allAppUserTags = appUsers?.flatMap(appUser => appUser?.BranchAppUserTags || []) || [];
  const allAppUsersUniqueTags = [...new Set(allAppUserTags.map(item => item?.id))].map(id => allAppUserTags?.find(item => item?.id === id)!);
  const existingTagsIds = allAppUsersUniqueTags?.map(tag => tag?.id);

  const [addTag, { data: addedTag, loading: loadingAddAppUserTag }] = useMutation(AddBranchAppUserItemTag);
  const [refetchTagsById, { data: refetchedTags, loading: loadingRefetchTags }] = useLazyQuery<{ getBranchAppUserTags: BranchAppUserTag[] }>(GetBranchAppUserTags, {
    fetchPolicy: 'cache-and-network'
  });
  const [getTags, { data: { getBranchAppUserTags: tags = [] } = {} }] = useLazyQuery<{ getBranchAppUserTags: BranchAppUserTag[] }>(GetBranchAppUserTags, {
    fetchPolicy: 'cache-and-network',
    variables: { offset: 0, limit: 1000, quick_action: true }
  });

  useEffect(() => {
    if (loadTags && !quickTags?.length) {
      getTags();
    }
  }, [loadTags, getTags]);

  const currentTagId = useRef<string>('');

  const handleQuickTagClick = useCallback(
    (tag: BranchAppUserTag) => {
      checkAddAppUserTagCapacity({
        handleAddTag: () => {
          addTag({ variables: { AppUserId: uniqueAppUserIds, BranchAppUserTagId: [tag.id] } });
          currentTagId.current = tag.id;
        },
        appUsers,
        tags: [tag]
      });
    },
    [addTag, uniqueAppUserIds]
  );

  useEffect(() => {
    if (addedTag) {
      refetchTagsById({ variables: { id: [currentTagId.current] } });
    }
  }, [addedTag, refetchTagsById]);

  const onTag = async () => {
    // const { getBranchAppUsers = [] } =
    //   client.readQuery<{ getBranchAppUsers: BranchAppUser[] }>({
    //     query: GetBranchAppUsers
    //   }) || {};
    // const { data: { getBranchAppUsers: newAppUsers = [] } = {} } = await client.query<{ getBranchAppUsers: BranchAppUser[] }>({
    //   query: GetBranchAppUsers,
    //   variables: { id: uniqueAppUserIds, offset: 0, limit: 1000 },
    //   fetchPolicy: 'no-cache'
    // });
    // client.writeQuery({
    //   query: GetBranchAppUsers,
    //   data: {
    //     getBranchAppUsers: getBranchAppUsers.map(appUser => {
    //       if (uniqueAppUserIds.includes(appUser?.id)) {
    //         return newAppUsers.find(aUser => aUser.id === appUser.id) || appUser;
    //       }
    //       return appUser;
    //     })
    //   },
    //   overwrite: true,
    //   id: 'ROOT_QUERY'
    // });
    // afterTag?.(newAppUsers);
  };

  useEffect(() => {
    if (!refetchedTags) {
      return;
    }

    onTag();
  }, [refetchedTags]);

  const items = [
    ...(uniqueAppUserIds.length
      ? [
          ...(uniqueAppUserIds.length === 1 && isAdminOrSuperAdmin
            ? [
                {
                  name: 'Add Pet',
                  value: 'addPet',
                  onClick: () => {
                    ModalDialog.openModal({
                      content: () => <PetDetailsModal initialValues={{ AppUserId: uniqueAppUserIds[0] }} />,
                      title: 'Add Pet'
                    });
                  }
                }
              ]
            : []),
          {
            name: uniqueAppUserIds.length > 1 ? 'Tag all Users' : existingTagsIds?.length ? 'Edit User Tags' : 'Add User Tag',
            value: 'addTag',
            onClick: () =>
              ModalDialog.openModal({
                content: () => <AppUserTagsModal appUsers={appUsers || []} afterTag={onTag} refetchAppUsersAfterTag />,
                title: uniqueAppUserIds.length > 1 ? 'Tag all Users' : `${appUsers?.[0]?.name}'s Tags`
              })
          },
          ...(quickTags || tags || [])
            ?.filter(({ id }) => !existingTagsIds.includes(id))
            .map(tag => ({
              name: tag.name,
              value: tag.id,
              onClick: () => handleQuickTagClick(tag)
            }))
        ]
      : [])
  ].filter(Boolean) as OptionDropDownItem[];

  const loading = loadingAddAppUserTag || loadingRefetchTags;
  return { items, loading };
}
