import React, { useState } from 'react';
import { ModalBody, ModalFooter } from '../../components/Modal/styled';
import { FormButtonsContainer, FormSubmitButton, InputGroup, FormInput, FormLabel, FormError } from '../../components/Shared/Forms/Forms';
import { Col, Container, Row } from '../../components/Shared/Shared';

const PhotoLink = ({ photos, setPhotos, LinkModalRef }) => {
  const [link, setLink] = useState('');
  const [error, setError] = useState('');
  const handleAddPhoto = () => {
    if (link !== '') {
      setPhotos([...photos, link]);
      LinkModalRef.current.closeModal();
    }
    setError('URL is Required.');
  };
  return (
    <>
      <ModalBody>
        <Container>
          <Row>
            <Col size={12}>
              <InputGroup>
                <FormLabel>Image URL (including http// or https://)</FormLabel>
                <FormInput
                  error={error}
                  value={link}
                  onChange={e => {
                    setLink(e.target.value);
                  }}
                  height={32}
                  fontSize={16}
                />
              </InputGroup>
              {error && <FormError>{error}</FormError>}
            </Col>
          </Row>
        </Container>
      </ModalBody>
      <ModalFooter>
        <FormButtonsContainer>
          <FormSubmitButton
            error={false}
            loading={false}
            onClick={() => {
              handleAddPhoto();
            }}
          >
            Add Image
          </FormSubmitButton>
        </FormButtonsContainer>
      </ModalFooter>
    </>
  );
};

export default PhotoLink;
