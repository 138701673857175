import { useMutation } from '@apollo/client';
import React, { forwardRef, useCallback, useEffect } from 'react';
import { RetryOrderInvoice, SendOrderInvoiceEmail, UpdateOrderInvoiceStatus } from '../../queries';
import { HoverRecordOptionsContainer } from '../../views/styled';
import ModalDialog from '../Modal/ModalDialog';
import OptionDropdown, { OptionDropDownItem } from '../Shared/Menus/OptionDropdown/OptionDropdown';
import { OPTION_DROPDOWN_MENU_BUTTON_TYPES, OPTION_DROPDOWN_TYPES } from '../Shared/Menus/OptionDropdown/types';
import RefundModal from '../Shared/Modals/RefundModal/RefundModal';
import AdjustInvoiceAmountModal from '../UserDrawer/AdjustInvoiceAmountModal';
import { ORDER_INVOICE_STATUS, OrderInvoice } from './types';
import { getOrderInvoiceTitles } from './utils';

type OrderInvoiceOptionsProps = {
  orderInvoice: OrderInvoice;
  refetchOrderInvoices: () => void;
};

const OrderInvoiceOptions = forwardRef<HTMLDivElement, OrderInvoiceOptionsProps>(({ orderInvoice, refetchOrderInvoices }, optionsRef) => {
  const { id } = orderInvoice;
  const { shouldShow: { shouldShowRetry, shouldShowUpdate, shouldShowSendEmail, shouldShowMarkAsPaid, shouldDisplayRefund } = {} } = getOrderInvoiceTitles(orderInvoice);

  const [retryOrderInvoice, { data: { retryOrderInvoice: newOrderInvoice = {} } = {}, loading: loadingRetry }] = useMutation(RetryOrderInvoice);

  const [sendOrderInvoiceEmail, { loading: loadingSendEmail }] = useMutation(SendOrderInvoiceEmail);

  const [updateOrderInvoiceStatus, { loading: loadingUpdateStatus }] = useMutation(UpdateOrderInvoiceStatus);

  const handleRefund = useCallback(
    (then: (...args: unknown[]) => void = () => {}) => {
      ModalDialog.openModal({
        content: () => <RefundModal order={orderInvoice.Order} shouldIssueRefund />,
        title: 'Refund Payment',
        onClose() {
          setTimeout(() => {
            then();
          }, 3000);
        }
      });
    },
    [orderInvoice]
  );

  const loading = loadingRetry || loadingSendEmail || loadingUpdateStatus;

  const items = [
    shouldShowRetry && {
      name: 'Retry Charge',
      value: 'retry',
      onClick: () => {
        retryOrderInvoice({ variables: { OrderInvoiceId: [id] } });
      }
    },
    shouldShowUpdate && {
      name: 'Update Amount',
      value: 'update',
      onClick: () => {
        ModalDialog.openModal({
          title: 'Update Amount',
          content: () => <AdjustInvoiceAmountModal OrderInvoice={orderInvoice} refetchOrderInvoices={refetchOrderInvoices} />
        });
      }
    },
    shouldShowSendEmail && {
      name: 'Send Invoice',
      value: 'send',
      onClick: () => {
        sendOrderInvoiceEmail({ variables: { OrderInvoiceId: id } });
      }
    },
    shouldShowMarkAsPaid && {
      name: 'Mark as Paid',
      value: 'mark',
      onClick: () => {
        updateOrderInvoiceStatus({ variables: { OrderInvoiceId: id, status: ORDER_INVOICE_STATUS.PAYMENT_SUCCEEDED } });
      }
    },
    shouldDisplayRefund && {
      name: 'Refund payment',
      value: 'refund',
      onClick: () => handleRefund(() => refetchOrderInvoices())
    }
  ].filter(Boolean) as OptionDropDownItem[];

  useEffect(() => {
    if (newOrderInvoice?.id) {
      refetchOrderInvoices();
    }
  }, [newOrderInvoice?.id]);

  return (
    <HoverRecordOptionsContainer ref={optionsRef} show={loading}>
      <OptionDropdown
        menuButtonType={OPTION_DROPDOWN_MENU_BUTTON_TYPES.PLUS}
        noApplyButton
        containerRelative
        buttonLoading={loading}
        options={[{ optionType: OPTION_DROPDOWN_TYPES.BUTTONS, id: 'options', items }]}
      />
    </HoverRecordOptionsContainer>
  );
});

export default OrderInvoiceOptions;
