import { useQuery, useReactiveVar } from '@apollo/client';
import React, { useCallback, useEffect } from 'react';
import { Controller, ControllerRenderProps, useForm, useWatch } from 'react-hook-form';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { GetBranchAppUserTags, GetBranchTagCategories } from '../../../queries';
import { vars } from '../../../reactive';
import { manageChatsFilters } from '../../../reactive/actions';
import BranchTagsModal from '../../../views/Store/BranchTags/BranchTagsModal';
import { BRANCH_TAGS_TYPES, BranchAppUserTag } from '../../../views/Store/BranchTags/types';
import ModalDialog from '../../Modal/ModalDialog';
import { FilterItem } from '../../Shared/Filters/Filters';
import { CHATS_FILTER_TYPES, CHATS_MORE_OPTIONS_TYPES } from '../../Shared/Filters/config';
import { AddPetTagButton, PetTagFilterPill, PetTagsQuickFilterContainer, TagsOptionsContainer, TagsQuickFiltersWrapper } from '../../Shared/Filters/styled';
import { FormInput } from '../../Shared/Forms/Forms';
import OptionDropdown from '../../Shared/Menus/OptionDropdown/OptionDropdown';
import { OPTION_DROPDOWN_MENU_BUTTON_TYPES, OPTION_DROPDOWN_TYPES } from '../../Shared/Menus/OptionDropdown/types';
import { VerticallyCenteredLoader } from '../../Shared/Spinner';

const ChatTagItem = ({ form, item }: { form: ControllerRenderProps<Record<string, any>>; item: BranchAppUserTag }) => {
  const { onChange, value } = form;

  return (
    <PetTagFilterPill>
      <FormInput
        type={'checkbox'}
        onChange={e => {
          if (e.target.checked) {
            onChange([item.id]);
          } else {
            onChange([]);
          }
        }}
        checked={value.includes(item.id)}
      />
      <span>
        {item.name} ({item.appUsersCount})
      </span>
    </PetTagFilterPill>
  );
};

type ChatTagsQuickFilterProps = {
  quickTagFilter: FilterItem;
};

const ChatTagsQuickFilter = ({ quickTagFilter }: ChatTagsQuickFilterProps) => {
  const { addFilter, removeFilter } = manageChatsFilters;
  const { control } = useForm();

  const selectedCategoryId = useReactiveVar(vars.chatSelectedCategoryId);

  const selectedQuickFilters = useWatch<string[]>({
    control,
    name: 'selectedQuickFilters',
    defaultValue: []
  });

  const { data: { getBranchTagCategories: tagCategories = [] } = {}, loading: loadingTagCategories } = useQuery<{ getBranchTagCategories: { id: string; name: string }[] }>(GetBranchTagCategories);

  const {
    data: { getBranchAppUserTags: categoryTags = [] } = {},
    loading: loadingTagsByCategory,
    refetch: refetchTagsByCategory
  } = useQuery<{
    getBranchAppUserTags: BranchAppUserTag[];
  }>(GetBranchAppUserTags, {
    variables: {
      offset: 0,
      limit: 1000,
      BranchTagCategoryId: [selectedCategoryId],
      visibility: ['showOnBar']
    },
    skip: !selectedCategoryId,
    fetchPolicy: 'cache-and-network'
  });

  const { data: { getBranchAppUserTags: allQuickTags = [] } = {}, loading: loadingAllQuickTags } = useQuery<{
    getBranchAppUserTags: BranchAppUserTag[];
  }>(GetBranchAppUserTags, {
    fetchPolicy: 'cache-and-network',
    variables: {
      offset: 0,
      limit: 1000,
      visibility: ['showOnBar']
    }
  });

  const loading = loadingTagCategories || loadingTagsByCategory || loadingAllQuickTags;

  const quickTags = selectedCategoryId ? categoryTags : allQuickTags;

  const quickTagMoreOptions = quickTagFilter.moreOptions.find(({ type }) => type === CHATS_MORE_OPTIONS_TYPES.CHAT_TAGS)!;

  const { mobile } = useMediaQuery({ mobile: true });

  const onQuickTagClick = useCallback(
    (tag: string[]) => {
      if (tag.length) {
        addFilter({
          ...quickTagFilter,
          moreOptions: [{ ...quickTagMoreOptions, values: quickTags.filter(({ id }) => tag.includes(id)).map(({ id, name }) => ({ value: id, name })) }],
          requisite: 'true'
        });
        return;
      }
      removeFilter(CHATS_FILTER_TYPES.CHAT_QUICK_TAGS);
    },
    [quickTags]
  );

  useEffect(() => {
    onQuickTagClick(selectedQuickFilters);
  }, [selectedQuickFilters]);

  return (
    <TagsQuickFiltersWrapper>
      <PetTagsQuickFilterContainer>
        {loading && <VerticallyCenteredLoader size={25} />}
        {!loading && (
          <>
            <Controller
              name={`selectedQuickFilters`}
              control={control}
              defaultValue={[]}
              render={form => (
                <>
                  {quickTags?.map((item, index) => (
                    <ChatTagItem key={index} form={form} item={item as BranchAppUserTag} />
                  ))}
                </>
              )}
            />

            {!mobile && (
              <AddPetTagButton
                onClick={() =>
                  ModalDialog.openModal({
                    content: () => (
                      <BranchTagsModal
                        presetType={BRANCH_TAGS_TYPES.APP_USER}
                        defaultCategoryId={selectedCategoryId}
                        onAddTag={() => (selectedCategoryId ? refetchTagsByCategory()?.then(ModalDialog.closeModal) : null)}
                      />
                    ),
                    title: 'Add New Smart Tag'
                  })
                }
              >
                <span>+ Add</span>
              </AddPetTagButton>
            )}
          </>
        )}
      </PetTagsQuickFilterContainer>
      <TagsOptionsContainer>
        <OptionDropdown
          menuButtonType={!mobile ? OPTION_DROPDOWN_MENU_BUTTON_TYPES.MENU : OPTION_DROPDOWN_MENU_BUTTON_TYPES.MORE}
          loading={loading}
          options={[
            {
              id: 'categories',
              optionType: OPTION_DROPDOWN_TYPES.BUTTONS,
              items: [
                {
                  name: 'All',
                  value: 'all',
                  onClick: () => vars.chatSelectedCategoryId(null),
                  green: !selectedCategoryId
                },
                ...tagCategories.map(({ id, name }) => ({
                  name,
                  value: id,
                  onClick: () => vars.chatSelectedCategoryId(id),
                  green: selectedCategoryId === id
                }))
              ]
            }
          ]}
          noApplyButton
          buttonLoading={loading}
        />
      </TagsOptionsContainer>
    </TagsQuickFiltersWrapper>
  );
};

export default ChatTagsQuickFilter;
