import React, { FC, Fragment } from 'react';
import { ExtensionDivider, ExtensionPetCaseLabel, ExtensionPetCasesRecord } from '../../../components/Pets/styled';
import { toReadableDate } from '../../../utils/dates';
import { PetForm as PetFormType } from './PetFormsHistory';

const PetForm: FC<{ petForm: PetFormType; onClick: React.MouseEventHandler<HTMLDivElement>; selected: boolean }> = ({ petForm, onClick, selected }) => {
  return (
    <Fragment>
      <ExtensionPetCasesRecord selected={selected} onClick={onClick}>
        <ExtensionPetCaseLabel fontSize={16} fontWeight={'700'} margingB={8}>
          {petForm?.form?.name || 'Completed Form'}
        </ExtensionPetCaseLabel>
        <ExtensionPetCaseLabel fontSize={14} fontWeight={'600'} margingB={2}>
          {toReadableDate(petForm?.createdAt, { noTime: true, isLocale: true })}
        </ExtensionPetCaseLabel>
        <ExtensionPetCaseLabel fontSize={14} fontWeight={'600'} margingB={8} primary={petForm?.isAnswered} orange={!petForm?.isAnswered}>
          {petForm?.isAnswered ? 'Complete' : 'Incomplete'}
        </ExtensionPetCaseLabel>
      </ExtensionPetCasesRecord>
      <ExtensionDivider />
    </Fragment>
  );
};

export default PetForm;
