import React, { useState, lazy, forwardRef, useImperativeHandle, Suspense, useEffect, useRef } from 'react';
const Editor = lazy(() => import('react-draft-wysiwyg').then(mod => Promise.resolve({ default: mod.Editor })));
import htmlToDraft from 'html-to-draftjs';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { ActivityIndicator } from './Spinner';

type MarkupEditorProps = {
  defaultValue?: string;
  onChange?: (data: { html: string; text: string }) => void;
};

export type MarkupEditorRef = {
  getHTML: () => string;
  getText: () => string;
  getValues: () => {
    html: string;
    text: string;
  };
  reset: () => void;
  setEditorText: (data: string) => void;
  focus: () => void;
};

const MarkupEditor = forwardRef<MarkupEditorRef, MarkupEditorProps>(({ defaultValue, onChange }, ref) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const editorRef = useRef<any>(null);

  const reset = () => {
    setEditorState(EditorState.createEmpty());
  };

  const getHTML = () => {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const markup = draftToHtml(rawContentState);
    return markup;
  };

  const getText = () => {
    const text = editorState.getCurrentContent().getPlainText('\u0001');
    return text;
  };

  const getValues = () => {
    const html = getHTML();
    const text = getText();
    return { html, text };
  };

  const setEditorText = (data: string) => {
    const htmlBody = htmlToDraft(data);
    const state = ContentState.createFromBlockArray(htmlBody.contentBlocks, htmlBody.entityMap);
    setEditorState(EditorState.createWithContent(state));
  };

  const focus = () => {
    if (editorRef.current) {
      editorRef.current.editor.focus();
    }
  };

  useImperativeHandle(ref, () => ({ getHTML, reset, setEditorText, getValues, getText, focus }));

  useEffect(() => {
    if (defaultValue) {
      setEditorText(defaultValue);
    }
  }, [defaultValue]);

  return (
    <Suspense fallback={<ActivityIndicator />}>
      <Editor
        toolbar={{
          options: ['blockType', 'inline', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'image', 'remove', 'history']
        }}
        editorState={editorState}
        onEditorStateChange={setEditorState}
        wrapperClassName="wrapper-class"
        editorClassName="editor-class"
        toolbarClassName="toolbar-class"
        onChange={() => onChange && onChange(getValues())}
        ref={editorRef}
      />
    </Suspense>
  );
});

export default MarkupEditor;
