import { useLazyQuery, useMutation } from '@apollo/client';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import ModalDialog from '../../components/Modal/ModalDialog';
import { GetBranchAppointments, ReplaceBranchAppointmentItemTag, GetBranchAppointmentTags } from '../../queries';
import client from '../../utils/client';
import BranchTagItemModal from '../../views/Store/BranchTags/BranchTagItemModal';
import BranchTagsModal from '../../views/Store/BranchTags/BranchTagsModal';
import { BRANCH_TAGS_TYPES, BranchAppointmentTag, BranchPetRecordTag } from '../../views/Store/BranchTags/types';
import Common from '../../views/Store/Common';
import { BOOKING_STATUS_TYPES, BOOKING_TYPE, Booking } from './types';
import { checkAddAppointmentTagCapacity } from './utils';

type BookingTagsModalProps = {
  appointments: Booking[];
  refetchAppointmentsAfterTag?: boolean;
  afterTag?: (newAppointments: Booking[]) => void;
  extraTags?: BranchAppointmentTag[];
};

const BookingTagsModal: FC<BookingTagsModalProps> = ({ appointments, refetchAppointmentsAfterTag, afterTag, extraTags }) => {
  const formOptions = useForm();
  const [loading, setLoading] = useState(false);
  const { handleSubmit } = formOptions;
  const type = BRANCH_TAGS_TYPES.APPOINTMENT;

  const [replaceBookingTags, { loading: loadingAddBookingTag, data: repalcedTags = {} }] = useMutation(ReplaceBranchAppointmentItemTag);

  const [refetchTagsById, { data: { getBranchAppointmentTags: refetchedTags = [] } = {}, loading: loadingRefetchTags }] = useLazyQuery<{ getBranchAppointmentTags: BranchAppointmentTag[] }>(
    GetBranchAppointmentTags,
    {
      fetchPolicy: 'cache-and-network'
    }
  );

  const allAppointmentsTags = appointments?.flatMap(appointment => appointment?.BranchAppointmentTags || []);

  const sharedTagsBetweenAppointments = allAppointmentsTags.filter(tag => appointments?.every(appointment => appointment?.BranchAppointmentTags?.some(t => t?.id === tag?.id)));

  const uniqueSharedTags = [...new Set(sharedTagsBetweenAppointments?.map(tag => tag?.id))].map(id => allAppointmentsTags?.find(tag => tag?.id === id)!).concat(extraTags || []);

  const uniqueTagsRef = useRef<string[]>([]);
  const allTagsRef = useRef<{ tags: BranchAppointmentTag[] }>({ tags: [] });

  const onSubmit = ({ selectedTags }) => {
    const tagIds = selectedTags.map((tag: any) => tag.value);
    const existingTagIds = allAppointmentsTags?.map(tag => tag?.id);
    const uniqueTagIds = [...new Set([...tagIds, ...existingTagIds])];

    uniqueTagsRef.current = uniqueTagIds;

    checkAddAppointmentTagCapacity({
      appointments,
      tags: allTagsRef.current.tags.filter(tag => tagIds.includes(tag?.id)),
      handleAddTag: () => {
        replaceBookingTags({ variables: { appointmentId: appointments.map(({ id }) => id), BranchAppointmentTagId: tagIds } });
      }
    });
  };

  useEffect(() => {
    if (repalcedTags?.replaceBranchAppointmentTagItems !== undefined) {
      const uniqueTagIds = uniqueTagsRef.current;
      refetchTagsById({ variables: { id: uniqueTagIds } });
    }
  }, [repalcedTags]);

  useEffect(() => {
    if (refetchedTags?.length) {
      setLoading(true);
      (async () => {
        const { data: { getBranchAppointments: newAppointments = [] } = {} } = await client.query<{ getBranchAppointments: Booking[] }>({
          query: GetBranchAppointments,
          variables: {
            appointment_id: appointments.map(({ id }) => id),
            requisite_queries: ['appointment_id'],
            alternative_queries: [],
            offset: 0,
            limit: 1000,
            status: [BOOKING_STATUS_TYPES.CONFIRMED],
            booking_type: [BOOKING_TYPE.SLOT, BOOKING_TYPE.MULTI_SLOT, BOOKING_TYPE.MULTI_DAY]
          },
          fetchPolicy: 'network-only'
        });
        afterTag?.(newAppointments);
      })();

      if (!refetchAppointmentsAfterTag) {
        ModalDialog.closeModal();
        setLoading(false);
        return;
      }

      const refetchAppointments = Common.get<() => Promise<void>>(`Bookings.GetBranchAppointments.refetch`);
      if (refetchAppointments) {
        refetchAppointments()?.then(() => {
          ModalDialog.closeModal();
          setLoading(false);
        });
      }
    }
  }, [refetchedTags]);

  const onAddTag = useCallback(
    (newTag: BranchPetRecordTag | BranchAppointmentTag, type: BRANCH_TAGS_TYPES) => {
      const newExtraTags = [...(extraTags || []), ...(type === BRANCH_TAGS_TYPES.APPOINTMENT ? [newTag as BranchAppointmentTag] : [])];
      ModalDialog.openModal({
        content: () => <BookingTagsModal appointments={appointments} refetchAppointmentsAfterTag={refetchAppointmentsAfterTag} afterTag={afterTag} extraTags={newExtraTags} />,
        title: `Appointment Tags`
      });
    },
    [appointments, extraTags, refetchAppointmentsAfterTag, afterTag]
  );

  const handleAddBranchTag = (tag: string) => {
    ModalDialog.openModal({
      content: () => <BranchTagsModal name={tag} presetType={type} onAddTag={onAddTag} />,
      title: 'Add New Smart Tag'
    });
  };
  return (
    <BranchTagItemModal
      formOptions={formOptions}
      existingTagsItems={uniqueSharedTags}
      onSubmit={onSubmit}
      loading={loadingAddBookingTag || loadingRefetchTags || loading}
      handleAddBranchTag={handleAddBranchTag}
      type={type}
      ref={allTagsRef}
    />
  );
};

export default BookingTagsModal;
