import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import React, { useEffect } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import CreatableSelect from 'react-select';
import Colors from '../../../Colors';
import { AddBranchSlot, AddProductBranchSlots, DeleteBranchSlot, GetAllServices, GetBusUserProfile } from '../../../queries';
import { vars } from '../../../reactive';
import { toTimeInput } from '../../../utils/dates';
import { Product } from '../../../views/Store/types';
import ModalDialog from '../../Modal/ModalDialog';
import { ModalBody, ModalFooter } from '../../Modal/styled';
import DurationSelector, { convertDurationToMinutes } from '../../Shared/DurationSelector';
import { FormButtonsContainer, FormError, FormInput, FormLabel, FormSubmitButton, InputsWrapper, WideInputGroup, selectTheme } from '../../Shared/Forms/Forms';
import { Container } from '../../Shared/Shared';
import { CenteredLoader } from '../../Shared/Spinner';

const BlockSlotModal = () => {
  const formOptions = useForm();
  const { control, handleSubmit, errors, formState, getValues, setValue } = formOptions;
  const selectedDate = useReactiveVar(vars.selectedDate);
  const date = selectedDate ? selectedDate?.date : new Date();
  const selectedSlotDateUTC = selectedDate
    ? new Date(Date.UTC(selectedDate.date?.getFullYear(), selectedDate.date?.getMonth(), selectedDate.date?.getDate(), selectedDate.hour > -1 ? selectedDate.hour : 0, selectedDate.minute || 0))
    : '';

  const hour = selectedDate ? (selectedDate?.hour > -1 ? selectedDate?.hour : 0) : 0;
  const minute = selectedDate ? selectedDate?.minute || 0 : 0;
  const defaultDate = new Date(selectedSlotDateUTC);

  const { data: { getBusUserProfile: profile = {} } = {} } = useQuery(GetBusUserProfile);

  const watchedDate = useWatch({
    control,
    name: 'date',
    defaultValue: defaultDate.toISOString().slice(0, -14)
  });

  const defaultTime = toTimeInput(Number(hour) + Number(minute) / 60);

  const watchedTime =
    useWatch({
      control,
      name: 'time',
      defaultValue: defaultTime
    }) || '';

  const [handleDeleteBranchSlot, { data: deletedBranchSlot, loading: deleteLoading, error: deleteError }] = useMutation(DeleteBranchSlot);

  const [handleAddBranchSlot, { data: { branchSlotAdd: { id: addedSlotId = '' } = {} } = {}, loading: addLoading, error: addError }] = useMutation(AddBranchSlot);

  const [handleAddProductBranchSlots, { data: { addProductBranchSlots = [] } = {}, loading: addProductLoading, error: addProductError }] = useMutation(AddProductBranchSlots);

  const { data: { getProducts: services = [] } = {}, loading: loadingServices } = useQuery<{ getProducts: Product[] }>(GetAllServices, {
    fetchPolicy: 'cache-and-network'
  });

  const timestamp = new Date(watchedDate);
  const splitTime = watchedTime.split(':');
  timestamp?.setUTCHours(+splitTime[0], +splitTime[1]);

  const existingSlot = profile.Branch?.BranchSlots?.find(slot => slot.timestamp === timestamp?.toISOString());

  const options = services.map(service => ({
    value: service.id,
    label: service.name
  }));

  const existingProductIds = options.filter(option => existingSlot?.Products?.find(product => product?.id === option.value));

  const handleDelete = () => {
    handleDeleteBranchSlot({
      variables: {
        id: existingSlot?.id
      }
    });
  };

  const handleAddSlot = () => {
    const { count, duration, description } = getValues();
    handleAddBranchSlot({
      variables: {
        timestamp,
        count: Number(count),
        duration: Number(duration !== undefined ? convertDurationToMinutes(duration) || 0 : 0),
        BranchId: profile.Branch.id,
        description
      }
    });
  };

  const handleBlocking = handleSubmit(() => {
    if (existingSlot) {
      handleDelete();
      return;
    }
  });

  useEffect(() => {
    if (formState.isSubmitted) {
      if ((existingSlot && deletedBranchSlot) || !existingSlot) {
        handleAddSlot();
      }
    }

    if (deletedBranchSlot && !formState.isSubmitted) {
      ModalDialog.closeModal();
    }
  }, [formState.isSubmitted, deletedBranchSlot, existingSlot]);

  useEffect(() => {
    const { productIds } = getValues();

    if (addedSlotId) {
      if (productIds.length) {
        handleAddProductBranchSlots({
          variables: {
            ProductId: productIds.map(({ value }) => value),
            BranchSlotId: addedSlotId
          }
        });
        return;
      }

      ModalDialog.closeModal();
    }
  }, [addedSlotId]);

  useEffect(() => {
    const { productIds } = getValues();
    if (addProductBranchSlots?.[0]?.id && productIds.length) {
      ModalDialog.closeModal();
    }
  }, [addProductBranchSlots, addedSlotId]);

  useEffect(() => {
    if (existingSlot) {
      setValue('productIds', existingProductIds);
      setValue('count', existingSlot?.count);
      setValue('duration', existingSlot?.duration);
    }
  }, [watchedDate, watchedTime, existingSlot]);

  return (
    <>
      <ModalBody>
        <Container width={500}>
          {loadingServices && <CenteredLoader />}
          {!loadingServices && (
            <InputsWrapper>
              <WideInputGroup>
                <FormLabel error={errors.title}>Title</FormLabel>
                <Controller
                  as={<FormInput error={errors.description} height={32} fontSize={16} />}
                  control={control}
                  name={'description'}
                  defaultValue={existingSlot?.description || 'Blocked'}
                  rules={{
                    required: true
                  }}
                />
                {errors.description && <FormError>{errors.description.message || 'Title is required'}</FormError>}
              </WideInputGroup>
              <WideInputGroup>
                <FormLabel error={errors.productId}>Services</FormLabel>
                <Controller
                  as={
                    <CreatableSelect
                      defaultValue={existingProductIds}
                      styles={{ container: (provided, state) => ({ ...provided }), valueContainer: (provided, state) => ({ ...provided, height: 48, overflowY: 'scroll' }) }}
                      options={options}
                      isMulti
                      theme={selectTheme}
                      name={'productIds'}
                    />
                  }
                  control={control}
                  name={'productIds'}
                  defaultValue={existingProductIds}
                />
                {errors.productId && <FormError>This field is required</FormError>}
              </WideInputGroup>

              <WideInputGroup>
                <FormLabel error={errors.date}>Date</FormLabel>
                <Controller
                  as={<FormInput error={errors.date} type={'date'} height={32} fontSize={16} />}
                  control={control}
                  name={'date'}
                  defaultValue={defaultDate.toISOString().slice(0, -14)}
                  rules={{
                    required: true
                  }}
                />
                {errors.date && <FormError>{errors.date.message || 'date is required'}</FormError>}
              </WideInputGroup>
              <WideInputGroup>
                <FormLabel error={errors.time}>Time</FormLabel>
                <Controller as={<FormInput error={errors.time} type={'time'} height={32} fontSize={16} />} control={control} name={'time'} defaultValue={defaultTime} rules={{ required: true }} />
                {errors.time && <FormError>{errors.time.message || 'time is required'}</FormError>}
              </WideInputGroup>
              <DurationSelector
                defaultDuration={existingSlot?.duration || 60}
                options={{
                  name: 'duration',
                  title: 'Duration',
                  rules: { required: true }
                }}
                formOptions={formOptions}
              />
              {errors?.duration && <FormError>{errors?.duration?.message || 'duration is required'}</FormError>}
              <WideInputGroup>
                <FormLabel error={errors.count}>Count</FormLabel>
                <Controller
                  as={<FormInput error={errors.count} type={'number'} height={32} fontSize={16} />}
                  control={control}
                  name={'count'}
                  defaultValue={existingSlot?.count || 1}
                  rules={{ required: true, max: 50, min: 1 }}
                />
                {errors.count && <FormError>{errors.count.message || 'count is required'}</FormError>}
              </WideInputGroup>
            </InputsWrapper>
          )}
        </Container>
      </ModalBody>
      <ModalFooter bgColor={Colors.white}>
        <FormButtonsContainer>
          <FormSubmitButton error={!!addError || !!deleteError || !!addProductError} loading={addLoading || addProductLoading || (existingSlot && deleteLoading)} onClick={handleBlocking}>
            Block Slot
          </FormSubmitButton>
          {existingSlot?.id && (
            <FormSubmitButton type="button" onClick={handleDelete} loading={deleteLoading} error={!!deleteError} secondary danger>
              Unblock Slot
            </FormSubmitButton>
          )}
        </FormButtonsContainer>
      </ModalFooter>
    </>
  );
};

export default BlockSlotModal;
