import { useLazyQuery, useReactiveVar } from '@apollo/client';
import React, { Fragment, useCallback, useEffect } from 'react';
import Colors from '../../Colors';
import { GetBranchPetRecordTags } from '../../queries';
import { vars } from '../../reactive';
import { Booking, BookingOrder } from '../../views/Bookings/types';
import { getPetsWithTags } from '../../views/Bookings/utils';
import { BranchPetRecordTag } from '../../views/Store/BranchTags/types';
import { Divider, DrawerSubLabel, ProfileListItemsContainer } from '../DrawerBar/styled';
import { DRAWER_IDS } from '../DrawerBar/types';
import ProfileListRecord from '../Profile/ProfileListRecord';
import { ReportActionButtonContainer } from '../Profile/styled';
import PetRecordOptions from './PetRecordOptions';
import { Pet } from './types';

const PetProfilesList = ({
  pets,
  booking,
  loadPetTags = false,
  drawerId,
  afterTag,
  splitByTags = false,
  tagsToInclude,
  hideUntagged = false,
  renderUnderPetProfile,
  onClickPetProfile,
  renderTagHeader
}: {
  pets: Pet[];
  booking?: Booking;
  loadPetTags?: boolean;
  drawerId: DRAWER_IDS;
  afterTag?: (newPets: Pet[]) => void;
  splitByTags?: boolean;
  tagsToInclude?: string[];
  hideUntagged?: boolean;
  renderUnderPetProfile?: (pet: Pet) => JSX.Element | null | undefined;
  onClickPetProfile?: (pet: Pet) => void;
  renderTagHeader?: ({ pets, tag }: { pets: Pet[]; tag: BranchPetRecordTag }) => JSX.Element | null | undefined;
}) => {
  const { sortedPets, countOfEachPet, splitPetsByTags, allPetsTagsUniqueByTagId, untaggedPets, countOfUntaggedPets } = getPetsWithTags(pets, { tagsToInclude });
  const drawerBars = useReactiveVar(vars.drawerBars);
  const drawer = drawerBars.find(({ drawerId: id }) => id === drawerId);
  const [getTags, { data: { getBranchPetRecordTags: tags = [] } = {} }] = useLazyQuery<{ getBranchPetRecordTags: BranchPetRecordTag[] }>(GetBranchPetRecordTags, {
    fetchPolicy: 'cache-and-network',
    variables: { offset: 0, limit: 1000, quick_action: true }
  });

  useEffect(() => {
    if (loadPetTags) {
      getTags();
    }
  }, [loadPetTags]);

  const renderPet = useCallback(
    (pet: Pet, index: number) => {
      return (
        <ProfileListRecord
          profile={pet}
          key={pet?.id || index}
          booking={booking}
          drawerId={drawerId}
          petCount={countOfEachPet.find(p => p?.pet?.id === pet?.id)!.petCount}
          renderUnderProfile={() => renderUnderPetProfile?.(pet)}
          onClickPetProfile={onClickPetProfile}
        >
          <ReportActionButtonContainer>
            <PetRecordOptions orderId={(drawer?.recordData as BookingOrder)?.[0]?.OrderItem?.Order?.id} pets={[pet]} showAlways quickTags={tags} afterTag={afterTag} />
          </ReportActionButtonContainer>
        </ProfileListRecord>
      );
    },
    [booking, drawerId, countOfEachPet, tags, afterTag, renderUnderPetProfile]
  );

  return (
    <>
      {!splitByTags && (
        <ProfileListItemsContainer>
          {sortedPets.map((pet, index) => {
            return (
              <Fragment key={pet?.id || index}>
                {renderPet(pet, index)}
                {index !== sortedPets.length - 1 && <Divider marginBottom={16} />}
              </Fragment>
            );
          })}
        </ProfileListItemsContainer>
      )}
      {splitByTags && (
        <>
          {!!allPetsTagsUniqueByTagId?.length &&
            splitPetsByTags.map(({ tag, countOfPetsWithThisTag, petsWithThisTag }, index) => (
              <Fragment key={tag?.id}>
                {renderTagHeader && renderTagHeader?.({ pets: petsWithThisTag, tag })}
                {!renderTagHeader && (
                  <DrawerSubLabel weight={800} size={14} marginBottom={12} key={tag.id} color={Colors.black}>
                    {tag?.name} ({countOfPetsWithThisTag})
                  </DrawerSubLabel>
                )}
                <ProfileListItemsContainer>{petsWithThisTag.map(renderPet)}</ProfileListItemsContainer>
                {index !== splitPetsByTags.length - 1 && <Divider marginBottom={16} />}
              </Fragment>
            ))}

          {!!untaggedPets?.length && !hideUntagged && (
            <>
              {!!allPetsTagsUniqueByTagId?.length && <Divider marginBottom={16} />}
              <DrawerSubLabel weight={800} size={14} marginBottom={12} color={Colors.black}>
                Untagged ({countOfUntaggedPets})
              </DrawerSubLabel>

              <ProfileListItemsContainer>{untaggedPets.map(renderPet)}</ProfileListItemsContainer>
            </>
          )}
        </>
      )}
    </>
  );
};

export default PetProfilesList;
