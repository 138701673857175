import React, { useRef } from 'react';
import { StoreEditButton } from '../../../components/DrawerBar/DrawerButtons';
import { DRAWER_IDS } from '../../../components/DrawerBar/types';
import { HoverRecordOptionsContainer, RecordBody, RecordDataContainer } from '../../styled';
import { BranchMessage, BRANCH_MESSAGE_TYPES_LABELS } from './BranchMessages';

const BranchMessageRecord = ({ branchMessage, navigateDrawer }: { branchMessage: BranchMessage; navigateDrawer: ({ drawerLinkId }: { drawerLinkId: string }) => void }) => {
  const storeEditButtonRef = useRef<{ handleClick: () => void }>(null);

  const handleClick = () => {
    // storeEditButtonRef.current?.handleClick();
    // navigateDrawer({ drawerLinkId: branchMessage?.id });
  };

  return (
    <RecordDataContainer onClick={handleClick}>
      <RecordBody fontWeight={'800'}>{branchMessage?.name}</RecordBody>
      <RecordBody width="20%" marginRight="20">
        {branchMessage?.message}
      </RecordBody>
      <RecordBody width="30%">{BRANCH_MESSAGE_TYPES_LABELS[branchMessage?.type]}</RecordBody>
      <RecordBody>{branchMessage?.Products?.map(product => product.name).join(', ')}</RecordBody>
      <HoverRecordOptionsContainer>
        <StoreEditButton drawer={{ drawerId: DRAWER_IDS.BRANCH_MESSAGE_DRAWER, recordData: branchMessage }} ref={storeEditButtonRef} />
      </HoverRecordOptionsContainer>
    </RecordDataContainer>
  );
};

export default BranchMessageRecord;
