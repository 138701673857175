import styled from 'styled-components';

export const Container = styled.div<{ drawerBarsLength: number }>`
  width: calc(100% - ${props => props.drawerBarsLength * 370}px);
  transition: 0.3s ease-in-out all;

  @media (max-width: 1200px) {
    width: calc(100% - ${props => props.drawerBarsLength * 330}px);
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;
