import { useMutation } from '@apollo/client';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import MarkupEditor, { MarkupEditorRef } from '../../../components/Shared/MarkupEditor';
import useIcons from '../../../hooks/useIcons';
import { AddBranchNote, NoteDelete, NoteEdit } from '../../../queries';
import { toReadableDate } from '../../../utils/dates';
import { getKeys } from '../../../utils/helpers';
import Alert from '../../Alert/Alert';
import { Divider } from '../../DrawerBar/styled';
import {
  BranchNoteBody,
  BranchNoteBodyTextArea,
  BranchNoteBusUserName,
  BranchNoteButton,
  BranchNoteFooter,
  BranchNoteHeaderInfo,
  BranchNoteHoverActions,
  BranchNotePrivateTag,
  BranchNoteSubtitle,
  MarkupEditorContainer,
  MarkupEditorWrapper
} from './styled';
import { BranchNoteBodyContentRef, Note } from './types';

type BranchNoteBodyContentProps = {
  branchNote: Note | null;
  idVariables: Record<string, string[]>;
  refetchBranchNotes: () => void;
  options?: {
    busUserName?: boolean;
    timestamp?: boolean;
    deletable?: boolean;
    divider?: boolean;
    privateTag?: boolean;
    html?: boolean;
    cancelButton?: boolean;
  };
  onSave?: () => void;
};

const BranchNoteBodyContent = forwardRef<BranchNoteBodyContentRef, BranchNoteBodyContentProps>(({ branchNote, idVariables, refetchBranchNotes, options, onSave }, ref) => {
  const [isEditing, setIsEditing] = useState(false);
  const [addBranchNote, { data: addedBranchNote }] = useMutation<{ addBranchNote: Note }>(AddBranchNote);
  const [handleEditBranchNote, { data: editedBranchNote }] = useMutation<{ noteEdit: Note }>(NoteEdit);
  const [handleDeleteBranchNote, { data: deletedBranchNote }] = useMutation<{ noteDelete: Note }>(NoteDelete);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const htmlTextAreaRef = useRef<MarkupEditorRef>(null);
  const icons = useIcons();
  const deleteIcon = icons?.noteDelete?.childImageSharp?.gatsbyImageData;

  const textAreaFocus = () => {
    if (options?.html) {
      setIsEditing(true);
      requestAnimationFrame(() => {
        htmlTextAreaRef.current?.focus();
      });
      return;
    }
    textAreaRef.current?.focus();
  };

  useImperativeHandle(ref, () => ({
    textAreaFocus,
    setIsEditing
  }));

  const getBody = () => {
    const { html, text } = htmlTextAreaRef.current?.getValues() || {};
    const content = options?.html ? text : textAreaRef.current?.value;
    const content_html = options?.html ? html : undefined;
    return { content, content_html };
  };

  const handleEditNote = () => {
    handleEditBranchNote({
      variables: {
        id: branchNote?.id,
        body: getBody(),
        timestamp: new Date().toISOString()
      }
    });
  };

  const handleAddNewNote = () => {
    addBranchNote({
      variables: {
        body: getBody(),
        timestamp: new Date().toISOString(),
        ...getKeys(idVariables).reduce((acc, key) => {
          acc[key] = idVariables[key]?.[0];
          return acc;
        }, {} as Record<string, string | undefined>)
      }
    });

    setIsEditing(false);
  };

  const handleNoteSubmit = () => {
    const hasValue = options?.html ? htmlTextAreaRef.current?.getText() : textAreaRef.current?.value;
    if (!hasValue) {
      return;
    }
    if (branchNote) {
      handleEditNote();
      return;
    }
    handleAddNewNote();
  };

  const handleNoteDelete = () => {
    Alert.alert({
      title: 'Delete Note',
      description: 'Are you sure you want to delete this note?',
      acceptButtonText: 'Delete',
      onAccept: () => {
        handleDeleteBranchNote({
          variables: {
            id: branchNote?.id
          }
        });
      },
      denyButtonText: 'Cancel'
    });
  };

  useEffect(() => {
    if (addedBranchNote?.addBranchNote?.id || editedBranchNote?.noteEdit?.id || deletedBranchNote?.noteDelete) {
      refetchBranchNotes();
      setIsEditing(false);
      onSave?.();
    }
  }, [addedBranchNote, editedBranchNote, deletedBranchNote]);

  useEffect(() => {
    if (options?.html) {
      return;
    }
    setIsEditing(branchNote?.body?.content !== textAreaRef.current?.value);
  }, [branchNote?.body?.content]);

  const handleNoteCancel = () => {
    setIsEditing(false);
    if (branchNote) {
      if (textAreaRef.current) {
        textAreaRef.current.value = branchNote.body.content;
      }
      if (htmlTextAreaRef.current) {
        htmlTextAreaRef.current.setEditorText(branchNote.body.content_html || branchNote.body.content);
      }
    }
  };

  return (
    <BranchNoteBody>
      {branchNote && (
        <>
          <BranchNoteHeaderInfo>
            {options?.busUserName && <BranchNoteBusUserName>By {branchNote?.BusUser?.name} </BranchNoteBusUserName>}
            {options?.timestamp && <BranchNoteBusUserName>{toReadableDate(branchNote?.timestamp, { noTime: true, isLocale: true })}</BranchNoteBusUserName>}
            {options?.privateTag && <BranchNotePrivateTag>Private</BranchNotePrivateTag>}
          </BranchNoteHeaderInfo>
          {(options?.timestamp || options?.busUserName) && <Divider marginTop={20} />}
        </>
      )}
      {branchNote && options?.deletable && (
        <BranchNoteHoverActions right={options?.privateTag ? 80 : 0} top={options?.privateTag ? 15 : 10}>
          <BranchNoteButton onClick={handleNoteDelete}>
            <GatsbyImage image={deleteIcon} alt="Delete" />
          </BranchNoteButton>
        </BranchNoteHoverActions>
      )}

      {!options?.html && (
        <BranchNoteBodyTextArea
          maxRows={6}
          minRows={1}
          defaultValue={branchNote?.body?.content}
          placeholder="Add a private note"
          onChange={e => setIsEditing(branchNote?.body?.content !== e.target.value)}
          ref={textAreaRef}
        />
      )}

      {options?.html && (
        <MarkupEditorContainer noMargin={!branchNote}>
          {!isEditing && <div dangerouslySetInnerHTML={{ __html: branchNote?.body?.content_html || branchNote?.body?.content || 'Add a private note' }} onClick={textAreaFocus} />}

          <MarkupEditorWrapper visible={isEditing}>
            <MarkupEditor defaultValue={branchNote?.body?.content_html || branchNote?.body?.content || ''} ref={htmlTextAreaRef} />
          </MarkupEditorWrapper>
        </MarkupEditorContainer>
      )}

      {isEditing && (
        <BranchNoteFooter>
          <BranchNoteButton onClick={handleNoteSubmit} disabled={!isEditing}>
            <BranchNoteSubtitle>Save</BranchNoteSubtitle>
          </BranchNoteButton>
          {options?.cancelButton && (
            <BranchNoteButton onClick={handleNoteCancel}>
              <BranchNoteSubtitle>Cancel</BranchNoteSubtitle>
            </BranchNoteButton>
          )}
        </BranchNoteFooter>
      )}

      {options?.divider && <Divider />}
    </BranchNoteBody>
  );
});

export default BranchNoteBodyContent;
