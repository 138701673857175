import React, { FC } from 'react';
import { useMutation } from '@apollo/client';
import { RecordDataContainer, AppUserImage, AppUserImageContainer, AppUserName, RecordBody, AppUserImageNameContainer } from '../styled';
import { MarkOrderSubscriptionAsReadByBranch } from '../../queries';
import useIcons from '../../hooks/useIcons';
import { Subscription } from './types';
import { getSubscriptionTitles } from './utils';
import { RedDot } from '../../components/Shared/Shared';

type SubscriptionRecordProps = {
  subscription: Subscription;
  navigateDrawer: ({ drawerLinkId }: { drawerLinkId: string }) => void;
  showTotal?: {
    orderTotal?: boolean;
    dateFilter?: { from: string; to: string };
  };
};

const SubscriptionRecord: FC<SubscriptionRecordProps> = ({ subscription, navigateDrawer, showTotal }) => {
  const icons = useIcons();
  const [markAsRead] = useMutation(MarkOrderSubscriptionAsReadByBranch);

  const handleClick = () => {
    if (!subscription.is_read_by_branch) {
      markAsRead({ variables: { id: subscription.id } });
    }
    navigateDrawer({
      drawerLinkId: subscription.id
    });
  };

  const { subscriptionName, petsNames, user, lastRepeatTotal, orderTotal, activeText } = getSubscriptionTitles({ subscription, repeatsFilter: showTotal?.dateFilter });

  const isNew = !subscription.is_read_by_branch;

  return (
    <RecordDataContainer onClick={handleClick}>
      <RecordBody>
        <AppUserImageNameContainer>
          {isNew && <RedDot />}
          <AppUserImageContainer>
            <AppUserImage src={user?.profile_pic || icons.user.childImageSharp.gatsbyImageData.images.fallback.src} />
          </AppUserImageContainer>
          <AppUserName>{user?.name}</AppUserName>
        </AppUserImageNameContainer>
      </RecordBody>
      <RecordBody>{subscriptionName}</RecordBody>

      <RecordBody>{petsNames}</RecordBody>
      <RecordBody>{activeText}</RecordBody>
      {showTotal && <RecordBody flexEnd>{showTotal.orderTotal ? orderTotal : lastRepeatTotal}</RecordBody>}
    </RecordDataContainer>
  );
};

export default SubscriptionRecord;
