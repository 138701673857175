import React from 'react';
import Colors from '../../../Colors';
import { BlockedCalendarEvent } from '../../../views/Bookings/types';
import { BookingCard, BookingCardContainer, BookingCardContent, BookingCardLabel, BookingCardTitle, MoreInfoContainer } from '../styled';

const BlockedSlot = ({
  slot,
  dayIndex,
  onBlockedSlotClick,
  mediumSize
}: {
  slot: BlockedCalendarEvent;
  dayIndex: number;
  onBlockedSlotClick: (slot: BlockedCalendarEvent) => void;
  mediumSize?: boolean;
}) => {
  const { duration, isCalendarStyle, itemIndex, period, timestamp, totalNumberOfEvents, count, description } = slot;
  const hour = new Date(timestamp).getUTCHours();
  const minute = new Date(timestamp).getUTCMinutes();
  const color = isCalendarStyle ? Colors.white : Colors.orangeMedium;

  return (
    <BookingCardContainer>
      <BookingCard hour={hour} minute={minute} duration={Number(duration)} color={color} onClick={() => onBlockedSlotClick(slot)} index={itemIndex} mediumSize={mediumSize} total={totalNumberOfEvents}>
        <BookingCardContent color={color} isCalendarStyle={isCalendarStyle}>
          <BookingCardLabel isCalendarStyle={isCalendarStyle} fontSize={14} fontWeight={'bold'}>
            {description || '- Blocked -'}
          </BookingCardLabel>
          <BookingCardLabel isCalendarStyle={isCalendarStyle} fontSize={14} fontWeight={'400'}>
            {'Count: ' + count}
          </BookingCardLabel>
          {slot?.Products?.[0]?.id && (
            <BookingCardLabel isCalendarStyle={isCalendarStyle} fontSize={14} fontWeight={'400'}>
              {slot?.Products?.length === 1 ? slot?.Products?.[0]?.name : slot?.Products?.length + ' Products'}
            </BookingCardLabel>
          )}
          <BookingCardLabel isCalendarStyle={isCalendarStyle} fontSize={14} fontWeight={'400'}>
            {period}
          </BookingCardLabel>
        </BookingCardContent>
      </BookingCard>
      <MoreInfoContainer hour={hour} minute={minute} duration={Number(duration)} color={color} total={1} index={0} dayIndex={dayIndex} mediumSize={mediumSize}>
        <BookingCardTitle>{description || '- Blocked Slot -'}</BookingCardTitle>
        <BookingCardLabel isCalendarStyle={isCalendarStyle} fontSize={18} fontWeight={'600'}>
          {'Count: ' + count}
        </BookingCardLabel>
        {slot?.Products?.[0]?.id &&
          slot?.Products?.map?.((product, index) => (
            <BookingCardLabel isCalendarStyle={isCalendarStyle} key={index} fontSize={14} fontWeight={'400'}>
              {product.name}
            </BookingCardLabel>
          ))}
        <BookingCardLabel isCalendarStyle={isCalendarStyle} fontSize={14} fontWeight={'400'}>
          {period}
        </BookingCardLabel>
      </MoreInfoContainer>
    </BookingCardContainer>
  );
};

export default BlockedSlot;
