import React, { forwardRef } from 'react';
import useMediaQuery from '../../hooks/useMediaQuery';
import usePetRecordOptions from '../../hooks/usePetRecordOptions';
import { RequireAtLeastOne } from '../../utils/helpers';
import { BranchPetRecordTag } from '../../views/Store/BranchTags/types';
import { HoverRecordOptionsContainer } from '../../views/styled';
import OptionDropdown, { OptionDropDownItem } from '../Shared/Menus/OptionDropdown/OptionDropdown';
import { OPTION_DROPDOWN_MENU_BUTTON_TYPES, OPTION_DROPDOWN_MENU_POSITIONS, OPTION_DROPDOWN_TYPES } from '../Shared/Menus/OptionDropdown/types';
import { Pet } from './types';

type PetRecordOptionsProps = {
  pets: Pet[];
  quickTags?: BranchPetRecordTag[];
  orderId?: string;
  showAlways?: boolean;
  loadTags?: boolean;
  refetchPetsAfterTag?: boolean;
  right?: boolean;
  defaultAppointments?: any[];
  afterTag?: (newPets: Pet[]) => void;
  addedItems?: OptionDropDownItem[];
  menuButtonType?: OPTION_DROPDOWN_MENU_BUTTON_TYPES;
  onClickAction?: () => void;
};

type Props = RequireAtLeastOne<PetRecordOptionsProps, 'quickTags' | 'loadTags'>;

const PetRecordOptions = forwardRef<HTMLDivElement, Props>(
  (
    { pets, quickTags, orderId, showAlways = false, loadTags = false, refetchPetsAfterTag = false, right = false, defaultAppointments = [], afterTag, addedItems, menuButtonType, onClickAction },
    optionsRef
  ) => {
    const { items, loading } = usePetRecordOptions({ pets, quickTags, orderId, loadTags, refetchPetsAfterTag, defaultAppointments, afterTag, onClickAction });

    const adjustedItems = [...(items || []), ...(addedItems || [])];
    const { mobile } = useMediaQuery({ mobile: true });
    return (
      <HoverRecordOptionsContainer ref={optionsRef} show={loading || showAlways}>
        <OptionDropdown
          menuButtonType={menuButtonType || mobile ? OPTION_DROPDOWN_MENU_BUTTON_TYPES.MORE : OPTION_DROPDOWN_MENU_BUTTON_TYPES.PLUS}
          noApplyButton
          containerRelative
          buttonLoading={loading}
          options={[{ optionType: OPTION_DROPDOWN_TYPES.BUTTONS, id: 'options', items: adjustedItems }]}
          menuPosition={right ? OPTION_DROPDOWN_MENU_POSITIONS.RIGHT : OPTION_DROPDOWN_MENU_POSITIONS.LEFT}
        />
      </HoverRecordOptionsContainer>
    );
  }
);

export default PetRecordOptions;
