import { useQuery, useReactiveVar } from '@apollo/client';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useEffect, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import ReactSelect from 'react-select';
import { BackButton, BackButtonText } from '../../../components/DrawerBar/styled';
import ModalDialog from '../../../components/Modal/ModalDialog';
import { BusUserProfile } from '../../../components/Profile/types';
import { selectTheme } from '../../../components/Shared/Forms/Forms';
import { CenteredLoader } from '../../../components/Shared/Spinner';
import useIcons from '../../../hooks/useIcons';
import { GetBusUserProfile } from '../../../queries';
import { vars } from '../../../reactive';
import AppStorage from '../../../utils/AppStorage';
import { getUTCDateMonthName } from '../../../utils/dates';
import { isUserSuperAdmin } from '../../../utils/helpers';
import BranchBillingBranchStats from './BranchBillingBranchStats';
import BranchBillingProviderStats from './BranchBillingProviderStats';
import GenerateInvoicesModal from './GenerateInvoicesModal';
import OrderInvoicesChargeModal from './OrderInvoicesChargeModal';
import OrderInvoicesSendEmailModal from './OrderInvoicesSendEmailModal';
import { ActionButton, ActionButtonsContainer, BillingStatsContainer, BranchBillingContainer, SelectedBillingFiltersContainer } from './styled';

const BranchBilling = () => {
  const { data: { getBusUserProfile: userProfile } = {}, loading } = useQuery<{ getBusUserProfile: BusUserProfile }>(GetBusUserProfile, {
    fetchPolicy: 'cache-and-network'
  });

  const allBranchBillingDates = userProfile?.Branch?.allBranchBillingDates || [];

  const currentBranchCycle = userProfile?.Branch?.branchBillingCycle || allBranchBillingDates[0] || {};

  const activeBillingCycle = allBranchBillingDates.find(({ billing_date_from }) => new Date(billing_date_from).getTime() === new Date(currentBranchCycle.billing_date_from).getTime());

  const defaultBillingCycle = activeBillingCycle || allBranchBillingDates[0] || { billing_date_from: '', billing_date_to: '' };

  const { control } = useForm();
  const currentBillingCycle = useWatch({
    name: 'currentBillingCycle',
    control,
    defaultValue: defaultBillingCycle
  });

  const { billing_date_from = '', billing_date_to = '' } = currentBillingCycle || {};
  const icons = useIcons();
  const chevronIcon = icons.chevronLeft.childImageSharp.gatsbyImageData;
  const selectedBranchState = useState<string | null>(null);
  const invoiceFilterState = useState<boolean>(false);
  const subscriptionsFilterState = useState<boolean>(false);
  const subscriptionInvoicesFilterState = useState<boolean>(false);
  const [invoiceFilter] = invoiceFilterState;
  const [subscriptionsFilter] = subscriptionsFilterState;
  const [subscriptionInvoicesFilter] = subscriptionInvoicesFilterState;
  const [selectedBranch, setSelectedBranch] = selectedBranchState;

  const currentPeriod = { start: billing_date_from, end: billing_date_to };
  const isProvider = isUserSuperAdmin(userProfile);

  const disableBulkInvoiceSendEmail = useReactiveVar(vars.disableBulkInvoiceSendEmail);
  const disableBulkInvoiceCharge = useReactiveVar(vars.disableBulkInvoiceCharge);

  const handleBulkChargeInvoice = () => {
    ModalDialog.openModal({
      content: () => (
        <OrderInvoicesChargeModal
          initialValues={{
            billed_on_from: currentPeriod.start,
            billed_on_to: currentPeriod.end
          }}
        />
      ),
      title: 'Charge Invoices'
    });
  };

  const handleBulkSendEmailInvoice = () => {
    ModalDialog.openModal({
      content: () => (
        <OrderInvoicesSendEmailModal
          initialValues={{
            billed_on_from: currentPeriod.start,
            billed_on_to: currentPeriod.end
          }}
        />
      ),
      title: 'Send Invoices'
    });
  };

  const handleGenerateInvoices = () => {
    ModalDialog.openModal({
      content: () => <GenerateInvoicesModal />,
      title: 'Generate Invoices'
    });
  };

  useEffect(() => {
    AppStorage.set('providerBranchId', selectedBranch);

    return () => {
      AppStorage.remove('providerBranchId');
    };
  }, [selectedBranch]);

  const shouldDisableBulkInvoiceSendEmail =
    disableBulkInvoiceSendEmail && disableBulkInvoiceSendEmail?.branchId === selectedBranch && new Date(disableBulkInvoiceSendEmail.until!).getTime() > Date.now();
  const shouldDisableBulkInvoiceCharge = disableBulkInvoiceCharge && disableBulkInvoiceCharge?.branchId === selectedBranch && new Date(disableBulkInvoiceCharge.until!).getTime() > Date.now();
  const shouldRenderProviderView = isProvider && !selectedBranch;

  const renderDateSelector = () => {
    return (
      <div style={{ width: 300, marginLeft: 'auto', marginRight: 20, marginBottom: 20 }}>
        <Controller
          name="currentBillingCycle"
          control={control}
          defaultValue={defaultBillingCycle}
          render={({ onChange, value }) => (
            <ReactSelect
              options={allBranchBillingDates}
              theme={selectTheme}
              value={value}
              onChange={onChange}
              getOptionLabel={option =>
                !!option?.billing_date_from && option?.billing_date_to!! ? `${getUTCDateMonthName(option.billing_date_from)} - ${getUTCDateMonthName(option.billing_date_to)}` : ''
              }
              getOptionValue={option => option.billing_date_from}
            />
          )}
        />
      </div>
    );
  };

  const renderProviderBackButton = () => {
    return (
      <>
        {isProvider && (
          <BillingStatsContainer noMargin>
            <SelectedBillingFiltersContainer>
              <BackButton onClick={() => setSelectedBranch(null)}>
                <GatsbyImage image={chevronIcon} alt="Back" />
                <BackButtonText>All Branches</BackButtonText>
              </BackButton>
            </SelectedBillingFiltersContainer>
          </BillingStatsContainer>
        )}
      </>
    );
  };

  const renderBranchButtons = () => {
    return (
      <>
        {!!selectedBranch && invoiceFilter && (
          <ActionButtonsContainer>
            <ActionButton onClick={handleGenerateInvoices} disabled={false}>
              Generate Invoices
            </ActionButton>
            <ActionButton onClick={handleBulkSendEmailInvoice} disabled={shouldDisableBulkInvoiceSendEmail}>
              Send Invoices
            </ActionButton>
            <ActionButton onClick={handleBulkChargeInvoice} disabled={shouldDisableBulkInvoiceCharge}>
              Charge
            </ActionButton>
          </ActionButtonsContainer>
        )}
      </>
    );
  };

  const renderBranchView = () => {
    return (
      <>
        <BranchBillingContainer>
          {renderProviderBackButton()}
          {renderBranchButtons()}
        </BranchBillingContainer>
        <BranchBillingBranchStats currentPeriod={currentPeriod} invoiceFilter={invoiceFilter} subscriptionsFilter={subscriptionsFilter} subscriptionInvoiceFilter={subscriptionInvoicesFilter} />
      </>
    );
  };

  const renderProviderView = () => {
    return (
      <BranchBillingProviderStats
        selectedBranchState={selectedBranchState}
        invoiceFilterState={invoiceFilterState}
        currentPeriod={currentPeriod}
        subscriptionsFilterState={subscriptionsFilterState}
        subscriptionInvoiceFilterState={subscriptionInvoicesFilterState}
      />
    );
  };

  return (
    <>
      {loading && <CenteredLoader size={50} />}
      {!loading && (
        <>
          {renderDateSelector()}
          {shouldRenderProviderView && renderProviderView()}
          {!shouldRenderProviderView && renderBranchView()}
        </>
      )}
    </>
  );
};

export default BranchBilling;
