import { OPTION_DROPDOWN_TYPES } from '../Menus/OptionDropdown/types';
import { SelectedFilter } from './config';
import { FILTER_TYPES, MORE_OPTIONS_TYPES } from './Filters';

export const getPetFilter = (selectedFilters: SelectedFilter[], filterType: FILTER_TYPES) => {
  return selectedFilters.find(filter => filter.value === filterType);
};

export const getPetFilterMoreOptionsValues = (filter: SelectedFilter | undefined, moreOptionsType: MORE_OPTIONS_TYPES, options: { name?: boolean } = { name: false }) => {
  if (!filter) return;

  const { name } = options;

  const moreOption = filter?.moreOptions.find(option => option.type === moreOptionsType);

  if (name) {
    return (moreOption?.values as { name: string; value: string }[])?.map(({ name }) => name);
  }

  if (moreOption?.optionType === OPTION_DROPDOWN_TYPES.MULTI_SELECT) {
    return (moreOption?.values as { name: string; value: string }[])?.map(({ value }) => value);
  }

  return moreOption?.values as string;
};

export const getBookingFilter = (selectedFilters: SelectedFilter[], filterType: FILTER_TYPES) => {
  return selectedFilters.find(filter => filter.value === filterType);
};

export const getBookingFilterMoreOptionsValues = (filter: SelectedFilter | undefined, moreOptionsType: MORE_OPTIONS_TYPES, options: { name?: boolean } = { name: false }) => {
  if (!filter) return;

  const { name } = options;

  const moreOption = filter?.moreOptions.find(option => option.type === moreOptionsType);

  if (name) {
    return (moreOption?.values as { name: string; value: string }[])?.map(({ name }) => name);
  }

  if (moreOption?.optionType === OPTION_DROPDOWN_TYPES.MULTI_SELECT) {
    return (moreOption?.values as { name: string; value: string }[])?.map(({ value }) => value);
  }

  return moreOption?.values as string;
};

export const getProductFilter = (selectedFilters: SelectedFilter[], filterType: FILTER_TYPES) => {
  return selectedFilters.find(filter => filter.value === filterType);
};

export const getProductsFilterMoreOptionsValues = (filter: SelectedFilter | undefined, moreOptionsType: MORE_OPTIONS_TYPES, options: { name?: boolean } = { name: false }) => {
  if (!filter) return;

  const { name } = options;

  const moreOption = filter?.moreOptions.find(option => option.type === moreOptionsType);

  if (name) {
    return (moreOption?.values as { name: string; value: string }[])?.map(({ name }) => name);
  }

  if (moreOption?.optionType === OPTION_DROPDOWN_TYPES.MULTI_SELECT) {
    return (moreOption?.values as { name: string; value: string }[])?.map(({ value }) => value);
  }

  return moreOption?.values as string;
};

export const getSubscriptionFilter = (selectedFilters: SelectedFilter[], filterType: FILTER_TYPES) => {
  return selectedFilters.find(filter => filter.value === filterType);
};

export const getSubscriptionFilterMoreOptionsValues = (filter: SelectedFilter | undefined, moreOptionsType: MORE_OPTIONS_TYPES, options: { name?: boolean } = { name: false }) => {
  if (!filter) return;

  const { name } = options;

  const moreOption = filter?.moreOptions.find(option => option.type === moreOptionsType);

  if (name) {
    return (moreOption?.values as { name: string; value: string }[])?.map(({ name }) => name);
  }

  if (moreOption?.optionType === OPTION_DROPDOWN_TYPES.MULTI_SELECT) {
    return (moreOption?.values as { name: string; value: string }[])?.map(({ value }) => value);
  }

  return moreOption?.values as string;
};

export const getChatFilter = (selectedFilters: SelectedFilter[], filterType: FILTER_TYPES) => {
  return selectedFilters.find(filter => filter.value === filterType);
};

export const getChatFilterMoreOptionsValues = (filter: SelectedFilter | undefined, moreOptionsType: MORE_OPTIONS_TYPES, options: { name?: boolean } = { name: false }) => {
  if (!filter) return;

  const { name } = options;

  const moreOption = filter?.moreOptions.find(option => option.type === moreOptionsType);

  if (name) {
    return (moreOption?.values as { name: string; value: string }[])?.map(({ name }) => name);
  }

  if (moreOption?.optionType === OPTION_DROPDOWN_TYPES.MULTI_SELECT) {
    return (moreOption?.values as { name: string; value: string }[])?.map(({ value }) => value);
  }

  return moreOption?.values as string;
};
