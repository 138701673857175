import React, { useEffect } from 'react';
import { DRAWER_IDS } from '../../../components/DrawerBar/types';
import InfiniteList from '../../../components/Shared/InfiniteList/InfiniteList';
import useNavigateDrawer from '../../../hooks/useNavigateDrawer';
import usePaginatedQuery from '../../../hooks/usePaginatedQuery';
import useSetDrawerOnNavigation from '../../../hooks/useSetDrawerOnNavigation';
import { GetBranchPetRecordTags } from '../../../queries';
import { RecordsContainer } from '../../styled';
import Common from '../Common';
import BranchTagsRecord from './BranchTagsRecord';
import { BranchPetRecordTag } from './types';

const BranchPetRecordTags = () => {
  const [[hasMoreItems, setHasMoreItems], queryResult] = usePaginatedQuery<BranchPetRecordTag[]>({
    query: GetBranchPetRecordTags
  });

  const { data: { getBranchPetRecordTags: branchPetRecordTags = [] } = {}, loading, fetchMore, refetch } = queryResult;

  useSetDrawerOnNavigation({
    itemList: branchPetRecordTags,
    drawerId: DRAWER_IDS.BRANCH_TAG_DRAWER,
    itemsToFilterFrom: branchPetRecordTags
  });

  const navigateDrawer = useNavigateDrawer({
    drawerData: {
      drawerId: DRAWER_IDS.BRANCH_TAG_DRAWER,
      itemsToFilterFrom: branchPetRecordTags
    }
  });

  useEffect(() => {
    Common.set(`BranchTags.GetBranchTags.refetch`, () => {
      refetch?.().then(() => setHasMoreItems(true));
    });
  }, []);

  return (
    <RecordsContainer>
      <InfiniteList
        list={branchPetRecordTags}
        itemRenderer={(tag: BranchPetRecordTag) => <BranchTagsRecord key={tag.id} branchTag={tag} navigateDrawer={navigateDrawer} />}
        hasMoreItems={hasMoreItems}
        loading={loading}
        fetchMore={fetchMore as any}
        offset={branchPetRecordTags?.length}
        setHasMoreItems={setHasMoreItems}
      />
    </RecordsContainer>
  );
};

export default BranchPetRecordTags;
