import { useLazyQuery, useQuery } from '@apollo/client';
import React, { FC, useEffect, useRef } from 'react';
import Colors from '../../../../Colors';
import ModalDialog from '../../../../components/Modal/ModalDialog';
import { ExtensionDivider, ExtensionPetCaseLabel, ExtensionWideColumn, ExtensionWideColumnInfo, FlexContainer } from '../../../../components/Pets/styled';
import { Adherence, MedCond, Pet, SuppRecord, VaccRecord } from '../../../../components/Pets/types';
import { BusUserProfile } from '../../../../components/Profile/types';
import BranchNotes from '../../../../components/Shared/BranchNotes/BranchNotes';
import { BranchNoteType, BranchNotesRef } from '../../../../components/Shared/BranchNotes/types';
import InfiniteList from '../../../../components/Shared/InfiniteList/InfiniteList';
import OptionDropdown from '../../../../components/Shared/Menus/OptionDropdown/OptionDropdown';
import { OPTION_DROPDOWN_MENU_BUTTON_TYPES, OPTION_DROPDOWN_TYPES } from '../../../../components/Shared/Menus/OptionDropdown/types';
import { CenteredLoader } from '../../../../components/Shared/Spinner';
import useDownload from '../../../../hooks/useDownload';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import usePaginatedQuery from '../../../../hooks/usePaginatedQuery';
import { GetBusUserProfile, GetNotesByMedCondId, GetPetRecordRecords } from '../../../../queries';
import { toReadableDate } from '../../../../utils/dates';
import { getKeys } from '../../../../utils/helpers';
import { EditCase } from './CaseModal';
import MedicalNote from './MedicalNote';
import { AddNewMedicalNote } from './MedicalNoteModal';
import { AddNewMedication } from './MedicationModal';
import PrescribedMedications from './PrescribedMedications';
import PrescribedVaccinations from './PrescribedVaccinations';
import { AddNewVaccination } from './VaccinationModal';
import { AdministeredRecordContainer, DeselectReportButton, MedicalCaseDetailsContainer } from './styled';

type MedicalCaseDetailsProps = {
  petId: string;
  medicalCase: MedCond;
  innerRef?: ((instance: HTMLDivElement | null) => void) | React.RefObject<HTMLDivElement> | null | undefined;
  cases: Record<string, any>[];
  pet: Pet;
  loading?: boolean;
  deselectCase?: () => void;
  hideNotes?: boolean;
  fullWidth?: boolean;
};

enum OptionDropdownItems {
  EDIT_CASE = 'Edit Case',
  DOWNLOAD_REPORT = 'Download Report',
  ADD_MEDICATION = 'Add Medication',
  ADD_VACCINATION = 'Add Vaccination',
  ADD_NOTE = 'Add Note',
  ADD_PRIVATE_NOTE = 'Add Private Note'
}

const MedicalCaseDetails: FC<MedicalCaseDetailsProps> = ({ medicalCase, petId, innerRef, cases, pet, loading, deselectCase, hideNotes, fullWidth = false }) => {
  const { tablet } = useMediaQuery({ tablet: true });
  const branchNotesRef = useRef<BranchNotesRef>(null);
  const { addNote: addBranchNote } = branchNotesRef?.current || {};

  const [[hasMoreItems, setHasMoreItems], { data: { getNotesByMedCondId: notes = [] } = {}, loading: notesLoading, refetch, fetchMore }] = usePaginatedQuery<{ id: string; name: string }[]>({
    query: GetNotesByMedCondId,
    limit: 2,
    otherVariables: { medCondId: [medicalCase?.id] },
    otherParams: { skip: !medicalCase?.id || hideNotes }
  });

  const [getReports, { loading: downloadLoading }] = useDownload<{
    MedCondId: string[];
  }>({
    type: 'reports',
    variables: { MedCondId: [medicalCase?.id] },
    fileName: `Medical Records, ${pet?.name}, ${pet?.AppUser?.name} - 1 Report - ${toReadableDate(new Date(), { noTime: true, isLocale: true })}`,
    fileFormat: 'pdf'
  });

  const sharedOptionDropdownItems = {
    [OptionDropdownItems.ADD_NOTE]: () => ModalDialog.openModal({ content: () => <AddNewMedicalNote refetch={refetch} medicalCase={medicalCase} petId={petId} cases={cases} autoOpenModal /> }),
    [OptionDropdownItems.ADD_PRIVATE_NOTE]: () => addBranchNote?.()
  };

  const tabletOptionDropdownItems = {
    [OptionDropdownItems.ADD_MEDICATION]: () => ModalDialog.openModal({ content: () => <AddNewMedication medCond={medicalCase} pet={pet} autoOpenModal /> }),
    [OptionDropdownItems.ADD_VACCINATION]: () => ModalDialog.openModal({ content: () => <AddNewVaccination medCond={medicalCase} autoOpenModal /> }),
    ...sharedOptionDropdownItems
  };

  const optionDropdownItems = {
    [OptionDropdownItems.EDIT_CASE]: () => ModalDialog.openModal({ content: () => <EditCase medCase={medicalCase} autoOpenModal /> }),
    [OptionDropdownItems.DOWNLOAD_REPORT]: () => getReports(),
    ...(tablet ? tabletOptionDropdownItems : {})
  };

  const branchExclusiveOptions = [OptionDropdownItems.ADD_MEDICATION, OptionDropdownItems.ADD_VACCINATION, OptionDropdownItems.ADD_NOTE, OptionDropdownItems.EDIT_CASE];

  const [getRecords, { data: { getPetRecordRecords: { SuppRecords = [], VaccRecords = [], Adherences = [] } = {} } = {} }] = useLazyQuery<{
    getPetRecordRecords: {
      SuppRecords: SuppRecord[];
      VaccRecords: VaccRecord[];
      Adherences: Adherence[];
    };
  }>(GetPetRecordRecords, {
    fetchPolicy: 'cache-and-network'
  });

  useEffect(() => {
    if (medicalCase?.id) {
      getRecords({ variables: { MedCondId: [medicalCase?.id] } });
    }
  }, [medicalCase?.id]);

  const { data: { getBusUserProfile: profile } = {} } = useQuery<{ getBusUserProfile: BusUserProfile }>(GetBusUserProfile);

  const busUserProfile = (profile || {}) as BusUserProfile;

  const createdByBranch = busUserProfile?.Branch?.id === medicalCase?.Branch?.id;

  return (
    <>
      <ExtensionWideColumn ref={innerRef} active={!!medicalCase} noTextOverFlow={!!hideNotes} fullWidth={fullWidth}>
        {!cases.length && loading && <CenteredLoader size={70} />}

        {!!cases.length && medicalCase && (
          <InfiniteList
            list={notes}
            fetchMore={fetchMore}
            hasMoreItems={hasMoreItems}
            itemRenderer={(item: any) => <MedicalNote note={item} key={item?.id} medicalCase={medicalCase} refetch={refetch} />}
            loading={notesLoading}
            offset={notes.length}
            refetch={refetch}
            setHasMoreItems={setHasMoreItems}
            emptyListProps={{ dimensions: { width: '100%', height: 'auto' } }}
            noEmptyList={hideNotes}
            ListHeader={
              <>
                <ExtensionPetCaseLabel fontSize={22} fontWeight={'900'} margingB={8}>
                  {tablet && <DeselectReportButton onClick={deselectCase}>{'<'}</DeselectReportButton>}
                  {!tablet && medicalCase?.name}
                  {tablet && 'Reports'}
                  {!hideNotes && (
                    <OptionDropdown
                      menuButtonType={OPTION_DROPDOWN_MENU_BUTTON_TYPES.MENU}
                      buttonLoading={downloadLoading}
                      options={[
                        {
                          optionType: OPTION_DROPDOWN_TYPES.BUTTONS,
                          id: 'OPTIONS',
                          items: getKeys(optionDropdownItems).map(key => ({
                            name: key,
                            value: key,
                            disabled: !createdByBranch && branchExclusiveOptions.includes(key),
                            onClick: optionDropdownItems[key]
                          }))
                        }
                      ]}
                      noApplyButton
                      containerRelative
                    />
                  )}
                </ExtensionPetCaseLabel>
                <ExtensionDivider />
                <ExtensionWideColumnInfo>
                  {tablet && (
                    <>
                      <FlexContainer>
                        <ExtensionPetCaseLabel fontSize={20} fontWeight={'900'} margingB={8}>
                          {medicalCase?.name}
                        </ExtensionPetCaseLabel>
                      </FlexContainer>
                      <ExtensionDivider />
                    </>
                  )}
                  <FlexContainer>
                    <ExtensionPetCaseLabel fontSize={16} width={150} fontWeight={'700'} margingB={8}>
                      Date:
                    </ExtensionPetCaseLabel>
                    <ExtensionPetCaseLabel fontSize={16} fontWeight={'600'}>
                      {toReadableDate(medicalCase?.createdAt, { isLocale: true })}
                    </ExtensionPetCaseLabel>
                  </FlexContainer>
                  <FlexContainer>
                    <ExtensionPetCaseLabel fontSize={16} width={150} fontWeight={'700'}>
                      Veterinarian:
                    </ExtensionPetCaseLabel>
                    <ExtensionPetCaseLabel fontSize={16} fontWeight={'600'}>
                      {medicalCase?.Branch?.name}
                    </ExtensionPetCaseLabel>
                  </FlexContainer>
                  <ExtensionDivider />
                  <ExtensionPetCaseLabel fontSize={16} fontWeight={'normal'}>
                    {medicalCase?.description || 'No description'}
                  </ExtensionPetCaseLabel>
                </ExtensionWideColumnInfo>
                <ExtensionDivider />
                <AdministeredRecordContainer>
                  <PrescribedMedications medCond={medicalCase} pet={pet} hideButton={hideNotes} adherences={Adherences} busUserProfile={busUserProfile} suppRecords={SuppRecords} />
                  <PrescribedVaccinations vaccRecords={VaccRecords} hideButton={hideNotes} pet={pet} createdByBranch={createdByBranch} medCond={medicalCase} />
                </AdministeredRecordContainer>
                {!hideNotes && (
                  <ExtensionPetCaseLabel margingB={8} fontSize={18} fontWeight={'800'} primary>
                    Notes
                    {createdByBranch && !tablet && (
                      <OptionDropdown
                        menuButtonType={OPTION_DROPDOWN_MENU_BUTTON_TYPES.PLUS}
                        options={[
                          {
                            optionType: OPTION_DROPDOWN_TYPES.BUTTONS,
                            id: 'OPTIONS',
                            items: getKeys(sharedOptionDropdownItems).map(key => ({
                              name: key,
                              value: key,
                              onClick: sharedOptionDropdownItems[key]
                            }))
                          }
                        ]}
                        noApplyButton
                        containerRelative
                      />
                    )}
                  </ExtensionPetCaseLabel>
                )}
                {medicalCase?.id && (
                  <BranchNotes
                    type={BranchNoteType.MED_COND}
                    MedCondId={medicalCase?.id}
                    options={{
                      busUserName: true,
                      timestamp: true,
                      expandable: false,
                      hideHeader: true,
                      deletable: true,
                      bottomDivider: false,
                      privateTag: true,
                      html: true,
                      cancelButton: true
                    }}
                    ref={branchNotesRef}
                    styles={{
                      containerStyle: () => ({ backgroundColor: Colors.white, padding: 0 }),
                      itemStyle: () => ({ backgroundColor: '#f0f0f0', marginTop: 10, borderRadius: 20, padding: 20, boxSizing: 'border-box', border: '2px solid #e0e0e0' })
                    }}
                  />
                )}
              </>
            }
          />
        )}

        {!medicalCase && !loading && (
          <MedicalCaseDetailsContainer>
            <ExtensionPetCaseLabel fontSize={22} fontWeight={'900'} margingB={8}>
              Please select a Medical case to view details.
            </ExtensionPetCaseLabel>
          </MedicalCaseDetailsContainer>
        )}
      </ExtensionWideColumn>
    </>
  );
};

export default MedicalCaseDetails;
