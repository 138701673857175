import React from 'react';
import { Controller } from 'react-hook-form';
import { RadioBtnsGroup } from '../../../components/Shared/Forms/Forms';

const OpeningHours = ({ control, defaultOpeningHours }) => {
  return (
    <Controller
      control={control}
      name={'out_of_office'}
      defaultValue={defaultOpeningHours?.out_of_office}
      render={({ onChange, value }) => (
        <RadioBtnsGroup
          name={'out_of_office'}
          options={['Out of Office']}
          itemStyle={{ gap: 10 }}
          defaultValue={defaultOpeningHours?.out_of_office}
          inputType={'checkbox'}
          onChange={() => {
            onChange(!value);
          }}
        />
      )}
    />
  );
};

export default OpeningHours;
