import React, { useEffect } from 'react';
import ProductRecord from './ProductRecord';
import { GetProducts } from '../../../queries';
import Common from '../Common';
import InfiniteList from '../../../components/Shared/InfiniteList/InfiniteList';
import { DRAWER_IDS } from '../../../components/DrawerBar/types';
import useSetDrawerOnNavigation from '../../../hooks/useSetDrawerOnNavigation';
import usePaginatedQuery from '../../../hooks/usePaginatedQuery';
import useNavigateDrawer from '../../../hooks/useNavigateDrawer';
import { useRefetchProductsOnFiltersChange } from '../../../hooks/useRefetchOnFiltersChange';

const ProductsBody = () => {
  const [[hasMoreItems, setHasMoreItems], queryResult] = usePaginatedQuery({ query: GetProducts });

  const { data: { getProducts: products = [] } = {}, loading, fetchMore, refetch, variables: previousVariables } = queryResult;

  useSetDrawerOnNavigation({
    itemList: [],
    drawerId: DRAWER_IDS.PRODUCT_DRAWER
  });

  const navigateDrawer = useNavigateDrawer({
    drawerData: {
      drawerId: DRAWER_IDS.PRODUCT_DRAWER
    }
  });

  useEffect(() => {
    Common.set(`Products.GetProducts.refetch`, () => {
      refetch?.().then(() => setHasMoreItems(true));
    });
  }, []);

  const loadingRefetch = useRefetchProductsOnFiltersChange({
    refetch,
    defaultVariables: {
      limit: 20
    },
    setHasMoreItems,
    previousVariables
  });

  return (
    <InfiniteList
      list={products}
      itemRenderer={product => <ProductRecord key={product?.id} product={product} navigateDrawer={navigateDrawer} />}
      hasMoreItems={hasMoreItems}
      loading={loading || loadingRefetch}
      fetchMore={fetchMore}
      offset={products?.length}
      setHasMoreItems={setHasMoreItems}
    />
  );
};

export default ProductsBody;
