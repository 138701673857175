import React, { useEffect } from 'react';
import InfiniteList from '../../../components/Shared/InfiniteList/InfiniteList';
import { DRAWER_IDS } from '../../../components/DrawerBar/types';
import useSetDrawerOnNavigation from '../../../hooks/useSetDrawerOnNavigation';
import usePaginatedQuery from '../../../hooks/usePaginatedQuery';
import useNavigateDrawer from '../../../hooks/useNavigateDrawer';
import BranchTagsRecord from './BranchTagsRecord';
import { GetBranchTags } from '../../../queries';
import { BranchAppointmentTag, BranchPetRecordTag, BranchTag } from './types';
import Common from '../Common';
import { RecordsContainer } from '../../styled';

const BranchTagsAll = () => {
  const [[hasMoreItems, setHasMoreItems], queryResult] = usePaginatedQuery<{
    branchPetRecordTags: BranchPetRecordTag[];
    branchAppointmentTags: BranchAppointmentTag[];
    branchAppUserTags: BranchTag[];
  }>({
    query: GetBranchTags
  });

  const { data: { getBranchTags: { branchPetRecordTags = [], branchAppointmentTags = [], branchAppUserTags = [] } = {} } = {}, loading, fetchMore, refetch } = queryResult;

  const branchTags = [...branchPetRecordTags, ...branchAppointmentTags, ...branchAppUserTags];

  useSetDrawerOnNavigation({
    itemList: branchTags,
    drawerId: DRAWER_IDS.BRANCH_TAG_DRAWER,
    itemsToFilterFrom: branchTags
  });

  const navigateDrawer = useNavigateDrawer({
    drawerData: {
      drawerId: DRAWER_IDS.BRANCH_TAG_DRAWER,
      itemsToFilterFrom: branchTags
    }
  });

  useEffect(() => {
    Common.set(`BranchTags.GetBranchTags.refetch`, () => {
      refetch?.().then(() => setHasMoreItems(true));
    });
  }, []);

  return (
    <RecordsContainer>
      <InfiniteList
        list={branchTags}
        itemRenderer={(tag: BranchTag) => <BranchTagsRecord key={tag.id} branchTag={tag} navigateDrawer={navigateDrawer} />}
        hasMoreItems={hasMoreItems}
        loading={loading}
        fetchMore={fetchMore as any}
        offset={branchTags?.length}
        setHasMoreItems={setHasMoreItems}
      />
    </RecordsContainer>
  );
};

export default BranchTagsAll;
