import React, { useEffect, useState } from 'react';
import { RecordsContainer } from '../../styled';
import SubscriptionRecord from './StoreSubscriptionRecord';
import { GetSubscriptions } from '../../../queries';
import Common from '../Common';
import InfiniteList from '../../../components/Shared/InfiniteList/InfiniteList';
import { DRAWER_IDS } from '../../../components/DrawerBar/types';
import useSetDrawerOnNavigation from '../../../hooks/useSetDrawerOnNavigation';
import usePaginatedQuery from '../../../hooks/usePaginatedQuery';
import useNavigateDrawer from '../../../hooks/useNavigateDrawer';

const StoreSubscriptions = () => {
  const [[hasMoreItems, setHasMoreItems], queryResult] = usePaginatedQuery({ query: GetSubscriptions });

  const { data: { getProducts: subscriptions = [] } = {}, loading, fetchMore, refetch } = queryResult;

  useSetDrawerOnNavigation({
    itemList: [],
    drawerId: DRAWER_IDS.STORE_SUBSCRIPTION_DRAWER
  });

  const navigateDrawer = useNavigateDrawer({
    drawerData: {
      drawerId: DRAWER_IDS.STORE_SUBSCRIPTION_DRAWER
    }
  });

  useEffect(() => {
    Common.set(`Subscriptions.GetSubscriptions.refetch`, () => {
      refetch?.().then(() => setHasMoreItems(true));
    });
  }, []);

  return (
    <RecordsContainer>
      <InfiniteList
        list={subscriptions}
        itemRenderer={subscription => <SubscriptionRecord key={subscription.id} subscription={subscription} navigateDrawer={navigateDrawer} />}
        hasMoreItems={hasMoreItems}
        loading={loading}
        fetchMore={fetchMore}
        offset={subscriptions?.length}
        setHasMoreItems={setHasMoreItems}
      />
    </RecordsContainer>
  );
};

export default StoreSubscriptions;
