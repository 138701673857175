import React from 'react';
import ChatsBody from '../../components/Chat/ChatsBody';
import ChatsHeader from '../../components/Chat/ChatsHeader/ChatsHeader';
import { CHAT_TABS_TYPES } from '../../components/Chat/types';
import { ViewContainer } from '../../components/Shared/Shared';
import useSelectedState from '../../hooks/useSelectedState';

const Chats = () => {
  const selectedTabState = useSelectedState({ defaultTab: CHAT_TABS_TYPES.ALL, TABS_TYPES: CHAT_TABS_TYPES });

  return (
    <ViewContainer>
      <ChatsHeader selectedTabState={selectedTabState} tabs={Object.values(CHAT_TABS_TYPES)} />
      <ChatsBody selectedTabState={selectedTabState} />
    </ViewContainer>
  );
};

export default Chats;
