import React from 'react';
import { sentenceCase } from 'sentence-case';
import { HeaderLabel, HeaderRow, InputContainer } from '../../../components/Shared/Forms/Forms';
import { THREE_LETTER_WEEK_DAYS } from '../../../utils/dates';

const DaysLabels = ({ hideAvailability }: { hideAvailability?: boolean }) => {
  const itemWidth = hideAvailability ? 11.8 : 10.2;
  return (
    <HeaderRow>
      <HeaderLabel width={17}>Time</HeaderLabel>
      {!hideAvailability && <HeaderLabel width={10}>Slots</HeaderLabel>}
      {THREE_LETTER_WEEK_DAYS.map((day, idx) => (
        <HeaderLabel key={idx} width={itemWidth}>
          {sentenceCase(day)}
        </HeaderLabel>
      ))}
    </HeaderRow>
  );
};

export default DaysLabels;
