import React, { FC, useLayoutEffect } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { FormCheckbox, WideInputGroup } from '../../components/Shared/Forms/Forms';
import { BOOKING_TYPE } from '../Bookings/types';
import { isProductNoCharge, isProductPostpaid } from './ProductPrice';
import { CHARGE_STATUS, Product } from './types';

type ProductOptionsProps = {
  product: Product;
  formOptions: ReturnType<typeof useForm>;
  hasFromFlag: boolean;
  serviceType?: boolean;
  subscriptionType?: boolean;
};

export enum ProductOptionsTypes {
  FROM = 'from',
  AUTOMATICALLY_PICK_BEST_DISCOUNT = 'automatically_pick_best_discount',
  USE_PETS_COUNT = 'use_pets_count',
  AUTO_CONFIRM_ORDER = 'auto_confirm_order',
  ALLOWS_REPEATED_ORDERS = 'allows_repeated_orders',
  ALLOWS_BULK_ORDERS = 'allows_bulk_orders',
  ALLOWS_PAST_SLOTS = 'allows_past_slots',
  OPTIONAL_CARD_DETAILS = 'optional_card_details',
  USER_CAN_CANCEL = 'user_can_cancel',
  USER_CAN_RESCHEDULE = 'user_can_reschedule',
  ENABLE_CUSTOMIZATIONS_QUANTITY_FACTOR = 'enable_customizations_quantity_factor',
  SHOW_CUSTOMIZATIONS_PRICE = 'show_customizations_price'
}

export const getSelectedProductOptions = (values: any[]) => {
  const vals = values || [];
  const selectedOptions = Object.values(ProductOptionsTypes).reduce((acc, option) => {
    acc[option] = vals.includes(option);
    return acc;
  }, {} as Record<ProductOptionsTypes, boolean>);

  return selectedOptions;
};

const getProductOptionsDefaultValue = ({ product, hasFromFlag }: { product: Product; hasFromFlag: boolean }) => {
  const defaultValue = [
    hasFromFlag && ProductOptionsTypes.FROM,
    !!product?.automatically_pick_best_discount && ProductOptionsTypes.AUTOMATICALLY_PICK_BEST_DISCOUNT,
    !!product?.use_pets_count && ProductOptionsTypes.USE_PETS_COUNT,
    !!product?.auto_confirm_order && ProductOptionsTypes.AUTO_CONFIRM_ORDER,
    !!product?.allows_repeated_orders && ProductOptionsTypes.ALLOWS_REPEATED_ORDERS,
    !!product?.allows_bulk_orders && ProductOptionsTypes.ALLOWS_BULK_ORDERS,
    !!product?.payment?.method_optional && ProductOptionsTypes.OPTIONAL_CARD_DETAILS,
    !!product?.allows_past_slots && ProductOptionsTypes.ALLOWS_PAST_SLOTS,
    !!product?.user_can_cancel && ProductOptionsTypes.USER_CAN_CANCEL,
    !!product?.user_can_reschedule && ProductOptionsTypes.USER_CAN_RESCHEDULE,
    !!product?.enable_customizations_quantity_factor && ProductOptionsTypes.ENABLE_CUSTOMIZATIONS_QUANTITY_FACTOR,
    !!product?.show_customizations_price && ProductOptionsTypes.SHOW_CUSTOMIZATIONS_PRICE
  ].filter(Boolean) as ProductOptionsTypes[];

  return defaultValue;
};

const ProductOptions: FC<ProductOptionsProps> = ({ formOptions, product, hasFromFlag, serviceType, subscriptionType }) => {
  const { control, setValue, errors } = formOptions;
  const defaultChargeStatus = isProductNoCharge(product) ? CHARGE_STATUS.NO_CHARGE : isProductPostpaid(product) ? CHARGE_STATUS.CHARGE_LATER : CHARGE_STATUS.CHARGE_NOW;

  const watchedChargeStatus = useWatch({
    control,
    name: 'charge_status',
    defaultValue: defaultChargeStatus
  });

  const { booking_type } = useWatch({ control, name: ['booking_type'], defaultValue: { booking_type: product?.booking_type } });

  const isChargeLater = watchedChargeStatus === CHARGE_STATUS.CHARGE_LATER;
  const isNoCharge = watchedChargeStatus === CHARGE_STATUS.NO_CHARGE;

  const defaultValue = getProductOptionsDefaultValue({ product, hasFromFlag });

  const watchedProductOptions = useWatchProductOptions({ product, hasFromFlag, formOptions });

  const isMultiday = booking_type === BOOKING_TYPE.MULTI_DAY;
  const isMultiSlot = booking_type === BOOKING_TYPE.MULTI_SLOT;

  const isAutoConfirmOrder = watchedProductOptions.includes(ProductOptionsTypes.AUTO_CONFIRM_ORDER);

  const items = [
    {
      id: ProductOptionsTypes.FROM,
      name: 'From flag'
    },
    (serviceType || subscriptionType) && {
      id: ProductOptionsTypes.AUTOMATICALLY_PICK_BEST_DISCOUNT,
      name: 'Automatically pick best discount'
    },
    (serviceType || subscriptionType) && {
      id: ProductOptionsTypes.USE_PETS_COUNT,
      name: 'Use pets count'
    },
    serviceType && {
      id: ProductOptionsTypes.AUTO_CONFIRM_ORDER,
      name: 'Auto Confirm Order'
    },
    serviceType &&
      (isNoCharge || isChargeLater || !isAutoConfirmOrder) &&
      !isMultiday &&
      !isMultiSlot && {
        id: ProductOptionsTypes.ALLOWS_REPEATED_ORDERS,
        name: 'Allow repeated orders'
      },
    serviceType &&
      (isNoCharge || isChargeLater || !isAutoConfirmOrder) &&
      !isMultiday &&
      !isMultiSlot && {
        id: ProductOptionsTypes.ALLOWS_BULK_ORDERS,
        name: 'Allow multiple orders'
      },
    serviceType &&
      (isNoCharge || isChargeLater) && {
        id: ProductOptionsTypes.OPTIONAL_CARD_DETAILS,
        name: 'Card Detail not Required'
      },
    serviceType && {
      id: ProductOptionsTypes.ALLOWS_PAST_SLOTS,
      name: 'Allow Booking in the Past (For Business Use)'
    },
    serviceType && {
      id: ProductOptionsTypes.USER_CAN_CANCEL,
      name: 'Allow users to Cancel booking (Outside the No-cancellation Window)'
    },
    serviceType && {
      id: ProductOptionsTypes.USER_CAN_RESCHEDULE,
      name: 'Allow users to reschedule booking (Outside the No-reschedule Window)'
    },
    serviceType &&
      isMultiday && {
        id: ProductOptionsTypes.ENABLE_CUSTOMIZATIONS_QUANTITY_FACTOR,
        name: 'Enable Variation Price Per Night'
      },
    serviceType && {
      id: ProductOptionsTypes.SHOW_CUSTOMIZATIONS_PRICE,
      name: 'Show Customization Price'
    }
  ].filter(Boolean) as { id: string; name: string }[];

  useLayoutEffect(() => {
    if (!isChargeLater || isMultiday || isMultiSlot) {
      setValue(
        'productOptions',
        watchedProductOptions.filter(option => option !== ProductOptionsTypes.ALLOWS_REPEATED_ORDERS && option !== ProductOptionsTypes.ALLOWS_BULK_ORDERS)
      );
    }
  }, [watchedChargeStatus, booking_type]);

  return (
    <WideInputGroup>
      <Controller
        control={control}
        name={`productOptions`}
        defaultValue={defaultValue}
        render={({ onChange, value }) => <FormCheckbox error={errors?.productOptions} column itemsArray={items} onChange={onChange} value={value || []} fontSize={14} />}
      />
    </WideInputGroup>
  );
};

export const useWatchProductOptions = ({ product, hasFromFlag, formOptions }: { product: Product; hasFromFlag: boolean; formOptions: ReturnType<typeof useForm> }) => {
  const { control } = formOptions;
  const defaultValue = getProductOptionsDefaultValue({ product, hasFromFlag });

  const watchedProductOptions = useWatch({
    control,
    name: 'productOptions',
    defaultValue
  });

  return watchedProductOptions;
};

export default ProductOptions;
