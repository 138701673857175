import { useQuery } from '@apollo/client';
import React, { FC, useEffect, useMemo, useState } from 'react';
import Colors from '../../Colors';
import useMediaQuery from '../../hooks/useMediaQuery';
import { GetBusUserProfile } from '../../queries';
import { vars } from '../../reactive';
import Diet from '../../views/Pets/Diet/Diet';
import Health from '../../views/Pets/Health/Health';
import PetForms from '../../views/Pets/PetForms/PetForms';
import Daycare from '../../views/Pets/Reports/Daycare/Daycare';
import Grooming from '../../views/Pets/Reports/Grooming/Grooming';
import Medical from '../../views/Pets/Reports/Medical/Medical';
import Sitting from '../../views/Pets/Reports/Sitting/Sitting';
import Training from '../../views/Pets/Reports/Training/Training';
import Walking from '../../views/Pets/Reports/Walking/Walking';
import { Divider } from '../DrawerBar/styled';
import { DRAWER_IDS } from '../DrawerBar/types';
import { ExtensionBody, ExtensionContainer, TabContainer } from '../Pets/styled';
import { Pet } from '../Pets/types';
import PetProfileInfo from '../Profile/PetProfileInfo';
import TabsHeader from '../Shared/TabsHeader';
const { ENV } = require('../../utils/config');

//TODO change this after permissions on branches
export const VET_BRANCHES = [
  // Vet in the city
  '221d3ffa-e7d8-4ece-a672-bdfabb428ecc',
  // Collar Store
  '014ef20d-33e4-4333-a5b3-2ca0d5d1ddc1',
  // Alphas Canine Care
  '58344757-1127-45f8-b303-3a8202c24bd2',
  // The Wag Club - West Hampstead
  '49cc4fbf-2ee8-4182-82d7-242a1813f1da',
  // The Wag Club - Golders Green
  'a1b8d818-021b-4f81-ab19-8ef7aed5dc9e',
  // The Wag Club - Muswell Hill
  'f50f7551-0700-4dd1-8ff3-81f5f448588e',
  // Snoots
  '483c7a7d-547d-465e-9d8e-bad86d9d4793',
  // The Paw and Whistle
  '05870340-e6ee-4f35-aebc-0ae9d734e384'
] as const;

export const TRAINER_BRANCHES = [
  // Country pooch
  '4ccecc7a-8c87-4730-b0ad-c6e18d2d12df'
] as const;

export enum PETS_EXTENSTION_TABS {
  SUMMARY = 'SUMMARY',
  MEDICAL = 'MEDICAL',
  GROOMING = 'GROOMING',
  TRAINING = 'TRAINING',
  WALKING = 'WALKING',
  SITTING = 'SITTING',
  DAYCARE = 'DAYCARE',
  FORMS = 'FORMS',
  DIET = 'DIET'
}

export const isVetBranch = (branchId: string) => VET_BRANCHES.includes(branchId as any) || ENV === 'development';

export const isTrainerBranch = (branchId: string) => TRAINER_BRANCHES.includes(branchId as any) || ENV === 'development';

type PetExtensionProps = {
  petProfile: Pet;
  onContainerScroll?: React.UIEventHandler<HTMLDivElement>;
};

const PetExtension: FC<PetExtensionProps> = ({ petProfile, onContainerScroll }) => {
  const drawerBar = vars.drawerBars().find(drawer => drawer.drawerId === DRAWER_IDS.PETS_DRAWER);
  const { data: { getBusUserProfile: profile = {} } = {} } = useQuery(GetBusUserProfile);
  const isVet = isVetBranch(profile?.Branch?.id);
  const isTrainer = isTrainerBranch(profile?.Branch?.id);

  const { mobile } = useMediaQuery({ mobile: true });

  const tabs = useMemo(() => {
    const { SUMMARY, MEDICAL, GROOMING, TRAINING, FORMS, DAYCARE, SITTING, WALKING, DIET } = PETS_EXTENSTION_TABS;
    // if (isVet) {
    //   return [SUMMARY, MEDICAL, GROOMING, TRAINING, WALKING, SITTING, DAYCARE, FORMS];
    // }

    // if (isTrainer) {
    //   return [SUMMARY, TRAINING, FORMS];
    // }

    return [SUMMARY, DIET, MEDICAL, GROOMING, TRAINING, WALKING, SITTING, DAYCARE, FORMS];
    // return [SUMMARY, GROOMING, TRAINING, WALKING, SITTING, DAYCARE, FORMS];
  }, [isVet, isTrainer]);

  const tabsToRender = mobile ? [PETS_EXTENSTION_TABS.SUMMARY, ...tabs.slice(1)] : tabs;

  const [currentTab, setCurrentTab] = useState(drawerBar?.otherData?.selectedTab || tabsToRender[0]);

  useEffect(() => {
    if (drawerBar?.otherData?.selectedTab) {
      setCurrentTab(drawerBar?.otherData?.selectedTab);
    }
  }, [drawerBar?.otherData?.selectedTab]);

  return (
    <ExtensionContainer>
      {mobile && <Divider />}
      <TabsHeader
        tabs={Object.values(tabsToRender)}
        selected={currentTab}
        setSelected={setCurrentTab}
        backgroundColor={Colors.white}
        higherRadius={mobile}
        actionsPadding={mobile ? '16px 16px' : '20px'}
        mobilePadding="0 0"
        mobileFontSize={16}
      />
      {mobile && <Divider />}
      <ExtensionBody onScroll={onContainerScroll}>
        <TabContainer onScroll={onContainerScroll}>
          {currentTab === PETS_EXTENSTION_TABS.SUMMARY && (mobile ? <PetProfileInfo profile={petProfile} /> : <Health />)}
          {currentTab === PETS_EXTENSTION_TABS.MEDICAL && <Medical />}
          {currentTab === PETS_EXTENSTION_TABS.GROOMING && <Grooming />}
          {currentTab === PETS_EXTENSTION_TABS.TRAINING && <Training />}
          {currentTab === PETS_EXTENSTION_TABS.WALKING && <Walking />}
          {currentTab === PETS_EXTENSTION_TABS.SITTING && <Sitting />}
          {currentTab === PETS_EXTENSTION_TABS.DAYCARE && <Daycare />}
          {currentTab === PETS_EXTENSTION_TABS.FORMS && <PetForms />}
          {currentTab === PETS_EXTENSTION_TABS.DIET && <Diet />}
        </TabContainer>
      </ExtensionBody>
    </ExtensionContainer>
  );
};

export default PetExtension;
