import { useMutation, useQuery } from '@apollo/client';
import React, { FC, useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { sentenceCase } from 'sentence-case';
import { DRAWER_IDS } from '../../../../components/DrawerBar/types';
import ModalDialog from '../../../../components/Modal/ModalDialog';
import { ModalBody, ModalFooter } from '../../../../components/Modal/styled';
import { FormButtonsContainer, FormError, FormInput, FormLabel, FormSelect, FormSubmitButton, FormTextArea, WideInputGroup } from '../../../../components/Shared/Forms/Forms';
import { Container } from '../../../../components/Shared/Shared';
import { ActivityIndicator } from '../../../../components/Shared/Spinner';
import useIcons from '../../../../hooks/useIcons';
import useReduceAppointments from '../../../../hooks/useReduceAppointments';
import { DaycareReportAdd, DaycareReportEdit, GetBranchAppointments, GetBranchPetProfile } from '../../../../queries';
import { vars } from '../../../../reactive';
import { toReadableDate } from '../../../../utils/dates';
import { BOOKING_STATUS_TYPES, BOOKING_TYPE } from '../../../Bookings/types';
import { ActionBtn } from '../../../Store/styled';
import { NOTE_ACTION_TYPES } from './DaycareReportNoteAction';

export const DaycareReportModal: FC<{ selectedReport?: Record<string, any>; refetchReports?: ReturnType<typeof useQuery>['refetch'] }> = ({ selectedReport, refetchReports }) => {
  const { control, handleSubmit, errors } = useForm();
  const drawerBars = vars.drawerBars();
  const drawer = drawerBars.find(drawerBar => drawerBar.drawerId === DRAWER_IDS.PETS_DRAWER);
  const petId = drawer?.recordData;
  const orderId = drawer?.otherData?.orderId;

  // get pet profile
  const { data: { getBranchPetProfile: petProfile = {} } = {}, loading: loadingPetProfile } = useQuery(GetBranchPetProfile, {
    variables: { id: petId }
  });

  // get past pet appointments

  const { data: { getBranchAppointments: appUserAppointments = [] } = {}, loading: loadingAppUserAppointments } = useQuery(GetBranchAppointments, {
    variables: {
      PetRecordId: [petProfile?.PetRecord?.id],
      status: [BOOKING_STATUS_TYPES.CONFIRMED, BOOKING_STATUS_TYPES.REQUESTED],
      limit: 50,
      offset: 0,
      booking_type: [BOOKING_TYPE.SLOT, BOOKING_TYPE.MULTI_SLOT, BOOKING_TYPE.MULTI_DAY],
      requisite_queries: [],
      alternative_queries: []
    }
  });

  const reducedAppointments = useReduceAppointments(appUserAppointments || []);
  const [handleAddDaycareReport, { loading: loadingAdd, error: errorAdd }] = useMutation(DaycareReportAdd);
  const [handleEditDaycareReport, { loading: loadingEdit, error: errorEdit }] = useMutation(DaycareReportEdit);

  const handleSave = handleSubmit(async form => {
    const { appointmentId } = form;
    if (selectedReport) {
      await handleEditDaycareReport({ variables: { ...form, id: selectedReport?.id, appointmentId: appointmentId || null } });
    } else {
      await handleAddDaycareReport({ variables: { ...form, petRecordId: petProfile.PetRecord.id, appointmentId: appointmentId || null } });
    }

    ModalDialog.closeModal();
    refetchReports?.();
  });

  const defaultAppointment = appUserAppointments.find(appointment => appointment.OrderItem.Order.id === orderId) || {};

  if (loadingPetProfile || loadingAppUserAppointments) {
    return <ActivityIndicator />;
  }

  return (
    <>
      <ModalBody>
        <Container>
          <WideInputGroup>
            <FormLabel>Name</FormLabel>
            <Controller as={<FormInput error={errors.name} />} control={control} name={'name'} defaultValue={selectedReport?.name || ''} rules={{ required: true }} rows={6} />
            {errors?.name && <FormError>{errors.name.message || 'name is required'}</FormError>}
          </WideInputGroup>
          <WideInputGroup>
            <FormLabel error={errors?.appointmentId}>Appointment</FormLabel>
            <Controller
              render={({ onChange, value }) => (
                <FormSelect
                  height={48}
                  fontSize={16}
                  error={errors?.appointmentId}
                  onChange={e => {
                    onChange(e.target.value);
                  }}
                  value={value || ''}
                >
                  <option value={''}>Not specified</option>
                  {reducedAppointments?.map((orders, index) => (
                    <option key={index} value={orders[0].id}>
                      {orders[0].OrderItem.item.name} - {toReadableDate(orders[0].timestamp || new Date(), { isLocale: true })}
                    </option>
                  ))}
                </FormSelect>
              )}
              control={control}
              name={'appointmentId'}
              defaultValue={defaultAppointment?.id || selectedReport?.Appointment?.id || ''}
            />
          </WideInputGroup>
          <WideInputGroup>
            <FormLabel>Description</FormLabel>
            <Controller as={<FormTextArea error={errors.description} />} control={control} name={'description'} defaultValue={selectedReport?.body?.description || ''} rows={6} />
            {errors.description && <FormError>{errors.description.message || 'description is required'}</FormError>}
          </WideInputGroup>
        </Container>
      </ModalBody>
      <ModalFooter>
        <FormButtonsContainer>
          <FormSubmitButton error={!!errorAdd?.message || !!errorEdit?.message} loading={loadingAdd || loadingEdit} onClick={handleSave}>
            Save report
          </FormSubmitButton>
        </FormButtonsContainer>
      </ModalFooter>
    </>
  );
};

const DaycareReportAction: FC<{
  type: NOTE_ACTION_TYPES;
  selectedReport?: Record<string, any>;
  refetchReports?: ReturnType<typeof useQuery>['refetch'];
  onClose?: () => void;
  autoOpenModal?: boolean;
}> = ({ type, selectedReport, refetchReports, onClose, autoOpenModal }) => {
  const icons = useIcons();
  const icon = type === NOTE_ACTION_TYPES.ADD ? icons.add.childImageSharp.gatsbyImageData.images.fallback.src : icons.edit.childImageSharp.gatsbyImageData.images.fallback.src;

  const showModal = useCallback(
    () =>
      ModalDialog.openModal({
        content: () => <DaycareReportModal selectedReport={selectedReport} refetchReports={refetchReports} />,
        title: `${sentenceCase(type)} report`,
        onClose
      }),
    [selectedReport, refetchReports, onClose, type]
  );

  useEffect(() => {
    if (autoOpenModal) {
      showModal();
    }
  }, [autoOpenModal, showModal]);

  return <ActionBtn bgImage={icon} onClick={showModal} />;
};

export default DaycareReportAction;
