import React, { FC, useCallback } from 'react';
import { vars } from '../../../reactive';
import { isUserSuperVisorOrAdminOrSuper } from '../../../utils/helpers';
import { BlockedCalendarEvent, CalendarEvent, CalendarEvents } from '../../../views/Bookings/types';
import { BranchAppointmentTag } from '../../../views/Store/BranchTags/types';
import { BusUserProfile } from '../../Profile/types';
import BlockedSlot from '../SlotCalendar/BlockedSlot';
import MultiDayAppointment from '../SlotCalendar/MultiDayAppointment';
import SlotAppointment from '../SlotCalendar/SlotAppointment';
import { DayColumn, HourDiv } from '../styled';
import { getMultiDayBookingsForDay, getNumberOfRowsForTags, groupMultiDayBookingsByTags } from '../utils';

type SlotCalendarProps = {
  weekDay: Date;
  dayIndex: number;
  weekDays: Date[];
  bookingCardColors: string[];
  eventsToRender?: CalendarEvents;
  multiDayBookingType: boolean;
  profile: BusUserProfile;
  handleBookingClick: ({ appointmentsIds, group }: { appointmentsIds?: string[]; group?: boolean }) => void;
  currentCalendarLength: number;
  hoursLength: number;
  renderSmallCalendar: boolean;
  renderMediumCalendar: boolean;
  filterByTags?: BranchAppointmentTag[];
};

const SlotCalendar: FC<SlotCalendarProps> = ({
  weekDay,
  dayIndex,
  weekDays,
  bookingCardColors,
  eventsToRender,
  multiDayBookingType,
  profile,
  handleBookingClick,
  hoursLength,
  renderSmallCalendar,
  renderMediumCalendar,
  filterByTags
}) => {
  const firstDayOfView = weekDays[0];
  const lastDayOfView = weekDays[weekDays.length - 1];
  const singleDayBookings = eventsToRender?.singleDayAppointmentsViews[dayIndex]?.views || [];
  const multiDayBookingsViews = eventsToRender?.multiDayAppointmentsViews || [];

  const multiDayBookings = getMultiDayBookingsForDay({ multiDayBookings: multiDayBookingsViews, firstDayOfView, weekDay });

  const filterByTagsIds = filterByTags?.map(tag => tag.id) || [];

  const multiDayAppointmentsForTagsToRender = groupMultiDayBookingsByTags({ multiDayBookings: multiDayBookingsViews, filterByTagsIds });

  const blockedSlots = eventsToRender?.blockedSlotsViews?.[dayIndex]?.views || [];

  const renderMultiDayBookings = useCallback(
    ({ calendarIndexOffset = () => 0, calendarEvents }: { calendarEvents: CalendarEvent[]; calendarIndexOffset?: (event: CalendarEvent) => number }) => {
      return (
        multiDayBookingType &&
        calendarEvents.map(calendarEvent => {
          const calendarIndex = calendarIndexOffset(calendarEvent);
          const color = bookingCardColors[calendarIndex % bookingCardColors.length];
          const assingedTo = isUserSuperVisorOrAdminOrSuper(profile) ? calendarEvent.BusUsers?.map(user => user.name).join(', ') : 'You & Others';

          return (
            <MultiDayAppointment
              key={calendarEvent.appointmentsIds[0]}
              calendarEvent={calendarEvent}
              calendarIndex={calendarIndex}
              color={color}
              dayIndex={dayIndex}
              assignedTo={assingedTo}
              firstDayOfView={firstDayOfView}
              lastDayOfView={lastDayOfView}
              handleBookingClick={handleBookingClick}
              small={renderSmallCalendar}
              medium={renderMediumCalendar}
            />
          );
        })
      );
    },
    [multiDayBookingType, bookingCardColors, dayIndex, firstDayOfView, lastDayOfView, profile, renderSmallCalendar, handleBookingClick]
  );

  const numberOfRowsForTags = getNumberOfRowsForTags({ filterByTags, multiDayAppointmentsForTagsToRender });

  const tagsAppointmetns = filterByTagsIds.map((tagId, index) => {
    const bookingsIds = multiDayAppointmentsForTagsToRender?.[tagId] || [];
    const bookings = multiDayBookingsViews.filter(event => bookingsIds?.includes(event.appointmentsIds[0])) || [];
    const multiDayAppointmentsForTags = getMultiDayBookingsForDay({ multiDayBookings: bookings, firstDayOfView, weekDay });

    const allPreviousTags = filterByTagsIds.slice(0, index);
    const previousTagRows = numberOfRowsForTags?.filter(row => allPreviousTags.includes(row.tagId)).reduce((acc, row) => acc + row.currentTagRows, 0);
    const currentTagRows = numberOfRowsForTags?.find(row => row.tagId === tagId)!.currentTagRows;

    const calendarIndexOffset = (booking: CalendarEvent) => previousTagRows + bookingsIds?.findIndex(id => id === booking.appointmentsIds[0]) || 0;

    return { previousTagRows, currentTagRows, render: () => renderMultiDayBookings({ calendarEvents: multiDayAppointmentsForTags, calendarIndexOffset }) };
  });

  const renderBorders = (houridx: number) => {
    if (!multiDayBookingType) {
      return true;
    }
    if (!filterByTags) {
      return false;
    }

    const filterByTagsBorders = tagsAppointmetns.some(({ currentTagRows, previousTagRows }) => currentTagRows + previousTagRows === houridx + 1);

    return filterByTagsBorders;
  };

  return (
    <DayColumn>
      {new Array(hoursLength).fill('').map((_, houridx) => (
        <HourDiv
          key={houridx}
          onClick={() => !multiDayBookingType && vars.selectedDate({ date: weekDay, hour: houridx - 1 })}
          renderBorders={renderBorders(houridx)}
          noHover={multiDayBookingType}
          smallSize={renderSmallCalendar}
          mediumSize={renderMediumCalendar}
        />
      ))}

      {!multiDayBookingType &&
        singleDayBookings.map((calendarEvent, index) => {
          const color = bookingCardColors[(index + dayIndex) % bookingCardColors.length];

          const assignedTo = isUserSuperVisorOrAdminOrSuper(profile) ? calendarEvent.BusUsers?.map(user => user.name).join(', ') : 'You & Others';
          return (
            <SlotAppointment
              key={calendarEvent.appointmentsIds[0]}
              calendarEvent={calendarEvent}
              onBookingClick={handleBookingClick}
              color={color}
              dayIndex={dayIndex}
              assignedTo={assignedTo}
              mediumSize={!renderSmallCalendar}
            />
          );
        })}

      {!filterByTags &&
        renderMultiDayBookings({ calendarEvents: multiDayBookings, calendarIndexOffset: event => multiDayBookingsViews.findIndex(bkng => event.appointmentsIds.includes(bkng.appointmentsIds[0])) })}

      {!!filterByTags && !!filterByTagsIds?.length && tagsAppointmetns.map(({ render }) => render())}

      {!multiDayBookingType &&
        blockedSlots.map((slot, index) => {
          const onBlockedSlotClick = (slot: BlockedCalendarEvent) => {
            const date = weekDay;
            const hour = new Date(slot.timestamp).getUTCHours();
            const minute = new Date(slot.timestamp).getUTCMinutes();
            vars.selectedDate({ date, hour, minute });
          };

          return <BlockedSlot key={index} slot={slot} dayIndex={index} onBlockedSlotClick={onBlockedSlotClick} mediumSize={!renderSmallCalendar} />;
        })}
    </DayColumn>
  );
};

export default SlotCalendar;
