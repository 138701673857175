import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { FILTERS_ID, SelectedFilter } from './config';
import { FILTER_TYPES, SelectedMoreOptions } from './Filters';

type useFiltersProps = {
  setSelectedFilters: (selectedFilters: SelectedFilter[]) => void;
  toCall: { called: boolean; get: () => void; id: FILTER_TYPES }[];
  addHeaderAction: ({ id, action }: { id: string; action: JSX.Element }) => void;
  clearHeaderAction: ({ id }: { id: string }) => void;
};
const useFilters = ({ setSelectedFilters, toCall, addHeaderAction, clearHeaderAction }: useFiltersProps) => {
  const selectedFiltersForm = useForm({
    mode: 'onTouched'
  });
  const REQUISITE_ID = 'REQUISITE';
  const { control } = selectedFiltersForm;

  const watchedValues = useWatch({ control });
  useEffect(() => {
    if (!watchedValues?.[FILTERS_ID]?.length) {
      return;
    }

    toCall.forEach(({ called, get, id }) => {
      if (!called && watchedValues?.[FILTERS_ID]?.includes(id)) {
        get();
      }
    });
  }, [toCall.map(({ called }) => called), watchedValues]);

  return {
    watchedValues,
    selectedFiltersForm,
    REQUISITE_ID,
    setSelectedFilters,
    addHeaderAction,
    clearHeaderAction
  };
};

export default useFilters;
