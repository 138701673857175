import React from 'react';
import { PetRecordTagPill, PetRecordTagPillsContainer } from '../../components/Pets/styled';
import { BOOKINGS_FILTER_TYPES, BOOKINGS_MORE_OPTIONS_TYPES, SelectedBookingsFilter } from '../../components/Shared/Filters/config';
import { getBookingFilter, getBookingFilterMoreOptionsValues as getMoreOptions } from '../../components/Shared/Filters/helpers';
import { RecordBody } from '../../views/styled';
import { BranchAppointmentTag, BranchPetRecordTag } from '../Store/BranchTags/types';
import { BookingOrder } from './types';

const BookingRecordFilters = ({ booking, selectedFilters }: { booking: BookingOrder; selectedFilters: SelectedBookingsFilter[] }) => {
  const getFilter = (filter: BOOKINGS_FILTER_TYPES) => getBookingFilter(selectedFilters, filter);

  // ----SEARCH----
  const searchFilter = getFilter(BOOKINGS_FILTER_TYPES.BOOKING_SEARCH);
  const searchFilterValue = getMoreOptions(searchFilter, BOOKINGS_MORE_OPTIONS_TYPES.BOOKING_SEARCH);

  // ----TAGS----
  const tagsFilter = getFilter(BOOKINGS_FILTER_TYPES.BOOKING_TAGS);
  const quickTagsFilter = getFilter(BOOKINGS_FILTER_TYPES.BOOKING_QUICK_TAGS);
  const tagsFilterValue = getMoreOptions(tagsFilter, BOOKINGS_MORE_OPTIONS_TYPES.BOOKING_TAGS);
  const quickTagsFilterValue = getMoreOptions(quickTagsFilter, BOOKINGS_MORE_OPTIONS_TYPES.BOOKING_TAGS);
  const bookingTags: BranchAppointmentTag[] = booking?.map(b => b.BranchAppointmentTags || []).flat();
  const allTags = [...new Set([...(tagsFilterValue || []), ...(quickTagsFilterValue || [])])];
  const selectedBookingTags = bookingTags?.filter(tag => allTags?.includes(tag?.id));

  // ----PET TAGS----
  const petTagsFilter = getFilter(BOOKINGS_FILTER_TYPES.BOOKING_PETS_TAGS);
  const petTagsFilterValue = getMoreOptions(petTagsFilter, BOOKINGS_MORE_OPTIONS_TYPES.BOOKING_PETS_TAGS);
  const bookingPetTags: BranchPetRecordTag[] = booking?.map(b => b?.PetRecord?.BranchPetRecordTags || []).flat();
  const allPetTags = [...new Set([...(petTagsFilterValue || [])])];
  const selectedBookingPetTags = bookingPetTags?.filter(tag => allPetTags?.includes(tag?.id));

  // ----BUS USERS----
  const busUsersFilter = getFilter(BOOKINGS_FILTER_TYPES.BOOKING_BUS_USERS);
  const busUsersFilterValue = getMoreOptions(busUsersFilter, BOOKINGS_MORE_OPTIONS_TYPES.BOOKING_BUS_USERS);
  const bookingBusUsers = booking?.map(b => b?.BusUsers || []).flat();
  const allBusUsers = [...new Set([...(busUsersFilterValue || [])])];
  const selectedBookingBusUsers = bookingBusUsers?.filter(busUser => allBusUsers?.includes(busUser?.id));

  return (
    <>
      {!!busUsersFilterValue && <RecordBody>{selectedBookingBusUsers.map(i => i.name).join(' & ')}</RecordBody>}

      {!!petTagsFilterValue && (
        <RecordBody>
          <PetRecordTagPillsContainer>
            {selectedBookingPetTags?.map((item, index) => (
              <PetRecordTagPill key={index} color={item.color}>
                <span>{item.name}</span>
              </PetRecordTagPill>
            ))}
          </PetRecordTagPillsContainer>
        </RecordBody>
      )}
    </>
  );
};

export default BookingRecordFilters;
