import { useLazyQuery, useQuery, useReactiveVar } from '@apollo/client';
import React, { FC, Fragment, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Filters from '../../../components/Shared/Filters/Filters';
import SearchBar from '../../../components/Shared/Filters/SearchBar';
import { BOOKINGS_FILTER_TYPES, BOOKINGS_MORE_OPTIONS_TYPES, FILTERS_ID } from '../../../components/Shared/Filters/config';
import useFilters from '../../../components/Shared/Filters/useFilters';
import OptionDropdown from '../../../components/Shared/Menus/OptionDropdown/OptionDropdown';
import { OPTION_DROPDOWN_MENU_BUTTON_TYPES, OPTION_DROPDOWN_TYPES } from '../../../components/Shared/Menus/OptionDropdown/types';
import { TabsHeaderContainer } from '../../../components/Shared/Shared';
import TabsHeader from '../../../components/Shared/TabsHeader';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { GetBranchUnreadItemsCountReturn } from '../../../hooks/useUnreadBranchItemsCount';
import { GetAllServices, GetBranchAppointmentTags, GetBranchPetRecordTags, GetBranchUnreadItemsCount, GetBusUserProfile } from '../../../queries';
import { vars } from '../../../reactive';
import { manageBookingsFilters } from '../../../reactive/actions';
import { FULL_DAYS, SUN_THREE_DAYS } from '../../../utils/dates';
import { areObjectsEqual } from '../../../utils/helpers';
import { Divider } from '../../Pets/Health/styled';
import { BranchAppointmentTag } from '../../Store/BranchTags/types';
import { BOOKING_RECORD_VIEW_SETTINGS, BOOKING_TABS_TYPES, BOOKING_TABS_TYPES_LABELS } from '../types';
import BookingTagsQuickFilter from './BookingTagsQuickFilter';

const BOOKINGS_FILTER_TYPES_NAMES: Record<BOOKINGS_FILTER_TYPES, string> = {
  [BOOKINGS_FILTER_TYPES.BOOKING_TAGS]: 'Tags',
  [BOOKINGS_FILTER_TYPES.BOOKING_QUICK_TAGS]: '',
  [BOOKINGS_FILTER_TYPES.BOOKING_PETS_TAGS]: 'Pets Tags',
  [BOOKINGS_FILTER_TYPES.BOOKING_BUS_USERS]: 'Staff Member',
  [BOOKINGS_FILTER_TYPES.BOOKING_PRODUCT_NAME]: 'Service Name',
  [BOOKINGS_FILTER_TYPES.BOOKING_SEARCH]: 'Search',
  [BOOKINGS_FILTER_TYPES.BOOKING_WEEKDAY]: 'Weekday'
};

const MORE_OPTIONS_TITLES: Record<BOOKINGS_MORE_OPTIONS_TYPES, string> = {
  [BOOKINGS_MORE_OPTIONS_TYPES.BOOKING_TAGS]: 'Tags',
  [BOOKINGS_MORE_OPTIONS_TYPES.BOOKING_PETS_TAGS]: 'Pets Tags',
  [BOOKINGS_MORE_OPTIONS_TYPES.BOOKING_BUS_USERS]: 'Staff Member',
  [BOOKINGS_MORE_OPTIONS_TYPES.BOOKING_PRODUCT_NAME]: 'Service Name',
  [BOOKINGS_MORE_OPTIONS_TYPES.BOOKING_SEARCH]: 'Search',
  [BOOKINGS_MORE_OPTIONS_TYPES.BOOKING_WEEKDAY]: 'Weekday'
};

const MORE_OPTIONS_DROPDOWN_TYPES: Record<BOOKINGS_MORE_OPTIONS_TYPES, OPTION_DROPDOWN_TYPES> = {
  [BOOKINGS_MORE_OPTIONS_TYPES.BOOKING_TAGS]: OPTION_DROPDOWN_TYPES.MULTI_SELECT,
  [BOOKINGS_MORE_OPTIONS_TYPES.BOOKING_PETS_TAGS]: OPTION_DROPDOWN_TYPES.MULTI_SELECT,
  [BOOKINGS_MORE_OPTIONS_TYPES.BOOKING_BUS_USERS]: OPTION_DROPDOWN_TYPES.MULTI_SELECT,
  [BOOKINGS_MORE_OPTIONS_TYPES.BOOKING_PRODUCT_NAME]: OPTION_DROPDOWN_TYPES.MULTI_SELECT,
  [BOOKINGS_MORE_OPTIONS_TYPES.BOOKING_SEARCH]: OPTION_DROPDOWN_TYPES.TEXT_INPUT,
  [BOOKINGS_MORE_OPTIONS_TYPES.BOOKING_WEEKDAY]: OPTION_DROPDOWN_TYPES.MULTI_SELECT
};

const BOOKINGS_FILTER_TYPES_UNITS: Record<BOOKINGS_FILTER_TYPES, string> = {
  [BOOKINGS_FILTER_TYPES.BOOKING_TAGS]: '',
  [BOOKINGS_FILTER_TYPES.BOOKING_QUICK_TAGS]: '',
  [BOOKINGS_FILTER_TYPES.BOOKING_PETS_TAGS]: '',
  [BOOKINGS_FILTER_TYPES.BOOKING_BUS_USERS]: '',
  [BOOKINGS_FILTER_TYPES.BOOKING_PRODUCT_NAME]: '',
  [BOOKINGS_FILTER_TYPES.BOOKING_SEARCH]: '',
  [BOOKINGS_FILTER_TYPES.BOOKING_WEEKDAY]: ''
};

type BookingsHeaderProps = {
  selectedTabState: [BOOKING_TABS_TYPES, React.Dispatch<React.SetStateAction<BOOKING_TABS_TYPES>>];
  tabs: BOOKING_TABS_TYPES[];
};

const BookingsHeader: FC<BookingsHeaderProps> = ({ selectedTabState, tabs }) => {
  const { addFilter, addHeaderAction, clearHeaderAction, removeFilter, setNewVisibleFilters } = manageBookingsFilters;
  const [selected, setSelected] = selectedTabState;
  const { mobile } = useMediaQuery({ mobile: true });

  const [getAllTags, { data: { getBranchAppointmentTags: allTags = [] } = {}, called: calledTags }] = useLazyQuery<{ getBranchAppointmentTags: BranchAppointmentTag[] }>(GetBranchAppointmentTags, {
    fetchPolicy: 'cache-and-network'
  });

  const [getAllPetsTags, { data: { getBranchPetRecordTags: allPetsTags = [] } = {}, called: calledPetsTags }] = useLazyQuery<{ getBranchPetRecordTags: BranchAppointmentTag[] }>(
    GetBranchPetRecordTags,
    {
      fetchPolicy: 'cache-and-network'
    }
  );

  const [getBusUsers, { data: { getBusUserProfile: { Branch: { BusUsers = [] } = {} } = {} } = {}, called: calledBusUsers }] = useLazyQuery(GetBusUserProfile, {
    fetchPolicy: 'cache-and-network'
  });

  const [getAllServices, { data: { getProducts: services = [] } = {}, called: calledServices }] = useLazyQuery(GetAllServices, {
    fetchPolicy: 'cache-and-network'
  });

  const toCall = [
    { called: calledTags, get: () => getAllTags({ variables: { offset: 0, limit: 1000 } }), id: BOOKINGS_FILTER_TYPES.BOOKING_TAGS },
    { called: calledPetsTags, get: () => getAllPetsTags({ variables: { offset: 0, limit: 1000 } }), id: BOOKINGS_FILTER_TYPES.BOOKING_PETS_TAGS },
    { called: calledBusUsers, get: () => getBusUsers({ variables: { offset: 0, limit: 1000 } }), id: BOOKINGS_FILTER_TYPES.BOOKING_BUS_USERS },
    { called: calledServices, get: () => getAllServices({ variables: { offset: 0, limit: 1000 } }), id: BOOKINGS_FILTER_TYPES.BOOKING_PRODUCT_NAME }
  ];

  const filtersControl = useFilters({
    setSelectedFilters: setNewVisibleFilters,
    toCall,
    addHeaderAction: addHeaderAction,
    clearHeaderAction: clearHeaderAction
  });

  const BOOKINGS_FILTER_TYPES_MORE_OPTIONS: Record<BOOKINGS_FILTER_TYPES, BOOKINGS_MORE_OPTIONS_TYPES[]> = {
    [BOOKINGS_FILTER_TYPES.BOOKING_TAGS]: [BOOKINGS_MORE_OPTIONS_TYPES.BOOKING_TAGS],
    [BOOKINGS_FILTER_TYPES.BOOKING_QUICK_TAGS]: [BOOKINGS_MORE_OPTIONS_TYPES.BOOKING_TAGS],
    [BOOKINGS_FILTER_TYPES.BOOKING_PETS_TAGS]: [BOOKINGS_MORE_OPTIONS_TYPES.BOOKING_PETS_TAGS],
    [BOOKINGS_FILTER_TYPES.BOOKING_BUS_USERS]: [BOOKINGS_MORE_OPTIONS_TYPES.BOOKING_BUS_USERS],
    [BOOKINGS_FILTER_TYPES.BOOKING_PRODUCT_NAME]: [BOOKINGS_MORE_OPTIONS_TYPES.BOOKING_PRODUCT_NAME],
    [BOOKINGS_FILTER_TYPES.BOOKING_SEARCH]: [BOOKINGS_MORE_OPTIONS_TYPES.BOOKING_SEARCH],
    [BOOKINGS_FILTER_TYPES.BOOKING_WEEKDAY]: [BOOKINGS_MORE_OPTIONS_TYPES.BOOKING_WEEKDAY]
  };

  const MORE_OPTIONS_ITEMS: Record<BOOKINGS_MORE_OPTIONS_TYPES, { value: string | boolean | number; name: string }[] | []> = {
    [BOOKINGS_MORE_OPTIONS_TYPES.BOOKING_TAGS]: allTags?.map(({ id, name }: { id: string; name: string }) => ({ value: id, name })),
    [BOOKINGS_MORE_OPTIONS_TYPES.BOOKING_PETS_TAGS]: allPetsTags?.map(({ id, name }: { id: string; name: string }) => ({ value: id, name })),
    [BOOKINGS_MORE_OPTIONS_TYPES.BOOKING_BUS_USERS]: BusUsers?.map(({ id, name }: { id: string; name: string }) => ({ value: id, name })),
    [BOOKINGS_MORE_OPTIONS_TYPES.BOOKING_PRODUCT_NAME]: services?.map(({ id, name }: { id: string; name: string }) => ({ value: id, name })),
    [BOOKINGS_MORE_OPTIONS_TYPES.BOOKING_SEARCH]: [],
    [BOOKINGS_MORE_OPTIONS_TYPES.BOOKING_WEEKDAY]: SUN_THREE_DAYS.map((_day, index) => ({ value: index, name: FULL_DAYS[index] }))
  };

  const filterItems = Object.values(BOOKINGS_FILTER_TYPES).map(filter => ({
    name: BOOKINGS_FILTER_TYPES_NAMES[filter],
    value: filter,
    unit: BOOKINGS_FILTER_TYPES_UNITS[filter],
    moreOptions: BOOKINGS_FILTER_TYPES_MORE_OPTIONS[filter].map(option => ({
      type: option,
      filterType: filter,
      id: `${filter}.${option}`,
      title: MORE_OPTIONS_TITLES[option] ? `${MORE_OPTIONS_TITLES[option]} Filter` : '',
      optionType: MORE_OPTIONS_DROPDOWN_TYPES[option],
      items: MORE_OPTIONS_ITEMS[option]
    }))
  }));

  const quickTagFilter = filterItems.find(({ value }) => value === BOOKINGS_FILTER_TYPES.BOOKING_QUICK_TAGS)!;

  const searchFilter = filterItems.find(({ value }) => value === BOOKINGS_FILTER_TYPES.BOOKING_SEARCH)!;
  const searchMoreOptions = searchFilter.moreOptions.find(({ type }) => type === BOOKINGS_MORE_OPTIONS_TYPES.BOOKING_SEARCH)!;

  const onBookingSearch = (query: string) => {
    if (query) {
      addFilter({
        ...searchFilter,
        moreOptions: [{ ...searchMoreOptions, values: query }],
        requisite: 'true'
      });
      return;
    }
    removeFilter(searchFilter.value);
  };

  const HeaderViewSettings = useCallback(() => {
    const selectedItemsForm = useForm({
      defaultValues: {
        viewSettings: vars.activeViewSettings()?.record ? [vars.activeViewSettings().record].flat() : [BOOKING_RECORD_VIEW_SETTINGS[0].id]
      }
    });
    const { watch } = selectedItemsForm;
    const watchedValues = watch('viewSettings');

    useEffect(() => {
      vars.activeViewSettings({ record: watchedValues });
    }, [watchedValues]);

    return (
      <OptionDropdown
        menuButtonType={OPTION_DROPDOWN_MENU_BUTTON_TYPES.THREE_DOT}
        selectedItemsForm={selectedItemsForm}
        options={[
          {
            title: 'View Settings',
            id: 'viewSettings',
            optionType: OPTION_DROPDOWN_TYPES.CHECKBOX,
            items: BOOKING_RECORD_VIEW_SETTINGS.map(({ id, title }) => ({
              value: id,
              name: title
            }))
          }
        ]}
        noApplyButton
      />
    );
  }, []);

  const Header = useCallback(() => {
    const headerActions = useReactiveVar(vars.bookingsHeaderActions);

    const { data: unreadOrdersCount } = useQuery<{ getBranchUnreadItemsCount: GetBranchUnreadItemsCountReturn }>(GetBranchUnreadItemsCount, {
      fetchPolicy: 'cache-only',
      notifyOnNetworkStatusChange: true
    });

    const { unreadServiceOrdersCount } = unreadOrdersCount?.getBranchUnreadItemsCount || {};

    const filterHeaderActions = headerActions.filter(({ id }) => id !== FILTERS_ID && id !== 'bulkSelectAll');
    const filtersHeaderAction = headerActions.find(({ id }) => id === FILTERS_ID)?.action;
    const actions = (
      <>
        {filterHeaderActions.map(({ action, id }) => (
          <Fragment key={id}>{action}</Fragment>
        ))}
        {!mobile && filtersHeaderAction}
      </>
    );

    return (
      <TabsHeader
        tabs={tabs}
        tabsTitles={BOOKING_TABS_TYPES_LABELS}
        notifications={{
          [BOOKING_TABS_TYPES.NEW]: unreadServiceOrdersCount || 0
        }}
        selected={selected}
        setSelected={setSelected}
        actions={
          <>
            {!mobile && (
              <>
                <SearchBar onSearch={onBookingSearch} defaultText="Search Bookings" showAlways />
                {actions}
              </>
            )}
            <HeaderViewSettings />
          </>
        }
        actionsWidth={600}
      />
    );
  }, [selected, setSelected, tabs]);

  const HeaderBottom = useCallback(() => {
    const headerActions = useReactiveVar(vars.bookingsHeaderActions);
    const bulkSelectAll = headerActions.find(({ id }) => id === 'bulkSelectAll')?.action;
    return <>{bulkSelectAll && <TabsHeaderContainer padding="0 32px 0 32px">{bulkSelectAll}</TabsHeaderContainer>}</>;
  }, []);

  return (
    <>
      <Header />
      {!mobile && <Filters filtersControl={filtersControl} filterItems={filterItems} />}

      <TabsHeaderContainer padding="0 32px 0 32px" mobilePadding="10px 16px 0 16px" style={{ flexDirection: 'column' }}>
        {mobile && <Divider color="#F2F2F2" />}
        <BookingTagsQuickFilter quickTagFilter={quickTagFilter} />
        {mobile && <Divider color="#F2F2F2" />}
      </TabsHeaderContainer>
      {mobile && <SearchBar onSearch={onBookingSearch} showAlways defaultText="Pet, owner, email, service or tags" />}
      <HeaderBottom />
    </>
  );
};

export default React.memo(BookingsHeader, areObjectsEqual);
