import React, { FC, useState } from 'react';
import { Controller } from 'react-hook-form';
import Colors from '../../../../Colors';
import { getBranchCurrencySymbol } from '../../../../utils/getBranchCurrencySymbol';
import { isProductPostpaid } from '../../../../views/Store/ProductPrice';
import { ModalBody, ModalFooter } from '../../../Modal/styled';
import {
  FormButtonsContainer,
  FormCheckbox,
  FormError,
  FormInput,
  FormLabel,
  FormSelect,
  FormSubmitButton,
  InputContainer,
  InputsWrapper,
  RadioBtnsGroup,
  WideInputGroup,
  selectTheme
} from '../../Forms/Forms';
import AppUsersList from '../../Lists/AppUsersList';
import { Container } from '../../Shared';
import { VerticallyCenteredLoader } from '../../Spinner';
import OrderGroups from './OrderGroups';
import PreOrderSummary from './PreOrderSummary';
import { NewBookingModalFormProps, getProductsItems } from './utils';
import ReactSelect from 'react-select';

const NewBookingModalForm: FC<NewBookingModalFormProps> = newBookingModalFormProps => {
  const {
    loadingStartOrder,
    onSubmit,
    formOptions,
    appUserProfileResponse,
    selectedProductsResponse,
    allPets,
    selectedOrderGroups,
    defaultValues,
    orderGroups,
    productModalType,
    discounts,
    vouchers,
    busUsers
  } = newBookingModalFormProps;

  const [preOrderSummaryForAppUserItems, setPreOrderSummaryForAppUserItems] = useState({
    loadingSummary: false,
    preOrderSummaryForAppUser: {
      isAlreadySubscribed: false
    },
    shouldRenderFees: false
  });

  const { loadingSummary, preOrderSummaryForAppUser, shouldRenderFees } = preOrderSummaryForAppUserItems;

  const { control, errors, handleSubmit, watch } = formOptions;
  const { loadingAppUserProfile } = appUserProfileResponse;
  const { selectedProducts } = selectedProductsResponse;
  const selectedProduct = selectedProducts[0];
  const subscriptionProduct = selectedProduct?.type === 'subscription';
  const { defaultAppUserId, defaultPets } = defaultValues;

  const watchedValues = watch(['overrideTotal', 'total', 'discountId']);

  const watchedOrderGroups = watch('orderGroups');

  const isMultiOrder = selectedOrderGroups.length > 1;

  const [{ isRecurring }] = getProductsItems({ orderGroups: watchedOrderGroups, products: selectedProducts }) || [{}];

  const submitHandler = handleSubmit!(values => {
    if (loadingStartOrder) {
      return;
    }
    onSubmit(values);
  });

  const postpaidProduct = isProductPostpaid(selectedProduct);

  return (
    <>
      <ModalBody>
        <Container width={500}>
          <InputsWrapper noWrap>
            <OrderGroups {...newBookingModalFormProps} />
          </InputsWrapper>
          <WideInputGroup>
            <FormLabel error={errors?.appUserId?.message}>Client</FormLabel>
            <AppUsersList formOptions={formOptions as any} defaultValues={defaultAppUserId ? [defaultAppUserId] : []} />
            {errors?.appUserId && <FormError>{errors?.appUserId.message || 'Please select a client'}</FormError>}
          </WideInputGroup>
          {!productModalType && (
            <WideInputGroup>
              <FormLabel error={(Object.values(errors?.pets || {}) || [])?.find(e => e?.message)?.message}>Pets {!allPets?.length && !loadingAppUserProfile && '(Select a client)'}</FormLabel>
              {loadingAppUserProfile && <VerticallyCenteredLoader size={15} />}
              {!loadingAppUserProfile && (
                <InputContainer marginBottom={20}>
                  <Controller
                    control={control}
                    name={`pets`}
                    defaultValue={defaultPets}
                    render={({ onChange, value }) => (
                      <FormCheckbox error={(Object.values(errors?.pets || {}) || [])?.find(e => e?.message)?.message} itemsArray={allPets} onChange={onChange} value={value} />
                    )}
                    rules={{
                      required: true,
                      validate: (value: any) => {
                        if (value.length === 0) {
                          return 'Please select at least one pet';
                        }
                      }
                    }}
                  />
                </InputContainer>
              )}
              {errors?.pets && <FormError>{(Object.values(errors?.pets || {}) || []).find(e => e?.message)?.message || 'Please select at least one pet'}</FormError>}
            </WideInputGroup>
          )}
          {!watchedValues.overrideTotal && !isRecurring && !isMultiOrder && (
            <>
              <WideInputGroup>
                <FormLabel error={errors?.discountId?.message}>Discount</FormLabel>
                <Controller
                  render={({ onChange, value }) => (
                    <FormSelect
                      height={48}
                      fontSize={16}
                      name={'discountId'}
                      error={errors?.discountId?.message}
                      onChange={e => {
                        onChange(e.target.value);
                      }}
                      value={value || ''}
                    >
                      <option value={''}>-- No Discount --</option>
                      {discounts?.map(({ id, name }) => (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      ))}
                    </FormSelect>
                  )}
                  control={control}
                  name={'discountId'}
                  defaultValue={''}
                />
                {errors?.discountId && <FormError>{errors?.discountId.message || 'Please select a discount'}</FormError>}
              </WideInputGroup>
              {watchedValues.discountId && (
                <WideInputGroup>
                  <FormLabel error={errors?.voucherId?.message}>Voucher</FormLabel>
                  <Controller
                    render={({ onChange, value }) => (
                      <FormSelect
                        height={48}
                        fontSize={16}
                        name={'voucherId'}
                        error={errors?.voucherId?.message}
                        onChange={e => {
                          onChange(e.target.value);
                        }}
                        value={value || ''}
                      >
                        <option value={''}>-- No Voucher --</option>
                        {vouchers?.map(({ id, code }) => (
                          <option key={id} value={id}>
                            {code}
                          </option>
                        ))}
                      </FormSelect>
                    )}
                    control={control}
                    name={'voucherId'}
                    defaultValue={''}
                  />
                  {errors?.voucherId && <FormError>{errors?.voucherId.message || 'Please select a voucher'}</FormError>}
                </WideInputGroup>
              )}
            </>
          )}
          {!subscriptionProduct && !productModalType && (
            <WideInputGroup>
              <FormLabel>Staff Members</FormLabel>
              <Controller
                render={({ onChange, value }) => (
                  <ReactSelect
                    theme={selectTheme}
                    onChange={onChange}
                    isMulti
                    value={value}
                    options={busUsers}
                    getOptionValue={busUser => busUser?.id}
                    getOptionLabel={busUser => busUser?.name}
                    name={'busUserId'}
                  />
                )}
                control={control}
                name={'busUserId'}
                defaultValue={[]}
              />
            </WideInputGroup>
          )}
          <InputsWrapper>
            {!watchedValues.overrideTotal && <PreOrderSummary {...newBookingModalFormProps} setPreOrderSummaryForAppUserItems={setPreOrderSummaryForAppUserItems} />}
            <WideInputGroup>
              <InputContainer isRow flex={1} marginBottom={20}>
                <Controller
                  render={({ onChange, value }) => (
                    <RadioBtnsGroup
                      options={['Override total']}
                      defaultValue={''}
                      onChange={() => {
                        onChange(!value);
                      }}
                      itemStyle={{ width: '100%' }}
                      name={`overrideTotal`}
                      inputType={'checkbox'}
                    />
                  )}
                  control={control}
                  name={`overrideTotal`}
                  defaultValue={false}
                />
              </InputContainer>
              {watchedValues.overrideTotal && (
                <InputContainer marginBottom={20}>
                  <FormLabel error={errors?.total?.message}>Total</FormLabel>
                  {isMultiOrder && (
                    <FormLabel>
                      *Note that this total will be applied to all orders ({getBranchCurrencySymbol()}
                      {watchedValues.total} x {orderGroups.fields.length})
                    </FormLabel>
                  )}
                  <Controller
                    as={<FormInput error={!!errors?.total} type={'number'} height={32} fontSize={14} />}
                    control={control}
                    name={'total'}
                    rules={{ required: true, min: { value: 0, message: 'Total must be greater than 0' } }}
                    defaultValue={0}
                  />
                  {errors?.total && <FormError>{errors?.total.message || 'Total is required'}</FormError>}
                </InputContainer>
              )}
            </WideInputGroup>
            {!subscriptionProduct && !productModalType && (
              <WideInputGroup>
                <InputContainer isRow flex={1} marginBottom={20}>
                  <Controller
                    render={({ onChange, value }) => (
                      <RadioBtnsGroup
                        options={['Ignore stock']}
                        defaultValue={''}
                        onChange={() => {
                          onChange(!value);
                        }}
                        itemStyle={{ width: '100%' }}
                        name={`ignoreStock`}
                        inputType={'checkbox'}
                      />
                    )}
                    control={control}
                    name={`ignoreStock`}
                    defaultValue={false}
                  />
                  <FormLabel>({selectedProduct?.stock ? `Stock: ${selectedProduct?.stock}` : 'No stock'})</FormLabel>
                </InputContainer>
              </WideInputGroup>
            )}
            {!subscriptionProduct && !productModalType && !postpaidProduct && !isRecurring && (
              <WideInputGroup>
                <InputContainer isRow flex={1} marginBottom={20}>
                  <Controller
                    render={({ onChange, value }) => (
                      <RadioBtnsGroup
                        options={['Auto Confirm Payment']}
                        defaultValue={''}
                        onChange={() => {
                          onChange(!value);
                        }}
                        itemStyle={{ width: '100%' }}
                        name={`autoConfirm`}
                        inputType={'checkbox'}
                      />
                    )}
                    control={control}
                    name={`autoConfirm`}
                    defaultValue={false}
                  />
                </InputContainer>
                <FormLabel>Select this option to charge the client now</FormLabel>
              </WideInputGroup>
            )}
            <WideInputGroup>
              <InputContainer isRow flex={1} marginBottom={20}>
                <Controller
                  render={({ onChange, value }) => (
                    <RadioBtnsGroup
                      options={['Disable notifications']}
                      defaultValue={''}
                      onChange={() => {
                        onChange(!value);
                      }}
                      itemStyle={{ width: '100%' }}
                      name={`disableNotifications`}
                      inputType={'checkbox'}
                    />
                  )}
                  control={control}
                  name={`disableNotifications`}
                  defaultValue={false}
                />
              </InputContainer>
            </WideInputGroup>
          </InputsWrapper>
        </Container>
      </ModalBody>
      <ModalFooter bgColor={Colors.white}>
        <FormButtonsContainer>
          <FormSubmitButton
            loading={loadingStartOrder}
            onClick={submitHandler}
            disabled={!shouldRenderFees || preOrderSummaryForAppUser?.isAlreadySubscribed || (subscriptionProduct && loadingSummary)}
          >
            {subscriptionProduct && loadingSummary ? 'Loading ...' : preOrderSummaryForAppUser?.isAlreadySubscribed ? 'Already subscribed' : 'Confirm'}
          </FormSubmitButton>
        </FormButtonsContainer>
      </ModalFooter>
    </>
  );
};

export default NewBookingModalForm;
