import React, { useEffect } from 'react';
import { GetBranchAppUsers } from '../../../queries';
import Common from '../Common';
import InfiniteList from '../../../components/Shared/InfiniteList/InfiniteList';
import { DRAWER_IDS } from '../../../components/DrawerBar/types';
import useSetDrawerOnNavigation from '../../../hooks/useSetDrawerOnNavigation';
import usePaginatedQuery from '../../../hooks/usePaginatedQuery';
import useNavigateDrawer from '../../../hooks/useNavigateDrawer';
import BranchBillingRecord from './BranchBillingRecord';
import { BranchAppUser, CurrentPeriod } from './types';

const BranchBillingAppUsers = ({ period }: { period: CurrentPeriod }) => {
  const [[hasMoreItems, setHasMoreItems], queryResult] = usePaginatedQuery<BranchAppUser[]>({
    query: GetBranchAppUsers,
    otherVariables: {
      billed_on_from: period.start,
      billed_on_to: period.end,
      timestamp_from: period.start,
      timestamp_to: period.end,
      appUserCounts_timestamp_from: period.start,
      appUserCounts_timestamp_to: period.end
    }
  });

  const { data: { getBranchAppUsers: branchAppUsers = [] } = {}, loading, fetchMore, refetch } = queryResult;

  useSetDrawerOnNavigation({
    itemList: branchAppUsers,
    drawerId: DRAWER_IDS.USER_DRAWER,
    otherParams: { isExtended: true }
  });

  const navigateDrawer = useNavigateDrawer({
    drawerData: {
      drawerId: DRAWER_IDS.USER_DRAWER,
      otherParams: { isExtended: true }
    }
  });

  useEffect(() => {
    Common.set(`BranchAppUsers.GetBranchAppUsers.refetch`, () => {
      refetch?.().then(() => setHasMoreItems(true));
    });
  }, []);

  return (
    <InfiniteList
      list={branchAppUsers}
      itemRenderer={(appUser: BranchAppUser) => <BranchBillingRecord key={appUser.id} branchAppUser={appUser} navigateDrawer={navigateDrawer} />}
      hasMoreItems={hasMoreItems}
      loading={loading}
      fetchMore={fetchMore}
      offset={branchAppUsers?.length}
      setHasMoreItems={setHasMoreItems}
    />
  );
};

export default BranchBillingAppUsers;
