import React, { FC } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { FormError, FormInput, FormLabel, FormSelect, WideInputGroup } from '../../components/Shared/Forms/Forms';
import { CHARGE_STATUS, CHARGE_TYPE, Product, chargeStatuses } from './types';
import { ProductOptionsTypes } from './ProductOptions';

type ProductPriceProps = {
  product: Product;
  formOptions: ReturnType<typeof useForm>;
  hideCharge?: boolean;
};

export const getChargeType = (chargeStatus: CHARGE_STATUS, productOptions?: Record<ProductOptionsTypes, boolean>) => {
  const { charge_type } = chargeStatuses[chargeStatus] || {};
  const isNoCharge = chargeStatus === CHARGE_STATUS.NO_CHARGE;
  const shouldBePostpaid = productOptions && (productOptions?.allows_repeated_orders || productOptions?.allows_bulk_orders);
  if (isNoCharge && shouldBePostpaid) {
    return CHARGE_TYPE.POSTPAID;
  }
  return charge_type;
};

export const isProductNoCharge = (product: Product) => {
  return +product?.original_price === 0;
};

export const isProductPostpaid = (product: Product) => {
  return product?.charge_type === CHARGE_TYPE.POSTPAID;
};

const ProductPrice: FC<ProductPriceProps> = ({ product, formOptions, hideCharge }) => {
  const { control, errors, setValue } = formOptions;

  const defaultChargeStatus = isProductNoCharge(product) ? CHARGE_STATUS.NO_CHARGE : isProductPostpaid(product) ? CHARGE_STATUS.CHARGE_LATER : CHARGE_STATUS.CHARGE_NOW;

  const watchedChargeStatus = useWatch({
    control,
    name: 'charge_status',
    defaultValue: defaultChargeStatus
  });

  const selectedNoCharge = watchedChargeStatus === CHARGE_STATUS.NO_CHARGE;

  const isSubscription = product?.type === 'subscription';

  return (
    <>
      {!hideCharge && (
        <WideInputGroup>
          <FormLabel>Charge</FormLabel>
          <Controller
            render={({ onChange, value }) => (
              <FormSelect
                height={48}
                fontSize={16}
                name={'charge_status'}
                onChange={e => {
                  const selectedChargeStatus = e.target.value as CHARGE_STATUS;
                  const { value } = chargeStatuses[selectedChargeStatus];
                  if (value === CHARGE_STATUS.NO_CHARGE) {
                    setValue('original_price', 0);
                    setValue('price', 0);
                  }

                  onChange(value);
                }}
                value={value || ''}
              >
                {Object.values(chargeStatuses).map(({ value, label }) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </FormSelect>
            )}
            control={control}
            name={'charge_status'}
            defaultValue={defaultChargeStatus || ''}
          />
        </WideInputGroup>
      )}

      {!selectedNoCharge && (
        <>
          <WideInputGroup>
            <FormLabel>Original Price</FormLabel>
            <Controller
              as={<FormInput error={errors.original_price} type={'number'} height={32} fontSize={16} flexBasis={78} min={isSubscription ? 0.5 : 0} max={9999} />}
              control={control}
              name={'original_price'}
              defaultValue={product?.original_price || ''}
              rules={{
                required: true,
                min: isSubscription ? 0.5 : 0,
                max: 9999
              }}
            />
            {errors.original_price && <FormError>{errors.original_price.message || 'Original Price is required'}</FormError>}
          </WideInputGroup>
          <WideInputGroup>
            <FormLabel>Discounted Price</FormLabel>
            <Controller
              as={<FormInput error={errors.price} type={'number'} height={32} fontSize={16} flexBasis={78} min={isSubscription ? 0.5 : 0} max={9999} />}
              control={control}
              name={'price'}
              defaultValue={product?.price || ''}
              rules={{
                min: isSubscription ? 0.5 : 0,
                max: 9999
              }}
            />
            {errors.price && <FormError>{errors.price.message || 'Discounted Price is invalid'}</FormError>}
          </WideInputGroup>
        </>
      )}
    </>
  );
};

export default ProductPrice;
