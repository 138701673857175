import styled, { css } from 'styled-components';
import Colors from '../../../Colors';

export const OperationMaxSlotsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;
`;

export const OperationMaxSlots = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 42px;
  height: 100%;
  gap: 26px;
  margin-bottom: 16px;
`;
export const OperationMaxSlotContainer = styled.div`
  width: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const DiscountsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  max-width: 600px;
  margin-bottom: 20px;
`;

export const DiscountsFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
  padding: 10px;
`;

export const DiscountsFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  margin-bottom: 10px;
`;

export const CustomizationFormOptionsWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const CustomizationFormOptionsContainer = styled.div<{ column?: boolean }>`
  display: flex;
  flex-direction: ${({ column }) => (column ? 'column' : 'row')};
  width: 100%;
  flex: 1;
`;

export const CustomizationInputContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const CenteredCustomizationInputContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;

export const CustomizationTypeContainer = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
`;

export const DiscountVoucherEditButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border: none;
  background-color: transparent;
  color: ${Colors.primary};
  cursor: pointer;
  outline: none;
`;

export const VoucherModalContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-height: 80vh;
  box-shadow: 0 3px 25px 0 rgba(0, 0, 0, 0.1);
`;

export const VoucherContent = styled.div<{ width: number; flex?: number }>`
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 10px 0;
  ${({ width }) =>
    width &&
    css`
      width: ${width}px
      max-width: ${width}px;
      min-width: ${width}px;
  `};
  flex: ${({ flex }) => flex};
`;

export const VoucherContainer = styled.div<{ gap: number }>`
  display: flex;
  align-items: flex-start;
  gap: ${({ gap }) => gap}px;
  padding: 6px 10px;
  cursor: pointer;
  border-radius: 12px;
  width: 800px;
  flex: 1;
  &:hover {
    background-color: ${Colors.grey};
  }
`;

export const VouchersContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
  overflow-x: scroll;
  margin-bottom: 10px;
`;
