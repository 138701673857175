import { useQuery } from '@apollo/client';
import React, { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ReactSelect from 'react-select';
import ModalDialog from '../../../components/Modal/ModalDialog';
import { ModalBody, ModalFooter } from '../../../components/Modal/styled';
import { ORDER_INVOICE_STATUS } from '../../../components/OrderInvoices/types';
import { BusUserProfile } from '../../../components/Profile/types';
import { FormButtonsContainer, FormInput, FormLabel, FormSubmitButton, InputGroup, InputsWrapper, WideInputGroup, selectTheme } from '../../../components/Shared/Forms/Forms';
import AppUsersList from '../../../components/Shared/Lists/AppUsersList';
import { Container } from '../../../components/Shared/Shared';
import useDownload from '../../../hooks/useDownload';
import { GetBranchAppUserTags, GetBusUserProfile } from '../../../queries';
import { toReadableDate } from '../../../utils/dates';
import { BranchAppUserTag } from '../BranchTags/types';

type OrderInvoiceDownloadFormValues = {
  AppUserId: string[];
  billed_on_from: string;
  billed_on_to: string;
  status: 'ALL' | 'BILLED' | 'UNBILLED' | 'PENDING_REJECTED';
  BranchAppUserTagId: string[];
};

type OrderInvoiceDownloadModalProps = {
  initialValues?: Partial<{ AppUserId: string[]; unbilled: boolean; status?: ORDER_INVOICE_STATUS[] | null; billed_on_from: string; billed_on_to: string; BranchAppUserTagId: string[] }>;
};

const OrderInvoicesDownloadModal: FC<OrderInvoiceDownloadModalProps> = ({ initialValues }) => {
  const { data: { getBusUserProfile: busUserProfile } = {} } = useQuery<{ getBusUserProfile: BusUserProfile }>(GetBusUserProfile, {
    fetchPolicy: 'cache-only'
  });

  const formOptions = useForm<OrderInvoiceDownloadFormValues>({
    defaultValues: {
      AppUserId: initialValues?.AppUserId || [],
      billed_on_from: initialValues?.billed_on_from ? new Date(initialValues?.billed_on_from).toISOString().split('T')[0] : '',
      billed_on_to: initialValues?.billed_on_to ? new Date(initialValues?.billed_on_to).toISOString().split('T')[0] : '',
      status: initialValues?.unbilled ? 'UNBILLED' : 'ALL',
      BranchAppUserTagId: initialValues?.BranchAppUserTagId || []
    }
  });

  const { control, handleSubmit, watch } = formOptions;

  const watchedValues = watch(['billed_on_from']);

  const [downloadInvoices, { loading, error }] = useDownload<
    Partial<{
      offset: number;
      limit: number;
      AppUserId: string[];
      billed_on_from: string;
      billed_on_to: string;
      OrderInvoiceId: string[];
      status: string[] | null;
      unbilled: boolean;
      BranchAppUserTagId: string[];
    }>
  >({
    type: 'invoices',
    includeProviderBranchId: true,
    fileFormat: 'csv'
  });

  const onSubmit = handleSubmit(async values => {
    const fileName = `${busUserProfile?.Branch?.name}${values.billed_on_from ? ` ${toReadableDate(values.billed_on_from, { noTime: true })}` : ''}${
      values.billed_on_to ? ` - ${toReadableDate(values.billed_on_to, { noTime: true })}` : ''
    }${values.AppUserId?.length ? ' Client' : ''} Invoices`;

    await downloadInvoices({
      variables: {
        ...(values.billed_on_from ? { billed_on_from: new Date(new Date(values.billed_on_from).setUTCHours(0, 0, 0, 0)).toISOString() } : {}),
        ...(values.billed_on_to ? { billed_on_to: new Date(new Date(values.billed_on_to).setUTCHours(23, 59, 59, 999)).toISOString() } : {}),
        status: ['UNBILLED', 'ALL'].includes(values.status) ? null : values.status === 'PENDING_REJECTED' ? ['PAYMENT_PENDING', 'PAYMENT_REJECTED'] : ['PAYMENT_SUCCEEDED'],
        ...(values.AppUserId?.length && { AppUserId: values.AppUserId }),
        ...(values.status === 'UNBILLED' && { unbilled: true }),
        ...(values.BranchAppUserTagId?.length && { BranchAppUserTagId: values.BranchAppUserTagId })
      },
      fileName
    });

    requestAnimationFrame(() => {
      ModalDialog.closeModal();
    });
  });

  const { data: { getBranchAppUserTags: allTags = [] } = {}, loading: loadingAllQuickTags } = useQuery<{
    getBranchAppUserTags: BranchAppUserTag[];
  }>(GetBranchAppUserTags, {
    fetchPolicy: 'cache-and-network',
    variables: {
      offset: 0,
      limit: 1000
    }
  });

  const billedOnToMin = watchedValues?.billed_on_from ? new Date(watchedValues?.billed_on_from)?.toISOString().split('T')[0] : '';

  return (
    <>
      <ModalBody>
        <Container width={600}>
          <InputsWrapper>
            <WideInputGroup>
              <FormLabel>Clients</FormLabel>
              <AppUsersList isMulti formOptions={formOptions} name="AppUserId" defaultValues={initialValues?.AppUserId} />
            </WideInputGroup>
            <WideInputGroup>
              <FormLabel>Status</FormLabel>
              <Controller
                render={({ onChange, value }) => (
                  <ReactSelect
                    options={[
                      { label: 'All', value: 'ALL' },
                      { label: 'Paid', value: 'BILLED' },
                      { label: 'Unpaid', value: 'UNBILLED' },
                      { label: 'Pending/Rejected', value: 'PENDING_REJECTED' }
                    ]}
                    value={{ label: value === 'ALL' ? 'All' : value === 'BILLED' ? 'Paid' : value === 'UNBILLED' ? 'Unpaid' : 'Pending/Rejected', value }}
                    onChange={option => onChange(option?.value)}
                    theme={selectTheme}
                  />
                )}
                control={control}
                name={'status'}
                defaultValue={initialValues?.unbilled ? 'UNBILLED' : 'ALL'}
              />
            </WideInputGroup>
            <WideInputGroup>
              <FormLabel>Client Tags</FormLabel>
              <Controller
                render={({ onChange, value }) => (
                  <ReactSelect
                    options={allTags.map(tag => ({ label: tag.name, value: tag.id }))}
                    onChange={tags => onChange(tags?.map(tag => tag.value))}
                    isMulti
                    theme={selectTheme}
                    value={[...(value || [])]?.map(tagId => allTags.find(tag => tag.id === tagId)).map(tag => ({ label: tag?.name, value: tag?.id })) || []}
                  />
                )}
                control={control}
                name={'BranchAppUserTagId'}
                defaultValue={initialValues?.BranchAppUserTagId || []}
              />
            </WideInputGroup>
            <WideInputGroup marginTop={20} flexDirection="row" gap={10}>
              <InputGroup>
                <FormLabel>From</FormLabel>
                <Controller
                  as={<FormInput type={'date'} height={32} fontSize={16} />}
                  control={control}
                  name={'billed_on_from'}
                  defaultValue={initialValues?.billed_on_from ? new Date(initialValues?.billed_on_from).toISOString().split('T')[0] : ''}
                />
              </InputGroup>
              <InputGroup>
                <FormLabel>To</FormLabel>
                <Controller
                  as={<FormInput type={'date'} height={32} fontSize={16} min={billedOnToMin} disabled={!watchedValues?.billed_on_from} />}
                  control={control}
                  name={'billed_on_to'}
                  defaultValue={initialValues?.billed_on_to ? new Date(initialValues?.billed_on_to).toISOString().split('T')[0] : ''}
                />
              </InputGroup>
            </WideInputGroup>
          </InputsWrapper>
        </Container>
      </ModalBody>
      <ModalFooter>
        <FormButtonsContainer>
          <FormSubmitButton error={!!error} loading={loading} onClick={() => onSubmit()}>
            Download
          </FormSubmitButton>
        </FormButtonsContainer>
      </ModalFooter>
    </>
  );
};

export default OrderInvoicesDownloadModal;
