import React, { useState } from 'react';
import FormTree, { removeFormTrunkWithOneTwig, TwigComponentProps } from '../../../components/Shared/FormTree';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { FormError, FormInput, FormLabel, FormSelect, InputContainer } from '../../../components/Shared/Forms/Forms';
import { getKeys } from '../../../utils/helpers';
import transformDeliveryCodesState from '../transformDeliveryState';
import useWatchFormState from '../../../hooks/useWatchFormState';

const RowComponent = ({ data, errors, control, idx, twig_idx }: TwigComponentProps) => {
  const [rule, setRule] = useWatchFormState({
    defaultValue: data?.rule ? getKeys(data.rule)[0] : 'none'
  });

  return (
    <>
      <InputContainer width={35}>
        <FormLabel>Rule</FormLabel>
        <Controller
          render={({ onChange }) => (
            <FormSelect
              error={errors[`custom_type_delivery_${idx}_delivery_rule_${twig_idx}`]}
              height={38}
              fontSize={16}
              defaultValue={data?.rule || 'none'}
              onChange={e => {
                onChange(e.target.value);
                setRule(e.target.value);
              }}
            >
              <option value="min_total">Min Total</option>
              <option value="min_weight">Min Weight</option>
              <option value="none">None</option>
            </FormSelect>
          )}
          control={control}
          name={`custom_type_delivery_${idx}_delivery_rule_${twig_idx}`}
          defaultValue={data?.rule || 'none'}
          rules={{ required: true }}
        />
        {errors[`custom_type_delivery_${idx}_delivery_rule_${twig_idx}`] && <FormError>{errors[`custom_type_delivery_${idx}_delivery_rule_${twig_idx}`].message || 'Rule is required'}</FormError>}
      </InputContainer>
      <InputContainer width={35} style={rule === 'none' ? { display: 'none' } : {}}>
        <FormLabel>Value</FormLabel>
        <Controller
          as={<FormInput type={'number'} error={errors[`custom_type_delivery_${idx}_delivery_value_${twig_idx}`]} height={20} fontSize={16} />}
          control={control}
          name={`custom_type_delivery_${idx}_delivery_value_${twig_idx}`}
          defaultValue={data?.price || 0}
          rules={rule !== 'none' ? { required: true } : {}}
        />
        {errors[`custom_type_delivery_${idx}_delivery_value_${twig_idx}`] && <FormError>{errors[`custom_type_delivery_${idx}_delivery_value_${twig_idx}`].message || 'Value is required'}</FormError>}
      </InputContainer>

      <InputContainer width={30}>
        <FormLabel>Charges</FormLabel>
        <Controller
          as={<FormInput type={'number'} error={errors[`custom_type_delivery_${idx}_charges_${twig_idx}`]} height={20} fontSize={16} />}
          control={control}
          name={`custom_type_delivery_${idx}_charges_${twig_idx}`}
          defaultValue={data?.charges || 0}
          rules={{ required: true }}
        />
        {errors[`custom_type_delivery_${idx}_charges_${twig_idx}`] && <FormError>{errors[`custom_type_delivery_${idx}_charges_${twig_idx}`].message || 'Charges is required'}</FormError>}
      </InputContainer>
    </>
  );
};

type DeliveryProps = {
  control: Control;
  errors: FieldErrors;
  defaultValue: any;
};

const Delivery = ({ control, errors, defaultValue }: DeliveryProps): JSX.Element => {
  const [customTypes, setCustomTypes] = useState<any>(defaultValue ? defaultValue : []);

  const addNewType = () => {
    const currentState = transformDeliveryCodesState(control.getValues());
    setCustomTypes([...currentState, { description: '', price: 0, rule: {} }]);
  };

  const handleRemoveType = (type, idx) => {
    const filteredTypes = customTypes.filter(customType => customType.description !== type);
    removeFormTrunkWithOneTwig(idx, customTypes.length, control, getFieldNames);
    setCustomTypes(filteredTypes);
  };

  return (
    <FormTree
      title={'delivery'}
      newTrunkTitle={'Add New Option'}
      newTwigTitle={'Add New Rule'}
      trunks={customTypes.map((type: any, idx: number) => ({
        title: 'Option',
        value: type?.description,
        twigs: [{ rule: getKeys(type?.rule || {})[0], charges: type.price, price: type.rule?.[getKeys(type.rule || {})[0]] || 0 }]
      }))}
      TwigComponent={RowComponent}
      control={control}
      errors={errors}
      addNewTrunk={addNewType}
      handleRemoveTrunk={handleRemoveType}
    />
  );
};

export default Delivery;

const getFieldNames = (idx: number, rowIdx: number) => [
  `custom_type_delivery_${idx}`,
  `custom_type_delivery_${idx}_delivery_rule_${rowIdx}`,
  `custom_type_delivery_${idx}_delivery_value_${rowIdx}`,
  `custom_type_delivery_${idx}_charges_${rowIdx}`
];
