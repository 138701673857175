import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { Controller, UseFormMethods } from 'react-hook-form';
import CreatableSelect from 'react-select/creatable';
import { FormLabel, WideInputGroup, selectTheme } from '../../../components/Shared/Forms/Forms';
import { AddBranchTagCategory, GetBranchTagCategories } from '../../../queries';
import { FormData } from './BranchTagsModal';
import { BranchTag } from './types';

const BranchTagCategories = ({ formOptions, branchTag, defaultValue: defaultCategory }: { formOptions: UseFormMethods<FormData>; branchTag?: BranchTag; defaultValue?: string | null }) => {
  const { control } = formOptions;
  const { data: { getBranchTagCategories: categories = [] } = {}, loading, refetch: refetchCategories } = useQuery<{ getBranchTagCategories: { id: string; name: string }[] }>(GetBranchTagCategories);
  const categoriesOptions = categories.map(category => ({ value: category.id, label: category.name }));
  const [handleAddNewBranchTagCategory, { data: newCategoryAdded }] = useMutation(AddBranchTagCategory);

  const handleAddCategory = (category: string) => {
    handleAddNewBranchTagCategory({ variables: { name: category } });
  };

  useEffect(() => {
    if (newCategoryAdded) {
      refetchCategories();
    }
  }, [newCategoryAdded]);

  const defaultValue = categoriesOptions.find(category => category.value === defaultCategory || category.value === branchTag?.BranchTagCategories?.[0]?.id) || null;

  return (
    <WideInputGroup>
      <FormLabel>Category</FormLabel>
      <Controller
        render={({ onChange, value }) => (
          <CreatableSelect
            styles={{ container: (provided, state) => ({ ...provided }), valueContainer: (provided, state) => ({ ...provided, height: 48, overflowY: 'scroll' }) }}
            options={categoriesOptions}
            isMulti={false}
            isLoading={loading}
            isDisabled={loading}
            theme={selectTheme}
            onCreateOption={handleAddCategory}
            defaultValue={defaultValue}
            onChange={onChange}
            value={value}
          />
        )}
        control={control}
        name={'categoryId'}
        defaultValue={defaultValue}
      />
    </WideInputGroup>
  );
};

export default BranchTagCategories;
