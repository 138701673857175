import React, { FC } from 'react';
import { Adherence, Pet, SuppRecord as SuppRecordType } from '../../../../components/Pets/types';
import { GetAllPetRecordRecordsSuppRecords } from '../../../../queries';
import { ModalBody, ModalFooter } from '../../../../components/Modal/styled';
import { Container } from '../../../../components/Shared/Shared';
import { FormButtonsContainer, FormSubmitButton } from '../../../../components/Shared/Forms/Forms';
import { SuppRecord } from '../../Health/HealthPetRecords';
import ModalDialog from '../../../../components/Modal/ModalDialog';
import usePaginatedQuery from '../../../../hooks/usePaginatedQuery';
import InfiniteList from '../../../../components/Shared/InfiniteList/InfiniteList';
import { CenteredLoader } from '../../../../components/Shared/Spinner';
import { getUniqueSuppRecords } from './helpers';
import { Divider } from '../../../../components/DrawerBar/styled';

type TreatmentsModalProps = {
  pet: Pet;
};

const TreatmentsModalForm = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <ModalBody>
        <Container>{children}</Container>
      </ModalBody>
      <ModalFooter>
        <FormButtonsContainer>
          <FormSubmitButton onClick={ModalDialog.closeModal}>Done</FormSubmitButton>
        </FormButtonsContainer>
      </ModalFooter>
    </>
  );
};

const TreatmentsModal: FC<TreatmentsModalProps> = ({ pet }) => {
  const paginatedQueryResult = usePaginatedQuery<{ SuppRecords: SuppRecordType[]; Adherences: Adherence[] }>({
    query: GetAllPetRecordRecordsSuppRecords /* TODO: make this paginated */,
    otherVariables: { PetRecordId: pet.PetRecord.id }
  });

  const [_, queryResult] = paginatedQueryResult;

  const { data: { getPetRecordRecords: { SuppRecords: suppRecords = [], Adherences: adherences = [] } = {} } = {}, loading: loadingSuppRecords, fetchMore } = queryResult;

  const loading = loadingSuppRecords;

  const uniqueSupps = getUniqueSuppRecords({ adherences, suppRecords })?.filter(({ type }) => type === 'treatment');

  const sortedEndedSuppRecords = uniqueSupps
    ?.filter(({ end_date }) => new Date(end_date).getTime() < new Date().getTime())
    ?.sort((a, b) => {
      const aDate = a?.Adherences?.length ? new Date(a?.Adherences?.[0]?.timestamp) : new Date(a?.createdAt);
      const bDate = b?.Adherences?.length ? new Date(b?.Adherences?.[0]?.timestamp) : new Date(b?.createdAt);
      return aDate.getTime() - bDate.getTime();
    });
  const sortedSuppRecords = uniqueSupps
    ?.filter(({ end_date }) => new Date(end_date).getTime() > new Date().getTime())
    ?.sort((a, b) => {
      const aDate = a?.Adherences?.length ? new Date(a?.Adherences?.[0]?.timestamp) : new Date(a?.createdAt);
      const bDate = b?.Adherences?.length ? new Date(b?.Adherences?.[0]?.timestamp) : new Date(b?.createdAt);
      return aDate.getTime() - bDate.getTime();
    });

  const renderSuppRecord = (suppRecord: SuppRecordType) => (
    <SuppRecord
      key={suppRecord.id}
      suppRecord={suppRecord}
      pet={pet}
      style={{
        width: '100%',
        marginBottom: '10px'
      }}
    />
  );

  return (
    <TreatmentsModalForm>
      {loading && <CenteredLoader />}
      {/* <InfiniteList
        fetchMore={fetchMore}
        hasMoreItems={hasMoreItems}
        setHasMoreItems={setHasMoreItems}
        itemRenderer={(suppRecord: SuppRecordType) => ( */}
      {!loading && (
        <>
          {sortedSuppRecords.map(renderSuppRecord)}
          <Divider marginBottom={10} />
          {sortedEndedSuppRecords.map(renderSuppRecord)}
        </>
      )}
      {/* )}
        list={SuppRecords}
        loading={loading}
        offset={SuppRecords?.length} */}
      {/* /> */}
    </TreatmentsModalForm>
  );
};

export default TreatmentsModal;
