import React, { FC } from 'react';
import { VaccRecord as VaccRecordType } from '../../../../components/Pets/types';
import { GetPetRecordRecords, GetPetRecordRecordsVaccRecords } from '../../../../queries';
import { useQuery } from '@apollo/client';
import { ModalBody, ModalFooter } from '../../../../components/Modal/styled';
import { Container } from '../../../../components/Shared/Shared';
import { CenteredLoader } from '../../../../components/Shared/Spinner';
import { FormButtonsContainer, FormSubmitButton, InputsWrapper, WideInputGroup } from '../../../../components/Shared/Forms/Forms';
import { VaccRecord } from '../../Health/HealthPetRecords';
import ModalDialog from '../../../../components/Modal/ModalDialog';
import usePaginatedQuery from '../../../../hooks/usePaginatedQuery';
import InfiniteList from '../../../../components/Shared/InfiniteList/InfiniteList';
import { RecordsContainer } from '../../../styled';

type VaccinationsModalProps = {
  PetRecordId: string;
};

const VaccinationsModalForm = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <ModalBody>
        <Container>{children}</Container>
      </ModalBody>
      <ModalFooter>
        <FormButtonsContainer>
          <FormSubmitButton onClick={ModalDialog.closeModal}>Done</FormSubmitButton>
        </FormButtonsContainer>
      </ModalFooter>
    </>
  );
};

const VaccinationsModal: FC<VaccinationsModalProps> = ({ PetRecordId }) => {
  const paginatedQueryResult = usePaginatedQuery<{ VaccRecords: VaccRecordType[] }>({
    query: GetPetRecordRecordsVaccRecords /* TODO: make this paginated */,
    otherVariables: { PetRecordId }
  });

  const [_, queryResult] = paginatedQueryResult;

  const { data: { getPetRecordRecords: { VaccRecords = [] } = {} } = {}, loading: loadingVaccRecords } = queryResult;

  const loading = loadingVaccRecords;

  const sortedVaccRecords = [...(VaccRecords || [])].sort((a, b) => {
    const aDate = new Date(a?.dates?.[a?.dates?.length - 1]);
    const bDate = new Date(b?.dates?.[b?.dates?.length - 1]);
    return bDate.getTime() - aDate.getTime();
  });

  return (
    <VaccinationsModalForm>
      {/* <InfiniteList
        fetchMore={fetchMore}
        hasMoreItems={hasMoreItems}
        setHasMoreItems={setHasMoreItems}
        itemRenderer={(vaccRecord: VaccRecordType) => ( */}
      {loading && <CenteredLoader />}
      {!loading &&
        sortedVaccRecords.map((vaccRecord: VaccRecordType) => (
          <VaccRecord
            key={vaccRecord.id}
            vaccRecord={vaccRecord}
            style={{
              width: '100%',
              marginBottom: '10px'
            }}
          />
        ))}
      {/* )}
        list={VaccRecords}
        loading={loading}
        offset={VaccRecords?.length}
      /> */}
    </VaccinationsModalForm>
  );
};

export default VaccinationsModal;
