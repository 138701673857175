import React from 'react';
import { getBranchCurrencySymbol } from '../../../utils/getBranchCurrencySymbol';
import { RecordBody, RecordDataContainer } from '../../styled';
import { isProductNoCharge } from '../ProductPrice';
import { productStatuses } from '../types';

const ProductRecord = ({ product, navigateDrawer }) => {
  const categories = product?.ProductCategories.map(category => category?.name).join(', ');

  const handleClick = () => {
    navigateDrawer({ drawerLinkId: product?.id });
  };
  const price = isProductNoCharge(product) ? 'Free' : getBranchCurrencySymbol() + product?.price;
  const stock = product?.stock ?? 'Unlimited';
  const status = product?.stock === 0 && product?.status === 'IN_STOCK' ? 'Out of stock' : productStatuses.find(status => status.value === product?.status)?.label;
  return (
    <RecordDataContainer onClick={handleClick}>
      <RecordBody fontWeight={'800'}>{product?.name}</RecordBody>
      <RecordBody>{price}</RecordBody>
      <RecordBody>{stock}</RecordBody>
      <RecordBody>{status}</RecordBody>
      <RecordBody>{categories}</RecordBody>
    </RecordDataContainer>
  );
};

export default ProductRecord;
