import React from 'react';
import usePaginatedQuery from '../../../hooks/usePaginatedQuery';
import { GetBranchOrderSubscriptions } from '../../../queries';
import SubscriptionsList from '../../Subscriptions/SubscriptionsList';
import { SUBSCRIPTION_COLLECTION_STATUS_TYPES, SUBSCRIPTION_STATUS_TYPES, Subscription } from '../../Subscriptions/types';
import { BRANCH_BILLING_SUBSCRIPTIONS_FILTERS, BRANCH_BILLING_SUBSCRIPTIONS_FILTERS_REPEAT_STATUS, CurrentPeriod } from './types';

const BranchBillingSubscriptions = ({ period, filter }: { period: CurrentPeriod; filter: BRANCH_BILLING_SUBSCRIPTIONS_FILTERS }) => {
  const repeatStatus = BRANCH_BILLING_SUBSCRIPTIONS_FILTERS_REPEAT_STATUS[filter];
  const paginatedQueryResult = usePaginatedQuery<Subscription[]>({
    query: GetBranchOrderSubscriptions,
    defaultStatus: [SUBSCRIPTION_STATUS_TYPES.CONFIRMED, SUBSCRIPTION_STATUS_TYPES.CANCELED, SUBSCRIPTION_STATUS_TYPES.REQUESTED],
    limit: 20,
    otherVariables: {
      OrderSubscriptionRepeat_payment_invoice_date_from: period.start,
      OrderSubscriptionRepeat_payment_invoice_date_to: period.end,
      ...(repeatStatus && repeatStatus?.length && { OrderSubscriptionRepeat_status: repeatStatus }),
      collectionStatus: [SUBSCRIPTION_COLLECTION_STATUS_TYPES.ACTIVE, SUBSCRIPTION_COLLECTION_STATUS_TYPES.PAUSED],
      requisite_queries: [],
      alternative_queries: []
    }
  });

  return (
    <SubscriptionsList
      paginatedQueryResult={paginatedQueryResult}
      showTotal={{
        dateFilter: {
          from: period.start,
          to: period.end
        }
      }}
    />
  );
};

export default BranchBillingSubscriptions;
