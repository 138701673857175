import { useMutation, useQuery } from '@apollo/client';
import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import { Controller, useForm } from 'react-hook-form';
import RRule from 'rrule';
import ModalDialog from '../../../../components/Modal/ModalDialog';

import { CurrentList, CurrentListItem, ExtensionPetCaseLabel, FlexCenterContainer, FlexColumnBetweenContainer } from '../../../../components/Pets/styled';
import { FormLabel, InputsWrapper, RadioBtnsGroup } from '../../../../components/Shared/Forms/Forms';
import { AddAdherence, GetBranchPetProfile, GetPetRecordRecords } from '../../../../queries';
import { convertDateToDueIn, getNextTimestampForSuppRecord, convertDateToOverdue, getStartOfToday } from '../../../../utils/dates';
import Common from '../../../Store/Common';
import { AddNewContainer } from '../../../Store/styled';
import { OverdueTitle } from './styled';
import { CurrentTreatmentsRef, TabsTypes } from './types';
import { Adherence, MedCond, Pet, SuppRecord } from '../../../../components/Pets/types';
import { getUniqueSuppRecords } from './helpers';
import { CenteredLoader } from '../../../../components/Shared/Spinner';

type CurrentTreatmentsTypeProp = {
  pet: Pet;
  medCond: MedCond;
  setActiveTab: (tab: TabsTypes) => void;
};

const frequencies = [
  { id: 3, name: 'days' },
  { id: 2, name: 'weeks' },
  { id: 1, name: 'months' },
  { id: 0, name: 'years' }
];

const CurrentTreatments = forwardRef<CurrentTreatmentsRef, CurrentTreatmentsTypeProp>(({ pet, medCond, setActiveTab }, ref) => {
  const [addAdherence] = useMutation(AddAdherence);
  const { data: { getBranchPetProfile: petProfile } = {} } = useQuery<{ getBranchPetProfile: Pet }>(GetBranchPetProfile, {
    variables: { id: pet.id },
    fetchPolicy: 'cache-and-network'
  });

  const {
    data: { getPetRecordRecords: { SuppRecords = [], Adherences = [] } = {} } = {},
    loading,
    error,
    called
  } = useQuery<{
    getPetRecordRecords: {
      SuppRecords: SuppRecord[];
      Adherences: Adherence[];
    };
  }>(GetPetRecordRecords, {
    variables: { PetRecordId: pet?.PetRecord?.id },
    fetchPolicy: 'cache-and-network'
  });

  const { control, handleSubmit } = useForm({
    defaultValues: {
      currentTreatments: []
    }
  });

  const submitAdherence = handleSubmit(form => onSubmit(form));
  const onSubmit = (form: { currentTreatments: never[] }) => {
    Promise.all(
      form?.currentTreatments?.map((suppId: SuppRecord[]) => {
        return addAdherence({
          variables: { PetRecordId: petProfile?.PetRecord.id, SuppRecordId: suppId, MedCondId: medCond.id, timestamp: new Date() }
        } as any);
      })
    ).then(() => {
      Common.get<() => null>(`Medical.GetMedCondsByPetRecordId.refetch`)();
    });
    ModalDialog.closeModal();
  };

  useImperativeHandle(ref, () => ({
    submitAdherence
  }));
  const petName = petProfile?.name;

  const uniqueSuppRecords = getUniqueSuppRecords({ adherences: Adherences, suppRecords: SuppRecords });

  const suppsIds = uniqueSuppRecords?.map(supp => supp.id);

  const filteredSuppRecords = (petProfile?.PetRecord?.SuppRecords || []).filter(supp => supp.type === 'treatment' && supp.adherences_allowed && suppsIds.includes(supp.id));

  useEffect(() => {
    if (!filteredSuppRecords?.length && !loading && !error && called) {
      setActiveTab(TabsTypes.New);
      return;
    }
    setActiveTab(TabsTypes.Current);
  }, [filteredSuppRecords, loading, error, called]);

  return (
    <>
      <AddNewContainer>
        {loading && <CenteredLoader />}
        {!loading && (
          <>
            <FormLabel style={{ color: '#000' }}>{!!uniqueSuppRecords?.length ? petName + ` is currently on` : petName + ` has no scheduled treatments yet.`}</FormLabel>
            <InputsWrapper noWrap>
              <CurrentList>
                <Controller
                  render={({ onChange, value }: any) => (
                    <div style={{ width: '100%' }}>
                      <RadioBtnsGroup
                        options={filteredSuppRecords?.map(supp => supp.id)}
                        onChange={(e: any) => {
                          value.includes(e.target.value) ? onChange([...value].filter(v => v !== e.target.value)) : onChange([...value, e.target.value]);
                        }}
                        itemStyle={{ width: '100%' }}
                        name={`currentTreatments`}
                        alignTriggerEnd
                        inputType={'checkbox'}
                        as={filteredSuppRecords.map(medication => {
                          const rruleTime = RRule.fromString(medication?.time || '');
                          const frequency = medication?.time && frequencies.find(freq => freq.id === rruleTime?.options?.freq)?.name;
                          const nextTimestamp = getNextTimestampForSuppRecord(medication);
                          const overdue = new Date(nextTimestamp).getTime() < getStartOfToday().getTime();
                          return (
                            <CurrentListItem overdue={overdue} key={medication?.id}>
                              <FlexColumnBetweenContainer>
                                <FlexCenterContainer>
                                  <ExtensionPetCaseLabel fontSize={14} marginR={5} fontWeight={'bold'}>
                                    {medication?.Product?.Branch?.name}
                                  </ExtensionPetCaseLabel>
                                  <ExtensionPetCaseLabel fontSize={14} fontWeight={'normal'}>
                                    {medication?.Product?.name}
                                  </ExtensionPetCaseLabel>
                                </FlexCenterContainer>
                                <FlexCenterContainer>
                                  <ExtensionPetCaseLabel fontSize={14} fontWeight={'normal'}>
                                    {overdue ? (
                                      <OverdueTitle orange>{frequency ? `Overdue by ${convertDateToOverdue(nextTimestamp)}` : 'As Needed'}</OverdueTitle>
                                    ) : (
                                      <OverdueTitle>{frequency ? `Due in ${convertDateToDueIn(nextTimestamp)}` : 'As Needed'}</OverdueTitle>
                                    )}
                                  </ExtensionPetCaseLabel>
                                </FlexCenterContainer>
                              </FlexColumnBetweenContainer>
                            </CurrentListItem>
                          );
                        })}
                      />
                    </div>
                  )}
                  control={control}
                  name={`currentTreatments`}
                  defaultValue={[]}
                />
              </CurrentList>
            </InputsWrapper>
          </>
        )}
      </AddNewContainer>
    </>
  );
});

export default React.memo(CurrentTreatments);
