import React, { FC, Fragment } from 'react';
import { Controller, Control, FieldErrors, RegisterOptions } from 'react-hook-form';
import { AddNewBtn, AddNewBtnIcon } from '../../views/Store/Products/styled';
import { RemoveAddedImage } from '../../views/Store/styled';
import {
  FormError,
  FormInput,
  FormLabel,
  HeaderLabel,
  HeaderRow,
  InputContainer,
  WideInputGroup,
  InputsRow,
  RemoveRowContainer,
  SectionLabel,
  FormFieldSet,
  FormFieldSetLegend,
  FormSelect
} from './Forms/Forms';
import { Divider } from '../DrawerBar/styled';
import { sentenceCase } from 'sentence-case';
import useIcons from '../../hooks/useIcons';

type Trunk = {
  heading?: string;
  title: string;
  value: string | { name: string; value: string }[];
  rules: Partial<RegisterOptions>;
  twigs: {}[];
  formName?: string;
  formInputType?: string;
};

export type TwigComponentProps = { data: any; control: Control; errors: FieldErrors; idx: number; twig_idx: number; otherProps?: Record<string, any> };

type FormTreeProps = {
  title: string;
  newTrunkTitle?: string;
  newTwigTitle?: string;
  trunks: Trunk[];
  control: Control;
  errors: FieldErrors;
  headers?: { title: string; width: number }[];
  TwigComponent: React.FC<TwigComponentProps>;
  addNewTrunk?: () => void;
  handleRemoveTrunk?: (trunkTitle: string, idx: number) => void;
  addNewTwig?: (trunkTitle: string) => void;
  handleRemoveTwig?: (trunkTitle: string, idx: number, twig_idx: number) => void;
  deleteLastTrunkOnly?: Boolean;
  twigComponentOtherProps?: Record<string, any>;
};

const FormTree: FC<FormTreeProps> = ({
  title,
  newTrunkTitle,
  newTwigTitle,
  trunks,
  control,
  errors,
  TwigComponent,
  addNewTrunk,
  handleRemoveTrunk,
  headers,
  addNewTwig,
  handleRemoveTwig,
  deleteLastTrunkOnly,
  twigComponentOtherProps
}) => {
  const icons = useIcons();
  return (
    <WideInputGroup>
      {!trunks?.[0]?.heading && <Divider marginBottom={16} />}
      <SectionLabel>{sentenceCase(title)}</SectionLabel>
      {trunks?.map((trunk, idx) => {
        const formName = trunk.formName || `custom_type_${title.toLowerCase()}_${idx}`;
        const formInputType = trunk.formInputType || 'text';
        return (
          <Fragment key={idx}>
            <FormFieldSet showBorder={!!trunk?.heading}>
              {!!trunk?.heading && <FormFieldSetLegend>{trunk?.heading}</FormFieldSetLegend>}
              <InputsRow>
                {formInputType !== 'select' && (
                  <InputContainer width={100}>
                    <FormLabel>{trunk?.title}</FormLabel>
                    <Controller
                      as={<FormInput error={errors[formName]} height={32} fontSize={16} type={formInputType} />}
                      control={control}
                      name={formName}
                      defaultValue={trunk?.value}
                      rules={{ required: true, ...(trunk.rules || {}) }}
                    />
                    {errors[formName] && <FormError>{errors[formName].message || `${trunk?.title} is required`}</FormError>}
                  </InputContainer>
                )}
                {formInputType === 'select' && (
                  <InputContainer width={100}>
                    <FormLabel>{trunk?.title}</FormLabel>
                    <Controller
                      render={({ onChange, value }) => (
                        <FormSelect onChange={e => onChange(e.target.value)} value={value} error={errors[formName]} height={48} fontSize={16}>
                          {trunk?.value?.map?.((option: any) => (
                            <option key={option?.value} value={option?.value}>
                              {option?.name}
                            </option>
                          ))}
                        </FormSelect>
                      )}
                      control={control}
                      name={formName}
                      defaultValue={trunk?.value[0]}
                      rules={{ required: true, ...(trunk.rules || {}) }}
                    />

                    {errors[formName] && <FormError>{errors[formName].message || `${trunk?.title} is required`}</FormError>}
                  </InputContainer>
                )}

                {handleRemoveTrunk && (deleteLastTrunkOnly ? idx + 1 === trunks.length : true) && (
                  <RemoveRowContainer height={90}>
                    <RemoveAddedImage src={icons.delete.childImageSharp.gatsbyImageData.images.fallback.src} onClick={() => handleRemoveTrunk(trunk?.value, idx)} />
                  </RemoveRowContainer>
                )}
              </InputsRow>
              {headers && (
                <HeaderRow>
                  {handleRemoveTwig && <HeaderLabel width={6} />}
                  {headers?.map((header, idx) => (
                    <HeaderLabel key={idx} width={header.width}>
                      {header.title}
                    </HeaderLabel>
                  ))}
                </HeaderRow>
              )}
              {trunk?.twigs
                .filter(twig => !!twig)
                ?.map((twig, twig_idx) => (
                  <InputsRow key={twig_idx}>
                    {handleRemoveTwig && (
                      <RemoveRowContainer>
                        <RemoveAddedImage src={icons.delete.childImageSharp.gatsbyImageData.images.fallback.src} onClick={() => handleRemoveTwig(trunk?.value, idx, twig_idx)} />
                      </RemoveRowContainer>
                    )}
                    <TwigComponent data={twig} control={control} errors={errors} idx={idx} twig_idx={twig_idx} otherProps={twigComponentOtherProps} />
                  </InputsRow>
                ))}
              {!!addNewTwig && (
                <AddNewBtn onClick={() => addNewTwig(trunk?.value)}>
                  <AddNewBtnIcon src={icons.addPhoto.childImageSharp.gatsbyImageData.images.fallback.src} />
                  {!!newTwigTitle && newTwigTitle}
                </AddNewBtn>
              )}
              {!trunk?.heading && <Divider marginBottom={16} />}
            </FormFieldSet>
          </Fragment>
        );
      })}
      {addNewTrunk && (
        <AddNewBtn onClick={() => addNewTrunk()}>
          <AddNewBtnIcon src={icons.addPhoto.childImageSharp.gatsbyImageData.images.fallback.src} />
          {newTrunkTitle && newTrunkTitle}
        </AddNewBtn>
      )}
      <Divider />
    </WideInputGroup>
  );
};

export default FormTree;

export function removeFormTwig(idx: number, rowIdx: number, rowsLength: number, control: Control, getFieldNames: (idx: number, rowIdx: number) => string[]) {
  let itr = rowIdx;
  while (rowsLength >= itr) {
    const nextField = getFieldNames(idx, itr + 1);
    getFieldNames(idx, itr).forEach((field, i) => control.setValue(field, control.getValues(nextField[i])));
    itr++;
  }
}

export function removeFormTrunkWithOneTwig(idx: number, trunksLength: number, control: Control, getFieldNames: (idx: number, rowIdx: number) => string[]) {
  let itr = idx;
  while (trunksLength >= itr) {
    const nextField = getFieldNames(itr + 1, 0);
    getFieldNames(itr, 0).forEach((field, i) => control.setValue(field, control.getValues(nextField[i])));
    itr++;
  }
}
