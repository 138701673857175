import { useReactiveVar } from '@apollo/client';
import React, { useMemo, useRef } from 'react';
import useBulkSelect from '../../hooks/useBulkSelect';
import useIcons from '../../hooks/useIcons';
import useMediaQuery from '../../hooks/useMediaQuery';
import { vars } from '../../reactive';
import { setDrawerBar } from '../../reactive/actions';
import { getRandomPetPlaceholder } from '../../utils/helpers';
import { BranchPetRecordTag } from '../../views/Store/BranchTags/types';
import {
  AppUserImage,
  AppUserImageAnimation,
  AppUserImageContainer,
  AppUserImageContainerAnimation,
  AppUserImageNameContainer,
  AppUserName,
  RecordBody,
  RecordDataContainer,
  RecordDataOverflowContainer
} from '../../views/styled';
import { DRAWER_IDS } from '../DrawerBar/types';
import { HIDDEN_PETS_FILTERS } from '../Shared/Filters/config';
import { Icon } from '../Shared/Forms/Forms';
import { Checkbox, CheckboxItemContainer } from '../Shared/Forms/styled';
import PetRecordFilters from './PetRecordFilters';
import PetRecordOptions from './PetRecordOptions';
import { AppUserImagePetRecordOptions, PetItemsContainer, PetRecordOptionsContainer, PetRecordTagPill, PetRecordTagPillsContainer } from './styled';
import { Pet } from './types';

const PetRecord = ({
  pet,
  profile,
  navigateDrawer,
  quickTags,
  tagsVisibleOnItem,
  bulkSelect
}: {
  pet: Pet;
  profile: Record<string, any>;
  navigateDrawer: ({ drawerLinkId }: { drawerLinkId: string }) => void;
  quickTags: BranchPetRecordTag[];
  tagsVisibleOnItem?: BranchPetRecordTag[];
  bulkSelect: ReturnType<typeof useBulkSelect>;
}) => {
  const icons = useIcons();
  const selectedFilters = useReactiveVar(vars.selectedPetsFilters);
  const optionsRef = useRef<HTMLDivElement>(null);
  const { mobile } = useMediaQuery({ mobile: true });

  const existingTags: BranchPetRecordTag[] = pet?.PetRecord?.BranchPetRecordTags;

  const { show: showBulkSelect, toggleSelectMultiple, isSelected } = bulkSelect || {};

  const handleClick = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    if (optionsRef?.current?.contains(e.target as Node)) {
      return;
    }

    if (showBulkSelect) {
      toggleSelectMultiple([pet?.id]);
      return;
    }

    setDrawerBar({ drawerId: DRAWER_IDS.PETS_DRAWER, recordData: pet?.id });

    navigateDrawer({ drawerLinkId: pet?.id });
  };

  const isCollarSupport = profile?.Branch?.name === 'Collar';

  const profilePic = useMemo(() => (isCollarSupport ? getRandomPetPlaceholder(icons) : pet?.profile_pic || getRandomPetPlaceholder(icons)), [icons, pet]);

  const petBreed = pet?.Breed?.name;

  const petOwnerName = pet?.AppUser?.name;

  const hasFilters = !!selectedFilters?.length;
  const hasVisibileFilters = !!selectedFilters.filter(({ value }) => !HIDDEN_PETS_FILTERS[value]).length;
  const petRecordOptions = (
    <PetRecordOptionsContainer>
      <PetRecordOptions pets={[pet]} quickTags={quickTags} ref={optionsRef} showAlways={mobile} refetchPetsAfterTag={hasFilters} />
    </PetRecordOptionsContainer>
  );

  const tagsToRender = tagsVisibleOnItem ? existingTags.filter(tag => tagsVisibleOnItem.some(visibleTag => visibleTag.id === tag.id)) : existingTags;

  const renderTags = () => {
    return (
      <PetRecordTagPillsContainer>
        {tagsToRender?.map((item, index) => (
          <PetRecordTagPill color={item.color} key={index}>
            <span>{item.name}</span>
          </PetRecordTagPill>
        ))}
      </PetRecordTagPillsContainer>
    );
  };

  return (
    <div>
      <RecordDataContainer onClick={handleClick} streach={mobile} mobilePadding="16px 8px" mobileGap="0" MobileMinHeight="auto">
        <RecordBody>
          <AppUserImagePetRecordOptions>
            <AppUserImageNameContainer>
              <AppUserImageContainer noPadding={!showBulkSelect}>
                <AppUserImageContainerAnimation rotateOption={!!showBulkSelect} noPadding={showBulkSelect}>
                  {!showBulkSelect && !mobile && (
                    <AppUserImageAnimation>
                      <AppUserImage src={pet?.profile_pic || icons.user.childImageSharp.gatsbyImageData.images.fallback.src} />
                    </AppUserImageAnimation>
                  )}
                  {showBulkSelect && (
                    <AppUserImageAnimation>
                      <CheckboxItemContainer checked={isSelected(pet?.id)} noMargin>
                        <Checkbox>
                          <Icon viewBox="0 0 24 24">
                            <polyline points="20 6 9 17 4 12" />
                          </Icon>
                        </Checkbox>
                      </CheckboxItemContainer>
                    </AppUserImageAnimation>
                  )}
                </AppUserImageContainerAnimation>
                {/* {mobile && petRecordOptions} */}
              </AppUserImageContainer>
              {!mobile && <AppUserName>{pet?.name}</AppUserName>}
            </AppUserImageNameContainer>
          </AppUserImagePetRecordOptions>
        </RecordBody>
        {!hasVisibileFilters && (
          <>
            {mobile && (
              <RecordBody mobileWidth="100%" MobileGap={10}>
                <RecordDataOverflowContainer flexDirection="row">
                  <AppUserImagePetRecordOptions mobileWidth="auto">
                    <AppUserImageNameContainer>
                      <AppUserImageContainer noPadding={!showBulkSelect}>
                        <AppUserImageContainerAnimation rotateOption={!!showBulkSelect} noPadding={showBulkSelect}>
                          {!showBulkSelect && (
                            <AppUserImageAnimation>
                              <AppUserImage src={pet?.profile_pic || icons.user.childImageSharp.gatsbyImageData.images.fallback.src} />
                            </AppUserImageAnimation>
                          )}
                        </AppUserImageContainerAnimation>
                      </AppUserImageContainer>
                    </AppUserImageNameContainer>
                  </AppUserImagePetRecordOptions>
                  <PetItemsContainer>
                    <RecordBody fontSize={16} noMargin>
                      <RecordBody marginBottom="4">
                        <RecordBody fontWeight="800">{pet?.name}, </RecordBody>
                        <RecordBody>{petBreed}</RecordBody>
                      </RecordBody>
                    </RecordBody>

                    <RecordBody fontSize={14}>{petOwnerName}</RecordBody>
                  </PetItemsContainer>
                </RecordDataOverflowContainer>
                {mobile && petRecordOptions}
              </RecordBody>
            )}

            {!mobile && (
              <>
                <RecordBody fontWeight="bold">{petBreed}</RecordBody>
                <RecordBody>{petOwnerName}</RecordBody>
              </>
            )}
          </>
        )}
        {!hasFilters && !mobile && (
          <RecordDataOverflowContainer>
            <PetItemsContainer>
              <RecordBody width="360">{renderTags()}</RecordBody>
            </PetItemsContainer>
          </RecordDataOverflowContainer>
        )}
        <PetRecordFilters selectedFilters={selectedFilters} pet={pet} />
        {!mobile && petRecordOptions}
      </RecordDataContainer>
    </div>
  );
};

export default PetRecord;
