import React from 'react';
import { BRANCH_TAGS_TABS_TYPES } from './types';
import useSelectedState from '../../../hooks/useSelectedState';
import BranchTagsAll from './BranchTagsAll';
import BranchPetRecordTags from './BranchPetRecordTags';
import BranchAppointmentTags from './BranchAppointmentTags';
import TabsHeader from '../../../components/Shared/TabsHeader';
import { BranchTagsHeaderContainer } from './styled';
import BranchAppUserTags from './BranchAppUserTags';

const BranchTags = () => {
  const selectedTabState = useSelectedState({ defaultTab: BRANCH_TAGS_TABS_TYPES.ALL, TABS_TYPES: BRANCH_TAGS_TABS_TYPES });

  const [selected, setSelected] = selectedTabState;

  const tabs = [
    {
      name: BRANCH_TAGS_TABS_TYPES.ALL,
      component: BranchTagsAll
    },
    {
      name: BRANCH_TAGS_TABS_TYPES.PETS,
      component: BranchPetRecordTags
    },
    {
      name: BRANCH_TAGS_TABS_TYPES.BOOKINGS,
      component: BranchAppointmentTags
    },
    {
      name: BRANCH_TAGS_TABS_TYPES.APP_USERS,
      component: BranchAppUserTags
    }
  ];

  const Component = tabs.find(tab => tab.name === selected)?.component || (() => <></>);

  return (
    <>
      <BranchTagsHeaderContainer>
        <TabsHeader tabs={Object.values(BRANCH_TAGS_TABS_TYPES)} actions={<></>} selected={selected} setSelected={setSelected} noPadding />
      </BranchTagsHeaderContainer>
      <Component />
    </>
  );
};

export default BranchTags;
