import { useMutation } from '@apollo/client';
import React, { useEffect } from 'react';
import { Controller, ControllerRenderProps, UseFormMethods, useForm } from 'react-hook-form';
import ModalDialog from '../../../components/Modal/ModalDialog';
import { ModalBody, ModalFooter } from '../../../components/Modal/styled';
import { FormButtonsContainer, FormCheckbox, FormError, FormInput, FormLabel, FormSubmitButton, InputContainer, RadioBtnsGroup, WideInputGroup } from '../../../components/Shared/Forms/Forms';
import { CheckboxItemName } from '../../../components/Shared/Forms/styled';
import InfiniteList from '../../../components/Shared/InfiniteList/InfiniteList';
import usePaginatedQuery, { UsePagintaedQueryResult } from '../../../hooks/usePaginatedQuery';
import { BatchCreateProductsFromPharmaItems, GetPharmaItems } from '../../../queries';
import debounce from '../../../utils/debounce';
import { getBranchCurrencySymbol } from '../../../utils/getBranchCurrencySymbol';
import { Divider } from '../../Pets/Health/styled';
import Common from '../Common';
import { PharmaItemCheckboxContainer, PharmaItemContainer, PharmaItemCost, PharmaItemCostContainer, PharmaItemDescription, PharmaItemMarginBoxContainer, PharmaItemsListContainer } from './styled';

type PharmaItem = {
  id: string;
  cost: number;
  supplier: string;
  description: string;
};

type FormValues = {
  selectedPharmaItemsIds: string[];
  description: string;
  margins: { [key: string]: string };
  generalMargin: string;
  enableGeneralMargin: boolean;
};

const ImportProductsModal = () => {
  const defaultValues = {
    selectedPharmaItemsIds: [],
    description: '',
    margins: {},
    generalMargin: '',
    enableGeneralMargin: false
  };

  const formOptions = useForm<FormValues>({ defaultValues });

  const { control, handleSubmit, watch, errors } = formOptions;

  const paginatedQueryResult = usePaginatedQuery<PharmaItem[]>({
    query: GetPharmaItems
  });

  const [_, { loading: loadingPharmaItems, refetch: refetchPharmaItems, previousData }] = paginatedQueryResult;

  const { description: watchedDescription, enableGeneralMargin: watchedEnableGeneralMargin } = watch(['description', 'enableGeneralMargin']);

  useEffect(() => {
    if (!watchedDescription && !previousData) {
      return;
    }

    debounce(() => {
      refetchPharmaItems({ description: watchedDescription });
    }, 500);
  }, [watchedDescription]);

  const [batchCreateProductsFromPharmaItems, { loading: loadingBatchCreateProductsFromPharmaItems, data: { batchCreateProductsFromPharmaItems: createdProducts = [] } = {} }] =
    useMutation(BatchCreateProductsFromPharmaItems);

  const loading = loadingPharmaItems;
  const loadingSubmit = loadingBatchCreateProductsFromPharmaItems;

  const onSubmit = handleSubmit(async data => {
    const { selectedPharmaItemsIds, margins, generalMargin } = data;
    const selectedItems = selectedPharmaItemsIds.map(id => {
      const margin = Number(margins[id] || generalMargin);
      return { PharmaItemId: id, price_margin: margin };
    });

    const variables = {
      batchCreateProductsFromPharmaItemsInput: {
        CreateProductFromPharmaItems: selectedItems
      }
    };

    batchCreateProductsFromPharmaItems({ variables });
  });

  useEffect(() => {
    if (createdProducts?.length) {
      Common.get<() => Promise<any>>(`Products.GetProducts.refetch`)?.();
      ModalDialog.closeModal();
    }
  }, [createdProducts]);

  return (
    <>
      <ModalBody minHeight={300} padding={'10px 16px'} maxWidth={500}>
        <WideInputGroup marginBottom={19}>
          <Controller control={control} name="description" as={<FormInput name="description" placeholder="Start typing a product name" />} defaultValue={defaultValues.description} />
        </WideInputGroup>
        <PharmaItemsListContainer>
          <Controller
            control={control}
            name={`selectedPharmaItemsIds`}
            render={fields => <PharmaItemProductsList formOptions={formOptions} paginatedQueryResult={paginatedQueryResult} fields={fields} />}
            defaultValue={defaultValues.selectedPharmaItemsIds || []}
            rules={{ required: true, validate: value => value?.length > 0 }}
          />
          {errors?.selectedPharmaItemsIds && (
            <WideInputGroup marginBottom={20} marginTop={20} marginLeft={40}>
              <FormError>Please select at least 1 product</FormError>
            </WideInputGroup>
          )}
        </PharmaItemsListContainer>
        <PharmaItemDescription>
          <InputContainer isRow flex={1} marginBottom={20}>
            <Controller
              render={({ onChange, value }) => (
                <RadioBtnsGroup
                  options={['Apply a general margin to products with no item margin']}
                  defaultValue={''}
                  onChange={() => {
                    onChange(!value);
                  }}
                  itemStyle={{ width: '100%', margin: 0 }}
                  name={`enableGeneralMargin`}
                  inputType={'checkbox'}
                  fontSize={16}
                  fontWeight={400}
                />
              )}
              control={control}
              name={`enableGeneralMargin`}
              defaultValue={defaultValues.enableGeneralMargin}
            />
            <Controller
              control={control}
              name={`generalMargin`}
              render={({ onChange, value }) => {
                return <FormInput name={`generalMargin`} onChange={onChange} value={value} type="number" min={1} max={100} step={1} style={{ width: 46 }} />;
              }}
              defaultValue={''}
              readOnly={!watchedEnableGeneralMargin}
              rules={{
                required: {
                  value: watchedEnableGeneralMargin,
                  message: 'Please enter a margin'
                },
                validate: value => {
                  if (watchedEnableGeneralMargin) {
                    return (value > 0 && value <= 100) || 'Please enter a valid margin';
                  }
                }
              }}
            />
          </InputContainer>
          {errors?.generalMargin && <FormError>{errors?.generalMargin?.message}</FormError>}
        </PharmaItemDescription>
      </ModalBody>
      <ModalFooter>
        <FormButtonsContainer>
          <FormSubmitButton loading={loadingSubmit} onClick={onSubmit} disabled={loading}>
            Import
          </FormSubmitButton>
        </FormButtonsContainer>
      </ModalFooter>
    </>
  );
};

export default ImportProductsModal;

const PharmaItemProductsList = ({
  formOptions,
  paginatedQueryResult,
  fields
}: {
  formOptions: UseFormMethods<FormValues>;
  paginatedQueryResult: UsePagintaedQueryResult<PharmaItem[]>;
  fields: ControllerRenderProps<Record<string, any>>;
}) => {
  const { control, errors, watch } = formOptions;
  const { onChange, value } = fields;
  const [[hasMoreItems, setHasMoreItems], { fetchMore, data: { getPharmaItems: pharmaItems = [] } = {}, loading }] = paginatedQueryResult;

  const {
    enableGeneralMargin: watchedEnableGeneralMargin,
    generalMargin: watchedGeneralMargin,
    selectedPharmaItemsIds: watchedSelectedPharmaItemsIds
  } = watch(['enableGeneralMargin', 'generalMargin', 'selectedPharmaItemsIds']);

  const renderCheckboxMargin = ({ id, fontSize, error, disabled }: { id: string; fontSize?: number; error?: boolean | string; disabled?: boolean }) => {
    const item = pharmaItems.filter(item => item.id === id)?.[0];
    const { cost, supplier, description } = item;
    const isSelected = watchedSelectedPharmaItemsIds?.includes?.(id);
    const noMargin = (watchedEnableGeneralMargin && Number(watchedGeneralMargin) === 0) || !watchedEnableGeneralMargin;
    const isRequired = isSelected && noMargin;
    return (
      <PharmaItemCheckboxContainer key={id}>
        <PharmaItemDescription>
          <CheckboxItemName fontSize={fontSize} error={error} disabled={disabled}>
            {description}
          </CheckboxItemName>
          <PharmaItemCostContainer>
            <PharmaItemCost>
              Cost {getBranchCurrencySymbol()}
              {cost}
            </PharmaItemCost>
            <PharmaItemCost>Supplier {supplier}</PharmaItemCost>
          </PharmaItemCostContainer>
        </PharmaItemDescription>
        <PharmaItemMarginBoxContainer>
          <FormLabel>Margin (%)</FormLabel>
          <Controller
            control={control}
            name={`margins.${id}`}
            render={({ onChange, value }) => {
              return <FormInput name={`margins.${id}`} onChange={onChange} value={value} type="number" min={1} max={100} step={1} style={{ width: 46 }} />;
            }}
            defaultValue={''}
            readOnly={!isSelected}
            rules={{
              required: {
                value: isRequired,
                message: 'Please enter a margin'
              },
              validate: value => {
                if (isRequired) {
                  return (value > 0 && value <= 100) || 'Please enter a valid margin';
                }
              }
            }}
          />
        </PharmaItemMarginBoxContainer>
      </PharmaItemCheckboxContainer>
    );
  };

  const renderListItem: (item: PharmaItem) => JSX.Element = item => {
    const { id, description } = item;
    return (
      <PharmaItemContainer>
        <FormCheckbox onChange={onChange} value={value} itemsArray={[{ id, name: description }]} error={errors?.margins?.[id]?.type} fontSize={16} column renderItem={renderCheckboxMargin} />
        {errors?.margins?.[id] && <FormError>{errors?.margins?.[id]?.message}</FormError>}
        <Divider />
      </PharmaItemContainer>
    );
  };

  return (
    <InfiniteList
      fetchMore={fetchMore}
      hasMoreItems={hasMoreItems}
      setHasMoreItems={setHasMoreItems}
      list={pharmaItems}
      loading={loading}
      offset={pharmaItems?.length}
      itemRenderer={renderListItem}
      listContainerStyles={{ padding: 0, width: '100%' }}
      noLineLoader
    />
  );
};
