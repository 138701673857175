import React, { useMemo } from 'react';
import { ViewContainer } from '../../components/Shared/Shared';
import useSelectedState from '../../hooks/useSelectedState';
import useMediaQuery from '../../hooks/useMediaQuery';
import { BOOKING_TABS_TYPES } from './types';
import BookingsHeader from './BookingsHeader/BookingsHeader';
import BookingsBody from './BookingsBody';

const Bookings = () => {
  const selectedTabState = useSelectedState({ defaultTab: BOOKING_TABS_TYPES.CONFIRMED, TABS_TYPES: BOOKING_TABS_TYPES });
  const { mobile } = useMediaQuery({ mobile: true });
  const headerTabs = Object.values(BOOKING_TABS_TYPES);

  const tabs = useMemo(() => (mobile ? headerTabs.filter(tab => tab !== BOOKING_TABS_TYPES.CANCELED) : headerTabs), [mobile, headerTabs]);

  return (
    <ViewContainer>
      <BookingsHeader tabs={tabs} selectedTabState={selectedTabState} />
      <BookingsBody selectedTabState={selectedTabState} />
    </ViewContainer>
  );
};

export default Bookings;
