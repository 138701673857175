import React, { FC } from 'react';
import { sentenceCase } from 'sentence-case';
import ModalDialog from '../../components/Modal/ModalDialog';
import OptionDropdown from '../../components/Shared/Menus/OptionDropdown/OptionDropdown';
import { OPTION_DROPDOWN_MENU_BUTTON_TYPES, OPTION_DROPDOWN_TYPES } from '../../components/Shared/Menus/OptionDropdown/types';
import useIcons from '../../hooks/useIcons';
import { ActionBtn } from './styled';
import { TABS_TYPES } from './types';

type AddNewItemProps = {
  type: TABS_TYPES;
  tabs: { name: TABS_TYPES; component: () => JSX.Element; modals: { title: string; component: () => JSX.Element }[] | (() => JSX.Element)[] }[];
};

export const AddNewItem: FC<AddNewItemProps> = ({ type, tabs }) => {
  const modals = tabs.find(tab => tab.name === type)?.modals;
  const icons = useIcons();
  const addIcon = icons.add.childImageSharp.gatsbyImageData.images.fallback.src;
  const hasModals = !!modals;
  if (hasModals) {
    const modalsComponents = modals?.map((Modal, index) => {
      if (typeof Modal === 'function') {
        return <Modal key={index} />;
      }

      return <Modal.component key={index} />;
    });
    const modalsOnClick = modalsComponents?.map(
      (component, index) => () =>
        ModalDialog.openModal({ content: () => component, title: modals?.[index]?.title ?? `Add New ${type === TABS_TYPES.BUS_USERS ? 'Staff' : sentenceCase(type.substring(0, type.length - 1))}` })
    );

    if (modalsComponents?.length > 1) {
      return (
        <OptionDropdown
          options={[
            {
              id: 'modals',
              optionType: OPTION_DROPDOWN_TYPES.BUTTONS,
              items: modalsOnClick?.map((onClick, index) => ({
                value: String(index),
                name: modals?.[index]?.title ?? `Add New ${type === TABS_TYPES.BUS_USERS ? 'Staff' : sentenceCase(type.substring(0, type.length - 1))}`,
                onClick
              }))
            }
          ]}
          menuButtonType={OPTION_DROPDOWN_MENU_BUTTON_TYPES.PLUS}
          noApplyButton
        />
      );
    }

    return <ActionBtn bgImage={addIcon} onClick={modalsOnClick?.[0]} />;
  }
  return <></>;
};
