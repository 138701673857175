import React from 'react';
import useIcons from '../../hooks/useIcons';
import useMediaQuery from '../../hooks/useMediaQuery';
import { RecordDataContainer, AppUserImage, AppUserName, RecordBody, AppUserImageNameContainer, AppUserImageContainer } from '../styled';
import { OrderDataContainer } from './styled';
import { getOrderTitles } from './utils';
import { RedDot } from '../../components/Shared/Shared';

type Props = { order: any; navigateDrawer: ({ drawerLinkId }: { drawerLinkId: string }) => void; totalAtTheEnd?: boolean };

const OrderRecord = ({ order, navigateDrawer, totalAtTheEnd }: Props) => {
  const icons = useIcons();

  const handleClick = () => {
    navigateDrawer({ drawerLinkId: order.id });
  };

  const { mobile } = useMediaQuery({ mobile: true });

  const { orderNumber, numberOfProducts, total, time, appUserName, user, isNew } = getOrderTitles(order);

  const recordData = (
    <>
      {mobile && (
        <RecordBody>
          <AppUserName noMargin>{appUserName}</AppUserName>
        </RecordBody>
      )}
      {!mobile && <RecordBody fontWeight={'800'}>{orderNumber}</RecordBody>}
      {!mobile && <RecordBody>{numberOfProducts}</RecordBody>}
      {!totalAtTheEnd && <RecordBody>{total}</RecordBody>}
      <RecordBody>{time}</RecordBody>
      {totalAtTheEnd && <RecordBody flexEnd>{total}</RecordBody>}
    </>
  );

  return (
    <RecordDataContainer onClick={handleClick}>
      <RecordBody>
        <AppUserImageNameContainer>
          {isNew && <RedDot />}
          <AppUserImageContainer>
            <AppUserImage src={user?.profile_pic || icons.user.childImageSharp.gatsbyImageData.images.fallback.src} index={0} />
          </AppUserImageContainer>
          {!mobile && <AppUserName>{appUserName}</AppUserName>}
        </AppUserImageNameContainer>
      </RecordBody>
      {mobile ? <OrderDataContainer>{recordData}</OrderDataContainer> : recordData}
    </RecordDataContainer>
  );
};

export default OrderRecord;
