import { useMutation, useQuery } from '@apollo/client';
import React, { FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ReactSelect from 'react-select';
import Alert from '../../../components/Alert/Alert';
import ModalDialog from '../../../components/Modal/ModalDialog';
import { ModalBody, ModalFooter } from '../../../components/Modal/styled';
import { ORDER_INVOICE_STATUS } from '../../../components/OrderInvoices/types';
import { FormButtonsContainer, FormInput, FormLabel, FormSubmitButton, InputGroup, InputsWrapper, WideInputGroup, selectTheme } from '../../../components/Shared/Forms/Forms';
import AppUsersList from '../../../components/Shared/Lists/AppUsersList';
import { Container } from '../../../components/Shared/Shared';
import { BatchRetryOrderInvoice, GetBranchAppUserTags } from '../../../queries';
import { BranchAppUserTag } from '../BranchTags/types';

type OrderInvoiceChargeFormValues = {
  AppUserId: string[];
  billed_on_from: string;
  billed_on_to: string;
  status: 'ALL' | 'UNBILLED' | 'PENDING_REJECTED';
  BranchAppUserTagId: string[];
};

type OrderInvoiceChargeModalProps = {
  initialValues?: Partial<{ AppUserId: string[]; unbilled: boolean; status?: ORDER_INVOICE_STATUS[] | null; billed_on_from: string; billed_on_to: string; BranchAppUserTagId: string[] }>;
};

const OrderInvoicesChargeModal: FC<OrderInvoiceChargeModalProps> = ({ initialValues }) => {
  const formOptions = useForm<OrderInvoiceChargeFormValues>({
    defaultValues: {
      AppUserId: initialValues?.AppUserId || [],
      billed_on_from: initialValues?.billed_on_from ? new Date(initialValues?.billed_on_from).toISOString().split('T')[0] : '',
      billed_on_to: initialValues?.billed_on_to ? new Date(initialValues?.billed_on_to).toISOString().split('T')[0] : '',
      status: initialValues?.unbilled ? 'UNBILLED' : 'ALL',
      BranchAppUserTagId: initialValues?.BranchAppUserTagId || []
    }
  });

  const { control, handleSubmit, watch } = formOptions;

  const watchedValues = watch(['billed_on_from']);

  const [handleBulkCharge, { data, loading, error }] = useMutation(BatchRetryOrderInvoice, {
    refetchQueries: ['getBranchOrderInvoices'],
    awaitRefetchQueries: true
  });

  const onSubmit = handleSubmit(values => {
    Alert.alert({
      title: 'Are you sure you want to charge invoices?',
      description: 'This action will charge all users that have invoices',
      onAccept: async () => {
        await handleBulkCharge({
          variables: {
            billed_on_from: new Date(new Date(values.billed_on_from).setUTCHours(0, 0, 0, 0)).toISOString(),
            billed_on_to: new Date(new Date(values.billed_on_to).setUTCHours(23, 59, 59, 999)).toISOString(),
            status: ['UNBILLED', 'ALL'].includes(values.status) ? null : values.status === 'PENDING_REJECTED' ? ['PAYMENT_PENDING', 'PAYMENT_REJECTED'] : ['PAYMENT_SUCCEEDED'],
            ...(values.AppUserId?.length && { AppUserId: values.AppUserId }),
            ...(values.status === 'UNBILLED' && { unbilled: true }),
            ...(values.BranchAppUserTagId?.length && { BranchAppUserTagId: values.BranchAppUserTagId })
          }
        });
      }
    });
  });

  const { data: { getBranchAppUserTags: allTags = [] } = {} } = useQuery<{
    getBranchAppUserTags: BranchAppUserTag[];
  }>(GetBranchAppUserTags, {
    fetchPolicy: 'cache-and-network',
    variables: {
      offset: 0,
      limit: 1000
    }
  });

  const billedOnToMin = watchedValues?.billed_on_from ? new Date(watchedValues?.billed_on_from)?.toISOString().split('T')[0] : '';

  useEffect(() => {
    if (data?.batchRetryOrderInvoice) {
      ModalDialog.closeModal();
    }
  }, [data?.batchRetryOrderInvoice]);

  return (
    <>
      <ModalBody>
        <Container width={600}>
          <InputsWrapper>
            <WideInputGroup>
              <FormLabel>Clients</FormLabel>
              <AppUsersList isMulti formOptions={formOptions} name="AppUserId" defaultValues={initialValues?.AppUserId} />
            </WideInputGroup>
            <WideInputGroup>
              <FormLabel>Status</FormLabel>
              <Controller
                render={({ onChange, value }) => (
                  <ReactSelect
                    options={[
                      { label: 'All', value: 'ALL' },
                      { label: 'Unpaid', value: 'UNBILLED' },
                      { label: 'Pending/Rejected', value: 'PENDING_REJECTED' }
                    ]}
                    value={{ label: value === 'ALL' ? 'All' : value === 'UNBILLED' ? 'Unpaid' : 'Pending/Rejected', value }}
                    onChange={option => onChange(option?.value)}
                    theme={selectTheme}
                  />
                )}
                control={control}
                name={'status'}
                defaultValue={initialValues?.unbilled ? 'UNBILLED' : 'ALL'}
              />
            </WideInputGroup>
            <WideInputGroup>
              <FormLabel>Client Tags</FormLabel>
              <Controller
                render={({ onChange, value }) => (
                  <ReactSelect
                    options={allTags.map(tag => ({ label: tag.name, value: tag.id }))}
                    onChange={tags => onChange(tags?.map(tag => tag.value))}
                    isMulti
                    theme={selectTheme}
                    value={[...(value || [])]?.map(tagId => allTags.find(tag => tag.id === tagId)).map(tag => ({ label: tag?.name, value: tag?.id })) || []}
                  />
                )}
                control={control}
                name={'BranchAppUserTagId'}
                defaultValue={initialValues?.BranchAppUserTagId || []}
              />
            </WideInputGroup>
            <WideInputGroup marginTop={20} flexDirection="row" gap={10}>
              <InputGroup>
                <FormLabel>From</FormLabel>
                <Controller
                  as={<FormInput type={'date'} height={32} fontSize={16} />}
                  control={control}
                  name={'billed_on_from'}
                  defaultValue={initialValues?.billed_on_from ? new Date(initialValues?.billed_on_from).toISOString().split('T')[0] : ''}
                />
              </InputGroup>
              <InputGroup>
                <FormLabel>To</FormLabel>
                <Controller
                  as={<FormInput type={'date'} height={32} fontSize={16} min={billedOnToMin} disabled={!watchedValues?.billed_on_from} />}
                  control={control}
                  name={'billed_on_to'}
                  defaultValue={initialValues?.billed_on_to ? new Date(initialValues?.billed_on_to).toISOString().split('T')[0] : ''}
                />
              </InputGroup>
            </WideInputGroup>
          </InputsWrapper>
        </Container>
      </ModalBody>
      <ModalFooter>
        <FormButtonsContainer>
          <FormSubmitButton error={!!error} loading={loading} onClick={() => onSubmit()}>
            Charge
          </FormSubmitButton>
        </FormButtonsContainer>
      </ModalFooter>
    </>
  );
};

export default OrderInvoicesChargeModal;
