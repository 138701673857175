import { useQuery, useReactiveVar } from '@apollo/client';
import React, { Fragment } from 'react';
import { Controller } from 'react-hook-form';
import { sentenceCase } from 'sentence-case';
import useCheckBranchAvailability from '../../../../hooks/useCheckBranchAvailability';
import useIcons from '../../../../hooks/useIcons';
import { GetBusUserProfile } from '../../../../queries';
import { vars } from '../../../../reactive';
import { convertTo24Hour, getNextDay, getStartOfToday, getUTCTime } from '../../../../utils/dates';
import { getBranchCurrencySymbol } from '../../../../utils/getBranchCurrencySymbol';
import { getKeys } from '../../../../utils/helpers';
import { BOOKING_TYPE } from '../../../../views/Bookings/types';
import { Product } from '../../../../views/Store/types';
import SlotAvailability from '../../../BookingsDrawer/SlotAvailability';
import { DatesContainer } from '../../../Calendar/Modals/styled';
import { FormCheckbox, FormError, FormInput, FormLabel, FormSelect, RemoveImage, RemoveRowContainer, WideInputGroup } from '../../Forms/Forms';
import ProductsList from '../../Lists/ProductsList';
import { CenteredLoader, VerticallyCenteredLoader } from '../../Spinner';
import { OrderGroupContainer, OrderGroupProductsContainer, OrderGroupProductsWrapper, OrderGroupSessionsContainer, OrderGroupSessionsWrapper, OrderGroupsContainer } from './styled';
import { NewBookingModalFormProps, getProductsItems } from './utils';

const OrderGroups = ({ formOptions, selectedProductsResponse, groupsFullDates, orderGroups, onRemoveSlotGroup, children, products, productModalType }: NewBookingModalFormProps) => {
  const { data: { getBusUserProfile: { Branch: { branchBillingCycle: { billing_date_from = '', billing_date_to = '' } = {} } = {} } = {} } = {} } = useQuery(GetBusUserProfile, {
    fetchPolicy: 'cache-and-network'
  });

  const productsSplitByType = products.reduce(
    (acc, product) => {
      if (product.type === 'subscription') {
        acc.subscriptions.push(product);
      } else if (product.type === 'service') {
        acc.services.push(product);
      } else {
        acc.products.push(product);
      }
      return acc;
    },
    { subscriptions: [], services: [], products: [] } as { subscriptions: Product[]; services: Product[]; products: Product[] }
  );

  const currentPeriod = { start: billing_date_from, end: billing_date_to };
  const { control, errors, watch, setValue, getValues } = formOptions;
  const icons = useIcons();
  const { selectedProducts, loadingSelectedProducts } = selectedProductsResponse;
  const selectedProduct = selectedProducts?.[0];

  const subscriptionProduct = selectedProduct?.type === 'subscription';

  const sessions = Array.from(Array(selectedProduct?.sessions).keys());
  const selectedDate = useReactiveVar(vars.selectedDate);

  const date = selectedDate ? selectedDate?.date : '';

  const selectedSlotDateUTC = selectedDate
    ? new Date(Date.UTC(selectedDate.date?.getFullYear(), selectedDate.date?.getMonth(), selectedDate.date?.getDate(), selectedDate.hour > -1 ? selectedDate.hour : 0, selectedDate.minute || 0))
    : '';

  const defaultDate = date ? new Date(selectedSlotDateUTC).toISOString().slice(0, -14) : '';
  const defaultDateUntil = getNextDay(selectedSlotDateUTC)?.toISOString().slice(0, -14) || '';
  const watchedOrderGroups = watch('orderGroups');
  const watchedDate = watchedOrderGroups.map(({ slots }) => sessions.map(session => slots?.[session]?.date));

  const watchedTime = watchedOrderGroups.map(({ slots }) => sessions.map(session => slots?.[session]?.time));

  const productsItems = getProductsItems({ products: selectedProducts, orderGroups: watchedOrderGroups });

  const { bookingDays, isRecurring, recurringProduct } = productsItems[0] || {};

  const appointments = useReactiveVar(vars.newBookingDefaultAppointments);

  const repetition = Array.from({ length: 52 }, (_, i) => ({ id: i + 1, name: `${i + 1} ${i === 0 ? 'Week' : 'Weeks'}` }));

  const today = getStartOfToday();
  const minPeriod = new Date(currentPeriod?.start);
  const minDate = selectedProduct?.allows_past_slots ? minPeriod : today;

  const isMultiSlot = selectedProduct?.booking_type === BOOKING_TYPE.MULTI_SLOT;

  const {
    loading: loadingAvailability,
    selectedSlotForEachDate,
    selectedDates
  } = useCheckBranchAvailability({
    appointmentsDates: (watchedDate || [])
      .flat()
      ?.filter(Boolean)
      ?.map(date => new Date(new Date(date).setUTCHours(0, 0, 0, 0))),
    skip:
      (watchedDate || [])
        .flat()
        ?.filter(Boolean)
        ?.every(date => !date) || productModalType,
    selectedProduct: selectedProduct,
    branch: selectedProduct?.Branch,
    appointmentDatesWithTime: groupsFullDates.flat()?.map(date => Object.values(date)?.[0]?.fullDate)
  });

  const showAvailability =
    !loadingAvailability &&
    !!selectedSlotForEachDate &&
    !!selectedSlotForEachDate.length &&
    !!selectedProduct?.id &&
    !isMultiSlot &&
    watchedTime?.flat()?.length === orderGroups.fields.length * sessions.length &&
    watchedTime.every(time => time.every(t => t && t?.includes('')));

  const renderRemoveOrderGroupButton = (orderGroup: number) => {
    return (
      orderGroups?.fields?.length > 1 && (
        <RemoveRowContainer onClick={() => onRemoveSlotGroup(orderGroup)} noMargin>
          <RemoveImage src={icons.delete.childImageSharp.gatsbyImageData.images.fallback.src} showMargin={showAvailability} />
        </RemoveRowContainer>
      )
    );
  };

  return (
    <>
      <OrderGroupsContainer>
        {orderGroups.fields.map((field, orderGroup) => {
          const currentOrderGroup = watchedOrderGroups?.[orderGroup];
          const selectedOrderGroupProduct = selectedProducts?.find(({ id }) => id === currentOrderGroup?.productId);
          const orderGroupField = orderGroups?.fields[orderGroup];
          const orderGroupErrors = errors?.orderGroups?.[orderGroup];

          return (
            <OrderGroupContainer key={field.id}>
              <OrderGroupProductsWrapper>
                <OrderGroupProductsContainer>
                  {(productModalType || orderGroup === 0) && (
                    <WideInputGroup>
                      <FormLabel error={errors?.orderGroups?.[orderGroup]?.productId?.message}>Product</FormLabel>
                      {!productModalType && (
                        <Controller
                          render={({ onChange, value }) => (
                            <FormSelect
                              height={48}
                              fontSize={16}
                              fullWidth
                              name={`orderGroups.${orderGroup}.productId`}
                              error={orderGroupErrors?.productId?.message}
                              onChange={e => {
                                const currentValue = getValues()['orderGroups'];
                                setValue('orderGroups', [
                                  {
                                    ...currentValue?.[0],
                                    slots: [],
                                    customizations: productsItems[0].titlesArray.reduce((all, key) => ({ ...all, [key]: '' }), {}),
                                    recurrenceType: 'one-off',
                                    repetition: 1,
                                    bookingDays: [],
                                    quantity: 1,
                                    productId: e.target.value
                                  }
                                ]);
                                onChange(e.target.value);
                              }}
                              value={value || ''}
                            >
                              <option disabled value={''}>
                                -- select a product --
                              </option>
                              {getKeys(productsSplitByType).map(key => (
                                <Fragment key={key}>
                                  {productsSplitByType[key]?.length && (
                                    <optgroup label={sentenceCase(key)}>
                                      {productsSplitByType[key].map(({ id, name }) => (
                                        <option key={id} value={id} disabled={!!watchedOrderGroups?.map(({ productId }) => productId)?.includes(id)}>
                                          {name}
                                        </option>
                                      ))}
                                    </optgroup>
                                  )}
                                </Fragment>
                              ))}
                            </FormSelect>
                          )}
                          control={control}
                          name={`orderGroups.${orderGroup}.productId`}
                          defaultValue={orderGroupField?.productId || appointments?.[0]?.OrderItem.item.id || ''}
                          rules={{
                            required: true,
                            validate: (value: string) => {
                              if (value === '') {
                                return 'Please select a product';
                              }
                            }
                          }}
                        />
                      )}
                      {productModalType && (
                        <ProductsList
                          formOptions={formOptions}
                          name={`orderGroups.${orderGroup}.productId`}
                          onChangeProduct={productId => {
                            const currentValue = getValues()['orderGroups'];
                            setValue(`orderGroups.${orderGroup}`, {
                              ...currentValue[orderGroup],
                              productId,
                              quantity: 1
                            });
                          }}
                          defaultValues={{
                            defaultProductId: orderGroupField?.productId || '',
                            defaultProduct: products?.find(({ id }) => id === orderGroupField?.productId)
                          }}
                          itemsToFilter={products?.filter(({ id }) => selectedProducts?.map(({ id }) => id)?.includes(id))?.map(({ id }) => id) || []}
                        />
                      )}
                      {orderGroupErrors?.productId && <FormError>{orderGroupErrors?.productId?.message || 'Please select a product'}</FormError>}
                    </WideInputGroup>
                  )}
                  {productModalType && !!currentOrderGroup?.productId && (
                    <WideInputGroup maxWidth={100}>
                      <FormLabel error={orderGroupErrors?.quantity?.message}>Quantity</FormLabel>
                      <Controller
                        control={control}
                        name={`orderGroups.${orderGroup}.quantity`}
                        defaultValue={1}
                        render={({ onChange, value }) => (
                          <FormInput
                            height={28}
                            fontSize={16}
                            type={'number'}
                            name={`orderGroups.${orderGroup}.quantity`}
                            error={orderGroupErrors?.quantity?.message}
                            min={0.01}
                            onChange={e => {
                              onChange(e.target.value);
                            }}
                            value={value || ''}
                          />
                        )}
                        rules={{
                          required: true,
                          min: 0.01,
                          validate: (value: string) => {
                            if (value === '') {
                              return 'Please select a quantity';
                            }
                          }
                        }}
                      />
                      {orderGroupErrors?.quantity && <FormError>{orderGroupErrors?.quantity?.message || 'Please select a quantity'}</FormError>}
                    </WideInputGroup>
                  )}
                  {productModalType && renderRemoveOrderGroupButton(orderGroup)}
                </OrderGroupProductsContainer>

                {productModalType && !!currentOrderGroup?.productId && selectedOrderGroupProduct && (
                  <WideInputGroup flexDirection="row" gap={6}>
                    <FormLabel>Price: {currentOrderGroup?.productId && `${getBranchCurrencySymbol()}${selectedOrderGroupProduct?.price}`}</FormLabel>
                    {!!selectedOrderGroupProduct?.PharmaItemProducts?.length && <FormLabel>Pack Size: {selectedOrderGroupProduct?.PharmaItemProducts?.[0]?.PharmaItem?.pack_size}</FormLabel>}
                    <FormLabel>Stock: {selectedOrderGroupProduct?.stock ?? 'Unlimited'}</FormLabel>
                  </WideInputGroup>
                )}

                {(productModalType || orderGroup === 0) && !!Object.keys(selectedProducts.find(({ id }) => watchedOrderGroups[orderGroup]?.productId === id)?.customizations || {}).length && (
                  <WideInputGroup>
                    <FormLabel error={Object.values(orderGroupErrors?.customizations || {})?.find(c => c?.message)?.message}>Variations</FormLabel>
                    {loadingSelectedProducts && <VerticallyCenteredLoader size={15} />}
                    {!loadingSelectedProducts && (
                      <>
                        {productsItems[orderGroup]?.titlesArray?.map((title, index) => (
                          <WideInputGroup key={index}>
                            <Controller
                              control={control}
                              name={`orderGroups.${orderGroup}.customizations.${title}`}
                              defaultValue={orderGroupField?.customizations?.[title] || ''}
                              render={({ onChange, value }) => (
                                <>
                                  <FormLabel error={orderGroupErrors?.customizations?.[title]?.message}>{title}</FormLabel>
                                  <FormSelect
                                    height={48}
                                    fontSize={16}
                                    name={`orderGroups.${orderGroup}.customizations.${title}`}
                                    error={!!orderGroupErrors?.customizations}
                                    onChange={e => {
                                      onChange(e.target.value);
                                    }}
                                    value={value || ''}
                                  >
                                    <option disabled value={''}>
                                      -- select a variation --
                                    </option>

                                    {productsItems?.[orderGroup]?.customizationsList[index].map((customization, i) => (
                                      <option key={i} value={customization.name}>
                                        {customization.name}
                                      </option>
                                    ))}
                                  </FormSelect>
                                </>
                              )}
                              rules={{
                                required: true,
                                validate: (value: any) => {
                                  if (value.length === 0) {
                                    return 'Please select a variation';
                                  }
                                }
                              }}
                            />
                          </WideInputGroup>
                        ))}
                      </>
                    )}
                    {orderGroupErrors?.customizations && <FormError>{Object.values(orderGroupErrors?.customizations || {})?.find(c => c?.message)?.message || 'Please select a variation'}</FormError>}
                  </WideInputGroup>
                )}

                {orderGroup === 0 && recurringProduct && (
                  <WideInputGroup>
                    <FormLabel error={errors?.orderGroups?.[0]?.recurrenceType?.message}>Type of booking</FormLabel>
                    <Controller
                      control={control}
                      name={`orderGroups.0.recurrenceType`}
                      defaultValue={orderGroups?.fields[0]?.recurrenceType || 'one-off'}
                      render={({ onChange, value }) => (
                        <FormSelect
                          height={48}
                          fontSize={16}
                          name={`orderGroups.0.recurrenceType`}
                          error={errors?.orderGroups?.[0]?.recurrenceType?.message}
                          onChange={e => {
                            const currentValue = getValues()['orderGroups']?.[0];
                            setValue('orderGroups', [{ ...currentValue, slots: [], recurrenceType: e.target.value, repetition: 1 }]);
                            onChange(e.target.value);
                          }}
                          value={value || ''}
                        >
                          <option value={'one-off'}>Ad-Hoc</option>
                          <option value={'recurring'}>Repeated</option>
                        </FormSelect>
                      )}
                    />
                  </WideInputGroup>
                )}

                {orderGroup === 0 && isRecurring && (
                  <WideInputGroup>
                    <FormLabel error={(errors?.orderGroups?.[0]?.bookingDays || [])?.find(e => !!e?.message)?.message}>Booking Days</FormLabel>
                    <Controller
                      control={control}
                      name={'orderGroups.0.bookingDays'}
                      defaultValue={[]}
                      render={({ onChange, value }) => (
                        <FormCheckbox
                          fontSize={16}
                          error={(errors?.orderGroups?.[0]?.bookingDays || [])?.find(e => !!e?.message)?.message}
                          onChange={onChange}
                          value={value || ''}
                          itemsArray={bookingDays.map(({ day, isAvailable }) => ({ id: day, name: day, disabled: !isAvailable }))}
                        />
                      )}
                      rules={{ required: true, validate: (value: any) => value.length > 0 || 'Please select at least one day' }}
                    />
                  </WideInputGroup>
                )}
              </OrderGroupProductsWrapper>
              {!productModalType && !subscriptionProduct && (
                <OrderGroupSessionsWrapper>
                  <OrderGroupSessionsContainer>
                    {sessions?.map((session, index) => (
                      <WideInputGroup key={index} marginBottom={0}>
                        <DatesContainer>
                          <WideInputGroup>
                            <FormLabel error={orderGroupErrors?.slots?.[index]?.date?.message}>
                              {isRecurring
                                ? 'Start Date'
                                : selectedProduct?.sessions > 1
                                ? `Session ${session + 1}`
                                : selectedProduct?.booking_type === BOOKING_TYPE.MULTI_DAY
                                ? 'Booking From'
                                : 'Date'}
                            </FormLabel>
                            <Controller
                              render={({ onChange, value }) => (
                                <FormInput
                                  error={orderGroupErrors?.slots?.[index]?.date?.message}
                                  type={'date'}
                                  height={32}
                                  fontSize={16}
                                  name={`orderGroups.${orderGroup}.slots.${index}.date`}
                                  onChange={e => {
                                    onChange(e.target.value);
                                  }}
                                  value={value || ''}
                                  // min={minDate.toISOString().split('T')[0]}
                                />
                              )}
                              control={control}
                              name={`orderGroups.${orderGroup}.slots.${index}.date`}
                              defaultValue={index === 0 && defaultDate}
                              rules={{
                                required: {
                                  value: true,
                                  message: 'Please select a date'
                                }
                                // validate: (value: string) => {
                                // const selectedDate = new Date(value);
                                // selectedDate.setUTCHours(0, 0, 0, 0);
                                // if (selectedDate.getTime() < minDate.getTime()) {
                                //   return "Date can't be in the past";
                                // }
                                // }
                              }}
                            />
                            {orderGroupErrors?.slots?.[index]?.date?.message && <FormError>{orderGroupErrors?.slots?.[index]?.date?.message || 'Please enter a valid date'}</FormError>}
                          </WideInputGroup>

                          {selectedProduct?.booking_type !== BOOKING_TYPE.MULTI_DAY && (
                            <WideInputGroup>
                              <FormLabel error={orderGroupErrors?.slots?.[index]?.time?.message}>Time</FormLabel>
                              {(!selectedProduct?.allows_past_slots ||
                                (selectedProduct?.allows_past_slots && new Date(watchedDate[orderGroup]?.[index]).setUTCHours(0, 0, 0, 0) > today.getTime())) && (
                                <>
                                  {loadingAvailability && !field?.slots?.[index]?.time && <CenteredLoader size={20} />}
                                  {(!loadingAvailability || field?.slots?.[index]?.time) && (
                                    <Controller
                                      render={({ onChange, value }) => (
                                        <FormSelect
                                          height={48}
                                          fontSize={16}
                                          name={`orderGroups.${orderGroup}.slots.${index}.time`}
                                          onChange={e => {
                                            onChange(e.target.value);
                                          }}
                                          value={value || ''}
                                        >
                                          <option value={''}>{loadingAvailability ? 'Loading ...' : '-- select a time --'}</option>
                                          {(selectedDates || [])
                                            .find(d => {
                                              return new Date(d?.fullDate).toLocaleDateString() === new Date(`${watchedDate[orderGroup]?.[index]} 00:00:00`).toLocaleDateString();
                                            })
                                            ?.slots?.map((slot, i) => (
                                              <option key={i} value={convertTo24Hour(getUTCTime(slot))}>
                                                {getUTCTime(slot)}
                                              </option>
                                            ))}
                                        </FormSelect>
                                      )}
                                      control={control}
                                      name={`orderGroups.${orderGroup}.slots.${index}.time`}
                                      defaultValue={
                                        field?.slots?.[index]?.time && selectedDates[index]?.slots?.some(slot => convertTo24Hour(slot.timestamp) === field?.slots?.[index]?.time)
                                          ? field?.slots?.[index]?.time
                                          : ''
                                      }
                                      rules={{
                                        required: {
                                          value: true,
                                          message: 'Please select a time'
                                        }
                                      }}
                                    />
                                  )}
                                </>
                              )}

                              {selectedProduct?.allows_past_slots && new Date(watchedDate[orderGroup]?.[index]).setUTCHours(0, 0, 0, 0) <= today.getTime() && (
                                <Controller
                                  render={({ onChange, value }) => (
                                    <FormInput
                                      error={orderGroupErrors?.slots?.[index]?.time?.message}
                                      type={'time'}
                                      height={32}
                                      fontSize={16}
                                      name={`orderGroups.${orderGroup}.slots.${index}.time`}
                                      onChange={e => {
                                        onChange(e.target.value);
                                      }}
                                      value={value || ''}
                                    />
                                  )}
                                  control={control}
                                  name={`orderGroups.${orderGroup}.slots.${index}.time`}
                                  rules={{
                                    required: {
                                      value: true,
                                      message: 'Please select a time'
                                    }
                                  }}
                                />
                              )}
                              {orderGroupErrors?.slots?.[index]?.time?.message && <FormError>{orderGroupErrors?.slots?.[index]?.time?.message || 'Please enter a valid time'}</FormError>}
                            </WideInputGroup>
                          )}
                          {selectedProduct?.booking_type === BOOKING_TYPE.MULTI_DAY && (
                            <WideInputGroup>
                              <FormLabel error={orderGroupErrors?.slots?.[index]?.dateUntil?.message}>Booking until</FormLabel>
                              <Controller
                                as={<FormInput error={orderGroupErrors?.slots?.[index]?.dateUntil?.message} type={'date'} height={32} fontSize={16} />}
                                control={control}
                                name={`orderGroups.${orderGroup}.slots.${index}.dateUntil`}
                                defaultValue={index === 0 && defaultDateUntil}
                                rules={{
                                  required: {
                                    value: true,
                                    message: 'Please select a date'
                                  },
                                  validate: (value: string) => {
                                    const dateUntil = new Date(value);
                                    const selectedStartDate = new Date(watchedDate[orderGroup][index]);
                                    if (dateUntil.getTime() < selectedStartDate.getTime()) {
                                      return "Date can't be before the start date";
                                    }
                                  }
                                }}
                              />
                              {orderGroupErrors?.slots?.[index]?.dateUntil?.message && <FormError>{orderGroupErrors?.slots?.[index]?.dateUntil?.message || 'Please enter a valid date'}</FormError>}
                            </WideInputGroup>
                          )}
                        </DatesContainer>
                        {showAvailability && <SlotAvailability index={index} selectedSlotForEachDate={selectedSlotForEachDate} bookingDates={selectedDates} />}
                      </WideInputGroup>
                    ))}
                  </OrderGroupSessionsContainer>
                  {renderRemoveOrderGroupButton(orderGroup)}
                </OrderGroupSessionsWrapper>
              )}
            </OrderGroupContainer>
          );
        })}

        {children}
      </OrderGroupsContainer>
      {isRecurring && (
        <WideInputGroup>
          <FormLabel error={errors?.orderGroups?.[0]?.repetition?.message}>Repeat For</FormLabel>
          <Controller
            render={({ onChange, value }) => (
              <FormSelect
                height={48}
                fontSize={16}
                name={`orderGroups.0.repetition`}
                error={errors?.orderGroups?.[0]?.repetition?.message}
                onChange={e => {
                  onChange(e.target.value);
                }}
                value={value || ''}
              >
                {repetition.map((option, index) => (
                  <option key={index} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </FormSelect>
            )}
            control={control}
            name={`orderGroups.0.repetition`}
            defaultValue={orderGroups?.fields[0]?.repetition || repetition[0]?.id}
            rules={{
              required: {
                value: true,
                message: 'Please select a repetition'
              }
            }}
          />
          {errors?.orderGroups?.[0]?.repetition && <FormError>{errors?.orderGroups?.[0]?.repetition.message}</FormError>}
        </WideInputGroup>
      )}
    </>
  );
};

export default OrderGroups;
