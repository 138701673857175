import React from 'react';
import { DRAWER_IDS } from '../../components/DrawerBar/types';
import useIcons from '../../hooks/useIcons';
import useMediaQuery from '../../hooks/useMediaQuery';
import { setDrawerBar } from '../../reactive/actions';
import { toReadableDate } from '../../utils/dates';
import { OrderDataContainer } from '../Orders/styled';
import { SubmittedForm } from '../Store/BranchForms/types';
import { AppUserImage, AppUserImageContainer, AppUserImageNameContainer, AppUserName, RecordBody, RecordDataContainer } from '../styled';

const PetsFormRecord = ({ submittedForm }: { submittedForm: SubmittedForm }) => {
  const icons = useIcons();

  const handleClick = () => {
    setDrawerBar({
      drawerId: DRAWER_IDS.SUBMITTED_FORM_DRAWER,
      recordData: submittedForm?.id
    });
  };

  const { mobile } = useMediaQuery({ mobile: true });
  const appUserName = submittedForm?.AppUser?.name || 'No Name';
  const orderNumber = submittedForm?.Order?.number || '-';
  const formName = submittedForm?.Form?.name || 'Completed Form';
  const time = toReadableDate(submittedForm?.createdAt, { isLocale: true });
  const answers = submittedForm?.Answers;
  const selectedPetRecordsIds = [...new Set(answers?.map(answer => answer?.PetRecordId))].filter(id => !!id);
  const selectedPetRecords = selectedPetRecordsIds?.map(id => answers?.find(answer => answer?.PetRecordId === id)?.PetRecord);
  const petsNames = selectedPetRecords?.map(petRecord => petRecord?.Pet?.name).join(', ') || '-';

  const recordData = (
    <>
      {mobile && (
        <RecordBody>
          <AppUserName noMargin>{appUserName}</AppUserName>
        </RecordBody>
      )}
      {!mobile && <RecordBody fontWeight={'800'}>{orderNumber}</RecordBody>}
      <RecordBody>{formName}</RecordBody>
      <RecordBody>{time}</RecordBody>
      <RecordBody>{petsNames}</RecordBody>
    </>
  );

  return (
    <RecordDataContainer onClick={handleClick}>
      <RecordBody>
        {!mobile && (
          <AppUserImageNameContainer>
            <AppUserImageContainer>
              <AppUserImage src={submittedForm?.AppUser?.profile_pic || icons.user.childImageSharp.gatsbyImageData.images.fallback.src} index={0} />
            </AppUserImageContainer>
            {!mobile && <AppUserName>{appUserName}</AppUserName>}
          </AppUserImageNameContainer>
        )}
      </RecordBody>
      {mobile ? <OrderDataContainer>{recordData}</OrderDataContainer> : recordData}
    </RecordDataContainer>
  );
};

export default PetsFormRecord;
