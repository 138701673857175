import React from 'react';
import useIcons from '../../hooks/useIcons';
import { MessageReadStatus } from './MessageItem';
import styled from 'styled-components';

const Container = styled.div<{ isFromUser: boolean }>`
  display: flex;
  justify-content: ${props => (props.isFromUser ? 'flex-end' : 'flex-start')};
`;
const Image = styled.img`
  width: 10px;
  height: 10px;
  border-radius: 5px;
  align-self: flex-end;
  margin-left: 2px;
`;

export default function MessageReadStatusIndicator({ isFromUser, foundMessageReadStatuses }: { isFromUser: boolean; foundMessageReadStatuses: MessageReadStatus[] }) {
  const icons = useIcons();
  return (
    <Container isFromUser={isFromUser}>
      {!!foundMessageReadStatuses?.length &&
        foundMessageReadStatuses.map(messageReadStatus => (
          <Image
            key={messageReadStatus?.id}
            src={messageReadStatus?.AppUser?.profile_pic || messageReadStatus?.BusUser?.profile_pic || icons?.user?.childImageSharp?.gatsbyImageData?.images?.fallback?.src}
            alt={messageReadStatus?.AppUser?.name || messageReadStatus?.BusUser?.name || 'No User'}
            title={messageReadStatus?.AppUser?.name || messageReadStatus?.BusUser?.name || 'No User'}
          />
        ))}
    </Container>
  );
}
