import React, { FC, useEffect } from 'react';
import RadioSwitch from '../../components/Shared/Forms/RadioSwitch';
import RefreshButton from '../../components/Shared/RefreshButton';
import { CenteredLoader } from '../../components/Shared/Spinner';
import useCheckSelectedTabAndRefetch from '../../hooks/useCheckSelectedTabAndRefetch';
import usePaginatedQuery from '../../hooks/usePaginatedQuery';
import { useRefetchSubscriptionsOnFiltersChange } from '../../hooks/useRefetchOnFiltersChange';
import { GetBranchOrderSubscriptions } from '../../queries';
import { manageSubscriptionsFilters } from '../../reactive/actions';
import Common from '../Store/Common';
import { RecordsContainer } from '../styled';
import SubscriptionsList from './SubscriptionsList';
import { GroupHeader } from './styled';
import { SUBSCRIPTION_STATUS_TYPES, SUBSCRIPTION_TABS_TYPES, Subscription } from './types';

type SubscriptionsBodyProps = {
  selectedTabsState: [string, React.Dispatch<React.SetStateAction<string>>];
  tabs: { name: string; status: string[]; otherVariables: Record<string, any> }[];
};

const SubscriptionsBody: FC<SubscriptionsBodyProps> = ({ tabs, selectedTabsState }) => {
  const { addHeaderAction, clearHeaderAction } = manageSubscriptionsFilters;
  const [selected] = selectedTabsState;
  const isCanceled = selected === SUBSCRIPTION_TABS_TYPES.CANCELED;
  const paginatedQueryResult = usePaginatedQuery<Subscription[]>({
    query: GetBranchOrderSubscriptions,
    defaultStatus: [SUBSCRIPTION_STATUS_TYPES.CONFIRMED],
    limit: 20,
    otherVariables: {
      requisite_queries: [],
      alternative_queries: []
    }
  });

  const [[_hasMoreItems, setHasMoreItems], queryResult] = paginatedQueryResult;

  const { refetch, variables: previousVariables } = queryResult;

  const checkSelectedTabAndRefetch = useCheckSelectedTabAndRefetch<Subscription>({
    tabs,
    queryResult,
    setHasMoreItems,
    selected
  });

  const action = (
    <>
      <RefreshButton checkSelectedTabAndRefetch={checkSelectedTabAndRefetch} setHasMoreItems={setHasMoreItems} />
      <RadioSwitch
        onChange={(on: boolean) => {
          setHasMoreItems(true);
          refetch(
            isCanceled
              ? {
                  order_by_end_date: on,
                  order_by_name: false
                }
              : {
                  order_by_name: on,
                  order_by_end_date: false
                }
          );
        }}
      />
    </>
  );

  const group = () => ({
    by: (item: any) => item.is_read_by_branch,
    separator(seperatedItems: Subscription[]) {
      if (selected === SUBSCRIPTION_TABS_TYPES.CANCELED) {
        return <GroupHeader>Cancelled Memberships</GroupHeader>;
      }

      if (selected === SUBSCRIPTION_TABS_TYPES.PAUSED) {
        return <GroupHeader>Paused Memberships</GroupHeader>;
      }

      return <GroupHeader>{seperatedItems![0]?.is_read_by_branch ? 'Active Memberships' : 'New Memberships'}</GroupHeader>;
    }
  });

  useEffect(() => {
    Common.set(`Subscriptions.GetOrderSubscriptions.refetch`, () => {
      refetch?.().then(() => setHasMoreItems(true));
    });
  }, []);

  useEffect(() => {
    addHeaderAction({ action, id: 'refresh' });
    return () => {
      clearHeaderAction({ id: 'refresh' });
    };
  }, [selected]);

  const selectedTab = tabs.find(tab => tab.name === selected);

  const loadingFilters = useRefetchSubscriptionsOnFiltersChange({
    refetch,
    defaultVariables: { ...selectedTab?.otherVariables, status: selectedTab?.status, limit: 20 } || {},
    setHasMoreItems,
    previousVariables
  });

  return (
    <RecordsContainer>
      {loadingFilters && <CenteredLoader />}
      {!loadingFilters && (
        <SubscriptionsList paginatedQueryResult={paginatedQueryResult} reverseItems={[SUBSCRIPTION_STATUS_TYPES.CANCELED].includes(selected)} group={group} showTotal={{ orderTotal: true }} />
      )}
    </RecordsContainer>
  );
};

export default SubscriptionsBody;
