import React, { useRef } from 'react';
import { StoreEditButton } from '../../../components/DrawerBar/DrawerButtons';
import { DRAWER_IDS } from '../../../components/DrawerBar/types';
import { BranchTag, TAGS_AUTOMATIONS_TYPES_LABELS } from './types';
import { HoverRecordOptionsContainer, RecordBody, RecordDataContainer } from '../../styled';

const BranchTagsRecord = ({ branchTag, navigateDrawer }: { branchTag: BranchTag; navigateDrawer: ({ drawerLinkId }: { drawerLinkId: string }) => void }) => {
  const storeEditButtonRef = useRef<{ handleClick: () => void }>(null);

  const handleClick = () => {
    // storeEditButtonRef.current?.handleClick();
    // navigateDrawer({ drawerLinkId: branchTag?.id });
  };

  const tagType = branchTag?.hasOwnProperty('petRecordsCount') ? 'Pet Tag' : branchTag?.hasOwnProperty('appointmentsCount') ? 'Appointment Tag' : 'App User Tag';

  return (
    <RecordDataContainer onClick={handleClick}>
      <RecordBody fontWeight={'800'}>{branchTag?.name}</RecordBody>
      <RecordBody>{tagType}</RecordBody>
      <RecordBody>Quick Action: {!!branchTag?.quick_action ? 'Yes' : 'No'}</RecordBody>
      <RecordBody>{branchTag?.automations?.map(automation => TAGS_AUTOMATIONS_TYPES_LABELS[automation?.type]).join(', ')}</RecordBody>

      <HoverRecordOptionsContainer>
        <StoreEditButton drawer={{ drawerId: DRAWER_IDS.BRANCH_TAG_DRAWER, recordData: branchTag }} ref={storeEditButtonRef} />
      </HoverRecordOptionsContainer>
    </RecordDataContainer>
  );
};

export default BranchTagsRecord;
