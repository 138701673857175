import React, { useCallback, useState, Fragment } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { FormError, FormSelect, RemoveRowContainer, FormCheckbox, FormHeaderLabel } from '../../../components/Shared/Forms/Forms';
import { AddNewBtn, AddNewBtnIcon } from '../Products/styled';
import useIcons from '../../../hooks/useIcons';
import { CheckboxContainer, QuestionCategoriesContainer, QuestionContainer, QuestionRowWrapper, QuestionTypeContainer } from './styled';
import { RemoveAddedImage } from '../styled';
import { BranchForm, FORM_QUESTION } from './types';
import { Divider } from '../../Pets/Health/styled';
import { useQuery } from '@apollo/client';
import { GetQuestionsCategories } from '../../../queries';
import { CenteredLoader } from '../../../components/Shared/Spinner';

const QuestionRow = ({
  index,
  defaultValue,
  categoryQuestions,
  formOptions
}: {
  index: number;
  defaultValue: string[];
  categoryQuestions: { id: string; title: string }[];
  formOptions: ReturnType<typeof useForm>;
}) => {
  const { control, errors } = formOptions;
  return (
    <QuestionRowWrapper>
      <Controller
        control={control}
        name={`questionCategories[${index}].questions`}
        render={({ onChange, value }) => {
          return (
            <CheckboxContainer>
              <FormCheckbox
                onChange={onChange}
                value={value}
                itemsArray={categoryQuestions.map(({ id, title }) => ({ id, name: title }))}
                error={errors?.questionCategories?.[index]?.questions}
                fontSize={16}
                column
              >
                <Divider />
              </FormCheckbox>
            </CheckboxContainer>
          );
        }}
        defaultValue={defaultValue || []}
        rules={{ required: true, validate: value => value?.length > 0 }}
      />
      {errors?.questionCategories?.[index]?.questions && <FormError>Please select at least 1 question</FormError>}
    </QuestionRowWrapper>
  );
};

const QustionCategory = ({
  index,
  formOptions,
  removeRow,
  selectedQuestionCategories,
  defaultValues,
  allCategories
}: {
  index: number;
  formOptions: ReturnType<typeof useForm>;
  removeRow: (index: number) => void;
  selectedQuestionCategories: { category: string; questions: string[] }[];
  defaultValues: { category: string; questions: string[] }[];
  allCategories: FORM_QUESTION['QuestionCategories'];
}) => {
  const { control, errors } = formOptions;
  const icons = useIcons();
  const selectedQuestionCategory = selectedQuestionCategories?.[index]?.category;
  const categoryQuestions = allCategories.find(({ id }) => id === selectedQuestionCategory)?.Questions || [];

  return (
    <Fragment key={index}>
      <Controller
        control={control}
        name={`questionCategories[${index}].category`}
        rules={{ required: true }}
        render={({ onChange, value }) => {
          return (
            <QuestionContainer>
              <QuestionTypeContainer>
                <FormSelect
                  height={48}
                  fontSize={16}
                  fullWidth
                  name={`questionCategories[${index}].category`}
                  onChange={e => {
                    onChange(e.target.value);
                  }}
                  value={String(value)}
                >
                  <option disabled value="">
                    -- Select a question category --
                  </option>
                  {allCategories.map(({ id, name: categoryName }) => {
                    return (
                      <option key={id} value={id} disabled={selectedQuestionCategories?.some(selectedQuestion => selectedQuestion.category === id)}>
                        {categoryName}
                      </option>
                    );
                  })}
                </FormSelect>
                <RemoveRowContainer>
                  <RemoveAddedImage src={icons.delete.childImageSharp.gatsbyImageData.images.fallback.src} onClick={() => removeRow(index)} />
                </RemoveRowContainer>
              </QuestionTypeContainer>
              {selectedQuestionCategory && (
                <QuestionRow
                  index={index}
                  categoryQuestions={categoryQuestions}
                  formOptions={formOptions}
                  defaultValue={defaultValues?.find(item => item.category === selectedQuestionCategory)?.questions || []}
                />
              )}
            </QuestionContainer>
          );
        }}
        defaultValue={defaultValues[index]?.category || ''}
      />
      {errors?.questionCategories?.[index]?.category && <FormError>Please select a question category</FormError>}
    </Fragment>
  );
};

const BranchFormQuestions = ({ formOptions, defaultValues }: { formOptions: ReturnType<typeof useForm>; defaultValues: BranchForm['Questions'] }) => {
  const { control } = formOptions;
  const { data: { questionCategoryGet: allCategories = [] } = {} } = useQuery(GetQuestionsCategories, {
    fetchPolicy: 'cache-and-network'
  });
  const selectedDefaultCategories = [...new Set(defaultValues?.map(({ QuestionCategories }) => QuestionCategories?.[0]?.id) || [])];
  const [addedRows, setAddedRows] = useState<{ index: number }[]>(selectedDefaultCategories.map((_, index) => ({ index })));
  const selectedDefaultQuestionCategories = selectedDefaultCategories.map(categoryId => {
    const categoryQuestions = defaultValues?.filter(({ QuestionCategories }) => QuestionCategories?.[0]?.id === categoryId);
    return {
      category: categoryId,
      questions: categoryQuestions?.map(({ id }) => id) || []
    };
  });

  const selectedQuestionCategories = useWatch<{ category: string; questions: string[] }[]>({ control: control, name: 'questionCategories', defaultValue: selectedDefaultQuestionCategories });
  const icons = useIcons();

  const addRow = useCallback(() => {
    const index = addedRows.length;
    if (index > 0) {
      const previousRow = selectedQuestionCategories?.[index - 1];
      if (!previousRow?.category) {
        return;
      }
    }

    setAddedRows([...addedRows, { index }]);
  }, [addedRows, selectedQuestionCategories]);

  const removeRow = useCallback(
    (index: number) => {
      setAddedRows(addedRows.filter(row => row.index !== index));
    },
    [addedRows]
  );
  const hasAddedRows = !!addedRows.length;
  return (
    <QuestionCategoriesContainer>
      {hasAddedRows && (
        <>
          <Divider />
          <FormHeaderLabel fontSize={18}>Questions</FormHeaderLabel>
        </>
      )}
      {addedRows.map(({ index }) => (
        <QustionCategory
          key={index}
          index={index}
          formOptions={formOptions}
          removeRow={removeRow}
          selectedQuestionCategories={selectedQuestionCategories}
          defaultValues={selectedDefaultQuestionCategories}
          allCategories={allCategories}
        />
      ))}
      {hasAddedRows && <Divider />}
      <AddNewBtn onClick={addRow} noSpaceArround>
        <AddNewBtnIcon src={icons.addPhoto.childImageSharp.gatsbyImageData.images.fallback.src} />
        Add question category
      </AddNewBtn>
    </QuestionCategoriesContainer>
  );
};

export default BranchFormQuestions;
