import styled from 'styled-components';
import Colors from '../../../../Colors';

export const GroomingReportNotesContainer = styled.div`
  padding-top: 8px;
  padding-right: 8px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const GroomingReportNoteContainer = styled.div`
  padding: 16px;
  border-radius: 24px;
  &:hover {
    background-color: #ededed;
  }
  margin-top: 8px;
  border: 2px solid #e9e9e9;
`;

export const ReportNoteSubCategoryContainer = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 14px;
`;

export const ReportNoteSubCategoryTitle = styled.span`
  font-weight: 800;
`;
