import { useQuery } from '@apollo/client';
import React, { useEffect, useRef } from 'react';
import { DRAWER_IDS } from '../../../components/DrawerBar/types';
import PetRecord from '../../../components/Pets/PetRecord';
import { Pet } from '../../../components/Pets/types';
import InfiniteList from '../../../components/Shared/InfiniteList/InfiniteList';
import { CenteredLoader } from '../../../components/Shared/Spinner';
import useCheckSelectedTabAndRefetch from '../../../hooks/useCheckSelectedTabAndRefetch';
import useNavigateDrawer from '../../../hooks/useNavigateDrawer';
import usePaginatedQuery from '../../../hooks/usePaginatedQuery';
import usePetsListBulkActions, { PetsBulkActionsRef } from '../../../hooks/usePetsListBulkActions';
import { useRefetchPetsOnFiltersChange } from '../../../hooks/useRefetchOnFiltersChange';
import useSetDrawerOnNavigation from '../../../hooks/useSetDrawerOnNavigation';
import { GetBranchPetRecordTags, GetBranchPetsReduced, GetBusUserProfile } from '../../../queries';
import { calculateAgeFromBirthdate } from '../../../utils/dates';
import { areObjectsEqual } from '../../../utils/helpers';
import { BranchPetRecordTag } from '../../Store/BranchTags/types';
import Common from '../../Store/Common';
import { RecordsContainer } from '../../styled';
import { PETS_TABS_TYPES } from '../Pets';

const PetsBody = ({ selectedTabState }: { selectedTabState: [PETS_TABS_TYPES, React.Dispatch<React.SetStateAction<PETS_TABS_TYPES>>] }) => {
  const [selected] = selectedTabState;

  const [[hasMoreItems, setHasMoreItems], queryResult] = usePaginatedQuery<Pet[]>({
    query: GetBranchPetsReduced,
    limit: 20,
    otherVariables: {
      daycare_filter: false,
      vaccination_filter: false,
      treatment_filter: false,
      weight_filter: false,
      neutering_filter: false,
      age_filter: false,
      requisite_queries: [],
      alternative_queries: []
    }
  });

  const { data: { getBranchPets: pets = [] } = {}, loading, fetchMore, refetch, variables: previousVariables } = queryResult;

  const { data: { getBusUserProfile: profile = {} } = {} } = useQuery(GetBusUserProfile);

  const { data: { getBranchPetRecordTags: allTags = [] } = {} } = useQuery<{ getBranchPetRecordTags: BranchPetRecordTag[] }>(GetBranchPetRecordTags, {
    fetchPolicy: 'cache-and-network',
    variables: { offset: 0, limit: 1000 }
  });

  const quickTags = allTags?.filter(tag => tag?.quick_action) || [];
  const tagsVisibleOnItem = allTags?.filter(tag => tag?.visibility?.showOnTaggedItem) || [];

  useSetDrawerOnNavigation({
    itemList: [],
    drawerId: DRAWER_IDS.PETS_DRAWER
  });

  const tabs: { name: PETS_TABS_TYPES; otherVariables: Record<string, any> }[] = [{ name: PETS_TABS_TYPES.ALL, otherVariables: {} }];

  useCheckSelectedTabAndRefetch({
    tabs,
    queryResult,
    setHasMoreItems,
    selected
  });
  const navigateDrawer = useNavigateDrawer({
    drawerData: {
      drawerId: DRAWER_IDS.PETS_DRAWER
    }
  });

  const loadingFilters = useRefetchPetsOnFiltersChange({ refetch, defaultVariables: { ...tabs.find(tab => tab.name === selected)!.otherVariables, limit: 20 }, setHasMoreItems, previousVariables });

  useEffect(() => {
    Common.set(`Pets.GetBranchPets.refetch`, (...args: any[]) => {
      return new Promise(async res => {
        await refetch?.(...args).then(() => setHasMoreItems(true));
        res(0);
      });
    });
  }, []);

  const petsList = pets?.map((pet: any) => ({ ...pet, age: calculateAgeFromBirthdate(pet?.birthdate) }));

  const petsBulkActionsRef = useRef<PetsBulkActionsRef>(null);

  const { bulkSelect } = usePetsListBulkActions({
    pets: petsList,
    ref: petsBulkActionsRef,
    refetch
  });

  useEffect(() => {
    petsBulkActionsRef?.current?.clearBulkActions?.();
    petsBulkActionsRef?.current?.hideBulkActions?.();
  }, [selected]);

  return (
    <RecordsContainer>
      {loadingFilters && <CenteredLoader />}
      {!loadingFilters && (
        <InfiniteList
          list={petsList}
          itemRenderer={(pet: Pet) => (
            <PetRecord key={pet?.id} pet={pet} profile={profile} navigateDrawer={navigateDrawer} quickTags={quickTags} bulkSelect={bulkSelect} tagsVisibleOnItem={tagsVisibleOnItem} />
          )}
          hasMoreItems={hasMoreItems}
          loading={loading || loadingFilters}
          fetchMore={fetchMore as any}
          offset={pets?.length}
          setHasMoreItems={setHasMoreItems}
        />
      )}
    </RecordsContainer>
  );
};

export default React.memo(PetsBody, areObjectsEqual);
