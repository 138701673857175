import { useEffect, useState } from 'react';

type useWatchFormStateProps = {
  defaultValue: any;
};
const useWatchFormState = ({ defaultValue }: useWatchFormStateProps) => {
  const [observable, setObservable] = useState(defaultValue);
  useEffect(() => {
    if (defaultValue) {
      setObservable(defaultValue);
    }
  }, [defaultValue]);

  return [observable, setObservable];
};

export default useWatchFormState;
