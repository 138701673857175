import React, { Fragment, useState } from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import RRule from 'rrule';
import { FormError, FormInput, InputContainer, InputsRow, RadioBtnsGroup, RemoveRowContainer, SectionLabel, WideInputGroup } from '../../../components/Shared/Forms/Forms';
import useIcons from '../../../hooks/useIcons';
import { WEEK_DAYS_MAP, rruleToReadableTime } from '../../../utils/dates';
import { AddNewBtn, AddNewBtnIcon } from '../Products/styled';
import { RemoveAddedImage } from '../styled';
import transformOperatingSlotsState from '../transformOperatingSlotsState';
import DaysLabels from './DaysLabels';
import { OperationMaxSlotContainer, OperationMaxSlots, OperationMaxSlotsContainer } from './styled';

type RowComponentProps = {
  data: any;
  control: Control;
  errors: FieldErrors;
  idx: number;
  hideAvailability?: boolean;
  isRecurring?: boolean;
  setCustomTypes: (items: any[]) => void;
};

const RowComponent = ({ data, errors, control, idx, hideAvailability, isRecurring, setCustomTypes }: RowComponentProps) => {
  return (
    <Fragment key={idx}>
      <InputContainer width={16}>
        <Controller
          as={<FormInput error={errors[`custom_type_operating_${idx}_time`]} height={20} fontSize={16} type={'time'} width={80} />}
          control={control}
          name={`custom_type_operating_${idx}_time`}
          defaultValue={data?.time || ''}
          rules={{ required: true, validate: val => val.split(':')[0] !== '00' }}
        />
        {errors[`custom_type_operating_${idx}_time`] && <FormError>{errors[`custom_type_operating_${idx}_time`].message || 'Time is required'}</FormError>}
      </InputContainer>
      {!hideAvailability && (
        <InputContainer width={9}>
          <Controller
            as={<FormInput type="number" error={errors[`custom_type_operating_${idx}_slots`]} height={20} fontSize={16} />}
            control={control}
            name={`custom_type_operating_${idx}_slots`}
            defaultValue={data?.slots || 0}
            rules={{ required: true }}
          />
          {errors[`custom_type_operating_${idx}_slots`] && <FormError>{errors[`custom_type_operating_${idx}_slots`].message || 'Slots is required'}</FormError>}
        </InputContainer>
      )}
      <InputContainer isRow flex={1}>
        <Controller
          render={({ onChange, value }) => (
            <RadioBtnsGroup
              options={WEEK_DAYS_MAP}
              defaultValue={data?.days || []}
              onChange={e => {
                const newValue = value.includes(e.target.value) ? [...value].filter(v => v !== e.target.value) : [...value, e.target.value];
                onChange(newValue);

                if (isRecurring) {
                  const items = transformOperatingSlotsState(control.getValues());
                  items.forEach((item, idx) => {
                    control.setValue(`custom_type_operating_${idx}_days`, newValue);
                  });
                  requestAnimationFrame(() => {
                    const items = transformOperatingSlotsState(control.getValues());
                    setCustomTypes(items);
                  });
                }
              }}
              inputType={'checkbox'}
              name={`custom_type_operating_${idx}_days`}
              noLabel
            />
          )}
          control={control}
          name={`custom_type_operating_${idx}_days`}
          defaultValue={data?.days || []}
        />
      </InputContainer>
    </Fragment>
  );
};

type OperatingSlotsProps = {
  operatingTimes: any[];
  control: Control;
  errors: FieldErrors;
  title?: string;
  hideAvailability?: boolean;
  defaultMaxBookingSlots?: { [key: string]: number };
  hideMaxSlots?: boolean;
  isRecurring?: boolean;
};

const OperatingSlots = ({ operatingTimes, errors, control, title, hideAvailability, defaultMaxBookingSlots, hideMaxSlots, isRecurring }: OperatingSlotsProps) => {
  const icons = useIcons();
  const defaultValue = operatingTimes ?? [];
  const [customTypes, setCustomTypes] = useState<any[]>(defaultValue);
  const times = customTypes.map(row => (row?.time ? RRule.fromString(row?.time || '') : ''));

  const addNewType = () => {
    const currentState = transformOperatingSlotsState(control.getValues());
    const newType = isRecurring ? { time: times?.[0]?.toString(), available: 0 } : { time: '', available: 0 };
    setCustomTypes([...currentState, newType]);
  };
  const handleRemoveType = typeIdx => {
    const filteredTypes = customTypes.filter((customType, idx) => idx !== typeIdx);
    setCustomTypes(filteredTypes);
  };

  return (
    <WideInputGroup>
      <SectionLabel>{title || 'Operating Slots'}</SectionLabel>
      <DaysLabels hideAvailability={hideAvailability} />
      {!hideMaxSlots && (
        <OperationMaxSlotsContainer>
          <OperationMaxSlots>
            <Controller
              name="operation_max_slots"
              control={control}
              defaultValue={defaultMaxBookingSlots || WEEK_DAYS_MAP.reduce((acc, day) => ({ ...acc, [day]: '0' }), {})}
              render={({ onChange, value }) => (
                <>
                  {WEEK_DAYS_MAP.map(day => (
                    <OperationMaxSlotContainer key={day}>
                      <FormInput
                        type="number"
                        error={errors[`operation_max_slots.${day}`]}
                        height={20}
                        fontSize={16}
                        placeholder={day}
                        fullWidth
                        borderRadius={4}
                        padding={8}
                        onChange={e => onChange({ ...value, [day]: e.target.value })}
                        value={value[day] || ''}
                        name={`operation_max_slots.${day}`}
                      />
                    </OperationMaxSlotContainer>
                  ))}
                </>
              )}
              // rules={{ required: true, validate: val => Object.values(val).every(v => v) }}
            />
          </OperationMaxSlots>
        </OperationMaxSlotsContainer>
      )}
      {customTypes?.map((row, idx) => {
        const timesToCheck = times?.[idx];
        return (
          <InputsRow key={`${timesToCheck.toString()}_${idx}`}>
            <RowComponent
              data={{
                time: timesToCheck ? rruleToReadableTime(timesToCheck.options) : '',
                slots: row?.available,
                days: timesToCheck ? timesToCheck.options.byweekday?.map(day => WEEK_DAYS_MAP[day]) : []
              }}
              setCustomTypes={setCustomTypes}
              errors={errors}
              control={control}
              idx={idx}
              hideAvailability={hideAvailability}
              isRecurring={isRecurring}
            />
            <RemoveRowContainer onClick={() => handleRemoveType(idx)}>
              <RemoveAddedImage src={icons.delete.childImageSharp.gatsbyImageData.images.fallback.src} />
            </RemoveRowContainer>
          </InputsRow>
        );
      })}
      <AddNewBtn onClick={() => addNewType()}>
        <AddNewBtnIcon src={icons.addPhoto.childImageSharp.gatsbyImageData.images.fallback.src} />
        Add a New Time
      </AddNewBtn>
    </WideInputGroup>
  );
};

export default OperatingSlots;
