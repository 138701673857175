import { useReactiveVar } from '@apollo/client';
import Bugsnag from '@bugsnag/js';
import firebase from 'gatsby-plugin-firebase';
import React, { useEffect } from 'react';
import Notifier from '../../Notifier';
import Routes from '../../Routes';
import { vars } from '../../reactive';
import { Container } from './styled';

interface PushNotification {
  data: {
    message: string;
    profile_pic: string;
    type: 'chat';
    chatRoomId: string;
  };
  notification: {
    title: string;
    body: string;
  };
}

export default function Main() {
  const userId = firebase?.auth?.()?.currentUser?.uid;

  useEffect(() => {
    turnOnNotifications(userId);
    Bugsnag.setUser(userId);
  }, [userId]);
  const drawerBars = useReactiveVar(vars.drawerBars);

  return (
    <Container drawerBarsLength={drawerBars.length}>
      <Routes />
    </Container>
  );
}

function turnOnNotifications(userId?: string) {
  if (!userId) {
    return;
  }
  if (userId && firebase.messaging.isSupported()) {
    firebase
      ?.messaging()
      .getToken()
      .then((token: string) => saveTokenToFirestore(token, userId))
      .catch(e => {
        console.error('FCM Token Error', e);
        if (e.code === 'messaging/token-unsubscribe-failed') turnOnNotifications(userId);
      });
    firebase?.messaging().onMessage((payload: PushNotification) => {
      Notifier.default({
        title: payload.notification.title,
        message: payload.notification.body,
        dismiss: {
          duration: 0
        }
        // onRemoval: () => navigate(`/app/chat/${payload.data.chatRoomId}`)
      });
    });
  }
}
async function saveTokenToFirestore(token: string, userId: string) {
  await firebase
    ?.firestore()
    .collection('Users')
    .doc(userId)
    .set({
      tokens: [token]
    });
}
