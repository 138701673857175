import React, { useCallback, useEffect, useRef } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { ModalBody, ModalFooter } from '../../../../components/Modal/styled';
import {
  FormButtonsContainer,
  FormError,
  FormLabel,
  FormSubmitButton,
  InputsWrapper,
  WideInputGroup,
  FormInput,
  FormLinkButtonStyled,
  SectionLabel,
  RadioBtnsGroup,
  InputContainer,
  FormSelect
} from '../../../../components/Shared/Forms/Forms';
import { Container } from '../../../../components/Shared/Shared';
import { EditPetRecord, GetNotesByMedCondId, NoteAdd, NoteEdit } from '../../../../queries';
import { ActionBtn } from '../../../Store/styled';
import { Shape, SHAPES } from '../../Health/Health';
import { sentenceCase } from 'sentence-case';
import MedicalNoteLumps from './MedicalNoteLumps';
import useIcons from '../../../../hooks/useIcons';
import NotePhotos from '../NotePhotos';
import usePhotoUpload, { useDeletePhotos, useDeletePhotosReturnType, UsePhotoUploadReturnType } from '../../../../hooks/usePhotoUpload';
import ModalDialog from '../../../../components/Modal/ModalDialog';
import VaccinationModal from './VaccinationModal';
import NotesMarkupEditor from '../NotesMarkupEditor';

export const AddNewMedicalNote = ({ medicalCase, petId, cases, refetch, autoOpenModal = false }: any) => {
  const icons = useIcons();
  const addIcon = icons.add.childImageSharp.gatsbyImageData.images.fallback.src;

  const showModal = useCallback(() => {
    ModalDialog.openModal({
      content: () => <MedicalNoteModal refetch={refetch} medicalCase={medicalCase} petId={petId} cases={cases} />,
      title: 'Add New Note',
      autoOpenModal
    });
  }, [medicalCase, petId, cases, refetch, autoOpenModal]);

  useEffect(() => {
    if (autoOpenModal) {
      showModal();
    }
  }, [autoOpenModal, showModal]);

  return <ActionBtn bgImage={addIcon} onClick={showModal} />;
};

export const EditMedicalNote = ({ note, medicalCase, refetch }) => {
  return (
    <FormLinkButtonStyled
      onClick={() => {
        ModalDialog.openModal({
          content: () => <MedicalNoteModal note={note} medicalCase={medicalCase} refetch={refetch} />,
          title: 'Edit Note'
        });
      }}
    >
      Edit
    </FormLinkButtonStyled>
  );
};

type MedicalNoteModalFormProps = {
  uploadPhotos: UsePhotoUploadReturnType;
  deletePhotos: useDeletePhotosReturnType;
  noteIdRef: React.MutableRefObject<string>;
  photosRef: React.MutableRefObject<Record<string, any>>;
  loading: boolean;
  error: boolean;
  note: Record<string, any>;
  petRecord: Record<string, any>;
  onSubmit: (form: Record<string, any>) => void;
  noteAction: string;
  isPrevNote: boolean;
};

const MedicalNoteModalForm = ({ uploadPhotos, deletePhotos, photosRef, noteIdRef, loading, error, note, petRecord, onSubmit, noteAction, isPrevNote }: MedicalNoteModalFormProps) => {
  const { control, handleSubmit, errors } = useForm();
  const shape: Shape = petRecord?.shape;
  const currentWeight: string = petRecord?.measurements?.weights?.slice(-1)?.[0]?.weight;

  const handleSave = handleSubmit(onSubmit);

  const watchedValues = useWatch({
    control,
    name: [
      'note_type',
      'eyes.appearance.status',
      'eyes.vision.status',
      'ears.status',
      'dental.tartar_calculus.affected',
      'dental.gingivitis.affected',
      'dental.missing_teeth.affected',
      'lymph_nodes.status',
      'chest.heart_rate_rythm.status',
      'chest.heart_murmur.status',
      'chest.lung_sounds.status',
      'chest.mucous_membrane.status',
      'abdominal_palpation.status',
      'movement_gait_nails.forelimbs.status',
      'movement_gait_nails.hindlimbs.status',
      'skin_coat.lumps.status',
      'skin_coat.skin_coat.status',
      'skin_coat.parasites.status',
      'treatments.taken'
    ],
    defaultValue: {
      note_type: note?.body?.note_type || 'basic',
      'eyes.appearance.status': note?.body?.eyes?.appearance?.status || 'normal',
      'eyes.vision.status': note?.body?.eyes?.vision?.status || 'normal',
      'ears.status': note?.body?.ears?.status || 'clean',
      'dental.tartar_calculus.affected': note?.body?.dental?.tartar_calculus?.affected || false,
      'dental.gingivitis.affected': note?.body?.dental?.gingivitis?.affected || false,
      'dental.missing_teeth.affected': note?.body?.dental?.missing_teeth?.affected || false,
      'lymph_nodes.status': note?.body?.lymph_nodes?.status || 'normal',
      'chest.heart_rate_rythm.status': note?.body?.chest?.heart_rate_rythm?.status || 'normal',
      'chest.heart_murmur.status': note?.body?.chest?.heart_murmur?.status || 'normal',
      'chest.lung_sounds.status': note?.body?.chest?.lung_sounds?.status || 'normal',
      'chest.mucous_membrane.status': note?.body?.chest?.mucous_membrane?.status || 'normal',
      'abdominal_palpation.status': note?.body?.abdominal_palpation?.status || 'normal',
      'movement_gait_nails.forelimbs.status': note?.body?.movement_gait_nails?.forelimbs?.status || 'normal',
      'movement_gait_nails.hindlimbs.status': note?.body?.movement_gait_nails?.hindlimbs?.status || 'normal',
      'skin_coat.lumps.status': note?.body?.skin_coat?.lumps?.status || 'none',
      'skin_coat.skin_coat.status': note?.body?.skin_coat?.coat?.status || 'healthy',
      'skin_coat.parasites.status': note?.body?.skin_coat?.parasites?.status || 'none',
      'treatments.taken': note?.body?.treatments?.taken || ''
    }
  });

  return (
    <>
      <ModalBody>
        <Container>
          <NotePhotos uploadPhotos={uploadPhotos} deletePhotos={deletePhotos} ref={photosRef} selectedNote={note} noteIdRef={noteIdRef} />
          <InputsWrapper noWrap>
            <WideInputGroup>
              <FormLabel>Report Type</FormLabel>
              <InputContainer isRow>
                <Controller
                  render={({ onChange, value }) => (
                    <RadioBtnsGroup
                      options={['basic', 'advanced']}
                      defaultValue={note?.body?.note_type || 'basic'}
                      onChange={e => {
                        onChange(e?.target?.value);
                      }}
                      itemStyle={{ width: '100%' }}
                      name={`note_type`}
                    />
                  )}
                  control={control}
                  name={`note_type`}
                  defaultValue={note?.body?.note_type || 'basic'}
                />
              </InputContainer>
            </WideInputGroup>
          </InputsWrapper>

          <InputsWrapper noWrap>
            <WideInputGroup>
              <FormLabel>Current Weight</FormLabel>
              <Controller
                as={<FormInput error={errors.weight} type={'number'} height={32} fontSize={16} flexBasis={78} />}
                control={control}
                name={'weight'}
                defaultValue={note?.body?.weight || currentWeight || 0}
                rules={{
                  required: watchedValues['note_type'] === 'advanced',
                  min: 0.1,
                  max: 999
                }}
              />
              {errors.weight && <FormError>{errors.weight.message || 'weight is invalid'}</FormError>}
            </WideInputGroup>
          </InputsWrapper>
          <InputsWrapper noWrap>
            <WideInputGroup>
              <FormLabel>Body Condition Score</FormLabel>
              <Controller
                render={({ onChange }) => (
                  <FormSelect height={48} fontSize={16} name={'shape'} onChange={e => onChange(e.target.value)} defaultValue={note?.body?.shape || shape || SHAPES[0]}>
                    {SHAPES.map(shape => (
                      <option key={shape} value={shape}>
                        {SHAPES.indexOf(shape) + 1} - {sentenceCase(shape === 'BELOW_IDEAL' ? 'LEAN' : shape || '')}
                      </option>
                    ))}
                  </FormSelect>
                )}
                control={control}
                name={'shape'}
                rules={{ required: true }}
                defaultValue={note?.body?.shape || shape || SHAPES[0]}
              />
              {errors.shape && <FormError>{errors.shape.message || 'Body Condition Score is invalid'}</FormError>}
            </WideInputGroup>
          </InputsWrapper>

          {watchedValues['note_type'] !== 'advanced' && (
            <>
              <InputsWrapper noWrap>
                <WideInputGroup>
                  <InputContainer marginBottom={20}>
                    <FormLabel>BPM</FormLabel>
                    <Controller
                      as={<FormInput error={errors.chest?.pulse?.grade} type={'number'} />}
                      control={control}
                      name={'chest.pulse.grade'}
                      defaultValue={note?.body?.chest?.pulse?.grade || ''}
                    />
                    {!!errors.chest?.pulse?.grade && <FormError>{errors.chest?.pulse?.grade?.message || 'bpm is invalid'}</FormError>}
                  </InputContainer>
                  <InputContainer marginBottom={20}>
                    <FormLabel>Temperature</FormLabel>
                    <Controller as={<FormInput error={errors.temperature?.grade} type={'number'} />} control={control} name={'temperature.grade'} defaultValue={note?.body?.temperature?.grade || ''} />
                    {!!errors.temperature?.grade && <FormError>{errors.temperature?.grade?.message || 'temperature  is invalid'}</FormError>}
                  </InputContainer>
                  <InputContainer>
                    <FormLabel>Additional Notes</FormLabel>
                    <NotesMarkupEditor
                      control={control}
                      defaultValue={{
                        details: note?.body?.additional_notes || '',
                        details_html: note?.body?.additional_notes_html || ''
                      }}
                      keyName={'additional_notes'}
                      name={''}
                    />
                  </InputContainer>
                </WideInputGroup>
              </InputsWrapper>
            </>
          )}
          {watchedValues['note_type'] === 'advanced' && (
            <>
              <InputsWrapper noWrap>
                <WideInputGroup>
                  <SectionLabel>Eyes & Vision</SectionLabel>
                  <FormLabel>Appearance</FormLabel>
                  <InputContainer isRow flex={1} marginBottom={20}>
                    <Controller
                      render={({ onChange, value }) => (
                        <RadioBtnsGroup
                          options={['normal', 'abnormal']}
                          defaultValue={note?.body?.eyes?.appearance?.status || 'normal'}
                          onChange={e => {
                            onChange(e?.target?.value);
                          }}
                          itemStyle={{ width: '100%' }}
                          name={`eyes.appearance.status`}
                        />
                      )}
                      control={control}
                      name={`eyes.appearance.status`}
                      defaultValue={note?.body?.eyes?.appearance?.status || 'normal'}
                    />
                  </InputContainer>
                  {watchedValues['eyes.appearance.status'] === 'abnormal' && (
                    <InputContainer marginBottom={20}>
                      <InputContainer isRow flex={1} marginBottom={20}>
                        <Controller
                          render={({ onChange, value }) => (
                            <RadioBtnsGroup
                              options={['left_eye', 'right_eye']}
                              defaultValue={note?.body?.eyes?.appearance?.position || []}
                              onChange={e => {
                                value.includes(e.target.value) ? onChange([...value].filter(v => v !== e.target.value)) : onChange([...value, e.target.value]);
                              }}
                              itemStyle={{ width: '100%' }}
                              name={`eyes.appearance.position`}
                              inputType={'checkbox'}
                            />
                          )}
                          control={control}
                          name={`eyes.appearance.position`}
                          defaultValue={note?.body?.eyes?.appearance?.position || []}
                        />
                      </InputContainer>

                      <FormLabel>Details</FormLabel>
                      <NotesMarkupEditor control={control} name={'eyes.appearance'} defaultValue={note?.body?.eyes?.appearance || ''} />
                      {!!errors.eyes?.appearance?.details && <FormError>{errors.eyes?.appearance?.details?.message || 'appearance is invalid'}</FormError>}
                    </InputContainer>
                  )}

                  <FormLabel>Vision</FormLabel>
                  <InputContainer isRow flex={1} marginBottom={20}>
                    <Controller
                      render={({ onChange, value }) => (
                        <RadioBtnsGroup
                          options={['normal', 'abnormal']}
                          defaultValue={note?.body?.eyes?.vision?.status || 'normal'}
                          onChange={e => {
                            onChange(e?.target?.value);
                          }}
                          itemStyle={{ width: '100%' }}
                          name={`eyes.vision.status`}
                        />
                      )}
                      control={control}
                      name={`eyes.vision.status`}
                      defaultValue={note?.body?.eyes?.vision?.status || 'normal'}
                    />
                  </InputContainer>
                  {watchedValues['eyes.vision.status'] === 'abnormal' && (
                    <InputContainer marginBottom={20}>
                      <FormLabel>Details</FormLabel>
                      <NotesMarkupEditor control={control} name={'eyes.vision'} defaultValue={note?.body?.eyes?.vision || ''} />
                      {!!errors.eyes?.vision?.details && <FormError>{errors.eyes?.vision?.details?.message || 'vision is invalid'}</FormError>}
                    </InputContainer>
                  )}
                </WideInputGroup>
              </InputsWrapper>

              <InputsWrapper noWrap>
                <WideInputGroup>
                  <SectionLabel>Ears</SectionLabel>
                  <InputContainer isRow flex={1} marginBottom={20}>
                    <Controller
                      render={({ onChange, value }) => (
                        <RadioBtnsGroup
                          options={['clean', 'discharge_present']}
                          as={['Clean & Healthy', 'Discharge present']}
                          defaultValue={note?.body?.ears?.status || 'clean'}
                          onChange={e => {
                            onChange(e?.target?.value);
                          }}
                          itemStyle={{ width: '100%' }}
                          name={`ears.status`}
                        />
                      )}
                      control={control}
                      name={`ears.status`}
                      defaultValue={note?.body?.ears?.status || 'clean'}
                    />
                  </InputContainer>
                  {watchedValues['ears.status'] === 'discharge_present' && (
                    <InputContainer marginBottom={20}>
                      <InputContainer isRow flex={1} marginBottom={20}>
                        <Controller
                          render={({ onChange, value }) => (
                            <RadioBtnsGroup
                              options={['left_ear', 'right_ear']}
                              defaultValue={note?.body?.ears?.position || []}
                              onChange={e => {
                                value.includes(e.target.value) ? onChange([...value].filter(v => v !== e.target.value)) : onChange([...value, e.target.value]);
                              }}
                              itemStyle={{ width: '100%' }}
                              name={`ears.position`}
                              inputType={'checkbox'}
                            />
                          )}
                          control={control}
                          name={`ears.position`}
                          defaultValue={note?.body?.ears?.position || []}
                        />
                      </InputContainer>
                      <FormLabel>Details</FormLabel>
                      <NotesMarkupEditor control={control} name={'ears'} defaultValue={note?.body?.ears || ''} />
                      {!!errors.ears?.details && <FormError>{errors.ears?.details?.message || 'ears status is invalid'}</FormError>}
                    </InputContainer>
                  )}
                </WideInputGroup>
              </InputsWrapper>

              <InputsWrapper noWrap>
                <WideInputGroup>
                  <SectionLabel>Dental</SectionLabel>
                  <InputContainer isRow flex={1} marginBottom={20}>
                    <Controller
                      render={({ onChange, value }) => (
                        <RadioBtnsGroup
                          options={['tartar_calculus']}
                          as={['Tartar/calculus']}
                          defaultValue={note?.body?.dental?.tartar_calculus?.affected || false}
                          onChange={e => {
                            onChange(!value);
                          }}
                          itemStyle={{ width: '100%' }}
                          name={`dental.tartar_calculus.affected`}
                          inputType={'checkbox'}
                        />
                      )}
                      control={control}
                      name={`dental.tartar_calculus.affected`}
                      defaultValue={note?.body?.dental?.tartar_calculus?.affected || false}
                    />
                  </InputContainer>
                  {watchedValues['dental.tartar_calculus.affected'] === true && (
                    <InputContainer marginBottom={20}>
                      <FormLabel>Grade</FormLabel>
                      <Controller
                        as={<FormInput error={errors.dental?.tartar_calculus?.grade} type={'number'} min={0} max={3} />}
                        control={control}
                        name={'dental.tartar_calculus.grade'}
                        rules={{ min: 0, max: 3 }}
                        defaultValue={note?.body?.dental?.tartar_calculus?.grade || 1}
                      />
                      {!!errors.dental?.tartar_calculus?.grade && <FormError>{errors.dental?.tartar_calculus?.grade?.message || 'tartar calculus is invalid'}</FormError>}
                    </InputContainer>
                  )}
                  <InputContainer isRow flex={1} marginBottom={20}>
                    <Controller
                      render={({ onChange, value }) => (
                        <RadioBtnsGroup
                          options={['gingivitis']}
                          defaultValue={note?.body?.dental?.gingivitis?.affected || false}
                          onChange={e => {
                            onChange(!value);
                          }}
                          itemStyle={{ width: '100%' }}
                          name={`dental.gingivitis.affected`}
                          inputType={'checkbox'}
                        />
                      )}
                      control={control}
                      name={`dental.gingivitis.affected`}
                      defaultValue={note?.body?.dental?.gingivitis?.affected || false}
                    />
                  </InputContainer>
                  {watchedValues['dental.gingivitis.affected'] === true && (
                    <InputContainer marginBottom={20}>
                      <FormLabel>Grade</FormLabel>
                      <Controller
                        as={<FormInput error={errors.dental?.gingivitis?.grade} type={'number'} min={0} max={3} />}
                        control={control}
                        rules={{ min: 0, max: 3 }}
                        name={'dental.gingivitis.grade'}
                        defaultValue={note?.body?.dental?.gingivitis?.grade || 1}
                      />
                      {!!errors.dental?.gingivitis?.grade && <FormError>{errors.dental?.gingivitis?.grade?.message || 'gingivitis is invalid'}</FormError>}
                    </InputContainer>
                  )}
                  <InputContainer isRow flex={1} marginBottom={20}>
                    <Controller
                      render={({ onChange, value }) => (
                        <RadioBtnsGroup
                          options={['missing_teeth']}
                          defaultValue={note?.body?.dental?.missing_teeth?.affected || false}
                          onChange={e => {
                            onChange(!value);
                          }}
                          itemStyle={{ width: '100%' }}
                          name={`dental.missing_teeth.affected`}
                          inputType={'checkbox'}
                        />
                      )}
                      control={control}
                      name={`dental.missing_teeth.affected`}
                      defaultValue={note?.body?.dental?.missing_teeth?.affected || false}
                    />
                  </InputContainer>
                  {watchedValues['dental.missing_teeth.affected'] === true && (
                    <InputContainer marginBottom={20}>
                      <FormLabel>Details</FormLabel>
                      <NotesMarkupEditor control={control} name={'dental.missing_teeth'} defaultValue={note?.body?.dental?.missing_teeth || ''} />
                      {!!errors.dental?.missing_teeth?.details && <FormError>{errors.dental?.missing_teeth?.details?.message || 'lymph nodes status is invalid'}</FormError>}
                    </InputContainer>
                  )}
                </WideInputGroup>
              </InputsWrapper>

              <InputsWrapper noWrap>
                <WideInputGroup>
                  <SectionLabel>Lymph Nodes/Glands</SectionLabel>
                  <InputContainer isRow flex={1} marginBottom={20}>
                    <Controller
                      render={({ onChange, value }) => (
                        <RadioBtnsGroup
                          options={['normal', 'abnormal']}
                          defaultValue={note?.body?.lymph_nodes?.status || 'normal'}
                          onChange={e => {
                            onChange(e?.target?.value);
                          }}
                          itemStyle={{ width: '100%' }}
                          name={`lymph_nodes.status`}
                        />
                      )}
                      control={control}
                      name={`lymph_nodes.status`}
                      defaultValue={note?.body?.lymph_nodes?.status || 'normal'}
                    />
                  </InputContainer>
                  {watchedValues['lymph_nodes.status'] === 'abnormal' && (
                    <InputContainer marginBottom={20}>
                      <FormLabel>Details</FormLabel>
                      <NotesMarkupEditor control={control} name={'lymph_nodes'} defaultValue={note?.body?.lymph_nodes || ''} />
                      {!!errors.lymph_nodes?.details && <FormError>{errors.lymph_nodes?.details?.message || 'lymph nodes status is invalid'}</FormError>}
                    </InputContainer>
                  )}
                </WideInputGroup>
              </InputsWrapper>

              <InputsWrapper noWrap>
                <WideInputGroup>
                  <SectionLabel>Chest/circulatory</SectionLabel>
                  <FormLabel>Heart Rate/Rythm</FormLabel>
                  <InputContainer isRow flex={1} marginBottom={20}>
                    <Controller
                      render={({ onChange, value }) => (
                        <RadioBtnsGroup
                          options={['normal', 'abnormal']}
                          defaultValue={note?.body?.chest?.heart_rate_rythm?.status || 'normal'}
                          onChange={e => {
                            onChange(e?.target?.value);
                          }}
                          itemStyle={{ width: '100%' }}
                          name={`chest.heart_rate_rythm.status`}
                        />
                      )}
                      control={control}
                      name={`chest.heart_rate_rythm.status`}
                      defaultValue={note?.body?.chest?.heart_rate_rythm?.status || 'normal'}
                    />
                  </InputContainer>
                  {watchedValues['chest.heart_rate_rythm.status'] === 'abnormal' && (
                    <InputContainer marginBottom={20}>
                      <FormLabel>Details</FormLabel>
                      <NotesMarkupEditor control={control} name={'chest.heart_rate_rythm'} defaultValue={note?.body?.chest?.heart_rate_rythm || ''} />
                      {!!errors.chest?.heart_rate_rythm?.details && <FormError>{errors.chest?.heart_rate_rythm?.details?.message || 'chest heart rate/rhythm is invalid'}</FormError>}
                    </InputContainer>
                  )}
                  <InputContainer marginBottom={20}>
                    <FormLabel>BPM</FormLabel>
                    <Controller
                      as={<FormInput error={errors.chest?.pulse?.grade} type={'number'} />}
                      control={control}
                      name={'chest.pulse.grade'}
                      defaultValue={note?.body?.chest?.pulse?.grade || ''}
                    />
                    {!!errors.chest?.pulse?.grade && <FormError>{errors.chest?.pulse?.grade?.message || 'bpm is invalid'}</FormError>}
                  </InputContainer>
                  <FormLabel>Pulse</FormLabel>
                  <InputContainer isRow flex={1} marginBottom={20}>
                    <Controller
                      render={({ onChange, value }) => (
                        <RadioBtnsGroup
                          options={['normal', 'abnormal']}
                          defaultValue={note?.body?.chest?.pulse?.status || 'normal'}
                          onChange={e => {
                            onChange(e?.target?.value);
                          }}
                          itemStyle={{ width: '100%' }}
                          name={`chest.pulse.status`}
                        />
                      )}
                      control={control}
                      name={`chest.pulse.status`}
                      defaultValue={note?.body?.chest?.pulse?.status || 'normal'}
                    />
                  </InputContainer>
                  <FormLabel>Heart Murmur</FormLabel>
                  <InputContainer isRow flex={1} marginBottom={20}>
                    <Controller
                      render={({ onChange, value }) => (
                        <RadioBtnsGroup
                          options={['normal', 'present']}
                          defaultValue={note?.body?.chest?.heart_murmur?.status || 'normal'}
                          onChange={e => {
                            onChange(e?.target?.value);
                          }}
                          itemStyle={{ width: '100%' }}
                          name={`chest.heart_murmur.status`}
                        />
                      )}
                      control={control}
                      name={`chest.heart_murmur.status`}
                      defaultValue={note?.body?.chest?.heart_murmur?.status || 'normal'}
                    />
                  </InputContainer>
                  {watchedValues['chest.heart_murmur.status'] === 'present' && (
                    <InputContainer marginBottom={20}>
                      <FormLabel>Grade</FormLabel>
                      <Controller
                        as={<FormInput error={errors.chest?.heart_murmur?.grade} type={'number'} min={1} max={6} />}
                        control={control}
                        rules={{ min: 1, max: 6 }}
                        name={'chest.heart_murmur.grade'}
                        defaultValue={note?.body?.chest?.heart_murmur?.grade || 1}
                      />
                      {!!errors.chest?.heart_murmur?.grade && <FormError>{errors.chest?.heart_murmur?.grade?.message || 'heart murmur is invalid'}</FormError>}
                    </InputContainer>
                  )}
                  <FormLabel>Lung Sounds</FormLabel>
                  <InputContainer isRow flex={1} marginBottom={20}>
                    <Controller
                      render={({ onChange, value }) => (
                        <RadioBtnsGroup
                          options={['normal', 'abnormal']}
                          defaultValue={note?.body?.chest?.lung_sounds?.status || 'normal'}
                          onChange={e => {
                            onChange(e?.target?.value);
                          }}
                          itemStyle={{ width: '100%' }}
                          name={`chest.lung_sounds.status`}
                        />
                      )}
                      control={control}
                      name={`chest.lung_sounds.status`}
                      defaultValue={note?.body?.chest?.lung_sounds?.status || 'normal'}
                    />
                  </InputContainer>
                  {watchedValues['chest.lung_sounds.status'] === 'abnormal' && (
                    <InputContainer marginBottom={20}>
                      <FormLabel>Details</FormLabel>
                      <NotesMarkupEditor control={control} name={'chest.lung_sounds'} defaultValue={note?.body?.chest?.lung_sounds || ''} />
                      {!!errors.chest?.lung_sounds?.details && <FormError>{errors.chest?.lung_sounds?.details?.message || 'chest lung sounds is invalid'}</FormError>}
                    </InputContainer>
                  )}
                  <FormLabel>Mucous membrane colour and capillary refill time</FormLabel>
                  <InputContainer isRow flex={1} marginBottom={20}>
                    <Controller
                      render={({ onChange, value }) => (
                        <RadioBtnsGroup
                          options={['normal', 'abnormal']}
                          defaultValue={note?.body?.chest?.mucous_membrane?.status || 'normal'}
                          onChange={e => {
                            onChange(e?.target?.value);
                          }}
                          itemStyle={{ width: '100%' }}
                          name={`chest.mucous_membrane.status`}
                        />
                      )}
                      control={control}
                      name={`chest.mucous_membrane.status`}
                      defaultValue={note?.body?.chest?.mucous_membrane?.status || 'normal'}
                    />
                  </InputContainer>
                  {watchedValues['chest.mucous_membrane.status'] === 'abnormal' && (
                    <InputContainer marginBottom={20}>
                      <FormLabel>Details</FormLabel>
                      <NotesMarkupEditor control={control} name={'chest.mucous_membrane'} defaultValue={note?.body?.chest?.mucous_membrane || ''} />
                      {!!errors.chest?.mucous_membrane?.details && <FormError>{errors.chest?.mucous_membrane?.details?.message || 'chest mucous membrane is invalid'}</FormError>}
                    </InputContainer>
                  )}
                </WideInputGroup>
              </InputsWrapper>

              <InputsWrapper noWrap>
                <WideInputGroup>
                  <SectionLabel>Abdominal Palpation</SectionLabel>
                  <InputContainer isRow flex={1} marginBottom={20}>
                    <Controller
                      render={({ onChange, value }) => (
                        <RadioBtnsGroup
                          options={['normal', 'abnormal']}
                          defaultValue={note?.body?.abdominal_palpation?.status || 'normal'}
                          onChange={e => {
                            onChange(e?.target?.value);
                          }}
                          itemStyle={{ width: '100%' }}
                          name={`abdominal_palpation.status`}
                        />
                      )}
                      control={control}
                      name={`abdominal_palpation.status`}
                      defaultValue={note?.body?.abdominal_palpation?.status || 'normal'}
                    />
                  </InputContainer>
                  {watchedValues['abdominal_palpation.status'] === 'abnormal' && (
                    <InputContainer marginBottom={20}>
                      <FormLabel>Details</FormLabel>
                      <NotesMarkupEditor control={control} name={'abdominal_palpation'} defaultValue={note?.body?.abdominal_palpation || ''} />
                      {!!errors.abdominal_palpation?.details && <FormError>{errors.abdominal_palpation?.details?.message || 'abdominal palpation status is invalid'}</FormError>}
                    </InputContainer>
                  )}
                </WideInputGroup>
              </InputsWrapper>

              <InputsWrapper noWrap>
                <WideInputGroup>
                  <SectionLabel>Movement, Gait & Nails</SectionLabel>
                  <FormLabel>Forelimbs</FormLabel>
                  <InputContainer isRow flex={1} marginBottom={20}>
                    <Controller
                      render={({ onChange, value }) => (
                        <RadioBtnsGroup
                          options={['normal', 'lamness']}
                          defaultValue={note?.body?.movement_gait_nails?.forelimbs?.status || 'normal'}
                          onChange={e => {
                            onChange(e?.target?.value);
                          }}
                          itemStyle={{ width: '100%' }}
                          name={`movement_gait_nails.forelimbs.status`}
                        />
                      )}
                      control={control}
                      name={`movement_gait_nails.forelimbs.status`}
                      defaultValue={note?.body?.movement_gait_nails?.forelimbs?.status || 'normal'}
                    />
                  </InputContainer>
                  {watchedValues['movement_gait_nails.forelimbs.status'] === 'lamness' && (
                    <InputContainer marginBottom={20}>
                      <InputContainer isRow flex={1} marginBottom={20}>
                        <Controller
                          render={({ onChange, value }) => (
                            <RadioBtnsGroup
                              options={['left_limb', 'right_limb']}
                              defaultValue={note?.body?.movement_gait_nails?.forelimbs?.position || []}
                              onChange={e => {
                                value.includes(e.target.value) ? onChange([...value].filter(v => v !== e.target.value)) : onChange([...value, e.target.value]);
                              }}
                              itemStyle={{ width: '100%' }}
                              name={`movement_gait_nails.forelimbs.position`}
                              inputType={'checkbox'}
                            />
                          )}
                          control={control}
                          name={`movement_gait_nails.forelimbs.position`}
                          defaultValue={note?.body?.movement_gait_nails?.forelimbs?.position || []}
                        />
                      </InputContainer>
                      <FormLabel>Grade</FormLabel>
                      <Controller
                        as={<FormInput error={errors.movement_gait_nails?.forelimbs?.grade} type={'number'} />}
                        control={control}
                        name={'movement_gait_nails.forelimbs.grade'}
                        defaultValue={note?.body?.movement_gait_nails?.forelimbs?.grade || ''}
                      />
                      {!!errors.movement_gait_nails?.forelimbs?.grade && <FormError>{errors.movement_gait_nails?.forelimbs?.grade?.message || 'movement_gait_nails forelimbs is invalid'}</FormError>}
                      <InputContainer marginBottom={20}>
                        <FormLabel>Details</FormLabel>
                        <NotesMarkupEditor control={control} name={'movement_gait_nails.forelimbs'} defaultValue={note?.body?.movement_gait_nails?.forelimbs || ''} />
                        {!!errors.movement_gait_nails?.forelimbs?.details && (
                          <FormError>{errors.movement_gait_nails?.forelimbs?.details?.message || 'movement_gait_nails forelimbs details is invalid'}</FormError>
                        )}
                      </InputContainer>
                    </InputContainer>
                  )}
                  <FormLabel>Hindlimbs</FormLabel>
                  <InputContainer isRow flex={1} marginBottom={20}>
                    <Controller
                      render={({ onChange, value }) => (
                        <RadioBtnsGroup
                          options={['normal', 'lamness']}
                          defaultValue={note?.body?.movement_gait_nails?.hindlimbs?.status || 'normal'}
                          onChange={e => {
                            onChange(e?.target?.value);
                          }}
                          itemStyle={{ width: '100%' }}
                          name={`movement_gait_nails.hindlimbs.status`}
                        />
                      )}
                      control={control}
                      name={`movement_gait_nails.hindlimbs.status`}
                      defaultValue={note?.body?.movement_gait_nails?.hindlimbs?.status || 'normal'}
                    />
                  </InputContainer>
                  {watchedValues['movement_gait_nails.hindlimbs.status'] === 'lamness' && (
                    <InputContainer marginBottom={20}>
                      <InputContainer isRow flex={1} marginBottom={20}>
                        <Controller
                          render={({ onChange, value }) => (
                            <RadioBtnsGroup
                              options={['left_limb', 'right_limb']}
                              defaultValue={note?.body?.movement_gait_nails?.hindlimbs?.position || []}
                              onChange={e => {
                                value.includes(e.target.value) ? onChange([...value].filter(v => v !== e.target.value)) : onChange([...value, e.target.value]);
                              }}
                              itemStyle={{ width: '100%' }}
                              name={`movement_gait_nails.hindlimbs.position`}
                              inputType={'checkbox'}
                            />
                          )}
                          control={control}
                          name={`movement_gait_nails.hindlimbs.position`}
                          defaultValue={note?.body?.movement_gait_nails?.hindlimbs?.position || []}
                        />
                      </InputContainer>
                      <FormLabel>Grade</FormLabel>
                      <Controller
                        as={<FormInput error={errors.movement_gait_nails?.hindlimbs?.grade} type={'number'} />}
                        control={control}
                        name={'movement_gait_nails.hindlimbs.grade'}
                        defaultValue={note?.body?.movement_gait_nails?.hindlimbs?.grade || ''}
                      />
                      {!!errors.movement_gait_nails?.hindlimbs?.grade && <FormError>{errors.movement_gait_nails?.hindlimbs?.grade?.message || 'movement_gait_nails hindlimbs is invalid'}</FormError>}
                      <InputContainer marginBottom={20}>
                        <FormLabel>Details</FormLabel>
                        <NotesMarkupEditor control={control} name={'movement_gait_nails.hindlimbs'} defaultValue={note?.body?.movement_gait_nails?.hindlimbs || ''} />
                        {!!errors.movement_gait_nails?.hindlimbs?.details && (
                          <FormError>{errors.movement_gait_nails?.hindlimbs?.details?.message || 'movement_gait_nails hindlimbs details is invalid'}</FormError>
                        )}
                      </InputContainer>
                    </InputContainer>
                  )}
                  <FormLabel>Nails</FormLabel>
                  <InputContainer isRow flex={1} marginBottom={20}>
                    <Controller
                      render={({ onChange, value }) => (
                        <RadioBtnsGroup
                          options={['short', 'long']}
                          defaultValue={note?.body?.movement_gait_nails?.nails?.status || 'short'}
                          onChange={e => {
                            onChange(e?.target?.value);
                          }}
                          itemStyle={{ width: '100%' }}
                          name={`movement_gait_nails.nails.status`}
                        />
                      )}
                      control={control}
                      name={`movement_gait_nails.nails.status`}
                      defaultValue={note?.body?.movement_gait_nails?.nails?.status || 'short'}
                    />
                  </InputContainer>
                </WideInputGroup>
              </InputsWrapper>

              <InputsWrapper noWrap>
                <WideInputGroup>
                  <SectionLabel>Skin & Coat</SectionLabel>
                  <FormLabel>Skin & Coat</FormLabel>
                  <InputContainer isRow flex={1} marginBottom={20}>
                    <Controller
                      render={({ onChange, value }) => (
                        <RadioBtnsGroup
                          options={['healthy', 'abnormal']}
                          defaultValue={note?.body?.skin_coat?.skin_coat?.status || 'healthy'}
                          onChange={e => {
                            onChange(e?.target?.value);
                          }}
                          itemStyle={{ width: '100%' }}
                          name={`skin_coat.skin_coat.status`}
                        />
                      )}
                      control={control}
                      name={`skin_coat.skin_coat.status`}
                      defaultValue={note?.body?.skin_coat?.skin_coat?.status || 'healthy'}
                    />
                  </InputContainer>
                  {watchedValues['skin_coat.skin_coat.status'] === 'abnormal' && (
                    <InputContainer marginBottom={20}>
                      <FormLabel>Details</FormLabel>
                      <NotesMarkupEditor control={control} name={'skin_coat.skin_coat'} defaultValue={note?.body?.skin_coat?.skin_coat || ''} />
                      {!!errors.skin_coat?.skin_coat?.details && <FormError>{errors.skin_coat?.skin_coat?.details?.message || 'skin coat status is invalid'}</FormError>}
                    </InputContainer>
                  )}
                  <FormLabel>Lumps</FormLabel>
                  <InputContainer isRow flex={1} marginBottom={20}>
                    <Controller
                      render={({ onChange, value }) => (
                        <RadioBtnsGroup
                          options={['none', 'present']}
                          defaultValue={note?.body?.skin_coat?.lumps?.status || 'none'}
                          onChange={e => {
                            onChange(e?.target?.value);
                          }}
                          itemStyle={{ width: '100%' }}
                          name={`skin_coat.lumps.status`}
                        />
                      )}
                      control={control}
                      name={`skin_coat.lumps.status`}
                      defaultValue={note?.body?.skin_coat?.lumps?.status || 'none'}
                    />
                  </InputContainer>
                  {watchedValues['skin_coat.lumps.status'] === 'present' && (
                    <>
                      <MedicalNoteLumps control={control} errors={errors} defaultValue={note?.body?.skin_coat?.lumps?.lumps} />
                    </>
                  )}
                  <FormLabel>Parasites</FormLabel>
                  <InputContainer isRow flex={1} marginBottom={20}>
                    <Controller
                      render={({ onChange, value }) => (
                        <RadioBtnsGroup
                          options={['none', 'observed']}
                          defaultValue={note?.body?.skin_coat?.parasites?.status || 'none'}
                          onChange={e => {
                            onChange(e?.target?.value);
                          }}
                          itemStyle={{ width: '100%' }}
                          name={`skin_coat.parasites.status`}
                        />
                      )}
                      control={control}
                      name={`skin_coat.parasites.status`}
                      defaultValue={note?.body?.skin_coat?.parasites?.status || 'none'}
                    />
                  </InputContainer>
                  {watchedValues['skin_coat.parasites.status'] === 'observed' && (
                    <InputContainer marginBottom={20}>
                      <FormLabel>Details</FormLabel>
                      <NotesMarkupEditor control={control} name={'skin_coat.parasites'} defaultValue={note?.body?.skin_coat?.parasites || ''} />
                      {!!errors.skin_coat?.parasites?.details && <FormError>{errors.skin_coat?.parasites?.details?.message || 'skin coat status is invalid'}</FormError>}
                    </InputContainer>
                  )}
                </WideInputGroup>
              </InputsWrapper>

              <InputsWrapper noWrap>
                <WideInputGroup>
                  <SectionLabel>Temperature</SectionLabel>
                  <InputContainer isRow flex={1} wrap={'wrap'} marginBottom={20}>
                    <Controller
                      render={({ onChange, value }) => (
                        <RadioBtnsGroup
                          options={['normal', 'abnormal', 'high', 'low']}
                          defaultValue={note?.body?.temperature?.status || 'normal'}
                          onChange={e => {
                            onChange(e?.target?.value);
                          }}
                          itemStyle={{ flex: '50%', marginBottom: 5 }}
                          name={`temperature.status`}
                        />
                      )}
                      control={control}
                      name={`temperature.status`}
                      defaultValue={note?.body?.temperature?.status || 'normal'}
                    />
                  </InputContainer>
                  <InputContainer marginBottom={20}>
                    <FormLabel>Temperature</FormLabel>
                    <Controller as={<FormInput error={errors.temperature?.grade} type={'number'} />} control={control} name={'temperature.grade'} defaultValue={note?.body?.temperature?.grade || ''} />
                    {!!errors.temperature?.grade && <FormError>{errors.temperature?.grade?.message || 'temperature  is invalid'}</FormError>}
                  </InputContainer>
                </WideInputGroup>
              </InputsWrapper>

              <InputsWrapper noWrap>
                <WideInputGroup marginBottom={0}>
                  <SectionLabel>Treatments</SectionLabel>
                  <InputContainer marginBottom={20}>
                    <Controller
                      render={({ onChange, value }) => (
                        <RadioBtnsGroup
                          options={['vaccinations', 'parasite_control', 'nails_trimmed', 'anal_gland_emptied', 'microchip_checked']}
                          defaultValue={(!isPrevNote ? note?.body?.treatments?.taken : []) || []}
                          onChange={e => {
                            value.includes(e.target.value) ? onChange([...value].filter(v => v !== e.target.value)) : onChange([...value, e.target.value]);
                          }}
                          itemStyle={{ width: '100%' }}
                          name={`treatments.taken`}
                          inputType={'checkbox'}
                        />
                      )}
                      control={control}
                      name={`treatments.taken`}
                      defaultValue={(!isPrevNote ? note?.body?.treatments?.taken : []) || []}
                    />
                  </InputContainer>
                </WideInputGroup>
              </InputsWrapper>
            </>
          )}
        </Container>
      </ModalBody>
      <ModalFooter>
        <FormButtonsContainer>
          <FormSubmitButton error={error} loading={loading} onClick={handleSave}>
            {noteAction} Note
          </FormSubmitButton>
        </FormButtonsContainer>
      </ModalFooter>
    </>
  );
};

type MedicalNoteModalProps = { note?: {}; medicalCase?: {}; petId?: string; cases: Record<string, any>[]; refetch: ReturnType<typeof useQuery>['refetch'] };
const MedicalNoteModal = ({ note, medicalCase, petId, cases, refetch }: MedicalNoteModalProps) => {
  const { data: { getNotesByMedCondId: [prevNote = {}] = [] } = {}, loading: notesLoading } = useQuery(GetNotesByMedCondId, {
    variables: { medCondId: cases?.map(c => c.id) || [], limit: 1 }
  });

  const [getNotes] = useLazyQuery(GetNotesByMedCondId);

  const [handleAddNote, { data: addedNote, loading: loadingAdd, error: errorAdd, client }] = useMutation(NoteAdd);
  const [handleEditNote, { data: editedNote, loading: loadingEdit, error: errorEdit }] = useMutation(NoteEdit);
  const [handleEditPetRecord, { data: editedWeight }] = useMutation(EditPetRecord);

  const uploadPhotos = usePhotoUpload();
  const [_, { loading: loadingUploadPhotos, error: errorUploadPhotos }] = uploadPhotos;
  const deletePhotos = useDeletePhotos();

  const noteIdRef = useRef<string>('');
  const photosRef = useRef<Record<string, any>>({});

  useEffect(() => {
    if (addedNote) {
      getNotes({ variables: { limit: 2, medCondId: [medicalCase?.id] } });
    }
    if (addedNote || editedNote) {
      refetch();
      ModalDialog.closeModal();
    }
    if (addedNote?.noteAdd?.body?.treatments?.taken?.includes?.('vaccinations')) {
      ModalDialog.openModal({
        content: () => <VaccinationModal medCond={medicalCase} petId={petId} />,
        title: 'Add Vaccination'
      });
    }
  }, [addedNote, editedNote, petId]);

  const onSubmit = async (form: Record<string, any>) => {
    if (note) {
      noteIdRef.current = note.id;
    }

    if (!note) {
      handleEditPetRecord({
        variables: {
          id: medicalCase?.PetRecord?.id,
          measurements: form.weight
            ? { ...medicalCase?.PetRecord?.measurements, weights: [...(medicalCase?.PetRecord?.measurements?.weights || []), { weight: Number(form.weight), date: new Date().toISOString() }] }
            : undefined,
          shape: form.shape
        }
      });
      const { data: { noteAdd: addedNote = {} } = {} } = await handleAddNote({
        variables: {
          body: form,
          timestamp: new Date(),
          medicalCaseId: medicalCase.id
        }
      });

      noteIdRef.current = addedNote.id;
    }

    const photos = await photosRef.current?.getPhotos();
    const attachments = await photosRef.current?.getAttachments();

    handleEditNote({
      variables: {
        id: noteIdRef.current,
        body: {
          ...form,
          photos: {
            primary: photos
          },
          attachments: {
            primary: attachments
          }
        },
        timestamp: new Date()
      }
    });
  };

  const loading = loadingAdd || loadingEdit || loadingUploadPhotos;
  const error = !!errorAdd?.message || !!errorEdit?.message || !!errorUploadPhotos;

  return (
    <MedicalNoteModalForm
      {...{
        uploadPhotos,
        deletePhotos,
        photosRef,
        noteIdRef,
        loading,
        error,
        note: note || prevNote,
        petRecord: medicalCase?.PetRecord,
        onSubmit,
        noteAction: !note ? 'Add' : 'Save',
        isPrevNote: !note
      }}
    />
  );
};

export default MedicalNoteModal;
