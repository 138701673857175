import { useState } from 'react';
import { useLocation } from '@reach/router';

export default function useSelectedState<T extends Record<string, string | number | symbol>>({
  defaultTab,
  TABS_TYPES
}: {
  defaultTab: T[keyof T];
  TABS_TYPES: T;
}): [T[keyof T], React.Dispatch<React.SetStateAction<T[keyof T]>>] {
  const location = useLocation();
  const preSelectedTab = (location.pathname?.split('/')[3] || defaultTab) as T[keyof T];

  const tabIncluded = Object.values(TABS_TYPES).includes(preSelectedTab);

  const [selected, setSelected] = useState<T[keyof T]>(preSelectedTab && tabIncluded ? preSelectedTab : defaultTab);

  return [selected, setSelected];
}
