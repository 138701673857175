import { getKeys } from '../../utils/helpers';

type Operation = {
  description: string;
  price: number;
  rule: { [key: string]: string | number };
};

type Operations = Operation[];

const transformDeliveryCodesState = (formState: { [key: string]: any }): Operations => {
  const keys = getKeys(formState);
  return keys
    .filter((key: string) => key.includes('custom_type_delivery_') && !isNaN(Number(key.replace('custom_type_delivery_', ''))))
    .reduce((operations, parentKey) => {
      const properties: Operations[] = keys
        .filter((key: string) => key.includes(parentKey) && key !== parentKey)
        .reduce((children, childKey) => {
          let propertyName;

          if (childKey.includes('delivery_rule')) {
            return children;
          } else if (childKey.includes('delivery_value')) {
            propertyName = 'delivery_value';
          } else if (childKey.includes('charges')) {
            propertyName = 'price';
          }

          const childIndex = childKey.replace(RegExp(`${parentKey}_delivery_rule_|${parentKey}_delivery_value_|${parentKey}_charges_`), '');

          if (propertyName === 'delivery_value') {
            if (formState[childKey.replace('value_', 'rule_')] === 'none') {
              children[childIndex] = {
                ...(children[childIndex] || {}),
                rule: {}
              };
              return children;
            }
            children[childIndex] = {
              ...(children[childIndex] || {}),
              rule: { [formState[childKey.replace('value_', 'rule_')]]: formState[childKey] }
            };
            return children;
          }

          children[childIndex] = {
            ...(children[childIndex] || {}),
            [propertyName]: formState[childKey]
          };

          return children;
        }, []);

      return [...operations, { ...properties[0], description: formState[parentKey] }];
    }, []);
};

export default transformDeliveryCodesState;
