import { useMutation, useQuery } from '@apollo/client';
import React, { FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ReactSelect from 'react-select';
import { AssignBusUserToAppointment, GetBusUserProfile } from '../../queries';
import { toReadableDate } from '../../utils/dates';
import { BookingOrder } from '../../views/Bookings/types';
import ModalDialog from '../Modal/ModalDialog';
import { ModalBody, ModalFooter } from '../Modal/styled';
import { BusUserProfile } from '../Profile/types';
import { FormButtonsContainer, FormLabel, FormSubmitButton, InputsWrapper, SectionSpan, WideInputGroup, selectTheme } from '../Shared/Forms/Forms';
import { Container } from '../Shared/Shared';

interface BookingAssignModalFormProps {
  appointments: BookingOrder;
  busUsers: BusUserProfile[];
  formOptions: Partial<ReturnType<typeof useForm>>;
  loadingAdd: boolean;
  existingAssigned: string[];
  onSubmit: () => void;
}

const BookingAssignModalForm: FC<BookingAssignModalFormProps> = ({ appointments, busUsers, formOptions, loadingAdd, existingAssigned, onSubmit }) => {
  const { control } = formOptions;
  const petsIds = [...new Set(appointments.map(appointment => appointment.PetRecord.Pet.id))];
  const pets = petsIds.map(petId => appointments.find(appointment => appointment.PetRecord.Pet.id === petId)?.PetRecord.Pet.name);
  const appUsersIds = [...new Set(appointments.map(appointment => appointment.OrderItem.Order.AppUser.id))];
  const appUsersLength = appUsersIds.length;
  const appUsers = appUsersIds.map(appUserId => appointments.find(appointment => appointment.OrderItem.Order.AppUser.id === appUserId)?.OrderItem.Order.AppUser.name);

  const appUsersNames = appUsersLength > 1 ? `${appUsersLength} Clients` : appUsers.join(' & ');

  const petsNames = appUsersLength > 1 ? `${petsIds.length} Pets` : pets.join(' & ');

  return (
    <>
      <ModalBody>
        <Container>
          <FormLabel>Service</FormLabel>
          <SectionSpan>{appointments[0].OrderItem.item.name}</SectionSpan>
          <FormLabel>Booking Slot</FormLabel>
          <SectionSpan>{toReadableDate(appointments[0].timestamp)}</SectionSpan>
          <FormLabel>Booked by</FormLabel>
          <SectionSpan>{appUsersNames}</SectionSpan>
          <FormLabel>Booked for</FormLabel>
          <SectionSpan>{petsNames}</SectionSpan>
          <InputsWrapper noWrap>
            <WideInputGroup>
              <FormLabel>Staff Member</FormLabel>
              <Controller
                render={({ onChange, value }) => (
                  <ReactSelect
                    theme={selectTheme}
                    onChange={onChange}
                    isMulti
                    value={value}
                    options={busUsers}
                    getOptionValue={busUser => busUser?.id}
                    getOptionLabel={busUser => busUser?.name}
                    name={'busUserId'}
                  />
                )}
                control={control}
                name={'busUserId'}
                defaultValue={busUsers.filter(({ id }) => existingAssigned.includes(id))}
              />
            </WideInputGroup>
          </InputsWrapper>
        </Container>
      </ModalBody>
      <ModalFooter>
        <FormButtonsContainer>
          <FormSubmitButton loading={loadingAdd} onClick={onSubmit}>
            Assign
          </FormSubmitButton>
        </FormButtonsContainer>
      </ModalFooter>
    </>
  );
};

export const BookingAssignModal = ({ appointments }: { appointments: BookingOrder }) => {
  const bookingBusUsers = appointments?.[0].BusUsers;
  const existingAssigned = bookingBusUsers?.map(({ id }) => id);
  const { control, handleSubmit, errors } = useForm<{
    busUserId: BusUserProfile[];
  }>({
    defaultValues: {
      busUserId: bookingBusUsers
    }
  });

  const { data: { getBusUserProfile: { Branch: { BusUsers = [] } = {} } = {} } = {} } = useQuery<{
    getBusUserProfile: BusUserProfile;
  }>(GetBusUserProfile);

  const [addStaff, { data: addedStaff, loading: loadingAdd }] = useMutation<{ assignBusUserToAppointment: BookingOrder }>(AssignBusUserToAppointment);

  const onSubmit = handleSubmit(({ busUserId }) => {
    addStaff({
      variables: {
        id: [...new Set(appointments.map(appointment => appointment.id))],
        BusUserId: busUserId?.length ? busUserId.map(({ id }) => id) : null
      }
    });
  });

  useEffect(() => {
    if (addedStaff?.assignBusUserToAppointment?.length) {
      ModalDialog.closeModal();
    }
  }, [addedStaff]);

  return <BookingAssignModalForm appointments={appointments} busUsers={BusUsers} formOptions={{ control, errors }} loadingAdd={loadingAdd} existingAssigned={existingAssigned} onSubmit={onSubmit} />;
};

export default BookingAssignModal;
