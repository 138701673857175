import { useQuery, useReactiveVar } from '@apollo/client';
import React, { useEffect, useMemo, useRef } from 'react';
import CalendarBookings from '../../components/Calendar/CalendarBookings';
import CalendarHeader from '../../components/Calendar/CalendarHeader';
import { BookingMessageButton, BookingMessageContainer, BookingMessageText, BookingMessageWrapper, CalendarContainer, DaysContainer, ExtensionFullBody } from '../../components/Calendar/styled';
import { CALENDAR_MESSAGES, CALENDAR_VIEWS, CALENDAR_VIEW_LENGTH } from '../../components/Calendar/types';
import useCalendarNavigation from '../../components/Calendar/useCalendarNavigation';
import { BusUserProfile } from '../../components/Profile/types';
import { ViewContainer } from '../../components/Shared/Shared';
import useIcons from '../../hooks/useIcons';
import useMediaQuery from '../../hooks/useMediaQuery';
import { GetBusUserProfile } from '../../queries';
import { vars } from '../../reactive';
import { hideCalendarActionMessage } from '../../reactive/actions';

const Calendar = () => {
  const icons = useIcons();
  const showCalendarMessage = useReactiveVar(vars.calendarMessage);
  const { mobile } = useMediaQuery({ mobile: true });
  const selectedBookingType = useReactiveVar(vars.calendarSelectedBookingType) || CALENDAR_VIEWS.MY_SCHEDULE;
  const selectedBookingTypeState = [selectedBookingType, vars.calendarSelectedBookingType] as [CALENDAR_VIEWS, (value: CALENDAR_VIEWS) => void];
  const calendarHeaderRef = useRef<{ refetchAvailability: () => void }>(null);
  const multiStaffBookingType = selectedBookingType === CALENDAR_VIEWS.MULTI_STAFF;

  const defaultCalendarLength = useMemo(() => {
    const length = CALENDAR_VIEW_LENGTH[selectedBookingType];
    if (selectedBookingType === CALENDAR_VIEWS.MULTI_STAFF || !mobile) {
      return length;
    }
    return 1;
  }, [selectedBookingType, mobile]);
  const calendarLength = useReactiveVar(vars.calendarLength);

  const currentCalendarLength = calendarLength || defaultCalendarLength;

  const currentCalendarLengthState = [currentCalendarLength, vars.calendarLength] as [number, React.Dispatch<React.SetStateAction<number>>];

  const { data: { getBusUserProfile: busUserProfile } = {}, refetch: refetchProfile } = useQuery<{ getBusUserProfile: BusUserProfile }>(GetBusUserProfile, {
    fetchPolicy: 'cache-and-network'
  });

  const profile = (busUserProfile || {}) as BusUserProfile;

  const BusUsers = profile?.Branch?.BusUsers || [];

  const calendarNavigation = useCalendarNavigation({
    step: currentCalendarLength,
    useDefaultPage: !multiStaffBookingType && !mobile
  });

  const [firstDayOfWeek] = calendarNavigation;

  useEffect(() => {
    return () => {
      hideCalendarActionMessage();
      vars.selectedDate(null);
    };
  }, []);

  useEffect(() => {
    if (!calendarLength) {
      vars.calendarLength(defaultCalendarLength);
    }
  }, [calendarLength, defaultCalendarLength]);

  const refetchAvailability = () => {
    if (calendarHeaderRef.current) {
      calendarHeaderRef.current.refetchAvailability();
    }
  };

  return (
    <ViewContainer>
      <CalendarContainer>
        <BookingMessageContainer active={showCalendarMessage}>
          <BookingMessageWrapper closeButton={true} active={showCalendarMessage}>
            <BookingMessageText>{CALENDAR_MESSAGES.SELECT}</BookingMessageText>
            <BookingMessageButton
              onClick={() => {
                hideCalendarActionMessage();
              }}
              bgImage={icons.closeTransparent.childImageSharp.gatsbyImageData.images.fallback.src}
            />
          </BookingMessageWrapper>
        </BookingMessageContainer>
        <CalendarHeader
          BusUsers={BusUsers}
          selectedBookingTypeState={selectedBookingTypeState}
          calendarNavigation={calendarNavigation}
          profile={profile}
          currentCalendarLengthState={currentCalendarLengthState}
          defaultCalendarLength={defaultCalendarLength}
          ref={calendarHeaderRef}
        />
        <ExtensionFullBody fullHeight={multiStaffBookingType}>
          <DaysContainer>
            <CalendarBookings
              BusUsers={BusUsers}
              selectedBookingType={selectedBookingType}
              currentCalendarLength={currentCalendarLength}
              firstDayOfWeek={firstDayOfWeek}
              profile={profile}
              refetchProfile={refetchProfile}
              refetchAvailability={refetchAvailability}
            />
          </DaysContainer>
        </ExtensionFullBody>
      </CalendarContainer>
    </ViewContainer>
  );
};

export default Calendar;
