import styled from 'styled-components';

export const NewBookingModalLoaderContainer = styled.div`
  padding: 20px 0;
`;

export const OrderGroupsContainer = styled.div`
  width: 100%;
  margin-bottom: 32px;
`;

export const OrderGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const OrderGroupProductsWrapper = styled.div``;

export const OrderGroupProductsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const OrderGroupSessionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
`;

export const OrderGroupSessionsContainer = styled.div`
  width: 100%;
`;
