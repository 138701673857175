import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react';
import { DatePicker, RangePicker } from 'react-trip-date';
import { getStartOfToday } from '../../utils/dates';
import { Triangle } from '../Shared/Menus/OptionDropdown/styled';
import { OPTION_DROPDOWN_MENU_POSITIONS } from '../Shared/Menus/OptionDropdown/types';
import { UserExtendButton } from '../UserDrawer/styled';
import { CalendarRangeContainer } from './styled';

type CalendarRangeProps = {
  currentCalendarLength: number;
  setUTCDate: (value: Date) => void;
  useDatePicker?: boolean;
  defaultValues?: { from: string; to: string };
};

export type CalendarRangeRef = {
  toggleRangePicker: () => void;
  clearRange: () => void;
  setRange: (range: { from: string; to: string }) => void;
};

export const getRange = (startDate: Date, length: number) => {
  const endDate = new Date(new Date(startDate).setUTCDate(startDate.getUTCDate() + length - 1));
  return { from: startDate.toISOString().slice(0, 10), to: endDate.toISOString().slice(0, 10) };
};

const CalendarRange = forwardRef<CalendarRangeRef, CalendarRangeProps>(({ currentCalendarLength, setUTCDate, useDatePicker = false, defaultValues = { from: '', to: '' } }, ref) => {
  const [range, setRange] = useState(defaultValues);
  const [datePickerSelectedDate, setUTCDatePickerSelectedDate] = useState<string[]>([]);
  const [show, setShow] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const onRangeChange = (range: { from: string; to: string }) => {
    const [fromY, fromM, fromD] = range.from.split('-').map(Number);
    const from = new Date(fromY, fromM - 1, fromD);

    const fromDate = new Date(Date.UTC(from.getFullYear(), from.getMonth(), from.getDate()));
    const toDate = new Date(fromDate);
    toDate.setUTCDate(toDate.getUTCDate() + currentCalendarLength - 1);
    const newRange = {
      from: fromDate.toISOString().slice(0, 10),
      to: toDate.toISOString().slice(0, 10)
    };

    setRange(newRange);
    setUTCDatePickerSelectedDate([newRange.from]);
    setUTCDate(from);
  };

  const onDatePickerChange = (dates: string[]) => {
    const newDate = dates[dates.length - 1];
    setUTCDatePickerSelectedDate([newDate]);
    setUTCDate(new Date(newDate));
  };

  const clearInput = () => {
    setRange({ from: '', to: '' });
    setUTCDatePickerSelectedDate([]);
  };

  useImperativeHandle(ref, () => ({
    toggleRangePicker: () => {
      setShow(prev => {
        if (!prev) {
          containerRef.current?.focus();
        }
        return !prev;
      });
    },

    clearRange: clearInput,

    setRange: (range: { from: string; to: string }) => {
      setRange(range);
      setUTCDatePickerSelectedDate([range.from]);
    }
  }));

  const handleBlur = useCallback(
    (e: React.FocusEvent<HTMLDivElement, Element>) => {
      const currentTarget = e.currentTarget;
      requestAnimationFrame(() => {
        if (!currentTarget.contains(document.activeElement)) {
          setShow(false);
        }
      });
    },
    [setShow]
  );

  const goToToday = () => {
    const today = getStartOfToday();
    const fromDate = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate()));
    const toDate = new Date(fromDate);
    toDate.setUTCDate(toDate.getUTCDate() + currentCalendarLength - 1);
    const newRange = {
      from: fromDate.toISOString().slice(0, 10),
      to: toDate.toISOString().slice(0, 10)
    };

    setRange(newRange);
    setUTCDatePickerSelectedDate([newRange.from]);
    setUTCDate(today);
  };

  return (
    <CalendarRangeContainer show={show} tabIndex={0} onBlur={handleBlur} ref={containerRef}>
      <Triangle menuPosition={OPTION_DROPDOWN_MENU_POSITIONS.RIGHT} />
      {!useDatePicker && <RangePicker autoResponsive onChange={onRangeChange} selectedDays={range} initialMonthAndYear={range.from ? range.from : undefined} />}
      {useDatePicker && (
        <DatePicker
          autoResponsive
          onChange={onDatePickerChange}
          selectedDays={datePickerSelectedDate}
          initialMonthAndYear={datePickerSelectedDate.length > 0 ? datePickerSelectedDate[0] : undefined}
        />
      )}
      <UserExtendButton noMargin onClick={goToToday}>
        Today
      </UserExtendButton>
    </CalendarRangeContainer>
  );
});

export default CalendarRange;
