import { Router } from '@reach/router';
import React from 'react';
import styled from 'styled-components';
import Bookings from './views/Bookings/Bookings';
import Calendar from './views/Calendar/Calendar';
import Chats from './views/Chats/Chats';
import Orders from './views/Orders/Orders';
import Pets from './views/Pets/Pets';
import Store from './views/Store/Store';
import Subscriptions from './views/Subscriptions/Subscriptions';
import BranchPayoutDetails from './views/Store/BranchPayouts/BranchPayoutDetails';

export default function Routes() {
  return (
    <StyledRouter basepath={'/app'}>
      <Bookings path={'bookings/*'} />
      <Store path={'operations/*'} />
      <BranchPayoutDetails path={'operations/payouts/:id'} />
      <Chats path={'chat/*'} />
      <Pets path={'pets/*'} />
      <Orders path={'orders/*'} />
      <Subscriptions path={'memberships/*'} />
      <Calendar path={'calendar/*'} />
    </StyledRouter>
  );
}

const StyledRouter = styled(Router)`
  width: 100%;
  height: 100%;
`;
