import { useMutation } from '@apollo/client';
import React, { FC, useEffect } from 'react';
import { Controller, UseFormMethods, useForm } from 'react-hook-form';
import { sentenceCase } from 'sentence-case';
import { AddBranchPet, EditBranchPet } from '../../queries';
import { getKeys } from '../../utils/helpers';
import ModalDialog from '../Modal/ModalDialog';
import { ModalBody, ModalFooter } from '../Modal/styled';
import { Pet, PetType } from '../Pets/types';
import { FormButtonsContainer, FormError, FormInput, FormLabel, FormSelect, FormSubmitButton, InputsWrapper, SectionLabel, WideInputGroup } from '../Shared/Forms/Forms';
import AppUsersList from '../Shared/Lists/AppUsersList';
import BreedsList from '../Shared/Lists/BreedsList';
import PetColorsList from '../Shared/Lists/PetColorsList';
import { Container } from '../Shared/Shared';

type PetDetailsForm = {
  type: keyof typeof PetType;
  name: string;
  birthdate: string;
  gender: Pet['gender'];
  // profile_pic: string;
  AppUserId: string;
  BreedId: string;
  PetColorId: string;
  petRecord_neutred: string;
};

type PetDetailsModalFormProps = {
  onSubmit: () => void;
  pet?: Pet;
  loading: boolean;
  error: boolean;
  formOptions: UseFormMethods<PetDetailsForm>;
  defaultValues?: PetDetailsForm;
};

const PetDetailsModalForm: FC<PetDetailsModalFormProps> = ({ error, formOptions, loading, onSubmit, pet, defaultValues }) => {
  const { control, errors, watch } = formOptions;

  const watchedValues = watch(['type', 'gender']);

  return (
    <>
      <ModalBody>
        <Container width={480}>
          <InputsWrapper noWrap>
            {!pet?.id && (
              <WideInputGroup>
                <SectionLabel>Client</SectionLabel>
                <FormLabel>Client</FormLabel>
                <AppUsersList
                  formOptions={formOptions}
                  name="AppUserId"
                  rules={{
                    required: true
                  }}
                  defaultValues={defaultValues?.AppUserId ? [defaultValues.AppUserId] : []}
                />
              </WideInputGroup>
            )}
            <WideInputGroup>
              <SectionLabel>Details</SectionLabel>
              <FormLabel>Name</FormLabel>
              <Controller
                as={<FormInput error={errors?.name?.message} type={'text'} height={32} fontSize={16} name={'name'} />}
                control={control}
                name={'name'}
                defaultValue={defaultValues?.name || ''}
                rules={{ required: 'Please add a name', pattern: /^[a-zA-Z0-9\s,.'-]{3,}$/ }}
              />
              {errors?.name && <FormError>{errors?.name?.message}</FormError>}
            </WideInputGroup>
            <WideInputGroup>
              <FormLabel>Type</FormLabel>
              <Controller
                render={({ onChange, value }) => (
                  <FormSelect height={40} value={value} onChange={e => onChange(e.target.value)} error={errors?.type?.message} name={'type'}>
                    {getKeys(PetType).map(key => (
                      <option key={key} value={key}>
                        {sentenceCase(PetType[key])}
                      </option>
                    ))}
                  </FormSelect>
                )}
                control={control}
                name={'type'}
                defaultValue={defaultValues?.type || ''}
                rules={{ required: 'Please select a type' }}
              />
              {errors?.type && <FormError>{errors?.type?.message}</FormError>}
            </WideInputGroup>
            <WideInputGroup>
              <FormLabel>Gender</FormLabel>
              <Controller
                render={({ onChange, value }) => (
                  <FormSelect height={40} value={value} onChange={e => onChange(e.target.value)} error={errors?.gender?.message}>
                    <option value={'MALE'}>Male</option>
                    <option value={'FEMALE'}>Female</option>
                  </FormSelect>
                )}
                control={control}
                name={'gender'}
                defaultValue={defaultValues?.gender || 'MALE'}
                rules={{ required: 'Please select a gender' }}
              />

              {errors?.gender && <FormError>{errors?.gender?.message}</FormError>}
            </WideInputGroup>
            <WideInputGroup>
              <FormLabel>Birthdate</FormLabel>
              <Controller
                as={<FormInput error={errors?.birthdate?.message} type={'date'} height={32} fontSize={16} name={'birthdate'} max={new Date().toISOString().split('T')[0]} />}
                control={control}
                name={'birthdate'}
                defaultValue={defaultValues?.birthdate || ''}
                rules={{ required: 'Please add a birthdate', validate: value => new Date(value) < new Date() || 'Birthdate cannot be in the future', max: new Date().toISOString().split('T')[0] }}
              />
              {errors?.birthdate && <FormError>{errors?.birthdate?.message}</FormError>}
            </WideInputGroup>
            <WideInputGroup>
              <FormLabel>Breed</FormLabel>
              <BreedsList formOptions={formOptions} name="BreedId" defaultBreedId={defaultValues?.BreedId} petType={watchedValues?.type} />
            </WideInputGroup>
            <WideInputGroup>
              <FormLabel>{watchedValues?.gender === 'FEMALE' ? 'Spayed' : 'Neutered'}</FormLabel>
              <Controller
                render={({ onChange }) => (
                  <FormSelect height={48} fontSize={16} name={'petRecord_neutred'} onChange={e => onChange(e.target.value)} error={errors?.petRecord_neutred?.message}>
                    <option value={'true'}>YES</option>
                    <option value={'false'}>NO</option>
                  </FormSelect>
                )}
                control={control}
                name={'petRecord_neutred'}
                rules={{ required: true }}
                defaultValue={defaultValues?.petRecord_neutred || false}
              />
              {errors?.petRecord_neutred && <FormError>{errors?.petRecord_neutred?.message}</FormError>}
            </WideInputGroup>
            <WideInputGroup>
              <FormLabel>Color</FormLabel>
              <PetColorsList formOptions={formOptions} name="PetColorId" defaultColorId={defaultValues?.PetColorId} petType={watchedValues?.type} />
            </WideInputGroup>
          </InputsWrapper>
        </Container>
      </ModalBody>
      <ModalFooter>
        <FormButtonsContainer>
          <FormSubmitButton error={error} loading={loading} onClick={onSubmit}>
            {pet?.id ? 'Update' : 'Add'}
          </FormSubmitButton>
        </FormButtonsContainer>
      </ModalFooter>
    </>
  );
};

const PetDetailsModal = ({ pet, initialValues }: { pet?: Pet; initialValues?: Partial<PetDetailsForm> }) => {
  const defaultValues: PetDetailsForm = {
    AppUserId: initialValues?.AppUserId || pet?.AppUser?.id || pet?.AppUserId || '',
    BreedId: initialValues?.BreedId || pet?.Breed?.id || '',
    PetColorId: initialValues?.PetColorId || pet?.PetColor?.id || '',
    birthdate: initialValues?.birthdate || pet?.birthdate || '',
    gender: initialValues?.gender || pet?.gender || 'MALE',
    name: initialValues?.name || pet?.name || '',
    type: initialValues?.type || pet?.type || PetType.DOG,
    petRecord_neutred: 'false'
  };
  const formOptions = useForm<PetDetailsForm>({ defaultValues });
  const { handleSubmit } = formOptions;

  const [handleEditPet, { data: editedUser, loading: loadingEditUser, error: errorEditUser }] = useMutation<{ editBranchPet: Pet }>(EditBranchPet);

  const [handleAddPet, { data: addedUser, loading: loadingAddUser, error: errorAddUser }] = useMutation<{ addBranchPet: Pet }>(AddBranchPet);

  const onSubmit = handleSubmit(form => {
    const variables = {
      BreedId: form.BreedId,
      PetColorId: form.PetColorId || null,
      birthdate: form.birthdate,
      gender: form.gender,
      name: form.name,
      type: form.type,
      petRecord_neutred: form.petRecord_neutred === 'true'
    };

    if (!pet?.id) {
      handleAddPet({ variables: { ...variables, AppUserId: form.AppUserId } });
      return;
    }

    handleEditPet({ variables: { ...variables, id: pet?.id } });
  });

  useEffect(() => {
    if (editedUser?.editBranchPet?.id || addedUser?.addBranchPet?.id) {
      ModalDialog.closeModal();
    }
  }, [editedUser, addedUser]);

  const loading = loadingEditUser || loadingAddUser;
  const error = !!errorEditUser?.message || !!errorAddUser?.message;

  return <PetDetailsModalForm onSubmit={onSubmit} loading={loading} error={error} pet={pet} formOptions={formOptions} defaultValues={defaultValues} />;
};

export default PetDetailsModal;
