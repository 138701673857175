import React, { useEffect } from 'react';
import { DRAWER_IDS } from '../../../components/DrawerBar/types';
import InfiniteList from '../../../components/Shared/InfiniteList/InfiniteList';
import useNavigateDrawer from '../../../hooks/useNavigateDrawer';
import usePaginatedQuery from '../../../hooks/usePaginatedQuery';
import useSetDrawerOnNavigation from '../../../hooks/useSetDrawerOnNavigation';
import { GetServices } from '../../../queries';
import { RecordsContainer } from '../../styled';
import Common from '../Common';
import ServiceRecord from './ServiceRecord';

const Services = () => {
  const [[hasMoreItems, setHasMoreItems], queryResult] = usePaginatedQuery({ query: GetServices });

  const { data: { getProducts: services = [] } = {}, loading, fetchMore, refetch } = queryResult;

  useSetDrawerOnNavigation({
    itemList: [],
    drawerId: DRAWER_IDS.SERVICE_DRAWER
  });

  const navigateDrawer = useNavigateDrawer({
    drawerData: {
      drawerId: DRAWER_IDS.SERVICE_DRAWER
    }
  });

  useEffect(() => {
    Common.set(`Services.GetServices.refetch`, () => {
      refetch?.().then(() => setHasMoreItems(true));
    });
  }, []);

  return (
    <RecordsContainer>
      <InfiniteList
        list={services}
        itemRenderer={service => <ServiceRecord key={service.id} service={service} navigateDrawer={navigateDrawer} />}
        hasMoreItems={hasMoreItems}
        loading={loading}
        fetchMore={fetchMore}
        offset={services?.length}
        setHasMoreItems={setHasMoreItems}
      />
    </RecordsContainer>
  );
};

export default Services;
