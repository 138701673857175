import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import useBookingActions from '../../hooks/useBookingActions';
import useBookingTagsOptions from '../../hooks/useBookingTagsOptions';
import useMediaQuery from '../../hooks/useMediaQuery';
import usePetRecordOptions from '../../hooks/usePetRecordOptions';
import { RequireAtLeastOne } from '../../utils/helpers';
import { Booking } from '../../views/Bookings/types';
import { BranchAppointmentTag, BranchPetRecordTag } from '../../views/Store/BranchTags/types';
import { HoverRecordOptionsContainer } from '../../views/styled';
import BookingAssignModal from '../BookingsDrawer/BookingAssignModal';
import ModalDialog from '../Modal/ModalDialog';
import { Pet } from '../Pets/types';
import OptionDropdown, { OptionDropDownItem, OptionDropdownRef } from '../Shared/Menus/OptionDropdown/OptionDropdown';
import { OPTION_DROPDOWN_MENU_BUTTON_TYPES, OPTION_DROPDOWN_MENU_POSITIONS, OPTION_DROPDOWN_TYPES } from '../Shared/Menus/OptionDropdown/types';

type BookingRecordOptionsProps = {
  pets: Pet[];
  petQuickTags?: BranchPetRecordTag[];
  bookingQuickTags?: BranchAppointmentTag[];
  refetchAppointmentsAfterTag?: boolean;
  appointments?: Booking[];
  orderId?: string;
  showAlways?: boolean;
  loadTags?: boolean;
  refetchAfterTag?: boolean;
  right?: boolean;
  defaultAppointments?: any[];
};

type Props = RequireAtLeastOne<BookingRecordOptionsProps, ('petQuickTags' & 'bookingQuickTags') | 'loadTags'>;

export type BookingRecordOptionsRef = {
  getContainerRefOptions?: () => HTMLDivElement | null;
  getOptionDropdownRefOptions: () => OptionDropdownRef | null;
};

const BookingRecordOptions = forwardRef<BookingRecordOptionsRef, Props>(
  (
    { pets, petQuickTags, bookingQuickTags, orderId, showAlways = false, loadTags = false, right = false, defaultAppointments = [], refetchAppointmentsAfterTag = false, appointments = [] },
    bookingRecordOptionsRef
  ) => {
    const { items: bookingItems, loading: bookingLoading } = useBookingTagsOptions({ appointments, quickTags: bookingQuickTags, loadTags, refetchAppointmentsAfterTag });

    const { items: petItems, loading: petLoading } = usePetRecordOptions({ pets, quickTags: petQuickTags, orderId, loadTags, refetchPetsAfterTag: false, defaultAppointments });

    const {
      shouldDisplay: { shouldDisplayAssign, shouldDisplayConfirmationAndRejection },
      actions: { handleConfirmPayment, handleConfirmation, handleRejectCancel }
    } = useBookingActions({
      booking: appointments,
      options: {
        loadAvailability: false,
        loadOrderItems: false
      }
    });

    const { mobile } = useMediaQuery({ mobile: true });
    let items;
    if (!mobile) {
      items = [
        ...petItems.filter(item => item.value === 'newBooking' || item.value === 'newOrder'),
        ...bookingItems,
        shouldDisplayAssign && {
          name: 'Assign',
          value: 'assign',
          onClick: () => ModalDialog.openModal({ content: () => <BookingAssignModal appointments={appointments} />, title: 'Assign to Staff' })
        }
      ].filter(Boolean) as OptionDropDownItem[];
    }

    if (mobile) {
      items = [
        shouldDisplayConfirmationAndRejection && {
          name: 'Confirm',
          value: 'confirm',
          onClick: handleConfirmation,
          green: true
        },
        shouldDisplayConfirmationAndRejection && {
          name: 'Reject',
          value: 'reject',
          onClick: handleRejectCancel,
          danger: true
        },
        ...bookingItems.filter(item => item.value !== 'addTag')
      ].filter(Boolean) as OptionDropDownItem[];
    }

    const loading = bookingLoading || petLoading;

    const containerRef = useRef<HTMLDivElement>(null);
    const optionDropdownRef = useRef<OptionDropdownRef>(null);

    useImperativeHandle(bookingRecordOptionsRef, () => ({
      getContainerRefOptions: () => {
        return containerRef?.current;
      },
      getOptionDropdownRefOptions: () => {
        return optionDropdownRef?.current;
      }
    }));

    return (
      <HoverRecordOptionsContainer ref={containerRef} show={loading || showAlways}>
        <OptionDropdown
          menuButtonType={!mobile ? OPTION_DROPDOWN_MENU_BUTTON_TYPES.PLUS : OPTION_DROPDOWN_MENU_BUTTON_TYPES.MORE}
          noApplyButton
          containerRelative
          buttonLoading={loading}
          options={[
            {
              optionType: OPTION_DROPDOWN_TYPES.BUTTONS,
              id: 'bookingOptions',
              items
            }
          ]}
          menuPosition={right ? OPTION_DROPDOWN_MENU_POSITIONS.RIGHT : OPTION_DROPDOWN_MENU_POSITIONS.LEFT}
          ref={optionDropdownRef}
        />
      </HoverRecordOptionsContainer>
    );
  }
);

export default BookingRecordOptions;
