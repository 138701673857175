import styled, { css } from 'styled-components';

export const BranchSummaryCountsContainer = styled.div<{ isExpanded?: boolean }>`
  display: grid;
  grid-template-columns: ${({ isExpanded }) => css`repeat(auto-fill, minmax(${isExpanded ? '1fr' : '40vw'}, 1fr))`};
  gap: 20px;
  padding: 20px;
`;

export const BranchSummaryLoaderContainer = styled.div`
  padding: 20px;
  display: flex;
  gap: 20px;
`;

export const BranchSummaryCountContainer = styled.div<{ isExpanded?: boolean; isNew?: boolean }>`
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  background-color: #fff;
  transition: all 0.2s ease-in-out;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  height: ${({ isExpanded }) => (isExpanded ? '600px' : '400px')};
  ${({ isNew }) =>
    isNew &&
    css`
      background-color: #fff;
      border: 3px dashed #ccc;
    `}
`;

export const BranchSummaryAddContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  height: 100%;
  flex: 1;
  justify-content: center;
  &:hover {
    cursor: pointer;
    background-color: #eee;
  }
`;

export const BranchSummaryCountWrapper = styled.div<{ isExpanded?: boolean }>`
  height: ${({ isExpanded }) => (isExpanded ? '460px' : '260px')};
  width: calc(100% - 20px);
  overflow-y: scroll;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 0 10px;
`;

export const BranchSummaryCountHeader = styled.div`
  display: grid;
  width: 100%;
  top: 0;
  background-color: #fff;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
`;

export const BranchSummaryCountHeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
`;

export const BranchSummaryCountTitle = styled.span`
  font-size: 20px;
  font-weight: 800;
`;

export const BranchSummaryCountHeaderOptions = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
`;

export const BranchSummaryCountMode = styled.button<{ isActive?: boolean }>`
  padding: 10px;
  border-radius: 10px;
  border: none;
  background-color: ${({ isActive }) => (isActive ? '#eee' : '#fff')};
  &:hover {
    background-color: #eee;
    cursor: pointer;
  }
`;

export const BranchSummaryCountHeaderFilters = styled.div`
  display: flex;
  gap: 20px;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  min-width: 0;
  flex: 1;
  width: 100%;
  padding: 10px 20px;
`;

export const BranchSummaryCountFilterTitle = styled.span`
  font-size: 14px;
`;

export const BranchSummaryCountTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  margin-top: 20px;
  border-radius: 10px;

  & td,
  th {
    padding: 10px;
  }
  & tr {
    &:nth-child(even) {
      background-color: #eee;
    }
  }
  border: 2px solid #eee;
`;

export const BranchSummaryCountTableHeader = styled.thead`
  background-color: #eee;
`;

export const BranchSummaryCountTableBody = styled.tbody``;

export const BranchSummaryCountTableRow = styled.tr`
  &:not(:last-child) {
    border-bottom: 1px solid #eee;
  }
`;

export const BranchSummaryCountTableHeaderCell = styled.th`
  padding: 10px;
`;

export const BranchSummaryCountTableCell = styled.td`
  padding: 10px;
`;

export const BranchSummaryFilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex: 1;
  padding: 20px;
`;

export const BranchSummaryFilterWrapper = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  gap: 12px;
`;

export const BranchSummaryFilterInputContainer = styled.div`
  width: 90%;
`;

export const BranchSummaryFilterDateContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  margin-top: 20px;
`;
