import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import { ButtonsContainer } from '../../../components/BookingsDrawer/styled';
import ModalDialog from '../../../components/Modal/ModalDialog';
import OrderInvoicesList from '../../../components/OrderInvoices/OrderInvoicesList';
import { OrderInvoice } from '../../../components/OrderInvoices/types';
import useIcons from '../../../hooks/useIcons';
import usePaginatedQuery from '../../../hooks/usePaginatedQuery';
import { GetBranchOrderInvoices } from '../../../queries';
import { BranchSummaryCountMode } from '../BranchSummary/styled';
import OrderInvoicesDownloadModal from './OrderInvoicesDownloadModal';
import { BRANCH_BILLING_INVOICES_FILTERS, BRANCH_BILLING_INVOICES_FILTERS_STATUS, CurrentPeriod } from './types';

const BranchBillingInvoices = ({ period, filter }: { period: CurrentPeriod; filter: BRANCH_BILLING_INVOICES_FILTERS }) => {
  const invoiceFilter = BRANCH_BILLING_INVOICES_FILTERS_STATUS[filter as keyof typeof BRANCH_BILLING_INVOICES_FILTERS_STATUS];
  const unbilledFilter = filter === BRANCH_BILLING_INVOICES_FILTERS.INVOICES_UNBILLED;

  const paginatedQueryResult = usePaginatedQuery<OrderInvoice[]>({
    query: GetBranchOrderInvoices,
    limit: 50,
    otherVariables: {
      billed_on_from: period.start,
      billed_on_to: period.end,
      ...(invoiceFilter && invoiceFilter.length && { status: invoiceFilter }),
      ...(unbilledFilter && { unbilled: true })
    }
  });

  const icons = useIcons();
  const exportIcon = icons?.export?.childImageSharp.gatsbyImageData;

  return (
    <OrderInvoicesList
      paginatedQueryResult={paginatedQueryResult}
      ListHeader={
        <ButtonsContainer style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
          <BranchSummaryCountMode
            style={{ display: 'flex', gap: 10, paddingBottom: 10 }}
            onClick={() =>
              ModalDialog.openModal({
                content: () => (
                  <OrderInvoicesDownloadModal
                    initialValues={{
                      billed_on_from: period.start,
                      billed_on_to: period.end,
                      ...(invoiceFilter && invoiceFilter.length && { status: invoiceFilter }),
                      ...(unbilledFilter && { unbilled: true })
                    }}
                  />
                ),
                title: 'Export Invoices'
              })
            }
          >
            <span>Export</span>
            <GatsbyImage image={exportIcon} alt="export" />
          </BranchSummaryCountMode>
        </ButtonsContainer>
      }
    />
  );
};

export default BranchBillingInvoices;
