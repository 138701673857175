import { useQuery } from '@apollo/client';
import React from 'react';
import Colors from '../../../Colors';
import { GetProduct } from '../../../queries';
import { getBranchCurrencySymbol } from '../../../utils/getBranchCurrencySymbol';
import { isProductNoCharge } from '../../../views/Store/ProductPrice';
import { LinkedProductContainer } from '../../../views/Store/Products/styled';
import { DrawerContainer, DrawerContent, DrawerSection, DrawerSubLabel } from '../../DrawerBar/styled';
import { WideInputGroup } from '../../Shared/Forms/Forms';
import { FlexCenterCenter } from '../../Shared/Shared';
import { ActivityIndicator } from '../../Shared/Spinner';
import { MorePhotosIndicator, ProductDescription, ProductImage, ProductImageContainer, ProductPrice, ProductTitle } from './styled';

type Props = { data: string | Record<string, any> | undefined };
export const ProductDrawer = ({ data: productId = '' }: Props) => {
  const { data: { productGet: [product = {}] = [] } = {}, loading } = useQuery(GetProduct, { variables: { id: productId } });
  const photosLength = loading ? 0 : product?.photos?.length;
  const price = isProductNoCharge(product) ? 'Free' : getBranchCurrencySymbol() + product?.price;
  return (
    <DrawerContainer scrollable>
      {loading ? (
        <DrawerSection paddingY={55}>
          <FlexCenterCenter height={200}>
            <ActivityIndicator size={30} color={Colors.primary} />
          </FlexCenterCenter>
        </DrawerSection>
      ) : (
        <>
          <DrawerSection paddingT={55}>
            <ProductTitle>{product?.name}</ProductTitle>
            <ProductPrice>{price}</ProductPrice>
            {!!product?.PharmaItemProducts?.length && (
              <WideInputGroup>
                <LinkedProductContainer>
                  <DrawerSubLabel size={16} weight={700} color={Colors.black}>
                    Linked to: {product?.PharmaItemProducts?.[0]?.PharmaItem?.description}
                  </DrawerSubLabel>
                  <DrawerSubLabel size={16} weight={600} color={Colors.black}>
                    Net Cost: {getBranchCurrencySymbol()}
                    {product?.PharmaItemProducts?.[0]?.PharmaItem?.cost}
                  </DrawerSubLabel>
                  <DrawerSubLabel size={16} weight={600} color={Colors.black}>
                    Supplier: {product?.PharmaItemProducts?.[0]?.PharmaItem?.supplier}
                  </DrawerSubLabel>
                  <DrawerSubLabel size={16} weight={600} color={Colors.black}>
                    Controlled Drug: {product?.PharmaItemProducts?.[0]?.PharmaItem?.controlled_drug ? 'Yes' : 'No'}
                  </DrawerSubLabel>
                  <DrawerSubLabel size={16} weight={600} color={Colors.black}>
                    Margin: {product?.PharmaItemProducts?.[0]?.price_margin}%
                  </DrawerSubLabel>
                  <DrawerSubLabel size={16} weight={600} color={Colors.black}>
                    Pack Size: {product?.PharmaItemProducts?.[0]?.PharmaItem?.pack_size}
                  </DrawerSubLabel>
                  <DrawerSubLabel size={16} weight={600} color={Colors.black}>
                    Stock {product?.stock ?? 'Unlimited'}
                  </DrawerSubLabel>
                </LinkedProductContainer>
              </WideInputGroup>
            )}
            {photosLength > 0 && (
              <ProductImageContainer hasMorePhotos={photosLength > 1}>
                <ProductImage src={product?.photos?.[0]} />
                {photosLength > 1 && <MorePhotosIndicator>{'+' + (photosLength - 1)}</MorePhotosIndicator>}
              </ProductImageContainer>
            )}
          </DrawerSection>
          <DrawerContent>
            <ProductDescription dangerouslySetInnerHTML={{ __html: product?.description }} />
          </DrawerContent>
        </>
      )}
    </DrawerContainer>
  );
};
