import React, { FC } from 'react';
import { Controller } from 'react-hook-form';
import { FormError, FormInput, FormLabel, FormSelect, InputContainer, RemoveRowContainer } from '../../../components/Shared/Forms/Forms';
import { FlexRow } from '../../../components/Shared/Shared';
import useIcons from '../../../hooks/useIcons';
import { DiscountsFormContainer } from '../Operations/styled';
import { RemoveAddedImage } from '../styled';
import { DiscountRule as DiscountRuleType, DiscountRulesProps } from './types';

const DiscountRule: FC<DiscountRulesProps> = ({ rule, index, formOptions, discountIndex, handleRemoveRule }) => {
  const { control, errors, watch } = formOptions;
  const watchedRules: DiscountRuleType[] = watch(`discounts[${discountIndex}].rules`);
  const watchedRule = watchedRules[index];
  const icons = useIcons();

  const previousRules = [...watchedRules].slice(0, index);

  return (
    <FlexRow>
      <DiscountsFormContainer>
        <FlexRow>
          <InputContainer>
            <FormLabel>Rule</FormLabel>
            <Controller
              render={({ onChange, value }) => (
                <FormSelect error={!!errors?.discounts?.[discountIndex]?.rules?.[index]?.rule?.type} height={38} fontSize={16} onChange={onChange} value={value} width={160}>
                  <option value="none" disabled>
                    -- Select Rule --
                  </option>
                  <option value="min_pets" disabled={!!previousRules.find(rule => rule.rule.type === 'min_pets')}>
                    Min Pets
                  </option>
                  <option value="min_total" disabled={!!previousRules.find(rule => rule.rule.type === 'min_total')}>
                    Min Total
                  </option>
                  {/* <option value="min_orders">Min Orders</option> */}
                </FormSelect>
              )}
              control={control}
              name={`discounts[${discountIndex}].rules[${index}].rule.type`}
              defaultValue={rule?.rule?.type || 'none'}
              rules={{ required: true, validate: value => value !== 'none' || 'Rule is required' }}
            />
            {!!errors?.discounts?.[discountIndex]?.rules?.[index]?.rule?.type && <FormError>{errors?.discounts?.[discountIndex]?.rules?.[index]?.rule?.type?.message || 'Rule is required'}</FormError>}
          </InputContainer>
          {/* <InputContainer>
              <FormLabel>Level</FormLabel>
              <Controller
                render={({ onChange, value }) => (
                  <FormSelect error={!!errors?.discounts?.[discountIndex]?.rules?.[index]?.level} height={38} fontSize={16} onChange={onChange} value={value || 'none'} width={160}>
                    <option value="main">Main</option>
                    <option value="auto_apply">Auto Apply</option>
                  </FormSelect>
                )}
                control={control}
                name={`discounts[${discountIndex}].rules[${index}].level`}
                defaultValue={rule?.level || 'MAIN'}
                rules={{ required: true }}
              />
              {!!errors?.discounts?.[discountIndex]?.rules?.[index]?.level && <FormError>{errors?.discounts?.[discountIndex]?.rules?.[index]?.level?.message || 'Level is required'}</FormError>}
            </InputContainer> */}
          <InputContainer>
            <FormLabel>Amount</FormLabel>
            <Controller
              as={<FormInput error={!!errors?.discounts?.[discountIndex]?.rules?.[index]?.rule?.amount} height={20} fontSize={16} width={60} />}
              control={control}
              name={`discounts[${discountIndex}].rules[${index}].rule.amount`}
              defaultValue={rule?.rule?.amount || 0}
              rules={{ required: true }}
            />
            {!!errors?.discounts?.[discountIndex]?.rules?.[index]?.rule?.amount && (
              <FormError>{errors?.discounts?.[discountIndex]?.rules?.[index]?.rule?.amount?.message || 'Amount is required'}</FormError>
            )}
          </InputContainer>
          {watchedRule?.rule?.type === 'min_orders' && (
            <InputContainer>
              <FormLabel>Offset</FormLabel>
              <Controller
                as={<FormInput error={!!errors?.discounts?.[discountIndex]?.rules?.[index]?.rule?.offset} height={20} fontSize={16} width={60} />}
                control={control}
                name={`discounts[${discountIndex}].rules[${index}].rule.offset`}
                defaultValue={rule?.rule?.offset || 0}
                rules={{ required: true }}
              />
              {!!errors?.discounts?.[discountIndex]?.rules?.[index]?.rule?.offset && (
                <FormError>{errors?.discounts?.[discountIndex]?.rules?.[index]?.rule?.offset?.message || 'Offset is required'}</FormError>
              )}
            </InputContainer>
          )}
        </FlexRow>
        <InputContainer>
          <FormLabel>Description</FormLabel>
          <Controller
            as={<FormInput error={!!errors?.discounts?.[discountIndex]?.rules?.[index]?.description} height={20} fontSize={16} />}
            control={control}
            name={`discounts[${discountIndex}].rules[${index}].description`}
            defaultValue={rule?.description || ''}
            rules={{ required: true }}
          />
          {!!errors?.discounts?.[discountIndex]?.rules?.[index]?.description && (
            <FormError>{errors?.discounts?.[discountIndex]?.rules?.[index]?.description?.message || 'Description is required'}</FormError>
          )}
        </InputContainer>
      </DiscountsFormContainer>
      <RemoveRowContainer onClick={() => handleRemoveRule(index)}>
        <RemoveAddedImage src={icons.delete.childImageSharp.gatsbyImageData.images.fallback.src} />
      </RemoveRowContainer>
    </FlexRow>
  );
};

export default DiscountRule;
