import styled from 'styled-components';

export const CustomizationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  max-width: 600px;
  margin-bottom: 20px;
`;

export const CustomizationsFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  padding: 10px;
`;

export const CustomizationsFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  margin-bottom: 10px;
`;

export const CustomizationFormOptionsWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const CustomizationFormOptionsContainer = styled.div<{ column?: boolean }>`
  display: flex;
  flex-direction: ${({ column }) => (column ? 'column' : 'row')};
  width: 100%;
  flex: 1;
`;

export const CustomizationInputContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const CenteredCustomizationInputContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;

export const CustomizationTypeContainer = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
`;
