import { useLocation } from '@reach/router';
import { Link } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import React, { useMemo } from 'react';
import useIcons from '../../hooks/useIcons';
import useMediaQuery from '../../hooks/useMediaQuery';
import useUnreadBranchItemsCount from '../../hooks/useUnreadBranchItemsCount';
import { useWindowSize } from '../../hooks/useWindowResize';
import { unsetDrawerBars } from '../../reactive/actions';
import BusinessUserContent from './BusinessUserContent';
import { BusinessUserContainer, Divider, Icon, LogoContainer, LogoImage, MenuItem, MenuItemNotification, MenuItemWrapper, Name, SideBarContainer, SideBarMenu } from './styled';

enum SIDEBAR_ITEMS {
  BOOKINGS = 'BOOKINGS',
  ORDERS = 'ORDERS',
  MEMBERSHIPS = 'MEMBERSHIPS',
  MESSAGES = 'MESSAGES',
  PETS = 'PETS',
  STORE = 'STORE',
  CALENDAR = 'CALENDAR'
}

export default function SideBar() {
  const location = useLocation();
  const icons = useIcons();
  const windowSize = useWindowSize();
  const { mobile, tablet } = useMediaQuery({ mobile: true, tablet: true });

  const { unreadChatRoomsCount, unreadProductOrdersCount, unreadServiceOrdersCount, unreadSubscriptionOrdersCount } = useUnreadBranchItemsCount();

  const items = [
    { id: SIDEBAR_ITEMS.BOOKINGS, name: 'Bookings', icon: 'bookings', to: 'bookings', defaultTab: 'confirmed' },
    { id: SIDEBAR_ITEMS.ORDERS, name: 'Orders', icon: 'orders', to: 'orders', defaultTab: 'new' },
    { id: SIDEBAR_ITEMS.MEMBERSHIPS, name: 'Memberships', icon: 'membership', to: 'memberships', defaultTab: 'active' },
    { id: SIDEBAR_ITEMS.MESSAGES, name: 'Messages', icon: 'chatSideBar', to: 'chat', defaultTab: 'all' },
    { id: SIDEBAR_ITEMS.PETS, name: 'Pets', icon: 'pets', to: 'pets', defaultTab: 'all' },
    { id: SIDEBAR_ITEMS.STORE, name: 'Operations', icon: 'services', to: 'operations', defaultTab: 'services' },
    { id: SIDEBAR_ITEMS.CALENDAR, name: 'Calendar', icon: 'calendarIcon', to: 'calendar', defaultTab: '' }
  ];

  const itemsData = {
    [SIDEBAR_ITEMS.BOOKINGS]: { notifications: unreadServiceOrdersCount },
    [SIDEBAR_ITEMS.ORDERS]: { notifications: unreadProductOrdersCount },
    [SIDEBAR_ITEMS.MEMBERSHIPS]: { notifications: unreadSubscriptionOrdersCount },
    [SIDEBAR_ITEMS.MESSAGES]: { notifications: unreadChatRoomsCount },
    [SIDEBAR_ITEMS.PETS]: { notifications: 0 },
    [SIDEBAR_ITEMS.STORE]: { notifications: 0 },
    [SIDEBAR_ITEMS.CALENDAR]: { notifications: 0 }
  };

  const sideBarItems = [SIDEBAR_ITEMS.CALENDAR, SIDEBAR_ITEMS.BOOKINGS, SIDEBAR_ITEMS.PETS, SIDEBAR_ITEMS.ORDERS, SIDEBAR_ITEMS.MEMBERSHIPS, SIDEBAR_ITEMS.MESSAGES, SIDEBAR_ITEMS.STORE];

  const mobileSideBarItems = [SIDEBAR_ITEMS.CALENDAR, SIDEBAR_ITEMS.BOOKINGS, SIDEBAR_ITEMS.PETS, SIDEBAR_ITEMS.MESSAGES];

  const handleSelect = () => {
    unsetDrawerBars();
  };

  const itemsToRender = useMemo(() => {
    let itemsToFiler = sideBarItems;
    if (tablet) {
      itemsToFiler = sideBarItems;
    }
    if (mobile) {
      itemsToFiler = mobileSideBarItems;
    }
    return items.filter(item => itemsToFiler.includes(item.id)).sort((a, b) => itemsToFiler.indexOf(a.id) - itemsToFiler.indexOf(b.id));
  }, [mobile, tablet]);
  const currentSelected = itemsToRender.slice(1).find(item => location.pathname.slice(1).split('/')[1] === item.to) || itemsToRender[0];

  return (
    <SideBarContainer windowHeight={windowSize.height}>
      {!mobile && (
        <LogoContainer>
          <LogoImage alt={'Logo'} image={icons.logo.childImageSharp.gatsbyImageData} />
        </LogoContainer>
      )}
      <SideBarMenu>
        <MenuItemWrapper>
          {itemsToRender.map(item => {
            const isSelected = currentSelected!.to === item.to;
            const icon = getImage(icons[item.icon]);
            const { notifications } = itemsData?.[item.id];
            return (
              <Link key={item.id} to={`/app/${item.to}/${item.defaultTab}`} style={{ textDecoration: 'none' }}>
                <MenuItem onClick={() => handleSelect()} selected={isSelected}>
                  {Number(notifications) > 0 && <MenuItemNotification>{notifications}</MenuItemNotification>}
                  <Icon image={icon!} objectFit="cover" objectPosition="50% 50%" alt="Category Image" selected={isSelected} />
                  <Name>{item.name}</Name>
                </MenuItem>
              </Link>
            );
          })}
        </MenuItemWrapper>
      </SideBarMenu>
      {!mobile && (
        <BusinessUserContainer>
          <Divider />
          <BusinessUserContent />
        </BusinessUserContainer>
      )}
    </SideBarContainer>
  );
}
