import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import Alert from '../../../components/Alert/Alert';
import Modal from '../../../components/Modal/Modal';
import { FormLabel } from '../../../components/Shared/Forms/Forms';
import useIcons from '../../../hooks/useIcons';
import { UsePhotoUploadReturnType, useDeletePhotosReturnType } from '../../../hooks/usePhotoUpload';
import { AddPhotoByLink } from '../../Store/AddPhotoByLink';
import PhotoLink from '../../Store/PhotoLink';
import { AddImageButton, AddImageContainer, AddedImage, FlexColumnCenterBetweenContainer, PhotosContainer, RemoveAddedImage } from '../../Store/styled';

type NotePhotosProps = {
  uploadPhotos: UsePhotoUploadReturnType;
  deletePhotos: useDeletePhotosReturnType;
  noteIdRef: React.MutableRefObject<string>;
  selectedNote: Record<string, any> | undefined;
};

const NotePhotos = forwardRef(({ uploadPhotos, deletePhotos, noteIdRef, selectedNote }: NotePhotosProps, ref) => {
  const [uploadedPhotos, setUploadedPhotos] = useState<File[]>([]);
  const [uploadedAttachments, setUploadedAttachments] = useState<File[]>([]);
  const [uploadedLinks, setUploadedLinks] = useState((selectedNote?.body?.photos?.primary as string[]) || []);
  const [uploadedAttachmentsLinks, setUploadedAttachmentsLinks] = useState((selectedNote?.body?.attachments?.primary as string[]) || []);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const attachmentInputRef = useRef<HTMLInputElement>(null);
  const LinkModalRef = useRef(null);
  const icons = useIcons();
  const [handlePhotosUpload, _, { checkPhotosSize }] = uploadPhotos;
  const [handleDeletePhotos, { setPhotosDeleteQueue }] = deletePhotos;

  useImperativeHandle(ref, () => ({
    getPhotos: async () => {
      const url = `images/reports/primary/${noteIdRef.current}`;
      await handleDeletePhotos(url);
      const photos = await handlePhotosUpload(url, uploadedPhotos, { indexId: false, resizeImages: true });
      return [...(photos || []), ...(uploadedLinks || [])];
    },
    getAttachments: async () => {
      const url = `attachments/reports/primary/${noteIdRef.current}`;
      await handleDeletePhotos(url);
      const attachments = await handlePhotosUpload(url, uploadedAttachments, { indexId: true });
      return [...(attachments || []), ...(uploadedAttachmentsLinks || [])];
    }
  }));

  const handleRemovePhoto = async (removedPhoto: any) => {
    setUploadedPhotos(uploadedPhotos.filter(photo => photo !== removedPhoto));
    setUploadedLinks(uploadedLinks.filter(link => link !== removedPhoto));
    if (typeof removedPhoto === 'string') {
      setPhotosDeleteQueue(photosDeleteQueue => [...photosDeleteQueue, removedPhoto]);
    }
  };

  const handlePhotoSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPhotos = [...uploadedPhotos, ...(e.target.files || [])];
    if ([...newPhotos, ...uploadedLinks].length > 8) {
      Alert.alert({
        title: 'Maximum number of photos reached',
        acceptButtonText: 'Ok',
        description: 'You can only upload up to 8 photos',
        options: {
          hideDenyButton: true
        }
      });
      return;
    }
    if (e.target.files?.length && checkPhotosSize(e)) {
      setUploadedPhotos([...uploadedPhotos, ...e.target.files]);
    }
  };

  const handleRemoveAttachment = async (removedAttachment: any) => {
    setUploadedAttachments(uploadedAttachments.filter(attachment => attachment !== removedAttachment));
    setUploadedAttachmentsLinks(uploadedAttachmentsLinks.filter(link => link !== removedAttachment));
    if (typeof removedAttachment === 'string') {
      setPhotosDeleteQueue(photosDeleteQueue => [...photosDeleteQueue, removedAttachment]);
    }
  };

  const handleAttachmentSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length && checkPhotosSize(e)) {
      setUploadedAttachments([...uploadedAttachments, ...e.target.files]);
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div>
        <FormLabel>Photos ({[...(uploadedPhotos || []), ...(uploadedLinks || [])].length} / 8)</FormLabel>
        <PhotosContainer>
          {[...(uploadedPhotos || []), ...(uploadedLinks || [])].map((photo, idx) => {
            return (
              <FlexColumnCenterBetweenContainer key={idx}>
                <AddedImage key={idx} src={typeof photo === 'string' ? photo : URL.createObjectURL(photo)} />
                <RemoveAddedImage src={icons.delete.childImageSharp.gatsbyImageData.images.fallback.src} onClick={() => handleRemovePhoto(photo)} />
              </FlexColumnCenterBetweenContainer>
            );
          })}
          <FlexColumnCenterBetweenContainer>
            <AddImageContainer disabled={[...uploadedPhotos, ...uploadedLinks].length >= 8}>
              <AddImageButton onClick={() => fileInputRef?.current?.click()} src={icons.addPhoto.childImageSharp.gatsbyImageData.images.fallback.src} />
              <Modal
                ref={LinkModalRef}
                title="Image Link"
                ModalBtn={AddPhotoByLink}
                modalContent={() => <PhotoLink setPhotos={setUploadedLinks} photos={uploadedLinks} LinkModalRef={LinkModalRef} />}
              />
            </AddImageContainer>
          </FlexColumnCenterBetweenContainer>
          <input ref={fileInputRef} type={'file'} accept={'image/*'} onChange={handlePhotoSelect} disabled={uploadedPhotos.length >= 8} style={{ display: 'none' }} multiple />
        </PhotosContainer>
      </div>
      <div>
        <FormLabel>Attachments ({[...(uploadedAttachments || []), ...(uploadedAttachmentsLinks || [])].length} / 3)</FormLabel>
        <PhotosContainer>
          {[...(uploadedAttachments || []), ...(uploadedAttachmentsLinks || [])].map((attachment, idx) => {
            return (
              <FlexColumnCenterBetweenContainer key={idx}>
                <AddedImage key={idx} src={icons.pdfPlaceholder.childImageSharp.gatsbyImageData.images.fallback.src} />
                <RemoveAddedImage src={icons.delete.childImageSharp.gatsbyImageData.images.fallback.src} onClick={() => handleRemoveAttachment(attachment)} />
              </FlexColumnCenterBetweenContainer>
            );
          })}
          <FlexColumnCenterBetweenContainer>
            <AddImageContainer disabled={[...uploadedAttachments, ...uploadedAttachmentsLinks].length >= 3}>
              <AddImageButton onClick={() => attachmentInputRef?.current?.click()} src={icons.addPhoto.childImageSharp.gatsbyImageData.images.fallback.src} />
            </AddImageContainer>
          </FlexColumnCenterBetweenContainer>
          <input ref={attachmentInputRef} type={'file'} accept={'application/pdf'} onChange={handleAttachmentSelect} disabled={uploadedAttachments.length >= 3} style={{ display: 'none' }} />
        </PhotosContainer>
      </div>
    </div>
  );
});

export default NotePhotos;
