import React from 'react';
import { sentenceCase } from 'sentence-case';
import { toReadableDate } from '../../../utils/dates';
import { numberWithCommas } from '../../../utils/helpers';
import { RecordBody, RecordDataContainer } from '../../styled';
import { BranchBalanceTransaction } from './types';

const BranchBalanceTransactionRecord = ({ balanceTransaction }: { balanceTransaction: BranchBalanceTransaction }) => {
  return (
    <RecordDataContainer>
      <RecordBody>{sentenceCase(balanceTransaction?.type || '')}</RecordBody>
      <RecordBody marginRight="20">
        {balanceTransaction?.currency?.toUpperCase?.()} {numberWithCommas(Number(Number(balanceTransaction?.amount || 0) / 100).toFixed(2))}
      </RecordBody>
      <RecordBody marginRight="20">
        {balanceTransaction?.currency?.toUpperCase?.()} {numberWithCommas(Number(Number(balanceTransaction?.fee || 0) / 100).toFixed(2))}
      </RecordBody>
      <RecordBody marginRight="20">
        {balanceTransaction?.currency?.toUpperCase?.()} {numberWithCommas(Number(Number(balanceTransaction?.net || 0) / 100).toFixed(2))}
      </RecordBody>
      <RecordBody fontWeight={'800'}>{balanceTransaction?.description}</RecordBody>
      <RecordBody fontWeight={'800'} flexEnd>
        {toReadableDate((balanceTransaction?.created || 0) * 1000, {
          noTime: true,
          isLocale: true
        })}
      </RecordBody>
    </RecordDataContainer>
  );
};

export default BranchBalanceTransactionRecord;
