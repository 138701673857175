import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import debounce from '../../../utils/debounce';
import { SliderContainer, SliderButton } from './styled';

const Slider = forwardRef(({ length, defaultLength, onScrollEnd }: { length: number; defaultLength: number; onScrollEnd: (value: number) => void }, ref) => {
  const [currentValue, setCurrentValue] = useState(defaultLength);

  const handleSetCurrentValue = (value: number) => {
    setCurrentValue(value);

    debounce(() => onScrollEnd(value), 300);
  };

  useImperativeHandle(ref, () => ({
    setSliderValue: setCurrentValue
  }));

  useEffect(() => {
    setCurrentValue(defaultLength);
  }, [defaultLength]);

  return (
    <SliderContainer>
      <input type="range" min={1} max={length} value={currentValue} onChange={e => handleSetCurrentValue(parseInt(e.target.value))} />

      {Array.from({ length: length }, (_, index) => {
        const current = index + 1;
        const isSelected = currentValue === current;
        return (
          <SliderButton key={current} onClick={() => handleSetCurrentValue(current)} selected={isSelected}>
            {current}
          </SliderButton>
        );
      })}
    </SliderContainer>
  );
});

export default Slider;
