import { useState } from 'react';

const useBulkSelect = ({ items }: { items?: { id: string }[] } = { items: [] }) => {
  const [show, setShow] = useState<boolean>(false);
  const [selected, setSelected] = useState<string[]>([]);
  const [selectedAll, setSelectedAll] = useState<boolean>(false);

  const toggleShow = () => {
    setShow(!show);
    if (show) {
      setSelectedAll(false);
      setSelected([]);
    }
  };

  const toggleSelectAll = (newSelected: string[]) => {
    if (newSelected.length === items?.length && newSelected.every(item => items?.map(item => item.id).includes(item))) {
      setSelectedAll(true);
    } else {
      setSelectedAll(false);
    }
  };

  const toggleSelect = (id: string) => {
    let newSelected = [];
    if (selected.includes(id)) {
      newSelected = selected.filter(item => item !== id);
    } else {
      newSelected = [...selected, id];
    }
    setSelected(newSelected);
    toggleSelectAll(newSelected);
  };

  const toggleSelectMultiple = (ids: string[]) => {
    const newSelected = selected.filter(item => !ids.includes(item));
    const newIds = ids.filter(id => !selected.includes(id));
    const newSelectedIds = [...newSelected, ...newIds];
    setSelected(newSelectedIds);
    toggleSelectAll(newSelectedIds);
  };

  const selectAllItems = () => {
    const ids = (items || []).map(item => item.id);
    setSelectedAll(true);
    setSelected(ids);
  };

  const deselectAllItems = () => {
    setSelected([]);
    setSelectedAll(false);
  };

  const toggleSelectAllItems = () => {
    if (selectedAll) {
      deselectAllItems();
    } else {
      selectAllItems();
    }
  };

  const clearSelected = () => {
    setSelected([]);
    setSelectedAll(false);
  };

  const isSelected = (id: string) => selected.includes(id);

  const hide = () => setShow(false);

  return {
    show,
    selected,
    selectedAll,
    toggleShow,
    hide,
    toggleSelect,
    toggleSelectAllItems,
    clearSelected,
    toggleSelectMultiple,
    isSelected
  };
};

export default useBulkSelect;
