import React from 'react';
import styled from 'styled-components';

const NotificationBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  padding: 16px;
  justify-content: center;
  gap: 8px;
  height: 90px;
  background-color: #000000;
  width: 100%;
  border-radius: 25px;
  backdrop-filter: blur(10px);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

const Title = styled.span`
  font-size: 16px;
  font-weight: bold;
  color: #fff;
`;

const Message = styled.span`
  font-size: 14px;
  color: #fff;
`;

const NotificationBox = ({ title, message, onClick }: { title: string; message?: string; onClick?: () => void }) => {
  return (
    <NotificationBoxContainer onClick={onClick}>
      <Title>{title}</Title>
      <Message>{message}</Message>
    </NotificationBoxContainer>
  );
};

export default NotificationBox;
