import { useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { DRAWER_IDS } from '../../../components/DrawerBar/types';
import InfiniteList from '../../../components/Shared/InfiniteList/InfiniteList';
import useNavigateDrawer from '../../../hooks/useNavigateDrawer';
import usePaginatedQuery from '../../../hooks/usePaginatedQuery';
import useSetDrawerOnNavigation from '../../../hooks/useSetDrawerOnNavigation';
import { GetBranchAppUserTags, GetListBranchAppUsers } from '../../../queries';
import { BranchAppUser } from '../../Store/BranchBilling/types';
import { BranchAppUserTag } from '../../Store/BranchTags/types';
import Common from '../../Store/Common';
import { RecordsContainer } from '../../styled';
import BranchAppUserRecord from './BranchAppUserRecord';

const BranchAppUsers = () => {
  const [[hasMoreItems, setHasMoreItems], queryResult] = usePaginatedQuery<BranchAppUser[]>({
    query: GetListBranchAppUsers
  });

  const { data: { getBranchAppUsers: branchAppUsers = [] } = {}, loading, fetchMore, refetch } = queryResult;

  useSetDrawerOnNavigation({
    itemList: branchAppUsers,
    drawerId: DRAWER_IDS.USER_DRAWER,
    otherParams: { isExtended: true }
  });

  const navigateDrawer = useNavigateDrawer({
    drawerData: {
      drawerId: DRAWER_IDS.USER_DRAWER,
      otherParams: { isExtended: true }
    }
  });

  useEffect(() => {
    Common.set(`BranchAppUsers.GetBranchAppUsers.refetch`, () => {
      refetch?.().then(() => setHasMoreItems(true));
    });
  }, []);

  const { data: { getBranchAppUserTags: allTags = [] } = {} } = useQuery<{
    getBranchAppUserTags: BranchAppUserTag[];
  }>(GetBranchAppUserTags, {
    fetchPolicy: 'cache-and-network',
    variables: { offset: 0, limit: 1000 }
  });

  const quickTags = allTags?.filter(tag => tag?.quick_action) || [];
  const tagsVisibleOnItem = allTags?.filter(tag => tag?.visibility?.showOnTaggedItem) || [];

  return (
    <RecordsContainer>
      <InfiniteList
        list={branchAppUsers}
        itemRenderer={(appUser: BranchAppUser) => (
          <BranchAppUserRecord key={appUser.id} branchAppUser={appUser} navigateDrawer={navigateDrawer} appUserQuickTags={quickTags} tagsVisibleOnItem={tagsVisibleOnItem} />
        )}
        hasMoreItems={hasMoreItems}
        loading={loading}
        fetchMore={fetchMore}
        offset={branchAppUsers?.length}
        setHasMoreItems={setHasMoreItems}
      />
    </RecordsContainer>
  );
};

export default BranchAppUsers;
