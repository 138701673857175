import { useLocation } from '@reach/router';
import { navigate } from 'gatsby-link';
import React, { useEffect, useState } from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Helmet } from 'react-helmet';
import 'react-swipeable-list/dist/styles.css';
import Alert, { AlertElement } from '../components/Alert/Alert';
import DrawerBar from '../components/DrawerBar/DrawerBar';
import Main from '../components/Main/Main';
import ModalDialog, { ModalDialogElement } from '../components/Modal/ModalDialog';
import { CenteredLoader } from '../components/Shared/Spinner';
import SideBar from '../components/SideBar/SideBar';
import { useWindowSize } from '../hooks/useWindowResize';
import { AppContainer, AppContentContainer } from '../styled';
import AppStorage from '../utils/AppStorage';

const App = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const willRedirect = migrateToNewDomain(location);
    if (willRedirect) {
      return;
    }
    const tokens = AppStorage.get('tokens');
    AppStorage.remove('providerBranchId');
    if (!tokens) {
      navigate('/login');
    } else {
      const currentLocation = location.pathname?.split('/');
      if (currentLocation[1] === 'app' && currentLocation.length <= 3) {
        navigate('/app/calendar', { replace: true });
      }
      setLoading(false);
    }
  }, []);
  const windowSize = useWindowSize();

  return (
    <AppContainer windowHeight={windowSize.height}>
      <Helmet>
        <meta charSet="utf-8" />
        <meta httpEquiv="refresh" content="86400" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="../favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="../favicon-16x16.png" />
        <link rel="manifest" href="../site.webmanifest"></link>
        <link rel="mask-icon" href="../apple-touch-icon.png" color="#5bbad5"></link>
        <meta name="msapplication-TileColor" content="#00aba9"></meta>
        <meta name="theme-color" content="#ffffff"></meta>
        <title>Collar Platform</title>
      </Helmet>
      {loading ? (
        <CenteredLoader />
      ) : (
        <>
          <SideBar />
          <AppContentContainer>
            <Main />
            <DrawerBar />
            <ModalDialogElement
              ref={modalDialogRef => {
                ModalDialog.setContainer(modalDialogRef);
              }}
            />
            <AlertElement
              ref={alertRef => {
                Alert.setContainer(alertRef);
              }}
            />
          </AppContentContainer>
        </>
      )}
    </AppContainer>
  );
};

export default App;

function migrateToNewDomain(location) {
  const currentHost = location.hostname;
  if (currentHost === 'platform.collarapp.uk') {
    navigate('https://platform.collar.pet' + location.pathname, { replace: true });
    return true;
  }
  return false;
}
