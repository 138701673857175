import styled, { css } from 'styled-components';
import Colors from '../../../Colors';
import { FadeInRight } from '../Animations';

export const SelectedFiltersContainer = styled.div`
  padding-left: 32px;
  padding-right: 20px;
  padding-top: 20px;
  margin-bottom: 20px;
  display: flex;
`;

export const SelectedFiltersWrapper = styled.div`
  display: flex;
  overflow-x: scroll;
  border-radius: 15px;
  background-color: #fff;
  width: 100%;
  align-items: center;
  padding: 8px 20px;
  padding-bottom: 4px;
`;

export const SelectedFilters = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const FiltersContainer = styled.div`
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  gap: 12px;
  align-items: center;
  min-height: 50px;
`;

export const HorizontalSeparator = styled.div<{ dashed?: boolean }>`
  width: 0.5px;
  height: 17px;
  margin: 0 6px;
  background-color: ${Colors.primary};
  ${({ dashed }) =>
    dashed &&
    css`
      background: transparent;
      background-image: linear-gradient(grey 33%, rgba(255, 255, 255, 0) 0%);
      background-position: right;
      background-size: 1px 10px;
      background-repeat: repeat-y;
      opacity: 0.5;
      height: 68px;
      width: 1px;
    `}
`;

export const RequisiteLabel = styled.span`
  font-size: 10px;
  font-weight: 800;
  color: ${Colors.warmGrey};
  opacity: 0.8;
`;

export const SelectedFilter = styled.label<{ outlined: boolean; activeMenu?: boolean }>`
  user-select: none;
  align-items: center;
  padding: 0 3px 0 0;
  display: flex;

  span {
    white-space: nowrap;
    padding: 6px 16px 6px 14px;
    user-select: none;
    border-radius: 16px;
    font-size: 12px;
    cursor: pointer;
    outline: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    background-color: #ededed;
    color: #212121;
  }

  ${props =>
    props.activeMenu &&
    css`
      span {
        background-color: ${Colors.primary};
        color: ${Colors.white};
      }
    `}

  input {
    display: none;
  }

  ${props =>
    props.outlined &&
    css`
      span {
        background-color: ${Colors.white};
        color: ${Colors.primary};
      }
      background-color: transparent;
      border: 1px solid ${Colors.primary};
      border-radius: 16px;
    `}
`;

export const CloseButton = styled.button<{ bgImg?: string; small?: boolean }>`
  ${props =>
    !props.small &&
    css`
      width: 30px;
      height: 30px;
      ${props.bgImg &&
      css`
        background-color: #e9e9e9;
      `}
    `}

  ${props =>
    props.small &&
    css`
      width: 20px;
      height: 20px;
    `}

  border-radius: 50%;
  background-image: url(${props => props.bgImg});
  cursor: pointer;
  outline: none;
  border: none;
  margin-left: auto;
  background-color: transparent;
`;

export const SearchBarContainer = styled.div`
  margin: 0 10px 0 0;
  display: flex;
  gap: 12px;
  align-items: center;
  @media (max-width: 768px) {
    margin: 9px 16px;
  }
`;

export const SearchBarWrapper = styled.div<{ show: boolean }>`
  box-sizing: border-box;
  position: relative;
  animation: ${props => (props.show ? FadeInRight : '')} 0.3s ease-in-out;
  ${props =>
    props.show &&
    css`
      width: 220px;
      visibility: visible;
      opacity: 1;
    `}
  ${props =>
    !props.show &&
    css`
      width: 0px;
      visibility: hidden;
      opacity: 0;
    `}

  @media (max-width: 768px) {
    width: 100%;
  }

  transition: all 0.1s ease-in-out;
`;

export const TagsQuickFiltersWrapper = styled.div`
  display: flex;
  gap: 12px;
  overflow: hidden;
  width: 100%;
  @media (max-width: 768px) {
    gap: 6px;
  }
`;

export const PetTagsQuickFilterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 768px) {
    gap: 6px;
  }
`;

export const TagsOptionsContainer = styled.div`
  margin-left: auto;
`;

export const PetTagFilterPill = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  font-size: 12px;
  cursor: pointer;
  outline: none;
  box-sizing: border-box;
  span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    white-space: nowrap;
    user-select: none;
    border-radius: 16px;
    font-size: 12px;
    cursor: pointer;
    outline: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 800;
    background-color: #878787;
    color: #fff;
    padding: 6px 12px;
  }

  input {
    display: none;

    &:checked + span {
      background-color: ${Colors.primary};
      color: ${Colors.white};

      &:hover {
        background-color: ${Colors.primary};
        color: ${Colors.white};
      }
    }
  }

  @media (max-width: 768px) {
    span {
      padding: 8px 10px;
      background-color: #e6e6e6;
      font-weight: 700;
      color: ${Colors.black};
      font-size: 13px;
    }

    input {
      &:checked + span {
        background-color: #3f3f3f;
        color: ${Colors.white};

        &:hover {
          background-color: #3f3f3f;
          color: ${Colors.white};
        }
      }
    }
  }
`;

export const AddPetTagButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  font-size: 12px;
  cursor: pointer;
  outline: none;
  border: none;
  background-color: transparent;
  border: 1px dashed #7c7c7c;
  box-sizing: border-box;
  opacity: 0.55;
  span {
    white-space: nowrap;
    user-select: none;
    border-radius: 16px;
    font-size: 12px;
    cursor: pointer;
    outline: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 800;
    color: #7c7c7c;
    padding: 6px 24px;
  }
`;
