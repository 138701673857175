import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import React, { FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ReactSelect from 'react-select';
import { GetAllServices, GetBusUserProfile, GetUpdateAppUsersOrdersAmountFromProductsSummary, UpdateAppUsersOrdersAmountFromProducts } from '../../queries';
import debounce from '../../utils/debounce';
import { isValidAmount } from '../../utils/validators';
import { Divider } from '../../views/Pets/Health/styled';
import { Product } from '../../views/Store/types';
import ModalDialog from '../Modal/ModalDialog';
import { ModalBody, ModalFooter } from '../Modal/styled';
import { BusUserProfile } from '../Profile/types';
import { FormButtonsContainer, FormError, FormInput, FormLabel, FormRadio, FormSubmitButton, InputGroup, InputsWrapper, WideInputGroup, selectTheme } from '../Shared/Forms/Forms';
import AppUsersList from '../Shared/Lists/AppUsersList';
import { Container } from '../Shared/Shared';

type AdjustAppUserOrdersPaymentFormValues = {
  selectedAppUsers: string[];
  selectedProducts: string[];
  added_amounts: { [key: string]: number };
  amountType: 'addition' | 'fixed';
  timestamp_from: string;
  timestamp_to: string;
  reason: string;
};

type AdjustAppUserOrdersPaymentModalProps = {
  initialValues?: Partial<AdjustAppUserOrdersPaymentFormValues>;
};

const getVariables = (form: Partial<AdjustAppUserOrdersPaymentFormValues>) => {
  const { selectedAppUsers, selectedProducts, added_amounts, timestamp_from, timestamp_to, reason, amountType } = form;

  const variables = {
    AppUsersOrdersAmountFromProducts: [...new Set(selectedAppUsers)]?.map(appUserId => ({
      AppUserId: appUserId,
      Products: selectedProducts?.map(productId => ({
        ProductId: productId,
        [amountType === 'fixed' ? 'amount' : 'added_amount']: added_amounts?.[productId]
      }))
    })),
    timestamp_from: timestamp_from ? new Date(timestamp_from).toISOString() : null,
    timestamp_to: timestamp_to ? new Date(timestamp_to).toISOString() : null,
    reason
  };
  return variables;
};

const validateForm = (values: Partial<AdjustAppUserOrdersPaymentFormValues>) => {
  const { selectedAppUsers, selectedProducts, added_amounts, timestamp_from, timestamp_to } = values;
  return !!selectedAppUsers?.length && !!selectedProducts?.length && !!timestamp_from && !!timestamp_to && !!added_amounts && !!Object.keys(added_amounts || {})?.length;
};

const AdjustAppUserOrdersPaymentModal: FC<AdjustAppUserOrdersPaymentModalProps> = ({ initialValues }) => {
  const defaultValues: AdjustAppUserOrdersPaymentFormValues = {
    selectedAppUsers: initialValues?.selectedAppUsers || [],
    selectedProducts: initialValues?.selectedProducts || [],
    added_amounts: initialValues?.added_amounts || {},
    amountType: initialValues?.amountType || 'addition',
    reason: initialValues?.reason || '',
    timestamp_from: initialValues?.timestamp_from || '',
    timestamp_to: initialValues?.timestamp_to || ''
  };

  const { data: { getBusUserProfile: userProfile } = {} } = useQuery<{ getBusUserProfile: BusUserProfile }>(GetBusUserProfile, {
    fetchPolicy: 'cache-and-network'
  });

  const allBranchBillingDates = userProfile?.Branch?.allBranchBillingDates || [];

  const currentBranchCycle = userProfile?.Branch?.branchBillingCycle || allBranchBillingDates[0] || {};

  const formOptions = useForm<AdjustAppUserOrdersPaymentFormValues>({ defaultValues });

  const [updateAppUsersOrdersAmountFromProducts, { data: dataSubmit, loading: loadingSubmit, error: errorSubmit }] = useMutation(UpdateAppUsersOrdersAmountFromProducts);

  const [getUpdateAppUsersOrdersAmountFromProductsSummary, { data }] = useLazyQuery<{
    getUpdateAppUsersOrdersAmountFromProductsSummary?: { ordersToUpdate: number; appUsersToUpdate: number };
  }>(GetUpdateAppUsersOrdersAmountFromProductsSummary, {
    fetchPolicy: 'network-only'
  });

  const summary = data?.getUpdateAppUsersOrdersAmountFromProductsSummary;

  const { control, handleSubmit, errors, watch } = formOptions;

  const { data: { getProducts: products = [] } = {} } = useQuery<{ getProducts: Product[] }>(GetAllServices, {
    fetchPolicy: 'cache-and-network'
  });

  const watchedValues = watch(['selectedProducts', 'selectedAppUsers', 'added_amounts', 'timestamp_from', 'timestamp_to', 'amountType']);

  const onSubmit = handleSubmit(form => {
    if (!validateForm(form)) {
      return;
    }

    const variables = getVariables(form);

    updateAppUsersOrdersAmountFromProducts({ variables });
  });

  useEffect(() => {
    if (dataSubmit?.updateAppUsersOrdersAmountFromProducts) {
      ModalDialog.closeModal();
    }
  }, [dataSubmit?.updateAppUsersOrdersAmountFromProducts]);

  useEffect(() => {
    debounce(() => {
      if (!validateForm(watchedValues)) {
        return;
      }
      const variables = getVariables(watchedValues);
      getUpdateAppUsersOrdersAmountFromProductsSummary({ variables });
    }, 500);
  }, [watchedValues?.selectedAppUsers, watchedValues?.selectedProducts, watchedValues?.timestamp_from, watchedValues?.timestamp_to, JSON.stringify(watchedValues?.added_amounts || {})]);

  const timestampFromMin = new Date(currentBranchCycle?.billing_date_from || new Date()).toISOString().split('T')[0];
  const timestampToMin = watchedValues?.timestamp_from || timestampFromMin;

  return (
    <>
      <ModalBody>
        <Container width={600}>
          <InputsWrapper>
            <WideInputGroup>
              <FormLabel>Clients</FormLabel>
              <AppUsersList isMulti formOptions={formOptions} name="selectedAppUsers" defaultValues={defaultValues?.selectedAppUsers} />
            </WideInputGroup>
            <Divider />
            <WideInputGroup marginTop={20}>
              <FormLabel>Services</FormLabel>
              <Controller
                render={({ onChange, value }) => (
                  <ReactSelect
                    isMulti
                    theme={selectTheme}
                    name={'selectedProducts'}
                    onChange={newValue => {
                      if (!newValue?.length) {
                        onChange(undefined);
                        return;
                      }
                      onChange(newValue?.map(item => item?.id));
                    }}
                    value={products?.filter(product => value?.includes(product?.id))}
                    options={products}
                    getOptionLabel={option => option?.name}
                    getOptionValue={option => option?.id}
                  />
                )}
                control={control}
                name={'selectedProducts'}
                defaultValue={[]}
                rules={{ required: false }}
              />
            </WideInputGroup>
            <WideInputGroup marginTop={20}>
              <FormLabel>Amount Type</FormLabel>
              <Controller
                render={({ onChange, value }) => (
                  <FormRadio
                    onChange={onChange}
                    value={value}
                    itemsArray={[
                      { name: 'Add', id: 'addition' },
                      { name: 'Fixed', id: 'fixed' }
                    ]}
                    fontSize={16}
                  />
                )}
                control={control}
                name={'amountType'}
                defaultValue={'addition'}
                rules={{ required: false }}
              />
            </WideInputGroup>
            {watchedValues?.selectedProducts?.map(productId => {
              const product = products?.find(product => product?.id === productId);
              return (
                <WideInputGroup key={productId}>
                  <FormLabel error={errors.added_amounts?.[productId]?.message}>
                    {product?.name} {watchedValues?.amountType === 'addition' ? 'adjust' : 'fixed'} amount
                  </FormLabel>
                  <Controller
                    render={({ onChange, value }) => (
                      <FormInput
                        error={errors.added_amounts?.[productId]?.message}
                        type={'number'}
                        height={32}
                        fontSize={16}
                        onChange={e => {
                          onChange(e.target.value);
                        }}
                        value={value}
                        min={0}
                      />
                    )}
                    control={control}
                    name={`added_amounts.${productId}`}
                    defaultValue={defaultValues?.added_amounts?.[productId] || ''}
                    rules={{
                      required: true,
                      pattern: {
                        value: isValidAmount,
                        message: 'Invalid Amount'
                      },
                      min: {
                        value: 0,
                        message: 'Amount must be greater or equal to 0'
                      }
                    }}
                  />
                  {errors.added_amounts?.[productId] && <FormError>{errors.added_amounts?.[productId]?.message || 'New Amount is required'}</FormError>}
                </WideInputGroup>
              );
            })}
            <Divider />
            <WideInputGroup marginTop={20} flexDirection="row" gap={10}>
              <InputGroup>
                <FormLabel>From</FormLabel>
                <Controller
                  as={<FormInput type={'date'} height={32} fontSize={16} min={timestampFromMin} />}
                  control={control}
                  name={'timestamp_from'}
                  defaultValue={''}
                  rules={{ required: true, min: timestampFromMin }}
                />
                {errors.timestamp_from && <FormError>{errors.timestamp_from.message || 'From is required'}</FormError>}
              </InputGroup>
              <InputGroup>
                <FormLabel>To</FormLabel>
                <Controller
                  as={<FormInput type={'date'} height={32} fontSize={16} min={timestampToMin} disabled={!watchedValues?.timestamp_from} />}
                  control={control}
                  name={'timestamp_to'}
                  defaultValue={''}
                  rules={{ required: true, min: timestampToMin, validate: value => value >= watchedValues?.timestamp_from }}
                />
                {errors.timestamp_to && <FormError>{errors.timestamp_to.message || 'To is required'}</FormError>}
              </InputGroup>
            </WideInputGroup>
            <WideInputGroup>
              <FormLabel>Notes</FormLabel>
              <Controller
                as={<FormInput type={'text'} height={32} fontSize={16} />}
                control={control}
                name={'reason'}
                defaultValue={''}
                rules={{
                  required: true
                }}
              />
              {errors.reason && <FormError>{errors.reason.message || 'Reason is required'}</FormError>}
            </WideInputGroup>
          </InputsWrapper>
          {summary && (
            <WideInputGroup>
              <FormLabel>Orders to update: {summary?.ordersToUpdate}</FormLabel>
              <FormLabel>Clients to update: {summary?.appUsersToUpdate}</FormLabel>
            </WideInputGroup>
          )}
        </Container>
      </ModalBody>
      <ModalFooter>
        <FormButtonsContainer>
          <FormSubmitButton error={!!errorSubmit} loading={loadingSubmit} onClick={() => onSubmit()}>
            Confirm Adjustment
          </FormSubmitButton>
        </FormButtonsContainer>
      </ModalFooter>
    </>
  );
};

export default AdjustAppUserOrdersPaymentModal;
