import { CalendarEvent } from '../../views/Bookings/types';
import { BranchAppointmentTag } from '../../views/Store/BranchTags/types';

export const getMultiDayBookingsForDay = ({ multiDayBookings, weekDay, firstDayOfView }: { multiDayBookings: CalendarEvent[]; weekDay: Date; firstDayOfView: Date }) => {
  return multiDayBookings.filter(booking => {
    const timestamp = new Date(booking.timestamp);
    timestamp.setHours(weekDay.getHours());
    timestamp.setMinutes(weekDay.getMinutes());
    return timestamp.getTime() === weekDay.getTime() || (timestamp.getTime() < firstDayOfView.getTime() && weekDay.getDate() === firstDayOfView.getDate());
  });
};

export const groupMultiDayBookingsByTags = ({ filterByTagsIds, multiDayBookings }: { multiDayBookings: CalendarEvent[]; filterByTagsIds: string[] }) => {
  const multiDayAppointmentsForTagsToRender = filterByTagsIds?.reduce<{ [key: string]: string[] }>((acc, tagId) => {
    const appointmentsThatHaveTag = multiDayBookings.filter(booking => {
      const bookingTags = booking.branchAppointmentTagsCount?.map(tagCount => tagCount.BranchAppointmentTag?.id);
      return bookingTags?.includes(tagId);
    });
    return { ...acc, [tagId]: appointmentsThatHaveTag.map(booking => booking.appointmentsIds[0]) };
  }, {});

  return multiDayAppointmentsForTagsToRender;
};

export const getNumberOfRowsForTags = ({
  multiDayAppointmentsForTagsToRender,
  filterByTags
}: {
  multiDayAppointmentsForTagsToRender: { [key: string]: string[] };
  filterByTags?: BranchAppointmentTag[];
}) => {
  const filterByTagsIds = (filterByTags || [])?.map(tag => tag.id);
  const rows = filterByTagsIds.map(tagId => {
    const bookingsIds = multiDayAppointmentsForTagsToRender?.[tagId];

    const currentTagCapacity = filterByTags?.find(tag => tag.id === tagId)?.capacity;
    const maxTagRows = Math.max(currentTagCapacity || 0, bookingsIds?.length || 0);
    const currentTagRows = maxTagRows === 0 ? 1 : maxTagRows;
    const numberOfAppointmentsWithinTimePeriod = bookingsIds?.length || 0;
    return { tagId, currentTagRows: currentTagRows, numberOfAppointmentsWithinTimePeriod };
  });
  return rows;
};
