import React, { useRef, useState } from 'react';
import { useQuery } from '@apollo/client';
import TrainingHistory from './TrainingHistory';
import TrainingDetails from './TrainingDetails';
import { GetBranchPetProfile, GetTrainingReportsByPetRecordId } from '../../../../queries';
import { vars } from '../../../../reactive';
import { DRAWER_IDS } from '../../../../components/DrawerBar/types';
import usePaginatedQuery from '../../../../hooks/usePaginatedQuery';
import useMediaQuery from '../../../../hooks/useMediaQuery';

const Training = () => {
  const drawerBar = vars.drawerBars().find(drawer => drawer.drawerId === DRAWER_IDS.PETS_DRAWER);

  const petId = drawerBar?.recordData;

  const autoOpenModal = drawerBar?.otherData?.autoOpenModal;
  const preSelectedReprotId = drawerBar?.otherData?.selectedReportId;

  const trainingDetailsRef = useRef(null);

  const { data: { getBranchPetProfile: petProfile = {} } = {} } = useQuery(GetBranchPetProfile, {
    variables: {
      id: petId
    }
  });

  const [[hasMoreItems, setHasMoreItems], { data: { getTrainingReportsByPetRecordId: trainingReports = [] } = {}, loading: loadingTrainingReports, fetchMore, refetch: refetchTrainingReports }] =
    usePaginatedQuery<any>({
      query: GetTrainingReportsByPetRecordId,
      limit: 6,
      otherVariables: { petRecordId: petProfile?.PetRecord?.id },
      otherParams: {
        skip: !petProfile?.id
      }
    });

  const { tablet } = useMediaQuery({ tablet: true });

  const [selectedReportId, setSelectedReportId] = useState<string | null>(preSelectedReprotId || (!tablet ? trainingReports[0]?.id : null));

  const setSelectedReportIdAndScroll = (noteId: string) => {
    setSelectedReportId(noteId);
    trainingDetailsRef.current?.scrollIntoView({
      behavior: 'smooth'
    });
  };

  const selectedReport = trainingReports.find((note: any) => note.id === selectedReportId);

  return (
    <>
      <TrainingHistory
        fetchMore={fetchMore}
        hasMoreItems={hasMoreItems}
        setHasMoreItems={setHasMoreItems}
        trainingReports={trainingReports || []}
        setSelectedReportId={setSelectedReportIdAndScroll}
        loading={loadingTrainingReports}
        selectedReportId={selectedReportId as string}
        refetchTrainingReports={refetchTrainingReports}
        autoOpenModal={autoOpenModal}
        onModalClose={drawerBar?.otherData?.onClose}
      />
      <TrainingDetails
        innerRef={trainingDetailsRef}
        selectedReport={selectedReport}
        trainingReports={trainingReports}
        deselectReport={() => setSelectedReportId(null)}
        loading={loadingTrainingReports}
      />
    </>
  );
};

export default Training;
