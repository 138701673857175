import React from 'react';
import { Controller, UseFormMethods } from 'react-hook-form';
import { FormError, FormInputsContainer, FormLabel, FormSelect, WideInputGroup } from './Forms/Forms';

const DurationSelector = ({
  defaultDuration,
  formOptions,
  options
}: {
  defaultDuration: number;
  formOptions: UseFormMethods<any>;
  options?: { title?: string; includeDays?: boolean; name?: string; rules?: any };
}) => {
  const { control, errors } = formOptions;
  const defaultDays = defaultDuration ? Math.floor(defaultDuration / 1440) : 0;
  const defaultHours = defaultDuration ? Math.floor((defaultDuration - defaultDays * 1440) / 60) : 0;
  const defaultMinutes = defaultDuration ? defaultDuration - defaultDays * 1440 - defaultHours * 60 : 0;
  const defaultValues = {
    days: defaultDays,
    hours: defaultHours,
    minutes: defaultMinutes
  };
  const name = options?.name || 'duration';
  return (
    <WideInputGroup>
      <FormLabel>{options?.title || 'Service Time (Hours) (Minutes)'}</FormLabel>
      <FormInputsContainer flexDirection="row" gap={10}>
        {options?.includeDays && (
          <Controller
            render={({ onChange, value }) => (
              <FormSelect error={errors?.[name]?.days} height={40} fontSize={16} value={value} onChange={onChange}>
                {[...Array(31).keys()].map(day => (
                  <option key={day} value={day}>
                    {day}
                  </option>
                ))}
              </FormSelect>
            )}
            control={control}
            name={`${name}.days`}
            defaultValue={defaultValues.days}
            rules={options?.rules}
          />
        )}
        <Controller
          render={({ onChange, value }) => (
            <FormSelect error={errors?.[name]?.hours} height={40} fontSize={16} value={value} onChange={onChange}>
              {[...Array(24).keys()].map(hour => (
                <option key={hour} value={hour}>
                  {hour}
                </option>
              ))}
            </FormSelect>
          )}
          control={control}
          name={`${name}.hours`}
          defaultValue={defaultValues.hours}
          rules={options?.rules}
        />
        <Controller
          render={({ onChange, value }) => (
            <FormSelect error={errors?.[name]?.minutes} height={40} fontSize={16} value={value} onChange={onChange}>
              {[...Array(60).keys()].map(minute => (
                <option key={minute} value={minute}>
                  {minute}
                </option>
              ))}
            </FormSelect>
          )}
          control={control}
          name={`${name}.minutes`}
          defaultValue={defaultValues.minutes}
          rules={options?.rules}
        />
      </FormInputsContainer>
      {options?.includeDays && errors?.[name]?.days && <FormError>{errors?.[name]?.days?.message || 'days is required'}</FormError>}
      {errors?.[name]?.hours && <FormError>{errors?.[name]?.hours?.message || 'hours is required'}</FormError>}
      {errors?.[name]?.minutes && <FormError>{errors?.[name]?.minutes?.message || 'minutes is required'}</FormError>}
    </WideInputGroup>
  );
};

export const convertDurationToMinutes = (duration: { days: string; hours: string; minutes: string } | string | null) => {
  if (!duration || typeof duration === 'string') {
    return duration || null;
  }
  const { days, hours, minutes } = duration;
  return String(parseInt(days || '0') * 1440 + parseInt(hours || '0') * 60 + parseInt(minutes));
};

export default DurationSelector;
