import React from 'react';
import { calculateNights, compareDatesWithoutHours, formatMultiDayAppointmentPeriod, getNextDay } from '../../../utils/dates';
import { getBranchCurrencySymbol } from '../../../utils/getBranchCurrencySymbol';
import { CalendarEvent } from '../../../views/Bookings/types';
import { BookingCardContainer, BookingCardLabel, BookingCardTitle, MultiDayBookingCard, MultiDayMoreInfoContainer } from '../styled';

function MultiDayAppointment({
  calendarEvent,
  lastDayOfView,
  firstDayOfView,
  calendarIndex,
  color,
  dayIndex,
  assignedTo,
  handleBookingClick,
  small,
  medium
}: {
  calendarEvent: CalendarEvent;
  lastDayOfView: Date;
  firstDayOfView: Date;
  calendarIndex: number;
  color: string;
  handleBookingClick: ({ appointmentsIds, group }: { appointmentsIds?: string[]; group?: boolean }) => void;
  dayIndex: number;
  assignedTo: string;
  small: boolean;
  medium: boolean;
}) {
  const { appointmentsIds, itemName, orderTotal, petsNames, BusUsers, timestamp: ts, timestamp_until: ts_until } = calendarEvent;
  const bookingTimestamp = new Date(ts);
  const bookingTimestampUntil = new Date(ts_until);
  const firstDayOfViewUTC = new Date(firstDayOfView.getFullYear(), firstDayOfView.getMonth(), firstDayOfView.getDate());
  const lastDayOfViewUTC = new Date(lastDayOfView.getFullYear(), lastDayOfView.getMonth(), lastDayOfView.getDate(), 23, 59, 59);

  const isTimestampBeforeWeek = compareDatesWithoutHours(firstDayOfViewUTC, bookingTimestamp);
  const isTimestampUntilAfterWeek = compareDatesWithoutHours(bookingTimestampUntil, lastDayOfViewUTC);
  const timestamp = isTimestampBeforeWeek ? firstDayOfView : bookingTimestamp;
  const timestamp_until = new Date(isTimestampUntilAfterWeek ? (getNextDay(lastDayOfView) as Date)?.setHours(0, 0, 0, 0) : new Date(bookingTimestampUntil)?.setHours(12, 0, 0, 0));
  const hour_from = timestamp.getHours() || 0;
  const minute_from = timestamp.getMinutes() || 0;
  const duration = (timestamp_until.getTime() - timestamp.getTime()) / (1000 * 3600 * 24);
  const serviceDurationNights = calculateNights(bookingTimestamp, bookingTimestampUntil);
  const isFree = +orderTotal < 0.5;
  const busUserName = BusUsers?.[0]?.name;

  return (
    <BookingCardContainer>
      <MultiDayBookingCard
        index={calendarIndex}
        hour_from={isTimestampBeforeWeek ? 0 : hour_from}
        minute_from={isTimestampBeforeWeek ? 0 : minute_from}
        duration={duration}
        onClick={() => handleBookingClick({ appointmentsIds, group: false })}
        color={color}
        smallSize={small}
        mediumSize={medium}
      >
        <BookingCardLabel fontSize={14} fontWeight={'bold'}>
          {isTimestampBeforeWeek && '<<< '} {petsNames} ({serviceDurationNights} nights)
          {!(small || medium) && itemName}
          {isTimestampUntilAfterWeek && ' >>>'}
        </BookingCardLabel>
        {!(small || medium) && (
          <>
            <BookingCardLabel fontSize={14} fontWeight={'500'}>
              {petsNames}
            </BookingCardLabel>
            <BookingCardLabel fontSize={12} fontWeight={'500'}>
              {formatMultiDayAppointmentPeriod(bookingTimestamp, bookingTimestampUntil)} ({serviceDurationNights} nights)
            </BookingCardLabel>
          </>
        )}
      </MultiDayBookingCard>

      <MultiDayMoreInfoContainer
        isTimestampUntilAfterWeek={isTimestampUntilAfterWeek}
        hour_from={hour_from}
        minute_from={minute_from}
        index={calendarIndex}
        dayIndex={dayIndex}
        color={color}
        duration={duration}
        smallSize={small}
        mediumSize={medium}
      >
        <BookingCardTitle>{itemName}</BookingCardTitle>
        <BookingCardLabel fontSize={18} fontWeight={'600'}>
          {petsNames}
        </BookingCardLabel>
        <BookingCardLabel fontSize={16} fontWeight={'400'}>
          {formatMultiDayAppointmentPeriod(bookingTimestamp, bookingTimestampUntil)} ({serviceDurationNights} nights)
        </BookingCardLabel>
        <BookingCardLabel fontSize={16} fontWeight={'800'}>
          {isFree ? 'Free' : `${getBranchCurrencySymbol()}${orderTotal}`}
        </BookingCardLabel>
        {busUserName && (
          <BookingCardLabel fontSize={16} fontWeight={'800'}>
            Assigned to: {assignedTo}
          </BookingCardLabel>
        )}
      </MultiDayMoreInfoContainer>
    </BookingCardContainer>
  );
}

export default MultiDayAppointment;
