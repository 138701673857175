import React from 'react';
import TabsHeader from '../../components/Shared/TabsHeader';
import { RecordsContainer } from '../styled';
import { GetBranchOrders, GetBranchUnreadItemsCount } from '../../queries';
import { ViewContainer } from '../../components/Shared/Shared';
import useCheckSelectedTabAndRefetch from '../../hooks/useCheckSelectedTabAndRefetch';
import RefreshButton from '../../components/Shared/RefreshButton';
import useSelectedState from '../../hooks/useSelectedState';
import usePaginatedQuery from '../../hooks/usePaginatedQuery';
import useMediaQuery from '../../hooks/useMediaQuery';
import { ORDERS_TABS_TYPES, ORDER_STATUS_TYPES, ProductOrder } from './types';
import OrdersList from './OrdersList';
import { GetBranchUnreadItemsCountReturn } from '../../hooks/useUnreadBranchItemsCount';
import { useQuery } from '@apollo/client';

const Orders = () => {
  const [selected, setSelected] = useSelectedState({ defaultTab: ORDERS_TABS_TYPES.NEW, TABS_TYPES: ORDERS_TABS_TYPES });
  const { mobile } = useMediaQuery({ mobile: true });

  const paginatedQueryResult = usePaginatedQuery<ProductOrder[]>({
    query: GetBranchOrders,
    defaultStatus: ORDER_STATUS_TYPES.NEW,
    otherVariables: { type: 'product' },
    limit: 20
  });

  const [[_hasMoreItems, setHasMoreItems], queryResult] = paginatedQueryResult;

  const tabs = [
    { name: ORDERS_TABS_TYPES.NEW, status: ORDER_STATUS_TYPES.NEW },
    { name: ORDERS_TABS_TYPES.UPCOMING, status: ORDER_STATUS_TYPES.ACTIVE },
    { name: ORDERS_TABS_TYPES.ARCHIVED, status: ORDER_STATUS_TYPES.ARCHIVED }
  ];

  const checkSelectedTabAndRefetch = useCheckSelectedTabAndRefetch<ProductOrder[]>({
    tabs,
    queryResult,
    setHasMoreItems,
    selected
  });

  const actions = <RefreshButton checkSelectedTabAndRefetch={checkSelectedTabAndRefetch} setHasMoreItems={setHasMoreItems} />;

  const headerTabs = Object.values(ORDERS_TABS_TYPES);

  const { data: unreadOrdersCount } = useQuery<{ getBranchUnreadItemsCount: GetBranchUnreadItemsCountReturn }>(GetBranchUnreadItemsCount, {
    fetchPolicy: 'cache-only',
    notifyOnNetworkStatusChange: true
  });

  const { unreadProductOrdersCount } = unreadOrdersCount?.getBranchUnreadItemsCount || {};

  return (
    <ViewContainer>
      <TabsHeader
        tabs={mobile ? headerTabs?.slice(0, -1) : headerTabs}
        actions={actions}
        selected={selected}
        setSelected={setSelected}
        notifications={{
          [ORDERS_TABS_TYPES.NEW]: unreadProductOrdersCount || 0
        }}
      />
      <RecordsContainer>
        <OrdersList paginatedQueryResult={paginatedQueryResult} />
      </RecordsContainer>
    </ViewContainer>
  );
};

export default Orders;
