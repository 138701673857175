import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ModalDialog from '../../../components/Modal/ModalDialog';
import { ModalBody, ModalFooter } from '../../../components/Modal/styled';
import { FormButtonsContainer, FormLabel, FormSubmitButton, FormTextArea, InputsWrapper, WideInputGroup } from '../../../components/Shared/Forms/Forms';
import { Container } from '../../../components/Shared/Shared';
import { EditBranch, GetBusUserProfile } from '../../../queries';
import { WEEK_DAYS_MAP } from '../../../utils/dates';
import { AddNewContainer, Loader } from '../styled';
import transformDeliveryCodesState from '../transformDeliveryState';
import transformOperatingSlotsState from '../transformOperatingSlotsState';
import AutomaticInvoicing from './AutomaticInvoicing';
import Delivery from './Delivery';
import OpeningHours from './OpeningHours';
import OperatingSlots from './OperatingSlots';
import { OperationsForm } from './types';

const Operations = () => {
  const formOptions = useForm<OperationsForm>();
  const { control, errors, handleSubmit } = formOptions;
  const { data: { getBusUserProfile: profile = {} } = {}, loading: profileLoading } = useQuery(GetBusUserProfile);
  const [handleEditBranch, { data: editedBranch, loading, error }] = useMutation(EditBranch);

  const handleFormSubmit = handleSubmit(async form => {
    const booking_slots = transformOperatingSlotsState(form);
    const delivery_rules = transformDeliveryCodesState(form);
    const max_booking_slots = {
      frequency: {
        daily: WEEK_DAYS_MAP.reduce(
          (acc, day) => ({
            ...acc,
            [day]: +form.operation_max_slots[day] || 0
          }),
          {}
        )
      },
      max_slot_offset: profile.Branch?.max_booking_slots?.max_slot_offset || 30000
    };
    const opening_hours = {
      out_of_office: !!form.out_of_office && String(form.out_of_office) === 'true'
    };
    handleEditBranch({
      variables: {
        booking_slots,
        delivery_rules,
        id: profile.Branch.id,
        max_booking_slots,
        opening_hours,
        payment: {
          ...profile.Branch.payment,
          disable_automatic_billing: form.disable_automatic_billing
        },
        additional_info: {
          ...(form.additional_info.invoice_footer ? { invoice_footer: form.additional_info.invoice_footer } : {})
        }
      }
    });
  });

  useEffect(() => {
    if (editedBranch) {
      ModalDialog.closeModal();
    }
  }, [editedBranch]);

  return (
    <>
      <ModalBody>
        <AddNewContainer>
          <Container>
            {profile && !profileLoading && (
              <>
                <InputsWrapper noWrap>
                  <OperatingSlots control={control} errors={errors} operatingTimes={profile.Branch?.booking_slots} defaultMaxBookingSlots={profile.Branch?.max_booking_slots?.frequency?.daily} />
                  <Delivery control={control} errors={errors} defaultValue={profile.Branch?.delivery_rules} />
                </InputsWrapper>
              </>
            )}
            {!loading && !profile.Branch && <span>You are not assigned to a Branch</span>}
            {profileLoading && <Loader />}
            <InputsWrapper noWrap>
              <WideInputGroup>
                <FormLabel>Invoice Footer</FormLabel>
                <Controller
                  as={<FormTextArea error={errors?.additional_info?.invoice_footer} />}
                  control={control}
                  name={'additional_info.invoice_footer'}
                  defaultValue={profile?.Branch?.additional_info?.invoice_footer || ''}
                  rules={{}}
                />
              </WideInputGroup>
              <AutomaticInvoicing control={control} defaultDisableAutomaticInvoicing={profile.Branch?.payment?.disable_automatic_billing} />
              <OpeningHours control={control} defaultOpeningHours={profile.Branch?.opening_hours} />
            </InputsWrapper>
          </Container>
        </AddNewContainer>
      </ModalBody>
      <ModalFooter>
        <FormButtonsContainer>
          <FormSubmitButton error={!!error} loading={loading} onClick={handleFormSubmit}>
            Save Settings
          </FormSubmitButton>
        </FormButtonsContainer>
      </ModalFooter>
    </>
  );
};

export default Operations;
