import React from 'react';
import Colors from '../../Colors';
import useCheckBranchAvailability from '../../hooks/useCheckBranchAvailability';
import { SlotAvailabilityContainer } from '../../views/Bookings/styled';
import { DrawerSubLabel } from '../DrawerBar/styled';
import ModalDialog from '../Modal/ModalDialog';
import SlotAvailabilityModal from './SlotAvailabilityModal';

const SlotAvailability = ({
  index,
  selectedSlotForEachDate,
  options,
  style,
  bookingDates
}: {
  index: number;
  selectedSlotForEachDate: ReturnType<typeof useCheckBranchAvailability>['selectedSlotForEachDate'];
  options?: { includeMainSlot?: boolean; includeEmptySlots?: boolean; emptySlotText?: string; hover?: boolean };
  style?: React.CSSProperties;
  bookingDates?: ReturnType<typeof useCheckBranchAvailability>['bookingDates'];
}) => {
  const { includeMainSlot = true, includeEmptySlots = true, emptySlotText = '', hover = false } = options || {};
  const selectedSlot = selectedSlotForEachDate[index];
  const { mainSlotAvailablity } = selectedSlot || {};
  const count = selectedSlot?.available;
  const atCapacity = selectedSlot?.available! <= 0;
  const moreThanCapacity = selectedSlot?.available! < 0;
  const extraCapacity = Math.abs(selectedSlot?.available!);

  return (
    <>
      {!!selectedSlotForEachDate && !!selectedSlotForEachDate?.length && bookingDates && (
        <SlotAvailabilityContainer
          style={style}
          hover={!!bookingDates?.length && hover}
          onClick={() => {
            if (!bookingDates) {
              return;
            }
            ModalDialog.openModal({
              content: () => <SlotAvailabilityModal bookingDates={bookingDates} selectedSlot={selectedSlotForEachDate[index]} index={index} />,
              title: 'Availability'
            });
          }}
        >
          {selectedSlot && (
            <DrawerSubLabel size={14} weight={700} color={atCapacity || moreThanCapacity ? Colors.orange : Colors.primary}>
              {atCapacity ? `At Capacity ${moreThanCapacity ? `+${extraCapacity}` : ''}` : `Availability: ${count}${includeMainSlot ? ` slots out of ${mainSlotAvailablity} slots` : ''}`}
            </DrawerSubLabel>
          )}

          {!selectedSlot && includeEmptySlots && (
            <DrawerSubLabel size={14} weight={700} color={Colors.black}>
              {emptySlotText ? emptySlotText : `Slot doesn't exist for this service`}
            </DrawerSubLabel>
          )}
        </SlotAvailabilityContainer>
      )}
    </>
  );
};

export default SlotAvailability;
