import React from 'react';
import { sentenceCase } from 'sentence-case';
import ModalDialog from '../../../components/Modal/ModalDialog';
import { getBranchCurrencySymbol } from '../../../utils/getBranchCurrencySymbol';
import { RecordBody, RecordDataContainer } from '../../styled';
import DiscountsModal from './DiscountsModal';
import { Discount } from './types';

const DiscountRecord = ({ discount }: { discount: Discount }) => {
  const handleClick = () => {
    ModalDialog.openModal({
      content: () => <DiscountsModal discountId={discount?.id} />,
      title: 'Edit Discount'
    });
  };

  return (
    <RecordDataContainer onClick={handleClick}>
      <RecordBody fontWeight={'800'}>{discount?.name}</RecordBody>
      <RecordBody>
        Discounts {discount?.type === 'amount' ? getBranchCurrencySymbol() : '%'}
        {discount?.amount}
      </RecordBody>
      <RecordBody>{sentenceCase(discount?.status)}</RecordBody>
    </RecordDataContainer>
  );
};

export default DiscountRecord;
