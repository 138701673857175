import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import useIcons from '../../../hooks/useIcons';
import { formatAppointmentPeriod } from '../../../utils/dates';
import { getBranchCurrencySymbol } from '../../../utils/getBranchCurrencySymbol';
import { CalendarEvent } from '../../../views/Bookings/types';
import { BookingCard, BookingCardContainer, BookingCardContent, BookingCardLabel, BookingCardTitle, GroupIconContainer, MoreInfoContainer } from '../styled';

const SlotAppointment = ({
  calendarEvent,
  onBookingClick,
  color,
  dayIndex,
  assignedTo,
  isLast,
  mediumSize
}: {
  calendarEvent: CalendarEvent;
  onBookingClick: ({ appointmentsIds, group }: { appointmentsIds?: string[]; group?: boolean }) => void;
  dayIndex: number;
  color: string;
  assignedTo: string;
  isLast?: boolean;
  mediumSize?: boolean;
}) => {
  const icons = useIcons();
  const groupBookings = icons.groupBookings?.childImageSharp.gatsbyImageData;
  const { appointmentsIds, itemName, orderTotal, petsNames, BusUsers, timestamp: ts, duration, branchAppointmentTagsCount, totalNumberOfEvents, itemIndex, isGroup, petsCount } = calendarEvent;
  const timestamp = new Date(ts);
  const hours = timestamp.getUTCHours() || 0;
  const minutes = timestamp.getUTCMinutes() || 0;
  const busUserName = BusUsers?.[0]?.name;
  const isFree = +orderTotal < 0.5;
  const itemDuration = formatAppointmentPeriod(timestamp, duration);

  return (
    <BookingCardContainer>
      <BookingCard
        hour={hours}
        minute={minutes}
        duration={Number(duration)}
        onClick={() => onBookingClick({ appointmentsIds, group: isGroup })}
        total={totalNumberOfEvents}
        index={itemIndex}
        group={isGroup}
        mediumSize={mediumSize}
      >
        <BookingCardContent color={color} group={isGroup}>
          {isGroup && (
            <GroupIconContainer>
              <GatsbyImage image={groupBookings} alt="group icon" />
            </GroupIconContainer>
          )}
          <BookingCardLabel fontSize={12} fontWeight={'bold'} maxWidth={'85%'}>
            {itemName}
          </BookingCardLabel>
          <BookingCardLabel fontSize={12} fontWeight={'500'}>
            {isGroup ? `${petsCount} Pets` : petsNames}
          </BookingCardLabel>
          {isGroup &&
            branchAppointmentTagsCount.map(({ BranchAppointmentTag, count }) => (
              <BookingCardLabel fontSize={12} fontWeight={'500'} key={BranchAppointmentTag?.id}>
                {BranchAppointmentTag?.name} (x{count})
              </BookingCardLabel>
            ))}
        </BookingCardContent>
      </BookingCard>

      {!isGroup && (
        <MoreInfoContainer
          hour={hours}
          minute={minutes}
          total={totalNumberOfEvents}
          index={itemIndex}
          dayIndex={dayIndex}
          color={color}
          duration={Number(duration)}
          last={isLast}
          mediumSize={mediumSize}
        >
          <BookingCardTitle>{itemName}</BookingCardTitle>
          <BookingCardLabel fontSize={18} fontWeight={'600'}>
            {petsNames}
          </BookingCardLabel>
          <BookingCardLabel fontSize={16} fontWeight={'400'}>
            {itemDuration}
          </BookingCardLabel>
          <BookingCardLabel fontSize={16} fontWeight={'800'}>
            {isFree ? 'Free' : `${getBranchCurrencySymbol()}${orderTotal}`}
          </BookingCardLabel>
          {branchAppointmentTagsCount.map(({ BranchAppointmentTag, count }) => (
            <BookingCardLabel fontSize={12} fontWeight={'500'} key={BranchAppointmentTag?.id}>
              {BranchAppointmentTag?.name} (x{count})
            </BookingCardLabel>
          ))}
          {busUserName && (
            <BookingCardLabel fontSize={16} fontWeight={'800'}>
              Assigned to: {assignedTo}
            </BookingCardLabel>
          )}
        </MoreInfoContainer>
      )}
    </BookingCardContainer>
  );
};

export default SlotAppointment;
