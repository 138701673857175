import { useLazyQuery, useReactiveVar } from '@apollo/client';
import React, { useCallback, useEffect, useRef } from 'react';
import Split from 'react-split';
import useInterval from '../../hooks/useInterval';
import { GetBranchBusUserAssignedCalendarAppointmentsViews, GetBranchCalendarAppointmentsViews } from '../../queries';
import { vars } from '../../reactive';
import { hideCalendarActionMessage, setDrawerBar } from '../../reactive/actions';
import { getHourByIndexTitle, getWeekDays } from '../../utils/dates';
import { BOOKING_STATUS_TYPES, Booking, CalendarEvents } from '../../views/Bookings/types';
import { DRAWER_IDS } from '../DrawerBar/types';
import ModalDialog from '../Modal/ModalDialog';
import { BusUserProfile } from '../Profile/types';
import CalendarSlot from './CalendarSlot';
import SlotModal, { SLOTS_TABS_TYPES } from './Modals/SlotModal';
import MultiStaffCalendar, { MultiStaffDaysHeader } from './MultiStaffCalendar/MultiStaffCalendar';
import { AllBookingsCalendarContainer, DayColumn, Hour, HourDiv, HourHand, MultiStaffCalendarContainer, MultiStaffCalendarWrapper } from './styled';
import { CALENDAR_VIEWS, CALENDAR_VIEW_BOOKING_TYPE, bookingCardColors } from './types';

const CalendarBookings = ({
  profile,
  firstDayOfWeek,
  currentCalendarLength,
  selectedBookingType,
  refetchProfile,
  refetchAvailability,
  BusUsers
}: {
  profile: BusUserProfile;
  firstDayOfWeek: Date;
  currentCalendarLength: number;
  selectedBookingType: CALENDAR_VIEWS;
  refetchProfile: () => void;
  refetchAvailability: () => void;
  BusUsers: Booking['BusUsers'];
}) => {
  const fromDate = new Date(Date.UTC(firstDayOfWeek.getFullYear(), firstDayOfWeek.getMonth(), firstDayOfWeek.getDate()));
  const selectedDate = useReactiveVar(vars.selectedDate);
  const modalTitle = useReactiveVar(vars.modalTitle);
  const calendarDefaultGutterSizes = useReactiveVar(vars.calendarDefaultGutterSizes);
  const multiDayBookingType = selectedBookingType === CALENDAR_VIEWS.MULTI_DAY;
  const multiStaffBookingType = selectedBookingType === CALENDAR_VIEWS.MULTI_STAFF;
  const myScheduleBookingType = selectedBookingType === CALENDAR_VIEWS.MY_SCHEDULE;
  const allBookingsType = selectedBookingType === CALENDAR_VIEWS.ALL || myScheduleBookingType;
  const weekDays = getWeekDays(currentCalendarLength, firstDayOfWeek);

  const toDate = new Date(firstDayOfWeek);
  const HourHandRef = useRef<HTMLDivElement>(null);
  toDate.setDate(toDate.getDate() + currentCalendarLength);
  const calendarTagCategoryId = useReactiveVar(vars.calendarTagCategoryId);

  const [getCalendarAppointments, { data: calendarAppointmentsData, refetch: refetchCalendarAppointments }] = useLazyQuery<{
    getBranchCalendarAppointmentsViews: CalendarEvents;
  }>(GetBranchCalendarAppointmentsViews, {
    variables: {
      status: [BOOKING_STATUS_TYPES.CONFIRMED],
      timestamp_from: new Date(new Date(fromDate)?.setUTCHours(0, 0, 0, 0)).toISOString(),
      timestamp_to: new Date(toDate?.setUTCHours(23, 59, 59, 59)).toISOString(),
      filter_by_role: profile.role,
      booking_type: CALENDAR_VIEW_BOOKING_TYPE[selectedBookingType],
      BranchTagCategoryId: calendarTagCategoryId ? [calendarTagCategoryId] : null,
      ...(multiStaffBookingType && {
        appointment_busUserAssigned: true
      }),
      ...(myScheduleBookingType && {
        appointment_busUserAssigned_id: profile?.id
      })
    },
    fetchPolicy: 'cache-and-network',
    pollInterval: 60000
  });

  const [getBusUserAssignedCalendarAppointments, { data: busUserAssignedCalendarAppointmentsData, refetch: refetchBusUserAssignedCalendarAppointments }] = useLazyQuery<{
    getBranchBusUserAssignedCalendarAppointmentsViews: {
      busUserViews: {
        BusUser: BusUserProfile;
        views: CalendarEvents;
      }[];
    };
  }>(GetBranchBusUserAssignedCalendarAppointmentsViews, {
    variables: {
      status: [BOOKING_STATUS_TYPES.CONFIRMED],
      timestamp_from: new Date(fromDate?.setUTCHours(0, 0, 0, 0)).toISOString(),
      timestamp_to: new Date(toDate?.setUTCHours(23, 59, 59, 59)).toISOString(),
      filter_by_role: profile.role,
      booking_type: CALENDAR_VIEW_BOOKING_TYPE[selectedBookingType],
      BranchTagCategoryId: calendarTagCategoryId ? [calendarTagCategoryId] : null
    },
    fetchPolicy: 'cache-and-network',
    pollInterval: 60000
  });

  const refetch = useCallback(() => {
    if (multiStaffBookingType) {
      refetchBusUserAssignedCalendarAppointments();
    } else {
      refetchCalendarAppointments();
    }
  }, [multiStaffBookingType]);

  useEffect(() => {
    if (multiStaffBookingType) {
      getBusUserAssignedCalendarAppointments();
    } else {
      getCalendarAppointments();
    }
  }, [multiStaffBookingType]);

  const calendarAppointments = calendarAppointmentsData?.getBranchCalendarAppointmentsViews || {
    multiDayAppointmentsViews: [],
    singleDayAppointmentsViews: [],
    blockedSlotsViews: []
  };

  const busUserAssignedCalendarAppointments = busUserAssignedCalendarAppointmentsData?.getBranchBusUserAssignedCalendarAppointmentsViews?.busUserViews || [];

  const handleBookingClick = ({ appointmentsIds, group }: { appointmentsIds?: string[]; group?: boolean }) => {
    if (!appointmentsIds?.length) {
      return;
    }

    const drawerId = group ? DRAWER_IDS.GROUP_BOOKINGS_DRAWER : DRAWER_IDS.BOOKING_DRAWER;
    const recordData = appointmentsIds.map(id => ({ id }));
    setDrawerBar({ drawerId, recordData, otherData: { refetch } });
  };

  const blockedSlots = (profile.Branch?.BranchSlots || []).filter(slot => slot.status === 'BLOCKED');

  useEffect(() => {
    if (selectedDate) {
      const blockedSlotsDates = blockedSlots.map(slot => slot.timestamp);

      const selectedSlotDateUTC = new Date(
        Date.UTC(selectedDate.date?.getFullYear(), selectedDate.date?.getMonth(), selectedDate.date?.getDate(), selectedDate.hour > -1 ? selectedDate.hour : 0, selectedDate.minute || 0)
      ).toISOString();

      const defaultTab = blockedSlotsDates.includes(selectedSlotDateUTC) ? SLOTS_TABS_TYPES.BLOCK : SLOTS_TABS_TYPES.BOOK;

      ModalDialog.openModal({
        title: modalTitle,
        content: () => <SlotModal tab={defaultTab} />,
        onCloseBySave: () => {
          hideCalendarActionMessage();
          setTimeout(() => {
            refetchProfile();
            refetch();
            vars.selectedDate(null);
            refetchAvailability();
          }, 3000);
        }
      });
    }
  }, [selectedDate]);

  const scrollToHourHand = () =>
    HourHandRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });

  useEffect(() => {
    scrollToHourHand();
  }, []);

  const renderDayColumn = useCallback(
    ({ hoursLength, mediumSize, smallSize, multiDayBookingType }: { hoursLength?: number; mediumSize?: boolean; smallSize?: boolean; multiDayBookingType?: boolean }) => {
      const length = hoursLength || 25;
      return (
        <DayColumn maxWidth={120} minWidth={120} mobileMaxWidth={64} mobileMinWidth={64}>
          {new Array(length).fill('').map((_, index) => (
            <HourDiv key={index} noHover mediumSize={mediumSize} smallSize={smallSize} renderBorders>
              {!multiDayBookingType && <Hour>{getHourByIndexTitle(index)}</Hour>}
            </HourDiv>
          ))}
        </DayColumn>
      );
    },
    [multiDayBookingType]
  );

  const renderSlot = useCallback(
    (options: { multiDayBookingType: boolean } = { multiDayBookingType }) => {
      const { multiDayBookingType } = options;
      return (
        <CalendarSlot
          multiDayBookingType={multiDayBookingType}
          allBookingsType={allBookingsType}
          eventsToRender={calendarAppointments}
          currentCalendarLength={currentCalendarLength}
          handleBookingClick={handleBookingClick}
          profile={profile}
          renderDayColumn={renderDayColumn}
          weekDays={weekDays}
        >
          <HourHandInterval HourHandRef={HourHandRef} />
        </CalendarSlot>
      );
    },
    [allBookingsType, calendarAppointments, bookingCardColors, handleBookingClick, profile, weekDays, multiDayBookingType, currentCalendarLength]
  );

  return (
    <>
      {!multiStaffBookingType && (
        <AllBookingsCalendarContainer>
          {allBookingsType && (
            <Split className="split" direction="vertical" gutterSize={15} minSize={0} sizes={calendarDefaultGutterSizes || [0, 100]} onDragEnd={sizes => vars.calendarDefaultGutterSizes(sizes)}>
              {renderSlot({ multiDayBookingType: true })}
              {renderSlot({ multiDayBookingType: false })}
            </Split>
          )}
          {!allBookingsType && renderSlot()}
        </AllBookingsCalendarContainer>
      )}

      {multiStaffBookingType && (
        <MultiStaffCalendarWrapper>
          <MultiStaffDaysHeader BusUsers={BusUsers} />
          <MultiStaffCalendarContainer>
            {renderDayColumn({ mediumSize: true })}

            {busUserAssignedCalendarAppointments?.map?.((busUserViews, index) => (
              <MultiStaffCalendar
                BusUsers={BusUsers}
                bookingsToRender={busUserViews.views}
                bookingCardColors={bookingCardColors}
                handleBookingClick={handleBookingClick}
                profile={profile}
                weekDays={weekDays}
                user={busUserViews.BusUser}
                userIndex={index}
                key={index}
                mediumSize
              />
            ))}
          </MultiStaffCalendarContainer>
        </MultiStaffCalendarWrapper>
      )}
    </>
  );
};

export const HourHandInterval = ({ HourHandRef }: { HourHandRef: React.RefObject<HTMLDivElement> }) => {
  const today = useInterval({ delay: 60000 });
  return <HourHand ref={HourHandRef} hour={today.getHours()} minute={today.getMinutes()} />;
};

export default CalendarBookings;
