import { useQuery } from '@apollo/client';
import React from 'react';
import { sentenceCase } from 'sentence-case';
import Colors from '../../Colors';
import { GetBranchOrderSubscriptionsById, GetBranchOrderVouchers } from '../../queries';
import { formatOrdinals, getHourByIndex, getUTCHourOffset, toReadableDate } from '../../utils/dates';
import { getBranchCurrencySymbol } from '../../utils/getBranchCurrencySymbol';
import { getKeys } from '../../utils/helpers';
import { OrderVoucher } from '../../views/Store/Discounts/types';
import { Subscription } from '../../views/Subscriptions/types';
import { getSubscriptionOrder, getSubscriptionTitles } from '../../views/Subscriptions/utils';
import { BookingDrawerButtonsContainer } from '../BookingsDrawer/styled';
import { Divider, DrawerContainer, DrawerContent, DrawerLabel, DrawerLabelContainer, DrawerList, DrawerSection, DrawerSubLabel, PaymentLabel } from '../DrawerBar/styled';
import { DRAWER_IDS } from '../DrawerBar/types';
import PetProfilesList from '../Pets/PetProfilesList';
import ProfileActionBar from '../Profile/ProfileActionBar';
import BranchNotes from '../Shared/BranchNotes/BranchNotes';
import { BranchNoteType } from '../Shared/BranchNotes/types';
import { FlexBreak } from '../Shared/Shared';
import { LoadingOverlay } from '../Shared/Spinner';
import SubscriptionDrawerButtons from './SubscriptionDrawerButtons';

const SubscriptionDrawer = ({ data }: { data: Subscription }) => {
  const { data: { getBranchOrderSubscriptions: [subscription] = [] } = {}, loading: loadingSubscription } = useQuery<{ getBranchOrderSubscriptions: Subscription[] }>(GetBranchOrderSubscriptionsById, {
    variables: { id: data.id, status: [data.status] }
  });

  const { order } = getSubscriptionOrder(subscription);
  const {
    data: { getBranchOrderVouchers: orderVouchers = [] } = {},
    loading: loadingDiscounts,
    refetch: refetchOrderVouchers
  } = useQuery<{
    getBranchOrderVouchers: OrderVoucher[];
  }>(GetBranchOrderVouchers, { variables: { OrderId: order?.id }, fetchPolicy: 'cache-and-network', skip: !subscription?.id });

  const { user, pets, subscriptionNumber, product, nextDate, isCanceled, total, discount, voucher, customizations, isFree, period } = getSubscriptionTitles({ subscription, orderVouchers });

  return (
    <>
      {loadingSubscription && <LoadingOverlay />}
      {!loadingSubscription && (
        <>
          <DrawerContainer>
            <DrawerSection paddingT={24} paddingB={20} isFixed flexRow>
              <DrawerLabel>{subscriptionNumber}</DrawerLabel>
              <BookingDrawerButtonsContainer>
                <SubscriptionDrawerButtons subscription={subscription} orderVouchers={orderVouchers} loading={loadingDiscounts} onUpdate={() => refetchOrderVouchers()} />
              </BookingDrawerButtonsContainer>
            </DrawerSection>
            <Divider />
            <DrawerContent>
              <div>
                <DrawerSubLabel weight={600} size={14} marginBottom={12}>
                  Subscribed by
                </DrawerSubLabel>
                <ProfileActionBar profile={user} drawerId={DRAWER_IDS.SUBSCRIPTION_DRAWER} />
                <Divider marginBottom={16} marginTop={16} />
                <DrawerSubLabel weight={600} size={14} marginBottom={12}>
                  Membership for
                </DrawerSubLabel>
                <DrawerList>
                  <PetProfilesList pets={pets} drawerId={DRAWER_IDS.SUBSCRIPTION_DRAWER} />
                </DrawerList>
                <Divider marginBottom={16} />
                <DrawerSubLabel marginBottom={4} weight={600} size={14}>
                  Membership
                </DrawerSubLabel>
                <DrawerSubLabel marginBottom={28} weight={700} color={Colors.black}>
                  {product?.name}
                </DrawerSubLabel>
                <DrawerSubLabel marginBottom={4} weight={600} size={14}>
                  Status
                </DrawerSubLabel>
                <DrawerSubLabel marginBottom={28} weight={700} color={Colors.black}>
                  {sentenceCase(isCanceled ? 'Cancelled' : subscription?.collection_status || '')}
                </DrawerSubLabel>
                <DrawerSubLabel marginBottom={4} weight={600} size={14}>
                  Trial
                </DrawerSubLabel>
                <DrawerSubLabel marginBottom={28} weight={700} color={Colors.black}>
                  {new Date(subscription?.trial_end).getTime() > new Date().getTime() ? toReadableDate(subscription?.trial_end, { noTime: true }) : 'Ended'}
                </DrawerSubLabel>
                {!!customizations?.length && (
                  <>
                    <DrawerSubLabel marginBottom={4} weight={600} size={14}>
                      Variations
                    </DrawerSubLabel>
                    <DrawerSubLabel marginBottom={28} weight={700} color={Colors.black}>
                      {customizations.map((customization, index) => (
                        <DrawerSubLabel weight={700} color={Colors.black} key={index}>
                          {getKeys(customization.fields)
                            .map(customKey => `${customKey}: ${customization.fields[customKey]?.title}`)
                            .join(', ')
                            .trim()}
                        </DrawerSubLabel>
                      ))}
                    </DrawerSubLabel>
                  </>
                )}
                <DrawerSubLabel marginBottom={4} weight={600} size={14}>
                  Start Date
                </DrawerSubLabel>
                <DrawerSubLabel marginBottom={28} weight={700} color={Colors.black}>
                  {toReadableDate(subscription?.start_date, { isLocale: true })}
                </DrawerSubLabel>
                {!isCanceled && subscription?.next_date && (
                  <>
                    <DrawerSubLabel marginBottom={4} weight={600} size={14}>
                      Next Billing Date
                    </DrawerSubLabel>
                    <DrawerSubLabel marginBottom={28} weight={700} color={Colors.black}>
                      {`${sentenceCase(period)} on ~ ${formatOrdinals(new Date(nextDate.setUTCDate(nextDate?.getUTCDate() + 1)).getUTCDate())} at ${getHourByIndex(getUTCHourOffset())}`}
                    </DrawerSubLabel>
                  </>
                )}
                {isCanceled && (
                  <>
                    <DrawerSubLabel marginBottom={4} weight={600} size={14}>
                      Cancelled At
                    </DrawerSubLabel>
                    <DrawerSubLabel marginBottom={28} weight={700} color={Colors.black}>
                      {toReadableDate(subscription?.ended_at, { isLocale: true })}
                    </DrawerSubLabel>
                  </>
                )}
                <Divider marginBottom={12} />
                {!!order?.id && <BranchNotes type={BranchNoteType.ORDER} OrderId={order?.id} />}
                <Divider marginTop={12} />
              </div>
              <div>
                <DrawerLabelContainer marginY={15}>
                  {!!customizations?.length && !isFree && (
                    <>
                      <DrawerLabelContainer marginY={15}>
                        <DrawerSubLabel marginBottom={0} weight={600} color={Colors.secondaryGrey}>
                          {'Original Price'}
                        </DrawerSubLabel>
                        <DrawerSubLabel marginBottom={0} weight={600} color={Colors.secondaryGrey}>
                          {getBranchCurrencySymbol()}
                          {product?.price}
                        </DrawerSubLabel>
                      </DrawerLabelContainer>
                      <DrawerSubLabel marginBottom={0} weight={600} color={Colors.secondaryGrey}>
                        {'Variations'}
                      </DrawerSubLabel>
                      {customizations.map((customization, index) => {
                        return (
                          <DrawerSubLabel key={index}>
                            {getKeys(customization.fields)
                              .map(customKey => `(${customization.fields[customKey]?.title}: ${getBranchCurrencySymbol()}${Number(customization.fields[customKey]?.price)})`)
                              .join(' ')
                              .trim()}
                          </DrawerSubLabel>
                        );
                      })}
                    </>
                  )}
                  {!customizations.length && (
                    <>
                      <DrawerSubLabel marginBottom={0} weight={600} color={Colors.secondaryGrey}>
                        {'Subtotal'}
                      </DrawerSubLabel>

                      <DrawerSubLabel marginBottom={0} weight={600} color={Colors.secondaryGrey}>
                        {getBranchCurrencySymbol()}
                        {total?.toFixed(2)}
                      </DrawerSubLabel>
                    </>
                  )}
                </DrawerLabelContainer>
                {!!orderVouchers.length && (
                  <DrawerLabelContainer marginY={15}>
                    <DrawerSubLabel marginBottom={0} weight={600} color={Colors.secondaryGrey}>
                      Discount
                    </DrawerSubLabel>
                    <DrawerSubLabel marginBottom={0} weight={600} color={Colors.secondaryGrey}>
                      {voucher?.code} -{discount}
                    </DrawerSubLabel>
                  </DrawerLabelContainer>
                )}
                <DrawerLabelContainer marginY={16}>
                  <DrawerSubLabel marginBottom={4} weight={700} color={Colors.black} size={18}>
                    Total Price
                  </DrawerSubLabel>
                  <DrawerSubLabel marginBottom={4} weight={700} color={Colors.black} size={22}>
                    {getBranchCurrencySymbol()}
                    {order?.total}
                  </DrawerSubLabel>

                  <FlexBreak />
                </DrawerLabelContainer>
                <PaymentLabel danger={['CANCELED'].includes(subscription?.status)}>{sentenceCase(subscription?.status === 'CONFIRMED' ? 'Active' : subscription?.status || '')}</PaymentLabel>
              </div>
            </DrawerContent>
          </DrawerContainer>
        </>
      )}
    </>
  );
};

export default SubscriptionDrawer;
