import { PETS_FILTER_TYPES } from '../config';

export const requiredPetsFiltersData = {
  [PETS_FILTER_TYPES.DAYCARE]: [`daycare_filter`],
  [PETS_FILTER_TYPES.BREED]: [],
  [PETS_FILTER_TYPES.AGE]: [`age_filter`],
  [PETS_FILTER_TYPES.NEUTERING]: [`neutering_filter`],
  [PETS_FILTER_TYPES.VACCINATION]: [`vaccination_filter`],
  [PETS_FILTER_TYPES.TREATMENT]: [`treatment_filter`],
  [PETS_FILTER_TYPES.WEIGHT]: [`weight_filter`],
  [PETS_FILTER_TYPES.SEARCH]: [],
  [PETS_FILTER_TYPES.TAGS]: [],
  [PETS_FILTER_TYPES.BIRTHDATE]: [`age_filter`],
  [PETS_FILTER_TYPES.QUICK_TAGS]: []
};
