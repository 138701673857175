import { useLazyQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import AsyncSelect from 'react-select/async';
import { GetSearchBranchCategoryVets, GetVetById } from '../../../queries';
import debounce from '../../../utils/debounce';
import { Branch } from '../../Profile/types';
import { selectTheme } from '../Forms/Forms';

const getOptions = (data: Branch[]) => {
  return data?.map((user: any) => ({ value: user.id, label: user.name })) || [];
};

const VetsList = ({ formOptions, defaultVetId }: { formOptions: ReturnType<typeof useForm>; defaultVetId?: string }) => {
  const { control } = formOptions;

  const [getSearchVets, { data: { branchCategoryGet: [{ Branches: vets = [] } = {}] = [] } = {} }] = useLazyQuery(GetSearchBranchCategoryVets, {
    fetchPolicy: 'cache-and-network'
  });

  const [getVetById, { data: { branchCategoryGet: [{ Branches: [defaultVetProfile = {}] = [] } = {}] = [] } = {} }] = useLazyQuery(GetVetById, {
    fetchPolicy: 'cache-and-network'
  });

  useEffect(() => {
    if (defaultVetId) {
      getVetById({ variables: { id: defaultVetId } });
    }
  }, [defaultVetId]);

  const options = getOptions(vets);

  const loadOptions = (inputValue: string, callback: (options: { value: string; label: string }[]) => void) => {
    debounce(async () => {
      const { data: { branchCategoryGet: [{ Branches: vets = [] } = {}] = [] } = {} } = await getSearchVets({ variables: { search: inputValue } });
      const options = getOptions(vets);
      callback(options);
    }, 500);
  };

  return (
    <Controller
      name="selectedVetId"
      control={control}
      defaultValue={defaultVetId || ''}
      render={({ onChange, value }) => {
        const val = value ? options.find(option => option.value === value) : '';
        const defaultVal = defaultVetId === value && defaultVetProfile && defaultVetId ? { value: defaultVetId, label: defaultVetProfile?.name } : '';
        return (
          <>
            <AsyncSelect
              styles={{ container: provided => ({ ...provided }), valueContainer: provided => ({ ...provided, height: 48, overflowY: 'scroll' }) }}
              loadOptions={loadOptions}
              theme={selectTheme}
              name={'selectedVetId'}
              onChange={(option: any) => {
                onChange(option?.value);
              }}
              value={defaultVal || val || ''}
              options={options}
              cacheOptions
              noOptionsMessage={({ inputValue }) => (inputValue === '' ? 'Start typing to search' : 'No results found')}
            />
          </>
        );
      }}
    />
  );
};

export default VetsList;
