import { UseFormMethods, ArrayField } from 'react-hook-form';
import { BusUserProfile } from '../../../components/Profile/types';

export enum CustomizationRules {
  NONE = 'none',
  DURATION = 'duration',
  ASSIGN = 'assign',
  CREATE_VOUCHER = 'create_voucher',
  TAG_APPOINTMENTS = 'tag_appointments'
}

export const CustomizationRulesTitles = {
  NONE: 'None',
  DURATION: 'Duration',
  ASSIGN: 'Staff',
  CREATE_VOUCHER: 'Create Voucher',
  TAG_APPOINTMENTS: 'Tag Appointments'
};

type Customization = {
  title: string;
  price: number;
  meta:
    | {
        [key: string]: string;
      }
    | 'none';
  rules?: {
    type: CustomizationRules;
    value: string;
  }[];
};

export type Customizations = Record<string, Customization[]>;

export type TransformedCustomization = {
  name: string;
  options: {
    title: string;
    price: number;
    meta: {
      type: string;
      value: string;
    };
    rules?: {
      type: CustomizationRules;
      value: string | Record<string, any> | Array<{ [key: string]: string }>;
    }[];
  }[];
};

export type FormOptions = {
  customizations: TransformedCustomization[];
};

export type CustomizationsProps = {
  formOptions: UseFormMethods<FormOptions>;
  defaultValue?: Customizations;
  hideRules?: Partial<{
    [key in keyof typeof CustomizationRules]: boolean;
  }>;
};

export type CustomizationsFormProps = {
  formOptions: UseFormMethods<FormOptions>;
  customization: Partial<ArrayField<TransformedCustomization, 'id'>>;
  index: number;
  handleRemoveRow: (index: number) => void;
  watchedCustomizations: TransformedCustomization[];
  busUsers: BusUserProfile[];
  hideRules?: Partial<{
    [key in keyof typeof CustomizationRules]: boolean;
  }>;
  defaultValue?: TransformedCustomization;
};

export type CustomizationFormOptionsProps = {
  formOptions: UseFormMethods<FormOptions>;
  option: Partial<ArrayField<TransformedCustomization['options'][0], 'id'>>;
  index: number;
  handleRemoveRow: (index: number) => void;
  watchedCustomization: TransformedCustomization;
  customizationIndex: number;
  busUsers: BusUserProfile[];
  hideRules?: Partial<{
    [key in keyof typeof CustomizationRules]: boolean;
  }>;
  defaultValue?: TransformedCustomization['options'][0];
};

export type CustomizationsRef = {
  handleCustomizationsSubmit: () => Customizations;
};
