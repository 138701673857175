import { navigate } from 'gatsby';
import React from 'react';
import { sentenceCase } from 'sentence-case';
import { toReadableDate } from '../../../utils/dates';
import { numberWithCommas } from '../../../utils/helpers';
import { RecordBody, RecordDataContainer } from '../../styled';
import { BranchPayout } from './types';

const BranchPayoutRecord = ({ payout }: { payout: BranchPayout }) => {
  const handleClick = () => {
    navigate(`/app/operations/payouts/${payout.id}`);
  };

  return (
    <RecordDataContainer onClick={handleClick}>
      <RecordBody fontWeight={'800'}>
        {toReadableDate(Number(payout?.arrival_date || 0) * 1000, {
          noTime: true,
          isLocale: true
        })}
      </RecordBody>
      <RecordBody marginRight="20">
        {payout?.currency?.toUpperCase?.()} {numberWithCommas(Number(Number(payout?.amount || 0) / 100).toFixed(2))}
      </RecordBody>
      <RecordBody>{sentenceCase(payout?.status || '')}</RecordBody>
      <RecordBody>{sentenceCase(payout?.type || '')}</RecordBody>
      <RecordBody fontWeight="800" flexEnd>
        {'>'}
      </RecordBody>
    </RecordDataContainer>
  );
};

export default BranchPayoutRecord;
