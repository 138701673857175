import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import CreatableSelect from 'react-select';
import useIcons from '../../hooks/useIcons';
import { GetAllBranchAppUsers, GetBusUserProfile, SendChatMessageToAppUsers } from '../../queries';
import { InputBarArea, InputBarAreaContainer } from '../ChatDrawer/styled';
import { ModalRef } from '../Modal/Modal';
import ModalDialog from '../Modal/ModalDialog';
import { ModalBody, ModalFooter } from '../Modal/styled';
import { FormButtonsContainer, FormLabel, FormSubmitButton, InputsWrapper, WideInputGroup, selectTheme } from '../Shared/Forms/Forms';
import { Container } from '../Shared/Shared';
import { CenteredLoader } from '../Shared/Spinner';
import { UsersMessageContainer } from './styled';
type AppUser = { id: string; name: string; email: string; profile_pic: string | null };

const AddUserModal = ({ appUsers, onAddUsers }: { appUsers: AppUser[]; onAddUsers: (users: AppUser[]) => void }) => {
  const { control, getValues } = useForm<{ appUserId: { value: string; label: string }[] }>();
  const options = [...(appUsers || [])]?.sort((a, b) => a.name.localeCompare(b.name))?.map(({ name, email, id }) => ({ value: id, label: `${name} - ${email}` }));

  const onAddUserCallback = () => {
    const selectedUsers = getValues('appUserId');
    const selectedUsersIds = selectedUsers?.map((user: { value: string }) => user.value);

    const selectedUsersData = appUsers?.filter(user => selectedUsersIds?.includes(user.id));
    onAddUsers(selectedUsersData);
  };
  return (
    <>
      <ModalBody minHeight={200}>
        <Container width={460}>
          <Controller as={<CreatableSelect options={options} isMulti theme={selectTheme} name={'appUserId'} />} control={control} name={'appUserId'} rules={{ required: true }} defaultValue={[]} />
        </Container>
      </ModalBody>
      <ModalFooter>
        <FormButtonsContainer>
          <FormSubmitButton onClick={onAddUserCallback}>Add User(s)</FormSubmitButton>
        </FormButtonsContainer>
      </ModalFooter>
    </>
  );
};

const GroupMessageModal = ({ preSelectedUsersIds }: { preSelectedUsersIds?: string[] }) => {
  const icons = useIcons();
  const closeIcon = icons?.closeModal?.childImageSharp.gatsbyImageData;
  const addUserModalRef = useRef<ModalRef>(null);
  const inputBarRef = useRef<HTMLTextAreaElement>(null);
  const { data: { getBusUserProfile: profile = {} } = {}, loading: loadingBusUserProfile } = useQuery(GetBusUserProfile);
  const [sendMessage, { data: sentMessage, loading: sendingMessage }] = useMutation(SendChatMessageToAppUsers);

  const { data: { getBranchAppUsers: branchAppUsers } = {}, loading: loadingAppUsers } = useQuery<{ getBranchAppUsers: AppUser[] }>(GetAllBranchAppUsers);

  const preSelectedUsers = branchAppUsers?.filter(user => preSelectedUsersIds?.includes(user.id));

  const [selectedUsers, setSelectedUsers] = useState<AppUser[]>(preSelectedUsers || []);

  useEffect(() => {
    if (preSelectedUsersIds?.length && branchAppUsers?.length) {
      setSelectedUsers(preSelectedUsers || []);
    }
  }, [preSelectedUsersIds, branchAppUsers]);

  const handleSendMessage = () => {
    if (!inputBarRef.current?.value) {
      return;
    }
    const message = inputBarRef.current?.value || '';

    const defaultMessageVariables = {
      AppUserId: selectedUsers.map(user => user.id),
      user_id: profile?.id,
      user_type: 'bus',
      timestamp: new Date().toISOString(),
      location: null,
      video: null,
      photos: []
    };

    sendMessage({
      variables: {
        ...defaultMessageVariables,
        body: message
      },
      fetchPolicy: 'no-cache'
    });
  };

  useEffect(() => {
    if (sentMessage) {
      inputBarRef.current!.value = '';
      ModalDialog.closeModal();
    }
  }, [sentMessage]);
  const unSelectedUsers = branchAppUsers?.filter(user => !selectedUsers.find(u => u.id === user.id))!;

  const onAddUsers = (users: AppUser[]) => {
    const newUsers = users.filter(user => !selectedUsers.find(u => u.id === user.id));
    setSelectedUsers([...selectedUsers, ...newUsers]);

    addUserModalRef.current?.closeModal();
  };

  const loading = loadingBusUserProfile || loadingAppUsers;

  return (
    <>
      <ModalBody>
        <Container width={500}>
          {loading && <CenteredLoader />}
          {!loading && (
            <UsersMessageContainer>
              <FormLabel>
                Sending to {selectedUsers?.length} {selectedUsers?.length === 1 ? 'user' : 'users'}
              </FormLabel>
              {/* <UsersContainer>
                <GroupProfilesContainer>
                  {selectedUsers.map(user => (
                    <GroupProfileContainer key={user.id}>
                      <ProfileActionBar drawerId={DRAWER_IDS.CHAT_DRAWER} key={user.id} profile={user} showChat={false} />
                      {selectedUsers?.length > 1 && (
                        <CloseButton onClick={() => setSelectedUsers(selectedUsers.filter(u => u.id !== user.id))}>
                          <GatsbyImage image={closeIcon} alt="remove" />
                        </CloseButton>
                      )}
                    </GroupProfileContainer>
                  ))}
                </GroupProfilesContainer>

                <Modal
                  title="Add User"
                  ModalBtn={props => (
                    <AddNewBtn {...props} noSpaceArround>
                      <AddNewBtnIcon src={icons.addPhoto.childImageSharp.gatsbyImageData.images.fallback.src} />
                      Add New User
                    </AddNewBtn>
                  )}
                  ref={addUserModalRef}
                  modalContent={<AddUserModal onAddUsers={onAddUsers} appUsers={unSelectedUsers} />}
                />
              </UsersContainer> */}
              <InputsWrapper>
                <WideInputGroup>
                  <FormLabel>Message</FormLabel>
                  <InputBarAreaContainer noPadding>
                    <InputBarArea minRows={4} maxRows={8} ref={inputBarRef} borderRadius={8} fontSize={16} />
                  </InputBarAreaContainer>
                </WideInputGroup>
              </InputsWrapper>
            </UsersMessageContainer>
          )}
        </Container>
      </ModalBody>
      <ModalFooter>
        <FormButtonsContainer>
          <FormSubmitButton loading={sendingMessage} onClick={handleSendMessage}>
            Send
          </FormSubmitButton>
        </FormButtonsContainer>
      </ModalFooter>
    </>
  );
};

export default GroupMessageModal;
