import React, { useEffect } from 'react';
import InfiniteList from '../../../components/Shared/InfiniteList/InfiniteList';
import usePaginatedQuery from '../../../hooks/usePaginatedQuery';
import { GetReducedBranchDiscounts } from '../../../queries';
import { RecordsContainer } from '../../styled';
import Common from '../Common';
import DiscountRecord from './DiscountRecord';
import { Discount } from './types';

const Discounts = () => {
  const [[hasMoreItems, setHasMoreItems], queryResult] = usePaginatedQuery<Discount[]>({ query: GetReducedBranchDiscounts, otherParams: { fetchPolicy: 'cache-and-network' } });

  const { data: { getBranchDiscounts: discounts = [] } = {}, loading, fetchMore, refetch } = queryResult;

  useEffect(() => {
    Common.set(`Discounts.GetBranchDiscounts.refetch`, () => {
      refetch?.().then(() => setHasMoreItems(true));
    });
  }, []);

  return (
    <RecordsContainer>
      <InfiniteList
        list={discounts}
        itemRenderer={(discount: Discount) => <DiscountRecord key={discount.id} discount={discount} />}
        hasMoreItems={hasMoreItems}
        loading={loading}
        fetchMore={fetchMore}
        offset={discounts?.length}
        setHasMoreItems={setHasMoreItems}
      />
    </RecordsContainer>
  );
};

export default Discounts;
