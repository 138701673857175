import React from 'react';
import { getBranchCurrencySymbol } from '../../../utils/getBranchCurrencySymbol';
import { RecordBody, RecordDataContainer } from '../../styled';
import { isProductNoCharge, isProductPostpaid } from '../ProductPrice';
import { CHARGE_STATUS, chargeStatuses, productStatuses } from '../types';

const ServiceRecord = ({ service, navigateDrawer }) => {
  const categories = service?.ProductCategories.map(category => category?.name).join(',');

  const defaultChargeStatus = isProductNoCharge(service) ? CHARGE_STATUS.NO_CHARGE : isProductPostpaid(service) ? CHARGE_STATUS.CHARGE_LATER : CHARGE_STATUS.CHARGE_NOW;

  const chargeStatus = chargeStatuses[defaultChargeStatus]?.label;

  const handleClick = () => {
    navigateDrawer({ drawerLinkId: service?.id });
  };
  const price = isProductNoCharge(service) ? 'Free' : getBranchCurrencySymbol() + service?.price;
  const status = productStatuses.find(status => status.value === service?.status)?.availablality;
  return (
    <RecordDataContainer onClick={handleClick}>
      <RecordBody fontWeight={'800'}>{service?.name}</RecordBody>
      <RecordBody>{service?.sku}</RecordBody>
      <RecordBody>{price}</RecordBody>
      <RecordBody>{chargeStatus}</RecordBody>
      <RecordBody>{status}</RecordBody>
      <RecordBody>{categories}</RecordBody>
    </RecordDataContainer>
  );
};

export default ServiceRecord;
