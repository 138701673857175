import { useQuery } from '@apollo/client';
import React, { Fragment } from 'react';
import { ExtensionDivider, ExtensionPetCaseLabel, FlexCenterBetweenContainer } from '../../../../components/Pets/styled';
import useIcons from '../../../../hooks/useIcons';
import { GetBusUserProfile } from '../../../../queries';
import { toReadableDate } from '../../../../utils/dates';
import { getKeys, toPascalCase } from '../../../../utils/helpers';
import { AddedImage, AddedImageLink, FlexColumnCenterBetweenContainer, PhotosContainer } from '../../../Store/styled';
import { SHAPES } from '../../Health/Health';
import NoteSectionHtml from '../NoteSectionHtml';
import { EditMedicalNote } from './MedicalNoteModal';
import { MedicalNoteContainer, TempBPMContainer, TempBPMIcon } from './styled';

const MedicalNote = ({ note, medicalCase, refetch }) => {
  const icons = useIcons();
  const { data: { getBusUserProfile: profile = {} } = {}, loading } = useQuery(GetBusUserProfile);

  const bpmIcon = icons.bpm.childImageSharp.gatsbyImageData;
  const tempIcon = icons.temp.childImageSharp.gatsbyImageData;
  const weightIcon = icons.weight.childImageSharp.gatsbyImageData;
  const bcsIcon = icons.bcs.childImageSharp.gatsbyImageData;
  const photos = note?.body?.photos?.primary || [];
  const attachments = note?.body?.attachments?.primary || [];
  return (
    <MedicalNoteContainer>
      <ExtensionPetCaseLabel fontSize={14} fontWeight={'600'} margingB={10}>
        {toReadableDate(note?.timestamp, { isLocale: true })}
        {profile?.id === note?.BusUser?.id && <EditMedicalNote note={note} medicalCase={medicalCase} refetch={refetch} />}
      </ExtensionPetCaseLabel>
      <ExtensionPetCaseLabel fontSize={12} fontWeight={'normal'} margingB={10}>
        By {note?.BusUser?.name}
      </ExtensionPetCaseLabel>
      <ExtensionDivider />
      {!!photos?.length && (
        <>
          <ExtensionPetCaseLabel fontSize={18} fontWeight={'800'} margingB={16}>
            Photos
          </ExtensionPetCaseLabel>
          <ExtensionDivider />
          <PhotosContainer>
            {photos?.map((photo: string) => (
              <FlexColumnCenterBetweenContainer key={photo}>
                <AddedImageLink href={photo} target="_blank" rel="noopener noreferrer">
                  <AddedImage src={photo} alt="report image" />
                </AddedImageLink>
              </FlexColumnCenterBetweenContainer>
            ))}
          </PhotosContainer>
          <ExtensionDivider />
        </>
      )}
      {!!attachments?.length && (
        <>
          <ExtensionPetCaseLabel fontSize={18} fontWeight={'800'} margingB={16}>
            Attachments
          </ExtensionPetCaseLabel>
          <PhotosContainer>
            {attachments?.map((attachment: string) => (
              <FlexColumnCenterBetweenContainer key={attachment}>
                <AddedImageLink href={attachment} target="_blank" rel="noopener noreferrer">
                  <AddedImage src={icons.pdfPlaceholder.childImageSharp.gatsbyImageData.images.fallback.src} alt="report image" />
                </AddedImageLink>
              </FlexColumnCenterBetweenContainer>
            ))}
          </PhotosContainer>
          <ExtensionDivider />
        </>
      )}

      <FlexCenterBetweenContainer style={{ flexWrap: 'wrap' }}>
        {!!note?.body?.chest?.pulse?.grade && (
          <TempBPMContainer>
            <ExtensionPetCaseLabel fontSize={16} fontWeight={'bold'}>
              <TempBPMIcon image={bpmIcon} alt="bpm" />
              {note?.body?.chest?.pulse?.grade}
              <sub style={{ height: '1ch', fontSize: 10 }}>BPM</sub>
            </ExtensionPetCaseLabel>
          </TempBPMContainer>
        )}
        {!!note?.body?.temperature?.grade && (
          <TempBPMContainer>
            <ExtensionPetCaseLabel fontSize={16} fontWeight={'bold'}>
              <TempBPMIcon image={tempIcon} alt="temperature" />
              {note?.body?.temperature?.grade}&#176;C
            </ExtensionPetCaseLabel>
          </TempBPMContainer>
        )}
        {!!note?.body?.weight && (
          <TempBPMContainer>
            <ExtensionPetCaseLabel fontSize={16} fontWeight={'bold'}>
              <TempBPMIcon image={weightIcon} alt="weight" />
              {note?.body?.weight}kg
            </ExtensionPetCaseLabel>
          </TempBPMContainer>
        )}
        {!!note?.body?.shape && (
          <TempBPMContainer>
            <ExtensionPetCaseLabel fontSize={16} fontWeight={'bold'}>
              <TempBPMIcon image={bcsIcon} alt="shape" />
              {SHAPES.indexOf(note?.body?.shape) + 1} - {toPascalCase(note?.body?.shape === 'BELOW_IDEAL' ? 'LEAN' : note?.body?.shape || '')}
            </ExtensionPetCaseLabel>
          </TempBPMContainer>
        )}
      </FlexCenterBetweenContainer>
      <ExtensionDivider />
      {note?.body?.note_type === 'basic' && <NoteSectionHtml item={note?.body} keyName={'additional_notes'} />}
      {note?.body?.note_type !== 'basic' && (
        <>
          <ExtensionPetCaseLabel fontSize={18} fontWeight={'800'} margingB={18}>
            Eyes & Vision
          </ExtensionPetCaseLabel>
          <ExtensionPetCaseLabel fontSize={16} fontWeight={'bold'} margingB={12}>
            Appearance: {toPascalCase(note?.body?.eyes?.appearance?.status || '')}. {note?.body?.eyes?.appearance?.position?.map(eye => toPascalCase(eye)).join?.(' & ')}
          </ExtensionPetCaseLabel>
          <NoteSectionHtml item={note?.body?.eyes?.appearance} />
          <ExtensionPetCaseLabel fontSize={16} fontWeight={'bold'} margingB={12}>
            Vision: {note?.body?.eyes?.vision?.status}.
          </ExtensionPetCaseLabel>
          <NoteSectionHtml item={note?.body?.eyes?.vision} />
          <ExtensionDivider />
          <ExtensionPetCaseLabel fontSize={18} fontWeight={'800'} margingB={18}>
            Ears
          </ExtensionPetCaseLabel>
          <ExtensionPetCaseLabel fontSize={16} fontWeight={'bold'} margingB={12}>
            {toPascalCase(note?.body?.ears?.status === 'clean' ? 'Clean & Healthy' : note?.body?.ears?.status || '')}. {note?.body?.ears?.position?.map(ear => toPascalCase(ear)).join?.(' & ')}
          </ExtensionPetCaseLabel>
          <NoteSectionHtml item={note?.body?.ears} />
          <ExtensionDivider />
          <ExtensionPetCaseLabel fontSize={18} fontWeight={'800'} margingB={18}>
            Dental
          </ExtensionPetCaseLabel>
          {getKeys(note?.body?.dental || {}).some(dentalCase => note?.body?.dental[dentalCase].affected) ? (
            <>
              <ExtensionPetCaseLabel fontSize={16} fontWeight={'bold'} margingB={12}>
                Tartar/calculus: {note?.body?.dental?.tartar_calculus?.affected ? note?.body?.dental?.tartar_calculus?.grade : 'None'}
              </ExtensionPetCaseLabel>
              <ExtensionPetCaseLabel fontSize={16} fontWeight={'bold'} margingB={12}>
                Gingivitis: {note?.body?.dental?.gingivitis?.affected ? note?.body?.dental?.gingivitis?.grade : 'None'}
              </ExtensionPetCaseLabel>
              <ExtensionPetCaseLabel fontSize={16} fontWeight={'bold'} margingB={12}>
                Missing Teeth: {note?.body?.dental?.missing_teeth?.affected ? 'Yes' : 'None'}
              </ExtensionPetCaseLabel>
              <NoteSectionHtml item={note?.body?.dental?.missing_teeth} />
            </>
          ) : (
            <ExtensionPetCaseLabel fontSize={16} fontWeight={'bold'} margingB={12}>
              Clean & Healthy
            </ExtensionPetCaseLabel>
          )}
          <ExtensionDivider />
          <ExtensionPetCaseLabel fontSize={18} fontWeight={'800'} margingB={18}>
            Lymph Nodes/Glands
          </ExtensionPetCaseLabel>
          <ExtensionPetCaseLabel fontSize={16} fontWeight={'bold'} margingB={12}>
            {toPascalCase(note?.body?.lymph_nodes?.status || '')}.
          </ExtensionPetCaseLabel>
          <NoteSectionHtml item={note?.body?.lymph_nodes} />
          <ExtensionDivider />
          <ExtensionPetCaseLabel fontSize={18} fontWeight={'800'} margingB={18}>
            Chest/circulatory
          </ExtensionPetCaseLabel>
          <ExtensionPetCaseLabel fontSize={16} fontWeight={'bold'} margingB={12}>
            Heart Rate/Rythm: {toPascalCase(note?.body?.chest?.heart_rate_rythm?.status || '')}.
          </ExtensionPetCaseLabel>
          <NoteSectionHtml item={note?.body?.chest?.heart_rate_rythm} />
          <ExtensionPetCaseLabel fontSize={16} fontWeight={'bold'} margingB={12}>
            Heart Murmur: {toPascalCase(note?.body?.chest?.heart_murmur?.status || '')}. {note?.body?.chest?.heart_murmur?.grade && `Grade ${note?.body?.chest?.heart_murmur?.grade}`}
          </ExtensionPetCaseLabel>
          <ExtensionPetCaseLabel fontSize={16} fontWeight={'bold'} margingB={12}>
            Lung Sounds: {toPascalCase(note?.body?.chest?.lung_sounds?.status || '')}.
          </ExtensionPetCaseLabel>
          <NoteSectionHtml item={note?.body?.chest?.lung_sounds} />
          <ExtensionPetCaseLabel fontSize={16} fontWeight={'bold'} margingB={12}>
            Pulse: {toPascalCase(note?.body?.chest?.pulse?.status || '')}. {note?.body?.chest?.pulse?.grade && `BPM ${note?.body?.chest?.pulse?.grade}`}
          </ExtensionPetCaseLabel>
          <ExtensionPetCaseLabel fontSize={16} fontWeight={'bold'} margingB={12}>
            Mucous membrane colour and capillary refill time: {toPascalCase(note?.body?.chest?.mucous_membrane?.status || '')}.
          </ExtensionPetCaseLabel>
          <NoteSectionHtml item={note?.body?.chest?.mucous_membrane} />
          <ExtensionDivider />
          <ExtensionPetCaseLabel fontSize={18} fontWeight={'800'} margingB={18}>
            Abdominal palpation
          </ExtensionPetCaseLabel>
          <ExtensionPetCaseLabel fontSize={16} fontWeight={'bold'} margingB={12}>
            {toPascalCase(note?.body?.abdominal_palpation?.status || '')}.
          </ExtensionPetCaseLabel>
          <NoteSectionHtml item={note?.body?.abdominal_palpation} />
          <ExtensionDivider />
          <ExtensionPetCaseLabel fontSize={18} fontWeight={'800'} margingB={18}>
            Movement, Gait & Nails
          </ExtensionPetCaseLabel>
          <ExtensionPetCaseLabel fontSize={16} fontWeight={'bold'} margingB={12}>
            Forelimbs: {toPascalCase(note?.body?.movement_gait_nails?.forelimbs?.status || '')} - {note?.body?.movement_gait_nails?.forelimbs?.position?.map(limb => toPascalCase(limb)).join?.(' & ')}{' '}
            {note?.body?.movement_gait_nails?.forelimbs?.grade && ` - Grade ${note?.body?.movement_gait_nails?.forelimbs?.grade}`}
          </ExtensionPetCaseLabel>
          <NoteSectionHtml item={note?.body?.movement_gait_nails?.forelimbs} />
          <ExtensionPetCaseLabel fontSize={16} fontWeight={'bold'} margingB={12}>
            Hindlimbs: {toPascalCase(note?.body?.movement_gait_nails?.hindlimbs?.status || '')} - {note?.body?.movement_gait_nails?.hindlimbs?.position?.map(limb => toPascalCase(limb)).join?.(' & ')}{' '}
            {note?.body?.movement_gait_nails?.hindlimbs?.grade && ` - Grade ${note?.body?.movement_gait_nails?.hindlimbs?.grade}`}
          </ExtensionPetCaseLabel>
          <NoteSectionHtml item={note?.body?.movement_gait_nails?.hindlimbs} />
          <ExtensionPetCaseLabel fontSize={16} fontWeight={'bold'} margingB={12}>
            Nails: {toPascalCase(note?.body?.movement_gait_nails?.nails?.status || '')}.
          </ExtensionPetCaseLabel>
          <ExtensionDivider />
          <ExtensionPetCaseLabel fontSize={18} fontWeight={'800'} margingB={18}>
            Skin & Coat
          </ExtensionPetCaseLabel>
          <ExtensionPetCaseLabel fontSize={16} fontWeight={'bold'} margingB={12}>
            Skin & Coat: {toPascalCase(note?.body?.skin_coat?.skin_coat?.status || '')}.
          </ExtensionPetCaseLabel>
          <NoteSectionHtml item={note?.body?.skin_coat?.skin_coat} />
          <ExtensionPetCaseLabel fontSize={16} fontWeight={'bold'} margingB={12}>
            Lumps: {toPascalCase(note?.body?.skin_coat?.lumps?.status || '')}
          </ExtensionPetCaseLabel>
          {(note?.body?.skin_coat?.lumps?.lumps || []).map((lump, i) => (
            <Fragment key={i}>
              <ExtensionPetCaseLabel fontSize={16} fontWeight={'bold'} margingB={12}>
                Lump ({i + 1}){lump?.size ? ` - ${lump?.size}mm` : ''}
              </ExtensionPetCaseLabel>
              {!!lump?.details && (
                <ExtensionPetCaseLabel fontSize={16} fontWeight={'400'} margingB={17}>
                  {lump?.details}
                </ExtensionPetCaseLabel>
              )}
            </Fragment>
          ))}
          <ExtensionPetCaseLabel fontSize={16} fontWeight={'bold'} margingB={12}>
            Parasites: {toPascalCase(note?.body?.skin_coat?.parasites?.status?.replace('none', 'none_seen') || '')}.
          </ExtensionPetCaseLabel>
          <NoteSectionHtml item={note?.body?.skin_coat?.parasites} />
          <ExtensionDivider />
          <ExtensionPetCaseLabel fontSize={18} fontWeight={'800'} margingB={18}>
            Temperature
          </ExtensionPetCaseLabel>
          <ExtensionPetCaseLabel fontSize={16} fontWeight={'bold'} margingB={12}>
            {toPascalCase(note?.body?.temperature?.status || '')} - {note?.body?.temperature?.grade}°C
          </ExtensionPetCaseLabel>
          <ExtensionDivider />
          {!!note?.body?.treatments?.taken?.length && (
            <>
              <ExtensionPetCaseLabel fontSize={18} fontWeight={'800'} margingB={18}>
                Treatments
              </ExtensionPetCaseLabel>
              <ExtensionPetCaseLabel fontSize={16} fontWeight={'bold'} margingB={12}>
                {note?.body?.treatments?.taken
                  ?.map(treatment => toPascalCase(treatment))
                  .sort()
                  .join(', ')}
              </ExtensionPetCaseLabel>
              {/* {!!note?.body?.treatments?.taken?.includes('parasite_control') && !!note?.body?.treatments?.parasite_control?.products?.length && (
                <ExtensionPetCaseLabel fontSize={16} fontWeight={'400'} margingB={12}>
                  Parasite Control Administered:{' '}
                  {note?.body?.treatments?.parasite_control?.products?.map(productId => toPascalCase(parasiteControlProducts.find(product => product.id === productId)?.name || '')).join(', ')}
                </ExtensionPetCaseLabel>
              )} */}
              <ExtensionDivider />
            </>
          )}
        </>
      )}
    </MedicalNoteContainer>
  );
};

export default MedicalNote;
