import React, { FC, useCallback, useRef, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Controller, useForm } from 'react-hook-form';
import { ModalBody, ModalFooter } from '../../../../components/Modal/styled';
import { FormButtonsContainer, FormLabel, FormSubmitButton, InputsWrapper, WideInputGroup, FormInput, RadioBtnsGroup, SectionLabel } from '../../../../components/Shared/Forms/Forms';
import { Container } from '../../../../components/Shared/Shared';
import { NoteAdd, NoteEdit } from '../../../../queries';
import { ActionBtn } from '../../../Store/styled';
import useIcons from '../../../../hooks/useIcons';
import { sentenceCase } from 'sentence-case';
import NotePhotos from '../NotePhotos';
import usePhotoUpload, { useDeletePhotos } from '../../../../hooks/usePhotoUpload';
import ModalDialog from '../../../../components/Modal/ModalDialog';
import NotesMarkupEditor from '../NotesMarkupEditor';

export enum NOTE_ACTION_TYPES {
  ADD = 'ADD',
  EDIT = 'EDIT'
}

const coatOptions = ['fairly_clean', 'dirty', 'tangled_matted_fur', 'heavily_matted_fur', 'severely_matted_fur_seek_medical_attention'];

const earsOptions = ['fairly_clean', 'dirty_and_waxy', 'seek_medical_attention'];

const teethOptions = ['fairly_clean', 'some_plaque_suggest_brushing_dental_chews', 'built_up_tartar_suggest_dental_leaning', 'seek_medical_attention'];

const nailsOptions = ['trimmed', 'not_trimmed'];

const brushingFrequencyOptions = ['1_to_2_per_week', '3_per_week', '4_to_5_per_week', 'daily', 'other'];

const petBehaviorBehaviorOptions = ['patient', 'friendly', 'vocal', 'anxious', 'reactive', 'other'];

const GroomingReportNoteModal: FC<{
  selectedReport: Record<string, any>;
  selectedNote?: Record<string, any>;
  refetchNotes?: ReturnType<typeof useQuery>['refetch'];
}> = ({ selectedReport, selectedNote, refetchNotes }) => {
  const [handleAddNote, { loading: loadingAddNote, error: errorNoteAdd }] = useMutation(NoteAdd);
  const [handleEditNote, { loading: loadingEditNote, error: errorNoteEdit }] = useMutation(NoteEdit);
  const noteIdRef = useRef<string>('');
  const photosRef = useRef<Record<string, any>>({});

  const uploadPhotos = usePhotoUpload();
  const [_, { loading: loadingUploadPhotos, error: errorUploadPhotos }] = uploadPhotos;
  const deletePhotos = useDeletePhotos();

  const { control, handleSubmit } = useForm();

  const loading = loadingAddNote || loadingEditNote || loadingUploadPhotos;
  const error = !!errorNoteAdd?.message || !!errorNoteEdit?.message || !!errorUploadPhotos;

  const handleSave = handleSubmit(async form => {
    if (selectedNote) {
      noteIdRef.current = selectedNote.id;
    }

    if (!selectedNote) {
      const { data: { noteAdd: addedNote = {} } = {} } = await handleAddNote({
        variables: {
          body: form,
          timestamp: new Date(),
          groomingReportId: selectedReport.id
        }
      });
      noteIdRef.current = addedNote.id;
    }
    const photos = await photosRef.current?.getPhotos();
    const attachments = await photosRef.current?.getAttachments();

    await handleEditNote({
      variables: {
        id: noteIdRef.current,
        body: {
          ...form,
          photos: {
            primary: photos
          },
          attachments: {
            primary: attachments
          }
        },
        timestamp: new Date()
      }
    });

    ModalDialog.closeModal();
    refetchNotes?.();
  });

  return (
    <>
      <ModalBody>
        <Container width={500}>
          <NotePhotos uploadPhotos={uploadPhotos} deletePhotos={deletePhotos} ref={photosRef} selectedNote={selectedNote} noteIdRef={noteIdRef} />
          <InputsWrapper>
            <SectionLabel>Grooming</SectionLabel>
            <WideInputGroup>
              <FormLabel>Style</FormLabel>
              <Controller as={<FormInput />} name={`grooming.style.details`} control={control} defaultValue={selectedNote?.body?.grooming?.style?.details || ''} />
            </WideInputGroup>

            <WideInputGroup>
              <FormLabel>Blade</FormLabel>
              <NotesMarkupEditor control={control} defaultValue={selectedNote?.body?.grooming?.blade} name={`grooming.blade`} />
            </WideInputGroup>
          </InputsWrapper>
          <InputsWrapper>
            <WideInputGroup>
              <SectionLabel>Condition</SectionLabel>
              <FormLabel>Coat</FormLabel>
              <Controller
                render={({ onChange }) => (
                  <RadioBtnsGroup
                    options={coatOptions}
                    name={'condition.coat.status'}
                    defaultValue={selectedNote?.body?.condition?.coat?.status || coatOptions[0]}
                    onChange={e => onChange(e.target.value)}
                    itemStyle={{ marginBottom: 4 }}
                  />
                )}
                control={control}
                name={'condition.coat.status'}
                defaultValue={selectedNote?.body?.condition?.coat?.status || coatOptions[0]}
              />
            </WideInputGroup>
            <WideInputGroup>
              <FormLabel>Ears</FormLabel>
              <Controller
                render={({ onChange }) => (
                  <RadioBtnsGroup
                    options={earsOptions}
                    name={'condition.ears.status'}
                    defaultValue={selectedNote?.body?.condition.ears.status || earsOptions[0]}
                    onChange={e => onChange(e.target.value)}
                    itemStyle={{ marginBottom: 4 }}
                  />
                )}
                control={control}
                name={'condition.ears.status'}
                defaultValue={selectedNote?.body?.condition.ears.status || earsOptions[0]}
              />
            </WideInputGroup>

            <WideInputGroup>
              <FormLabel>Teeth</FormLabel>
              <Controller
                render={({ onChange }) => (
                  <RadioBtnsGroup
                    options={teethOptions}
                    name={'condition.teeth.status'}
                    defaultValue={selectedNote?.body?.condition?.teeth.status || teethOptions[0]}
                    onChange={e => onChange(e.target.value)}
                    itemStyle={{ marginBottom: 4 }}
                  />
                )}
                control={control}
                name={'condition.teeth.status'}
                defaultValue={selectedNote?.body?.condition?.teeth.status || teethOptions[0]}
              />
            </WideInputGroup>

            <WideInputGroup>
              <FormLabel>Nails</FormLabel>
              <Controller
                render={({ onChange }) => (
                  <RadioBtnsGroup
                    options={nailsOptions}
                    name={'condition.nails.status'}
                    defaultValue={selectedNote?.body?.condition?.nails.status || nailsOptions[0]}
                    onChange={e => onChange(e.target.value)}
                    itemStyle={{ marginBottom: 4 }}
                  />
                )}
                control={control}
                name={'condition.nails.status'}
                defaultValue={selectedNote?.body?.condition?.nails.status || nailsOptions[0]}
              />
            </WideInputGroup>

            <WideInputGroup>
              <FormLabel>Notes</FormLabel>
              <NotesMarkupEditor control={control} defaultValue={selectedNote?.body?.condition?.notes} name={`condition.notes`} />
            </WideInputGroup>
          </InputsWrapper>

          <InputsWrapper>
            <SectionLabel>Brushing</SectionLabel>
            <WideInputGroup>
              <FormLabel>Frequency</FormLabel>
              <Controller
                render={({ onChange }) => (
                  <RadioBtnsGroup
                    options={brushingFrequencyOptions}
                    name={'brushing.frequency.status'}
                    defaultValue={selectedNote?.body?.brushing?.frequency.status || brushingFrequencyOptions[0]}
                    onChange={e => onChange(e.target.value)}
                    itemStyle={{ marginBottom: 4 }}
                  />
                )}
                control={control}
                name={'brushing.frequency.status'}
                defaultValue={selectedNote?.body?.brushing?.frequency.status || brushingFrequencyOptions[0]}
              />
            </WideInputGroup>

            <WideInputGroup>
              <FormLabel>Notes on Brushing</FormLabel>
              <NotesMarkupEditor control={control} defaultValue={selectedNote?.body?.brushing?.notes} name={`brushing.notes`} />
            </WideInputGroup>
          </InputsWrapper>

          <InputsWrapper>
            <SectionLabel>Pet Behaviour</SectionLabel>
            <WideInputGroup>
              <FormLabel>Behaviour</FormLabel>
              <Controller
                render={({ onChange }) => (
                  <RadioBtnsGroup
                    options={petBehaviorBehaviorOptions}
                    name={'pet_behavior.behavior.status'}
                    defaultValue={selectedNote?.body?.pet_behavior?.behavior.status || petBehaviorBehaviorOptions[0]}
                    onChange={e => onChange(e.target.value)}
                    itemStyle={{ marginBottom: 4 }}
                  />
                )}
                control={control}
                name={'pet_behavior.behavior.status'}
                defaultValue={selectedNote?.body?.pet_behavior?.behavior.status || petBehaviorBehaviorOptions[0]}
              />
            </WideInputGroup>

            <WideInputGroup>
              <FormLabel>Other on Behaviour</FormLabel>
              <NotesMarkupEditor control={control} defaultValue={selectedNote?.body?.pet_behavior?.notes} name={`pet_behavior.notes`} />
            </WideInputGroup>
          </InputsWrapper>

          <InputsWrapper>
            <SectionLabel>Notes</SectionLabel>
            <WideInputGroup>
              <FormLabel>General notes</FormLabel>
              <NotesMarkupEditor control={control} defaultValue={selectedNote?.body?.notes?.general_notes} name={`notes.general_notes`} />
            </WideInputGroup>
          </InputsWrapper>
        </Container>
      </ModalBody>
      <ModalFooter>
        <FormButtonsContainer>
          <FormSubmitButton error={error} loading={loading} onClick={handleSave}>
            Save note
          </FormSubmitButton>
        </FormButtonsContainer>
      </ModalFooter>
    </>
  );
};

const GroomingReportNoteAction: FC<{
  type: NOTE_ACTION_TYPES;
  note?: Record<string, any>;
  selectedReport: Record<string, any>;
  refetchNotes?: ReturnType<typeof useQuery>['refetch'];
  autoOpenModal?: boolean;
}> = ({ type, note, selectedReport, refetchNotes, autoOpenModal }) => {
  const icons = useIcons();
  const icon = type === NOTE_ACTION_TYPES.ADD ? icons.add.childImageSharp.gatsbyImageData.images.fallback.src : icons.edit.childImageSharp.gatsbyImageData.images.fallback.src;

  const showModal = useCallback(
    () =>
      ModalDialog.openModal({
        content: () => <GroomingReportNoteModal selectedNote={note} selectedReport={selectedReport} refetchNotes={refetchNotes} />,
        title: `${sentenceCase(type)} note`
      }),
    [note, selectedReport, refetchNotes, type]
  );

  useEffect(() => {
    if (autoOpenModal) {
      showModal();
    }
  }, [autoOpenModal, showModal]);

  return <ActionBtn bgImage={icon} onClick={showModal} />;
};

export default GroomingReportNoteAction;
