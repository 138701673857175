import React, { FC, useRef } from 'react';
import { Controller } from 'react-hook-form';
import ReactSelect from 'react-select';
import Modal, { ModalRef } from '../../../components/Modal/Modal';
import { convertDurationToMinutes } from '../../../components/Shared/DurationSelector';
import { ColorPicker, FormError, FormInput, FormLabel, FormLinkButtonStyled, FormSelect, InputContainer, RemoveRowContainer, selectTheme } from '../../../components/Shared/Forms/Forms';
import useIcons from '../../../hooks/useIcons';
import { getKeys } from '../../../utils/helpers';
import { isValidURL } from '../../../utils/validators';
import BranchTagItemModal from '../BranchTags/BranchTagItemModal';
import { BRANCH_TAGS_TYPES } from '../BranchTags/types';
import VoucherModal from '../Discounts/VoucherModal';
import { FormVoucher } from '../Discounts/types';
import { RemoveAddedImage } from '../styled';
import { CustomizationFormOptionsContainer, CustomizationFormOptionsWrapper, CustomizationInputContainer } from './styled';
import { CustomizationFormOptionsProps, CustomizationRules, CustomizationRulesTitles } from './types';

const CustomizationFormOptions: FC<CustomizationFormOptionsProps> = ({ formOptions, handleRemoveRow, index, option, watchedCustomization, customizationIndex, busUsers, hideRules, defaultValue }) => {
  const icons = useIcons();
  const { control, errors, setValue } = formOptions;
  const { meta: watchedMeta, rules: watchedRules } = watchedCustomization?.options?.[index] || {};

  const optionErrors = errors?.customizations?.[customizationIndex]?.options?.find((_item, optionIndex) => optionIndex === index);

  const optionName = `customizations[${customizationIndex}].options[${index}]`;

  const vouchersModalRef = useRef<ModalRef>(null);
  const tagsModalRef = useRef<ModalRef>(null);

  const onClickVoucher = () => {
    const voucher = watchedRules?.[0]?.value as FormVoucher;
    vouchersModalRef.current?.openModal({ voucher, title: 'Voucher to create' });
  };

  const onClickTags = () => {
    const selectedTags = watchedRules?.[0]?.value as { tags: string[] };

    tagsModalRef.current?.openModal({ selectedTags, title: 'Tags to add' });
  };

  const rulesToShow = getKeys(CustomizationRules).filter(key => !hideRules?.[key]);

  return (
    <CustomizationFormOptionsWrapper>
      <RemoveRowContainer noMargin>
        <RemoveAddedImage src={icons.delete.childImageSharp.gatsbyImageData.images.fallback.src} onClick={() => handleRemoveRow(index)} noMargin />
      </RemoveRowContainer>
      <CustomizationFormOptionsContainer column>
        <CustomizationFormOptionsContainer>
          <InputContainer flex={1}>
            <FormLabel>Option</FormLabel>
            <Controller
              as={<FormInput error={!!optionErrors?.title} height={20} fontSize={16} />}
              control={control}
              name={`${optionName}.title`}
              defaultValue={option?.title || ''}
              rules={{ required: true }}
            />
            {!!optionErrors?.title && <FormError>{optionErrors?.title?.message || 'Title is required'}</FormError>}
          </InputContainer>
          <InputContainer>
            <FormLabel>Cost</FormLabel>
            <Controller
              as={<FormInput error={!!optionErrors?.price} height={20} fontSize={16} width={44} />}
              control={control}
              name={`${optionName}.price`}
              defaultValue={option?.price || 0}
              rules={{ required: true }}
            />
            {!!optionErrors?.price && <FormError>{optionErrors?.price?.message || 'Price is required'}</FormError>}
          </InputContainer>
        </CustomizationFormOptionsContainer>
        {!!rulesToShow.length && (
          <CustomizationFormOptionsContainer>
            <InputContainer flex={1}>
              <FormLabel>Rule</FormLabel>
              <CustomizationInputContainer>
                <Controller
                  render={({ onChange, value }) => (
                    <FormSelect error={!!optionErrors?.rules?.[0]?.type} height={38} fontSize={16} onChange={onChange} value={value || 'none'} width={160}>
                      {rulesToShow.map((rule, index) => (
                        <option key={index} value={CustomizationRules[rule]}>
                          {CustomizationRulesTitles[rule]}
                        </option>
                      ))}
                    </FormSelect>
                  )}
                  control={control}
                  name={`${optionName}.rules.0.type`}
                  defaultValue={option?.rules?.[0]?.type || 'none'}
                  rules={{ required: true }}
                />
                {!!optionErrors?.rules?.[0]?.type && <FormError>{optionErrors?.rules?.[0]?.type?.message || 'Rule is required'}</FormError>}
                {watchedRules?.[0]?.type && (
                  <>
                    {watchedRules?.[0]?.type === CustomizationRules.DURATION && (
                      <>
                        <FormLabel marginBottom={0} bold>
                          Extend the duration by (min)
                        </FormLabel>
                        <Controller
                          as={<FormInput type={'number'} error={!!optionErrors?.rules?.[0]?.value} height={20} fontSize={16} width={40} />}
                          control={control}
                          name={`${optionName}.rules.0.value`}
                          defaultValue={option?.rules?.[0]?.value || 0}
                          rules={{ required: true }}
                        />
                        {!!optionErrors?.rules?.[0]?.value && <FormError>{optionErrors?.rules?.[0]?.value?.message || 'Value is required'}</FormError>}
                      </>
                    )}
                    {watchedRules?.[0]?.type === CustomizationRules.ASSIGN && (
                      <>
                        <FormLabel marginBottom={0} bold>
                          Assign to
                        </FormLabel>
                        <Controller
                          render={({ onChange, value }) => (
                            <ReactSelect
                              theme={selectTheme}
                              onChange={newValue => {
                                onChange([...(newValue || [])]?.map(busUser => busUser?.id));
                              }}
                              isMulti
                              value={[...(value || [])]?.map(id => busUsers.filter(busUser => busUser.id === id)?.[0])}
                              options={busUsers}
                              getOptionValue={busUser => busUser?.id || ''}
                              getOptionLabel={busUser => busUser?.name || ''}
                              name={`${optionName}.rules.0.value`}
                            />
                          )}
                          control={control}
                          name={`${optionName}.rules.0.value`}
                          defaultValue={option?.rules?.[0]?.value || []}
                          rules={{ required: true, validate: value => value?.length || 'Value is required' }}
                        />

                        {!!optionErrors?.rules?.[0]?.value && <FormError>{optionErrors?.rules?.[0]?.value?.message || 'Value is required'}</FormError>}
                      </>
                    )}
                    {watchedRules?.[0]?.type === CustomizationRules.CREATE_VOUCHER && (
                      <>
                        <FormLabel marginBottom={0} bold>
                          Create voucher with details
                        </FormLabel>
                        <Modal
                          title="Add Voucher"
                          modalContent={props => (
                            <VoucherModal
                              onSubmit={voucher => {
                                setValue(`${optionName}.rules.0.value`, {
                                  auto_apply: !!(String(voucher.auto_apply) === 'true'),
                                  discountId: voucher.discountId,
                                  limit: Number(voucher.limit),
                                  period: {
                                    start: voucher.period?.start,
                                    end: voucher.period?.end,
                                    createdAt_duration: voucher.period?.createdAt_duration ? convertDurationToMinutes(voucher.period?.createdAt_duration) : null
                                  },
                                  user_limit: Number(voucher.user_limit),
                                  productId: voucher.products
                                });
                                requestAnimationFrame(() => vouchersModalRef.current?.closeModal());
                              }}
                              voucher={{
                                ...(props?.voucher || defaultValue?.rules?.[0]?.value || null),
                                products: props?.voucher?.productId || (defaultValue?.rules?.[0]?.value as Record<string, any>)?.productId || []
                              }}
                              otherVouchers={props?.otherVouchers}
                              disableAppUserSelect
                              disableCodeGeneration
                              disablePeriodSelect
                            />
                          )}
                          ref={vouchersModalRef}
                          noButton
                        />
                        <FormLinkButtonStyled onClick={onClickVoucher}>Customize</FormLinkButtonStyled>
                        <Controller
                          render={({ onChange, value }) => (
                            <FormInput type={'hidden'} error={!!optionErrors?.rules?.[0]?.value} height={20} fontSize={16} flexOne onChange={onChange} value={value || ''} />
                          )}
                          control={control}
                          name={`${optionName}.rules.0.value`}
                          defaultValue={option?.rules?.[0]?.value || ''}
                          rules={{ validate: value => value || 'Voucher is required' }}
                        />

                        {!!optionErrors?.rules?.[0]?.value && <FormError>{optionErrors?.rules?.[0]?.value?.message || 'Value is required'}</FormError>}
                      </>
                    )}
                    {watchedRules?.[0]?.type === CustomizationRules.TAG_APPOINTMENTS && (
                      <>
                        <FormLabel marginBottom={0} bold>
                          Tag appointments with
                        </FormLabel>
                        <Modal
                          title="Tag Appointments"
                          modalContent={props => (
                            <BranchTagItemModal
                              existingTagsItems={(props?.selectedTags?.tags || defaultValue?.rules?.[0]?.value?.tags || []).map((tag: string) => ({ id: tag })) || []}
                              onSubmit={({ selectedTags }) => {
                                setValue(`${optionName}.rules.0.value`, {
                                  tags: selectedTags?.map(({ value }) => value) || []
                                });
                                requestAnimationFrame(() => tagsModalRef.current?.closeModal());
                              }}
                              type={BRANCH_TAGS_TYPES.APPOINTMENT}
                              loading={false}
                              isCreatable={false}
                            />
                          )}
                          ref={tagsModalRef}
                          noButton
                        />
                        <FormLinkButtonStyled onClick={onClickTags}>Customize</FormLinkButtonStyled>
                        <Controller
                          render={({ onChange, value }) => (
                            <FormInput type={'hidden'} error={!!optionErrors?.rules?.[0]?.value} height={20} fontSize={16} flexOne onChange={onChange} value={value || ''} />
                          )}
                          control={control}
                          name={`${optionName}.rules.0.value`}
                          defaultValue={option?.rules?.[0]?.value || ''}
                          rules={{ required: true }}
                        />
                        {!!optionErrors?.rules?.[0]?.value && <FormError>{optionErrors?.rules?.[0]?.value?.message || 'Value is required'}</FormError>}
                      </>
                    )}
                  </>
                )}
              </CustomizationInputContainer>
            </InputContainer>
          </CustomizationFormOptionsContainer>
        )}
        <CustomizationFormOptionsContainer>
          <InputContainer flex={1}>
            <FormLabel>Visual Preview</FormLabel>
            <CustomizationInputContainer>
              <Controller
                render={({ onChange, value }) => (
                  <FormSelect
                    error={!!optionErrors?.meta?.type}
                    height={38}
                    fontSize={16}
                    onChange={e => {
                      setValue(`${optionName}.meta.value`, '');
                      onChange(e);
                    }}
                    value={value || 'none'}
                    width={160}
                  >
                    <option value="hex">Colour</option>
                    <option value="url">Image</option>
                    <option value="none">None</option>
                  </FormSelect>
                )}
                control={control}
                name={`${optionName}.meta.type`}
                defaultValue={option?.meta?.type || 'none'}
                rules={{ required: true }}
              />
              {!!optionErrors?.meta?.type && <FormError>{optionErrors?.meta?.type?.message || 'Preview is required'}</FormError>}
              {watchedMeta?.type && watchedMeta?.type !== 'none' && (
                <>
                  <Controller
                    render={({ onChange, value }) => (
                      <>
                        {watchedMeta.type === 'url' && <FormInput type={'text'} error={!!optionErrors?.meta?.value} height={20} fontSize={16} flexOne onChange={onChange} value={value || ''} />}
                        {watchedMeta.type === 'hex' && <ColorPicker type={'color'} error={optionErrors?.meta?.value?.message} width={120} autoHeight onChange={onChange} value={value || ''} />}
                      </>
                    )}
                    control={control}
                    name={`${optionName}.meta.value`}
                    defaultValue={option?.meta?.value || ''}
                    rules={
                      watchedMeta.type === 'url'
                        ? {
                            required: true,
                            pattern: {
                              value: isValidURL,
                              message: 'Invalid URL'
                            }
                          }
                        : { required: watchedMeta.type !== 'none' }
                    }
                  />
                  {optionErrors?.meta?.value && <FormError>{optionErrors?.meta?.value?.message || 'Preview is required'}</FormError>}
                </>
              )}
            </CustomizationInputContainer>
          </InputContainer>
        </CustomizationFormOptionsContainer>
      </CustomizationFormOptionsContainer>
    </CustomizationFormOptionsWrapper>
  );
};

export default CustomizationFormOptions;
