import RRule from 'rrule';
import { getKeys } from '../../utils/helpers';

type Customization = {
  available: number;
  time?: string;
  days?: string[];
};

type Customizations = Customization[];

const transformOperatingSlotsState = (formState: { [key: string]: any }): Customizations => {
  if (formState.booking_type === 'MULTI_DAY') {
    const timeRRule = new RRule({
      freq: RRule.HOURLY,
      byhour: [12],
      byminute: [0],
      bysecond: [0]
    });

    return Array(7)
      .fill(0)
      .map(() => ({
        available: +formState?.slots,
        time: timeRRule.toString()
      }));
  }

  if (formState.booking_type === 'OTHER') {
    const time = formState.time.split(':');
    const localTimeHour = time[0];
    const rrule = new RRule({
      freq: RRule[formState.frequency],
      interval: +formState.interval,
      count: +formState.count || undefined,
      byhour: [+localTimeHour],
      byminute: [+time[1]],
      bysecond: [0],
      byweekday: formState.selectedDays.map((day: any) => (+formState?.nth?.[day] ? RRule[day].nth(+formState.nth[day]) : RRule[day]))
    });
    return [{ time: rrule.toString(), available: formState.slots }];
  }
  const keys = getKeys(formState);
  return keys
    .filter((key: string) => key.includes('custom_type_operating_'))
    .reduce((customizations, parentKey) => {
      if (!parentKey.includes('days')) {
        return customizations;
      }
      if (formState[parentKey.replace('days', 'time')] === '') {
        return customizations;
      }
      const days = formState[parentKey];
      const available = formState[parentKey.replace('days', 'slots')];
      const time = formState[parentKey.replace('days', 'time')].split(':');
      const localTimeHour = time[0];

      const timeRRule = new RRule({
        freq: RRule.WEEKLY,
        interval: 1,
        byhour: [+localTimeHour],
        byminute: [+time[1]],
        bysecond: [0],
        byweekday: days.map((day: any) => RRule[day])
      });

      return [
        ...customizations,
        {
          available,
          time: timeRRule.toString(),
          hour: localTimeHour
        }
      ];
    }, [])
    .sort((slotA, slotB) => slotA.hour - slotB.hour)
    .map(slot => {
      const rrule = RRule.fromString(slot.time || '');
      return {
        available: slot.available,
        time: slot.time
      };
    });
};

export default transformOperatingSlotsState;
