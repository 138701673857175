import { Branch } from '../../../components/Profile/types';
import { getKeys } from '../../../utils/helpers';
import {
  BRANCH_BILLING_FILTERS,
  BRANCH_BILLING_FILTERS_SECONDARY_TOTALS,
  BRANCH_BILLING_FILTERS_TOTALS,
  BRANCH_BILLING_INVOICES_FILTERS,
  BRANCH_BILLING_SUBSCRIPTIONS_FILTERS,
  BRANCH_BILLING_SUBSCRIPTION_INVOICES_FILTERS,
  CurrentPeriod
} from './types';

export const getCountForSelectedFilter = ({
  counts,
  selectedFilter,
  useSecondaryTotals = false
}: {
  counts: NonNullable<Branch['branchCounts']>;
  selectedFilter: BRANCH_BILLING_FILTERS | BRANCH_BILLING_INVOICES_FILTERS | BRANCH_BILLING_SUBSCRIPTIONS_FILTERS | BRANCH_BILLING_SUBSCRIPTION_INVOICES_FILTERS;
  useSecondaryTotals?: boolean;
}) => {
  const countKeys = getKeys(counts);
  const totals = BRANCH_BILLING_FILTERS_TOTALS[selectedFilter];
  const secondaryTotals = BRANCH_BILLING_FILTERS_SECONDARY_TOTALS[selectedFilter];
  const count = countKeys.reduce((acc, key) => {
    if ((useSecondaryTotals ? secondaryTotals : totals).includes(key)) {
      return acc + Number(Number(counts[key]).toFixed(2));
    }
    return acc;
  }, 0);

  return count;
};

export const getCurrentBillingFilters = (currentPeriod: CurrentPeriod) => {
  const hasSelectedPastBillingPeriod = !!currentPeriod.end && new Date(currentPeriod.end).getTime() < new Date().getTime();

  const currentSubscriptionFilter = hasSelectedPastBillingPeriod
    ? [BRANCH_BILLING_SUBSCRIPTIONS_FILTERS.SUBSCRIPTIONS, BRANCH_BILLING_SUBSCRIPTIONS_FILTERS.SUBSCRIPTIONS_ORDER_TOTAL]
    : [BRANCH_BILLING_SUBSCRIPTIONS_FILTERS.SUBSCRIPTIONS];

  const filtersWithoutTotal = [...Object.values(BRANCH_BILLING_FILTERS)].filter(filter => filter !== BRANCH_BILLING_FILTERS.ORDERS_TOTAL);

  const filtersWithoutInvoices = [...filtersWithoutTotal, BRANCH_BILLING_SUBSCRIPTIONS_FILTERS.SUBSCRIPTIONS, BRANCH_BILLING_FILTERS.ORDERS_TOTAL];

  const invoicesFilters = Object.values(BRANCH_BILLING_INVOICES_FILTERS);
  const subscriptionsFilters = Object.values(BRANCH_BILLING_SUBSCRIPTIONS_FILTERS).filter(filter => !currentSubscriptionFilter.includes(filter));
  const subscriptionInvoiceFilters = Object.values(BRANCH_BILLING_SUBSCRIPTION_INVOICES_FILTERS);

  return { filtersWithoutInvoices, invoicesFilters, subscriptionsFilters, subscriptionInvoiceFilters };
};
