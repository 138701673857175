import React from 'react';
import { Controller } from 'react-hook-form';
import MarkupEditor from '../../../components/Shared/MarkupEditor';
import { Control } from 'react-hook-form';
import { RequireAtLeastOne } from '../../../utils/helpers';

const NotesMarkupEditor = ({
  defaultValue,
  control,
  name,
  keyName = 'details'
}: RequireAtLeastOne<
  {
    defaultValue: {
      details_html: string;
      details: string;
    };
    control: Control;
    name?: string;
    keyName?: string;
  },
  'keyName' | 'name'
>) => {
  const { setValue } = control;
  const combinedName = name ? `${name}.${keyName}` : keyName;
  const combinedNameHTML = name ? `${name}.${keyName}_html` : `${keyName}_html`;

  return (
    <>
      <Controller
        render={({ onChange }) => (
          <MarkupEditor
            onChange={({ text, html }) => {
              onChange(text);
              setValue(combinedNameHTML, html);
            }}
            defaultValue={defaultValue?.details_html || defaultValue?.details || ''}
          />
        )}
        control={control}
        name={combinedName}
        defaultValue={defaultValue?.details || ''}
      />
      <Controller control={control} name={combinedNameHTML} defaultValue={defaultValue?.details_html || defaultValue?.details || ''} as={<></>} />
    </>
  );
};

export default NotesMarkupEditor;
