import React, { FC, Fragment, useCallback, useEffect, useImperativeHandle, useRef } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import EmptyList from '../EmptyList';
import { CenteredLoader, LineLoader, VerticallyCenteredLoader } from '../Spinner';
import { LineLoaderContainer, ListContainer, ListSeparator, LoadersContainer } from './styled';

export type InfiniteListActions = { scrollToEnd: () => void };
export type InfiniteListGroup = {
  by: (item: itemType['0']) => string;
  separator?: (seperatedItems: itemType, groupLabel: string, index: number) => JSX.Element;
  modifiedSeperatedItems?: (seperatedItems: any[], key: string, index: number) => any[];
};
interface InifiniteListProps {
  list: any[];
  itemRenderer: (item: any) => JSX.Element;
  loading: boolean;
  hasMoreItems: boolean;
  group?: InfiniteListGroup;
  inversed?: boolean;
  fetchMore: Function;
  offset: number;
  setHasMoreItems: React.Dispatch<React.SetStateAction<boolean>>;
  emptyListProps?: { dimensions?: { width?: string; height?: string }; text?: string };
  ListHeader?: JSX.Element;
  ListFooter?: JSX.Element;
  listContainerStyles?: React.CSSProperties;
  loaderSize?: number;
  noEmptyList?: boolean;
  reversedItems?: boolean;
  listActionsRef?: React.MutableRefObject<InfiniteListActions>;
  noLineLoader?: boolean;
  type?: 'offset_limit' | 'cursor';
  mobilePadding?: string;
}

type itemType = any[];

const InfiniteList: FC<InifiniteListProps> = ({
  list = [],
  itemRenderer,
  loading,
  hasMoreItems,
  offset = 0,
  setHasMoreItems,
  fetchMore,
  group,
  inversed,
  emptyListProps,
  ListHeader,
  ListFooter,
  listContainerStyles,
  loaderSize = 35,
  noEmptyList = false,
  reversedItems = false,
  listActionsRef,
  noLineLoader = false,
  type = 'offset_limit',
  mobilePadding
}) => {
  const listRef = useRef<HTMLUListElement>(null);

  const paginate = useCallback(() => {
    if (loading || !hasMoreItems) {
      return;
    }

    const typeToFetchVariables = {
      offset,
      ...(type === 'cursor' ? { starting_after: list?.length ? list[list.length - 1]?.id : undefined } : {})
    };

    fetchMore({
      variables: typeToFetchVariables
    })
      .then(fetchMoreResult => {
        setHasMoreItems(false);

        if (Object.values(fetchMoreResult?.data as itemType)?.[0]?.length) {
          setHasMoreItems(true);
        }
      })
      .finally(() => {
        scrollInverse();
      });
  }, [list, fetchMore, loading, hasMoreItems, offset, setHasMoreItems]);

  const startingOffset = offset === 0;
  const firstFetch = loading && startingOffset;
  const showFirstLoader = (loading && !list.length) || firstFetch;
  const scrollToEnd = () => {
    const listContainer = listRef.current;
    listContainer?.scrollTo(0, reversedItems ? listContainer.scrollHeight : 0);
  };

  useEffect(() => {
    if (inversed) {
      scrollToEnd();
      return () => {
        scrollToEnd();
      };
    }
  }, [inversed]);

  useImperativeHandle(listActionsRef, () => ({
    scrollToEnd
  }));

  const scrollInverse = () => {
    if (inversed) {
      const listContainer = listRef.current;
      listContainer?.scrollBy(0, 0);
    }
  };

  const listToRender = reversedItems ? [...list].reverse() : list;

  const renderItems = useCallback(() => {
    if (firstFetch) {
      return <></>;
    }
    if (group?.by) {
      const { by, separator, modifiedSeperatedItems } = group;
      const groupedItems = listToRender.reduce((acc: any, item: itemType) => {
        const key = by(item);
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(item);
        return acc;
      }, {});

      return Object.keys(groupedItems).map((key: string, index: number) => {
        const seperatedItems: any[] = groupedItems[key];
        const newSeperatedItems = modifiedSeperatedItems?.(seperatedItems, key, index) || seperatedItems;
        return (
          <Fragment key={key}>
            {separator && <ListSeparator>{separator(seperatedItems, key, index)}</ListSeparator>}
            {newSeperatedItems.map((item: itemType) => itemRenderer(item))}
          </Fragment>
        );
      });
    }

    return listToRender.map((item: itemType, index) => <Fragment key={index}>{itemRenderer(item)}</Fragment>);
  }, [listToRender, group, itemRenderer, firstFetch]);

  return (
    <ListContainer style={listContainerStyles} ref={listRef} inversed={inversed} mobilePadding={mobilePadding}>
      {ListHeader}

      {!loading && !list.length && !noEmptyList && !firstFetch && (
        <EmptyList dimensions={emptyListProps?.dimensions} message={emptyListProps?.text ? '' : 'you have no entries at the moment.'} loading={loading} title={emptyListProps?.text} />
      )}

      {renderItems()}
      <LoadersContainer>
        {hasMoreItems && !!list.length && !startingOffset && (
          <VisibilitySensor onChange={(isVisible: boolean) => isVisible && paginate()} partialVisibility={true} scrollCheck={true} scrollDelay={0} scrollThrottle={0}>
            <VerticallyCenteredLoader size={loaderSize} />
          </VisibilitySensor>
        )}
        {showFirstLoader && <CenteredLoader size={60} />}
        {!noLineLoader && (
          <LineLoaderContainer>
            <LineLoader active={firstFetch} loadingContent={loading} size={4} startingLoaderWidth={50} />
          </LineLoaderContainer>
        )}
      </LoadersContainer>
      {ListFooter}
    </ListContainer>
  );
};

export default InfiniteList;
