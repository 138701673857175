import React, { useRef } from 'react';
import Colors from '../../../../Colors';
import ModalDialog from '../../../../components/Modal/ModalDialog';
import { ExtensionDivider, ExtensionPetCaseLabel, ExtensionWideColumn, ExtensionWideColumnInfo, FlexContainer, Note } from '../../../../components/Pets/styled';
import BranchNotes from '../../../../components/Shared/BranchNotes/BranchNotes';
import { BranchNoteType, BranchNotesRef } from '../../../../components/Shared/BranchNotes/types';
import InfiniteList from '../../../../components/Shared/InfiniteList/InfiniteList';
import OptionDropdown from '../../../../components/Shared/Menus/OptionDropdown/OptionDropdown';
import { OPTION_DROPDOWN_MENU_BUTTON_TYPES, OPTION_DROPDOWN_TYPES } from '../../../../components/Shared/Menus/OptionDropdown/types';
import { CenteredLoader } from '../../../../components/Shared/Spinner';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import usePaginatedQuery from '../../../../hooks/usePaginatedQuery';
import { GetNotesByTrainingReportId } from '../../../../queries';
import { toReadableDate } from '../../../../utils/dates';
import { getKeys } from '../../../../utils/helpers';
import { DeselectReportButton, MedicalCaseDetailsContainer } from '../Medical/styled';
import TrainingReportAction from './TrainingReportAction';
import TrainingReportNote from './TrainingReportNote';
import TrainingReportNoteAction, { NOTE_ACTION_TYPES } from './TrainingReportNoteAction';

enum OptionDropdownItems {
  EDIT_CASE = 'Edit Case',
  ADD_NOTE = 'Add Note',
  ADD_PRIVATE_NOTE = 'Add Private Note'
}
const TrainingDetails = ({
  innerRef,
  selectedReport,
  trainingReports,
  deselectReport,
  hideNotes,
  fullWidth = false,
  loading
}: {
  innerRef?: React.Ref<HTMLDivElement>;
  selectedReport: Record<string, any>;
  trainingReports: Record<string, any>;
  deselectReport?: () => void;
  hideNotes?: boolean;
  fullWidth?: boolean;
  loading?: boolean;
}) => {
  const { tablet } = useMediaQuery({ tablet: true });

  const [[hasMoreItems, setHasMoreItems], { data: { getNotesByTrainingReportId: notes = [] } = {}, loading: notesLoading, refetch, fetchMore }] = usePaginatedQuery<any>({
    query: GetNotesByTrainingReportId,
    limit: 2,
    otherVariables: { trainingReportId: [selectedReport?.id] },
    otherParams: { skip: !selectedReport?.id || hideNotes }
  });

  const branchNotesRef = useRef<BranchNotesRef>(null);

  const { addNote: addBranchNote } = branchNotesRef?.current || {};

  const sharedOptionDropdownItems = {
    [OptionDropdownItems.ADD_NOTE]: () =>
      ModalDialog.openModal({ content: () => <TrainingReportNoteAction type={NOTE_ACTION_TYPES.ADD} selectedReport={selectedReport} refetchNotes={refetch} autoOpenModal /> }),
    [OptionDropdownItems.ADD_PRIVATE_NOTE]: () => addBranchNote?.()
  };

  const optionDropdownItems = {
    [OptionDropdownItems.EDIT_CASE]: () =>
      ModalDialog.openModal({
        content: () => <TrainingReportAction selectedReport={selectedReport} type={NOTE_ACTION_TYPES.EDIT} autoOpenModal />
      }),
    ...sharedOptionDropdownItems
  };
  return (
    <ExtensionWideColumn ref={innerRef} active={!!selectedReport} noTextOverFlow={!!hideNotes} fullWidth={fullWidth}>
      {!trainingReports.length && loading && <CenteredLoader size={70} />}
      {!!trainingReports.length && selectedReport && (
        <InfiniteList
          list={notes}
          itemRenderer={(note: Record<string, any>) => <TrainingReportNote key={note?.id} note={note} selectedReport={selectedReport} />}
          loading={notesLoading}
          hasMoreItems={hasMoreItems}
          fetchMore={fetchMore}
          refetch={refetch}
          offset={notes.length}
          setHasMoreItems={setHasMoreItems}
          emptyListProps={{ dimensions: { width: '100%', height: 'auto' } }}
          noEmptyList={hideNotes}
          ListHeader={
            <>
              <ExtensionPetCaseLabel fontSize={20} fontWeight={'900'} margingB={8}>
                {tablet && <DeselectReportButton onClick={deselectReport}>{'<'}</DeselectReportButton>}
                {!tablet && selectedReport?.name}
                {tablet && 'Reports'}
                {!hideNotes && !tablet && <TrainingReportAction type={NOTE_ACTION_TYPES.EDIT} selectedReport={selectedReport} />}
                {tablet && (
                  <OptionDropdown
                    menuButtonType={OPTION_DROPDOWN_MENU_BUTTON_TYPES.MENU}
                    options={[
                      {
                        optionType: OPTION_DROPDOWN_TYPES.BUTTONS,
                        id: 'OPTIONS',
                        items: Object.keys(optionDropdownItems).map(key => ({
                          name: key,
                          value: key,
                          onClick: optionDropdownItems[key as OptionDropdownItems]
                        }))
                      }
                    ]}
                    noApplyButton
                  />
                )}
              </ExtensionPetCaseLabel>
              <ExtensionDivider />
              <ExtensionWideColumnInfo>
                {tablet && (
                  <>
                    <FlexContainer>
                      <ExtensionPetCaseLabel fontSize={20} fontWeight={'900'} margingB={8}>
                        {selectedReport?.name}
                      </ExtensionPetCaseLabel>
                    </FlexContainer>
                    <ExtensionDivider />
                  </>
                )}

                <FlexContainer>
                  <ExtensionPetCaseLabel fontSize={16} width={150} fontWeight={'bold'} margingB={8}>
                    Trainer:
                  </ExtensionPetCaseLabel>
                  <ExtensionPetCaseLabel fontSize={16} fontWeight={'500'}>
                    {selectedReport?.Branch?.name}
                  </ExtensionPetCaseLabel>
                </FlexContainer>
                {selectedReport?.Appointment?.id && (
                  <FlexContainer>
                    <ExtensionPetCaseLabel fontSize={16} width={150} fontWeight={'bold'}>
                      Appointment:
                    </ExtensionPetCaseLabel>
                    <ExtensionPetCaseLabel fontSize={16} fontWeight={'500'}>
                      {selectedReport.Appointment?.OrderItem?.item?.name} - {toReadableDate(selectedReport.Appointment?.timestamp || new Date(), { isLocale: true })}
                    </ExtensionPetCaseLabel>
                  </FlexContainer>
                )}
              </ExtensionWideColumnInfo>

              <ExtensionDivider />
              <ExtensionPetCaseLabel margingB={24} fontSize={18} fontWeight={'800'} primary>
                Description
              </ExtensionPetCaseLabel>
              <Note>{selectedReport?.description}</Note>
              <ExtensionDivider />
              {!hideNotes && (
                <ExtensionPetCaseLabel margingB={24} fontSize={18} fontWeight={'800'} primary>
                  Notes
                  {!tablet && (
                    <OptionDropdown
                      menuButtonType={OPTION_DROPDOWN_MENU_BUTTON_TYPES.PLUS}
                      options={[
                        {
                          optionType: OPTION_DROPDOWN_TYPES.BUTTONS,
                          id: 'OPTIONS',
                          items: getKeys(sharedOptionDropdownItems).map(key => ({
                            name: key,
                            value: key,
                            onClick: sharedOptionDropdownItems[key]
                          }))
                        }
                      ]}
                      noApplyButton
                      containerRelative
                    />
                  )}
                </ExtensionPetCaseLabel>
              )}
              {selectedReport?.id && (
                <BranchNotes
                  type={BranchNoteType.TRAINING_REPORT}
                  TrainingReportId={selectedReport?.id}
                  options={{
                    busUserName: true,
                    timestamp: true,
                    expandable: false,
                    hideHeader: true,
                    deletable: true,
                    bottomDivider: false,
                    privateTag: true,
                    html: true,
                    cancelButton: true
                  }}
                  ref={branchNotesRef}
                  styles={{
                    containerStyle: () => ({ backgroundColor: Colors.white, padding: 0 }),
                    itemStyle: () => ({ backgroundColor: '#f0f0f0', marginTop: 10, borderRadius: 20, padding: 20, boxSizing: 'border-box', border: '2px solid #e0e0e0' })
                  }}
                />
              )}
            </>
          }
        />
      )}

      {!selectedReport && !loading && (
        <MedicalCaseDetailsContainer>
          <ExtensionPetCaseLabel fontSize={22} fontWeight={'900'} margingB={8}>
            Please select a report to view details.
          </ExtensionPetCaseLabel>
        </MedicalCaseDetailsContainer>
      )}
    </ExtensionWideColumn>
  );
};

export default TrainingDetails;
