import React, { useRef } from 'react';
import { OrderInvoice } from '../../components/OrderInvoices/types';
import { addDrawerBar } from '../../reactive/actions';
import { getBranchCurrencySymbol } from '../../utils/getBranchCurrencySymbol';
import { RecordBody } from '../../views/styled';
import { DRAWER_IDS } from '../DrawerBar/types';
import OrderInvoiceOptions from '../OrderInvoices/OrderInvoiceOptions';
import { getOrderInvoiceTitles } from '../OrderInvoices/utils';
import { BillingStatus, BookingListItem, BookingsDivider, UserBookingContainer } from './styled';

const UserOrderInvoiceRecord = ({ invoice, refetch }: { invoice: OrderInvoice; refetch: () => void }) => {
  const { id, amount } = invoice;
  const { isCanceled, isFree, isBilled, isRefunded, isRejected, isPending, creationDate, statusTitle, orderInvoiceNumber, isUnbilled } = getOrderInvoiceTitles(invoice);
  const optionsRef = useRef<HTMLDivElement>(null);

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (optionsRef?.current?.contains(e.target as Node)) {
      return;
    }
    addDrawerBar({ drawerId: DRAWER_IDS.ORDER_INVOICE_DRAWER, recordData: id, isFloating: true, drawerWidths: { normal: 800, small: 800 } });
  };

  return (
    <BookingListItem key={id}>
      <UserBookingContainer onClick={handleClick} clickable>
        <RecordBody width="150" fontWeight={'800'}>
          {orderInvoiceNumber || '-'}
        </RecordBody>
        <RecordBody width="80" fontWeight={'800'}>
          {creationDate}
        </RecordBody>
        <RecordBody width="150" fontWeight={'600'}>
          {isFree ? 'Free' : `${getBranchCurrencySymbol()}${amount}`}
        </RecordBody>
        <RecordBody width="150" fontWeight="800" flexEnd noMargin>
          <BillingStatus noMaxWidth blue={isFree || isPending} gray={isCanceled} green={isBilled} red={isRefunded || isRejected || isUnbilled}>
            {statusTitle}
          </BillingStatus>
        </RecordBody>
        <OrderInvoiceOptions orderInvoice={invoice} refetchOrderInvoices={refetch} ref={optionsRef} />
      </UserBookingContainer>
      <BookingsDivider />
    </BookingListItem>
  );
};

export default UserOrderInvoiceRecord;
