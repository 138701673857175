import React from 'react';
import { vars } from '../../../reactive';
import { isUserSuperVisorOrAdminOrSuper } from '../../../utils/helpers';
import { Booking, CalendarEvents } from '../../../views/Bookings/types';
import { BusUserProfile } from '../../Profile/types';
import SlotAppointment from '../SlotCalendar/SlotAppointment';
import { DayColumn, DayDiv, HourDiv, MultiStaffDaysHeaderContainer } from '../styled';

export const MultiStaffDaysHeader = ({ BusUsers }: { BusUsers: Booking['BusUsers'] }) => {
  return (
    <MultiStaffDaysHeaderContainer>
      <DayColumn maxWidth={120} minWidth={120} mobileMaxWidth={64} mobileMinWidth={64}>
        <DayDiv multiStaff>Time</DayDiv>
      </DayColumn>
      {BusUsers?.map?.((user, i) => (
        <DayColumn key={i} minWidth={180}>
          <DayDiv multiStaff>{user.name}</DayDiv>
        </DayColumn>
      ))}
    </MultiStaffDaysHeaderContainer>
  );
};

const MultiStaffCalendar = ({
  BusUsers,
  weekDays,
  bookingCardColors,
  handleBookingClick,
  profile,
  bookingsToRender,
  user,
  userIndex,
  mediumSize
}: {
  BusUsers: Booking['BusUsers'];
  weekDays: Date[];
  bookingCardColors: string[];
  handleBookingClick: ({ appointmentsIds, group }: { appointmentsIds?: string[]; group?: boolean }) => void;
  profile: BusUserProfile;
  bookingsToRender?: CalendarEvents;
  user: Booking['BusUsers'][0];
  userIndex: number;
  mediumSize?: boolean;
}) => {
  const day = weekDays[0];
  const userEvents = bookingsToRender?.singleDayAppointmentsViews?.[0]?.views || [];

  return (
    <DayColumn minWidth={180}>
      {new Array(25).fill('').map((_, houridx) => (
        <HourDiv key={houridx} onClick={() => vars.selectedDate({ date: day, hour: houridx - 1 })} mediumSize={mediumSize} renderBorders />
      ))}

      {userEvents.map((event, i) => {
        const color = bookingCardColors[(userIndex + i) % bookingCardColors.length];
        const assignedTo = isUserSuperVisorOrAdminOrSuper(profile) ? event.BusUsers?.map?.(user => user.name).join(', ') : 'You & Others';
        const isLast = userIndex === BusUsers.length - 1;
        return (
          <SlotAppointment
            key={event.appointmentsIds?.[0]}
            assignedTo={assignedTo}
            calendarEvent={event}
            color={color}
            dayIndex={userIndex}
            onBookingClick={handleBookingClick}
            isLast={isLast}
            mediumSize={mediumSize}
          />
        );
      })}
    </DayColumn>
  );
};

export default MultiStaffCalendar;
