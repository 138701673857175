import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import { OrderInvoice } from '../../components/OrderInvoices/types';
import useIcons from '../../hooks/useIcons';
import usePaginatedQuery from '../../hooks/usePaginatedQuery';
import { GetBranchOrderInvoices } from '../../queries';
import OrderInvoicesDownloadModal from '../../views/Store/BranchBilling/OrderInvoicesDownloadModal';
import { BranchAppUser } from '../../views/Store/BranchBilling/types';
import { BranchSummaryCountMode } from '../../views/Store/BranchSummary/styled';
import { ButtonsContainer } from '../BookingsDrawer/styled';
import ModalDialog from '../Modal/ModalDialog';
import InfiniteList from '../Shared/InfiniteList/InfiniteList';
import UserOrderInvoiceRecord from './UserOrderInvoiceRecord';
import { BookingsDivider, BookingsListCotainer, ListHeaderContainer, ListHeaderText, ListHeaderWrapper, ListView } from './styled';

const UserInvoices = ({ userProfile }: { userProfile: BranchAppUser }) => {
  const [[hasMoreBookings, setHasMoreBookings], queryResult] = usePaginatedQuery<OrderInvoice[]>({
    query: GetBranchOrderInvoices,
    limit: 50,
    otherVariables: {
      AppUserId: [userProfile.id]
    }
  });

  const { data: { getBranchOrderInvoices: orderInvoices = [] } = {}, loading: loadingOrderInvoices, fetchMore, refetch } = queryResult;

  const icons = useIcons();
  const exportIcon = icons?.export?.childImageSharp.gatsbyImageData;
  return (
    <ListView>
      <ListHeaderContainer>
        <ListHeaderWrapper>
          <ListHeaderText>Invoices</ListHeaderText>
          <ButtonsContainer style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
            <BranchSummaryCountMode
              style={{ display: 'flex', gap: 10, paddingBottom: 10 }}
              onClick={() =>
                ModalDialog.openModal({
                  content: () => (
                    <OrderInvoicesDownloadModal
                      initialValues={{
                        AppUserId: [userProfile.id]
                      }}
                    />
                  ),
                  title: 'Export Invoices'
                })
              }
            >
              <span>Export</span>
              <GatsbyImage image={exportIcon} alt="export" />
            </BranchSummaryCountMode>
          </ButtonsContainer>
        </ListHeaderWrapper>
        <BookingsDivider />
      </ListHeaderContainer>
      <BookingsListCotainer flexOne>
        <InfiniteList
          hasMoreItems={hasMoreBookings}
          fetchMore={fetchMore}
          loading={loadingOrderInvoices}
          setHasMoreItems={setHasMoreBookings}
          itemRenderer={(invoice: OrderInvoice) => <UserOrderInvoiceRecord invoice={invoice} refetch={refetch} />}
          list={orderInvoices}
          offset={orderInvoices?.length}
        />
      </BookingsListCotainer>
    </ListView>
  );
};

export default UserInvoices;
