import React, { FC, Fragment } from 'react';
import { ExtensionDivider, ExtensionPetCaseLabel, ExtensionPetCasesRecord } from '../../../../components/Pets/styled';
import { toReadableDate } from '../../../../utils/dates';

type MedicalCasesProps = {
  medicalCase: Record<string, any>;
  onClick: React.MouseEventHandler<HTMLDivElement>;
  selected: boolean;
};

const MedicalCase: FC<MedicalCasesProps> = ({ medicalCase, onClick, selected }) => {
  return (
    <Fragment key={medicalCase?.id}>
      <ExtensionPetCasesRecord selected={selected} onClick={onClick}>
        <ExtensionPetCaseLabel fontSize={16} fontWeight={'700'} margingB={8}>
          {medicalCase?.name}
        </ExtensionPetCaseLabel>
        <ExtensionPetCaseLabel fontSize={14} fontWeight={'600'} margingB={8}>
          {toReadableDate(medicalCase?.createdAt, { isLocale: true })}
        </ExtensionPetCaseLabel>
        <ExtensionPetCaseLabel fontSize={14} fontWeight={'600'} margingB={2}>
          {medicalCase?.Branch?.name}
        </ExtensionPetCaseLabel>
      </ExtensionPetCasesRecord>
      <ExtensionDivider />
    </Fragment>
  );
};

export default MedicalCase;
