import { useLazyQuery, useQuery, useReactiveVar } from '@apollo/client';
import React, { FC, Fragment, useCallback } from 'react';
import ModalDialog from '../../../components/Modal/ModalDialog';
import Filters from '../../../components/Shared/Filters/Filters';
import SearchBar from '../../../components/Shared/Filters/SearchBar';
import { FILTERS_ID, PETS_FILTER_TYPES, PETS_MORE_OPTIONS_TYPES } from '../../../components/Shared/Filters/config';
import useFilters from '../../../components/Shared/Filters/useFilters';
import OptionDropdown from '../../../components/Shared/Menus/OptionDropdown/OptionDropdown';
import { OPTION_DROPDOWN_MENU_BUTTON_TYPES, OPTION_DROPDOWN_TYPES } from '../../../components/Shared/Menus/OptionDropdown/types';
import { TabsHeaderContainer } from '../../../components/Shared/Shared';
import TabsHeader from '../../../components/Shared/TabsHeader';
import AppUserDetailsModal from '../../../components/UserDrawer/AppUserDetailsModal';
import PetDetailsModal from '../../../components/UserDrawer/PetDetailsModal';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { GetAllBreeds, GetAllProducts, GetBranchCategoryByName, GetBranchPetRecordTags, GetBusUserProfile, GetProductKinds, GetVaccRecordTypes } from '../../../queries';
import { vars } from '../../../reactive';
import { managePetsFilters } from '../../../reactive/actions';
import { WEEK_DAYS } from '../../../utils/dates';
import { areObjectsEqual, isUserSuperVisorOrAdminOrSuper } from '../../../utils/helpers';
import { BranchPetRecordTag } from '../../Store/BranchTags/types';
import { PETS_TABS_TYPES } from '../Pets';
import PetTagsQuickFilter from './PetTagsQuickFilter';

const PETS_FILTER_TYPES_NAMES: Record<PETS_FILTER_TYPES, string> = {
  [PETS_FILTER_TYPES.DAYCARE]: 'Daycare',
  [PETS_FILTER_TYPES.BREED]: 'Breed',
  [PETS_FILTER_TYPES.AGE]: 'Age',
  [PETS_FILTER_TYPES.NEUTERING]: 'Neutering',
  [PETS_FILTER_TYPES.VACCINATION]: 'Vaccination',
  [PETS_FILTER_TYPES.TREATMENT]: 'Treatment',
  [PETS_FILTER_TYPES.WEIGHT]: 'Weight',
  [PETS_FILTER_TYPES.SEARCH]: 'Search',
  [PETS_FILTER_TYPES.TAGS]: 'Tags',
  [PETS_FILTER_TYPES.BIRTHDATE]: 'Birthday',
  [PETS_FILTER_TYPES.QUICK_TAGS]: ''
};

const MORE_OPTIONS_TITLES: Record<PETS_MORE_OPTIONS_TYPES, string> = {
  [PETS_MORE_OPTIONS_TYPES.DUE]: 'Due',
  [PETS_MORE_OPTIONS_TYPES.DATE]: '',
  [PETS_MORE_OPTIONS_TYPES.VACC_TYPE]: 'Vaccination Type',
  [PETS_MORE_OPTIONS_TYPES.DAY_CARE]: 'Daycare',
  [PETS_MORE_OPTIONS_TYPES.DAY]: 'Day',
  [PETS_MORE_OPTIONS_TYPES.BREED]: 'Breed',
  [PETS_MORE_OPTIONS_TYPES.NEUTERED]: 'Neutered',
  [PETS_MORE_OPTIONS_TYPES.FROM_NUMBER]: 'From',
  [PETS_MORE_OPTIONS_TYPES.TO_NUMBER]: 'To',
  [PETS_MORE_OPTIONS_TYPES.TREATMENT_NAME]: 'Treatment Name',
  [PETS_MORE_OPTIONS_TYPES.TREATMENT_TYPE]: 'Treatment Type',
  [PETS_MORE_OPTIONS_TYPES.SEARCH]: '',
  [PETS_MORE_OPTIONS_TYPES.TAGS]: 'Tags',
  [PETS_MORE_OPTIONS_TYPES.BIRTHDATE]: 'Birthday'
};

const MORE_OPTIONS_DROPDOWN_TYPES: Record<PETS_MORE_OPTIONS_TYPES, OPTION_DROPDOWN_TYPES> = {
  [PETS_MORE_OPTIONS_TYPES.DUE]: OPTION_DROPDOWN_TYPES.SELECT,
  [PETS_MORE_OPTIONS_TYPES.DATE]: OPTION_DROPDOWN_TYPES.DATE,
  [PETS_MORE_OPTIONS_TYPES.VACC_TYPE]: OPTION_DROPDOWN_TYPES.MULTI_SELECT,
  [PETS_MORE_OPTIONS_TYPES.DAY_CARE]: OPTION_DROPDOWN_TYPES.MULTI_SELECT,
  [PETS_MORE_OPTIONS_TYPES.DAY]: OPTION_DROPDOWN_TYPES.MULTI_SELECT,
  [PETS_MORE_OPTIONS_TYPES.BREED]: OPTION_DROPDOWN_TYPES.MULTI_SELECT,
  [PETS_MORE_OPTIONS_TYPES.NEUTERED]: OPTION_DROPDOWN_TYPES.RADIO,
  [PETS_MORE_OPTIONS_TYPES.FROM_NUMBER]: OPTION_DROPDOWN_TYPES.NUMBER,
  [PETS_MORE_OPTIONS_TYPES.TO_NUMBER]: OPTION_DROPDOWN_TYPES.NUMBER,
  [PETS_MORE_OPTIONS_TYPES.TREATMENT_NAME]: OPTION_DROPDOWN_TYPES.MULTI_SELECT,
  [PETS_MORE_OPTIONS_TYPES.TREATMENT_TYPE]: OPTION_DROPDOWN_TYPES.MULTI_SELECT,
  [PETS_MORE_OPTIONS_TYPES.SEARCH]: OPTION_DROPDOWN_TYPES.TEXT_INPUT,
  [PETS_MORE_OPTIONS_TYPES.TAGS]: OPTION_DROPDOWN_TYPES.MULTI_SELECT,
  [PETS_MORE_OPTIONS_TYPES.BIRTHDATE]: OPTION_DROPDOWN_TYPES.SELECT
};

const PETS_FILTER_TYPES_UNITS: Record<PETS_FILTER_TYPES, string> = {
  [PETS_FILTER_TYPES.DAYCARE]: '',
  [PETS_FILTER_TYPES.BREED]: '',
  [PETS_FILTER_TYPES.AGE]: 'Years',
  [PETS_FILTER_TYPES.NEUTERING]: '',
  [PETS_FILTER_TYPES.VACCINATION]: '',
  [PETS_FILTER_TYPES.TREATMENT]: '',
  [PETS_FILTER_TYPES.WEIGHT]: 'kg',
  [PETS_FILTER_TYPES.SEARCH]: '',
  [PETS_FILTER_TYPES.TAGS]: '',
  [PETS_FILTER_TYPES.QUICK_TAGS]: '',
  [PETS_FILTER_TYPES.BIRTHDATE]: ''
};

type PetsHeaderProps = {
  selectedTabState: [PETS_TABS_TYPES, React.Dispatch<React.SetStateAction<PETS_TABS_TYPES>>];
  tabs: PETS_TABS_TYPES[];
  showFilters: boolean;
};

const PetsHeader: FC<PetsHeaderProps> = ({ selectedTabState, tabs, showFilters }) => {
  const { addFilter, addHeaderAction, clearHeaderAction, removeFilter, setNewVisibleFilters } = managePetsFilters;
  const [selected, setSelected] = selectedTabState;
  const { mobile } = useMediaQuery({ mobile: true });

  const [getVacc, { data: { vaccRecordTypeGet: vaccRecordTypes = [] } = {}, called: calledVacc }] = useLazyQuery(GetVaccRecordTypes);
  const [getProductKinds, { data: { productKindGet: productKinds = [] } = {}, called: calledProductKinds }] = useLazyQuery(GetProductKinds);
  const [getBreeds, { data: { getBreeds: breeds = [] } = {}, called: calledBreeds }] = useLazyQuery(GetAllBreeds);
  const [getBranchCategories, { data: { branchCategoryGet: [{ Branches: dayCares = [] } = {}] = [] } = {}, called: calledBranchCategories }] = useLazyQuery(GetBranchCategoryByName, {
    variables: {
      name: 'Daycare'
    }
  });
  const [getProducts, { data: { productGet: allProducts = [] } = {}, called: calledProducts }] = useLazyQuery(GetAllProducts);

  const [getAllTags, { data: { getBranchPetRecordTags: allTags = [] } = {}, called: calledTags }] = useLazyQuery<{ getBranchPetRecordTags: BranchPetRecordTag[] }>(GetBranchPetRecordTags, {
    fetchPolicy: 'cache-and-network',
    variables: { offset: 0, limit: 1000 }
  });

  const toCall = [
    { called: calledVacc, get: getVacc, id: PETS_FILTER_TYPES.VACCINATION },
    { called: calledProductKinds, get: getProductKinds, id: PETS_FILTER_TYPES.TREATMENT },
    { called: calledBreeds, get: getBreeds, id: PETS_FILTER_TYPES.BREED },
    { called: calledBranchCategories, get: getBranchCategories, id: PETS_FILTER_TYPES.DAYCARE },
    { called: calledProducts, get: getProducts, id: PETS_FILTER_TYPES.TREATMENT },
    { called: calledTags, get: getAllTags, id: PETS_FILTER_TYPES.TAGS }
  ];

  const filtersControl = useFilters({
    setSelectedFilters: setNewVisibleFilters,
    toCall,
    addHeaderAction: addHeaderAction,
    clearHeaderAction: clearHeaderAction
  });
  const { watchedValues } = filtersControl;

  const selectedTreatmentTypes = watchedValues?.[PETS_FILTER_TYPES.TREATMENT]?.[PETS_MORE_OPTIONS_TYPES.TREATMENT_TYPE] || [];

  const selectedTreatmentTypesIds = selectedTreatmentTypes?.map((treatmentType: any) => treatmentType.value);

  const treatmentProducts = allProducts?.filter((product: Record<string, any>) => product.ProductKinds.some((kind: Record<string, string>) => selectedTreatmentTypesIds.includes(kind.id)));

  const vaccFilterValue = watchedValues?.[PETS_FILTER_TYPES.VACCINATION]?.[PETS_MORE_OPTIONS_TYPES.VACC_TYPE] || [];

  const vaccDueDateValue = watchedValues?.[PETS_FILTER_TYPES.VACCINATION]?.[PETS_MORE_OPTIONS_TYPES.DUE];

  const treatmentFilterValue = watchedValues?.[PETS_FILTER_TYPES.TREATMENT]?.[PETS_MORE_OPTIONS_TYPES.TREATMENT_TYPE] || [];

  const treatmentDueDateValue = watchedValues?.[PETS_FILTER_TYPES.TREATMENT]?.[PETS_MORE_OPTIONS_TYPES.DUE];

  const PETS_FILTER_TYPES_MORE_OPTIONS: Record<PETS_FILTER_TYPES, PETS_MORE_OPTIONS_TYPES[]> = {
    [PETS_FILTER_TYPES.DAYCARE]: [PETS_MORE_OPTIONS_TYPES.DAY_CARE, PETS_MORE_OPTIONS_TYPES.DAY],
    [PETS_FILTER_TYPES.BREED]: [PETS_MORE_OPTIONS_TYPES.BREED],
    [PETS_FILTER_TYPES.AGE]: [PETS_MORE_OPTIONS_TYPES.FROM_NUMBER, PETS_MORE_OPTIONS_TYPES.TO_NUMBER],
    [PETS_FILTER_TYPES.NEUTERING]: [PETS_MORE_OPTIONS_TYPES.NEUTERED],
    [PETS_FILTER_TYPES.VACCINATION]: [PETS_MORE_OPTIONS_TYPES.VACC_TYPE, vaccFilterValue.length && PETS_MORE_OPTIONS_TYPES.DUE, vaccDueDateValue && PETS_MORE_OPTIONS_TYPES.DATE].filter(Boolean),
    [PETS_FILTER_TYPES.TREATMENT]: [
      PETS_MORE_OPTIONS_TYPES.TREATMENT_TYPE,
      PETS_MORE_OPTIONS_TYPES.TREATMENT_NAME,
      treatmentFilterValue.length && PETS_MORE_OPTIONS_TYPES.DUE,
      treatmentDueDateValue && PETS_MORE_OPTIONS_TYPES.DATE
    ].filter(Boolean),
    [PETS_FILTER_TYPES.WEIGHT]: [PETS_MORE_OPTIONS_TYPES.FROM_NUMBER, PETS_MORE_OPTIONS_TYPES.TO_NUMBER],
    [PETS_FILTER_TYPES.SEARCH]: [PETS_MORE_OPTIONS_TYPES.SEARCH],
    [PETS_FILTER_TYPES.TAGS]: [PETS_MORE_OPTIONS_TYPES.TAGS],
    [PETS_FILTER_TYPES.QUICK_TAGS]: [PETS_MORE_OPTIONS_TYPES.TAGS],
    [PETS_FILTER_TYPES.BIRTHDATE]: [PETS_MORE_OPTIONS_TYPES.BIRTHDATE]
  };

  const MORE_OPTIONS_ITEMS: Record<PETS_MORE_OPTIONS_TYPES, { value: string | boolean; name: string }[] | []> = {
    [PETS_MORE_OPTIONS_TYPES.VACC_TYPE]: vaccRecordTypes?.map(({ id, name }: { id: string; name: string }) => ({ value: id, name })),
    [PETS_MORE_OPTIONS_TYPES.TREATMENT_TYPE]: productKinds?.map(({ id, name }: { id: string; name: string }) => ({ value: id, name })),
    [PETS_MORE_OPTIONS_TYPES.TREATMENT_NAME]: treatmentProducts?.map(({ id, name }: { id: string; name: string }) => ({ value: id, name })),
    [PETS_MORE_OPTIONS_TYPES.BREED]: breeds?.map(({ id, name }: { id: string; name: string }) => ({ value: id, name })),
    [PETS_MORE_OPTIONS_TYPES.DAY]: WEEK_DAYS.map(day => ({ value: day.toUpperCase(), name: day })),
    [PETS_MORE_OPTIONS_TYPES.DAY_CARE]: dayCares?.map(({ id, name }: { id: string; name: string }) => ({ value: id, name })),
    [PETS_MORE_OPTIONS_TYPES.TAGS]: allTags?.map(({ id, name }: { id: string; name: string }) => ({ value: id, name })),
    [PETS_MORE_OPTIONS_TYPES.DUE]: [
      { value: '', name: '-- None --' },
      { value: true, name: 'On or After' },
      { value: false, name: 'Before' }
    ],
    [PETS_MORE_OPTIONS_TYPES.NEUTERED]: [
      { value: true, name: 'Yes' },
      { value: false, name: 'No' }
    ],
    [PETS_MORE_OPTIONS_TYPES.DATE]: [],
    [PETS_MORE_OPTIONS_TYPES.FROM_NUMBER]: [],
    [PETS_MORE_OPTIONS_TYPES.TO_NUMBER]: [],
    [PETS_MORE_OPTIONS_TYPES.SEARCH]: [],
    [PETS_MORE_OPTIONS_TYPES.BIRTHDATE]: [
      { value: 'today', name: 'Today' },
      { value: 'inThreeDays', name: 'Within 3 Days' },
      { value: 'inAWeek', name: 'Within a Week' }
    ]
  };

  const filterItems = Object.values(PETS_FILTER_TYPES).map(filter => ({
    name: PETS_FILTER_TYPES_NAMES[filter],
    value: filter,
    unit: PETS_FILTER_TYPES_UNITS[filter],
    moreOptions: PETS_FILTER_TYPES_MORE_OPTIONS[filter].map(option => ({
      type: option,
      filterType: filter,
      id: `${filter}.${option}`,
      title: MORE_OPTIONS_TITLES[option] ? `${MORE_OPTIONS_TITLES[option]} Filter` : '',
      optionType: MORE_OPTIONS_DROPDOWN_TYPES[option],
      items: MORE_OPTIONS_ITEMS[option]
    }))
  }));

  const searchFilter = filterItems.find(({ value }) => value === PETS_FILTER_TYPES.SEARCH)!;
  const searchMoreOptions = searchFilter.moreOptions.find(({ type }) => type === PETS_MORE_OPTIONS_TYPES.SEARCH)!;

  const onPetSearch = (query: string) => {
    if (query) {
      addFilter({
        ...searchFilter,
        moreOptions: [{ ...searchMoreOptions, values: query }],
        requisite: 'true'
      });
      return;
    }
    removeFilter(searchFilter.value);
  };

  const quickTagFilter = filterItems.find(({ value }) => value === PETS_FILTER_TYPES.QUICK_TAGS)!;

  const Header = useCallback(() => {
    const { tablet } = useMediaQuery({ tablet: true });
    const headerActions = useReactiveVar(vars.petsHeaderActions);
    const { data: { getBusUserProfile: currentBusUserProfile = {} } = {} } = useQuery(GetBusUserProfile, {
      fetchPolicy: 'cache-only'
    });
    const isAdminOrSuperAdmin = isUserSuperVisorOrAdminOrSuper(currentBusUserProfile);
    const filterHeaderActions = headerActions.filter(({ id }) => id !== FILTERS_ID && !mobile && id !== 'bulkSelectAll');
    const filtersHeaderAction = headerActions.find(({ id }) => id === FILTERS_ID)?.action;
    const actions = (
      <>
        {filterHeaderActions.map(({ action, id }) => (
          <Fragment key={id}>{action}</Fragment>
        ))}
        {!mobile && filtersHeaderAction}
      </>
    );
    return (
      <TabsHeader
        tabs={tabs}
        selected={selected}
        setSelected={setSelected}
        actions={
          <>
            {showFilters && !mobile && <SearchBar onSearch={onPetSearch} defaultText="Search by Pet or Owner Name" showAlways={tablet} />}
            {actions}
            {selected === PETS_TABS_TYPES.CLIENTS && !mobile && (
              <OptionDropdown
                options={[
                  {
                    id: 'addItems',
                    optionType: OPTION_DROPDOWN_TYPES.BUTTONS,
                    items: [
                      ...(isAdminOrSuperAdmin
                        ? [
                            {
                              name: 'Add Client',
                              value: 'addAppUser',
                              onClick: () => {
                                ModalDialog.openModal({
                                  content: () => <AppUserDetailsModal />,
                                  title: 'Add Client'
                                });
                              }
                            },
                            {
                              name: 'Add Pet',
                              value: 'addPet',
                              onClick: () => {
                                ModalDialog.openModal({
                                  content: () => <PetDetailsModal />,
                                  title: 'Add Pet'
                                });
                              }
                            }
                          ]
                        : [])
                    ]
                  }
                ]}
                menuButtonType={OPTION_DROPDOWN_MENU_BUTTON_TYPES.PLUS}
                noApplyButton
              />
            )}
            {(selected === PETS_TABS_TYPES.CLIENTS || selected === PETS_TABS_TYPES.ALL) && mobile && (
              <OptionDropdown
                options={[
                  {
                    id: 'addItems',
                    optionType: OPTION_DROPDOWN_TYPES.BUTTONS,
                    items: [
                      ...(isAdminOrSuperAdmin
                        ? [
                            {
                              name: 'Add Client',
                              value: 'addAppUser',
                              onClick: () => {
                                ModalDialog.openModal({
                                  content: () => <AppUserDetailsModal />,
                                  title: 'Add Client'
                                });
                              }
                            },
                            {
                              name: 'Add Pet',
                              value: 'addPet',
                              onClick: () => {
                                ModalDialog.openModal({
                                  content: () => <PetDetailsModal />,
                                  title: 'Add Pet'
                                });
                              }
                            }
                          ]
                        : [])
                    ]
                  }
                ]}
                menuButtonType={OPTION_DROPDOWN_MENU_BUTTON_TYPES.PLUS_BLACK}
                noApplyButton
              />
            )}
          </>
        }
        actionsWidth={350}
      />
    );
  }, [selected, setSelected, tabs, showFilters]);

  const HeaderBottom = useCallback(() => {
    const headerActions = useReactiveVar(vars.petsHeaderActions);
    const bulkSelectAll = headerActions.find(({ id }) => id === 'bulkSelectAll')?.action;
    return <>{bulkSelectAll && <TabsHeaderContainer padding="0 32px 0 32px">{bulkSelectAll}</TabsHeaderContainer>}</>;
  }, []);

  return (
    <>
      <Header />
      {showFilters && (
        <>
          {!mobile && <Filters filtersControl={filtersControl} filterItems={filterItems} />}
          {!mobile && (
            <TabsHeaderContainer>
              <PetTagsQuickFilter quickTagFilter={quickTagFilter} />
            </TabsHeaderContainer>
          )}
          {mobile && <SearchBar onSearch={onPetSearch} showAlways defaultText="Pet, owner, email, service or tags" />}
          <HeaderBottom />
        </>
      )}
    </>
  );
};

export default React.memo(PetsHeader, areObjectsEqual);
