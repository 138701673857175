import React, { useRef } from 'react';
import { StoreEditButton } from '../../../components/DrawerBar/DrawerButtons';
import { DRAWER_IDS } from '../../../components/DrawerBar/types';
import { HoverRecordOptionsContainer, RecordBody, RecordDataContainer } from '../../styled';
import { BRANCH_FORM_LEVELS_LABELS, BRANCH_FORM_TYPES_LABELS, BranchForm } from './types';

const BranchFormRecord = ({ branchForm }: { branchForm: BranchForm }) => {
  const storeEditButtonRef = useRef<{ handleClick: () => void }>(null);

  return (
    <RecordDataContainer>
      <RecordBody fontWeight={'800'}>{branchForm?.name}</RecordBody>
      <RecordBody marginRight="20">{BRANCH_FORM_TYPES_LABELS[branchForm?.type]}</RecordBody>
      <RecordBody marginRight="20">{BRANCH_FORM_LEVELS_LABELS[branchForm?.level]}</RecordBody>
      <RecordBody>{branchForm?.Product?.name}</RecordBody>
      <HoverRecordOptionsContainer>
        <StoreEditButton drawer={{ drawerId: DRAWER_IDS.BRANCH_FORM_DRAWER, recordData: branchForm }} ref={storeEditButtonRef} />
      </HoverRecordOptionsContainer>
    </RecordDataContainer>
  );
};

export default BranchFormRecord;
