import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { Branch } from '../components/Profile/types';
import { GetSlots } from '../queries';
import { areDatesEqualWithoutHours, DATE_SLOTS_MAX, getBiggestAndSmallestDates, getUTCDaysBetweenDates, getUTCTime } from '../utils/dates';
import { BOOKING_TYPE } from '../views/Bookings/types';
import { getBookingDates } from '../views/Bookings/utils';
import { Product } from '../views/Store/types';

export type Slots = {
  availableSlots: {
    timestamp: string;
    available: number;
    mainSlotAvailablity: number;
  }[][];
  usedSchedules: { time: string; available: number }[][];
  timesByDay: { day: string; times: string[]; available: number }[];
};

const useCheckBranchAvailability = ({
  selectedProduct,
  branch,
  appointmentsDates = [],
  appointmentDatesWithTime,
  skip = false,
  start_date,
  slots_length
}: {
  branch: Branch;
  appointmentsDates?: Date[];
  appointmentDatesWithTime?: Date[];
  selectedProduct?: Product;
  skip?: boolean;
  start_date?: string;
  slots_length?: number;
}) => {
  const datesToFetch = useMemo(() => {
    const datesToFetch = appointmentsDates.map(appointmentDate => {
      const date = new Date(appointmentDate?.getTime() ? appointmentDate : new Date());
      return date.toISOString();
    });
    return [...new Set(datesToFetch)].sort();
  }, [appointmentsDates]);

  const { smallestDate, biggestDate } = useMemo(() => getBiggestAndSmallestDates(appointmentsDates), [appointmentsDates]);

  const daysBetweenDates = useMemo(() => Number(getUTCDaysBetweenDates(smallestDate, biggestDate)) + 1, [smallestDate, biggestDate]);

  const isMultiday = selectedProduct?.booking_type === BOOKING_TYPE.MULTI_DAY;

  const {
    data: { getSlots: { slots = {} as Slots } = {} } = {},
    loading,
    refetch
  } = useQuery<{
    getSlots: {
      slots: Slots;
    };
  }>(GetSlots, {
    fetchPolicy: 'network-only',
    skip: skip || !branch?.id || appointmentsDates.length < datesToFetch.length,
    variables: {
      branchId: branch?.id,
      ...(selectedProduct && { productId: selectedProduct?.id }),
      ...(datesToFetch &&
        datesToFetch?.length && {
          // selected_dates: datesToFetch,
          start_date: datesToFetch[0],
          slots_length: Math.min(daysBetweenDates, DATE_SLOTS_MAX)
        }),
      ...(slots_length && { slots_length: slots_length > DATE_SLOTS_MAX ? DATE_SLOTS_MAX : slots_length }),
      ...(start_date && { start_date })
    }
  });

  const bookingDates = useMemo(() => getBookingDates(slots), [slots]);

  const selectedDates = useMemo(() => {
    const selectedDates = appointmentsDates.map(appointmentDate => bookingDates.find(bookingDate => areDatesEqualWithoutHours(bookingDate?.fullDate!, appointmentDate))!);
    return selectedDates;
  }, [appointmentsDates, bookingDates]);

  const selectedSlotForEachDate = useMemo(() => {
    const selectedSlots = appointmentsDates.map((_, index) => {
      const appointmentDate = (appointmentDatesWithTime || [])[index];
      if (!appointmentDate) {
        return null;
      }
      const slotsForSelectedDate = selectedDates.find(slotsForSelectedDate => areDatesEqualWithoutHours(slotsForSelectedDate?.fullDate!, appointmentDate));
      if (isMultiday) {
        return slotsForSelectedDate?.slots?.[0];
      }
      const slotTime = appointmentDate.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true, timeZone: 'UTC' });
      const slotTimeFormatted = slotTime.replace('am', 'AM').replace('pm', 'PM');

      const selectedSlot = slotsForSelectedDate?.slots?.find(slot => getUTCTime(slot) === slotTimeFormatted);
      return selectedSlot;
    });
    return selectedSlots;
  }, [selectedDates, appointmentDatesWithTime, isMultiday]);

  const firstSlotOfEachBookingDate = useMemo(() => {
    const firstSlotOfEachBookingDate = bookingDates.map(bookingDate => bookingDate?.slots?.[0]);
    return firstSlotOfEachBookingDate;
  }, [bookingDates]);

  return {
    loading,
    refetch,
    selectedDates,
    selectedSlotForEachDate,
    bookingDates,
    firstSlotOfEachBookingDate,
    usedSchedules: slots.usedSchedules,
    timesByDay: slots.timesByDay
  };
};

export default useCheckBranchAvailability;
