import React from 'react';
import { PetRecordOptionsContainer, PetRecordTagPill, PetRecordTagPillsContainer } from '../../../components/Pets/styled';
import useIcons from '../../../hooks/useIcons';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { BranchAppUser } from '../../Store/BranchBilling/types';
import { BranchAppUserTag } from '../../Store/BranchTags/types';
import { AppUserImage, AppUserImageContainer, AppUserImageNameContainer, AppUserName, BlankAppUserImage, RecordBody, RecordDataContainer } from '../../styled';
import AppUserRecordOptions from './AppUserRecordOptions';

const BranchAppUserRecord = ({
  branchAppUser,
  navigateDrawer,
  appUserQuickTags = [],
  tagsVisibleOnItem = []
}: {
  branchAppUser: BranchAppUser;
  navigateDrawer: ({ drawerLinkId }: { drawerLinkId: string }) => void;
  appUserQuickTags?: BranchAppUserTag[];
  tagsVisibleOnItem?: BranchAppUserTag[];
}) => {
  const { mobile } = useMediaQuery({ mobile: true });
  const icons = useIcons();
  const optionsRef = React.useRef<HTMLDivElement>(null);
  const handleClick = (e: React.MouseEvent) => {
    if (optionsRef?.current?.contains(e.target as Node)) {
      return;
    }
    navigateDrawer({ drawerLinkId: branchAppUser?.id });
  };
  const { id, profile_pic, name } = branchAppUser || {};

  const recordOptions = (
    <PetRecordOptionsContainer>
      <AppUserRecordOptions ref={optionsRef} showAlways={mobile} loadTags={false} appUsers={[branchAppUser]} appUserQuickTags={appUserQuickTags} />
    </PetRecordOptionsContainer>
  );

  const tags = branchAppUser?.BranchAppUserTags || [];
  const tagsToRender = tagsVisibleOnItem ? tags.filter(tag => tagsVisibleOnItem.some(visibleTag => visibleTag.id === tag.id)) : tags;

  const renderTags = () => {
    return (
      <PetRecordTagPillsContainer>
        {tagsToRender?.map((item, index) => (
          <PetRecordTagPill color={item.color} key={index}>
            <span>{item.name}</span>
          </PetRecordTagPill>
        ))}
      </PetRecordTagPillsContainer>
    );
  };

  return (
    <RecordDataContainer onClick={handleClick} mobileGap="0" mobilePadding="16px 8px" MobileMinHeight="auto">
      <RecordBody width="200px" mobileWidth="100%">
        <AppUserImageNameContainer>
          <AppUserImageContainer mobileWidth="auto">
            {profile_pic && <AppUserImage key={id} src={profile_pic} index={0} />}
            {!profile_pic && <BlankAppUserImage alt="user" image={icons.user.childImageSharp.gatsbyImageData} />}
          </AppUserImageContainer>
          {!mobile && <AppUserName>{name}</AppUserName>}
        </AppUserImageNameContainer>
      </RecordBody>
      {!mobile && (
        <>
          <RecordBody>{branchAppUser?.email}</RecordBody>
          <RecordBody>{renderTags()}</RecordBody>
        </>
      )}
      {mobile && (
        <RecordBody mobileWidth="100%">
          <RecordBody mobileFlexDirection="column">
            <RecordBody mobileWidth="100%" marginLeft="85" fontWeight="800">
              {name}
            </RecordBody>
            <RecordBody mobileWidth="100%" marginLeft="85">
              {branchAppUser?.email}
            </RecordBody>
          </RecordBody>

          {recordOptions}
        </RecordBody>
      )}
      {!mobile && recordOptions}
    </RecordDataContainer>
  );
};

export default BranchAppUserRecord;
