import { GatsbyImage } from 'gatsby-plugin-image';
import React, { FC } from 'react';
import { ButtonsContainer } from '../../components/BookingsDrawer/styled';
import { ReportContainer } from '../../components/ChatDrawer/styled';
import useIcons from '../../hooks/useIcons';
import GroomingDetails from '../../views/Pets/Reports/Grooming/GroomingDetails';
import MedicalCaseDetails from '../../views/Pets/Reports/Medical/MedicalCaseDetails';
import TrainingDetails from '../../views/Pets/Reports/Training/TrainingDetails';
import { ActionBtn } from '../../views/Store/styled';
import { DynamicLink } from './types';
import WalkingDetails from '../../views/Pets/Reports/Walking/WalkingDetails';
import SittingDetails from '../../views/Pets/Reports/Sitting/SittingDetails';
import DaycareDetails from '../../views/Pets/Reports/Daycare/DaycareDetails';

export const PetsReportsModal: FC<{ dynamicLink: DynamicLink }> = ({ dynamicLink }) => {
  const {
    data: { selectedReport = {}, medCondReport = false, groomingReport = false, trainingReport = false, walkingReport = false, sittingReport = false, daycareReport = false, petProfile = {} } = {},
    handleClose,
    linkItems: { navigator = () => null }
  } = dynamicLink || {};
  const icons = useIcons();
  const closeIcon = icons?.closeModal?.childImageSharp.gatsbyImageData;
  const expandIcon = icons?.expand?.childImageSharp.gatsbyImageData;

  const handleReportExpand = () => navigator(dynamicLink);

  return (
    <ReportContainer>
      <ButtonsContainer>
        <ActionBtn onClick={handleClose} noMargin>
          <GatsbyImage image={closeIcon} alt={'close'} />
        </ActionBtn>
        <ActionBtn onClick={handleReportExpand} noBackgroundColor noMargin>
          <GatsbyImage image={expandIcon} alt={'expand'} />
        </ActionBtn>
      </ButtonsContainer>

      {selectedReport && (
        <>
          {medCondReport && <MedicalCaseDetails cases={[selectedReport]} medicalCase={selectedReport} pet={petProfile} petId={petProfile?.id as string} hideNotes fullWidth />}
          {groomingReport && <GroomingDetails groomingReports={[selectedReport]} selectedReport={selectedReport} hideNotes fullWidth />}
          {trainingReport && <TrainingDetails trainingReports={[selectedReport]} selectedReport={selectedReport} hideNotes fullWidth />}
          {walkingReport && <WalkingDetails walkingReports={[selectedReport]} selectedReport={selectedReport} hideNotes fullWidth />}
          {sittingReport && <SittingDetails sittingReports={[selectedReport]} selectedReport={selectedReport} hideNotes fullWidth />}
          {daycareReport && <DaycareDetails daycareReports={[selectedReport]} selectedReport={selectedReport} hideNotes fullWidth />}
        </>
      )}
    </ReportContainer>
  );
};
