import React from 'react';
import usePaginatedQuery from '../../../hooks/usePaginatedQuery';
import { GetBranchOrderSubscriptionRepeats } from '../../../queries';
import { OrderSubscriptionRepeat } from '../../Subscriptions/types';
import { BRANCH_BILLING_SUBSCRIPTION_INVOICES_FILTERS, BRANCH_BILLING_SUBSCRIPTION_INVOICES_FILTERS_STATUS, CurrentPeriod } from './types';
import SubscriptionRepeatsList from '../../Subscriptions/SubscriptionRepeatsList';

const BranchBillingSubscriptionRepeats = ({ period, filter }: { period: CurrentPeriod; filter: BRANCH_BILLING_SUBSCRIPTION_INVOICES_FILTERS }) => {
  const repeatStatus = BRANCH_BILLING_SUBSCRIPTION_INVOICES_FILTERS_STATUS[filter];
  const shouldFilterByDate = filter !== BRANCH_BILLING_SUBSCRIPTION_INVOICES_FILTERS.SUBSCRIPTION_INVOICES_UNBILLED_ALL;

  const paginatedQueryResult = usePaginatedQuery<OrderSubscriptionRepeat[]>({
    query: GetBranchOrderSubscriptionRepeats,
    limit: 20,
    otherVariables: {
      ...(shouldFilterByDate && {
        createdAt_from: period.start,
        createdAt_to: period.end
      }),
      status: repeatStatus && repeatStatus?.length ? repeatStatus : undefined
    }
  });

  return <SubscriptionRepeatsList paginatedQueryResult={paginatedQueryResult} />;
};

export default BranchBillingSubscriptionRepeats;
