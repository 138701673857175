import { useMutation } from '@apollo/client';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Notifier from '../../Notifier';
import { UpdateOrderAmount } from '../../queries';
import { getBranchCurrencySymbol } from '../../utils/getBranchCurrencySymbol';
import { isValidAmount } from '../../utils/validators';
import ModalDialog from '../Modal/ModalDialog';
import { ModalBody, ModalFooter } from '../Modal/styled';
import { FormButtonsContainer, FormError, FormInput, FormLabel, FormSubmitButton, InputGroup, InputsWrapper } from '../Shared/Forms/Forms';
import { Container } from '../Shared/Shared';

const AdjustPaymentModal = ({ orderId, oldAmount }) => {
  const [updateAmount, { data: { updateOrderAmount: order } = {}, loading, error }] = useMutation(UpdateOrderAmount);
  const { control, handleSubmit, errors } = useForm();
  const onChange = args => ({ value: args[0].target.value });

  const onSubmit = handleSubmit(form => {
    updateAmount({ variables: { orderId: orderId, amount: form.amount } });
  });

  useEffect(() => {
    if (order) {
      Notifier.success({ title: 'Payment Adjusted', message: 'Successfuly' });

      ModalDialog.closeModal();
    }
  }, [order]);

  return (
    <>
      <ModalBody>
        <Container>
          <InputsWrapper>
            <InputGroup>
              <FormLabel>Current Amount</FormLabel>
              <FormInput height={32} fontSize={16} disabled value={getBranchCurrencySymbol() + ' ' + oldAmount} />
            </InputGroup>
            <InputGroup>
              <FormLabel error={errors.amount}>New Amount</FormLabel>
              <Controller
                as={<FormInput error={errors.amount} type={'number'} height={32} fontSize={16} />}
                control={control}
                name={'amount'}
                onChange={onChange}
                defaultValue={''}
                rules={{
                  required: true,
                  pattern: {
                    value: isValidAmount,
                    message: 'Invalid Amount'
                  }
                }}
              />
              {errors.amount && <FormError>{errors.amount.message || 'New Amount is required'}</FormError>}
            </InputGroup>
          </InputsWrapper>
        </Container>
      </ModalBody>
      <ModalFooter>
        <FormButtonsContainer>
          <FormSubmitButton error={error} loading={loading} onClick={() => onSubmit()}>
            Confirm Adjustment
          </FormSubmitButton>
        </FormButtonsContainer>
      </ModalFooter>
    </>
  );
};

export default AdjustPaymentModal;
