import { TransformedCustomization, Customizations as CustomizationsType } from './types';

export const getSelectedCustomizations = (customizations?: TransformedCustomization[]) => {
  const customizationsState: CustomizationsType = (customizations || [])?.reduce((acc, customization) => {
    return {
      ...acc,
      [customization.name]: customization.options.map(option => ({
        title: option.title,
        price: option.price,
        meta: option.meta.type !== 'none' ? { [option.meta.type]: option.meta.value } : 'none',
        rules: option.rules?.filter(rule => rule.type !== 'none')
      }))
    };
  }, {});

  return customizationsState;
};
