import React, { Fragment } from 'react';
import { sentenceCase } from 'sentence-case';
import useMediaQuery from '../../hooks/useMediaQuery';
import { toReadableDate } from '../../utils/dates';
import { BranchPetRecordTag } from '../../views/Store/BranchTags/types';
import { RecordBody } from '../../views/styled';
import { PETS_FILTER_TYPES, PETS_MORE_OPTIONS_TYPES, SelectedPetsFilter } from '../Shared/Filters/config';
import { getPetFilterMoreOptionsValues as getMoreOptions, getPetFilter } from '../Shared/Filters/helpers';
import { PetRecordTagPill, PetRecordTagPillsContainer } from './styled';
import { Pet } from './types';

const PetRecordFilters = ({ pet, selectedFilters }: { pet: Pet; selectedFilters: SelectedPetsFilter[] }) => {
  const getFilter = (filter: PETS_FILTER_TYPES) => getPetFilter(selectedFilters, filter);

  const { mobile } = useMediaQuery({ mobile: true });

  // ----DAYCARE----
  const dayCareFilterType = getFilter(PETS_FILTER_TYPES.DAYCARE);
  const dayCareFilter = getMoreOptions(dayCareFilterType, PETS_MORE_OPTIONS_TYPES.DAY_CARE);
  const dayCareDayFilter = getMoreOptions(dayCareFilterType, PETS_MORE_OPTIONS_TYPES.DAY);
  const petDayCares = pet.PetRecord?.BranchPetRecordItems;
  const petDayCaresBranchNames = petDayCares?.filter(item => dayCareFilter?.includes(item?.Branch?.id)).map(item => item?.Branch?.name);
  const petDayCaresDays = petDayCares?.flatMap(item => item?.days).filter(day => dayCareDayFilter?.includes(day)) || [];

  // ----BREED----
  const breedFilterType = getFilter(PETS_FILTER_TYPES.BREED);
  const breedFilter = getMoreOptions(breedFilterType, PETS_MORE_OPTIONS_TYPES.BREED);
  const petBreed = pet?.Breed?.name;

  // ----AGE----
  const ageFilterType = getFilter(PETS_FILTER_TYPES.AGE);
  const ageFilterFrom = getMoreOptions(ageFilterType, PETS_MORE_OPTIONS_TYPES.FROM_NUMBER);
  const ageFilterTo = getMoreOptions(ageFilterType, PETS_MORE_OPTIONS_TYPES.TO_NUMBER);
  const petAge = pet?.age;

  // ----BIRTHDATE----
  const birthDateFilterType = getFilter(PETS_FILTER_TYPES.BIRTHDATE);
  const birthDateFilter = getMoreOptions(birthDateFilterType, PETS_MORE_OPTIONS_TYPES.BIRTHDATE);
  const birthDate = new Date(pet?.birthdate);

  // ----NEUTERED----
  const nuetrudedFilterType = getFilter(PETS_FILTER_TYPES.NEUTERING);
  const nuetrudedFilter = getMoreOptions(nuetrudedFilterType, PETS_MORE_OPTIONS_TYPES.NEUTERED);
  const petNeutered = pet?.PetRecord?.neutred ? 'Neutered' : 'Not Neutered';

  // ----VACCINATIONS----
  const vaccinationFilterType = getFilter(PETS_FILTER_TYPES.VACCINATION);
  const vaccinationFilter = getMoreOptions(vaccinationFilterType, PETS_MORE_OPTIONS_TYPES.VACC_TYPE);
  const vaccinationDueFilter = getMoreOptions(vaccinationFilterType, PETS_MORE_OPTIONS_TYPES.DUE);
  const vaccinationDateFilter = getMoreOptions(vaccinationFilterType, PETS_MORE_OPTIONS_TYPES.DATE);

  const petVacc = pet?.PetRecord?.VaccRecords;

  const petVaccRecords = petVacc?.filter(record => vaccinationFilter?.includes(record?.VaccRecordType?.id));

  const petVaccRecordsWithTheSameId = petVaccRecords?.reduce((acc, record) => {
    const recordId = record?.VaccRecordType?.id;
    const rec = acc[recordId];
    if (!rec) {
      acc[recordId] = [record];
    } else {
      acc[recordId].push(record);
    }
    return acc;
  }, {});

  const uniqueVaccRecordIds = Object.keys(petVaccRecordsWithTheSameId || {});

  const closestVaccDates = uniqueVaccRecordIds.map(id => {
    const vaccRecords = petVaccRecordsWithTheSameId[id];
    const vaccDates = vaccRecords.filter(record => record?.nextdate).map(record => new Date(record?.nextdate));

    const inThePast = vaccinationDueFilter === 'false';
    const selectedDate = new Date(vaccinationDateFilter);
    const closestDate = vaccDates.reduce((acc, date) => {
      const closest = inThePast ? selectedDate > date : selectedDate < date;
      if (closest) {
        return date;
      }
      return acc;
    }, null);

    const vaccName = vaccRecords[0]?.VaccRecordType?.name;

    if (!vaccinationDueFilter) {
      return { vaccName, vaccId: id, closestDate: null };
    }

    return { vaccName: closestDate ? vaccName : null, vaccId: id, closestDate: vaccDates?.length ? closestDate : null };
  });

  // ----SUPPRECORDS----
  const suppFilter = getFilter(PETS_FILTER_TYPES.TREATMENT);
  const suppFilterProductKind = getMoreOptions(suppFilter, PETS_MORE_OPTIONS_TYPES.TREATMENT_TYPE);
  const suppFilterProduct = getMoreOptions(suppFilter, PETS_MORE_OPTIONS_TYPES.TREATMENT_NAME);
  const suppFilterDue = getMoreOptions(suppFilter, PETS_MORE_OPTIONS_TYPES.DUE);
  const suppFilterDate = getMoreOptions(suppFilter, PETS_MORE_OPTIONS_TYPES.DATE);

  const treatmentRecords = [...(pet?.PetRecord?.SuppRecords || [])];

  // productKind
  const treatmentRecordsWithSelectedProductKinds = treatmentRecords?.filter(record => record?.Product?.ProductKinds?.some(kind => suppFilterProductKind?.includes(kind?.id)));

  const treatmentRecordsWithTheSameProductKindId = suppFilterProductKind?.reduce((acc, id) => {
    const records = treatmentRecordsWithSelectedProductKinds?.filter(record => record?.Product?.ProductKinds?.some(kind => kind?.id === id));
    acc[id] = records;
    return acc;
  }, {});

  const uniqueTreatmentRecordsProductKindIds = Object.keys(treatmentRecordsWithTheSameProductKindId || {});

  const closestTreatmentProductKindsDates = uniqueTreatmentRecordsProductKindIds.map(id => {
    const treatmentRecords = treatmentRecordsWithTheSameProductKindId[id] || [];
    const treatmentDates = treatmentRecords.filter(record => record?.nextdate).map(record => new Date(record?.nextdate));

    const inThePast = suppFilterDue === 'false';
    const selectedDate = new Date(suppFilterDate);
    const closestDate = treatmentDates.reduce((acc, date) => {
      const closest = inThePast ? selectedDate > date : selectedDate < date;
      if (closest) {
        return date;
      }
      return acc;
    }, null);

    const productKindName = treatmentRecords[0]?.Product?.ProductKinds?.find(kind => kind?.id === id)?.name;

    if (!suppFilterDue) {
      return { productKindName, productKindId: id, closestDate: null };
    }

    return { productKindName: closestDate ? productKindName : null, productKindId: id, closestDate: treatmentDates?.length ? closestDate : null };
  });

  // product
  const treatmentRecordsProductsWithTheSameId = suppFilterProduct?.reduce((acc, id) => {
    const records = treatmentRecords?.filter(record => record?.Product?.id === id);
    acc[id] = records;
    return acc;
  }, {});

  const closestTreatmentDates = suppFilterProduct?.map(id => {
    const treatmentRecords = treatmentRecordsProductsWithTheSameId[id] || [];
    const treatmentDates = treatmentRecords.filter(record => record?.nextdate).map(record => new Date(record?.nextdate));

    const inThePast = suppFilterDue === 'false';

    const selectedDate = new Date(suppFilterDate);
    const closestDate = treatmentDates.reduce((acc, date) => {
      const closest = inThePast ? selectedDate > date : selectedDate < date;
      if (closest) {
        return date;
      }
      return acc;
    }, null);

    const treatmentName = treatmentRecords[0]?.Product?.name;

    if (!suppFilterDue) {
      return { treatmentName, treatmentId: id, closestDate: null };
    }

    return { treatmentName: closestDate ? treatmentName : null, treatmentId: id, closestDate: treatmentDates?.length ? closestDate : null };
  });

  // ----WEIGHT----
  const weightFilterType = getFilter(PETS_FILTER_TYPES.WEIGHT);
  const weightFilterFrom = getMoreOptions(weightFilterType, PETS_MORE_OPTIONS_TYPES.FROM_NUMBER);
  const weightFilterTo = getMoreOptions(weightFilterType, PETS_MORE_OPTIONS_TYPES.TO_NUMBER);
  const petWeight = pet?.PetRecord?.measurements?.weights?.slice(-1)?.[0]?.weight;

  // ----SEARCH----
  const searchFilter = getFilter(PETS_FILTER_TYPES.SEARCH);
  const searchFilterValue = getMoreOptions(searchFilter, PETS_MORE_OPTIONS_TYPES.SEARCH);

  // ----TAGS----
  const tagsFilter = getFilter(PETS_FILTER_TYPES.TAGS);
  const quickTagsFilter = getFilter(PETS_FILTER_TYPES.QUICK_TAGS);
  const tagsFilterValue = getMoreOptions(tagsFilter, PETS_MORE_OPTIONS_TYPES.TAGS);
  const quickTagsFilterValue = getMoreOptions(quickTagsFilter, PETS_MORE_OPTIONS_TYPES.TAGS);
  const petTags: BranchPetRecordTag[] = pet?.PetRecord?.BranchPetRecordTags || [];
  const allTags = [...new Set([...(tagsFilterValue || []), ...(quickTagsFilterValue || [])])];
  const selectedPetTags = petTags?.filter(tag => allTags?.includes(tag?.id));
  return (
    <>
      {dayCareFilter && <RecordBody>{petDayCaresBranchNames?.join(', ')}</RecordBody>}
      {dayCareDayFilter && <RecordBody>{petDayCaresDays?.map(day => sentenceCase(day))?.join(', ')}</RecordBody>}
      {breedFilter && <RecordBody>{petBreed}</RecordBody>}
      {(ageFilterFrom || ageFilterTo || birthDateFilter) && <RecordBody>{petAge}</RecordBody>}
      {(ageFilterFrom || ageFilterTo || birthDateFilter) && <RecordBody>{toReadableDate(birthDate, { noTime: true, isLocale: true })}</RecordBody>}
      {nuetrudedFilter && <RecordBody>{petNeutered}</RecordBody>}

      {vaccinationFilter &&
        (vaccinationFilter as string[])?.map((vaccFilterId, index) => {
          const closestVaccDate = closestVaccDates?.find(date => date?.vaccId === vaccFilterId);
          return (
            <Fragment key={index}>
              <RecordBody>
                {closestVaccDate?.vaccName}
                {vaccinationDateFilter && !!closestVaccDate?.closestDate && ` - ${toReadableDate(closestVaccDate?.closestDate, { noTime: true, isLocale: true })}`}
              </RecordBody>
            </Fragment>
          );
        })}

      {suppFilter &&
        !suppFilterProduct &&
        (suppFilterProductKind as string[])?.map((suppFilterId, index) => {
          const closestTreatmentProductKindDate = closestTreatmentProductKindsDates?.find(date => date?.productKindId === suppFilterId);
          return (
            <Fragment key={index}>
              <RecordBody>
                {closestTreatmentProductKindDate?.productKindName}
                {suppFilterDate &&
                  !suppFilterProduct &&
                  !!closestTreatmentProductKindDate?.closestDate &&
                  ` - ${toReadableDate(closestTreatmentProductKindDate?.closestDate, { noTime: true, isLocale: true })}`}
              </RecordBody>
            </Fragment>
          );
        })}

      {suppFilter &&
        !!suppFilterProduct?.length &&
        (suppFilterProduct as string[])?.map((treatmentFilterId, index) => {
          const closestTreatmentDate = closestTreatmentDates?.find(date => date?.treatmentId === treatmentFilterId);
          return (
            <Fragment key={index}>
              <RecordBody>
                {closestTreatmentDate?.treatmentName}
                {suppFilterDate && !!closestTreatmentDate?.closestDate && ` - ${toReadableDate(closestTreatmentDate?.closestDate, { noTime: true, isLocale: true })}`}
              </RecordBody>
            </Fragment>
          );
        })}

      {!!(weightFilterFrom || weightFilterTo) && <RecordBody>{petWeight} kg</RecordBody>}

      {!!(tagsFilterValue || quickTagsFilterValue || searchFilter) && !mobile && (
        <RecordBody>
          <PetRecordTagPillsContainer>
            {petTags?.map((item, index) => (
              <PetRecordTagPill key={index} color={item.color}>
                <span>{item.name}</span>
              </PetRecordTagPill>
            ))}
          </PetRecordTagPillsContainer>
        </RecordBody>
      )}
    </>
  );
};

export default PetRecordFilters;
