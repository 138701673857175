import { useReactiveVar } from '@apollo/client';
import React, { FC, useRef } from 'react';
import Colors from '../../Colors';
import BookingRecordOptions, { BookingRecordOptionsRef } from '../../components/Bookings/BookingRecordOptions';
import { GroupPetDetails } from '../../components/BookingsDrawer/styled';
import { AppUserImagePetRecordOptions, PetRecordOptionsContainer, PetRecordTagPill, PetRecordTagPillsContainer } from '../../components/Pets/styled';
import { HIDDEN_BOOKINGS_FILTERS } from '../../components/Shared/Filters/config';
import { Icon } from '../../components/Shared/Forms/Forms';
import { Checkbox, CheckboxItemContainer } from '../../components/Shared/Forms/styled';
import { RedDot } from '../../components/Shared/Shared';
import useBookingActions from '../../hooks/useBookingActions';
import useBulkSelect from '../../hooks/useBulkSelect';
import useIcons from '../../hooks/useIcons';
import useMediaQuery from '../../hooks/useMediaQuery';
import { vars } from '../../reactive';
import { formatOrdinals, formatTime, sortByTime, toMealReadableText } from '../../utils/dates';
import { getUnit } from '../../utils/validators';
import { DietMealRecord } from '../Pets/Health/HealthPetRecordDiet';
import { Divider } from '../Pets/Health/styled';
import { BranchAppointmentTag } from '../Store/BranchTags/types';
import {
  AppUserImage,
  AppUserImageAnimation,
  AppUserImageContainer,
  AppUserImageContainerAnimation,
  AppUserImageNameContainer,
  AppUserName,
  BookingRecordContainer,
  RecordBody,
  RecordDataContainer,
  RecordDataOverflowContainer
} from '../styled';
import BookingRecordFilters from './BookingRecordFilters';
import { BOOKING_RECORD_VIEW_SETTINGS, BOOKING_TYPE, BookingOrder } from './types';
import { getBookingTitles } from './utils';

type BookingRecordProps = {
  booking: BookingOrder;
  navigateDrawer: ({ drawerLinkId }: { drawerLinkId: string }) => void;
  showTotal?: boolean;
  quickAppointmentsTags: BranchAppointmentTag[];
  tagsVisibleOnItem?: BranchAppointmentTag[];
  bulkSelect: ReturnType<typeof useBulkSelect>;
  recordActiveViewSettings?: (typeof BOOKING_RECORD_VIEW_SETTINGS)[number]['id'][];
};

const BookingRecord: FC<BookingRecordProps> = ({
  booking,
  navigateDrawer,
  showTotal,
  quickAppointmentsTags,
  bulkSelect,
  tagsVisibleOnItem,
  recordActiveViewSettings = [BOOKING_RECORD_VIEW_SETTINGS[0].id]
}) => {
  const { mobile } = useMediaQuery({ mobile: true });
  const selectedFilters = useReactiveVar(vars.selectedBookingsFilters);
  const icons = useIcons();
  const multiDayProduct = booking[0]?.OrderItem?.item?.booking_type === BOOKING_TYPE.MULTI_DAY;

  const pets = booking.map(orders => orders?.PetRecord?.Pet);
  const uniquePetsByPetId = pets.filter((pet, index, self) => self.findIndex(p => p?.id === pet?.id) === index);
  const { day, time, dateTo, numberOfDays, orderItemName, petsNames, priceString } = getBookingTitles(booking);
  const { actions, shouldDisplay } = useBookingActions({ booking });

  const { shouldDisplayConfirmationAndRejection, inConfirmed, inNew } = shouldDisplay;
  const { handleRejectCancel, handleConfirmation } = actions;
  const bookingRecordOptionsRef = useRef<BookingRecordOptionsRef>(null);
  const appointmentsIds = booking.map(({ id }) => id);
  const appUserAddress = booking?.[0]?.PetRecord?.Pet?.AppUser?.addresses?.[0];
  const postcode = booking?.[0]?.PetRecord?.Pet?.AppUser?.postcode;
  const line2 = appUserAddress?.line2 ? `, ${appUserAddress?.line2}` : '';
  const appUserAddressString = appUserAddress ? `${appUserAddress.line1}${line2}, ${postcode}, ${appUserAddress.country}` : '';
  const ordersNotes = booking.flatMap(order => order?.OrderItem?.Order.Notes);
  const uniqueOrderNotes = ordersNotes.filter((note, index, self) => self.findIndex(n => n?.id === note?.id) === index);
  const petsNotes = booking.flatMap(order => order?.PetRecord?.Notes);
  const uniquePetNotes = petsNotes.filter((note, index, self) => self.findIndex(n => n?.id === note?.id) === index);
  const petsContent = uniquePetNotes.map(note => note?.body?.content).join(', ');
  const ordersContent = uniqueOrderNotes.map(note => note?.body?.content).join(', ');
  const uniquePetRecords = booking.map(order => order?.PetRecord).filter((pet, index, self) => self.findIndex(p => p?.id === pet?.id) === index);
  const petsBehaviour = uniquePetRecords.map(pet => pet?.PetBehavior);
  const busUserName = booking[0]?.BusUsers?.map(({ name }) => name).join(', ');
  const hasFilters = !!selectedFilters?.length;
  const hasVisibileFilters = !!selectedFilters.filter(({ value }) => !HIDDEN_BOOKINGS_FILTERS[value]).length;
  const { show: showBulkSelect, toggleSelectMultiple, isSelected } = bulkSelect || {};
  const toggleMenu = () => {
    bookingRecordOptionsRef?.current?.getOptionDropdownRefOptions?.()?.toggleMenu?.();
  };

  const handleClick = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    const isTapToTagElement = e.target instanceof HTMLElement && e.target.innerText === 'Tap to tag this booking';

    if (isTapToTagElement) {
      toggleMenu();
    } else {
      if (bookingRecordOptionsRef?.current?.getContainerRefOptions?.()?.contains?.(e.target as Node)) {
        return;
      }

      if (showBulkSelect) {
        toggleSelectMultiple(appointmentsIds);
        return;
      }

      navigateDrawer({ drawerLinkId: booking?.[0].id });
    }
  };

  const tags = booking?.[0]?.BranchAppointmentTags;
  const petTags = booking?.flatMap(book => book?.PetRecord?.BranchPetRecordTags || []) || [];
  const uniquePetTags = petTags.filter((tag, index, self) => self.findIndex(t => t?.id === tag?.id) === index);
  const tagsToRender = tagsVisibleOnItem ? tags.filter(tag => tagsVisibleOnItem.some(visibleTag => visibleTag.id === tag.id)) : tags;
  const addedTags = [...tagsToRender, ...uniquePetTags];

  const mealsForPets = uniquePetRecords.map(petRecord => {
    const meals = sortByTime(petRecord?.Meals || []);
    const mealsRecords = meals.map((item, index) => (
      <DietMealRecord key={item?.id} meal={`${formatOrdinals(index + 1)} Meal`} name={item?.name} time={toMealReadableText(item.time)} unit={getUnit(item.unit)} quantity={item.quantity} />
    ));
    const mealTypes = petRecord?.BranchCategories?.map(category => category.name).join(' & ');
    if (!mealsRecords.length) {
      return null;
    }

    return (
      <GroupPetDetails
        key={petRecord?.id}
        backgroundColor={Colors.info2}
        style={{
          gap: 10,
          flexDirection: 'column'
        }}
      >
        <span>{mealTypes}</span>
        {mealsRecords}
      </GroupPetDetails>
    );
  });

  const renderTags = () => {
    return (
      <>
        {!!addedTags.length && (
          <PetRecordTagPillsContainer>
            {addedTags?.map((item, index) => (
              <PetRecordTagPill color={item.color} key={index}>
                <span>{item.name}</span>
              </PetRecordTagPill>
            ))}
          </PetRecordTagPillsContainer>
        )}
        {!hasFilters && !addedTags.length && mobile && (
          <RecordBody fontSize={13} mobilePadding="0 0 0 6px" style={{ color: '#ABABAB', textAlign: 'left' }} onClick={() => {}}>
            Tap to tag this booking
          </RecordBody>
        )}
      </>
    );
  };

  const RecordData = (
    <>
      {mobile && (
        <RecordBody mobileWidth="100%" style={{ marginBottom: '2px' }}>
          <AppUserName noMargin>{petsNames}</AppUserName>
        </RecordBody>
      )}
      <RecordBody fontWeight={'600'} MobileGap={10} mobileWidth="100%" marginRight="0">
        <RecordBody marginRight="0" mobileWidth="100%">
          <RecordBody mobileWidth="100" mobileDisplay="block" mobileOverflow="hidden">
            {orderItemName}
          </RecordBody>
          {multiDayProduct && mobile && (
            <RecordBody width="250">
              <strong>
                {day} to {dateTo}
                <span> {numberOfDays}</span>
              </strong>
            </RecordBody>
          )}
        </RecordBody>
        {mobile && !multiDayProduct && (
          <RecordBody fontWeight="800" marginRight="0">
            {formatTime(booking[0].timestamp, {
              twentyFourHour: true
            })}
          </RecordBody>
        )}
      </RecordBody>
      {!multiDayProduct && !mobile && (
        <RecordBody width="250" fontWeight="400">
          {day} <strong>{time}</strong>
        </RecordBody>
      )}
      {multiDayProduct && !mobile && (
        <RecordBody width="250">
          <strong> {day}</strong> to <strong>{dateTo}</strong> {numberOfDays}
        </RecordBody>
      )}
      {/* {multiDayProduct && mobile && (
        <RecordBody width="250">
          <strong>
            {day} to {dateTo}
            <span> {numberOfDays}</span>
          </strong>
        </RecordBody>
      )} */}

      {!mobile && <RecordBody width="300">{busUserName}</RecordBody>}
      {showTotal && (
        <RecordBody width="60" fontWeight="600" flexEnd>
          {priceString}
        </RecordBody>
      )}
    </>
  );

  const recordOptions = (
    <PetRecordOptionsContainer>
      <BookingRecordOptions
        pets={pets}
        petQuickTags={[]}
        ref={bookingRecordOptionsRef}
        showAlways={mobile}
        defaultAppointments={booking}
        bookingQuickTags={quickAppointmentsTags}
        appointments={booking}
        refetchAppointmentsAfterTag={hasFilters}
        loadTags={false}
      />
    </PetRecordOptionsContainer>
  );

  const petBehaviorContent = petsBehaviour
    .map(behaviour => behaviour?.feeding_routine)
    .filter(Boolean)
    .join(', ');

  const petTreatmentContent = petsBehaviour
    .map(behaviour => behaviour?.treatment_routine)
    .filter(Boolean)
    .join(', ');

  const renderTrailingView = () => {
    return (
      <div style={{ marginRight: 10, marginLeft: 10, display: 'flex', flexDirection: 'column', overflow: 'scroll', gap: 10, width: '100%' }}>
        {recordActiveViewSettings.includes('recordTags') && renderTags()}
        {recordActiveViewSettings.includes('recordPetNotes') && !!petsContent && <GroupPetDetails backgroundColor={Colors.info2}>{petsContent}</GroupPetDetails>}
        {recordActiveViewSettings.includes('recordBookingNotes') && !!ordersContent && <GroupPetDetails backgroundColor={Colors.info3}>{ordersContent}</GroupPetDetails>}
        {recordActiveViewSettings.includes('recordAddress') && !!appUserAddressString && <GroupPetDetails backgroundColor={Colors.info}>{appUserAddressString}</GroupPetDetails>}
        {recordActiveViewSettings.includes('recordFeedingSchedule') && mealsForPets}
        {recordActiveViewSettings.includes('recordFeedingRoutine') && !!petBehaviorContent && <GroupPetDetails backgroundColor={Colors.info}>{petBehaviorContent}</GroupPetDetails>}
        {recordActiveViewSettings.includes('recordTreatmentRoutine') && !!petTreatmentContent && <GroupPetDetails backgroundColor={Colors.info3}>{petTreatmentContent}</GroupPetDetails>}
      </div>
    );
  };

  const RecordItem = (
    <RecordDataContainer onClick={handleClick} fullWidth={mobile && !inConfirmed} streach={mobile} style={{ width: 'auto' }} autoHeight>
      {!mobile && inNew && <RedDot />}
      <RecordBody width="260" mobileWidth="100%" mobilePadding="12px 12px 0 12px">
        <AppUserImagePetRecordOptions>
          <AppUserImageNameContainer>
            <AppUserImageContainer noPadding={!showBulkSelect}>
              {mobile && inNew && <RedDot />}
              <AppUserImageContainerAnimation rotateOption={!!showBulkSelect} noPadding={showBulkSelect}>
                {!showBulkSelect &&
                  !mobile &&
                  uniquePetsByPetId.map((pet, idx) => (
                    <AppUserImageAnimation key={pet?.id || idx}>
                      <AppUserImage src={pet?.profile_pic || icons.user.childImageSharp.gatsbyImageData.images.fallback.src} index={idx} />
                    </AppUserImageAnimation>
                  ))}

                {!showBulkSelect &&
                  mobile &&
                  uniquePetsByPetId.map((pet, idx) => (
                    <>
                      <AppUserImageAnimation key={pet?.id || idx}>
                        <AppUserImage src={pet?.profile_pic || icons.user.childImageSharp.gatsbyImageData.images.fallback.src} index={idx} />
                      </AppUserImageAnimation>
                    </>
                  ))}
                {mobile && <RecordDataOverflowContainer>{RecordData}</RecordDataOverflowContainer>}
                {showBulkSelect && (
                  <AppUserImageAnimation>
                    <CheckboxItemContainer checked={isSelected(booking[0].id)} noMargin>
                      <Checkbox>
                        <Icon viewBox="0 0 24 24">
                          <polyline points="20 6 9 17 4 12" />
                        </Icon>
                      </Checkbox>
                    </CheckboxItemContainer>
                  </AppUserImageAnimation>
                )}
              </AppUserImageContainerAnimation>
            </AppUserImageContainer>
            {!mobile && <AppUserName>{petsNames}</AppUserName>}
          </AppUserImageNameContainer>
        </AppUserImagePetRecordOptions>
      </RecordBody>
      {!mobile && RecordData}
      {!mobile && (
        <RecordBody disableTruncate width="400">
          {renderTrailingView()}
        </RecordBody>
      )}

      <BookingRecordFilters selectedFilters={selectedFilters} booking={booking} />
      {!mobile && recordOptions}
      {mobile && (
        <>
          <RecordBody mobileWidth="100%" style={{ padding: '0 0 6px 0', borderBottomLeftRadius: '12px', borderBottomRightRadius: '12px' }} mobileFlexDirection="column" MobileGap={8}>
            <Divider color={Colors.white} />
            <RecordBody mobileWidth="100%" MobileGap={6} mobilePadding="0 8px 0 0" disableTruncate>
              {renderTrailingView()}
              {recordOptions}
            </RecordBody>
          </RecordBody>
        </>
      )}
    </RecordDataContainer>
  );

  return <BookingRecordContainer>{RecordItem}</BookingRecordContainer>;
};

export default BookingRecord;
