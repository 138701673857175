import React, { forwardRef, Ref, useEffect, useImperativeHandle, useState } from 'react';
import useIcons from '../../hooks/useIcons';
import { ModalContainer, ModalHeader, BackgroundOpacity, ModalDivider, CloseModal } from './styled';

let containerRef: any;
type ModalType = {
  content: ({
    toggleModal,
    openModal,
    closeModal
  }: {
    toggleModal: ({ byModal }: { byModal?: boolean }) => void;
    openModal: () => void;
    closeModal: ({ byModal }: { byModal?: boolean }) => void;
  }) => JSX.Element;
  title?: string;
  noHeader?: boolean;
  onClose?: () => void;
  onCloseBySave?: () => void;
  onOpen?: () => void;
  autoOpenModal?: boolean;
};

const openModal = (props: ModalType) => {
  containerRef.setProps(props);
  containerRef.openModal();
  return;
};

const ModalDialog = {
  openModal,
  setContainer: (ref: Ref<any>) => (containerRef = ref),
  closeModal: () => containerRef.closeModal(),
  isOpen: () => containerRef.isOpen()
};

export default ModalDialog;

export const ModalDialogElement = forwardRef(({}, ref: React.Ref<any>) => {
  const [visible, setVisible] = useState(false);
  const icons = useIcons();
  const defaultProps = {
    content: () => <></>
  };
  const [props, setProps] = useState<ModalType>(defaultProps);
  const [animationShowStatus, setAnimationShowStatus] = useState(false);

  const { content, onClose, title, onOpen, autoOpenModal = false, onCloseBySave, noHeader } = props;
  const toggleModal = (options: { byModal?: boolean } = { byModal: false }) => {
    const { byModal } = options;
    if (visible) {
      onClose?.();
      if (!byModal) {
        onCloseBySave?.();
      }
    }
    setVisible(!visible);
  };

  const openModal = () => {
    setVisible(true);
    onOpen?.();
  };

  const closeModal = (options: { byModal?: boolean } = { byModal: false }) => {
    const { byModal } = options;
    setVisible(false);
    onClose?.();
    if (!byModal) {
      onCloseBySave?.();
    }
  };

  const isOpen = () => visible;

  useImperativeHandle(ref, () => ({
    toggleModal,
    setProps,
    openModal,
    closeModal,
    isOpen
  }));

  useEffect(() => {
    if (visible) {
      setAnimationShowStatus(true);
    }
  }, [visible]);

  useEffect(() => {
    if (autoOpenModal) {
      openModal();
    }
  }, []);

  return (
    <>
      <BackgroundOpacity
        showModal={visible}
        onAnimationEnd={() => {
          if (!visible) {
            setAnimationShowStatus(false);
            setProps(defaultProps);
          }
        }}
      >
        <ModalContainer onMouseDown={e => e.stopPropagation()}>
          {!noHeader && (
            <ModalHeader>
              {title}
              <CloseModal bgImage={icons.closeModal.childImageSharp.gatsbyImageData.images.fallback.src} onClick={() => closeModal({ byModal: true })} />
            </ModalHeader>
          )}
          <ModalDivider />
          {animationShowStatus && content({ toggleModal, openModal, closeModal })}
        </ModalContainer>
      </BackgroundOpacity>
    </>
  );
});
