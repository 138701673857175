import React, { Fragment } from 'react';
import ChatDrawer from '../ChatDrawer/ChatDrawer';
import BookingDrawer from '../BookingsDrawer/BookingDrawer';
import OrderDrawer from '../OrderDrawer/OrderDrawer';
import PetDrawer from '../PetDrawer/PetDrawer';
import { CloseButton, ExtendButton, StoreEditButton } from './DrawerButtons';
import UserDrawer from '../UserDrawer/UserDrawer';
import { DrawerBar } from '../../reactive/main';
import { ProductDrawer } from '../StoreDrawers/ProductDrawer/ProductDrawer';
import SubscriptionDrawer from '../SubscriptionDrawer/SubscriptionDrawer';
import BranchMessageDrawer from '../StoreDrawers/BranchMessageDrawer/BranchMessageDrawer';
import Calendar from '../../views/Calendar/Calendar';
import BranchPetRecordTagDrawer from '../StoreDrawers/BranchPetRecordTagDrawer/BranchPetRecordTagDrawer';
import useMediaQuery from '../../hooks/useMediaQuery';
import BranchFormDrawer from '../StoreDrawers/BranchFormDrawer/BranchFormDrawer';
import GroupBookingsDrawer from '../BookingsDrawer/GroupBookingsDrawer';
import OrderInvoiceDrawer from '../OrderInvoiceDrawer/OrderInvoiceDrawer';
import SubmittedFormDrawer from '../../views/Pets/SubmittedFormDrawer';

export enum DRAWER_IDS {
  BOOKING_DRAWER = 'BOOKING_DRAWER',
  CHAT_DRAWER = 'CHAT_DRAWER',
  PETS_DRAWER = 'PETS_DRAWER',
  SERVICE_DRAWER = 'SERVICE_DRAWER',
  ORDER_DRAWER = 'ORDER_DRAWER',
  PRODUCT_DRAWER = 'PRODUCT_DRAWER',
  CALENDAR_DRAWER = 'CALENDAR_DRAWER',
  SUBSCRIPTION_DRAWER = 'SUBSCRIPTION_DRAWER',
  STORE_SUBSCRIPTION_DRAWER = 'STORE_SUBSCRIPTION_DRAWER',
  USER_DRAWER = 'USER_DRAWER',
  BRANCH_MESSAGE_DRAWER = 'BRANCH_MESSAGE_DRAWER',
  BRANCH_TAG_DRAWER = 'BRANCH_TAG_DRAWER',
  GROUP_BOOKINGS_DRAWER = 'GROUP_BOOKINGS_DRAWER',
  BRANCH_BILLING_DRAWER = 'BRANCH_BILLING_DRAWER',
  BRANCH_FORM_DRAWER = 'BRANCH_FORM_DRAWER',
  ORDER_INVOICE_DRAWER = 'ORDER_INVOICE_DRAWER',
  SUBMITTED_FORM_DRAWER = 'SUBMITTED_FORM_DRAWER'
}

export const DRAWER_BARS_COMPONENTS: Record<DRAWER_IDS, (props: { data: any }) => JSX.Element> = {
  [DRAWER_IDS.BOOKING_DRAWER]: BookingDrawer,
  [DRAWER_IDS.CHAT_DRAWER]: ChatDrawer,
  [DRAWER_IDS.PETS_DRAWER]: PetDrawer,
  [DRAWER_IDS.SERVICE_DRAWER]: ProductDrawer,
  [DRAWER_IDS.ORDER_DRAWER]: OrderDrawer,
  [DRAWER_IDS.PRODUCT_DRAWER]: ProductDrawer,
  [DRAWER_IDS.CALENDAR_DRAWER]: () => <Calendar />,
  [DRAWER_IDS.SUBSCRIPTION_DRAWER]: SubscriptionDrawer,
  [DRAWER_IDS.STORE_SUBSCRIPTION_DRAWER]: ProductDrawer,
  [DRAWER_IDS.USER_DRAWER]: UserDrawer,
  [DRAWER_IDS.BRANCH_MESSAGE_DRAWER]: BranchMessageDrawer,
  [DRAWER_IDS.BRANCH_TAG_DRAWER]: BranchPetRecordTagDrawer,
  [DRAWER_IDS.GROUP_BOOKINGS_DRAWER]: GroupBookingsDrawer,
  [DRAWER_IDS.BRANCH_BILLING_DRAWER]: UserDrawer,
  [DRAWER_IDS.BRANCH_FORM_DRAWER]: BranchFormDrawer,
  [DRAWER_IDS.ORDER_INVOICE_DRAWER]: OrderInvoiceDrawer,
  [DRAWER_IDS.SUBMITTED_FORM_DRAWER]: SubmittedFormDrawer
};

export const DRAWER_BARS_BUTTONS: Record<DRAWER_IDS, (props: { drawer: DrawerBar }) => JSX.Element> = {
  [DRAWER_IDS.BOOKING_DRAWER]: () => <Fragment />,
  [DRAWER_IDS.CHAT_DRAWER]: () => <Fragment />,
  [DRAWER_IDS.PETS_DRAWER]: ({ drawer }) => {
    const { mobile } = useMediaQuery({ mobile: true });
    return (
      <>
        <ExtendButton drawer={drawer} />
        {mobile && <CloseButton drawer={drawer} showAlways />}
      </>
    );
  },
  [DRAWER_IDS.SERVICE_DRAWER]: ({ drawer }) => <StoreEditButton drawer={drawer} />,
  [DRAWER_IDS.ORDER_DRAWER]: () => <Fragment />,
  [DRAWER_IDS.PRODUCT_DRAWER]: ({ drawer }) => <StoreEditButton drawer={drawer} />,
  [DRAWER_IDS.CALENDAR_DRAWER]: ExtendButton,
  [DRAWER_IDS.SUBSCRIPTION_DRAWER]: () => <Fragment />,
  [DRAWER_IDS.STORE_SUBSCRIPTION_DRAWER]: ({ drawer }) => <StoreEditButton drawer={drawer} />,
  [DRAWER_IDS.USER_DRAWER]: () => <Fragment />,
  [DRAWER_IDS.BRANCH_MESSAGE_DRAWER]: ({ drawer }) => <StoreEditButton drawer={drawer} />,
  [DRAWER_IDS.BRANCH_TAG_DRAWER]: ({ drawer }) => <StoreEditButton drawer={drawer} />,
  [DRAWER_IDS.GROUP_BOOKINGS_DRAWER]: () => <Fragment />,
  [DRAWER_IDS.BRANCH_BILLING_DRAWER]: () => <Fragment />,
  [DRAWER_IDS.BRANCH_FORM_DRAWER]: ({ drawer }) => <StoreEditButton drawer={drawer} />,
  [DRAWER_IDS.ORDER_INVOICE_DRAWER]: () => <Fragment />,
  [DRAWER_IDS.SUBMITTED_FORM_DRAWER]: () => <Fragment />
};
