import { PetRecord } from '../../components/Pets/types';

export enum ORDER_REPEAT_STATUS {
  REQUESTED = 'REQUESTED',
  PAYMENT_PENDING = 'PAYMENT_PENDING',
  PAYMENT_SUCCEEDED = 'PAYMENT_SUCCEEDED',
  PAYMENT_REJECTED = 'PAYMENT_REJECTED',
  CONFIRMED = 'CONFIRMED',
  CANCELED = 'CANCELED',
  REFUNDED = 'REFUNDED'
}

export type OrderSubscriptionRepeat = {
  id: string;
  createdAt: string;
  status: ORDER_REPEAT_STATUS;
  total: number;
  payment: {
    fail_reason?: string;
    invoice_id: string;
    payment_intent_id: string;
    status?: string;
    invoice_date?: string;
  };
  OrderItem: {
    quantity: number;
    customizations: {
      fields: {
        [key: string]: {
          price: number;
          title: string;
        };
      };
      quantity: number;
    }[];
    Order: {
      id: string;
      number: string;
      createdAt: string;
      AppUser: {
        id: string;
        name: string;
        profile_pic: string;
        addresses: {
          address_line_1: string;
          address_line_2: string;
          city: string;
          country: string;
          postcode: string;
        }[];
        email: string;
        postcode: string;
      };
      total: number;
    };
    item: {
      id: string;
      name: string;
      price: number;
    };
  };
  PetRecord: PetRecord;
};

export type Subscription = {
  id: string;
  createdAt: string;
  is_read_by_branch: boolean;
  last_order_date: string;
  period: string;
  status: keyof typeof SUBSCRIPTION_STATUS_TYPES;
  collection_status: keyof typeof SUBSCRIPTION_COLLECTION_STATUS_TYPES;
  trial_end: string;
  ended_at: string;
  start_date: string;
  next_date: string;
  OrderSubscriptionRepeats: OrderSubscriptionRepeat[];
};

export const SUBSCRIPTION_COLLECTION_STATUS_TYPES = {
  ACTIVE: 'ACTIVE',
  PAUSED: 'PAUSED'
};

export const SUBSCRIPTION_TABS_TYPES = {
  ACTIVE: 'active',
  PAUSED: 'PAUSED',
  CANCELED: 'cancelled'
};

export const SUBSCRIPTION_STATUS_TYPES = {
  CONFIRMED: 'CONFIRMED',
  CANCELED: 'CANCELED',
  REQUESTED: 'REQUESTED'
};

export type SubscriptionCounts = {
  totalOrderSubscriptionsCount: number;
  totalOrderSubscriptionsAmount: number;
  activeOrderSubscriptionsCount: number;
  activeOrderSubscriptionsAmount: number;
  canceledOrderSubscriptionsCount: number;
  canceledOrderSubscriptionsAmount: number;
  pausedOrderSubscriptionsCount: number;
  pausedOrderSubscriptionsAmount: number;
  onTrialSubscriptionsCount: number;
  onTrialSubscriptionsAmount: number;
};
