import React from 'react';
import { ViewContainer } from '../../components/Shared/Shared';
import useSelectedState from '../../hooks/useSelectedState';
import SubscriptionsBody from './SubscriptionsBody';
import SubscriptionsHeader from './SubscriptionsHeader';
import { SUBSCRIPTION_COLLECTION_STATUS_TYPES, SUBSCRIPTION_STATUS_TYPES, SUBSCRIPTION_TABS_TYPES } from './types';

const Subscriptions = () => {
  const selectedTabsState = useSelectedState({ defaultTab: SUBSCRIPTION_TABS_TYPES.ACTIVE, TABS_TYPES: SUBSCRIPTION_TABS_TYPES });

  const tabs: { name: string; status: string[]; otherVariables: Record<string, any> }[] = [
    {
      name: SUBSCRIPTION_TABS_TYPES.ACTIVE,
      status: [SUBSCRIPTION_STATUS_TYPES.CONFIRMED],
      otherVariables: {
        collectionStatus: [SUBSCRIPTION_COLLECTION_STATUS_TYPES.ACTIVE],
        order_by_end_date: false,
        order_by_name: false
      }
    },
    {
      name: SUBSCRIPTION_TABS_TYPES.PAUSED,
      status: [SUBSCRIPTION_STATUS_TYPES.CONFIRMED],
      otherVariables: {
        collectionStatus: [SUBSCRIPTION_COLLECTION_STATUS_TYPES.PAUSED],
        order_by_end_date: false,
        order_by_name: false
      }
    },
    {
      name: SUBSCRIPTION_TABS_TYPES.CANCELED,
      status: [SUBSCRIPTION_STATUS_TYPES.CANCELED],
      otherVariables: {
        collectionStatus: null,
        order_by_end_date: false,
        order_by_name: false
      }
    }
  ];

  return (
    <ViewContainer>
      <SubscriptionsHeader tabs={tabs} selectedTabsState={selectedTabsState} />
      <SubscriptionsBody tabs={tabs} selectedTabsState={selectedTabsState} />
    </ViewContainer>
  );
};

export default Subscriptions;
