import { useQuery } from '@apollo/client';
import React from 'react';
import useIcons from '../../hooks/useIcons';
import { GetBusUserProfile } from '../../queries';
import { logout } from '../../utils/auth';
import { BusinessUserImage, BusinessUserImageContainer, BusinessUserName, DefaultBusinessUserImage, LogOutButton, ServiceName } from './styled';

export default function BusinessUserContent() {
  const { data: { getBusUserProfile: profile = {} } = {}, loading } = useQuery(GetBusUserProfile, {
    fetchPolicy: 'cache-and-network'
  });
  const icons = useIcons();

  return loading ? (
    <span>loading...</span>
  ) : (
    <BusinessUserImageContainer>
      {!profile?.profile_pic && <DefaultBusinessUserImage image={icons?.user?.childImageSharp?.gatsbyImageData} alt="Business user" />}
      {profile?.profile_pic && <BusinessUserImage src={profile.profile_pic} alt="Business user" />}
      <BusinessUserName>{profile?.name}</BusinessUserName>
      <ServiceName>{profile?.Branch?.name || profile?.Provider?.name}</ServiceName>
      <LogOutButton onClick={logout}>Log Out</LogOutButton>
    </BusinessUserImageContainer>
  );
}
