import React from 'react';
import { RecordsContainer } from '../../styled';
import ProductsHeader from './ProductsHeader';
import ProductsBody from './ProductsBody';

const Products = () => {
  return (
    <RecordsContainer>
      <ProductsHeader />
      <ProductsBody />
    </RecordsContainer>
  );
};

export default Products;
