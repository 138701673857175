import { useQuery } from '@apollo/client';
import React from 'react';
import { DRAWER_IDS } from '../../../components/DrawerBar/types';
import { Pet } from '../../../components/Pets/types';
import { GetBranchPetProfile, GetPetBehaviors } from '../../../queries';
import { vars } from '../../../reactive';
import { EditPetRecordFeedingRoutine, EditPetRecordMeals, EditPetRecordTreatmentRoutine } from '../Health/Health';
import HealthPetRecordDiet, { PetFeedingRoutineBehavior, PetTreatmentRoutineBehavior } from '../Health/HealthPetRecordDiet';
import { Divider, PetRecordEntitiesContainer, SectionContainer, VetDaycareTitle } from '../Health/styled';

const Diet = () => {
  const petId = vars.drawerBars().find(drawer => drawer.drawerId === DRAWER_IDS.PETS_DRAWER)?.recordData;
  const { data: { getBranchPetProfile: pet = {} } = {} } = useQuery(GetBranchPetProfile, {
    variables: { id: petId },
    fetchPolicy: 'cache-and-network'
  });

  const { data: { getPetBehaviors: petBehaviors } = {} } = useQuery<{ getPetBehaviors: Pet['PetRecord']['PetBehavior'][] }>(GetPetBehaviors, {
    variables: { PetRecordId: pet?.PetRecord?.id },
    fetchPolicy: 'cache-and-network'
  });

  const petProfileBehavior = petBehaviors?.[0];

  return (
    <div style={{ flex: 1, overflow: 'auto', flexDirection: 'column' }}>
      <SectionContainer>
        <PetRecordEntitiesContainer column>
          <VetDaycareTitle>
            <span>Diet & Feeding Schedule</span>
            <EditPetRecordMeals pet={pet} />
          </VetDaycareTitle>
          <Divider />
          <HealthPetRecordDiet pet={pet} />
        </PetRecordEntitiesContainer>
      </SectionContainer>
      {petProfileBehavior && (
        <SectionContainer>
          <PetRecordEntitiesContainer column>
            <VetDaycareTitle>
              <span>Feeding Routine</span>
              <EditPetRecordFeedingRoutine petProfileBehavior={petProfileBehavior} />
            </VetDaycareTitle>
            <Divider />
            <PetFeedingRoutineBehavior petBehavior={petProfileBehavior} />
          </PetRecordEntitiesContainer>
        </SectionContainer>
      )}

      {petProfileBehavior && (
        <SectionContainer>
          <PetRecordEntitiesContainer column>
            <VetDaycareTitle>
              <span>Treatment Routine</span>
              <EditPetRecordTreatmentRoutine petProfileBehavior={petProfileBehavior} />
            </VetDaycareTitle>
            <Divider />
            <PetTreatmentRoutineBehavior petBehavior={petProfileBehavior} />
          </PetRecordEntitiesContainer>
        </SectionContainer>
      )}
    </div>
  );
};

export default Diet;
