import { useLazyQuery, useMutation } from '@apollo/client';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { GetBranchPetRecordTags, GetBranchPets, ReplaceBranchPetRecordItemTag } from '../../queries';
import client from '../../utils/client';
import BranchTagItemModal from '../../views/Store/BranchTags/BranchTagItemModal';
import BranchTagsModal from '../../views/Store/BranchTags/BranchTagsModal';
import { BRANCH_TAGS_TYPES, BranchAppointmentTag, BranchPetRecordTag } from '../../views/Store/BranchTags/types';
import Common from '../../views/Store/Common';
import ModalDialog from '../Modal/ModalDialog';
import { Pet } from './types';
import { checkAddPetTagCapacity } from './utils';

type PetTagsModalProps = {
  pets: Pet[];
  refetchPetsAfterTag?: boolean;
  afterTag?: (newPets: Pet[]) => void;
  extraTags?: BranchPetRecordTag[];
};

const PetTagsModal: FC<PetTagsModalProps> = ({ pets, refetchPetsAfterTag, afterTag, extraTags }) => {
  const formOptions = useForm();
  const [loading, setLoading] = useState(false);
  const { handleSubmit } = formOptions;
  const type = BRANCH_TAGS_TYPES.PET_RECORD;
  const [replacePetTags, { loading: loadingAddPetTag, data: repalcedTags = {} }] = useMutation(ReplaceBranchPetRecordItemTag);
  const [refetchTagsById, { loading: loadingRefetchTags, data: { getBranchPetRecordTags: refetchedTags = [] } = {} }] = useLazyQuery<{ getBranchPetRecordTags: BranchPetRecordTag[] }>(
    GetBranchPetRecordTags,
    {
      fetchPolicy: 'cache-and-network'
    }
  );

  const allPetsTags = pets?.flatMap(pet => pet?.PetRecord?.BranchPetRecordTags || []);

  const sharedTagsBetweenPets = allPetsTags.filter(tag => pets?.every(pet => pet?.PetRecord?.BranchPetRecordTags?.some(t => t?.id === tag?.id)));

  const uniqueSharedTags = [...new Set(sharedTagsBetweenPets?.map(tag => tag?.id))].map(id => allPetsTags?.find(tag => tag?.id === id)!).concat(extraTags || []);

  const uniqueTagsRef = useRef<string[]>([]);
  const allTagsRef = useRef<{ tags: BranchPetRecordTag[] }>({ tags: [] });

  const onSubmit = ({ selectedTags }) => {
    const tagIds = selectedTags.map((tag: any) => tag.value);
    const existingTagIds = allPetsTags?.map(tag => tag?.id);
    const uniqueTagIds = [...new Set([...tagIds, ...existingTagIds])];

    uniqueTagsRef.current = uniqueTagIds;

    checkAddPetTagCapacity({
      handleAddTag: () => {
        replacePetTags({ variables: { petRecordId: pets.map(({ PetRecord }) => PetRecord?.id), BranchPetRecordTagId: tagIds } });
      },
      pets,
      tags: allTagsRef?.current?.tags?.filter(tag => tagIds.includes(tag?.id))
    });
  };

  useEffect(() => {
    if (repalcedTags?.replaceBranchPetRecordTagItems !== undefined) {
      const uniqueTagIds = uniqueTagsRef.current;
      refetchTagsById({ variables: { id: uniqueTagIds } });
    }
  }, [repalcedTags]);

  useEffect(() => {
    if (refetchedTags?.length) {
      setLoading(true);
      (async () => {
        let newPets: Pet[] = [];
        if (pets?.length) {
          const { data: { getBranchPets: updatedPets = [] } = {} } = await client.query<{ getBranchPets: Pet[] }>({
            query: GetBranchPets,
            variables: { pet_id: pets.map(({ id }) => id), requisite_queries: ['pet_id'], alternative_queries: [], offset: 0, limit: 1000 },
            fetchPolicy: 'network-only'
          });
          newPets = updatedPets;
        }
        afterTag?.(newPets);
      })();

      if (!refetchPetsAfterTag) {
        ModalDialog.closeModal();
        setLoading(false);
        return;
      }

      const refetchPets = Common.get<() => Promise<void>>(`Pets.GetBranchPets.refetch`);
      if (refetchPets) {
        refetchPets()?.then(() => {
          setLoading(false);
          ModalDialog.closeModal();
        });
      }
    }
  }, [refetchedTags]);

  const onAddTag = useCallback(
    (newTag: BranchPetRecordTag | BranchAppointmentTag, type: BRANCH_TAGS_TYPES) => {
      const newExtraTags = [...(extraTags || []), ...(type === BRANCH_TAGS_TYPES.PET_RECORD ? [newTag as BranchPetRecordTag] : [])];
      ModalDialog.openModal({
        content: () => <PetTagsModal pets={pets} refetchPetsAfterTag={refetchPetsAfterTag} afterTag={afterTag} extraTags={newExtraTags} />,
        title: pets.length > 1 ? 'Tag all' : `${pets?.[0]?.name}'s Tags`
      });
    },
    [pets, refetchPetsAfterTag, afterTag, extraTags]
  );

  const handleAddBranchTag = (tag: string) => {
    ModalDialog.openModal({
      content: () => <BranchTagsModal name={tag} presetType={type} onAddTag={onAddTag} />,
      title: 'Add New Smart Tag'
    });
  };
  return (
    <BranchTagItemModal
      formOptions={formOptions}
      existingTagsItems={uniqueSharedTags}
      onSubmit={onSubmit}
      loading={loadingAddPetTag || loadingRefetchTags || loading}
      handleAddBranchTag={handleAddBranchTag}
      type={type}
      ref={allTagsRef}
    />
  );
};

export default PetTagsModal;
