import React, { FC, useEffect, useMemo } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import RRule, { Options } from 'rrule';
import { sentenceCase } from 'sentence-case';
import { FlexCenterContainer } from '../../../components/Pets/styled';
import DurationSelector from '../../../components/Shared/DurationSelector';
import { FormError, FormInput, FormLabel, FormRadio, FormSelect, InputContainer, InputsRow, InputsWrapper, RadioBtnsGroup, WideInputGroup } from '../../../components/Shared/Forms/Forms';
import { THREE_LETTER_WEEK_DAYS, WEEK_DAYS, WEEK_DAYS_MAP, rruleToReadableTime } from '../../../utils/dates';
import { getKeys } from '../../../utils/helpers';
import OperatingSlots from '../Operations/OperatingSlots';
import { ProductOptionsTypes, getSelectedProductOptions } from '../ProductOptions';
type BookingTypeProps = {
  control: ReturnType<typeof useForm>['control'];
  service: Record<string, any>;
  errors: ReturnType<typeof useForm>['errors'];
};

enum SlotOffsetTypes {
  NO_OFFSET = 'NO_OFFSET',
  START_DATE = 'START_DATE',
  SLOT_TIME_OFFSET = 'SLOT_TIME_OFFSET'
}

type Frequencies = 'WEEKLY' | 'MONTHLY' | 'YEARLY';

const frequencies: { label: string; value: Frequencies; title: string }[] = [
  { label: 'Monthly', value: 'MONTHLY', title: 'Month' },
  { label: 'Weekly', value: 'WEEKLY', title: 'Week' },
  { label: 'Yearly', value: 'YEARLY', title: 'Year' }
];

const nthDays: { [key in Frequencies]: number[] } = {
  MONTHLY: Array.from(Array(4).keys()).map(i => i + 1),
  WEEKLY: [1],
  YEARLY: Array.from(Array(56).keys()).map(i => i + 1)
};

const rruleToText = (options: Partial<Options>) => {
  try {
    const rrule = new RRule({
      ...options
    }).toText();

    return typeof rrule === 'string' ? sentenceCase(rrule) : 'Wrong format';
  } catch (e) {
    return 'Wrong format';
  }
};

const getFullWeekDayFrom2Letter = (day: string) => WEEK_DAYS[WEEK_DAYS_MAP.findIndex(weekDay => weekDay.toLowerCase() === day.toLowerCase())];

const BookingType: FC<BookingTypeProps> = ({ control, service, errors }) => {
  const defaultSelectedTab = (service?.booking_type === 'SLOT' ? (service.slots.length === 1 ? 'OTHER' : 'SLOT') : service?.booking_type) || 'SLOT';
  const defaultRRule = new RRule({
    freq: RRule.WEEKLY,
    interval: 1,
    count: 1,
    byweekday: [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR, RRule.SA, RRule.SU],
    byhour: [1],
    byminute: [0]
  }).toString();
  const options = RRule.fromString(service?.slots?.[0]?.time || defaultRRule || '').origOptions;
  const defaultFrequency = RRule.FREQUENCIES[options?.freq!] as Frequencies;
  const defaultCount = options.count;
  const defaultWeekdays = options?.byweekday?.map(({ weekday }) => {
    return WEEK_DAYS_MAP[weekday];
  }) as typeof WEEK_DAYS_MAP;

  const defaultTime = rruleToReadableTime(options);
  const defaultSlots = service?.slots?.[0]?.available;
  const defaultNthValues = options?.byweekday?.map(({ n }) => n);
  const defaultInterval = options?.interval;

  const { booking_type } = useWatch({ control, name: ['booking_type'], defaultValue: { booking_type: service?.booking_type } });
  const time = useWatch({ control, name: 'time', defaultValue: defaultTime || '' });
  const selectedDays = useWatch({ control, name: 'selectedDays', defaultValue: defaultWeekdays || [] });
  const frequency = useWatch({ control, name: 'frequency', defaultValue: defaultFrequency || frequencies[0].value });
  const interval = useWatch({ control, name: 'interval', defaultValue: defaultInterval || 1 });
  const count = useWatch({ control, name: 'count', defaultValue: defaultCount || 0 });
  const nthDay = useWatch({ control, name: 'nth' }) as { [key in 'MO' | 'TU' | 'WE' | 'TH' | 'FR' | 'SA' | 'SU']: number };

  useEffect(() => {
    control.setValue('interval', 1);
    if (nthDay) {
      const allNthDays = Object?.keys(nthDay);

      allNthDays?.forEach(key => {
        control.setValue(`nth.${key}`, 0);
      });
    }
  }, [frequency]);

  const watchedProductOptions = useWatch({
    control,
    name: 'productOptions'
  }) as ProductOptionsTypes[];

  const isRecurring = getSelectedProductOptions(watchedProductOptions).allows_repeated_orders;
  const isMultiday = booking_type === 'MULTI_DAY';
  const isMultiSlot = booking_type === 'MULTI_SLOT';
  const isSlot = booking_type === 'SLOT';
  const isOther = booking_type === 'OTHER';

  const typesOptions = useMemo(() => {
    if (isRecurring) {
      return [{ id: 'SLOT', name: 'Slot' }];
    }
    return [
      { id: 'SLOT', name: 'Slot' },
      { id: 'MULTI_SLOT', name: 'Multi Slot' },
      { id: 'MULTI_DAY', name: 'Multi Day' },
      { id: 'OTHER', name: 'Other' }
    ];
  }, [isRecurring]);

  const operatingTimes = service?.slots || [];

  useEffect(() => {
    if (isRecurring) {
      control.setValue('booking_type', 'SLOT');
      return;
    }

    control.setValue('booking_type', defaultSelectedTab);
  }, [isRecurring]);

  const defaultSlotOffsetType = service?.slots_start_date ? SlotOffsetTypes.START_DATE : service?.max_slot_offset ? SlotOffsetTypes.SLOT_TIME_OFFSET : SlotOffsetTypes.NO_OFFSET;
  const slotsOffsetType = useWatch({ control, name: 'slotsOffsetType', defaultValue: defaultSlotOffsetType });

  return (
    <InputsWrapper noWrap>
      <WideInputGroup>
        <FormLabel>Booking type</FormLabel>
        <FlexCenterContainer>
          <Controller as={<FormRadio itemsArray={typesOptions} error={errors.booking_type} fontSize={14} />} control={control} name={'booking_type'} defaultValue={defaultSelectedTab} />
        </FlexCenterContainer>
        {errors.booking_type && <FormError>{errors.booking_type.message || 'type is required'}</FormError>}
      </WideInputGroup>
      {(isMultiSlot || isSlot) && (
        <OperatingSlots
          key={String(isRecurring)}
          hideAvailability={isMultiSlot}
          control={control}
          errors={errors}
          operatingTimes={operatingTimes}
          title={'Service Slots'}
          hideMaxSlots
          isRecurring={isRecurring}
        />
      )}

      {!isRecurring && isMultiSlot && (
        <WideInputGroup>
          <FormLabel>Slots Recurrence (weeks)</FormLabel>
          <Controller
            as={<FormInput error={errors.slots_recurrence} type={'number'} height={32} fontSize={16} />}
            control={control}
            name={'slots_recurrence'}
            defaultValue={service?.slots_recurrence || '1'}
            rules={{
              required: true,
              min: 1,
              max: 14
            }}
          />
          {errors.slots_recurrence && <FormError>{errors.slots_recurrence.message || 'slots recurrence is required'}</FormError>}
        </WideInputGroup>
      )}

      {!isRecurring && isOther && (
        <>
          <WideInputGroup>
            <>
              <InputContainer isRow flex={1}>
                <Controller
                  render={({ onChange, value }) => (
                    <RadioBtnsGroup
                      options={WEEK_DAYS_MAP}
                      defaultValue={value}
                      onChange={e => {
                        value.includes(e.target.value) ? onChange([...value].filter(v => v !== e.target.value)) : onChange([...value, e.target.value]);
                      }}
                      inputType={'checkbox'}
                      name={`selectedDays`}
                      noLabel
                      customLabel={THREE_LETTER_WEEK_DAYS.map(day => sentenceCase(day))}
                    />
                  )}
                  control={control}
                  name={`selectedDays`}
                  defaultValue={defaultWeekdays || []}
                />
              </InputContainer>
              <InputsRow>
                <InputContainer width={16}>
                  <FormLabel error={errors['time']}>Time</FormLabel>
                  <Controller
                    as={<FormInput error={errors['time']} height={20} fontSize={16} type={'time'} width={80} />}
                    control={control}
                    name={'time'}
                    defaultValue={defaultTime || ''}
                    rules={{
                      required: true,
                      validate: val => val.split(':')[0] !== '00'
                    }}
                  />
                  {errors['time'] && <FormError>{errors['time'].message || 'Time is required'}</FormError>}
                </InputContainer>

                <InputContainer width={10}>
                  <FormLabel error={errors['slots']}>Slots</FormLabel>
                  <Controller
                    as={<FormInput type="number" error={errors['slots']} height={20} fontSize={16} />}
                    control={control}
                    name={'slots'}
                    defaultValue={defaultSlots || 0}
                    rules={{ required: true }}
                  />
                  {errors['slots'] && <FormError>{errors['slots'].message || 'Slots is required'}</FormError>}
                </InputContainer>
              </InputsRow>

              <WideInputGroup>
                <FormLabel error={errors?.frequency}>Frequency ({rruleToText({ freq: RRule[frequency!] })})</FormLabel>
                <Controller
                  render={({ onChange, value }) => (
                    <FormSelect
                      error={errors?.frequency}
                      height={48}
                      fontSize={16}
                      name={'frequency'}
                      onChange={e => {
                        onChange(e.target.value);
                      }}
                      value={value || ''}
                    >
                      {frequencies?.map((freq, index) => (
                        <option key={index} value={freq.value}>
                          {freq.label}
                        </option>
                      ))}
                    </FormSelect>
                  )}
                  control={control}
                  name={'frequency'}
                  defaultValue={defaultFrequency || frequencies[0].value}
                  rules={{ required: true }}
                />
                {errors?.frequency && <FormError>{errors?.frequency.message || 'Frequency is required'}</FormError>}
              </WideInputGroup>

              <WideInputGroup>
                <FormLabel error={errors?.interval}>
                  Interval (
                  {rruleToText({
                    freq: RRule[frequency!],
                    interval
                  })}
                  )
                </FormLabel>
                <Controller
                  render={({ onChange, value }) => <FormInput value={value} onChange={onChange} min={1} max={100} type={'number'} defaultValue={defaultInterval || 1} error={errors?.interval} />}
                  control={control}
                  name={'interval'}
                  defaultValue={defaultInterval || 1}
                  rules={{ required: true, max: 100, min: 1 }}
                />
                {errors?.interval && <FormError>{errors?.interval || 'Interval is required'}</FormError>}
              </WideInputGroup>

              <WideInputGroup>
                <FormLabel error={errors?.count}>
                  Count (
                  {rruleToText({
                    freq: RRule[frequency!],
                    interval,
                    count: +count === 0 ? undefined : count
                  })}
                  ){+count === 0 && ' (Infinite)'}
                </FormLabel>
                <Controller
                  as={<FormInput error={errors?.count} height={48} fontSize={16} name={'count'} type={'number'} min={0} max={100} defaultValue={defaultCount || 0} />}
                  control={control}
                  name={'count'}
                  defaultValue={defaultCount || 0}
                  rules={{ min: 0, max: 100 }}
                />
                {errors?.count && <FormError>{errors?.count.message || 'Please enter a valid number from 1 - 100'}</FormError>}
              </WideInputGroup>

              {frequency !== 'WEEKLY' &&
                selectedDays.map((day, index: number) => (
                  <WideInputGroup>
                    <FormLabel error={errors[`nth.${day}`]}>{getFullWeekDayFrom2Letter(day)}</FormLabel>
                    <Controller
                      render={({ onChange, value }) => (
                        <FormSelect
                          error={errors[`nth.${day}`]}
                          height={48}
                          fontSize={16}
                          name={`nth.${day}`}
                          onChange={e => {
                            onChange(e.target.value);
                          }}
                          value={value || ''}
                        >
                          <option value={0}>
                            {rruleToText({
                              freq: RRule[frequency!],
                              interval,
                              byweekday: RRule[day]
                            })}{' '}
                            (All {getFullWeekDayFrom2Letter(day)}s)
                          </option>
                          {nthDays[frequency!]?.map((nthDay, index: number) => (
                            <option key={index} value={nthDay}>
                              {rruleToText({
                                freq: RRule[frequency!],
                                interval,
                                byweekday: RRule[day].nth(nthDay)
                              })}
                            </option>
                          ))}
                        </FormSelect>
                      )}
                      control={control}
                      name={`nth.${day}`}
                      defaultValue={defaultNthValues?.[index] || 0}
                    />
                    {errors?.[`nth.${day}`] && <FormError>{errors?.[`nth.${day}`].message || `This field is required`}</FormError>}
                  </WideInputGroup>
                ))}

              <FormLabel>
                Schedule:{' '}
                {rruleToText({
                  freq: RRule[frequency],
                  interval: +interval,
                  count: +count,
                  byhour: [+time.split(':')[0]],
                  byminute: [+time.split(':')[1]],
                  bysecond: [0],
                  byweekday: selectedDays.map((day: any) => (+nthDay?.[day] ? RRule[day].nth(+nthDay[day]) : RRule[day]))
                })}{' '}
                at {time}
              </FormLabel>
            </>
          </WideInputGroup>
        </>
      )}

      {!isRecurring && isMultiday && (
        <>
          <WideInputGroup>
            <FormLabel error={errors?.slots}>Slots</FormLabel>
            <Controller
              as={<FormInput type="number" error={errors?.slots} height={20} fontSize={16} />}
              control={control}
              name={'slots'}
              defaultValue={defaultSlots || 0}
              rules={{
                required: true,
                validate: (value: any) => {
                  if (+value === 0) {
                    return 'Please enter a valid number from 1 - 100';
                  }
                }
              }}
            />
            {errors['slots'] && <FormError>{errors['slots'].message || 'Slots is required'}</FormError>}
          </WideInputGroup>
          {/* <WideInputGroup>
            <FormLabel error={errors?.start_date}>Start Date</FormLabel>
            <Controller
              as={<FormInput error={errors?.start_date} height={48} fontSize={16} name={'start_date'} type={'datetime-local'} />}
              control={control}
              name={'start_date'}
              defaultValue={''}
              rules={{ required: true }}
            />
            {errors?.start_date && <FormError>{errors?.start_date.message || 'Start Date is required'}</FormError>}
          </WideInputGroup>

          <WideInputGroup>
            <FormLabel error={errors?.end_date}>End Date</FormLabel>
            <Controller
              as={<FormInput error={errors?.end_date} height={48} fontSize={16} name={'end_date'} type={'datetime-local'} />}
              control={control}
              name={'end_date'}
              defaultValue={''}
              rules={{ required: true }}
            />
            {errors?.end_date && <FormError>{errors?.end_date.message || 'End Date is required'}</FormError>}
          </WideInputGroup> */}
        </>
      )}

      {!isMultiSlot && (
        <WideInputGroup>
          <FormLabel>Slots Offset (specify how many days in advance the customer can book)</FormLabel>
          <Controller
            render={({ onChange, value }) => (
              <FormSelect
                error={errors.slots_offset}
                height={48}
                fontSize={16}
                name={'slotsOffsetType'}
                onChange={e => {
                  onChange(e.target.value);
                }}
                value={value || ''}
              >
                {getKeys(SlotOffsetTypes).map(key => (
                  <option key={key} value={SlotOffsetTypes[key]}>
                    {sentenceCase(key)}
                  </option>
                ))}
              </FormSelect>
            )}
            control={control}
            name={'slotsOffsetType'}
            defaultValue={slotsOffsetType || ''}
            rules={{ required: true }}
          />
        </WideInputGroup>
      )}

      {(slotsOffsetType === SlotOffsetTypes.START_DATE || isMultiSlot) && (
        <WideInputGroup>
          <FormLabel>Slots start date (set a start date for slots)</FormLabel>
          <Controller
            as={<FormInput error={errors.slots_start_date} type={'date'} />}
            control={control}
            name={'slots_start_date'}
            defaultValue={service?.slots_start_date || new Date().toISOString().split('T')[0]}
            rules={{
              required: true,
              validate: val => !!new Date(val).getTime()
            }}
          />
          {errors.slots_start_date && <FormError>{errors.slots_start_date.message || 'Start date is invalid'}</FormError>}
        </WideInputGroup>
      )}

      {slotsOffsetType === SlotOffsetTypes.SLOT_TIME_OFFSET && !isMultiSlot && (
        <DurationSelector
          formOptions={{ control, errors }}
          defaultDuration={(service?.max_slot_offset || 0) / 1000}
          options={{
            title: 'Slots offset time (days) (hours) (minutes) before the slot start date that the customer can book',
            includeDays: true,
            name: 'max_slot_offset'
          }}
        />
      )}
    </InputsWrapper>
  );
};

export default BookingType;
