import React, { FC, useCallback, Fragment, useEffect } from 'react';
import { VaccRecord as VaccRecordType } from '../../../../components/Pets/types';
import { EditVaccRecord, GetAllPetRecordRecordsVaccRecords } from '../../../../queries';
import { useMutation, useQuery } from '@apollo/client';
import { Controller, UseFormMethods, useForm } from 'react-hook-form';
import { ModalBody, ModalFooter } from '../../../../components/Modal/styled';
import { Container } from '../../../../components/Shared/Shared';
import { CenteredLoader } from '../../../../components/Shared/Spinner';
import { FormButtonsContainer, FormCheckbox, FormLabel, FormSubmitButton, InputsWrapper, WideInputGroup } from '../../../../components/Shared/Forms/Forms';
import { VaccRecord } from '../../Health/HealthPetRecords';
import Alert from '../../../../components/Alert/Alert';
import ModalDialog from '../../../../components/Modal/ModalDialog';

type EndVaccinationsModalProps = {
  PetRecordId: string;
};

type EndVaccinationsModalFormValues = {
  selectedVaccRecordIds: string[];
};

const EndVaccinationsModalForm = ({
  formOptions,
  vaccRecords,
  loading,
  loadingSubmit,
  onSubmit
}: {
  formOptions: UseFormMethods<EndVaccinationsModalFormValues>;
  vaccRecords: VaccRecordType[];
  loading: boolean;
  onSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  loadingSubmit: boolean;
}) => {
  const { control, watch } = formOptions;

  const watchedValues = watch(['selectedVaccRecordIds']);

  const renderVaccRecordItem = useCallback(
    ({ id }: { id: string }) => {
      const vaccRecord = vaccRecords.filter(({ id: vaccRecordId }) => vaccRecordId === id)[0];
      return (
        <Fragment key={id}>
          {vaccRecord && (
            <VaccRecord
              vaccRecord={vaccRecord}
              style={{
                width: '100%'
              }}
            />
          )}
        </Fragment>
      );
    },
    [vaccRecords]
  );

  return (
    <>
      <ModalBody>
        <Container>
          {loading && <CenteredLoader size={15} />}
          {!loading && (
            <InputsWrapper noWrap>
              <WideInputGroup>
                <FormLabel>Select Vaccines</FormLabel>
                <Controller
                  render={({ onChange, value }) => (
                    <FormCheckbox onChange={onChange} value={value} itemsArray={vaccRecords.map(({ id, name }) => ({ id, name }))} fontSize={16} column renderItem={renderVaccRecordItem} reversed />
                  )}
                  control={control}
                  name={'selectedVaccRecordIds'}
                  rules={{ required: true, validate: value => value.length }}
                  defaultValue={''}
                />
              </WideInputGroup>
            </InputsWrapper>
          )}
        </Container>
      </ModalBody>
      <ModalFooter>
        <FormButtonsContainer>
          <FormSubmitButton loading={loadingSubmit} onClick={onSubmit} disabled={!watchedValues.selectedVaccRecordIds?.length}>
            End Vaccines
          </FormSubmitButton>
        </FormButtonsContainer>
      </ModalFooter>
    </>
  );
};

const EndVaccinationsModal: FC<EndVaccinationsModalProps> = ({ PetRecordId }) => {
  const {
    data: { getPetRecordRecords: { VaccRecords = [] } = {} } = {},
    loading: loadingVaccRecords,
    refetch
  } = useQuery<{
    getPetRecordRecords: {
      VaccRecords: VaccRecordType[];
    };
  }>(GetAllPetRecordRecordsVaccRecords, {
    variables: { PetRecordId, filter_by_active: true },
    skip: !PetRecordId,
    fetchPolicy: 'cache-and-network'
  });

  const loading = loadingVaccRecords;

  const [editVaccRecords, { data: editedVaccRecords, loading: loadingSubmit }] = useMutation(EditVaccRecord);

  const sortedVaccRecords = [...(VaccRecords || [])].sort((a, b) => {
    const aDate = new Date(a?.dates?.[a?.dates?.length - 1]);
    const bDate = new Date(b?.dates?.[b?.dates?.length - 1]);
    return bDate.getTime() - aDate.getTime();
  });

  const formOptions = useForm<EndVaccinationsModalFormValues>({
    defaultValues: {
      selectedVaccRecordIds: []
    }
  });

  const { handleSubmit } = formOptions;

  const onSubmit = handleSubmit(async ({ selectedVaccRecordIds }) => {
    Alert.alert({
      title: 'Mark Vaccines as inactive?',
      description: 'This will end the vaccine cycle and mark it as inactive, this action is irreversible. Are you sure you want to continue?',
      acceptButtonText: 'Yes, end cycle',
      denyButtonText: 'No',
      onAccept: () => {
        editVaccRecords({
          variables: {
            VaccRecordId: selectedVaccRecordIds,
            VaccRecord: {
              end_date: new Date().toISOString(),
              nextdate: null
            }
          }
        });
      }
    });
  });

  useEffect(() => {
    if (editedVaccRecords) {
      refetch();
      ModalDialog.closeModal();
    }
  }, [editedVaccRecords]);

  return <EndVaccinationsModalForm formOptions={formOptions} vaccRecords={sortedVaccRecords} loading={loading} onSubmit={onSubmit} loadingSubmit={loadingSubmit} />;
};

export default EndVaccinationsModal;
