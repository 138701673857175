import React, { useCallback, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { Controller, useForm } from 'react-hook-form';
import { ModalBody, ModalFooter } from '../../../../components/Modal/styled';
import { Container } from '../../../../components/Shared/Shared';
import { ActionBtn } from '../../../Store/styled';
import { FormButtonsContainer, FormError, FormInput, FormLabel, FormSubmitButton, FormTextArea, InputsWrapper, WideInputGroup } from '../../../../components/Shared/Forms/Forms';
import { GetMedCondsByPetRecordId, MedCondAdd, MedCondEdit } from '../../../../queries';
import useIcons from '../../../../hooks/useIcons';

import ModalDialog from '../../../../components/Modal/ModalDialog';

export const AddNewMedicalCase = ({ petId, petRecordId, onClose, autoOpenModal }: { petId: string; petRecordId: string; autoOpenModal?: boolean; onClose: () => void }) => {
  const icons = useIcons();
  const addIcon = icons.add.childImageSharp.gatsbyImageData.images.fallback.src;

  const showModal = useCallback(() => {
    ModalDialog.openModal({ content: () => <CaseModal petId={petId} petRecordId={petRecordId} />, title: 'New Case', onClose });
  }, [petId, petRecordId, onClose]);

  useEffect(() => {
    if (autoOpenModal) {
      showModal();
    }
  }, [autoOpenModal, showModal]);

  return <ActionBtn bgImage={addIcon} onClick={showModal} />;
};

export const EditCase = ({ medCase, autoOpenModal = false }: { medCase: Record<string, any>; autoOpenModal?: boolean }) => {
  const icons = useIcons();
  const editIcon = icons.edit.childImageSharp.gatsbyImageData.images.fallback.src;

  const showModal = useCallback(
    () =>
      ModalDialog.openModal({
        content: () => <CaseModal medCase={medCase} />,
        title: 'Edit Case'
      }),
    [medCase]
  );

  useEffect(() => {
    if (autoOpenModal) {
      showModal();
    }
  }, [autoOpenModal, showModal]);

  return <ActionBtn bgImage={editIcon} onClick={showModal} />;
};

const CaseModalForm = ({ medCase, handleAddCase, loading, error, petRecordId, handleEditCase }) => {
  const { control, handleSubmit, errors } = useForm();

  const handleSave = handleSubmit(form => {
    if (medCase) {
      handleEditCase({ variables: { ...form, id: medCase?.id } });
      return;
    }
    handleAddCase({ variables: { ...form, petRecordId } });
  });

  return (
    <>
      <ModalBody>
        <Container>
          <InputsWrapper noWrap>
            <WideInputGroup>
              <FormLabel>Name</FormLabel>
              <Controller as={<FormInput error={errors.name} />} control={control} name={'name'} defaultValue={medCase?.name || ''} rules={{ required: true }} rows={6} />
              {errors.name && <FormError>{errors.name.message || 'name is required'}</FormError>}
            </WideInputGroup>
            <WideInputGroup>
              <FormLabel>Description</FormLabel>
              <Controller as={<FormTextArea error={errors.description} />} control={control} name={'description'} defaultValue={medCase?.description || ''} rules={{ required: false }} rows={6} />
              {errors.description && <FormError>{errors.description.message || 'description is required'}</FormError>}
            </WideInputGroup>
          </InputsWrapper>
        </Container>
      </ModalBody>
      <ModalFooter>
        <FormButtonsContainer>
          <FormSubmitButton error={error} loading={loading} onClick={handleSave}>
            Save Case
          </FormSubmitButton>
        </FormButtonsContainer>
      </ModalFooter>
    </>
  );
};

const CaseModal = ({ medCase, petRecordId }: { petId?: string; medCase?: any; petRecordId?: any }) => {
  const [handleAddCase, { data: addedCase, loading, error, client }] = useMutation(MedCondAdd);
  const [handleEditCase, { data: editedCase, loading: editLoading, error: editError }] = useMutation(MedCondEdit);

  useEffect(() => {
    if (addedCase) {
      const currentQuery = client.readQuery({ query: GetMedCondsByPetRecordId, variables: { petRecordId } }) || { getMedCondsByPetRecordId: [] };
      client.writeQuery({
        query: GetMedCondsByPetRecordId,
        variables: { petRecordId },
        data: { getMedCondsByPetRecordId: [addedCase.medCondAdd, ...currentQuery.getMedCondsByPetRecordId] }
      });
    }
    if (addedCase || editedCase) {
      ModalDialog.closeModal();
    }
  }, [addedCase, editedCase]);

  return <CaseModalForm loading={editLoading || loading} error={error || editError} {...{ handleAddCase, petRecordId, handleEditCase, medCase }} />;
};

export default CaseModal;
