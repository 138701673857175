import React from 'react';
import { sentenceCase } from 'sentence-case';
import useMediaQuery from '../../hooks/useMediaQuery';
import ModalDialog from '../Modal/ModalDialog';
import { TabNotification, TabTitle, TabTitleItems, TabsActionsWrapper, TabsHeaderContainer } from './Shared';

type TabsHeaderProps = {
  tabs: string[];
  tabsTitles?: {
    [key: string]: string;
  };
  disabledTabs?: string[];
  modalTabs?: {
    name: string;
    modal: () => JSX.Element;
    modalTitle?: string;
  }[];
  actions?: JSX.Element;
  selected: string;
  setSelected: any;
  backgroundColor?: string;
  higherRadius?: boolean;
  beforeTabs?: JSX.Element;
  nextToTabs?: JSX.Element;
  hideTabs?: boolean;
  tabsOnClick?: (tab: string) => void;
  actionsWidth?: number;
  actionsPadding?: string;
  noPadding?: boolean;
  notifications?: {
    [key: string]: number;
  };
  mobilePadding?: string;
  mobileFontSize?: number;
};
const TabsHeader = ({
  tabs,
  tabsTitles,
  disabledTabs,
  modalTabs,
  actions,
  selected,
  setSelected,
  backgroundColor,
  higherRadius,
  beforeTabs,
  nextToTabs,
  hideTabs = false,
  tabsOnClick,
  actionsWidth = 80,
  actionsPadding,
  noPadding,
  notifications,
  mobilePadding,
  mobileFontSize
}: TabsHeaderProps) => {
  const { mobile, tablet } = useMediaQuery({ mobile: true, tablet: true });

  return (
    <TabsHeaderContainer
      hideTabs={hideTabs}
      hasActions={!!actions}
      hasActionsPadding={!!actionsPadding}
      noPadding={noPadding}
      mobilePadding={mobilePadding ? mobilePadding : '18px 20px 8px 20px'}
      minHieght="0"
    >
      <TabsActionsWrapper backgroundColor={backgroundColor} hasActions={!!actions} actionsWidth={actionsWidth} actionsPadding={actionsPadding}>
        {beforeTabs && beforeTabs}
        {!hideTabs &&
          tabs.map(tab => {
            const isSelected = selected?.toLowerCase() === tab?.toLowerCase();
            return (
              <TabTitle
                selected={isSelected}
                key={tab}
                onClick={() => {
                  if (tabsOnClick) {
                    tabsOnClick(tab);
                  }

                  setSelected(tab);
                }}
                higherRadius={higherRadius}
                mobileFontSize={mobileFontSize}
              >
                <TabTitleItems>
                  <span>{tabsTitles?.[tab] || sentenceCase(tab)}</span>
                  {!!notifications?.[tab] && <TabNotification selected={isSelected}>{notifications[tab]}</TabNotification>}
                </TabTitleItems>
              </TabTitle>
            );
          })}
        {!hideTabs &&
          !tablet &&
          disabledTabs &&
          disabledTabs.map(tab => (
            <TabTitle selected={false} key={tab} disabled>
              {tab}
            </TabTitle>
          ))}
        {!hideTabs &&
          modalTabs &&
          modalTabs.map(tab => (
            <TabTitle
              selected={false}
              key={tab.name}
              onClick={() =>
                ModalDialog.openModal({
                  content: () => <tab.modal />,
                  title: sentenceCase(tab.modalTitle ? tab.modalTitle : tab.name)
                })
              }
            >
              {sentenceCase(tab.name)}
            </TabTitle>
          ))}

        {nextToTabs && nextToTabs}
      </TabsActionsWrapper>
      {actions && (
        <TabsActionsWrapper hasActions actionsWidth={actionsWidth} actionsPadding={actionsPadding}>
          {actions}
        </TabsActionsWrapper>
      )}
    </TabsHeaderContainer>
  );
};

export default TabsHeader;
