import React, { FC, Fragment } from 'react';
import { ExtensionDivider, ExtensionPetCaseLabel, ExtensionPetCasesRecord } from '../../../../components/Pets/styled';
import { toReadableDate } from '../../../../utils/dates';
import { report } from './TrainingHistory';

const TrainingReport: FC<{ trainingReport: report; onClick: React.MouseEventHandler<HTMLDivElement>; selected: boolean }> = ({ trainingReport, onClick, selected }) => {
  return (
    <Fragment>
      <ExtensionPetCasesRecord selected={selected} onClick={onClick}>
        <ExtensionPetCaseLabel fontSize={16} fontWeight={'700'} margingB={8}>
          {trainingReport?.name}
        </ExtensionPetCaseLabel>
        <ExtensionPetCaseLabel fontSize={14} fontWeight={'600'} margingB={2}>
          {toReadableDate(trainingReport?.updatedAt, { isLocale: true })}
        </ExtensionPetCaseLabel>
        <ExtensionPetCaseLabel fontSize={14} fontWeight={'600'} margingB={8}>
          {trainingReport?.Branch?.name}
        </ExtensionPetCaseLabel>
      </ExtensionPetCasesRecord>
      <ExtensionDivider />
    </Fragment>
  );
};

export default TrainingReport;
