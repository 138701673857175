import { useMutation } from '@apollo/client';
import React, { FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Notifier from '../../Notifier';
import useReduceAppointments from '../../hooks/useReduceAppointments';
import { UpdateOrderBillsBilledOnDate } from '../../queries';
import { BookingOrder } from '../../views/Bookings/types';
import { DatesContainer } from '../Calendar/Modals/styled';
import ModalDialog from '../Modal/ModalDialog';
import { ModalBody, ModalFooter } from '../Modal/styled';
import { FormButtonsContainer, FormError, FormInput, FormLabel, FormSubmitButton, InputsWrapper, SectionSpan, WideInputGroup } from '../Shared/Forms/Forms';
import { Container } from '../Shared/Shared';

interface BookingOrderBillBilledOnFormProps {
  orderAppointments: BookingOrder[];
  formOptions: ReturnType<typeof useForm>;
  loadingAdd: boolean;
  onSubmit: () => void;
}

const BookingOrderBillBilledOnForm: FC<BookingOrderBillBilledOnFormProps> = ({ orderAppointments, formOptions, loadingAdd, onSubmit }) => {
  const { control, errors } = formOptions;
  const firstAppointment = orderAppointments?.[0]?.[0];
  const orderBills = firstAppointment?.OrderItem?.Order?.OrderBills;
  const billedOn = orderBills?.[0]?.billed_on;
  const selectedDate = billedOn?.split('T')[0];

  const appUsers = orderAppointments.flatMap(appointment => appointment.map(a => a.OrderItem.Order.AppUser));
  const uniqueAppUsersByIds = [...new Set(appUsers.map(appUser => appUser?.id))].filter(id => id);
  const appUsersLength = uniqueAppUsersByIds.length;
  const appUsersNames = appUsersLength > 1 ? `${appUsersLength} Clients` : appUsers.find(appUser => appUser?.id === uniqueAppUsersByIds[0])?.name;

  const pets = orderAppointments.flatMap(appointment => appointment.map(a => a.PetRecord.Pet));
  const uniquePetsByIds = [...new Set(pets.map(pet => pet.id))];
  const petsNames = appUsersLength > 1 ? `${uniquePetsByIds.length} Pets` : uniquePetsByIds.map(id => pets.find(pet => pet.id === id)?.name).join(' & ');

  return (
    <>
      <ModalBody>
        <Container width={500}>
          <FormLabel>Service</FormLabel>
          <SectionSpan>{firstAppointment?.OrderItem?.item?.name}</SectionSpan>
          <FormLabel>Booked by</FormLabel>
          <SectionSpan>{appUsersNames}</SectionSpan>
          <FormLabel>Booked for</FormLabel>
          <SectionSpan>{petsNames}</SectionSpan>
          <InputsWrapper noWrap>
            <DatesContainer>
              <WideInputGroup>
                <FormLabel error={errors?.date?.message}>New Date</FormLabel>
                <Controller
                  as={
                    <FormInput
                      error={errors?.date?.message}
                      type={'date'}
                      height={32}
                      fontSize={16}
                      name={`date`}
                      //  min={toDateWithSlashes(getStartOfToday())}
                    />
                  }
                  control={control}
                  name={`date`}
                  defaultValue={selectedDate}
                  rules={{
                    required: {
                      value: true,
                      message: 'Please select a date'
                    }
                    // min: {
                    //   value: toDateWithSlashes(getStartOfToday()),
                    //   message: 'Date can not be in the past'
                    // },
                    // validate: (value: string) => {
                    //   const selectedDate = new Date(value);
                    //   selectedDate.setUTCHours(0, 0, 0, 0);
                    //   const today = getStartOfToday();
                    //   today.setUTCHours(0, 0, 0, 0);
                    //   if (selectedDate.getTime() < today.getTime()) {
                    //     return "Date can't be in the past";
                    //   }
                    // }
                  }}
                />
                {errors?.date && <FormError>{errors?.date?.message || 'Please enter a valid date'}</FormError>}
              </WideInputGroup>
            </DatesContainer>
          </InputsWrapper>
        </Container>
      </ModalBody>
      <ModalFooter>
        <FormButtonsContainer>
          <FormSubmitButton loading={loadingAdd} onClick={onSubmit}>
            Save
          </FormSubmitButton>
        </FormButtonsContainer>
      </ModalFooter>
    </>
  );
};

export const BookingOrderBillBilledOnModal = ({ appointments }: { appointments: BookingOrder }) => {
  const formOptions = useForm();
  const { handleSubmit } = formOptions;

  const orderAppointments = useReduceAppointments(appointments, {
    uniqueByOrderIdAndTimestamp: true
  });

  const [updateBilledOnDate, { data: updatedBilledOnDate, loading: loadingUpdateBilledOnDate }] = useMutation(UpdateOrderBillsBilledOnDate);

  const onSubmit = handleSubmit(form => {
    const { date, time } = form;

    const newBilledOnDate = new Date(date);
    newBilledOnDate.setUTCHours(Number(time?.split(':')[0] || 0), Number(time?.split(':')[1] || 0), 0, 0);

    const updateOrderBillsBilledOnDate = () =>
      updateBilledOnDate({
        variables: {
          id: orderAppointments.flatMap(appointment => appointment.flatMap(app => app.OrderItem.Order.OrderBills.flatMap(orderBill => orderBill.id))),
          billed_on: newBilledOnDate.toISOString()
        }
      });

    updateOrderBillsBilledOnDate();
  });

  useEffect(() => {
    if (updatedBilledOnDate?.updateOrderBillsBilledOnDate?.length) {
      ModalDialog.closeModal();
      Notifier.success({ message: 'Booking bill date changed successfully' });
    }
  }, [updatedBilledOnDate]);

  return <BookingOrderBillBilledOnForm orderAppointments={orderAppointments} formOptions={formOptions} loadingAdd={loadingUpdateBilledOnDate} onSubmit={onSubmit} />;
};

export default BookingOrderBillBilledOnModal;
