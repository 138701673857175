import React, { useRef } from 'react';
import Colors from '../../Colors';
import useIcons from '../../hooks/useIcons';
import useMediaQuery from '../../hooks/useMediaQuery';
import { findMonthName, toReadableDate } from '../../utils/dates';
import useDynamicLinks from '../../utils/dynamicLinks/useDynamicLinks';
import { BranchAppUser } from '../../views/Store/BranchBilling/types';
import { BranchAppUserTag } from '../../views/Store/BranchTags/types';
import { AppUserImage, AppUserImageContainer, AppUserImageNameContainer, AppUserName, RecordBody, RecordDataContainer } from '../../views/styled';
import { PetRecordOptionsContainer, PetRecordTagPill, PetRecordTagPillsContainer } from '../Pets/styled';
import { RedDot } from '../Shared/Shared';
import ChatRecordOptions, { ChatRecordOptionsRef } from './ChatRecordOptions';
import client from '../../utils/client';

export type Room = {
  id: string;
  name: string;
  createdAt: string;
  cover_photo: string;
  AppUsers: BranchAppUser[];
  BusUsers: {
    id: string;
    name: string;
    profile_pic: string;
    email: string;
  }[];
  MessageReadStatuses: {
    id: string;
    status: string;
    last_read_message: string;
    last_read_timestamp: string;
    Branch: {
      id: string;
    };
  }[];
  Messages: {
    id: string;
    body: string;
    photos: string[];
    location: string;
    video: string;
    timestamp: string;
    user_id: string;
  }[];
  last_message: Room['Messages'][0];
  read_status: {
    is_read: boolean;
    user_id: string;
  }[];
};
type Props = {
  room: Room;
  navigateDrawer: ({ drawerLinkId }: { drawerLinkId: string }) => void;
  isRoomSeen: (room: Room) => boolean;
  appUserQuickTags?: BranchAppUserTag[];
  profile: any;
  tagsVisibleOnItem?: BranchAppUserTag[];
  updateChatRooms: (newRooms: Room[]) => void;
};

const resolveMessage = (message: any) => {
  if (message?.body) {
    return message.body;
  }
  if (message?.photos?.length) {
    return 'Photo';
  }
  if (message?.location) {
    return 'Location';
  }
  if (message?.video) {
    return 'Video';
  }
  return '';
};

const ChatRecord = ({ room, navigateDrawer, isRoomSeen, appUserQuickTags, profile, tagsVisibleOnItem, updateChatRooms }: Props) => {
  const icons = useIcons();
  const { mobile: isMobile } = useMediaQuery({ mobile: true });
  const users = room?.AppUsers || [];
  const time = toReadableDate(new Date(room?.last_message?.timestamp || room?.createdAt), { isLocale: true });

  const mobileTime = new Date(room?.last_message?.timestamp || room?.createdAt);

  const day = () => {
    return mobileTime.getDate() === new Date().getDate()
      ? 'Today,'
      : mobileTime.getDate() === new Date().getDate() - 1
      ? 'Yesterday,'
      : mobileTime.getDate() < 10
      ? `0${mobileTime.getDate()}`
      : mobileTime.getDate();
  };
  const month = () => {
    return mobileTime.getDate() !== new Date().getDate() && mobileTime.getDate() !== new Date().getDate() - 1 ? findMonthName(mobileTime.getMonth()) + ',' : '';
  };
  const hour = () => {
    return mobileTime.getHours() < 10 ? `0${mobileTime.getHours()}` : mobileTime.getHours();
  };
  const minutes = () => {
    return mobileTime.getMinutes() < 10 ? `0${mobileTime.getMinutes()}` : mobileTime.getMinutes();
  };
  const year = () => {
    let numberOfYears = 0;
    numberOfYears = new Date()?.getFullYear() - mobileTime?.getFullYear();
    return numberOfYears > 1 ? `${numberOfYears} years ago` : `A year ago`;
  };

  const mobileTimeToReturn = new Date().getFullYear() !== mobileTime.getFullYear() ? year() : `${day()} ${month()} ${hour()}:${minutes()}`;

  const { mobile } = useMediaQuery({ mobile: true });
  const chatRecordOptionsRef = useRef<ChatRecordOptionsRef>(null);
  const toggleMenu = () => {
    chatRecordOptionsRef?.current?.getOptionDropdownRefOptions?.()?.toggleMenu?.();
  };

  const handleClick = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    const isTapToTagElement = e.target instanceof HTMLElement && e.target.tagName.toLowerCase() === 'img' && mobile;

    if (isTapToTagElement) {
      toggleMenu();
    } else {
      if (chatRecordOptionsRef?.current?.getContainerRefOptions?.()?.contains?.(e.target as Node)) {
        return;
      }
      const read_status = (room?.read_status || [])?.filter(status => status?.user_id !== profile?.id);
      updateChatRooms([{ ...room, read_status: [...read_status, { is_read: true, user_id: profile?.id }] }]);
      navigateDrawer({ drawerLinkId: room?.id });
    }
  };

  const hasFilters = false;

  const tags = room?.AppUsers?.flatMap(user => user?.BranchAppUserTags) || [];
  const tagsToRender = tagsVisibleOnItem ? tags.filter(tag => tagsVisibleOnItem.some(visibleTag => visibleTag.id === tag.id)) : tags;

  const recordOptions = (
    <PetRecordOptionsContainer>
      <ChatRecordOptions
        ref={chatRecordOptionsRef}
        showAlways={mobile}
        loadTags={false}
        appUsers={users as BranchAppUser[]}
        appUserQuickTags={appUserQuickTags}
        profile={profile}
        chatRoom={room}
        right={mobile}
        updateChatRooms={updateChatRooms}
      />
    </PetRecordOptionsContainer>
  );

  const renderTags = () => {
    return (
      <>
        {!hasFilters && (
          <PetRecordTagPillsContainer>
            {tagsToRender?.map((item, index) => (
              <PetRecordTagPill color={item.color} key={index}>
                <span>{item.name}</span>
              </PetRecordTagPill>
            ))}
          </PetRecordTagPillsContainer>
        )}
      </>
    );
  };

  const lastMessage = resolveMessage(room?.last_message);

  const { messageWithoutDynamicLinks } = useDynamicLinks(lastMessage, { loadHandler: false });

  const isSeen = isRoomSeen(room);

  return (
    <RecordDataContainer onClick={handleClick} mobilePadding="14px 16px 16px 40px" mobileGap="0" MobileMinHeight="auto" disableAnimation>
      <RecordBody>
        <AppUserImageNameContainer direction={isMobile ? 'column' : 'row'}>
          {!mobile && <RedDot color={isSeen ? 'transparent' : Colors.alert} />}
          <AppUserImageContainer>
            <RecordBody style={{ position: 'absolute', right: '40px', height: 0 }}>{recordOptions}</RecordBody>
            {users.map((user, idx) => (
              <AppUserImage key={user.id} src={user?.profile_pic || icons.user.childImageSharp.gatsbyImageData.images.fallback.src} index={idx} mobileHeight={45} mobileWidth={45} />
            ))}
            {!users.length && <AppUserImage src={icons.user.childImageSharp.gatsbyImageData.images.fallback.src} />}
          </AppUserImageContainer>
          {!mobile && <AppUserName>{users.map(user => user.name).join(' & ') || 'No User'}</AppUserName>}
        </AppUserImageNameContainer>
      </RecordBody>
      {!mobile && <RecordBody width={'20%'}>{messageWithoutDynamicLinks}</RecordBody>}
      {!mobile && <RecordBody>{time}</RecordBody>}
      {mobile && (
        <div style={{ width: '100%' }}>
          <RecordBody marginBottom="4">
            <RecordBody noMargin mobileWidth="100%" fontWeight="800" marginLeft="24">
              {users.map(user => user.name).join(' & ') || 'No User'}
            </RecordBody>
            <RecordBody style={{ color: '#7A7A7A' }}>{mobileTimeToReturn}</RecordBody>
          </RecordBody>
          <div style={{ display: 'flex', gap: '8px', justifyContent: 'center', alignItems: 'center' }}>
            <RecordBody mobileWidth="100%" marginLeft="24" mobileOverflow="hidden" mobileDisplay="block">
              {lastMessage}
            </RecordBody>
            {mobile && <RedDot color={isSeen ? 'transparent' : Colors.alert} />}
          </div>

          {/* <RecordBody>{time}</RecordBody> */}
        </div>
      )}
      {!mobile && <RecordBody>{renderTags()}</RecordBody>}
      {!mobile && recordOptions}
    </RecordDataContainer>
  );
};

export default ChatRecord;
