import { useQuery } from '@apollo/client';
import React, { FC } from 'react';
import { ExtensionColumn, ExtensionColumnContent, ExtensionColumnHeader, ExtensionPetCaseLabel } from '../../../components/Pets/styled';
import InfiniteList from '../../../components/Shared/InfiniteList/InfiniteList';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { CenteredLoader } from '../../../components/Shared/Spinner';
import PetFormView from './PetForm';
import { BranchForm, SubmittedForm } from '../../Store/BranchForms/types';

export type PetForm = {
  id: string;
  form: BranchForm;
  isAnswered: boolean;
  answers: SubmittedForm['Answers'] | null;
  questions: BranchForm['Questions'];
  createdAt: string;
  orderNumber: string | null;
};

type PetFormsHistoryProps = {
  petForms: PetForm[];
  setSelectedFormId: (noteId: string) => void;
  loading: boolean;
  setHasMoreItems: React.Dispatch<React.SetStateAction<boolean>>;
  fetchMore: ReturnType<typeof useQuery>['fetchMore'];
  hasMoreItems: boolean;
  selectedFormId: string;
};

const PetFormsHistory: FC<PetFormsHistoryProps> = ({ petForms, setSelectedFormId, fetchMore, setHasMoreItems, hasMoreItems, loading, selectedFormId }) => {
  const { tablet } = useMediaQuery({ tablet: true });

  return (
    <ExtensionColumn>
      <ExtensionColumnHeader>
        <span>Forms</span>
      </ExtensionColumnHeader>
      <ExtensionColumnContent>
        {tablet && !petForms?.length && loading && <CenteredLoader />}
        {!!petForms?.length && (
          <InfiniteList
            list={petForms}
            itemRenderer={(petForm: PetForm, index: number) => <PetFormView selected={petForm.id === selectedFormId} key={index} petForm={petForm} onClick={() => setSelectedFormId(petForm?.id)} />}
            loading={loading}
            offeset={petForms?.length}
            setHasMoreItems={setHasMoreItems}
            fetchMore={fetchMore}
            hasMoreItems={hasMoreItems}
            offset={petForms?.length}
            listContainerStyles={tablet ? { width: '100%' } : {}}
          />
        )}

        {!petForms?.length && !loading && (
          <ExtensionPetCaseLabel fontSize={16} fontWeight={'700'}>
            There are no forms
          </ExtensionPetCaseLabel>
        )}
      </ExtensionColumnContent>
    </ExtensionColumn>
  );
};

export default PetFormsHistory;
