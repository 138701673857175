import { useLazyQuery, useMutation, useQuery, useReactiveVar } from '@apollo/client';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import Select from 'react-select';
import Alert from '../../../components/Alert/Alert';
import Modal from '../../../components/Modal/Modal';
import ModalDialog from '../../../components/Modal/ModalDialog';
import { ModalBody, ModalFooter } from '../../../components/Modal/styled';
import ServiceModalDuration, { convertDurationToMinutes } from '../../../components/Shared/DurationSelector';
import { FormButtonsContainer, FormError, FormInput, FormLabel, FormSubmitButton, FormTextArea, InputsWrapper, WideInputGroup, selectTheme } from '../../../components/Shared/Forms/Forms';
import MarkupEditor from '../../../components/Shared/MarkupEditor';
import { Container, FlexCenterCenter } from '../../../components/Shared/Shared';
import PageLoader from '../../../components/Shared/Spinner';
import useIcons from '../../../hooks/useIcons';
import usePhotoUpload, { useDeletePhotos } from '../../../hooks/usePhotoUpload';
import { AddFlagItem, AddService, AttachCategoryToProduct, DeleteFlagItem, DeleteProduct, DeleteProductCategoryItem, EditProduct, GetBusUserProfile, GetFromFlag, GetProduct } from '../../../queries';
import { vars } from '../../../reactive';
import { offsetUTC } from '../../../utils/dates';
import { getKeys } from '../../../utils/helpers';
import { AddPhotoByLink } from '../AddPhotoByLink';
import Common from '../Common';
import Customizations from '../Customizations/Customizations';
import { getSelectedCustomizations } from '../Customizations/utils';
import PhotoLink from '../PhotoLink';
import ProductOptions, { getSelectedProductOptions, useWatchProductOptions } from '../ProductOptions';
import ProductPrice, { getChargeType } from '../ProductPrice';
import { AddImageButton, AddImageContainer, AddNewContainer, AddedImage, CategorySelect, FlexColumnCenterBetweenContainer, PhotosContainer, RemoveAddedImage } from '../styled';
import transformOperatingSlotsState from '../transformOperatingSlotsState';
import { productStatuses } from '../types';
import BookingType from './BookingType';

type ServiceModalFormProps = {
  service: any;
  uploadedPhotos: any;
  uploadedLinks: any;
  icons: any;
  handleRemovePhoto: any;
  fileInputRef: any;
  LinkModalRef: any;
  setUploadedLinks: any;
  handlePhotoSelect: any;
  markupEditorRef: any;
  photoUploadError: any;
  editError: any;
  addError: any;
  addLoading: any;
  photoUploadLoading: any;
  editLoading: any;
  serviceId: any;
  handleFormUpdate: any;
  handleFormSubmit: any;
  handleDeleteService: any;
  deleteError: any;
  deleteLoading: any;
  hasFromFlag: boolean;
  formOptions: ReturnType<typeof useForm>;
};

function ServiceModalForm({
  service,
  uploadedPhotos,
  uploadedLinks,
  icons,
  handleRemovePhoto,
  fileInputRef,
  LinkModalRef,
  setUploadedLinks,
  handlePhotoSelect,
  markupEditorRef,
  photoUploadError,
  editError,
  addError,
  addLoading,
  photoUploadLoading,
  editLoading,
  serviceId,
  handleFormUpdate,
  handleFormSubmit,
  handleDeleteService,
  deleteError,
  deleteLoading,
  hasFromFlag,
  formOptions
}: ServiceModalFormProps) {
  const { control, errors } = formOptions;
  const { booking_type } = useWatch({ control, name: ['booking_type'], defaultValue: { booking_type: service?.booking_type || 'SLOT' } });
  const watchedProductOptions = useWatchProductOptions({ product: service, hasFromFlag, formOptions });

  const isRecurring = getSelectedProductOptions(watchedProductOptions)?.allows_repeated_orders;
  const isBulk = getSelectedProductOptions(watchedProductOptions)?.allows_bulk_orders;
  const notMulti = booking_type === 'SLOT' || booking_type === 'OTHER';
  const showSessions = !isRecurring && !isBulk && notMulti;

  return (
    <>
      <ModalBody>
        <AddNewContainer>
          <Container>
            <InputsWrapper noWrap>
              <WideInputGroup>
                <FormLabel>Title</FormLabel>
                <Controller as={<FormInput error={errors.name} height={32} fontSize={16} />} control={control} name={'name'} defaultValue={service?.name || ''} rules={{ required: true }} />
                {errors.name && <FormError>{errors.name.message || 'name is required'}</FormError>}
              </WideInputGroup>

              <ProductPrice formOptions={formOptions} product={service} />

              <WideInputGroup>
                <FormLabel>Stock (leave empty for unlimited)</FormLabel>
                <Controller
                  as={<FormInput error={errors.stock} type={'number'} height={32} fontSize={16} />}
                  control={control}
                  name={'stock'}
                  defaultValue={service?.stock !== null && service?.stock >= 0 ? String(service?.stock) : ''}
                  rules={{
                    validate: val => {
                      if (!val) {
                        return true;
                      }

                      const newStock = Number(val);
                      const isNumber = !isNaN(newStock);
                      const isPositive = newStock >= 0;
                      return isNumber && isPositive;
                    },
                    required: false
                  }}
                />
                {errors.stock && <FormError>{errors.stock.message || 'Stock is invalid'}</FormError>}
              </WideInputGroup>
              <Customizations formOptions={formOptions} defaultValue={service?.customizations || ''} />

              <ProductOptions formOptions={formOptions} product={service} hasFromFlag={hasFromFlag} serviceType />

              <WideInputGroup>
                <FormLabel>Category</FormLabel>
                <Controller
                  as={<CategorySelect defaultValue={service?.ProductCategories.map(category => ({ label: category.name, value: category.id })) || null} />}
                  control={control}
                  name={'category'}
                  defaultValue={service?.ProductCategories.map(category => ({ label: category.name, value: category.id })) || null}
                  rules={{ required: true }}
                />
                {errors.category && <FormError>{errors.category.message || 'category is required'}</FormError>}
              </WideInputGroup>

              <WideInputGroup>
                <FormLabel>Service Status</FormLabel>
                <Controller
                  render={({ onChange, value }) => {
                    return (
                      <Select
                        styles={{ container: (provided, state) => ({ ...provided }), valueContainer: (provided, state) => ({ ...provided, height: 48, overflowY: 'scroll' }) }}
                        options={productStatuses}
                        theme={selectTheme}
                        getOptionLabel={option => option.availablality || option.label}
                        isClearable
                        onChange={onChange}
                        value={value}
                      />
                    );
                  }}
                  control={control}
                  name={'status'}
                  defaultValue={
                    service?.status
                      ? { value: service?.status, label: productStatuses.find(status => service?.status === status.value)?.availablality }
                      : { value: productStatuses[0]?.value, label: productStatuses[0]?.availablality }
                  }
                  rules={{ required: true }}
                />
                {errors.status && <FormError>{errors.status.message || 'status is required'}</FormError>}
              </WideInputGroup>
              {booking_type !== 'MULTI_DAY' && (
                <ServiceModalDuration
                  formOptions={formOptions}
                  defaultDuration={service?.duration}
                  options={{
                    title: 'Service Time (Hours) (Minutes)'
                  }}
                />
              )}
              {showSessions && (
                <WideInputGroup>
                  <FormLabel>Sessions</FormLabel>
                  <Controller as={<FormInput error={errors.sessions} type={'number'} height={32} fontSize={16} />} control={control} name={'sessions'} defaultValue={service?.sessions || 1} />
                </WideInputGroup>
              )}
              {isBulk && (
                <WideInputGroup>
                  <FormLabel>Max Bulk Orders</FormLabel>
                  <Controller
                    as={<FormInput error={errors.max_bulk_orders} type={'number'} height={32} fontSize={16} />}
                    control={control}
                    name={'max_bulk_orders'}
                    defaultValue={service?.max_bulk_orders || 1}
                  />
                </WideInputGroup>
              )}
            </InputsWrapper>
            <InputsWrapper noWrap>
              <WideInputGroup>
                <FormLabel>Photos ({[...(uploadedPhotos || []), ...(uploadedLinks || [])].length} / 8)</FormLabel>
                <PhotosContainer>
                  {[...(uploadedPhotos || []), ...(uploadedLinks || [])].map((photo, idx) => {
                    return (
                      <FlexColumnCenterBetweenContainer key={idx}>
                        <AddedImage key={idx} src={typeof photo === 'string' ? photo : URL.createObjectURL(photo)} />
                        <RemoveAddedImage src={icons.delete.childImageSharp.gatsbyImageData.images.fallback.src} onClick={() => handleRemovePhoto(photo)} />
                      </FlexColumnCenterBetweenContainer>
                    );
                  })}
                  <FlexColumnCenterBetweenContainer>
                    <AddImageContainer disabled={[...uploadedPhotos, ...uploadedLinks].length >= 8}>
                      <AddImageButton onClick={() => fileInputRef?.current?.click()} src={icons.addPhoto.childImageSharp.gatsbyImageData.images.fallback.src} />
                      <Modal
                        ref={LinkModalRef}
                        title="Image Link"
                        ModalBtn={AddPhotoByLink}
                        modalContent={() => <PhotoLink setPhotos={setUploadedLinks} photos={uploadedLinks} LinkModalRef={LinkModalRef} />}
                      />
                    </AddImageContainer>
                  </FlexColumnCenterBetweenContainer>
                  <input ref={fileInputRef} type={'file'} accept={'image/*'} onChange={handlePhotoSelect} disabled={uploadedPhotos.length >= 8} style={{ display: 'none' }} multiple />
                </PhotosContainer>
              </WideInputGroup>
              <WideInputGroup>
                <BookingType control={control} errors={errors} service={service} />
              </WideInputGroup>
              <WideInputGroup>
                <FormLabel>Description</FormLabel>
                <MarkupEditor ref={markupEditorRef} defaultValue={service?.description || ''} />
              </WideInputGroup>
              <WideInputGroup>
                <FormLabel>Sort Index</FormLabel>
                <Controller
                  as={<FormInput error={errors.sort_index} type={'number'} height={32} fontSize={16} />}
                  control={control}
                  name={'sort_index'}
                  defaultValue={service?.sort_index || 1}
                  rules={{ required: false }}
                />
                <FormError>{errors.sort_index?.message || ''}</FormError>
              </WideInputGroup>
            </InputsWrapper>
            <InputsWrapper noWrap>
              <WideInputGroup>
                <FormLabel>Short Description</FormLabel>
                <Controller
                  as={<FormInput error={errors.short_description} height={32} fontSize={16} />}
                  control={control}
                  name={'short_description'}
                  defaultValue={service?.short_description || ''}
                  rules={{}}
                />
              </WideInputGroup>
            </InputsWrapper>
            <InputsWrapper noWrap>
              <WideInputGroup>
                <FormLabel>Service disclaimer</FormLabel>
                <Controller
                  as={<FormInput error={errors?.additional_info?.payment_description} height={32} fontSize={16} />}
                  control={control}
                  name={'additional_info.payment_description'}
                  defaultValue={service?.additional_info?.payment_description || ''}
                  rules={{}}
                />
              </WideInputGroup>
            </InputsWrapper>
            <InputsWrapper noWrap>
              <WideInputGroup>
                <FormLabel>Service close message</FormLabel>
                <Controller
                  as={<FormTextArea error={errors?.additional_info?.booking_order_close_message} />}
                  control={control}
                  name={'additional_info.booking_order_close_message'}
                  defaultValue={service?.additional_info?.booking_order_close_message || ''}
                  rules={{}}
                />
              </WideInputGroup>
            </InputsWrapper>
            <InputsWrapper noWrap>
              <WideInputGroup>
                <FormLabel>Action Button Text</FormLabel>
                <Controller as={<FormInput error={errors.cta_text} height={32} fontSize={16} />} control={control} name={'cta_text'} defaultValue={service?.cta_text || ''} rules={{}} />
                {errors.cta_text && <FormError>{errors.cta_text.message || 'Action Button Text is required'}</FormError>}
              </WideInputGroup>
            </InputsWrapper>
          </Container>
        </AddNewContainer>
      </ModalBody>
      <ModalFooter>
        <FormButtonsContainer>
          <FormSubmitButton
            error={addError || photoUploadError || editError}
            loading={addLoading || photoUploadLoading || editLoading}
            onClick={() => (serviceId ? handleFormUpdate() : handleFormSubmit())}
          >
            {serviceId ? 'Update' : 'Save'}
          </FormSubmitButton>
          {/* {serviceId && (
            <FormSubmitButton onClick={handleDeleteService} error={deleteError} loading={deleteLoading} secondary danger>
              Delete Service
            </FormSubmitButton>
          )} */}
        </FormButtonsContainer>
      </ModalFooter>
    </>
  );
}

const ServiceModal = () => {
  const icons = useIcons();
  const fileInputRef = useRef(null);
  const LinkModalRef = useRef(null);
  const markupEditorRef = useRef(null);
  const formOptions = useForm();
  const { control, handleSubmit } = formOptions;
  const [uploadedPhotos, setUploadedPhotos] = useState([]);
  const [deletePhotos, { setPhotosDeleteQueue }] = useDeletePhotos();
  const [uploadedLinks, setUploadedLinks] = useState([]);
  const [formCategories, setFormCategories] = useState([]);
  const serviceId = useReactiveVar(vars.productId); //will be null if adding new service will only be filled in case of editing
  const { data: { getBusUserProfile: profile = {} } = {} } = useQuery(GetBusUserProfile);

  const [loadService, { data: { productGet: [service] = [] } = {}, loading: serviceEditLoading }] = useLazyQuery(GetProduct, { fetchPolicy: 'cache-and-network' }); //used only when editing a service

  const [handleAddProduct, { data: { productAdd: addedProduct } = {}, loading: addLoading, error: addError }] = useMutation(AddService, { fetchPolicy: 'no-cache' });

  const [handleEditProduct, { data: { productEdit: editedProduct } = {}, loading: editLoading, error: editError }] = useMutation(EditProduct, { fetchPolicy: 'no-cache' });

  const [handleDelete, { data: { deleteProduct } = {}, loading: deleteLoading, error: deleteError }] = useMutation(DeleteProduct, { fetchPolicy: 'no-cache' });

  const [handleAttachCategoryToProduct] = useMutation(AttachCategoryToProduct, { fetchPolicy: 'no-cache' });

  const [handleDeleteProductCategoryItem] = useMutation(DeleteProductCategoryItem, { fetchPolicy: 'no-cache' });

  const [handlePhotosUpload, { data: photos, loading: photoUploadLoading, error: photoUploadError }, { checkPhotosSize }] = usePhotoUpload(); //custom hook for uploading photos to firestore

  const { data: { flagGet: fromFlagData = [] } = {} } = useQuery(GetFromFlag);
  const [handleAddFlagItem] = useMutation(AddFlagItem, { fetchPolicy: 'no-cache' });
  const [handleDeleteFlagItem] = useMutation(DeleteFlagItem, { fetchPolicy: 'no-cache' });

  const hasFromFlag = service?.Flags.filter((flag: any) => flag.id === fromFlagData?.[0]?.id).length > 0;

  const handleFormSubmit = handleSubmit(form => {
    const customizations = getSelectedCustomizations(form.customizations);
    const submittedCategoriesIds = form?.category.map(category => category?.value);
    setFormCategories(submittedCategoriesIds);
    const productOptions = getSelectedProductOptions(form.productOptions);
    const formToSubmit = {
      name: form?.name,
      price: form?.price ? form?.price : form?.original_price || '0',
      stock: form?.stock ? String(form?.stock) : null,
      original_price: form?.original_price || '0',
      charge_type: getChargeType(form?.charge_status, productOptions),
      cta_text: form?.cta_text,
      duration: form?.duration !== undefined ? convertDurationToMinutes(form?.duration) : null,
      sessions: Number(form?.sessions) || 1,
      booking_type: form?.booking_type === 'OTHER' ? 'SLOT' : form?.booking_type || 'SLOT',
      status: form?.status?.value,
      type: 'service',
      slots: transformOperatingSlotsState(form),
      slots_start_date: form?.slots_start_date ? new Date(new Date(form?.slots_start_date).getTime() - offsetUTC(form?.slots_start_date)) : null,
      slots_recurrence: Number(form?.slots_recurrence || 1),
      max_bulk_orders: Number(form?.max_bulk_orders) || 1,
      max_slot_offset: form?.max_slot_offset !== undefined ? Number(convertDurationToMinutes(form?.max_slot_offset)) * 1000 : 0,
      short_description: form?.short_description,
      ...productOptions,
      payment: {
        method_optional: productOptions.optional_card_details
      },
      sort_index: Number(form?.sort_index || 500),
      additional_info: {
        ...(form?.additional_info?.payment_description ? { payment_description: form?.additional_info?.payment_description } : {}),
        ...(form?.additional_info?.booking_order_close_message ? { booking_order_close_message: form?.additional_info?.booking_order_close_message } : {})
      }
    };

    handleAddProduct({ variables: { ...formToSubmit, description: markupEditorRef?.current?.getHTML(), photos: [...uploadedLinks], customizations }, fetchPolicy: 'no-cache' });
  });

  const handleFormUpdate = handleSubmit(form => {
    const customizations = getSelectedCustomizations(form.customizations);
    const productOptions = getSelectedProductOptions(form.productOptions);
    const addedFromFlag = productOptions.from;
    const formToSubmitWithoutCustomizations = {
      name: form?.name,
      price: form?.price ? form?.price : form?.original_price || '0',
      stock: form?.stock ? String(form?.stock) : null,
      original_price: form?.original_price || '0',
      charge_type: getChargeType(form?.charge_status, productOptions),
      cta_text: form?.cta_text,
      duration: form?.duration !== undefined ? convertDurationToMinutes(form?.duration) : null,
      sessions: Number(form?.sessions) || 1,
      booking_type: form?.booking_type === 'OTHER' ? 'SLOT' : form?.booking_type || 'SLOT',
      status: form?.status?.value,
      category: form?.category,
      type: 'service',
      slots: transformOperatingSlotsState(form),
      slots_start_date: form?.slots_start_date ? new Date(new Date(form?.slots_start_date).getTime() - offsetUTC(form?.slots_start_date)) : null,
      slots_recurrence: Number(form?.slots_recurrence || 1),
      max_bulk_orders: Number(form?.max_bulk_orders) || 1,
      max_slot_offset: form?.max_slot_offset !== undefined ? Number(convertDurationToMinutes(form?.max_slot_offset)) * 1000 : 0,
      short_description: form?.short_description,
      ...productOptions,
      payment: {
        ...(service?.payment || {}),
        method_optional: productOptions.optional_card_details
      },
      sort_index: Number(form?.sort_index || 500),
      additional_info: {
        ...(form?.additional_info?.payment_description ? { payment_description: form?.additional_info?.payment_description } : {}),
        ...(form?.additional_info?.booking_order_close_message ? { booking_order_close_message: form?.additional_info?.booking_order_close_message } : {})
      }
    };
    const submittedData = { ...formToSubmitWithoutCustomizations, description: markupEditorRef?.current?.getHTML(), customizations };
    const data = getKeys(submittedData)
      .filter(key => {
        if (key === 'category') {
          const existingCategories = service.ProductCategories.map(category => category.id); //in DB
          const submittedCategories = submittedData.category.map(category => category.value); //in DB + Form
          const removedCategories = existingCategories.filter(category => !submittedCategories.includes(category)); //in DB + not in Form

          removedCategories.forEach(category => {
            handleDeleteProductCategoryItem({ variables: { ProductId: service.id, ProductCategoryId: category }, fetchPolicy: 'no-cache' });
          });

          submittedData.category = submittedData.category.filter(category => !existingCategories.includes(category.value)).map(category => category?.value);
          submittedData.category.forEach(category => {
            handleAttachCategoryToProduct({ variables: { ProductId: service.id, ProductCategoryId: category }, fetchPolicy: 'no-cache' });
          });
          return false;
        }
        return submittedData[key] !== service[key];
      })
      .reduce((changes, key) => ({ ...changes, [key]: submittedData[key] }), {});

    const url = `images/providers/${profile.Provider.id}/branches/${profile.Branch.id}/products/${service.id}`;
    if (uploadedPhotos.length) {
      const newPhotos = uploadedPhotos.filter(photo => typeof photo !== 'string');
      handlePhotosUpload(url, newPhotos);
    }
    deletePhotos(url);

    if (addedFromFlag && !hasFromFlag) {
      handleAddFlagItem({ variables: { id: service.id, flagId: fromFlagData?.[0]?.id }, fetchPolicy: 'no-cache' });
    } else if (!addedFromFlag && hasFromFlag) {
      handleDeleteFlagItem({ variables: { id: service.id, flagId: fromFlagData?.[0]?.id }, fetchPolicy: 'no-cache' });
    }

    handleEditProduct({ variables: { id: service.id, ...data, photos: [...uploadedLinks] }, fetchPolicy: 'no-cache' });
  });

  const handleDeleteService = () => {
    Alert.alert({
      title: 'Are you sure?',
      acceptButtonText: 'Yes',
      denyButtonText: 'No',
      description: 'Are you sure you want to delete this service?',
      onAccept: () => {
        handleDelete({ variables: { id: service.id }, fetchPolicy: 'no-cache' });
      }
    });
  };
  const handlePhotoSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPhotos = [...uploadedPhotos, ...(e.target.files || [])];
    if ([...newPhotos, ...uploadedLinks].length > 8) {
      Alert.alert({
        title: 'Maximum number of photos reached',
        acceptButtonText: 'Ok',
        description: 'You can only upload up to 8 photos',
        options: {
          hideDenyButton: true
        }
      });
      return;
    }
    if (checkPhotosSize(e)) {
      setUploadedPhotos([...uploadedPhotos, ...e.target.files]);
    }
  };

  const handleRemovePhoto = async (removedPhoto: any) => {
    setUploadedPhotos(uploadedPhotos.filter(photo => photo !== removedPhoto));
    setUploadedLinks(uploadedLinks.filter(link => link !== removedPhoto));
    if (typeof removedPhoto === 'string') {
      setPhotosDeleteQueue(photosDeleteQueue => [...photosDeleteQueue, removedPhoto]);
    }
  };

  //for uploading photos to firestore after adding the service **because we need the id**
  useEffect(() => {
    if (addedProduct) {
      if (uploadedPhotos.length) {
        const url = `images/providers/${profile.Provider.id}/branches/${profile.Branch.id}/products/${addedProduct.id}`;
        handlePhotosUpload(url, uploadedPhotos);
      }
      formCategories.forEach(category => {
        handleAttachCategoryToProduct({ variables: { ProductId: addedProduct.id, ProductCategoryId: category }, fetchPolicy: 'no-cache' });
      });

      const form = control.getValues();
      const addedFromFlag = getSelectedProductOptions(form.productOptions).from;
      if (addedFromFlag) {
        handleAddFlagItem({ variables: { id: addedProduct.id, flagId: fromFlagData?.[0]?.id }, fetchPolicy: 'no-cache' });
      }
    }
  }, [addedProduct]);

  //after the photos are added we update the service with the URL of each photo
  useEffect(() => {
    if (photos && (addedProduct || editedProduct)) {
      handleEditProduct({ variables: { id: addedProduct?.id || editedProduct?.id, photos: [...(addedProduct?.photos || editedProduct?.photos), ...photos] }, fetchPolicy: 'no-cache' });
    }
  }, [photos]);

  //after the photos are attached to the service we reload the services and close the modal
  useEffect(() => {
    if ((editedProduct && !photoUploadLoading) || deleteProduct || (addedProduct && !uploadedPhotos.length)) {
      Common.get(`Services.GetServices.refetch`)();
      ModalDialog?.closeModal();
    }
  }, [editedProduct, photoUploadLoading, deleteProduct, addedProduct]);

  //in case of editing a service we load the service data by its id
  useEffect(() => {
    if (serviceId) {
      loadService({ variables: { id: serviceId } });
    }
  }, [serviceId]);

  //for mapping data into its proper fields
  useEffect(() => {
    if (service) {
      if (service?.photos.length) {
        setUploadedLinks([...service?.photos]);
      }
    }
  }, [serviceEditLoading]);

  if (serviceEditLoading) {
    return (
      <ModalBody>
        <AddNewContainer>
          <FlexCenterCenter height={650}>
            <PageLoader size={50} />
          </FlexCenterCenter>
        </AddNewContainer>
      </ModalBody>
    );
  }
  return (
    <ServiceModalForm
      {...{
        service,
        uploadedPhotos,
        uploadedLinks,
        icons,
        handleRemovePhoto,
        fileInputRef,
        LinkModalRef,
        setUploadedLinks,
        handlePhotoSelect,
        markupEditorRef,
        addError,
        photoUploadError,
        editError,
        addLoading,
        photoUploadLoading,
        editLoading,
        serviceId,
        handleFormUpdate,
        handleFormSubmit,
        handleDeleteService,
        deleteError,
        deleteLoading,
        hasFromFlag,
        formOptions
      }}
    />
  );
};

export default ServiceModal;
