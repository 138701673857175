import { useReactiveVar } from '@apollo/client';
import { vars } from '../../reactive';
import { getMonday, getStartOfToday } from '../../utils/dates';

type CalendarNavigationProps = {
  step: number;
  useDefaultPage?: boolean;
};

export type CalendarNavigation = [Date, () => void, () => void, (options?: { goToToday?: boolean | undefined }) => void, (date: Date) => void];

const useCalendarNavigation = ({ step, useDefaultPage = false }: CalendarNavigationProps): CalendarNavigation => {
  const today = getStartOfToday();
  const defaultValue = getMonday(today);
  const calendarDate = useReactiveVar(vars.calendarDate);
  const [date, setUTCDate] = [calendarDate ? new Date(new Date(calendarDate).setHours(0, 0, 0, 0)) : defaultValue, vars.calendarDate];

  const incrementDate = () => {
    const currentDay = new Date(date);
    currentDay.setUTCDate(currentDay.getUTCDate() + step);
    setUTCDate(currentDay);
  };
  const decrementDate = () => {
    const currentDay = new Date(date);
    currentDay.setUTCDate(currentDay.getUTCDate() - step);
    setUTCDate(currentDay);
  };
  const resetValue = ({ goToToday }: { goToToday?: boolean } = { goToToday: false }) => {
    if (goToToday) {
      setUTCDate(new Date());
      return;
    }

    setUTCDate(useDefaultPage ? defaultValue : today);
  };

  return [date, incrementDate, decrementDate, resetValue, setUTCDate];
};

export default useCalendarNavigation;
