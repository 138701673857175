import React, { useRef } from 'react';

/**
 * @returns { printRef } - ref of the div you want to print
 * @returns { onHandlePrint } - function to call to print the div
 * @returns { PrinterTags } - component to render in the div you want to print
 * @example
 * const { onHandlePrint, PrinterTags, printRef } = usePrinter();
 *  return (
 *  <>
 *   <div ref={printRef}>
 *    <PrinterTags />
 *     <p>Print this div</p>
 *   </div>
 *  <button onClick={onHandlePrint}>Print</button>
 * </>
 * );
 *
 *
 */

export const printerSize = {
  landscape: {
    width: '230px',
    height: '160px',
    page: '70mm 48mm'
  },
  portrait: {
    width: '160px',
    height: '230px',
    page: '48mm 70mm'
  }
};

export default function usePrinter({
  orientation = 'landscape',
  customOrientation
}: {
  orientation?: 'portrait' | 'landscape' | 'custom';
  customOrientation: {
    width: string;
    height: string;
    page: string;
  };
}): {
  printRef: React.MutableRefObject<HTMLDivElement | null>;
  onHandlePrint: () => void;
  PrinterTags: () => JSX.Element;
} {
  const printRef = useRef<HTMLDivElement>(null);

  const selectedSize = orientation === 'custom' ? customOrientation : printerSize[orientation];

  return {
    printRef,
    onHandlePrint: () => {
      const iframe = document.createElement('iframe');
      iframe.style.top = '-1000px';
      iframe.style.left = '-1000px';
      iframe.style.position = 'absolute';
      iframe.style.margin = '0';
      iframe.style.padding = '0';
      document.body.appendChild(iframe);
      const doc = iframe.contentWindow.document;
      doc.open();
      doc.write(`
        <html>
          <head>
            <title>Print</title>
            <style>
              @page {
                size: ${selectedSize.page};
                margin: 0!important;
                padding: 0!important;
              }
              @media print {
                @page {
                  size: ${selectedSize.page};
                  margin: 0!important;
                  padding: 0!important;
                }
                body {
                  margin: 0!important;
                  padding: 2mm!important;
                  width: ${selectedSize.width};
                  height: ${selectedSize.height};
                }
                body div {
                  width: ${selectedSize.width};
                  height: ${selectedSize.height};
                }
              }
            </style>
          </head>
          <body style="width: ${selectedSize.width}; height: ${selectedSize.height};">
          </body>
        </html>
      `);
      doc.close();

      // Copy the content of the div into the iframe's body
      const div = printRef?.current;
      const content = div?.innerHTML;
      doc.body.innerHTML = content || '';

      iframe.contentWindow.focus();

      // Print the iframe's contents
      iframe.contentWindow.print();
    },
    PrinterTags: () => (
      <>
        <link href="https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700&display=swap" rel="stylesheet" />
        <style>
          {`
             *{
                font-family: 'Manrope', sans-serif;
              }
              #print-container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                font-size: calc(15px + 0.390625vw);
                height: ${selectedSize.height};
                page-break-inside: avoid;
                break-inside: avoid;
              }
              #print-1 {
                font-size: 0.7rem;
                margin: 0;
                white-space: nowrap;
              }
              #print-2 {
                font-size: 0.6rem;
                margin: 0;
              }
              #print-3 {
                font-size: 0.6rem;
                font-weight: 800;
                font-style: italic;
                margin: 0;
              }
            `}
        </style>
      </>
    )
  };
}
