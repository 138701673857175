import React from 'react';
import { getBranchCurrencySymbol } from '../../../utils/getBranchCurrencySymbol';
import { RecordBody, RecordDataContainer } from '../../styled';
import { productStatuses } from '../types';

const StoreSubscriptionRecord = ({ subscription, navigateDrawer }) => {
  const categories = subscription?.ProductCategories.map(category => category?.name).join(',');

  const handleClick = () => {
    navigateDrawer({ drawerLinkId: subscription?.id });
  };

  const status = productStatuses.find(status => status.value === subscription?.status)?.label;

  return (
    <RecordDataContainer onClick={handleClick}>
      <RecordBody fontWeight={'800'} width="30%">
        {subscription?.name}
      </RecordBody>
      <RecordBody>
        {getBranchCurrencySymbol()}
        {subscription?.price}
      </RecordBody>
      <RecordBody>{status}</RecordBody>
      <RecordBody>{categories}</RecordBody>
    </RecordDataContainer>
  );
};

export default StoreSubscriptionRecord;
