import { GatsbyImage } from 'gatsby-plugin-image';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { BackgroundOpacity } from '../../components/Modal/styled';
import { SlideDown, SlideDownActionsContainer } from '../../components/Shared/Shared';
import { ActivityIndicator } from '../../components/Shared/Spinner';
import useBulkSelect from '../../hooks/useBulkSelect';
import useIcons from '../../hooks/useIcons';
import { ActionBtn } from '../Store/styled';
import { Booking } from './types';

type BookingSlideDownActionsProps = {
  bulkSelect: ReturnType<typeof useBulkSelect>;
  bulkActionSelectedBookingOrders: Booking[][];
  children?: React.ReactNode;
};

export type BookingSlideDownActionsRef = {
  handleOnClickAction?: () => void;
};

const BookingSlideDownActions = forwardRef<BookingSlideDownActionsRef, BookingSlideDownActionsProps>(({ bulkSelect, bulkActionSelectedBookingOrders, children }, ref) => {
  const icons = useIcons();
  const { toggleShow, show: showBulkActions } = bulkSelect;
  const closeImage = icons?.closeTransparentBlack?.childImageSharp?.gatsbyImageData;
  const toggleCheckbox = icons?.toggleCheckbox?.childImageSharp?.gatsbyImageData;
  const [loading, setLoading] = useState(false);

  const handleOnClickAction = () => {
    setLoading(true);
    setTimeout(() => {
      toggleShow();
      setLoading(false);
    }, 2000);
  };

  useImperativeHandle(ref, () => ({
    handleOnClickAction
  }));

  return (
    <SlideDownActionsContainer gap={bulkActionSelectedBookingOrders?.length ? 10 : 0}>
      <SlideDown show={showBulkActions} loadingData={loading}>
        {loading && (
          <>
            <ActivityIndicator size={20} />
            <span>Applying Collar Magic...</span>
          </>
        )}
        {!loading && (
          <ActionBtn onClick={toggleShow} noMargin>
            <GatsbyImage alt="select button" image={closeImage} />
          </ActionBtn>
        )}
        {!loading && <span>{bulkActionSelectedBookingOrders?.length ? `${bulkActionSelectedBookingOrders.length} Selected` : 'Select Bookings'}</span>}
        <SlideDownActionsContainer gap={10}>{!loading && children}</SlideDownActionsContainer>
      </SlideDown>
      <BackgroundOpacity showModal={loading} style={{ zIndex: 9 }} />
      {!showBulkActions && !loading && (
        <ActionBtn onClick={toggleShow} noMargin>
          <GatsbyImage alt="select button" image={toggleCheckbox} />
        </ActionBtn>
      )}
    </SlideDownActionsContainer>
  );
});

export default BookingSlideDownActions;
