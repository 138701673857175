import styled from 'styled-components';

export const ListContainer = styled.ul<{ inversed?: boolean; mobilePadding?: string }>`
  font-size: 14px;
  height: 100%;
  padding-bottom: 0;
  margin: 0;
  flex-direction: ${props => (props.inversed ? 'column-reverse' : 'column')};
  display: flex;
  overflow-x: hidden;
  padding: 16px;
  padding-bottom: 0;
  box-sizing: border-box;
  @media (max-width: 768px) {
    padding: ${props => (props.mobilePadding ? props.mobilePadding : '8px 0 0 0')};
    gap: 12px;
  }
`;

export const ListSeparator = styled.div`
  margin-top: 24px;

  &:first-child {
    margin-top: 0;
  }
  @media (max-width: 768px) {
    margin-top: 0;
    position: sticky;
    top: -15px;
    background-color: white;
    z-index: 1;
    width: 100%;
    padding: 5px;
  }
`;

export const LoadersContainer = styled.div`
  flex: 1;
`;

export const LineLoaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
`;
