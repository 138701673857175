import { useQuery, useReactiveVar } from '@apollo/client';
import React, { useCallback } from 'react';
import Colors from '../../Colors';
import { GetBranchAppointmentTags } from '../../queries';
import { vars } from '../../reactive';
import { CalendarEvents } from '../../views/Bookings/types';
import { BranchAppointmentTag } from '../../views/Store/BranchTags/types';
import { DrawerSubLabel } from '../DrawerBar/styled';
import { BusUserProfile } from '../Profile/types';
import SlotCalendar from './SlotCalendar/SlotCalendar';
import { CapacityDiv, DayColumn, HourDiv, SlotCalendarContainer } from './styled';
import { bookingCardColors } from './types';
import { getNumberOfRowsForTags, groupMultiDayBookingsByTags } from './utils';

type CalendarSlotProps = {
  multiDayBookingType: boolean;
  eventsToRender?: CalendarEvents;
  handleBookingClick: ({ appointmentsIds, group }: { appointmentsIds?: string[]; group?: boolean }) => void;
  profile: BusUserProfile;
  weekDays: Date[];
  allBookingsType: boolean;
  currentCalendarLength: number;
  renderDayColumn: ({ hoursLength, mediumSize, smallSize, multiDayBookingType }: { hoursLength?: number; mediumSize?: boolean; smallSize?: boolean; multiDayBookingType?: boolean }) => JSX.Element;
  children: React.ReactNode;
};

const CalendarSlot = ({ multiDayBookingType, eventsToRender, handleBookingClick, profile, weekDays, allBookingsType, currentCalendarLength, renderDayColumn, children }: CalendarSlotProps) => {
  const calendarTagCategoryId = useReactiveVar(vars.calendarTagCategoryId);

  const { data: { getBranchAppointmentTags: appointmentTags = [] } = {} } = useQuery<{ getBranchAppointmentTags: BranchAppointmentTag[] }>(GetBranchAppointmentTags, {
    variables: {
      BranchTagCategoryId: [calendarTagCategoryId]
    },
    skip: !calendarTagCategoryId,
    fetchPolicy: 'cache-and-network'
  });

  const filterByTags = calendarTagCategoryId ? appointmentTags || [] : undefined;

  const filterByTagsIds = (filterByTags || []).map(tag => tag.id);

  const multiDayBookings = eventsToRender?.multiDayAppointmentsViews || [];

  const multiDayAppointmentsForTagsToRender = groupMultiDayBookingsByTags({ multiDayBookings, filterByTagsIds });

  const numberOfRowsForTags = getNumberOfRowsForTags({ filterByTags, multiDayAppointmentsForTagsToRender });

  const allRows = numberOfRowsForTags?.reduce((acc, row) => acc + row.currentTagRows, 0) || 1;
  const multiDayHeight = calendarTagCategoryId ? allRows + 20 : multiDayBookings.length + 30;
  const hoursLength = multiDayBookingType ? multiDayHeight : 25;
  const renderSmallCalendar = multiDayBookingType && allBookingsType && !filterByTags;

  const renderDayTagsColumn = useCallback(
    ({ mediumSize, smallSize }: { mediumSize?: boolean; smallSize?: boolean }) => {
      return (
        <DayColumn maxWidth={120} minWidth={120}>
          {appointmentTags.map((tag, index) => {
            const numberOfRowsForTag = numberOfRowsForTags.find(row => row.tagId === tag.id);
            const numberOfRows = numberOfRowsForTag?.currentTagRows;
            const noCapacity = Number(tag.capacity) === 0;
            const numberOfAppointments = Number(tag.appointmentsCount);
            const atCapacity = Number(tag.capacity) <= numberOfAppointments;
            const moreThanCapacity = Number(tag.capacity) < numberOfAppointments;
            const extraCapacity = numberOfAppointments - tag.capacity;
            const availabilityText = noCapacity
              ? 'No Limit'
              : `${atCapacity ? `At Capacity ${moreThanCapacity ? `+${extraCapacity}` : ''}` : `Availability: ${Number(tag.capacity) - numberOfAppointments}`}`;
            return (
              <HourDiv key={index} noHover mediumSize={mediumSize} smallSize={smallSize} heightMultiplier={numberOfRows || 1} alternateColors renderBorders>
                <CapacityDiv>
                  <DrawerSubLabel size={14} weight={800} color={Colors.black}>
                    {tag.name}({numberOfAppointments})
                  </DrawerSubLabel>
                  <DrawerSubLabel size={12} weight={700} color={(atCapacity || moreThanCapacity) && !noCapacity ? Colors.orange : Colors.primary}>
                    {availabilityText}
                  </DrawerSubLabel>
                </CapacityDiv>
              </HourDiv>
            );
          })}
        </DayColumn>
      );
    },
    [appointmentTags, allRows, multiDayBookingType, allBookingsType, numberOfRowsForTags]
  );

  return (
    <SlotCalendarContainer>
      {allBookingsType && !(multiDayBookingType && !!calendarTagCategoryId) && renderDayColumn({ hoursLength, mediumSize: !renderSmallCalendar, smallSize: renderSmallCalendar, multiDayBookingType })}

      {allBookingsType && multiDayBookingType && !!calendarTagCategoryId && renderDayTagsColumn({ mediumSize: true, smallSize: false })}

      {!multiDayBookingType && children}

      {weekDays.map((weekDay, dayIndex) => (
        <SlotCalendar
          eventsToRender={eventsToRender}
          bookingCardColors={bookingCardColors}
          handleBookingClick={handleBookingClick}
          profile={profile}
          weekDay={weekDay}
          dayIndex={dayIndex}
          multiDayBookingType={multiDayBookingType}
          weekDays={weekDays}
          key={dayIndex}
          currentCalendarLength={currentCalendarLength}
          hoursLength={hoursLength}
          renderSmallCalendar={renderSmallCalendar}
          renderMediumCalendar={!renderSmallCalendar}
          filterByTags={filterByTags}
        />
      ))}
    </SlotCalendarContainer>
  );
};

export default CalendarSlot;
