import { useQuery } from '@apollo/client';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import ModalDialog from '../../../../components/Modal/ModalDialog';
import { ModalBody, ModalFooter } from '../../../../components/Modal/styled';
import { Pet } from '../../../../components/Pets/types';
import { FormButtonsContainer, FormCheckbox, FormError, FormHeaderLabel, FormSubmitButton, InputsWrapper, WideInputGroup } from '../../../../components/Shared/Forms/Forms';
import { Container } from '../../../../components/Shared/Shared';
import { CenteredLoader } from '../../../../components/Shared/Spinner';
import useDownload from '../../../../hooks/useDownload';
import { GetAllMedCondsByPetRecordId } from '../../../../queries';
import { toReadableDate } from '../../../../utils/dates';

const DownloadMedicalReports = ({ pet, petRecordId }: { pet: Pet; petRecordId: string }) => {
  const { data: { getMedCondsByPetRecordId: medReports = [] } = {}, loading: loadingMedReports } = useQuery<{ getMedCondsByPetRecordId: { id: string; name: string; createdAt: string }[] }>(
    GetAllMedCondsByPetRecordId,
    {
      variables: { petRecordId: petRecordId }
    }
  );
  const options = medReports.map(report => ({
    name: `${report.name} - ${toReadableDate(report.createdAt || new Date(), { isLocale: true })}`,
    id: report.id
  }));

  const form = useForm({
    defaultValues: {
      selectedReports: []
    }
  });
  const { control, handleSubmit, errors } = form;

  const [getReports, { loading: loadingDownload }] = useDownload<{
    MedCondId: string[];
  }>({
    type: 'reports',
    fileFormat: 'pdf'
  });

  const onSubmit = handleSubmit(form => {
    getReports({
      variables: { MedCondId: form.selectedReports },
      fileName: `Medical Records, ${pet?.name}, ${pet?.AppUser?.name} - ${form.selectedReports.length} Reports - ${toReadableDate(new Date(), { noTime: true, isLocale: true })}`
    }).then(() => {
      ModalDialog.closeModal();
    });
  });

  return (
    <>
      <ModalBody>
        <Container>
          {loadingMedReports && <CenteredLoader size={40} />}
          {!loadingMedReports && (
            <InputsWrapper noWrap>
              <WideInputGroup>
                <FormHeaderLabel fontSize={18} margin="0 0 24px 0">
                  {pet?.name}'s Reports
                </FormHeaderLabel>
                <WideInputGroup>
                  <Controller
                    name="selectedReports"
                    control={control}
                    render={({ onChange, value }) => <FormCheckbox itemsArray={options} onChange={onChange} value={value} column fontSize={16} error={!!errors.selectedReports} />}
                    rules={{
                      required: true,
                      validate: val => {
                        return (val.length > 0 && val.length <= 5) || 'Please select between 1 and 5 reports';
                      }
                    }}
                  />
                </WideInputGroup>
                {errors.selectedReports && <FormError>{errors.selectedReports[0]?.message || 'Please select between 1 and 5 reports'}</FormError>}
              </WideInputGroup>
            </InputsWrapper>
          )}
        </Container>
      </ModalBody>
      <ModalFooter>
        <FormButtonsContainer>
          <FormSubmitButton loading={loadingDownload} onClick={onSubmit}>
            Download
          </FormSubmitButton>
        </FormButtonsContainer>
      </ModalFooter>
    </>
  );
};

export default DownloadMedicalReports;
