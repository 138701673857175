import { useLazyQuery, useQuery, useReactiveVar } from '@apollo/client';
import React, { FC, Fragment, useCallback } from 'react';
import Filters from '../../components/Shared/Filters/Filters';
import SearchBar from '../../components/Shared/Filters/SearchBar';
import { FILTERS_ID, SUBSCRIPTIONS_FILTER_TYPES, SUBSCRIPTIONS_MORE_OPTIONS_TYPES } from '../../components/Shared/Filters/config';
import useFilters from '../../components/Shared/Filters/useFilters';
import { OPTION_DROPDOWN_TYPES } from '../../components/Shared/Menus/OptionDropdown/types';
import TabsHeader from '../../components/Shared/TabsHeader';
import useMediaQuery from '../../hooks/useMediaQuery';
import { GetBranchUnreadItemsCountReturn } from '../../hooks/useUnreadBranchItemsCount';
import { GetAllSubscriptions, GetBranchUnreadItemsCount } from '../../queries';
import { vars } from '../../reactive';
import { manageSubscriptionsFilters } from '../../reactive/actions';
import { areObjectsEqual } from '../../utils/helpers';
import SubscriptionsHeaderDashboard from './SubscriptionsHeaderDashboard';
import { SUBSCRIPTION_TABS_TYPES } from './types';

const SUBSCRIPTIONS_FILTER_TYPES_NAMES: Record<SUBSCRIPTIONS_FILTER_TYPES, string> = {
  [SUBSCRIPTIONS_FILTER_TYPES.SUBSCRIPTION_SEARCH]: 'Search',
  [SUBSCRIPTIONS_FILTER_TYPES.SUBSCRIPTION_PRODUCT_NAME]: 'Membership',
  [SUBSCRIPTIONS_FILTER_TYPES.SUBSCRIPTION_TRIAL_END]: 'Trial'
};

const MORE_OPTIONS_TITLES: Record<SUBSCRIPTIONS_MORE_OPTIONS_TYPES, string> = {
  [SUBSCRIPTIONS_MORE_OPTIONS_TYPES.SUBSCRIPTION_SEARCH]: '',
  [SUBSCRIPTIONS_MORE_OPTIONS_TYPES.SUBSCRIPTION_PRODUCT_NAME]: 'Membership',
  [SUBSCRIPTIONS_MORE_OPTIONS_TYPES.SUBSCRIPTION_TRIAL_ACTIVE]: 'Trial Active',
  [SUBSCRIPTIONS_MORE_OPTIONS_TYPES.SUBSCRIPTION_TRIAL_END_FROM]: 'Trial active from',
  [SUBSCRIPTIONS_MORE_OPTIONS_TYPES.SUBSCRIPTION_TRIAL_END_TO]: 'Trial active to'
};

const MORE_OPTIONS_DROPDOWN_TYPES: Record<SUBSCRIPTIONS_MORE_OPTIONS_TYPES, OPTION_DROPDOWN_TYPES> = {
  [SUBSCRIPTIONS_MORE_OPTIONS_TYPES.SUBSCRIPTION_SEARCH]: OPTION_DROPDOWN_TYPES.TEXT_INPUT,
  [SUBSCRIPTIONS_MORE_OPTIONS_TYPES.SUBSCRIPTION_PRODUCT_NAME]: OPTION_DROPDOWN_TYPES.MULTI_SELECT,
  [SUBSCRIPTIONS_MORE_OPTIONS_TYPES.SUBSCRIPTION_TRIAL_ACTIVE]: OPTION_DROPDOWN_TYPES.RADIO,
  [SUBSCRIPTIONS_MORE_OPTIONS_TYPES.SUBSCRIPTION_TRIAL_END_FROM]: OPTION_DROPDOWN_TYPES.DATE,
  [SUBSCRIPTIONS_MORE_OPTIONS_TYPES.SUBSCRIPTION_TRIAL_END_TO]: OPTION_DROPDOWN_TYPES.DATE
};

const SUBSCRIPTIONS_FILTER_TYPES_UNITS: Record<SUBSCRIPTIONS_FILTER_TYPES, string> = {
  [SUBSCRIPTIONS_FILTER_TYPES.SUBSCRIPTION_SEARCH]: '',
  [SUBSCRIPTIONS_FILTER_TYPES.SUBSCRIPTION_PRODUCT_NAME]: '',
  [SUBSCRIPTIONS_FILTER_TYPES.SUBSCRIPTION_TRIAL_END]: ''
};

type SubscriptionsHeaderProps = {
  selectedTabsState: [string, React.Dispatch<React.SetStateAction<string>>];
  tabs: { name: string; status: string[]; otherVariables: Record<string, any> }[];
};

const SubscriptionsHeader: FC<SubscriptionsHeaderProps> = ({ selectedTabsState, tabs }) => {
  const { addFilter, addHeaderAction, clearFilter, clearHeaderAction, removeFilter, setNewVisibleFilters } = manageSubscriptionsFilters;
  const { mobile } = useMediaQuery({ mobile: true });
  const [selected, setSelected] = selectedTabsState;

  const [getAllSubscriptions, { data: { getProducts: subscriptions = [] } = {}, called: calledSubscriptions }] = useLazyQuery(GetAllSubscriptions, {
    fetchPolicy: 'cache-and-network'
  });

  const toCall = [{ called: calledSubscriptions, get: () => getAllSubscriptions({ variables: { offset: 0, limit: 1000 } }), id: SUBSCRIPTIONS_FILTER_TYPES.SUBSCRIPTION_PRODUCT_NAME }];

  const filtersControl = useFilters({
    setSelectedFilters: setNewVisibleFilters as any,
    toCall,
    addHeaderAction: addHeaderAction,
    clearHeaderAction: clearHeaderAction
  });

  const { watchedValues } = filtersControl;

  const customEndTrial = watchedValues?.[SUBSCRIPTIONS_FILTER_TYPES.SUBSCRIPTION_TRIAL_END]?.[SUBSCRIPTIONS_MORE_OPTIONS_TYPES.SUBSCRIPTION_TRIAL_ACTIVE] === 'custom';

  const SUBSCRIPTIONS_FILTER_TYPES_MORE_OPTIONS: Record<SUBSCRIPTIONS_FILTER_TYPES, SUBSCRIPTIONS_MORE_OPTIONS_TYPES[]> = {
    [SUBSCRIPTIONS_FILTER_TYPES.SUBSCRIPTION_SEARCH]: [SUBSCRIPTIONS_MORE_OPTIONS_TYPES.SUBSCRIPTION_SEARCH],
    [SUBSCRIPTIONS_FILTER_TYPES.SUBSCRIPTION_PRODUCT_NAME]: [SUBSCRIPTIONS_MORE_OPTIONS_TYPES.SUBSCRIPTION_PRODUCT_NAME],
    [SUBSCRIPTIONS_FILTER_TYPES.SUBSCRIPTION_TRIAL_END]: [
      SUBSCRIPTIONS_MORE_OPTIONS_TYPES.SUBSCRIPTION_TRIAL_ACTIVE,
      ...(customEndTrial ? [SUBSCRIPTIONS_MORE_OPTIONS_TYPES.SUBSCRIPTION_TRIAL_END_FROM, SUBSCRIPTIONS_MORE_OPTIONS_TYPES.SUBSCRIPTION_TRIAL_END_TO] : [])
    ].filter(Boolean)
  };

  const MORE_OPTIONS_ITEMS: Record<SUBSCRIPTIONS_MORE_OPTIONS_TYPES, { value: string | boolean; name: string }[] | []> = {
    [SUBSCRIPTIONS_MORE_OPTIONS_TYPES.SUBSCRIPTION_SEARCH]: [],
    [SUBSCRIPTIONS_MORE_OPTIONS_TYPES.SUBSCRIPTION_PRODUCT_NAME]: subscriptions.map(({ id, name }: { id: string; name: string }) => ({ value: id, name })),
    [SUBSCRIPTIONS_MORE_OPTIONS_TYPES.SUBSCRIPTION_TRIAL_ACTIVE]: [
      { value: 'true', name: 'Yes' },
      { value: 'false', name: 'No' },
      { value: 'custom', name: 'Custom' }
    ],
    [SUBSCRIPTIONS_MORE_OPTIONS_TYPES.SUBSCRIPTION_TRIAL_END_FROM]: [],
    [SUBSCRIPTIONS_MORE_OPTIONS_TYPES.SUBSCRIPTION_TRIAL_END_TO]: []
  };

  const filterItems = Object.values(SUBSCRIPTIONS_FILTER_TYPES).map(filter => ({
    name: SUBSCRIPTIONS_FILTER_TYPES_NAMES[filter],
    value: filter,
    unit: SUBSCRIPTIONS_FILTER_TYPES_UNITS[filter],
    moreOptions: SUBSCRIPTIONS_FILTER_TYPES_MORE_OPTIONS[filter].map(option => ({
      type: option,
      filterType: filter,
      id: `${filter}.${option}`,
      title: MORE_OPTIONS_TITLES[option] ? `${MORE_OPTIONS_TITLES[option]} Filter` : '',
      optionType: MORE_OPTIONS_DROPDOWN_TYPES[option],
      items: MORE_OPTIONS_ITEMS[option]
    }))
  }));

  const searchFilter = filterItems.find(({ value }) => value === SUBSCRIPTIONS_FILTER_TYPES.SUBSCRIPTION_SEARCH)!;
  const searchMoreOptions = searchFilter.moreOptions.find(({ type }) => type === SUBSCRIPTIONS_MORE_OPTIONS_TYPES.SUBSCRIPTION_SEARCH)!;

  const onSubscriptionSearch = (query: string) => {
    if (query) {
      addFilter({
        ...searchFilter,
        moreOptions: [{ ...searchMoreOptions, values: query }],
        requisite: 'true'
      });
      return;
    }
    removeFilter(searchFilter.value);
  };

  const Header = useCallback(() => {
    const { tablet } = useMediaQuery({ tablet: true });
    const headerActions = useReactiveVar(vars.subscriptionsHeaderActions);

    const { data: unreadOrdersCount } = useQuery<{ getBranchUnreadItemsCount: GetBranchUnreadItemsCountReturn }>(GetBranchUnreadItemsCount, {
      fetchPolicy: 'cache-only',
      notifyOnNetworkStatusChange: true
    });

    const { unreadSubscriptionOrdersCount } = unreadOrdersCount?.getBranchUnreadItemsCount || {};

    const filtersHeaderAction = headerActions.find(({ id }) => id === FILTERS_ID)?.action;

    const nonFiltersHeaderAction = headerActions.filter(({ id }) => id !== FILTERS_ID);

    const actions = (
      <>
        {nonFiltersHeaderAction.map(({ action, id }) => (
          <Fragment key={id}>{action}</Fragment>
        ))}
        {filtersHeaderAction}
      </>
    );

    return (
      <TabsHeader
        tabs={Object.values(SUBSCRIPTION_TABS_TYPES)}
        selected={selected}
        setSelected={setSelected}
        actions={
          <>
            <SearchBar onSearch={onSubscriptionSearch} defaultText="Search by Subscription or Owner Name" showAlways={tablet} />
            {actions}
          </>
        }
        actionsWidth={350}
        notifications={{
          [SUBSCRIPTION_TABS_TYPES.ACTIVE]: unreadSubscriptionOrdersCount || 0
        }}
      />
    );
  }, [selected, setSelected, tabs]);

  return (
    <>
      <Header />
      <SubscriptionsHeaderDashboard />
      {!mobile && <Filters filtersControl={filtersControl} filterItems={filterItems} />}
      {mobile && <SearchBar onSearch={onSubscriptionSearch} showAlways defaultText="Search by Subscription or Owner Name" />}
    </>
  );
};

export default React.memo(SubscriptionsHeader, areObjectsEqual);
