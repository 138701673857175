import { useLazyQuery, useMutation, useQuery, useReactiveVar } from '@apollo/client';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { sentenceCase } from 'sentence-case';
import Colors from '../../../Colors';
import Alert from '../../../components/Alert/Alert';
import { DrawerSubLabel } from '../../../components/DrawerBar/styled';
import Modal from '../../../components/Modal/Modal';
import ModalDialog from '../../../components/Modal/ModalDialog';
import { ModalBody, ModalFooter } from '../../../components/Modal/styled';
import { FormButtonsContainer, FormError, FormInput, FormLabel, FormSubmitButton, InputsWrapper, RadioBtnsGroup, WideInputGroup, selectTheme } from '../../../components/Shared/Forms/Forms';
import MarkupEditor from '../../../components/Shared/MarkupEditor';
import { Container, FlexCenterCenter } from '../../../components/Shared/Shared';
import PageLoader from '../../../components/Shared/Spinner';
import useIcons from '../../../hooks/useIcons';
import usePhotoUpload, { useDeletePhotos } from '../../../hooks/usePhotoUpload';
import { AddFlagItem, AddProduct, AttachCategoryToProduct, DeleteFlagItem, DeleteProduct, DeleteProductCategoryItem, EditProduct, GetBusUserProfile, GetFromFlag, GetProduct } from '../../../queries';
import { vars } from '../../../reactive';
import { getBranchCurrencySymbol } from '../../../utils/getBranchCurrencySymbol';
import { getKeys } from '../../../utils/helpers';
import { AddPhotoByLink } from '../AddPhotoByLink';
import Common from '../Common';
import Customizations from '../Customizations/Customizations';
import { getSelectedCustomizations } from '../Customizations/utils';
import PhotoLink from '../PhotoLink';
import ProductOptions, { getSelectedProductOptions } from '../ProductOptions';
import ProductPrice, { getChargeType } from '../ProductPrice';
import { AddImageButton, AddImageContainer, AddNewContainer, AddedImage, CategorySelect, FlexColumnCenterBetweenContainer, PhotosContainer, RemoveAddedImage } from '../styled';
import { productStatuses } from '../types';
import { LinkedProductContainer } from './styled';

const futureDeliveryLabel = 'Allow future order delivery';
type ProductModalFormProps = {
  productEditLoading: any;
  product: any;
  uploadedPhotos: any;
  uploadedLinks: any;
  icons: any;
  handleRemovePhoto: any;
  fileInputRef: any;
  LinkModalRef: any;
  setUploadedLinks: any;
  handlePhotoSelect: any;
  markupEditorRef: any;
  photoUploadError: any;
  editError: any;
  addError: any;
  addLoading: any;
  photoUploadLoading: any;
  editLoading: any;
  productId: any;
  handleFormUpdate: any;
  handleFormSubmit: any;
  handleDeleteProduct: any;
  deleteError: any;
  deleteLoading: any;
  hasFromFlag: boolean;
  formOptions: ReturnType<typeof useForm>;
};

function ProductModalForm({
  productEditLoading,
  product,
  uploadedPhotos,
  uploadedLinks,
  icons,
  handleRemovePhoto,
  fileInputRef,
  LinkModalRef,
  setUploadedLinks,
  handlePhotoSelect,
  markupEditorRef,
  photoUploadError,
  editError,
  addError,
  addLoading,
  photoUploadLoading,
  editLoading,
  productId,
  handleFormUpdate,
  handleFormSubmit,
  handleDeleteProduct,
  deleteError,
  deleteLoading,
  hasFromFlag,
  formOptions
}: ProductModalFormProps) {
  const { control, errors } = formOptions;
  return (
    <>
      <ModalBody>
        <AddNewContainer>
          {!productEditLoading ? (
            <Container>
              <InputsWrapper noWrap>
                <WideInputGroup>
                  <FormLabel>Title</FormLabel>
                  <Controller as={<FormInput error={errors.name} height={32} fontSize={16} />} control={control} name={'name'} defaultValue={product?.name || ''} rules={{ required: true }} />
                  {errors.name && <FormError>{errors.name.message || 'name is required'}</FormError>}
                </WideInputGroup>
                {!!product?.PharmaItemProducts?.length && (
                  <WideInputGroup>
                    <LinkedProductContainer>
                      <DrawerSubLabel size={16} weight={700} color={Colors.black}>
                        Linked to: {product?.PharmaItemProducts?.[0]?.PharmaItem?.description}
                      </DrawerSubLabel>
                      <DrawerSubLabel size={16} weight={600} color={Colors.black}>
                        Net Cost: {getBranchCurrencySymbol()}
                        {product?.PharmaItemProducts?.[0]?.PharmaItem?.cost}
                      </DrawerSubLabel>
                      <DrawerSubLabel size={16} weight={600} color={Colors.black}>
                        Supplier: {product?.PharmaItemProducts?.[0]?.PharmaItem?.supplier}
                      </DrawerSubLabel>
                      <DrawerSubLabel size={16} weight={600} color={Colors.black}>
                        Controlled Drug: {product?.PharmaItemProducts?.[0]?.PharmaItem?.controlled_drug ? 'Yes' : 'No'}
                      </DrawerSubLabel>
                      <DrawerSubLabel size={16} weight={600} color={Colors.black}>
                        Margin: {product?.PharmaItemProducts?.[0]?.price_margin}%
                      </DrawerSubLabel>
                      <DrawerSubLabel size={16} weight={600} color={Colors.black}>
                        Pack Size: {product?.PharmaItemProducts?.[0]?.PharmaItem?.pack_size}
                      </DrawerSubLabel>
                    </LinkedProductContainer>
                  </WideInputGroup>
                )}

                <WideInputGroup>
                  <FormLabel>SKU Number</FormLabel>
                  <Controller as={<FormInput error={errors.sku} height={32} fontSize={16} />} control={control} name={'sku'} defaultValue={product?.sku || ''} rules={{ required: false }} />
                  {errors.sku && <FormError>{errors.sku.message || 'sku is required'}</FormError>}
                </WideInputGroup>

                <ProductPrice formOptions={formOptions} product={product} />

                <WideInputGroup>
                  <FormLabel>Stock (leave empty for unlimited)</FormLabel>
                  <Controller
                    as={<FormInput error={errors.stock} type={'number'} height={32} fontSize={16} />}
                    control={control}
                    name={'stock'}
                    defaultValue={product?.stock !== null && product?.stock >= 0 ? String(product?.stock) : ''}
                    rules={{
                      validate: val => {
                        if (!val) {
                          return true;
                        }

                        const newStock = Number(val);
                        const isNumber = !isNaN(newStock);
                        const isPositive = newStock >= 0;
                        return isNumber && isPositive;
                      },
                      required: false
                    }}
                  />
                  {errors.stock && <FormError>{errors.stock.message || 'Stock is invalid'}</FormError>}
                </WideInputGroup>

                <ProductOptions formOptions={formOptions} product={product} hasFromFlag={hasFromFlag} />

                <WideInputGroup>
                  <FormLabel>Category</FormLabel>
                  <Controller
                    as={<CategorySelect defaultValue={product?.ProductCategories.map(category => ({ label: category.name, value: category.id })) || null} />}
                    control={control}
                    name={'category'}
                    defaultValue={product?.ProductCategories.map(category => ({ label: category.name, value: category.id })) || null}
                    rules={{ required: true }}
                  />
                  {errors.category && <FormError>{errors.category.message || 'category is required'}</FormError>}
                </WideInputGroup>

                <WideInputGroup>
                  <FormLabel>Product Status</FormLabel>
                  <Controller
                    as={
                      <Select
                        styles={{ container: (provided, state) => ({ ...provided }), valueContainer: (provided, state) => ({ ...provided, height: 48, overflowY: 'scroll' }) }}
                        options={productStatuses}
                        theme={selectTheme}
                        isClearable
                        defaultValue={product?.status ? { value: product?.status, label: sentenceCase(product?.status || '') } : productStatuses[0]}
                      />
                    }
                    control={control}
                    name={'status'}
                    defaultValue={product?.status ? { value: product?.status, label: sentenceCase(product?.status || '') } : productStatuses[0]}
                    rules={{ required: true }}
                  />
                  {errors.status && <FormError>{errors.status.message || 'status is required'}</FormError>}
                </WideInputGroup>

                <WideInputGroup>
                  <FormLabel>Weight</FormLabel>
                  <Controller
                    as={<FormInput error={errors.weight} type={'number'} height={32} fontSize={16} />}
                    control={control}
                    name={'weight'}
                    defaultValue={product?.weight || ''}
                    rules={{
                      min: 0
                    }}
                  />
                  {errors.weight && <FormError>{errors.weight.message || 'weight is required'}</FormError>}
                </WideInputGroup>

                <WideInputGroup>
                  <FormLabel>Application Unit</FormLabel>
                  <Controller
                    as={
                      <Select
                        styles={{ container: (provided, state) => ({ ...provided }), valueContainer: (provided, state) => ({ ...provided, height: 48, overflowY: 'scroll' }) }}
                        options={[
                          { value: 'capsule', label: 'capsule' },
                          { value: 'chew', label: 'chew' },
                          { value: 'drops', label: 'drops' },
                          { value: 'g', label: 'g' },
                          { value: 'graduation', label: 'graduation' },
                          { value: 'ml', label: 'ml' },
                          { value: 'pipette', label: 'pipette' },
                          { value: 'pump', label: 'pump' },
                          { value: 'scoop', label: 'scoop' },
                          { value: 'spray', label: 'spray' },
                          { value: 'tablet', label: 'tablet' },
                          { value: 'tbs', label: 'tbs' }
                        ]}
                        theme={selectTheme}
                        isClearable
                        defaultValue={{ value: product?.unit, label: product?.unit } || ''}
                      />
                    }
                    control={control}
                    name={'unit'}
                    defaultValue={{ value: product?.unit, label: sentenceCase(product?.unit || '') } || ''}
                    rules={{}}
                  />
                  {errors.unit && <FormError>{errors.unit.message || 'unit is required'}</FormError>}
                </WideInputGroup>
              </InputsWrapper>
              <InputsWrapper noWrap>
                <WideInputGroup>
                  <FormLabel>Photos ({[...(uploadedPhotos || []), ...(uploadedLinks || [])].length} / 8)</FormLabel>
                  <PhotosContainer>
                    {[...(uploadedPhotos || []), ...(uploadedLinks || [])].map((photo, idx) => {
                      return (
                        <FlexColumnCenterBetweenContainer key={idx}>
                          <AddedImage key={idx} src={typeof photo === 'string' ? photo : URL.createObjectURL(photo)} />
                          <RemoveAddedImage src={icons.delete.childImageSharp.gatsbyImageData.images.fallback.src} onClick={() => handleRemovePhoto(photo)} />
                        </FlexColumnCenterBetweenContainer>
                      );
                    })}
                    <FlexColumnCenterBetweenContainer>
                      <AddImageContainer disabled={[...uploadedPhotos, ...uploadedLinks].length >= 8}>
                        <AddImageButton onClick={() => fileInputRef?.current?.click()} src={icons.addPhoto.childImageSharp.gatsbyImageData.images.fallback.src} />
                        <Modal
                          ref={LinkModalRef}
                          title="Image Link"
                          ModalBtn={AddPhotoByLink}
                          modalContent={() => <PhotoLink setPhotos={setUploadedLinks} photos={uploadedLinks} LinkModalRef={LinkModalRef} />}
                        />
                      </AddImageContainer>
                    </FlexColumnCenterBetweenContainer>
                    <input ref={fileInputRef} type={'file'} accept={'image/*'} onChange={handlePhotoSelect} disabled={uploadedPhotos.length >= 8} style={{ display: 'none' }} multiple />
                  </PhotosContainer>
                </WideInputGroup>
              </InputsWrapper>
              <InputsWrapper noWrap>
                <Customizations
                  formOptions={formOptions}
                  defaultValue={product?.customizations || ''}
                  hideRules={{
                    ASSIGN: true,
                    DURATION: true
                  }}
                />
              </InputsWrapper>
              <InputsWrapper noWrap>
                <WideInputGroup>
                  <FormLabel>Description</FormLabel>
                  <MarkupEditor ref={markupEditorRef} defaultValue={product?.description || ''} />
                </WideInputGroup>
                <WideInputGroup>
                  <FormLabel>Sort Index</FormLabel>
                  <Controller
                    as={<FormInput error={errors.sort_index} type={'number'} height={32} fontSize={16} />}
                    control={control}
                    name={'sort_index'}
                    defaultValue={product?.sort_index || 1}
                    rules={{ required: false }}
                  />
                  <FormError>{errors.sort_index?.message || ''}</FormError>
                </WideInputGroup>
              </InputsWrapper>
              <InputsWrapper noWrap>
                <WideInputGroup>
                  <FormLabel>Short Description</FormLabel>
                  <Controller
                    as={<FormInput error={errors.short_description} height={32} fontSize={16} />}
                    control={control}
                    name={'short_description'}
                    defaultValue={product?.short_description || ''}
                    rules={{}}
                  />
                </WideInputGroup>
              </InputsWrapper>
              <InputsWrapper noWrap>
                <WideInputGroup>
                  <FormLabel>Product disclaimer</FormLabel>
                  <Controller
                    as={<FormInput error={errors?.additional_info?.payment_description} height={32} fontSize={16} />}
                    control={control}
                    name={'additional_info.payment_description'}
                    defaultValue={product?.additional_info?.payment_description || ''}
                    rules={{}}
                  />
                </WideInputGroup>
              </InputsWrapper>
              <InputsWrapper noWrap>
                <WideInputGroup>
                  <FormLabel>Action Button Text</FormLabel>
                  <Controller as={<FormInput error={errors.cta_text} height={32} fontSize={16} />} control={control} name={'cta_text'} defaultValue={product?.cta_text || ''} rules={{}} />
                  {errors.cta_text && <FormError>{errors.cta_text.message || 'Action Button Text is required'}</FormError>}
                </WideInputGroup>
                <WideInputGroup>
                  <FormLabel>Future Delivery</FormLabel>
                  <Controller
                    control={control}
                    name={'future_delivery'}
                    defaultValue={product?.future_delivery ? [futureDeliveryLabel] : []}
                    render={({ onChange }) => (
                      <RadioBtnsGroup
                        options={[futureDeliveryLabel]}
                        name={'future_delivery'}
                        inputType={'checkbox'}
                        defaultValue={product?.future_delivery ? [futureDeliveryLabel] : []}
                        onChange={e => onChange(e.target.checked)}
                      />
                    )}
                  />
                  {errors.future_delivery && <FormError>{errors.future_delivery.message || 'future delivery is required'}</FormError>}
                </WideInputGroup>
              </InputsWrapper>
            </Container>
          ) : (
            <FlexCenterCenter height={650}>
              <PageLoader size={50} />
            </FlexCenterCenter>
          )}
        </AddNewContainer>
      </ModalBody>
      <ModalFooter>
        <FormButtonsContainer>
          <FormSubmitButton
            error={addError || photoUploadError || editError}
            loading={addLoading || photoUploadLoading || editLoading}
            onClick={() => (productId ? handleFormUpdate() : handleFormSubmit())}
          >
            {productId ? 'Update' : 'Save'}
          </FormSubmitButton>
          {/* {productId && (
            <FormSubmitButton onClick={handleDeleteProduct} error={deleteError} loading={deleteLoading} secondary danger>
              Delete Product
            </FormSubmitButton>
          )} */}
        </FormButtonsContainer>
      </ModalFooter>
    </>
  );
}

const ProductModal = () => {
  const icons = useIcons();
  const fileInputRef = useRef(null);
  const LinkModalRef = useRef(null);
  const markupEditorRef = useRef(null);
  const formOptions = useForm();
  const { control, handleSubmit } = formOptions;
  const [uploadedPhotos, setUploadedPhotos] = useState([]);
  const [deletePhotos, { setPhotosDeleteQueue }] = useDeletePhotos();
  const [uploadedLinks, setUploadedLinks] = useState([]);
  const [formCategories, setFormCategories] = useState([]);
  const productId = useReactiveVar(vars.productId); //will be null if adding new product will only be filled in case of editing
  const { data: { getBusUserProfile: profile = {} } = {} } = useQuery(GetBusUserProfile);
  const { data: { flagGet: fromFlagData = [] } = {} } = useQuery(GetFromFlag);
  const [loadProduct, { data: { productGet: [product] = [] } = {}, loading: productEditLoading }] = useLazyQuery(GetProduct, { fetchPolicy: 'cache-and-network' }); //used only when editing a product
  const [handleAddProduct, { data: { productAdd: addedProduct } = {}, loading: addLoading, error: addError }] = useMutation(AddProduct, { fetchPolicy: 'no-cache' });
  const [handleEditProduct, { data: { productEdit: editedProduct } = {}, loading: editLoading, error: editError }] = useMutation(EditProduct, { fetchPolicy: 'no-cache' });
  const [handleDelete, { data: { deleteProduct } = {}, loading: deleteLoading, error: deleteError }] = useMutation(DeleteProduct, { fetchPolicy: 'no-cache' });
  const [handleAttachCategoryToProduct] = useMutation(AttachCategoryToProduct, { fetchPolicy: 'no-cache' });
  const [handleAddFlagItem] = useMutation(AddFlagItem, { fetchPolicy: 'no-cache' });
  const [handleDeleteFlagItem] = useMutation(DeleteFlagItem, { fetchPolicy: 'no-cache' });
  const [handleDeleteProductCategoryItem] = useMutation(DeleteProductCategoryItem, { fetchPolicy: 'no-cache' });
  const [handlePhotosUpload, { data: photos, loading: photoUploadLoading, error: photoUploadError }, { checkPhotosSize }] = usePhotoUpload(); //custom hook for uploading photos to firestore
  const hasFromFlag = product?.Flags.filter((flag: any) => flag.id === fromFlagData?.[0]?.id).length > 0;

  const handleFormSubmit = handleSubmit(form => {
    const customizations = getSelectedCustomizations(form.customizations);
    const submittedCategoriesIds = form?.category.map(category => category?.value);

    setFormCategories(submittedCategoriesIds);
    const formWithoutCustomizations = {
      name: form?.name,
      price: form?.price ? form?.price : form?.original_price || '0',
      stock: form?.stock ? String(form?.stock) : null,
      original_price: form?.original_price || '0',
      charge_type: getChargeType(form?.charge_status),
      cta_text: form?.cta_text,
      duration: form?.duration,
      sku: form?.sku,
      booking_type: form?.booking_type,
      status: form?.status?.value,
      weight: form?.weight,
      unit: form?.unit?.value,
      short_description: form?.short_description,
      future_delivery: !!form?.future_delivery,
      sort_index: Number(form?.sort_index || 500),
      additional_info: {
        ...(form?.additional_info?.payment_description ? { payment_description: form?.additional_info?.payment_description } : {})
      }
    };
    handleAddProduct({ variables: { ...formWithoutCustomizations, description: markupEditorRef?.current?.getHTML(), photos: [...uploadedLinks], customizations } });
  });

  const handleFormUpdate = handleSubmit(form => {
    const customizations = getSelectedCustomizations(form.customizations);
    const productOptions = getSelectedProductOptions(form.productOptions);
    const addedFromFlag = productOptions.from;
    const formWithoutCustomizations = {
      name: form?.name,
      price: form?.price || '0',
      stock: form?.stock ? String(form?.stock) : null,
      original_price: form?.original_price || '0',
      charge_type: getChargeType(form?.charge_status),
      cta_text: form?.cta_text,
      duration: form?.duration,
      sku: form?.sku,
      booking_type: form?.booking_type,
      status: form?.status?.value,
      category: form?.category,
      weight: form?.weight,
      unit: form?.unit?.value || '',
      short_description: form?.short_description,
      future_delivery: !!form?.future_delivery,
      sort_index: Number(form?.sort_index || 500),
      additional_info: {
        ...(form?.additional_info?.payment_description ? { payment_description: form?.additional_info?.payment_description } : {})
      }
    };
    const submittedData = { ...formWithoutCustomizations, description: markupEditorRef?.current?.getHTML(), customizations };
    const data = getKeys(submittedData)
      .filter(key => {
        if (key === 'category') {
          const existingCategories = product.ProductCategories.map(category => category.id); //in DB
          const submittedCategories = submittedData.category.map(category => category.value); //in DB + Form
          const removedCategories = existingCategories.filter(category => !submittedCategories.includes(category)); //in DB + not in Form

          removedCategories.forEach(category => {
            handleDeleteProductCategoryItem({ variables: { ProductId: product.id, ProductCategoryId: category }, fetchPolicy: 'no-cache' });
          });

          submittedData.category = submittedData.category.filter(category => !existingCategories.includes(category.value)).map(category => category?.value);
          submittedData.category.forEach(category => {
            handleAttachCategoryToProduct({ variables: { ProductId: product.id, ProductCategoryId: category }, fetchPolicy: 'no-cache' });
          });
          return false;
        }
        return submittedData[key] !== product[key];
      })
      .reduce((changes, key) => ({ ...changes, [key]: submittedData[key] }), {});

    const url = `images/providers/${profile.Provider.id}/branches/${profile.Branch.id}/products/${product.id}`;
    if (uploadedPhotos.length) {
      const newPhotos = uploadedPhotos.filter(photo => typeof photo !== 'string');
      handlePhotosUpload(url, newPhotos);
    }

    deletePhotos(url);

    if (addedFromFlag && !hasFromFlag) {
      handleAddFlagItem({ variables: { id: product.id, flagId: fromFlagData?.[0]?.id }, fetchPolicy: 'no-cache' });
    } else if (!addedFromFlag && hasFromFlag) {
      handleDeleteFlagItem({ variables: { id: product.id, flagId: fromFlagData?.[0]?.id }, fetchPolicy: 'no-cache' });
    }
    handleEditProduct({ variables: { id: product.id, ...data, photos: [...uploadedLinks] }, fetchPolicy: 'no-cache' });
  });

  const handleDeleteProduct = () => {
    Alert.alert({
      title: 'Are you sure?',
      acceptButtonText: 'Yes',
      denyButtonText: 'No',
      description: 'Are you sure you want to delete this product?',
      onAccept: () => {
        handleDelete({ variables: { id: product.id }, fetchPolicy: 'no-cache' });
      }
    });
  };

  const handlePhotoSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPhotos = [...uploadedPhotos, ...(e.target.files || [])];
    if ([...newPhotos, ...uploadedLinks].length > 8) {
      Alert.alert({
        title: 'Maximum number of photos reached',
        acceptButtonText: 'Ok',
        description: 'You can only upload up to 8 photos',
        options: {
          hideDenyButton: true
        }
      });
      return;
    }

    if (checkPhotosSize(e)) {
      setUploadedPhotos(newPhotos);
    }
  };

  const handleRemovePhoto = (removedPhoto: any) => {
    setUploadedPhotos(uploadedPhotos.filter(photo => photo !== removedPhoto));
    setUploadedLinks(uploadedLinks.filter(link => link !== removedPhoto));
    if (typeof removedPhoto === 'string') {
      setPhotosDeleteQueue(photosDeleteQueue => [...photosDeleteQueue, removedPhoto]);
    }
  };

  //for uploading photos to firestore after adding the product **because we need the id**
  useEffect(() => {
    if (addedProduct) {
      if (uploadedPhotos.length) {
        const url = `images/providers/${profile.Provider.id}/branches/${profile.Branch.id}/products/${addedProduct.id}`;
        handlePhotosUpload(url, uploadedPhotos);
      }
      formCategories.forEach(category => {
        handleAttachCategoryToProduct({ variables: { ProductId: addedProduct.id, ProductCategoryId: category }, fetchPolicy: 'no-cache' });
      });

      const form = control.getValues();
      const addedFromFlag = getSelectedProductOptions(form.productOptions).from;
      if (addedFromFlag) {
        handleAddFlagItem({ variables: { id: addedProduct.id, flagId: fromFlagData?.[0]?.id }, fetchPolicy: 'no-cache' });
      }
    }
  }, [addedProduct]);

  //after the photos are added we update the product with the URL of each photo
  useEffect(() => {
    if (photos && (addedProduct || editedProduct)) {
      handleEditProduct({ variables: { id: addedProduct?.id || editedProduct?.id, photos: [...(addedProduct?.photos || editedProduct?.photos), ...photos] }, fetchPolicy: 'no-cache' });
    }
  }, [photos]);

  //after the photos are attached to the product we reload the products and close the modal
  useEffect(() => {
    if ((editedProduct && !photoUploadLoading) || deleteProduct || (addedProduct && !uploadedPhotos.length)) {
      Common.get(`Products.GetProducts.refetch`)();
      ModalDialog?.closeModal();
    }
  }, [editedProduct, photoUploadLoading, deleteProduct, addedProduct]);

  //in case of editing a product we load the product data by its id
  useEffect(() => {
    if (productId) {
      loadProduct({ variables: { id: productId } });
    }
  }, [productId]);

  //for mapping data into its proper fields
  useEffect(() => {
    if (product) {
      if (product?.photos.length) {
        setUploadedLinks([...product?.photos]);
      }
    }
  }, [productEditLoading]);

  return (
    <ProductModalForm
      {...{
        productEditLoading,
        product,
        uploadedPhotos,
        uploadedLinks,
        icons,
        handleRemovePhoto,
        fileInputRef,
        LinkModalRef,
        setUploadedLinks,
        handlePhotoSelect,
        markupEditorRef,
        addError,
        photoUploadError,
        editError,
        addLoading,
        photoUploadLoading,
        editLoading,
        productId,
        handleFormUpdate,
        handleFormSubmit,
        handleDeleteProduct,
        deleteError,
        deleteLoading,
        hasFromFlag,
        formOptions
      }}
    />
  );
};

export default ProductModal;
