import React from 'react';
import { sentenceCase } from 'sentence-case';
import { toReadableDate } from '../../utils/dates';
import { getKeys } from '../../utils/helpers';
import { Divider, PetDrawerContent } from '../DrawerBar/styled';
import { PetDrawerTag, PetDrawerTagsContainer } from '../PetDrawer/styled';
import { ExtensionDivider } from '../Pets/styled';
import { Pet } from '../Pets/types';
import BranchNotes from '../Shared/BranchNotes/BranchNotes';
import { BranchNoteType } from '../Shared/BranchNotes/types';
import { ProfileInfoContainer, ProfileInfoWrapper, ProfileLabel, ProfileSection, ProfileSubLabel } from './styled';

const PetProfileInfo = ({ profile }: { profile: Pet }) => {
  const allergies = profile?.PetRecord?.allergies;
  const conditions = getKeys(profile?.PetRecord?.chronic_conditions || {})
    .filter(cond => profile?.PetRecord?.chronic_conditions[cond])
    .map(cond => sentenceCase(cond))
    .join(', ');
  const petAllergies = getKeys(allergies || {})
    ?.filter(allergy => allergies[allergy])
    .map(cond => sentenceCase(cond))
    .join(', ');
  const diet = profile?.PetRecord?.BranchCategories?.map(category => category?.name).join(', ');
  const dietBranch = [...new Set(profile?.PetRecord?.Meals?.map(meal => meal?.name))].join(', ');
  const vet = profile?.PetRecord?.Branch?.name;
  const weight = profile?.PetRecord?.measurements?.weights?.slice(-1)?.[0]?.weight;

  const sections = [
    { subLabel: 'Breed', label: profile?.Breed?.name, unit: '' },
    { subLabel: 'Gender', label: sentenceCase(profile?.gender || ''), unit: '' },
    { subLabel: profile?.gender === 'FEMALE' ? 'Spayed' : 'Neutered', label: profile?.PetRecord?.hasOwnProperty('neutred') ? (profile?.PetRecord?.neutred ? 'Yes' : 'No') : '', unit: '' },
    { subLabel: 'Birthdate', label: toReadableDate(profile?.birthdate, { noTime: true, isLocale: true }), unit: '' },
    { subLabel: 'Colour', label: profile?.PetColor?.name, unit: '' },
    { subLabel: 'Weight', label: weight, unit: 'kg' },
    { subLabel: 'Allergies', label: petAllergies, unit: '' },
    { subLabel: 'Conditions', label: conditions, unit: '' },
    { subLabel: 'Behavior', label: null, unit: '' },
    { subLabel: 'Diet', label: diet, unit: '' },
    { subLabel: 'Food Brands', label: dietBranch, unit: '' },
    { subLabel: 'Vet', label: vet, unit: '' },
    { subLabel: 'Microchip', label: profile?.PetRecord?.microchip_number, unit: '' },
    { subLabel: 'Microchip Provider', label: profile?.PetRecord?.microchip_provider, unit: '' },
    { subLabel: 'Passport Number', label: profile?.PetRecord?.passport_number, unit: '' }
  ];

  const petTags = profile?.PetRecord?.BranchPetRecordTags;

  return (
    <PetDrawerContent>
      <ProfileInfoWrapper>
        <ProfileInfoContainer>
          {!!petTags?.length && (
            <>
              <PetDrawerTagsContainer>
                {petTags?.map((item, index) => (
                  <PetDrawerTag color={item.color} key={index}>
                    <span>{item.name}</span>
                  </PetDrawerTag>
                ))}
              </PetDrawerTagsContainer>
              <ExtensionDivider />
            </>
          )}
          {profile?.PetRecord?.id && <BranchNotes type={BranchNoteType.PET_RECORD} PetRecordId={profile?.PetRecord?.id} />}
          <Divider marginBottom={12} marginTop={12} />

          {sections.map(({ subLabel, label, unit }, i) => (
            <ProfileSection key={i}>
              <ProfileSubLabel>{subLabel}</ProfileSubLabel>
              <ProfileLabel>{label ? label + (unit || '') : '-'}</ProfileLabel>
            </ProfileSection>
          ))}
        </ProfileInfoContainer>
      </ProfileInfoWrapper>
    </PetDrawerContent>
  );
};

export default PetProfileInfo;
