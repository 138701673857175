import styled, { css } from 'styled-components';
import Colors from '../../../../Colors';

export const TrainingReportNotesContainer = styled.div`
  padding-top: 8px;
  padding-right: 8px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
export const TrainingReportNoteContainer = styled.div`
  padding: 16px;
  border-radius: 24px;
  &:hover {
    background-color: #ededed;
  }
  margin-top: 8px;
  border: 2px solid #e9e9e9;
`;

export const ExtensionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;
