declare var document: any;
declare var performance: any;

import { useEffect, useState } from 'react';

export type Props = {
  delay?: number;
};

export default function useInterval({ delay = 1000 }: Props) {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const controller = new AbortController();
    animationInterval(delay, controller.signal, timeElapsed => {
      setTime(new Date());
    });

    return () => {
      controller.abort();
    };
  }, []);

  return time || new Date();
}

function animationInterval(ms: number, signal: AbortSignal, callback: (n: number) => void) {
  const start = getCurrentTime();

  function frame(time: number) {
    if (signal.aborted) return;
    callback(time - start);
    scheduleFrame(time);
  }

  function scheduleFrame(time: number) {
    const elapsed = time - start;
    const roundedElapsed = Math.round(elapsed / ms) * ms;
    const targetNext = start + roundedElapsed + ms;
    const delay = targetNext - getCurrentTime();
    setTimeout(() => requestAnimationFrame(frame), delay);
  }

  scheduleFrame(start);
}

function getCurrentTime() {
  // Prefer currentTime, as it'll better sync animtions queued in the
  // same frame, but if it isn't supported, performance.now() is fine.
  if (typeof document !== 'undefined' && document.timeline) {
    return document.timeline.currentTime!;
  }
  if (typeof performance !== 'undefined') {
    return performance.now();
  }
  return Date.now();
}
