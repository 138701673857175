import React, { FC } from 'react';
import useIcons from '../../hooks/useIcons';
import { ActionBtn } from '../../views/Store/styled';

const RefreshButton: FC<{
  checkSelectedTabAndRefetch: () => void;
  setHasMoreItems: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ checkSelectedTabAndRefetch, setHasMoreItems }) => {
  const icons = useIcons();
  const refreshIcon = icons.refresh.childImageSharp.gatsbyImageData.images.fallback.src;

  const handleRefresh = () => {
    checkSelectedTabAndRefetch();
    setHasMoreItems(true);
  };

  return <ActionBtn onClick={handleRefresh} bgImage={refreshIcon} rotateOnClick />;
};

export default RefreshButton;
