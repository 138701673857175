import React, { FC } from 'react';
import { Controller, FieldValues, UseFormMethods, useForm, useWatch } from 'react-hook-form';
import { FormInput } from './Forms';
import { SwitchButton } from './styled';

export function useRadioSwitchValue({ defaultValue }: { defaultValue?: boolean }): [UseFormMethods<FieldValues>, boolean] {
  const form = useForm({
    defaultValues: {
      'radio-switch': defaultValue || false
    }
  });

  const { control } = form;

  const watchedSwitch = useWatch({
    control,
    name: 'radio-switch',
    defaultValue: false
  });

  return [form, watchedSwitch];
}

const RadioSwitch: FC<{ onChange?: (on: boolean) => void; defaultValue?: boolean }> = ({ onChange: handleChange, defaultValue }) => {
  const [{ control }, watchedSwitch] = useRadioSwitchValue({ defaultValue });

  return (
    <Controller
      control={control}
      name="radio-switch"
      render={({ onChange, value }) => (
        <SwitchButton>
          <FormInput
            name="radio-switch"
            type="checkbox"
            onChange={() => {
              onChange(!value);
              handleChange?.(!value);
            }}
            defaultChecked={defaultValue || false}
          />
          <span />
        </SwitchButton>
      )}
      defaultValue={defaultValue || false}
    />
  );
};

export default RadioSwitch;
