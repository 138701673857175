import React from 'react';
import { ViewContainer } from '../../components/Shared/Shared';
import useMediaQuery from '../../hooks/useMediaQuery';
import useNavigateOnTab from '../../hooks/useNavigateOnTab';
import useSelectedState from '../../hooks/useSelectedState';
import BranchAppUsers from './BranchAppUsers/BranchAppUsers';
import PetsBody from './PetsBody/PetsBody';
import PetsForms from './PetsForms';
import PetsHeader from './PetsHeader/PetsHeader';

export enum PETS_TABS_TYPES {
  ALL = 'all',
  CLIENTS = 'clients',
  FORMS = 'forms'
}

const Pets = () => {
  const selectedTabState = useSelectedState({ defaultTab: PETS_TABS_TYPES.ALL, TABS_TYPES: PETS_TABS_TYPES });
  const [selectedTab] = selectedTabState;
  const showFilters = [PETS_TABS_TYPES.ALL].includes(selectedTab);
  const { mobile } = useMediaQuery({ mobile: true });

  useNavigateOnTab({
    selected: selectedTab
  });
  const tabs = !mobile ? Object.values(PETS_TABS_TYPES) : Object.values(PETS_TABS_TYPES).filter(tab => tab !== PETS_TABS_TYPES.FORMS);

  return (
    <ViewContainer>
      <PetsHeader selectedTabState={selectedTabState} tabs={tabs} showFilters={showFilters} />
      {selectedTab === PETS_TABS_TYPES.ALL && <PetsBody selectedTabState={selectedTabState} />}
      {selectedTab === PETS_TABS_TYPES.CLIENTS && <BranchAppUsers />}
      {selectedTab === PETS_TABS_TYPES.FORMS && <PetsForms />}
    </ViewContainer>
  );
};

export default Pets;
