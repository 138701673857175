import { useLazyQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { Controller, UseFormMethods } from 'react-hook-form';
import AsyncSelect from 'react-select/async';
import { GetReducedProductById, GetSearchBranchProducts } from '../../../queries';
import debounce from '../../../utils/debounce';
import { Product } from '../../../views/Store/types';
import { selectTheme } from '../Forms/Forms';

const getOptions = (data: Record<string, Product[]>, itemsToFilter: string[] = []) => {
  return (
    Object.values(data || {})?.[0]
      ?.filter(product => !itemsToFilter.includes(product.id))
      ?.map(product => ({ value: product.id, label: `${product.name}\n${product.short_description ? `(${product.short_description})` : ''}` })) || []
  );
};

const ProductsList = ({
  formOptions,
  defaultValues,
  name,
  onChangeProduct,
  itemsToFilter,
  rules
}: {
  formOptions: UseFormMethods<any>;
  defaultValues?: { defaultProductId: string; defaultProduct?: Product };
  name?: string;
  onChangeProduct?: (productId: string) => void;
  itemsToFilter?: string[];
  rules?: Record<string, any>;
}) => {
  const { defaultProductId, defaultProduct } = defaultValues || {};
  const { control } = formOptions;
  const [getSearchProducts, { data }] = useLazyQuery(GetSearchBranchProducts, { fetchPolicy: 'cache-and-network' });
  const options = getOptions(data);

  const [getProductById, { data: { productGet: [defaultLoadProduct = {}] = [] } = {} }] = useLazyQuery(GetReducedProductById, {
    fetchPolicy: 'cache-and-network'
  });

  const loadOptions = (inputValue: string, callback: (options: { value: string; label: string }[]) => void) => {
    debounce(async () => {
      const { data } = await getSearchProducts({ variables: { search: [inputValue] } });
      const options = getOptions(data, itemsToFilter);
      callback(options);
    }, 500);
  };

  useEffect(() => {
    if (defaultProductId && !defaultProduct?.id) {
      getProductById({ variables: { id: [defaultProductId] } });
    }
  }, [defaultProductId]);

  return (
    <Controller
      name={name || 'productId'}
      control={control}
      defaultValue={defaultProductId || ''}
      rules={{ required: true, ...rules }}
      render={({ onChange, value }) => {
        const val = value ? options.find(option => option.value === value) : '';
        const defaultVal = defaultProductId && defaultProductId === value ? { value: defaultProductId, label: (defaultProduct || defaultLoadProduct)?.name } : '';
        return (
          <AsyncSelect
            styles={{
              container: provided => ({ ...provided }),
              valueContainer: provided => ({ ...provided, height: 48, overflowY: 'scroll' }),
              option: provided => ({
                ...provided,
                whiteSpace: 'pre-wrap'
              })
            }}
            loadOptions={loadOptions}
            theme={selectTheme}
            name={name || 'productId'}
            onChange={(option: any) => {
              onChangeProduct?.(option?.value);
              onChange(option?.value);
            }}
            value={defaultVal || val || ''}
            options={options}
            cacheOptions
            noOptionsMessage={({ inputValue }) => (inputValue === '' ? 'Start typing to search' : 'No results found')}
          />
        );
      }}
    />
  );
};

export default ProductsList;
