import React from 'react';
import { Controller } from 'react-hook-form';
import { RadioBtnsGroup } from '../../../components/Shared/Forms/Forms';

const AutomaticInvoicing = ({ control, defaultDisableAutomaticInvoicing }) => {
  return (
    <Controller
      control={control}
      name={'disable_automatic_billing'}
      defaultValue={defaultDisableAutomaticInvoicing}
      render={({ onChange, value }) => (
        <RadioBtnsGroup
          name={'disable_automatic_billing'}
          options={['Disable Automatic Invoicing']}
          itemStyle={{ gap: 10 }}
          defaultValue={defaultDisableAutomaticInvoicing}
          inputType={'checkbox'}
          onChange={() => {
            onChange(!value);
          }}
        />
      )}
    />
  );
};

export default AutomaticInvoicing;
