import { useLazyQuery, useMutation, useQuery, useReactiveVar } from '@apollo/client';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import Alert from '../../../components/Alert/Alert';
import Modal from '../../../components/Modal/Modal';
import ModalDialog from '../../../components/Modal/ModalDialog';
import { ModalBody, ModalFooter } from '../../../components/Modal/styled';
import { FormButtonsContainer, FormError, FormInput, FormLabel, FormSubmitButton, InputsWrapper, WideInputGroup, selectTheme } from '../../../components/Shared/Forms/Forms';
import MarkupEditor from '../../../components/Shared/MarkupEditor';
import { Container, FlexCenterCenter } from '../../../components/Shared/Shared';
import PageLoader from '../../../components/Shared/Spinner';
import useIcons from '../../../hooks/useIcons';
import usePhotoUpload, { useDeletePhotos } from '../../../hooks/usePhotoUpload';
import {
  AddFlagItem,
  AddSubscription,
  AttachCategoryToProduct,
  DeleteFlagItem,
  DeleteProduct,
  DeleteProductCategoryItem,
  EditProduct,
  GetBusUserProfile,
  GetFromFlag,
  GetProduct
} from '../../../queries';
import { vars } from '../../../reactive';
import { offsetUTC } from '../../../utils/dates';
import { getKeys } from '../../../utils/helpers';
import { AddPhotoByLink } from '../AddPhotoByLink';
import Common from '../Common';
import Customizations from '../Customizations/Customizations';
import { getSelectedCustomizations } from '../Customizations/utils';
import PhotoLink from '../PhotoLink';
import ProductOptions, { getSelectedProductOptions } from '../ProductOptions';
import ProductPrice from '../ProductPrice';
import { AddImageButton, AddImageContainer, AddNewContainer, AddedImage, CategorySelect, FlexColumnCenterBetweenContainer, PhotosContainer, RemoveAddedImage } from '../styled';
import transformOperatingSlotsState from '../transformOperatingSlotsState';
import { productStatuses } from '../types';

type SubscriptionModalFormProps = {
  subscription: any;
  uploadedPhotos: any;
  uploadedLinks: any;
  icons: any;
  handleRemovePhoto: any;
  fileInputRef: any;
  LinkModalRef: any;
  setUploadedLinks: any;
  handlePhotoSelect: any;
  markupEditorRef: any;
  photoUploadError: any;
  editError: any;
  addError: any;
  addLoading: any;
  photoUploadLoading: any;
  editLoading: any;
  subscriptionId: any;
  handleFormUpdate: any;
  handleFormSubmit: any;
  handleDeleteSubscription: any;
  deleteError: any;
  deleteLoading: any;
  hasFromFlag: boolean;
  formOptions: ReturnType<typeof useForm>;
};

function SubscriptionModalForm({
  subscription,
  uploadedPhotos,
  uploadedLinks,
  icons,
  handleRemovePhoto,
  fileInputRef,
  LinkModalRef,
  setUploadedLinks,
  handlePhotoSelect,
  markupEditorRef,
  photoUploadError,
  editError,
  addError,
  addLoading,
  photoUploadLoading,
  editLoading,
  subscriptionId,
  handleFormUpdate,
  handleFormSubmit,
  handleDeleteSubscription,
  deleteError,
  deleteLoading,
  hasFromFlag,
  formOptions
}: SubscriptionModalFormProps) {
  const { control, errors } = formOptions;
  return (
    <>
      <ModalBody>
        <AddNewContainer>
          <Container>
            <InputsWrapper noWrap>
              <WideInputGroup>
                <FormLabel>Title</FormLabel>
                <Controller as={<FormInput error={errors.name} height={32} fontSize={16} />} control={control} name={'name'} defaultValue={subscription?.name || ''} rules={{ required: true }} />
                {errors.name && <FormError>{errors.name.message || 'name is required'}</FormError>}
              </WideInputGroup>
              <Customizations
                formOptions={formOptions}
                defaultValue={subscription?.customizations || ''}
                hideRules={{
                  ASSIGN: true,
                  DURATION: true
                }}
              />

              <ProductPrice product={subscription} formOptions={formOptions} hideCharge />

              <ProductOptions product={subscription} formOptions={formOptions} hasFromFlag={hasFromFlag} subscriptionType />

              <WideInputGroup>
                <FormLabel>Category</FormLabel>
                <Controller
                  as={<CategorySelect defaultValue={subscription?.ProductCategories.map(category => ({ label: category.name, value: category.id })) || null} />}
                  control={control}
                  name={'category'}
                  defaultValue={subscription?.ProductCategories.map(category => ({ label: category.name, value: category.id })) || null}
                  rules={{ required: true }}
                />
                {errors.category && <FormError>{errors.category.message || 'category is required'}</FormError>}
              </WideInputGroup>

              <WideInputGroup>
                <FormLabel>Membership Status</FormLabel>
                <Controller
                  as={
                    <Select
                      styles={{ container: (provided, state) => ({ ...provided }), valueContainer: (provided, state) => ({ ...provided, height: 48, overflowY: 'scroll' }) }}
                      options={productStatuses}
                      theme={selectTheme}
                      isClearable
                      defaultValue={{ value: subscription?.status, label: productStatuses.find(status => subscription?.status === status.value)?.label } || null}
                    />
                  }
                  control={control}
                  name={'status'}
                  defaultValue={{ value: subscription?.status, label: productStatuses.find(status => subscription?.status === status.value)?.label } || null}
                  rules={{ required: true }}
                />
                {errors.status && <FormError>{errors.status.message || 'status is required'}</FormError>}
              </WideInputGroup>
            </InputsWrapper>
            <InputsWrapper noWrap>
              <WideInputGroup>
                <FormLabel>Photos ({[...(uploadedPhotos || []), ...(uploadedLinks || [])].length} / 8)</FormLabel>
                <PhotosContainer>
                  {[...(uploadedPhotos || []), ...(uploadedLinks || [])].map((photo, idx) => {
                    return (
                      <FlexColumnCenterBetweenContainer key={idx}>
                        <AddedImage key={idx} src={typeof photo === 'string' ? photo : URL.createObjectURL(photo)} />
                        <RemoveAddedImage src={icons.delete.childImageSharp.gatsbyImageData.images.fallback.src} onClick={() => handleRemovePhoto(photo)} />
                      </FlexColumnCenterBetweenContainer>
                    );
                  })}
                  <FlexColumnCenterBetweenContainer>
                    <AddImageContainer disabled={[...uploadedPhotos, ...uploadedLinks].length >= 8}>
                      <AddImageButton onClick={() => fileInputRef?.current?.click()} src={icons.addPhoto.childImageSharp.gatsbyImageData.images.fallback.src} />
                      <Modal
                        ref={LinkModalRef}
                        title="Image Link"
                        ModalBtn={AddPhotoByLink}
                        modalContent={() => <PhotoLink setPhotos={setUploadedLinks} photos={uploadedLinks} LinkModalRef={LinkModalRef} />}
                      />
                    </AddImageContainer>
                  </FlexColumnCenterBetweenContainer>
                  <input ref={fileInputRef} type={'file'} accept={'image/*'} onChange={handlePhotoSelect} disabled={uploadedPhotos.length >= 8} style={{ display: 'none' }} multiple />
                </PhotosContainer>
              </WideInputGroup>
            </InputsWrapper>

            <InputsWrapper noWrap>
              <WideInputGroup>
                <FormLabel>Description</FormLabel>
                <MarkupEditor ref={markupEditorRef} defaultValue={subscription?.description || ''} />
              </WideInputGroup>
              <WideInputGroup>
                <FormLabel>Sort Index</FormLabel>
                <Controller
                  as={<FormInput error={errors.sort_index} type={'number'} height={32} fontSize={16} />}
                  control={control}
                  name={'sort_index'}
                  defaultValue={subscription?.sort_index || 1}
                  rules={{ required: false }}
                />
                <FormError>{errors.sort_index?.message || ''}</FormError>
              </WideInputGroup>
            </InputsWrapper>

            <InputsWrapper noWrap>
              <WideInputGroup>
                <FormLabel>Short Description</FormLabel>
                <Controller
                  as={<FormInput error={errors.short_description} height={32} fontSize={16} />}
                  control={control}
                  name={'short_description'}
                  defaultValue={subscription?.short_description || ''}
                  rules={{}}
                />
              </WideInputGroup>
            </InputsWrapper>

            <InputsWrapper noWrap>
              <WideInputGroup>
                <FormLabel>Subscription disclaimer</FormLabel>
                <Controller
                  as={<FormInput error={errors?.additional_info?.payment_description} height={32} fontSize={16} />}
                  control={control}
                  name={'additional_info.payment_description'}
                  defaultValue={subscription?.additional_info?.payment_description || ''}
                  rules={{}}
                />
              </WideInputGroup>
            </InputsWrapper>

            <InputsWrapper noWrap>
              <WideInputGroup>
                <FormLabel>Action Button Text</FormLabel>
                <Controller as={<FormInput error={errors.cta_text} height={32} fontSize={16} />} control={control} name={'cta_text'} defaultValue={subscription?.cta_text || ''} rules={{}} />
                {errors.cta_text && <FormError>{errors.cta_text.message || 'Action Button Text is required'}</FormError>}
              </WideInputGroup>
            </InputsWrapper>
          </Container>
        </AddNewContainer>
      </ModalBody>
      <ModalFooter>
        <FormButtonsContainer>
          <FormSubmitButton
            error={addError || photoUploadError || editError}
            loading={addLoading || photoUploadLoading || editLoading}
            onClick={() => (subscriptionId ? handleFormUpdate() : handleFormSubmit())}
          >
            {subscriptionId ? 'Update' : 'Save'}
          </FormSubmitButton>
          {/* {subscriptionId && (
            <FormSubmitButton onClick={handleDeleteSubscription} error={deleteError} loading={deleteLoading} secondary danger>
              Delete Subscription
            </FormSubmitButton>
          )} */}
        </FormButtonsContainer>
      </ModalFooter>
    </>
  );
}

const SubscriptionModal = () => {
  const icons = useIcons();
  const fileInputRef = useRef(null);
  const LinkModalRef = useRef(null);
  const markupEditorRef = useRef(null);
  const formOptions = useForm();
  const { control, handleSubmit } = formOptions;
  const [uploadedPhotos, setUploadedPhotos] = useState([]);
  const [deletePhotos, { setPhotosDeleteQueue }] = useDeletePhotos();
  const [uploadedLinks, setUploadedLinks] = useState([]);
  const [formCategories, setFormCategories] = useState([]);
  const subscriptionId = useReactiveVar(vars.productId); //will be null if adding new subscription will only be filled in case of editing
  const { data: { getBusUserProfile: profile = {} } = {} } = useQuery(GetBusUserProfile);
  const [loadSubscription, { data: { productGet: [subscription] = [] } = {}, loading: subscriptionEditLoading }] = useLazyQuery(GetProduct, { fetchPolicy: 'cache-and-network' }); //used only when editing a subscription
  const [handleAddProduct, { data: { productAdd: addedProduct } = {}, loading: addLoading, error: addError }] = useMutation(AddSubscription, { fetchPolicy: 'no-cache' });
  const [handleEditProduct, { data: { productEdit: editedProduct } = {}, loading: editLoading, error: editError }] = useMutation(EditProduct, { fetchPolicy: 'no-cache' });
  const [handleDelete, { data: { deleteProduct } = {}, loading: deleteLoading, error: deleteError }] = useMutation(DeleteProduct, { fetchPolicy: 'no-cache' });
  const [handleAttachCategoryToProduct] = useMutation(AttachCategoryToProduct, { fetchPolicy: 'no-cache' });
  const [handleDeleteProductCategoryItem] = useMutation(DeleteProductCategoryItem, { fetchPolicy: 'no-cache' });
  const [handlePhotosUpload, { data: photos, loading: photoUploadLoading, error: photoUploadError }, { checkPhotosSize }] = usePhotoUpload(); //custom hook for uploading photos to firestore
  const { data: { flagGet: fromFlagData = [] } = {} } = useQuery(GetFromFlag);
  const [handleAddFlagItem] = useMutation(AddFlagItem, { fetchPolicy: 'no-cache' });
  const [handleDeleteFlagItem] = useMutation(DeleteFlagItem, { fetchPolicy: 'no-cache' });
  const hasFromFlag = subscription?.Flags.filter((flag: any) => flag.id === fromFlagData?.[0]?.id).length > 0;

  const handleFormSubmit = handleSubmit(form => {
    const customizations = getSelectedCustomizations(form.customizations);
    const submittedCategoriesIds = form?.category.map(category => category?.value);
    setFormCategories(submittedCategoriesIds);
    const productOptions = getSelectedProductOptions(form.productOptions);
    const formToSubmit = {
      name: form?.name,
      price: form?.price ? form?.price : form?.original_price,
      original_price: form?.original_price,
      cta_text: form?.cta_text,
      status: form?.status?.value,
      type: 'subscription',
      slots: transformOperatingSlotsState(form),
      slots_start_date: form?.slots_start_date ? new Date(new Date(form?.slots_start_date).getTime() - offsetUTC(form?.slots_start_date)) : null,
      slots_recurrence: Number(form?.slots_recurrence || 1),
      short_description: form?.short_description,
      ...productOptions,
      sort_index: Number(form?.sort_index || 500),
      additional_info: {
        ...(form?.additional_info?.payment_description ? { payment_description: form?.additional_info?.payment_description } : {})
      }
    };
    handleAddProduct({ variables: { ...formToSubmit, description: markupEditorRef?.current?.getHTML(), photos: [...uploadedLinks], customizations }, fetchPolicy: 'no-cache' });
  });

  const handleFormUpdate = handleSubmit(form => {
    const customizations = getSelectedCustomizations(form.customizations);
    const productOptions = getSelectedProductOptions(form.productOptions);
    const addedFromFlag = productOptions.from;
    const formToSubmitWithoutCustomizations = {
      name: form?.name,
      price: form?.price ? form?.price : form?.original_price,
      original_price: form?.original_price,
      cta_text: form?.cta_text,
      status: form?.status?.value,
      category: form?.category,
      type: 'subscription',
      slots: transformOperatingSlotsState(form),
      slots_start_date: form?.slots_start_date ? new Date(new Date(form?.slots_start_date).getTime() - offsetUTC(form?.slots_start_date)) : null,
      slots_recurrence: Number(form?.slots_recurrence || 1),
      short_description: form?.short_description,
      ...productOptions,
      sort_index: Number(form?.sort_index || 500),
      additional_info: {
        ...(form?.additional_info?.payment_description ? { payment_description: form?.additional_info?.payment_description } : {})
      }
    };
    const submittedData = { ...formToSubmitWithoutCustomizations, description: markupEditorRef?.current?.getHTML(), customizations };
    const data = getKeys(submittedData)
      .filter(key => {
        if (key === 'category') {
          const existingCategories = subscription.ProductCategories.map(category => category.id); //in DB
          const submittedCategories = submittedData.category.map(category => category.value); //in DB + Form
          const removedCategories = existingCategories.filter(category => !submittedCategories.includes(category)); //in DB + not in Form

          removedCategories.forEach(category => {
            handleDeleteProductCategoryItem({ variables: { ProductId: subscription.id, ProductCategoryId: category }, fetchPolicy: 'no-cache' });
          });

          submittedData.category = submittedData.category.filter(category => !existingCategories.includes(category.value)).map(category => category?.value);
          submittedData.category.forEach(category => {
            handleAttachCategoryToProduct({ variables: { ProductId: subscription.id, ProductCategoryId: category }, fetchPolicy: 'no-cache' });
          });
          return false;
        }
        return submittedData[key] !== subscription[key];
      })
      .reduce((changes, key) => ({ ...changes, [key]: submittedData[key] }), {});

    const url = `images/providers/${profile.Provider.id}/branches/${profile.Branch.id}/products/${subscription.id}`;
    if (uploadedPhotos.length) {
      const newPhotos = uploadedPhotos.filter(photo => typeof photo !== 'string');
      handlePhotosUpload(url, newPhotos);
    }
    deletePhotos(url);

    if (addedFromFlag && !hasFromFlag) {
      handleAddFlagItem({ variables: { id: subscription.id, flagId: fromFlagData?.[0]?.id }, fetchPolicy: 'no-cache' });
    } else if (!addedFromFlag && hasFromFlag) {
      handleDeleteFlagItem({ variables: { id: subscription.id, flagId: fromFlagData?.[0]?.id }, fetchPolicy: 'no-cache' });
    }

    handleEditProduct({ variables: { id: subscription.id, ...data, photos: [...uploadedLinks] }, fetchPolicy: 'no-cache' });
  });

  const handleDeleteSubscription = () => {
    Alert.alert({
      title: 'Are you sure?',
      acceptButtonText: 'Yes',
      denyButtonText: 'No',
      description: 'Are you sure you want to delete this subscription?',
      onAccept: () => {
        handleDelete({ variables: { id: subscription.id }, fetchPolicy: 'no-cache' });
      }
    });
  };
  const handlePhotoSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPhotos = [...uploadedPhotos, ...(e.target.files || [])];
    if ([...newPhotos, ...uploadedLinks].length > 8) {
      Alert.alert({
        title: 'Maximum number of photos reached',
        acceptButtonText: 'Ok',
        description: 'You can only upload up to 8 photos',
        options: {
          hideDenyButton: true
        }
      });
      return;
    }

    if (checkPhotosSize(e)) {
      setUploadedPhotos([...uploadedPhotos, ...e.target.files]);
    }
  };

  const handleRemovePhoto = async (removedPhoto: any) => {
    setUploadedPhotos(uploadedPhotos.filter(photo => photo !== removedPhoto));
    setUploadedLinks(uploadedLinks.filter(link => link !== removedPhoto));
    if (typeof removedPhoto === 'string') {
      setPhotosDeleteQueue(photosDeleteQueue => [...photosDeleteQueue, removedPhoto]);
    }
  };

  //for uploading photos to firestore after adding the subscription **because we need the id**
  useEffect(() => {
    if (addedProduct) {
      if (uploadedPhotos.length) {
        const url = `images/providers/${profile.Provider.id}/branches/${profile.Branch.id}/products/${addedProduct.id}`;
        handlePhotosUpload(url, uploadedPhotos);
      }
      formCategories.forEach(category => {
        handleAttachCategoryToProduct({ variables: { ProductId: addedProduct.id, ProductCategoryId: category }, fetchPolicy: 'no-cache' });
      });

      const form = control.getValues();
      const addedFromFlag = getSelectedProductOptions(form.productOptions).from;
      if (addedFromFlag) {
        handleAddFlagItem({ variables: { id: addedProduct.id, flagId: fromFlagData?.[0]?.id }, fetchPolicy: 'no-cache' });
      }
    }
  }, [addedProduct]);

  //after the photos are added we update the subscription with the URL of each photo
  useEffect(() => {
    if (photos && (addedProduct || editedProduct)) {
      handleEditProduct({ variables: { id: addedProduct?.id || editedProduct?.id, photos: [...(addedProduct?.photos || editedProduct?.photos), ...photos] }, fetchPolicy: 'no-cache' });
    }
  }, [photos]);

  //after the photos are attached to the subscription we reload the subscriptions and close the modal
  useEffect(() => {
    if ((editedProduct && !photoUploadLoading) || deleteProduct || (addedProduct && !uploadedPhotos.length)) {
      Common.get(`Subscriptions.GetSubscriptions.refetch`)();
      ModalDialog?.closeModal();
    }
  }, [editedProduct, photoUploadLoading, deleteProduct, addedProduct]);

  //in case of editing a subscription we load the subscription data by its id
  useEffect(() => {
    if (subscriptionId) {
      loadSubscription({ variables: { id: subscriptionId } });
    }
  }, [subscriptionId]);

  //for mapping data into its proper fields
  useEffect(() => {
    if (subscription) {
      if (subscription?.photos.length) {
        setUploadedLinks([...subscription?.photos]);
      }
    }
  }, [subscriptionEditLoading]);

  if (subscriptionEditLoading) {
    return (
      <ModalBody>
        <AddNewContainer>
          <FlexCenterCenter height={650}>
            <PageLoader size={50} />
          </FlexCenterCenter>
        </AddNewContainer>
      </ModalBody>
    );
  }
  return (
    <SubscriptionModalForm
      {...{
        subscription,
        uploadedPhotos,
        uploadedLinks,
        icons,
        handleRemovePhoto,
        fileInputRef,
        LinkModalRef,
        setUploadedLinks,
        handlePhotoSelect,
        markupEditorRef,
        addError,
        photoUploadError,
        editError,
        addLoading,
        photoUploadLoading,
        editLoading,
        subscriptionId,
        handleFormUpdate,
        handleFormSubmit,
        handleDeleteSubscription,
        deleteError,
        deleteLoading,
        hasFromFlag,
        formOptions
      }}
    />
  );
};

export default SubscriptionModal;
