import { useLazyQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { Controller, UseFormMethods } from 'react-hook-form';
import AsyncSelect from 'react-select/async';
import { GetBreeds } from '../../../queries';
import debounce from '../../../utils/debounce';
import { Pet } from '../../Pets/types';
import { Branch } from '../../Profile/types';
import { selectTheme } from '../Forms/Forms';

const getOptions = (data: Branch[]) => {
  return data?.map((user: any) => ({ value: user.id, label: user.name })) || [];
};

const BreedsList = ({ formOptions, defaultBreedId, petType, name }: { formOptions: UseFormMethods<any>; petType?: Pet['type']; defaultBreedId?: string; name?: string }) => {
  const { control } = formOptions;

  const [getSearchBreeds, { data: { getBreeds: breeds = [] } = {} }] = useLazyQuery(GetBreeds, {
    fetchPolicy: 'cache-and-network'
  });

  const [getBreedById, { data: { getBreeds: [defaultBreed = {}] = [] } = {} }] = useLazyQuery(GetBreeds, {
    fetchPolicy: 'cache-and-network'
  });

  useEffect(() => {
    if (defaultBreedId) {
      getBreedById({ variables: { id: [defaultBreedId] } });
    }
  }, [defaultBreedId]);

  const options = getOptions(breeds);

  const loadOptions = (inputValue: string, callback: (options: { value: string; label: string }[]) => void) => {
    debounce(async () => {
      const { data: { getBreeds: breeds = [] } = {} } = await getSearchBreeds({ variables: { name: [inputValue], type: petType ? [petType] : null } });
      const options = getOptions(breeds);
      callback(options);
    }, 500);
  };

  return (
    <Controller
      name={name || 'breedId'}
      control={control}
      defaultValue={defaultBreedId || ''}
      rules={{ required: true }}
      render={({ onChange, value }) => {
        const val = value ? options.find(option => option.value === value) : '';
        const defaultVal = defaultBreedId === value && defaultBreed && defaultBreedId ? { value: defaultBreedId, label: defaultBreed?.name } : '';
        return (
          <>
            <AsyncSelect
              styles={{ container: provided => ({ ...provided }), valueContainer: provided => ({ ...provided, height: 48, overflowY: 'scroll' }) }}
              loadOptions={loadOptions}
              theme={selectTheme}
              name={'selectedVetId'}
              onChange={(option: any) => {
                onChange(option?.value);
              }}
              value={defaultVal || val || ''}
              options={options}
              cacheOptions
              noOptionsMessage={({ inputValue }) => (inputValue === '' ? 'Start typing to search' : 'No results found')}
            />
          </>
        );
      }}
    />
  );
};

export default BreedsList;
