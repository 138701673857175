import React, { FC, Fragment } from 'react';
import { ExtensionDivider, ExtensionPetCaseLabel, ExtensionPetCasesRecord } from '../../../../components/Pets/styled';
import { toReadableDate } from '../../../../utils/dates';
import { report } from './WalkingHistory';

const WalkingReport: FC<{ walkingReport: report; onClick: React.MouseEventHandler<HTMLDivElement>; selected: boolean }> = ({ walkingReport, onClick, selected }) => {
  return (
    <Fragment>
      <ExtensionPetCasesRecord selected={selected} onClick={onClick}>
        <ExtensionPetCaseLabel fontSize={16} fontWeight={'700'} margingB={8}>
          {walkingReport?.name}
        </ExtensionPetCaseLabel>
        <ExtensionPetCaseLabel fontSize={14} fontWeight={'600'} margingB={2}>
          {toReadableDate(walkingReport?.updatedAt, { isLocale: true })}
        </ExtensionPetCaseLabel>
        <ExtensionPetCaseLabel fontSize={14} fontWeight={'600'} margingB={8}>
          {walkingReport?.Branch?.name}
        </ExtensionPetCaseLabel>
      </ExtensionPetCasesRecord>
      <ExtensionDivider />
    </Fragment>
  );
};

export default WalkingReport;
