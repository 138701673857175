import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { RRule, RRuleSet, rrulestr } from 'rrule';
import { NewBookingFormType } from '../components/Shared/Modals/NewBookingModal/utils';
import { GetSlots } from '../queries';
import { FULL_DAYS, MONTH_NAMES, SUN_THREE_DAYS } from '../utils/dates';
import { BOOKING_TYPE } from '../views/Bookings/types';
import { Product } from '../views/Store/types';

export type Slot = {
  date: Date;
  time: string;
  dateUntil: Date;
  startDate?: Date;
  days?: typeof SUN_THREE_DAYS;
  repetition?: { type: string; repeat: number };
};

export type FullDate = {
  date: number;
  day: string;
  month: string;
  hours: number;
  minutes: number;
  fullDate: Date;
};

export const getRRuleWeekDays = (rruleStrings: string[]) => {
  const rruleWeekDays = rruleStrings
    .map(rruleString => {
      const rrule = RRule.fromString(rruleString || '');
      return rrule.options.byweekday;
    })
    .flat();

  const uniqueRRuleWeekDays = [...new Set(rruleWeekDays)].sort((a, b) => a - b);

  return uniqueRRuleWeekDays;
};

const findAllDates = (date: Date, rruleset: RRuleSet, nextDueLimit: Date): Date[] => {
  const nextDate = rruleset.after(date, false);
  if (!nextDate || nextDate > nextDueLimit) {
    return [];
  }
  const nextDateFixed = applyTimezoneOffset([nextDate])[0];
  return [nextDateFixed, ...findAllDates(nextDate, rruleset, nextDueLimit)];
};

const getRRuleNextDatesForTheNextNWeeks = (str: string, weeks: number, startDate: Date = new Date()) => {
  const date = new Date(startDate);
  const rruleset = rrulestr(str);
  const afterNWeeks = new Date(startDate);
  afterNWeeks.setUTCDate(afterNWeeks.getUTCDate() + weeks * 7);
  const allowedDates = findAllDates(date, rruleset, afterNWeeks);
  return allowedDates;
};

const getLocaleFullDate = ({
  selectedDate,
  selectedTime,
  options = {
    twentyFourHourTime: false,
    timestamp_until: false
  }
}: {
  selectedDate: Date | string;
  selectedTime?: string;
  options?: {
    twentyFourHourTime?: boolean;
    timestamp_until?: boolean;
  };
}): { timestamp: FullDate } | { timestamp_until: FullDate } => {
  const { twentyFourHourTime, timestamp_until } = options;
  const nonUTCdate = new Date(selectedDate);
  const date = [nonUTCdate][0];
  if (selectedTime) {
    const diff = selectedTime!.split(':');
    if (twentyFourHourTime) {
      setLocaleHours(date, Number(diff[0] === '00' ? '24' : diff[0]));
      date.setUTCMinutes(Number(diff[1]));
    } else {
      setLocaleHours(date, Number(diff[0]) + (diff[0] === '12' ? 0 : diff[1].split(' ')[1] === 'AM' ? 0 : 12));
      date.setUTCMinutes(Number(diff[1].split(' ')[0]));
    }
  }

  if (!selectedTime) {
    // ONLY MULTIDAY SLOTS
    setLocaleHours(date, 12);
    setLocaleMinutes(date, 0);
  }

  date.setUTCSeconds(0);
  date.setMilliseconds(0);

  const outputDate = {
    date: date.getUTCDate(),
    day: FULL_DAYS[date.getUTCDay()],
    month: MONTH_NAMES[date.getMonth()],
    hours: date.getUTCHours(),
    minutes: date.getUTCMinutes(),
    fullDate: date
  };

  if (!timestamp_until) {
    return {
      timestamp: outputDate
    };
  }

  return {
    timestamp_until: outputDate
  };
};

const setLocaleHours = (date: Date, hours: number) => {
  date.setUTCHours(hours);
  date = applyTimezoneOffset([date])[0];
  return date;
};

const setLocaleMinutes = (date: Date, minutes: number) => {
  date.setUTCMinutes(minutes);
  date = applyTimezoneOffset([date])[0];
  return date;
};

function getFullDatesForMultiSlotsProduct(product: Product) {
  const { slots, slots_recurrence, slots_start_date } = product;

  const rruleProductSlots = slots
    ?.map((slot: { time: string }) => {
      return getRRuleNextDatesForTheNextNWeeks(slot.time, slots_recurrence, slots_start_date);
    })
    ?.flat();

  const rruleProductSlotsWithTimezoneOffset = applyTimezoneOffset(rruleProductSlots.map(applyUTCReverseOffset), { addition: true });

  const fullDates = rruleProductSlotsWithTimezoneOffset
    ?.map((date: Date) => {
      const selectedDate = new Date(date);
      const selectedTime = `${selectedDate.getUTCHours()}:${selectedDate.getUTCMinutes()}`;
      const fullDate = getLocaleFullDate({
        selectedDate,
        selectedTime,
        options: {
          twentyFourHourTime: true
        }
      });
      return fullDate;
    })
    ?.sort((a, b) => a.timestamp.fullDate.getTime() - b.timestamp.fullDate.getTime());
  return fullDates;
}

function applyUTCReverseOffset(date: Date) {
  const newDate = new Date(date);
  newDate.setUTCHours(newDate.getUTCHours() + newDate.getTimezoneOffset() / 60);
  return newDate;
}

function getUTCHourOffset(date = new Date()) {
  return new Date(date).getTimezoneOffset() / -60;
}

export function getUKTimeOffset() {
  const diff =
    new Date().getUTCHours() -
    Number(
      new Date().toLocaleString('en-GB', {
        hour: '2-digit',
        hour12: false,
        timeZone: 'Europe/London'
      })
    );
  return diff;
}

function applyTimezoneOffset(
  dates: Date[],
  options: {
    addition?: boolean;
  } = { addition: false }
) {
  const { addition = false } = options;
  const datesWithTimezoneOffset = dates.map((date: Date) => {
    const currentTimezoneOffset = getUTCHourOffset(date);
    const dateWithTimezoneOffset = new Date(date);
    const dateOffset = getUTCHourOffset(date) * (addition ? 1 : -1);
    if (dateOffset !== currentTimezoneOffset) {
      dateWithTimezoneOffset.setUTCHours(dateWithTimezoneOffset.getUTCHours() + (currentTimezoneOffset + dateOffset));
    }
    return dateWithTimezoneOffset;
  });
  return datesWithTimezoneOffset;
}

export default function useMemoizedFullDates({
  selectedOrderGroupSlots,
  selectedProducts
}: {
  selectedOrderGroupSlots: NewBookingFormType['orderGroups'];
  selectedProducts: Product[];
}): ({ timestamp: FullDate } | { timestamp_until: FullDate })[][] {
  const firstSelectedProduct = selectedProducts.find(product => product.id === selectedOrderGroupSlots[0]?.productId);

  const { data: { getSlots: { slots: multiSlots } = {} } = {} } = useQuery(GetSlots, {
    skip: !firstSelectedProduct || firstSelectedProduct?.booking_type !== BOOKING_TYPE.MULTI_SLOT,
    fetchPolicy: 'cache-and-network',
    variables: {
      branchId: firstSelectedProduct?.BranchId,
      productId: firstSelectedProduct?.id
    }
  });
  const fullDates = useMemo(() => {
    return selectedOrderGroupSlots.map(orderGroupSlots => {
      const selectedProduct = selectedProducts.find(product => product.id === orderGroupSlots.productId);
      if (!selectedProduct || selectedProduct?.type !== 'service') {
        return [];
      }

      if (selectedProduct?.booking_type === BOOKING_TYPE.MULTI_SLOT) {
        return [];
      }

      const selectedSlots = orderGroupSlots.slots;

      return selectedSlots
        .map(slot => {
          const date = slot?.date;
          const startDate = slot?.startDate;
          const dateUntil = slot?.dateUntil;
          const time = slot?.time;
          if (selectedProduct?.booking_type === BOOKING_TYPE.SLOT) {
            if (!(date || startDate) || !time) {
              return [];
            }

            if (startDate) {
              // convert the date to the timezone of the server (Europe/London)
              const fullDate = getLocaleFullDate({
                selectedDate: new Date(startDate),
                selectedTime: String(time),
                options: {
                  twentyFourHourTime: true
                }
              });
              const hrs = fullDate.timestamp.fullDate.getUTCHours();
              fullDate.timestamp.fullDate.setUTCHours(hrs);
              fullDate.timestamp.hours = hrs;
              return [fullDate];
            }

            return [
              getLocaleFullDate({
                selectedDate: new Date(date),
                selectedTime: String(time),
                options: {
                  twentyFourHourTime: true
                }
              })
            ];
          }

          if (selectedProduct?.booking_type === BOOKING_TYPE.MULTI_DAY) {
            if (!dateUntil) {
              return [];
            }

            return [
              {
                ...getLocaleFullDate({
                  selectedDate: new Date(date),
                  options: {
                    twentyFourHourTime: true
                  }
                }),

                ...getLocaleFullDate({
                  selectedDate: new Date(dateUntil),
                  options: {
                    timestamp_until: true,
                    twentyFourHourTime: true
                  }
                })
              }
            ];
          }
        })
        .flat();
    });
  }, [selectedProducts, selectedOrderGroupSlots]);

  if (firstSelectedProduct?.booking_type === BOOKING_TYPE.MULTI_SLOT) {
    return [
      (multiSlots?.availableSlots ?? [])?.map(({ timestamp }) => {
        const dt = new Date(timestamp);
        return {
          timestamp: {
            date: dt.getUTCDate(),
            day: FULL_DAYS[dt.getUTCDay()],
            month: MONTH_NAMES[dt.getUTCMonth()],
            hours: dt.getUTCHours(),
            minutes: dt.getUTCMinutes(),
            fullDate: dt
          }
        };
      })
    ];
  }

  return fullDates;
}
