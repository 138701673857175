import { useQuery, useReactiveVar } from '@apollo/client';
import React, { Fragment, useEffect, useRef } from 'react';
import { BusUserProfile } from '../../components/Profile/types';
import RadioSwitch from '../../components/Shared/Forms/RadioSwitch';
import { InfiniteListGroup } from '../../components/Shared/InfiniteList/InfiniteList';
import RefreshButton from '../../components/Shared/RefreshButton';
import { CenteredLoader } from '../../components/Shared/Spinner';
import useCheckSelectedTabAndRefetch, { filterItemsInTab } from '../../hooks/useCheckSelectedTabAndRefetch';
import usePaginatedQuery from '../../hooks/usePaginatedQuery';
import { useRefetchAppointmentsOnFiltersChange } from '../../hooks/useRefetchOnFiltersChange';
import { GetBusUserProfile, GetListBranchAppointments } from '../../queries';
import { vars } from '../../reactive';
import { manageBookingsFilters } from '../../reactive/actions';
import { formatDateDay, getStartOfTodayUTC, getUTCDateFromTimestamp } from '../../utils/dates';
import Common from '../Store/Common';
import { RecordsContainer } from '../styled';
import BookingsList, { BookingsBulkActionsRef } from './BookingsList';
import { GroupHeader } from './styled';
import { BOOKING_RECORD_VIEW_SETTINGS, BOOKING_STATUS_TYPES, BOOKING_TABS_TYPES, BOOKING_TYPE, Booking, BookingOrder } from './types';

type BookingBodyProps = {
  selectedTabState: [BOOKING_TABS_TYPES, React.Dispatch<React.SetStateAction<BOOKING_TABS_TYPES>>];
};

const BookingsBody = ({ selectedTabState }: BookingBodyProps) => {
  const { addHeaderAction, clearHeaderAction } = manageBookingsFilters;
  const today = getStartOfTodayUTC();
  const activeViewSettings = useReactiveVar(vars.activeViewSettings);
  const [selected] = selectedTabState;
  const { data: { getBusUserProfile: currentBusUserProfile } = {} } = useQuery<{ getBusUserProfile: BusUserProfile }>(GetBusUserProfile);
  const confirmedTabSelected = [BOOKING_TABS_TYPES.CONFIRMED, BOOKING_TABS_TYPES.MULTI_DAY].includes(selected);
  const bookingsListRef = useRef<BookingsBulkActionsRef>(null);

  const defaultVariables = {
    filter_by_role: currentBusUserProfile?.role,
    requisite_queries: [],
    alternative_queries: [],
    ...BOOKING_RECORD_VIEW_SETTINGS.map(settings => ({ [settings.id]: false })).reduce((acc, curr) => ({ ...acc, ...curr }), {}),
    ...(activeViewSettings?.record && !!activeViewSettings?.record.length ? [activeViewSettings?.record].flat()?.reduce((acc, record) => ({ ...acc, [record]: true }), {}) : {}),
    BranchId: currentBusUserProfile?.Branch.id
  };

  const tabs = [
    {
      name: BOOKING_TABS_TYPES.NEW,
      status: [BOOKING_STATUS_TYPES.REQUESTED],
      otherVariables: {
        ...defaultVariables,
        timestamp_from: null,
        timestamp_to: null,
        booking_type: [BOOKING_TYPE.SLOT, BOOKING_TYPE.MULTI_SLOT, BOOKING_TYPE.MULTI_DAY]
      },
      checkItemInList: (item: Record<string, any>) => item[0].status === BOOKING_STATUS_TYPES.REQUESTED
    },
    {
      name: BOOKING_TABS_TYPES.CANCELED,
      status: [BOOKING_STATUS_TYPES.CANCELED],
      otherVariables: {
        ...defaultVariables,
        timestamp_from: today.toISOString(),
        timestamp_to: null,
        booking_type: [BOOKING_TYPE.SLOT, BOOKING_TYPE.MULTI_SLOT, BOOKING_TYPE.MULTI_DAY],
        order: 'ASC'
      },
      checkItemInList: () => true
    },
    {
      name: BOOKING_TABS_TYPES.CONFIRMED,
      status: [BOOKING_STATUS_TYPES.CONFIRMED],
      otherVariables: {
        ...defaultVariables,
        timestamp_from: today.toISOString(),
        timestamp_to: null,
        booking_type: [BOOKING_TYPE.SLOT, BOOKING_TYPE.MULTI_SLOT]
      },
      checkItemInList: (item: Record<string, any>) => (item[0]?.timestamp_until ? true : getUTCDateFromTimestamp(item[0]) >= today.getTime() && item[0].status === BOOKING_STATUS_TYPES.CONFIRMED)
    },
    {
      name: BOOKING_TABS_TYPES.MULTI_DAY,
      status: [BOOKING_STATUS_TYPES.CONFIRMED],
      otherVariables: {
        ...defaultVariables,
        timestamp_from: today.toISOString(),
        timestamp_to: null,
        booking_type: [BOOKING_TYPE.MULTI_DAY]
      },
      checkItemInList: (item: Record<string, any>) => (item[0]?.timestamp_until ? true : getUTCDateFromTimestamp(item[0]) >= today.getTime() && item[0].status === BOOKING_STATUS_TYPES.CONFIRMED)
    }
  ];

  const selectedTab = tabs.find(tab => tab.name === selected);

  const paginatedQueryResult = usePaginatedQuery<Booking[]>({
    query: GetListBranchAppointments,
    limit: 50,
    otherVariables: {
      ...defaultVariables,
      ...selectedTab?.otherVariables,
      status: selectedTab?.status,
      limit: 50
    },
    otherParams: {
      skip: !currentBusUserProfile?.Branch.id
    }
  });

  const [[_hasMoreItems, setHasMoreItems], queryResult] = paginatedQueryResult;
  const { refetch, variables: previousVariables } = queryResult;

  const checkSelectedTabAndRefetch = useCheckSelectedTabAndRefetch({
    tabs,
    queryResult,
    setHasMoreItems,
    selected
  });

  const SwitchAction = confirmedTabSelected && (
    <RadioSwitch
      onChange={(on: boolean) => {
        setHasMoreItems(true);
        refetch({
          order_by_busUserAssigned: on
        });
      }}
    />
  );

  const action = (
    <Fragment>
      {SwitchAction}
      <RefreshButton checkSelectedTabAndRefetch={checkSelectedTabAndRefetch} setHasMoreItems={setHasMoreItems} />
    </Fragment>
  );

  const filterBookings = (bookings: BookingOrder[]) => {
    if (!!vars.selectedBookingsFilters().length) {
      return bookings;
    }

    return filterItemsInTab({ tabs, selected, list: bookings });
  };

  const group = (bookingsList: BookingOrder[]) =>
    ({
      by: (booking: Record<string, any>[]) => {
        const appointment = booking[0];
        const multiDayAppointment = appointment.timestamp_until;
        if (multiDayAppointment) {
          if (new Date().getTime() <= new Date(appointment.timestamp).getTime()) {
            return 'Upcoming';
          }
          return 'Active';
        }
        return `${formatDateDay(appointment.timestamp)}`;
      },
      separator: (seperatedBookings: BookingOrder[], groupLabel: string) => {
        const multiDayBookings = !!seperatedBookings[0][0].timestamp_until;
        const appointmentsWithSameDate = bookingsList.filter(booking => seperatedBookings.map(seperatedBooking => seperatedBooking[0].timestamp).includes(booking[0].timestamp)).length;
        const pets = seperatedBookings.map(booking => booking?.map(appointment => appointment?.PetRecord?.id)).flat();
        const uniquePets = [...new Set(pets)];

        if (multiDayBookings) {
          return (
            <GroupHeader>
              <strong>{groupLabel}</strong>
            </GroupHeader>
          );
        }

        return (
          <GroupHeader>
            <span style={{ fontWeight: 800 }}>{groupLabel},</span>
            <span style={{ fontWeight: 600 }}>
              {`${appointmentsWithSameDate} ${appointmentsWithSameDate > 1 ? 'Bookings' : 'Booking'}`}, {uniquePets.length} Pets
            </span>
          </GroupHeader>
        );
      }
    } as InfiniteListGroup);

  const loadingFilters = useRefetchAppointmentsOnFiltersChange({
    refetch,
    defaultVariables: { ...selectedTab?.otherVariables, status: selectedTab?.status, limit: 50 } || {},
    setHasMoreItems,
    previousVariables
  });

  useEffect(() => {
    Common.set(`Bookings.GetBranchAppointments.refetch`, (...args: any[]) => {
      return new Promise(async res => {
        await refetch?.(...args).then(() => setHasMoreItems(true));
        res(0);
      });
    });
  }, []);

  useEffect(() => {
    addHeaderAction({ action, id: 'refresh' });
    bookingsListRef?.current?.clearBulkActions?.();
    bookingsListRef?.current?.hideBulkActions?.();
    return () => {
      clearHeaderAction({ id: 'refresh' });
    };
  }, [selected]);

  return (
    <RecordsContainer style={{ position: 'relative' }}>
      {loadingFilters && <CenteredLoader />}

      {!loadingFilters && (
        <>
          <BookingsList
            paginatedQueryResult={paginatedQueryResult}
            filterBookings={filterBookings}
            reduceAppointments={confirmedTabSelected}
            group={confirmedTabSelected ? group : undefined}
            showPetTags={confirmedTabSelected}
            ref={bookingsListRef}
            activeViewSettings={activeViewSettings}
          />
          {/* {mobile && confirmedTabSelected && (
            <RadioSwitchContainer>
              {SwitchAction}
              <span>Sort by staff</span>
            </RadioSwitchContainer>
          )} */}
        </>
      )}
    </RecordsContainer>
  );
};

export default BookingsBody;
