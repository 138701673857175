import React, { FC, useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { OPTION_DROPDOWN_MENU_BUTTON_TYPES, OPTION_DROPDOWN_TYPES } from '../Menus/OptionDropdown/types';
import useIcons from '../../../hooks/useIcons';
import { CloseButton, FiltersContainer, HorizontalSeparator, RequisiteLabel, SelectedFilters, SelectedFiltersContainer, SelectedFiltersWrapper } from './styled';
import { FILTERS_ID, FILTER_TYPES, HIDDEN_FILTERS, MORE_OPTIONS_TYPES, SelectedFilter as SelectedFilterType } from './config';
import { areObjectsEqual } from '../../../utils/helpers';
import SelectedFilter from './SelectedFilter';
import useFilters from './useFilters';
import OptionDropdown from '../Menus/OptionDropdown/OptionDropdown';

export type FILTER_TYPES = keyof typeof FILTER_TYPES;
export type MORE_OPTIONS_TYPES = keyof typeof MORE_OPTIONS_TYPES;
export type HIDDEN_FILTERS = keyof typeof HIDDEN_FILTERS;

export type SelectedMoreOptions = {
  filterType: FILTER_TYPES;
  optionsValues: { optionValue: MORE_OPTIONS_TYPES | string; filterType: FILTER_TYPES; values: string | { name: string; value?: string }[] }[];
}[];

export type FilterItem = {
  name: string;
  value: FILTER_TYPES;
  moreOptions: {
    type: MORE_OPTIONS_TYPES;
    filterType: FILTER_TYPES;
    id: string;
    title: string;
    optionType: OPTION_DROPDOWN_TYPES;
    items:
      | {
          value: string | boolean | number;
          name: string;
        }[]
      | [];
  }[];
};

type FiltersProps = {
  filtersControl: ReturnType<typeof useFilters>;
  filterItems: FilterItem[];
};

const Filters: FC<FiltersProps> = ({ filtersControl, filterItems }) => {
  const { setSelectedFilters, selectedFiltersForm, REQUISITE_ID, addHeaderAction, clearHeaderAction } = filtersControl;
  const { formState } = selectedFiltersForm;

  const selectedMoreOptionsState = useState<SelectedMoreOptions>(
    filterItems.map(({ value }) => ({ filterType: value, optionsValues: [{ optionValue: REQUISITE_ID, filterType: value, values: 'true' }] }))
  );
  const [selectedMoreOptions, setSelectedMoreOptions] = selectedMoreOptionsState;

  const filters = {
    title: 'Filters',
    id: FILTERS_ID,
    optionType: OPTION_DROPDOWN_TYPES.CHECKBOX,
    items: filterItems
      .filter(({ value }) => !HIDDEN_FILTERS[value])
      .map(item => ({
        ...item,
        requisite: selectedMoreOptions.find(({ filterType }) => filterType === item.value)?.optionsValues.find(({ optionValue }) => optionValue === REQUISITE_ID)?.values || 'true'
      }))
  };

  const icons = useIcons();
  const closeIcon = icons?.closeModal?.childImageSharp.gatsbyImageData.images.fallback.src;

  const { control: controlSelectedFilters, reset: resetSelectedFilters } = selectedFiltersForm;

  const selectedFiltersTypes: FILTER_TYPES[] = useWatch({
    control: controlSelectedFilters,
    name: FILTERS_ID,
    defaultValue: []
  });

  const selectedFilters = filters.items.filter(item => selectedFiltersTypes.includes(item.value));

  const selectedFiltersWithMoreOptions = selectedFilters
    .map(filter => ({
      ...filter,
      moreOptions: filter.moreOptions?.map(moreOption => ({
        ...moreOption,
        values: selectedMoreOptions.find(({ filterType }) => filterType === filter.value)?.optionsValues.find(({ optionValue }) => optionValue === moreOption.type)?.values!
      }))
    }))
    .map(filter => ({
      ...filter,
      moreOptions: filter.moreOptions?.filter(moreOption => moreOption.values)
    }))
    .filter(({ moreOptions }) => moreOptions?.length);

  useEffect(() => {
    if (selectedFilters?.length) {
      setSelectedMoreOptions(selectedMoreOptions.filter(({ filterType }) => selectedFiltersTypes.includes(filterType)));
    }
  }, [selectedFiltersTypes]);

  useEffect(() => {
    if (formState.isSubmitting || !selectedFiltersWithMoreOptions?.length) {
      setSelectedFilters(selectedFiltersWithMoreOptions as SelectedFilterType[]);
    }
  }, [selectedFiltersWithMoreOptions]);

  const onReset = () => {
    setSelectedFilters([]);
    setSelectedMoreOptions(filterItems.map(({ value }) => ({ filterType: value, optionsValues: [{ optionValue: REQUISITE_ID, filterType: value, values: 'true' }] })));
    resetSelectedFilters({ [FILTERS_ID]: [] });
  };

  const requisiteFilters = selectedFilters.filter(selectedFilter => selectedFilter.requisite === 'true');

  const alternativeFilters = selectedFilters.filter(selectedFilter => selectedFilter.requisite === 'false');
  const headerAction = <OptionDropdown selectedItemsForm={selectedFiltersForm} options={[filters]} menuButtonType={OPTION_DROPDOWN_MENU_BUTTON_TYPES.FILTER} noApplyButton />;

  useEffect(() => {
    addHeaderAction({ id: FILTERS_ID, action: headerAction });

    return () => {
      clearHeaderAction({ id: FILTERS_ID });
    };
  }, [headerAction]);
  return (
    <>
      {!!selectedFilters?.length && (
        <SelectedFiltersContainer>
          <SelectedFiltersWrapper>
            {!!requisiteFilters?.length && (
              <SelectedFilters>
                <RequisiteLabel>Requisite</RequisiteLabel>
                <FiltersContainer>
                  {requisiteFilters.map(filter => (
                    <SelectedFilter
                      key={filter.value}
                      filter={filter}
                      REQUISITE_ID={REQUISITE_ID}
                      selectedFiltersForm={selectedFiltersForm}
                      selectedFiltersWithMoreOptions={selectedFiltersWithMoreOptions}
                      selectedMoreOptionsState={selectedMoreOptionsState}
                      setSelectedFilters={setSelectedFilters}
                    />
                  ))}
                </FiltersContainer>
              </SelectedFilters>
            )}
            {!!requisiteFilters?.length && !!alternativeFilters?.length && <HorizontalSeparator dashed />}
            {!!alternativeFilters?.length && (
              <SelectedFilters>
                <RequisiteLabel>Alternative</RequisiteLabel>
                <FiltersContainer>
                  {alternativeFilters.map(filter => (
                    <SelectedFilter
                      key={filter.value}
                      filter={filter}
                      REQUISITE_ID={REQUISITE_ID}
                      selectedFiltersForm={selectedFiltersForm}
                      selectedFiltersWithMoreOptions={selectedFiltersWithMoreOptions}
                      selectedMoreOptionsState={selectedMoreOptionsState}
                      setSelectedFilters={setSelectedFilters}
                    />
                  ))}
                </FiltersContainer>
              </SelectedFilters>
            )}
            <CloseButton bgImg={closeIcon} onClick={onReset} />
          </SelectedFiltersWrapper>
        </SelectedFiltersContainer>
      )}
    </>
  );
};

export default React.memo(Filters, areObjectsEqual);
