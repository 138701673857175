import styled, { css } from 'styled-components';
import Colors from '../../Colors';
import { SMALL_DESCRIPTION_POSITIONS } from './ProfileActionBar';
import { PROFILE_COVER_SIZES } from './ProfileCover';

export const CoverContainer = styled.div<{ bgImage: string; size: PROFILE_COVER_SIZES }>`
  display: flex;
  flex-direction: column-reverse;
  background-image: url(${props => props.bgImage});
  background-size: cover;
  width: 370px;
  position: relative;

  --largeSize: 370px;

  ${({ size }) => {
    if (size === PROFILE_COVER_SIZES.SMALL) {
      return css`
        height: 100px;
        min-height: 100px;
      `;
    }

    if (size === PROFILE_COVER_SIZES.LARGE) {
      return css`
        height: var(--largeSize);
        min-height: var(--largeSize);
      `;
    }
  }}
  transition: all 0.5s ease-in-out;
  background-position: center;

  @media (max-width: 1200px) {
    width: 330px;
    --largeSize: 280px;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const ConfettiContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const ReportButtonContainer = styled.div<{ zIndex?: number }>`
  z-index: ${props => props.zIndex || 1};
  position: absolute;
  right: 15px;
  top: 26px;
`;

export const CoverLabelContainer = styled.div<{ size: PROFILE_COVER_SIZES }>`
  padding: 23px 24px;
  color: white;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.7) 100%);
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ size }) =>
    size === PROFILE_COVER_SIZES.SMALL &&
    css`
      justify-content: center;
    `}
`;

export const CoverNameContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CoverLabel = styled.span<{ size: PROFILE_COVER_SIZES }>`
  display: block;
  font-weight: 800;
  font-size: 28px;
  margin-bottom: 6px;

  ${({ size }) =>
    size === PROFILE_COVER_SIZES.SMALL &&
    css`
      font-size: 15px;
      justify-self: center;
    `}
`;
export const CoverSubLabel = styled.span`
  display: block;
  font-weight: 500;
  font-size: 16px;
`;

export const ProfileInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (max-width: 768px) {
    width: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
  }
`;

export const ProfileInfoContainer = styled.div`
  @media (max-width: 768px) {
    padding: 8px 8px 0 8px;
  }
`;
export const ProfileSection = styled.div`
  align-items: center;
  margin-bottom: 23px;
`;
export const ProfileLabel = styled.p`
  color: ${Colors.black};
  font-weight: bold;
  font-size: 16px;

  @media (max-width: 1200px) and (min-width: 768px) {
    font-size: 14px;
  }
`;

export const ProfileLink = styled.a`
  color: ${Colors.primary};
  font-weight: bold;
  font-size: 16px;
  text-decoration: none;
`;

export const ProfileSubLabel = styled.span`
  display: block;
  color: ${Colors.warmGrey};
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 4px;

  @media (max-width: 1200px) and (min-width: 768px) {
    font-size: 12px;
  }
`;

export const ProfileSmallDescription = styled.div<{ positon: SMALL_DESCRIPTION_POSITIONS }>`
  display: block;
  visibility: hidden;
  position: absolute;
  ${props =>
    props.positon === SMALL_DESCRIPTION_POSITIONS.UP &&
    css`
      bottom: 50px;
    `}

  ${props =>
    props.positon === SMALL_DESCRIPTION_POSITIONS.DOWN &&
    css`
      top: 50px;
    `}

  color: ${Colors.warmGrey};
  font-weight: 500;
  font-size: 14px;
  z-index: 100;
  width: 250px;
  height: 150px;
  background-color: ${Colors.white};
  border-radius: 16px;
  border: 2px solid rgb(231, 231, 231);
  &:hover {
    display: block;
  }
`;

export const ProfileButton = styled.button<{ showSmallDescription: boolean; reverse?: boolean }>`
  position: relative;
  display: flex;
  padding: 0;
  align-items: center;
  background-color: transparent;
  color: ${Colors.white};
  border: none;
  transition: 0.1s ease-in all;

  cursor: pointer;
  ${props =>
    props.showSmallDescription &&
    css`
      &:hover {
        transform: scale(1.05);
        ${ProfileSmallDescription} {
          visibility: visible;
        }
      }
    `}

  ${props =>
    props.reverse &&
    css`
      flex-direction: row-reverse;
      gap: 10px;
    `}
`;
export const ProfileContainer = styled.div<{ autoWidth?: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;

  @media (max-width: 768px) {
    overflow: hidden;
    width: 100%;
    max-width: 100%;
    ${props =>
      props.autoWidth &&
      css`
        width: auto;
        max-width: auto;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      `}
  }
`;

export const ProfilePhotoContainer = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100px;
  align-items: center;
`;

export const ReportActionButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export const PetDrawerEdit = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 10px;
  z-index: 100;
  cursor: pointer;
  top: 10px;
  color: ${Colors.primary};
  font-weight: bold;
`;
