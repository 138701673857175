import React, { useEffect } from 'react';
import { SUBSCRIPTION_STATUS_TYPES, Subscription } from '../../views/Subscriptions/types';
import { ButtonsContainer } from '../BookingsDrawer/styled';
import OptionDropdown, { OptionDropDownItem } from '../Shared/Menus/OptionDropdown/OptionDropdown';
import { OPTION_DROPDOWN_MENU_BUTTON_TYPES, OPTION_DROPDOWN_MENU_OVERLAY_POSITIONS, OPTION_DROPDOWN_TYPES } from '../Shared/Menus/OptionDropdown/types';
import { CenteredLoader } from '../Shared/Spinner';
import ModalDialog from '../Modal/ModalDialog';
import EditSubscriptionModal from './EditSubscriptionModal';
import { GetBranchOrderSubscriptions, UpdateOrderSubscriptionStatus } from '../../queries';
import { useMutation } from '@apollo/client';
import client from '../../utils/client';
import Common from '../../views/Store/Common';
import Alert from '../Alert/Alert';
import { OrderVoucher } from '../../views/Store/Discounts/types';

const SubscriptionDrawerButtons = ({ subscription, orderVouchers, loading, onUpdate }: { subscription: Subscription; orderVouchers?: OrderVoucher[]; loading: boolean; onUpdate: () => void }) => {
  const [updateOrderSubscriptionStatus, { loading: loadingCancelSubscription, data: { updateOrderSubscriptionStatus: updatedSubscription = {} } = {} }] = useMutation(UpdateOrderSubscriptionStatus);

  const onCompleted = () => {
    const oldSubscriptions = client.readQuery<{ getBranchOrderSubscriptions: Subscription[] }>({
      query: GetBranchOrderSubscriptions,
      variables: { status: [SUBSCRIPTION_STATUS_TYPES.CONFIRMED], requisite_queries: [], alternative_queries: [] }
    });

    client.writeQuery({
      query: GetBranchOrderSubscriptions,
      data: {
        getBranchOrderSubscriptions: oldSubscriptions?.getBranchOrderSubscriptions?.map(subscription => {
          if (subscription.id === updatedSubscription.id) {
            return updatedSubscription;
          }
          return subscription;
        })
      }
    });
    const refetchSubscriptions = Common.get<() => Promise<void>>(`Subscriptions.GetOrderSubscriptions.refetch`);
    refetchSubscriptions?.();

    onUpdate();
  };

  const optionsItems = [
    subscription?.status === SUBSCRIPTION_STATUS_TYPES.CONFIRMED && {
      value: 'editSubscription',
      name: 'Edit Membership',
      onClick: () =>
        ModalDialog.openModal({
          content: () => <EditSubscriptionModal subscription={subscription} orderVouchers={orderVouchers} onUpdate={onCompleted} />,
          title: 'Edit Membership'
        })
    },
    subscription?.status === SUBSCRIPTION_STATUS_TYPES.CONFIRMED && {
      value: 'cancelSubscription',
      name: 'Cancel',
      danger: true,
      onClick: () => {
        Alert.alert({
          title: 'Cancel Membership',
          description: 'Are you sure you want to cancel this membership?',
          onAccept: () => {
            updateOrderSubscriptionStatus({ variables: { status: SUBSCRIPTION_STATUS_TYPES.CANCELED, id: subscription.id } });
          },
          acceptButtonText: 'Yes',
          denyButtonText: 'No'
        });
      }
    }
  ].filter(Boolean) as OptionDropDownItem[];

  useEffect(() => {
    if (updatedSubscription?.id) {
      onCompleted();
    }
  }, [updatedSubscription]);

  return (
    <ButtonsContainer>
      {loading && <CenteredLoader size={20} />}

      {!loading && (
        <OptionDropdown
          buttonLoading={loading || loadingCancelSubscription}
          menuButtonType={OPTION_DROPDOWN_MENU_BUTTON_TYPES.MENU}
          menuOverlayPosition={OPTION_DROPDOWN_MENU_OVERLAY_POSITIONS.DOWN}
          noApplyButton
          options={[
            {
              optionType: OPTION_DROPDOWN_TYPES.BUTTONS,
              id: 'subscriptionOptions',
              items: optionsItems
            }
          ]}
        />
      )}
    </ButtonsContainer>
  );
};

export default SubscriptionDrawerButtons;
