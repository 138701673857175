import styled from 'styled-components';
import Colors from '../../Colors';

export const LoadingPetData = styled.div`
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  background-color: rgba(216, 216, 216, 0.8);
  z-index: 100;
`;

export const PetContainer = styled.div<{ flexOne?: boolean }>`
  display: flex;
  height: 100%;
  max-height: 100%;
  flex: 1;

  ::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 768px) {
    flex: ${props => (props.flexOne ? 1 : 'unset')};
    height: unset;
    max-height: unset;
    flex-direction: column;
    overflow: scroll;
    display: flex;
    flex-direction: column;
  }
`;

export const PetDrawerTagsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  user-select: none;
  flex-wrap: wrap;
`;

export const PetDrawerTag = styled.div<{ color: string }>`
  display: flex;
  border-radius: 5px;
  border: 1px solid ${props => props.color};
  padding: 4px 8px;
  span {
    color: ${props => props.color};
    font-size: 12px;
    font-weight: bold;
  }
`;
