import React, { forwardRef, useImperativeHandle } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { ModalBody, ModalFooter } from '../../../components/Modal/styled';
import { FormButtonsContainer, FormLabel, FormSubmitButton, WideInputGroup, selectTheme } from '../../../components/Shared/Forms/Forms';
import { Container } from '../../../components/Shared/Shared';
import { ActivityIndicator } from '../../../components/Shared/Spinner';
import useGetBranchTagsByType from '../../../hooks/useGetBranchTagsByType';
import { LoaderContainer } from '../styled';
import { BRANCH_TAGS_TYPES, BranchTag } from './types';

type BranchTagItemModalProps = {
  existingTagsItems: BranchTag[];
  formOptions?: ReturnType<typeof useForm>;
  onSubmit: (data: { selectedTags: { value: string; label: string }[] }) => void;
  loading: boolean;
  type: BRANCH_TAGS_TYPES;
  handleAddBranchTag?: (tags: string) => void;
  isCreatable?: boolean;
};

const BranchTagItemModal = forwardRef<{ tags: BranchTag[] }, BranchTagItemModalProps>(({ formOptions, onSubmit, loading, existingTagsItems, handleAddBranchTag, type, isCreatable = true }, ref) => {
  const { branchTags: allTags, loading: loadingAllTags } = useGetBranchTagsByType({ type, variables: { offset: 0, limit: 1000 } });

  useImperativeHandle(ref, () => ({
    tags: allTags
  }));

  const { control, handleSubmit } = formOptions || useForm();
  const allTagsOptions = allTags?.map(tag => ({ value: tag.id, label: tag.name }));
  const existingTags = allTagsOptions?.filter(tag => existingTagsItems?.findIndex(item => item.id === tag.value) !== -1);

  const SelectComponent = isCreatable ? CreatableSelect : Select;

  const submitHandler = handleSubmit(onSubmit);

  return (
    <>
      {loadingAllTags && (
        <LoaderContainer>
          <ActivityIndicator size={30} />
        </LoaderContainer>
      )}
      {!loadingAllTags && (
        <>
          <ModalBody minHeight={300}>
            <Container width={400}>
              <WideInputGroup>
                <FormLabel>Active Tags</FormLabel>
                <Controller
                  name="selectedTags"
                  control={control}
                  defaultValue={existingTags}
                  as={
                    <SelectComponent
                      defaultValue={existingTags}
                      styles={{ container: provided => ({ ...provided }), valueContainer: provided => ({ ...provided, height: 80, overflowY: 'scroll' }) }}
                      options={allTagsOptions}
                      isMulti
                      theme={selectTheme}
                      name={'selectedTags'}
                      onCreateOption={handleAddBranchTag}
                    />
                  }
                />
              </WideInputGroup>
            </Container>
          </ModalBody>
          <ModalFooter>
            <FormButtonsContainer>
              <FormSubmitButton loading={loading} onClick={submitHandler}>
                Save
              </FormSubmitButton>
            </FormButtonsContainer>
          </ModalFooter>
        </>
      )}
    </>
  );
});

export default BranchTagItemModal;
