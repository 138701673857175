import React, { useCallback, useRef, useState, useEffect, FC } from 'react';
import { useLazyQuery, useQuery, useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import RRule from 'rrule';
import { ModalBody, ModalFooter } from '../../../../components/Modal/styled';
import { FormButtonsContainer, FormSubmitButton } from '../../../../components/Shared/Forms/Forms';
import { ActionBtn } from '../../../Store/styled';
import { AddSupplement, AddRemedy, EditSupplement, GetPetRecordReports, DeleteSupplement, GetProductById, GetPetRecordRecords, GetBranchPetProfile } from '../../../../queries';
import { offsetUTC } from '../../../../utils/dates';
import CurrentTreatments from './CurrentTreatments';
import NewTreatmentForm from './NewTreatmentForm';
import TapGroup from '../../../../components/Shared/TabGroup';
import useIcons from '../../../../hooks/useIcons';
import ModalDialog from '../../../../components/Modal/ModalDialog';
import { CenteredLoader } from '../../../../components/Shared/Spinner';
import { Product } from '../../../Store/types';
import { AddNewMedicationProps, CurrentTreatmentsRef, MedicationFormProps, MedicationModalFormValues, MedicationModalProps, Tabs, TabsTypes, everyFrequencies, frequencies } from './types';
import { getSuppRecordDefaultValues, getFrequenciesEndDateOptions } from './utils';
import client from '../../../../utils/client';

export const AddNewMedication: FC<AddNewMedicationProps> = ({ medCond, pet, autoOpenModal = false }) => {
  const icons = useIcons();
  const addIcon = icons.add.childImageSharp.gatsbyImageData.images.fallback.src;

  const showModal = useCallback(() => ModalDialog.openModal({ content: () => <MedicationModal medCond={medCond} pet={pet} />, title: 'Add Medication', autoOpenModal }), [medCond, pet]);

  useEffect(() => {
    if (autoOpenModal) {
      showModal();
    }
  }, [autoOpenModal, showModal]);

  return <ActionBtn bgImage={addIcon} onClick={showModal} />;
};

const MedicationForm: FC<MedicationFormProps> = ({ medicalCaseName, onSubmit, loading, error, pet, medicalCase, suppRecord, defaultFormValues, handleDlete, loadingDelete, errorDelete }) => {
  const formOptions = useForm<MedicationModalFormValues>({
    defaultValues: defaultFormValues
  });

  const { handleSubmit, watch } = formOptions;
  const [activeTab, setActiveTab] = useState<TabsTypes>(suppRecord ? TabsTypes.New : TabsTypes.Current);
  const currentTreatmentsRef = useRef<CurrentTreatmentsRef>(null);
  const watchedValues = watch(['product', 'frequency', 'quantity', 'count', 'startDate'], defaultFormValues);

  const { data: { productGet: products = [] } = {}, loading: loadingProducts } = useQuery<{
    productGet: Product[];
  }>(GetProductById, {
    fetchPolicy: 'cache-and-network',
    ...(watchedValues?.product && {
      variables: {
        id: watchedValues?.product
      }
    }),
    skip: !watchedValues?.product
  });

  const currentProduct = products.find(({ id }) => id === watchedValues?.product);

  const currentFrequency = Number(watchedValues?.frequency);

  const isRecurring = ![-2, -1].includes(currentFrequency);

  const handleSave = handleSubmit(form => onSubmit({ ...form, size: String(currentProduct?.weight) }));

  const handleSaveAdherence = () => {
    currentTreatmentsRef?.current?.submitAdherence();
  };

  const currentFrequencyName = currentFrequency === 3 ? 'day' : currentFrequency === 2 ? 'week' : currentFrequency === 1 ? 'month' : 'year';

  const getCountText = () => {
    const count = Number(watchedValues?.count);
    if (count === 1) {
      return 'once';
    }

    if (count === 2) {
      return 'twice';
    }

    return `${count} times`;
  };

  const intervalItems = [...Array(everyFrequencies[everyFrequencies.length - currentFrequency - 1])].map((_, item) => {
    const countText = getCountText();
    const unitText = currentProduct?.unit ? currentProduct?.unit?.toLowerCase() : 'dose';
    const quantityText = `${watchedValues.quantity} ${Number(watchedValues.quantity) === 1 ? `(${unitText}s)` : unitText}`;
    const everyText = `${item === 0 ? currentFrequencyName : `${item + 1} ${currentFrequencyName}s`}`;
    return { id: item + 1, name: `${quantityText} ${countText} every ${everyText}` };
  });

  return (
    <>
      <ModalBody>
        {!suppRecord && <TapGroup tabs={Tabs} active={activeTab} setActive={setActiveTab} />}
        {activeTab === TabsTypes.New && (
          <NewTreatmentForm
            medicalCaseName={medicalCaseName}
            products={products}
            isRecurring={isRecurring}
            intervalItems={intervalItems}
            currentProduct={currentProduct}
            suppRecord={suppRecord}
            defaultFormValues={defaultFormValues}
            loadingProducts={loadingProducts}
            formOptions={formOptions}
          />
        )}
        {activeTab === TabsTypes.Current && <CurrentTreatments ref={currentTreatmentsRef} pet={pet!} medCond={medicalCase} setActiveTab={setActiveTab} />}
      </ModalBody>
      <ModalFooter>
        <FormButtonsContainer>
          {activeTab === 'New' && (
            <>
              <FormSubmitButton error={error} loading={loading} onClick={handleSave}>
                {`${suppRecord ? 'Edit' : 'Add'} Medication`}
              </FormSubmitButton>
            </>
          )}
          {activeTab === 'Current' && (
            <FormSubmitButton error={error} loading={loading} onClick={handleSaveAdherence}>
              {'Done'}
            </FormSubmitButton>
          )}
          {suppRecord && (
            <FormSubmitButton error={errorDelete} loading={loadingDelete} onClick={handleDlete} secondary danger>
              {`Delete Medication`}
            </FormSubmitButton>
          )}
        </FormButtonsContainer>
      </ModalFooter>
    </>
  );
};

const MedicationModal: FC<MedicationModalProps> = ({ medCond, pet, suppRecord }) => {
  const [onSubmit, { loading, error, data }] = useMutation(suppRecord ? EditSupplement : AddSupplement);
  const [addRemedy] = useMutation(AddRemedy);
  const [deleteSupplement, { loading: loadingDelete, data: dataDelete, error: errorDelete }] = useMutation(DeleteSupplement);
  const [getMedCond, { data: { getPetRecordReports: { MedConds: [lazyMedCond = {}] = [] } = {} } = {}, loading: loadingMedCond }] = useLazyQuery(GetPetRecordReports, {
    fetchPolicy: 'cache-and-network',
    variables: { id: [medCond?.id || suppRecord?.MedConds?.[0]?.id] }
  });

  useEffect(() => {
    if (!medCond) {
      getMedCond();
    }
  }, [medCond, getMedCond]);

  const medicalCase = medCond || lazyMedCond;

  const onFormSubmit = (form: MedicationModalFormValues) => {
    const selectedFrequency = frequencies[everyFrequencies.length - Number(form.frequency) - 1];
    const startDate = form.startDate ? new Date(new Date(form.startDate).getTime() - offsetUTC(new Date(form.startDate))) : null;
    const endDateOptions = getFrequenciesEndDateOptions(startDate);
    const frequencyEndDate = endDateOptions?.find(({ frequencyId }) => frequencyId === selectedFrequency?.id)?.options?.find(({ id }) => id === Number(form.endDateFrequency))?.date;
    const endDate = frequencyEndDate ?? null;

    const suppRecordVariables = {
      ...form,
      quantity: String(form.quantity),
      type: 'treatment',
      start_date: startDate,
      MedCondId: medicalCase.id,
      PetRecordId: medicalCase?.PetRecordId,
      adherences_allowed: ![-2].includes(Number(selectedFrequency.id)),
      time: ![-1, -2].includes(Number(selectedFrequency.id))
        ? new RRule({
            freq: selectedFrequency.id,
            interval: Number(form.reccurence) || 1
          }).toString()
        : '',
      count: Number(form.count),
      end_date: endDate || null
    };

    const payload = {
      variables: suppRecord ? { id: suppRecord?.id, ...suppRecordVariables } : suppRecordVariables
    };

    onSubmit(payload);
  };

  const handleDelete = () => {
    deleteSupplement({ variables: { id: suppRecord?.id } });
  };

  const defaultFormValues: MedicationModalFormValues = getSuppRecordDefaultValues(suppRecord);

  const refetchAndClose = () => {
    if (medicalCase?.id) {
      (async () => {
        await client.query({
          query: GetPetRecordRecords,
          variables: {
            MedCondId: [medicalCase?.id]
          },
          fetchPolicy: 'network-only'
        });
      })();
    }
    ModalDialog.closeModal();
  };

  useEffect(() => {
    if (data?.suppRecordAdd) {
      addRemedy({
        variables: {
          SuppRecordId: data?.suppRecordAdd?.id,
          MedCondId: medicalCase.id
        }
      }).then(() => {
        refetchAndClose();
      });
    }
  }, [data]);

  useEffect(() => {
    if (data?.suppRecordEdit) {
      refetchAndClose();
    }
  }, [data]);

  useEffect(() => {
    if (dataDelete?.deleteSuppRecord) {
      refetchAndClose();
    }
  }, [dataDelete]);

  if (loadingMedCond) {
    return <CenteredLoader />;
  }

  return (
    <>
      {medicalCase?.id && !loadingMedCond && (
        <MedicationForm
          medicalCase={medicalCase}
          pet={pet}
          medicalCaseName={medicalCase?.name}
          onSubmit={onFormSubmit}
          loading={loading}
          error={!!error}
          suppRecord={suppRecord}
          defaultFormValues={defaultFormValues}
          loadingDelete={loadingDelete}
          errorDelete={!!errorDelete}
          handleDlete={handleDelete}
        />
      )}
    </>
  );
};

export default MedicationModal;
