import styled from 'styled-components';
import Colors from '../../../Colors';

export const SliderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 100;
  position: fixed;
  z-index: 100;
  bottom: 30px;
  right: 30px;
  background-color: ${Colors.white};
  padding: 10px;
  gap: 10px;
  border-radius: 20px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.28);
  box-sizing: border-box;
  input {
    -webkit-appearance: none;
    width: 100%;
    height: 16px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
    box-sizing: border-box;
    position: absolute;
    width: calc(100% - 8px);
    cursor: pointer;
    height: 100%;
    left: 0;
    opacity: 0;
    z-index: 1;
    ::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 25px;
      height: 25px;
      background: ${Colors.primary};
      cursor: pointer;
    }
  }

  @media (max-width: 768px) {
    bottom: 100px;
  }
`;

export const SliderButton = styled.button<{ selected?: boolean }>`
  background-color: ${Colors.primary};
  color: #fff;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: ${props => (props.selected ? Colors.white : Colors.primary)};
  overflow: hidden;
  min-width: 0;
  width: ${props => (props.selected ? '20px' : '8px')};
  height: ${props => (props.selected ? '20px' : '8px')};
  border: ${props => (props.selected ? 'none' : '1px solid #fff')};
  transition: all 0.3s ease-in-out;
  font-size: ${props => (props.selected ? '12px' : '8px')};
  z-index: ${props => (props.selected ? 0 : 2)};
  &:hover {
    height: ${props => (props.selected ? '16px' : '14px')};
    width: ${props => (props.selected ? '16px' : '14px')};
    color: #fff;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
  }
`;
