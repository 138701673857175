import React from 'react';
import { DRAWER_IDS } from '../../components/DrawerBar/types';
import InifiniteList, { InfiniteListGroup } from '../../components/Shared/InfiniteList/InfiniteList';
import useNavigateDrawer from '../../hooks/useNavigateDrawer';
import { UsePagintaedQueryResult } from '../../hooks/usePaginatedQuery';
import useSetDrawerOnNavigation from '../../hooks/useSetDrawerOnNavigation';
import SubscriptionRecord from './SubscriptionRecord';
import { Subscription } from './types';

const SubscriptionsList = ({
  paginatedQueryResult,
  group,
  reverseItems,
  showTotal
}: {
  paginatedQueryResult: UsePagintaedQueryResult<Subscription[]>;
  group?: (list: Subscription[]) => InfiniteListGroup;
  reverseItems?: boolean;
  showTotal?: {
    orderTotal?: boolean;
    dateFilter?: { from: string; to: string };
  };
}) => {
  const [[hasMoreItems, setHasMoreItems], queryResult] = paginatedQueryResult;

  const { data: { getBranchOrderSubscriptions: subscriptions = [] } = {}, loading, fetchMore } = queryResult;

  useSetDrawerOnNavigation({ itemList: subscriptions, drawerId: DRAWER_IDS.SUBSCRIPTION_DRAWER, itemsToFilterFrom: subscriptions, fireOnce: false });

  const navigateDrawer = useNavigateDrawer({
    drawerData: {
      drawerId: DRAWER_IDS.SUBSCRIPTION_DRAWER,
      itemsToFilterFrom: subscriptions
    }
  });

  return (
    <InifiniteList
      list={subscriptions}
      itemRenderer={(subscription: Subscription) => <SubscriptionRecord key={subscription?.id} subscription={subscription} navigateDrawer={navigateDrawer} showTotal={showTotal} />}
      hasMoreItems={hasMoreItems}
      loading={loading}
      fetchMore={fetchMore as any}
      offset={subscriptions?.length}
      setHasMoreItems={setHasMoreItems}
      group={group?.(subscriptions)}
      reversedItems={reverseItems}
    />
  );
};

export default SubscriptionsList;
