import { useQuery } from '@apollo/client';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import Colors from '../../../../Colors';
import ModalDialog from '../../../../components/Modal/ModalDialog';
import { ModalBody, ModalFooter } from '../../../../components/Modal/styled';
import { Pet, SuppRecord } from '../../../../components/Pets/types';
import { BusUserProfile } from '../../../../components/Profile/types';
import {
  FormButtonsContainer,
  FormCheckbox,
  FormError,
  FormHeaderLabel,
  FormInput,
  FormLabel,
  FormRadio,
  FormSubmitButton,
  InputsWrapper,
  WideInputGroup
} from '../../../../components/Shared/Forms/Forms';
import { Container } from '../../../../components/Shared/Shared';
import usePrinter, { printerSize } from '../../../../hooks/usePrinter';
import { GetBusUserProfile } from '../../../../queries';
import { toReadableDate } from '../../../../utils/dates';
import { PrintSuppRecord } from '../../Health/HealthPetRecords';
import { PrintPetAdministeredRecordContainer, PrintSplitter } from './styled';

const PrescribedMedicationsPrintModal = ({ pet, sortedSuppRecords }: { pet: Pet; sortedSuppRecords: SuppRecord[] }) => {
  const { data: { getBusUserProfile: userProfile } = {} } = useQuery<{ getBusUserProfile: BusUserProfile }>(GetBusUserProfile, {
    fetchPolicy: 'cache-and-network'
  });
  const options = sortedSuppRecords.map((suppRecord, index) => {
    const productName = suppRecord?.Product?.name;
    const createdAtDateString = toReadableDate(new Date(suppRecord.createdAt) || new Date(), { noTime: true, isLocale: true });
    return {
      name: `${productName} - ${createdAtDateString}`,
      id: String(index)
    };
  });

  const form = useForm({
    defaultValues: {
      selectedSuppRecords: [],
      orientation: 'landscape',
      customOrientation: {
        pageWidth: 70,
        pageHeight: 48
      }
    }
  });

  const { control, handleSubmit, errors, watch } = form;

  const watchedSelectedSuppRecords: string[] = watch('selectedSuppRecords');

  const selectedSuppRecords = sortedSuppRecords.filter((_suppRecord, index) => watchedSelectedSuppRecords.includes(index.toString()));
  const watchedOrientation = watch('orientation') as 'portrait' | 'landscape' | 'custom';
  const watchedCustomOrientation = watch('customOrientation');

  const customOrientation = {
    width: `${Number(watchedCustomOrientation?.pageWidth) * 3.3}px`,
    height: `${Number(watchedCustomOrientation?.pageHeight) * 3.3}px`,
    page: `${watchedCustomOrientation?.pageWidth}mm ${watchedCustomOrientation?.pageHeight}mm`
  };

  const { onHandlePrint, PrinterTags, printRef } = usePrinter({
    orientation: watchedOrientation,
    customOrientation
  });

  const onSubmit = handleSubmit(() => {
    onHandlePrint();
    ModalDialog.closeModal();
  });

  const selectedSize = watchedOrientation === 'custom' ? customOrientation : printerSize[watchedOrientation];

  return (
    <>
      <ModalBody minHeight={300}>
        <Container width={400}>
          <InputsWrapper noWrap>
            <WideInputGroup>
              <FormHeaderLabel fontSize={18} margin="0 0 24px 0">
                {pet?.name}'s Prescribed Medications
              </FormHeaderLabel>
              <WideInputGroup>
                <Controller
                  name="selectedSuppRecords"
                  control={control}
                  render={({ onChange, value }) => <FormCheckbox itemsArray={options} onChange={onChange} value={value} column fontSize={16} error={!!errors.selectedSuppRecords} />}
                  rules={{
                    required: true,
                    validate: val => {
                      return (val.length > 0 && val.length <= 5) || 'Please select between 1 and 5 reports';
                    }
                  }}
                />
              </WideInputGroup>
              {errors.selectedSuppRecords && <FormError>{errors.selectedSuppRecords[0]?.message || 'Please select at least 1 treatment'}</FormError>}
            </WideInputGroup>

            <PrintPetAdministeredRecordContainer ref={printRef} style={{ display: 'none' }} selectedSize={selectedSize}>
              <PrinterTags />
              {selectedSuppRecords.map(suppRecord => (
                <PrintSuppRecord pet={pet} suppRecord={suppRecord} key={suppRecord?.id} busUserProfile={userProfile} />
              ))}
            </PrintPetAdministeredRecordContainer>
          </InputsWrapper>
        </Container>
        {!!selectedSuppRecords?.length && (
          <>
            <FormLabel>Preview</FormLabel>
            <div style={{ width: watchedOrientation === 'landscape' ? '100%' : watchedOrientation === 'portrait' ? 'auto' : customOrientation?.width, backgroundColor: Colors.grey }}>
              <PrintPetAdministeredRecordContainer selectedSize={selectedSize}>
                <PrinterTags />
                {selectedSuppRecords.map((suppRecord, index) => (
                  <>
                    <PrintSuppRecord pet={pet} suppRecord={suppRecord} key={suppRecord?.id} busUserProfile={userProfile} />
                    {index !== selectedSuppRecords.length - 1 && <PrintSplitter />}
                  </>
                ))}
              </PrintPetAdministeredRecordContainer>
            </div>
          </>
        )}
      </ModalBody>

      <ModalFooter>
        <WideInputGroup>
          <FormLabel>Orientation</FormLabel>
          <Controller
            name="orientation"
            control={control}
            render={({ onChange, value }) => (
              <FormRadio
                itemsArray={[
                  { name: 'Landscape', id: 'landscape' },
                  { name: 'Portrait', id: 'portrait' },
                  { name: 'Custom', id: 'custom' }
                ]}
                onChange={onChange}
                value={value}
                column
                fontSize={16}
              />
            )}
            rules={{
              required: true
            }}
          />

          {errors.orientation && <FormError>{errors.orientation?.message || 'Please select an orientation'}</FormError>}
        </WideInputGroup>
        {watchedOrientation === 'custom' && (
          <WideInputGroup flexDirection="row" style={{ alignSelf: 'flex-end', margin: 20, gap: 8 }}>
            <Controller
              name="customOrientation.pageWidth"
              control={control}
              as={<FormInput type="number" placeholder="Width" width={38} />}
              rules={{
                required: true
              }}
              defaultValue={70}
            />
            <Controller
              name="customOrientation.pageHeight"
              control={control}
              as={<FormInput type="number" placeholder="Height" width={38} />}
              rules={{
                required: true
              }}
              defaultValue={50}
            />
          </WideInputGroup>
        )}
        <FormButtonsContainer>
          <FormSubmitButton onClick={onSubmit}>Print</FormSubmitButton>
        </FormButtonsContainer>
      </ModalFooter>
    </>
  );
};

export default PrescribedMedicationsPrintModal;
