import React, { useEffect } from 'react';
import { OPTION_DROPDOWN_TYPES } from '../../../components/Shared/Menus/OptionDropdown/types';
import Filters from '../../../components/Shared/Filters/Filters';
import { PRODUCTS_FILTER_TYPES, PRODUCTS_MORE_OPTIONS_TYPES } from '../../../components/Shared/Filters/config';
import { areObjectsEqual } from '../../../utils/helpers';
import useFilters from '../../../components/Shared/Filters/useFilters';
import SearchBar from '../../../components/Shared/Filters/SearchBar';
import { manageProductsFilters } from '../../../reactive/actions';

const PRODUCTS_FILTER_TYPES_NAMES: Record<PRODUCTS_FILTER_TYPES, string> = {
  [PRODUCTS_FILTER_TYPES.SEARCH]: 'Search'
};

const MORE_OPTIONS_TITLES: Record<PRODUCTS_MORE_OPTIONS_TYPES, string> = {
  [PRODUCTS_MORE_OPTIONS_TYPES.SEARCH]: ''
};

const MORE_OPTIONS_DROPDOWN_TYPES: Record<PRODUCTS_MORE_OPTIONS_TYPES, OPTION_DROPDOWN_TYPES> = {
  [PRODUCTS_MORE_OPTIONS_TYPES.SEARCH]: OPTION_DROPDOWN_TYPES.TEXT_INPUT
};

const PRODUCTS_FILTER_TYPES_UNITS: Record<PRODUCTS_FILTER_TYPES, string> = {
  [PRODUCTS_FILTER_TYPES.SEARCH]: ''
};

const ProductsHeader = () => {
  const { addFilter, addHeaderAction, clearFilter, clearHeaderAction, removeFilter, setNewVisibleFilters } = manageProductsFilters;
  const filtersControl = useFilters({
    setSelectedFilters: setNewVisibleFilters,
    toCall: [],
    addHeaderAction: addHeaderAction,
    clearHeaderAction: clearHeaderAction
  });

  const PRODUCTS_FILTER_TYPES_MORE_OPTIONS: Record<PRODUCTS_FILTER_TYPES, PRODUCTS_MORE_OPTIONS_TYPES[]> = {
    [PRODUCTS_FILTER_TYPES.SEARCH]: [PRODUCTS_MORE_OPTIONS_TYPES.SEARCH]
  };

  const MORE_OPTIONS_ITEMS: Record<PRODUCTS_MORE_OPTIONS_TYPES, { value: string | boolean; name: string }[] | []> = {
    [PRODUCTS_MORE_OPTIONS_TYPES.SEARCH]: []
  };

  const filterItems = Object.values(PRODUCTS_FILTER_TYPES).map(filter => ({
    name: PRODUCTS_FILTER_TYPES_NAMES[filter],
    value: filter,
    unit: PRODUCTS_FILTER_TYPES_UNITS[filter],
    moreOptions: PRODUCTS_FILTER_TYPES_MORE_OPTIONS[filter].map(option => ({
      type: option,
      filterType: filter,
      id: `${filter}.${option}`,
      title: MORE_OPTIONS_TITLES[option] ? `${MORE_OPTIONS_TITLES[option]} Filter` : '',
      optionType: MORE_OPTIONS_DROPDOWN_TYPES[option],
      items: MORE_OPTIONS_ITEMS[option]
    }))
  }));

  const searchFilter = filterItems.find(({ value }) => value === PRODUCTS_FILTER_TYPES.SEARCH)!;
  const searchMoreOptions = searchFilter.moreOptions.find(({ type }) => type === PRODUCTS_MORE_OPTIONS_TYPES.SEARCH)!;

  const onProductSearch = (query: string) => {
    if (query) {
      addFilter({
        ...searchFilter,
        moreOptions: [{ ...searchMoreOptions, values: query }],
        requisite: 'true'
      });
      return;
    }
    removeFilter(searchFilter.value);
  };

  useEffect(() => {
    addHeaderAction({
      action: <SearchBar onSearch={onProductSearch} showAlways defaultText="Search by Product Name" />,
      id: 'search'
    });

    return () => {
      clearHeaderAction({
        id: 'search'
      });
    };
  }, []);

  return <Filters filtersControl={filtersControl} filterItems={filterItems} />;
};

export default React.memo(ProductsHeader, areObjectsEqual);
