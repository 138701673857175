import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { Controller, UseFormMethods, useForm } from 'react-hook-form';
import { sentenceCase } from 'sentence-case';
import ModalDialog from '../../../components/Modal/ModalDialog';
import { ModalBody, ModalFooter } from '../../../components/Modal/styled';
import { Branch, BusUserProfile } from '../../../components/Profile/types';
import { FormButtonsContainer, FormCheckbox, FormError, FormInput, FormLabel, FormSelect, FormSubmitButton, InputsWrapper, WideInputGroup, selectTheme } from '../../../components/Shared/Forms/Forms';
import { Container } from '../../../components/Shared/Shared';
import { CenteredLoader } from '../../../components/Shared/Spinner';
import { AddBranchBusUser, EditBranchBusUser, GetReducedBusUserProfile } from '../../../queries';
import { isEmail } from '../../../utils/validators';
import { BUS_USER_ROLES } from '../../Bookings/types';
import Common from '../Common';

type BusUserModalForm = {
  name: string;
  email: string;
  status: 'ACTIVE' | 'INACTIVE';
  role: keyof typeof BUS_USER_ROLES;
  position: string;
  contact_number: string;
  password: string;
  confirmPassword: string;
  passwordToggled: string[];
  BranchId: string;
};

type BusUserModalFormProps = {
  loading: boolean;
  formOptions: UseFormMethods<BusUserModalForm>;
  saveError: boolean;
  busUser?: BusUserProfile;
  saveLoading: boolean;
  branches: Branch[];
  defaultValues?: Partial<BusUserModalForm>;
  isSelf?: boolean;
  onSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
};

const BusUserModalForm = ({ loading, formOptions, saveError, saveLoading, onSubmit, busUser, branches, defaultValues, isSelf }: BusUserModalFormProps) => {
  const { control, errors, watch } = formOptions;

  const { password: watchedPassword, passwordToggled } = watch(['password', 'passwordToggled']);

  const isPasswordToggled = passwordToggled.includes('passwordToggled');

  return (
    <>
      <ModalBody>
        <Container width={500}>
          {loading && <CenteredLoader />}
          {!loading && (
            <InputsWrapper>
              <WideInputGroup>
                <FormLabel error={errors.name?.message}>Full Name</FormLabel>
                <Controller as={<FormInput error={!!errors.name} type={'text'} />} control={control} name={'name'} rules={{ required: true }} defaultValue={defaultValues?.name} />
                {errors.name && <FormError>{errors.name.message || 'Full name is required'}</FormError>}
              </WideInputGroup>
              <WideInputGroup>
                <FormLabel error={errors.contact_number?.message}>Contact Number</FormLabel>
                <Controller
                  as={<FormInput error={!!errors.contact_number} role="presentation" autoComplete="off" />}
                  control={control}
                  name={'contact_number'}
                  defaultValue={defaultValues?.contact_number}
                />
                {errors.contact_number && <FormError>{errors.contact_number.message || 'Contact Number is required'}</FormError>}
              </WideInputGroup>

              <WideInputGroup>
                <FormLabel error={errors.position?.message}>Position</FormLabel>
                <Controller as={<FormInput error={!!errors.position} type={'text'} />} control={control} name={'position'} rules={{ required: true }} defaultValue={defaultValues?.position} />
                {errors.position && <FormError>{errors.position.message || 'Position is required'}</FormError>}
              </WideInputGroup>

              {!isSelf && (
                <WideInputGroup>
                  <FormLabel error={errors.role?.message}>Role</FormLabel>
                  <Controller
                    render={({ onChange, value }) => (
                      <FormSelect
                        height={48}
                        fontSize={16}
                        name={'role'}
                        onChange={e => {
                          onChange(e.target.value);
                        }}
                        value={value}
                        theme={selectTheme}
                      >
                        {Object.values(BUS_USER_ROLES).map(role => {
                          return (
                            <option key={role} value={role}>
                              {sentenceCase(role)}
                            </option>
                          );
                        })}
                      </FormSelect>
                    )}
                    control={control}
                    name={'role'}
                    rules={{ required: true }}
                    defaultValue={defaultValues?.role}
                  />

                  {errors.role && <FormError>{errors.role.message || 'Role is required'}</FormError>}
                </WideInputGroup>
              )}
              {!isSelf && (
                <WideInputGroup>
                  <FormLabel error={errors.email?.message}>Business Email Address</FormLabel>
                  <Controller
                    as={<FormInput error={!!errors.email} type={'email'} role="presentation" autoComplete="off" />}
                    control={control}
                    name={'email'}
                    rules={{ required: true, pattern: isEmail }}
                    defaultValue={defaultValues?.email}
                  />
                  {errors.email && <FormError>{errors.email.message || 'Email is required'}</FormError>}
                </WideInputGroup>
              )}

              <WideInputGroup>
                <FormLabel error={errors.status?.message}>Status</FormLabel>
                <Controller
                  render={({ onChange, value }) => (
                    <FormSelect
                      height={48}
                      fontSize={16}
                      name={'status'}
                      onChange={e => {
                        onChange(e.target.value);
                      }}
                      value={value}
                      theme={selectTheme}
                    >
                      <option value={'ACTIVE'}>Active</option>
                      <option value={'INACTIVE'}>Inactive</option>
                    </FormSelect>
                  )}
                  control={control}
                  name={'status'}
                  rules={{ required: true }}
                  defaultValue={defaultValues?.status}
                />
                {errors.status && <FormError>{errors.status.message || 'Status is required'}</FormError>}
              </WideInputGroup>

              {busUser?.id && !isSelf && (
                <WideInputGroup>
                  <Controller
                    render={({ onChange, value }) => <FormCheckbox itemsArray={[{ id: 'passwordToggled', name: 'Change Password' }]} onChange={onChange} value={value} fontSize={16} column />}
                    control={control}
                    name={'passwordToggled'}
                    rules={{ required: true }}
                    defaultValue={defaultValues?.passwordToggled}
                  />
                </WideInputGroup>
              )}

              {isPasswordToggled && (
                <>
                  <WideInputGroup>
                    <FormLabel error={errors.password?.message}>New Password</FormLabel>
                    <Controller as={<FormInput error={!!errors.password} type={'password'} role="presentation" autoComplete="off" />} control={control} name={'password'} />
                    {errors.password && <FormError>{errors.password.message || 'Password is required'}</FormError>}
                  </WideInputGroup>

                  {watchedPassword && (
                    <WideInputGroup>
                      <FormLabel error={errors.confirmPassword?.message}>Confirm New Password</FormLabel>
                      <Controller
                        as={<FormInput error={!!errors.confirmPassword} type={'password'} role="presentation" autoComplete="off" />}
                        control={control}
                        name={'confirmPassword'}
                        rules={{ validate: value => value === watchedPassword || 'Passwords do not match' }}
                      />
                      {errors.confirmPassword && <FormError>{errors.confirmPassword.message || 'Password is required'}</FormError>}
                    </WideInputGroup>
                  )}
                </>
              )}

              <WideInputGroup>
                <FormLabel error={errors.BranchId?.message}>Branch</FormLabel>
                <Controller
                  render={({ onChange, value }) => (
                    <FormSelect
                      height={48}
                      fontSize={16}
                      name={'BranchId'}
                      onChange={e => {
                        onChange(e.target.value);
                      }}
                      value={value}
                      theme={selectTheme}
                    >
                      {branches.map(branch => {
                        return (
                          <option key={branch.id} value={branch.id}>
                            {branch.name}
                          </option>
                        );
                      })}
                    </FormSelect>
                  )}
                  control={control}
                  name={'BranchId'}
                  defaultValue={defaultValues?.BranchId}
                  rules={{ required: false }}
                />
                {errors.BranchId && <FormError>{errors.BranchId.message || 'Branch is required'}</FormError>}
              </WideInputGroup>
            </InputsWrapper>
          )}
        </Container>
      </ModalBody>
      <ModalFooter>
        <FormButtonsContainer>
          <FormSubmitButton error={saveError} loading={saveLoading} onClick={onSubmit}>
            Save
          </FormSubmitButton>
        </FormButtonsContainer>
      </ModalFooter>
    </>
  );
};
const BusUserModal = ({ busUser }: { busUser?: BusUserProfile }) => {
  const { data: { getBusUserProfile: currentBusUser } = {} } = useQuery<{ getBusUserProfile: BusUserProfile }>(GetReducedBusUserProfile);

  const isSelf = busUser?.id === currentBusUser?.id;

  const branches = currentBusUser?.Provider?.Branches || [];

  const defaultValues: BusUserModalForm = {
    name: busUser?.name || '',
    email: busUser?.email || '',
    role: busUser?.role || 'STAFF',
    position: busUser?.position || '',
    contact_number: busUser?.contact_number || currentBusUser?.Provider?.contact_number || '',
    password: '',
    confirmPassword: '',
    status: busUser?.status || 'ACTIVE',
    passwordToggled: [],
    BranchId: busUser?.Branch?.id || branches[0]?.id
  };

  const formOptions = useForm<BusUserModalForm>({ defaultValues });

  const { handleSubmit } = formOptions;
  const [saveBusUser, { data: addedBusUser, loading: addLoading, error: addError }] = useMutation(AddBranchBusUser, {
    refetchQueries: ['getBranchBusUsers'],
    awaitRefetchQueries: true
  });

  const [editBusUser, { data: editedBusUser, loading: editLoading, error: editError }] = useMutation(EditBranchBusUser, {
    refetchQueries: ['getBranchBusUsers'],
    awaitRefetchQueries: true
  });

  const onSubmit = handleSubmit(data => {
    const { name, email, role, position, contact_number, password, status, BranchId } = data;
    const variables = {
      name,
      email: isSelf ? busUser?.email : email,
      ...(isSelf ? {} : { role }),
      position,
      contact_number,
      status,
      password: password || undefined,
      BranchId: BranchId || undefined
    };

    if (busUser) {
      editBusUser({
        variables: {
          id: busUser.id,
          ...variables
        }
      });

      return;
    }

    saveBusUser({ variables });
  });

  const loading = false;
  const saveError = !!addError?.message || !!editError?.message;
  const saveLoading = addLoading || editLoading;
  const savedBusUser = addedBusUser?.addBranchBusUser || editedBusUser?.editBranchBusUser;

  useEffect(() => {
    if (savedBusUser) {
      ModalDialog.closeModal();
      Common.get<() => Promise<void>>(`BusUsers.GetBranchBusUsers.refetch`)?.();
    }
  }, [savedBusUser]);

  return (
    <BusUserModalForm
      loading={loading}
      formOptions={formOptions}
      saveError={saveError}
      saveLoading={saveLoading}
      onSubmit={onSubmit}
      busUser={busUser}
      branches={branches}
      defaultValues={defaultValues}
      isSelf={isSelf}
    />
  );
};

export default BusUserModal;
