import styled, { css } from 'styled-components';
import Colors from '../../Colors';
import { HoverRecordOptionsContainer } from '../../views/styled';

export const UserBookingsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: auto;
`;

export const ListView = styled.div<{ minHeight?: number }>`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 20px;
  padding: 20px;
  overflow-y: auto;
  min-height: 200px;
  ${({ minHeight }) =>
    minHeight &&
    css`
      min-height: ${minHeight}px;
    `}
  flex: 1;
`;

export const BookingsListCotainer = styled.div<{ flexOne?: boolean }>`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  ${({ flexOne }) =>
    flexOne &&
    css`
      flex: 1;
    `}
`;

export const SubscriptionsInvoiceListContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 20px;
  flex: 1;
  gap: 20px;
`;

export const BookingListItem = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ListHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const ListHeaderContainer = styled.div`
  padding: 8px 16px;
`;

export const ListHeaderText = styled.span`
  font-size: 14px;
  font-weight: 800;
  color: #000;
`;

export const ListHeaderContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;

export const BookingsDivider = styled.div`
  height: 1px;
  background-color: ${Colors.divider};
  margin: 10px 0;
`;

export const UserBookingContainer = styled.div<{ clickable?: boolean; selected?: boolean; disableHover?: boolean; autoHeight?: boolean; opacity?: number }>`
  height: ${({ autoHeight }) => (autoHeight ? 'auto' : '50px')};
  min-height: 50px;
  display: flex;
  align-items: center;
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};
  border-radius: 12px;
  opacity: ${({ opacity }) => (opacity ? opacity : 1)};
  background-color: ${({ selected }) => (selected ? '#f5f5f5' : '#fff')};
  padding: 0 8px;
  ${({ disableHover }) =>
    !disableHover &&
    css`
      &:hover {
        background-color: #f5f5f5;
        ${HoverRecordOptionsContainer} {
          visibility: visible;
        }
      }
    `}
`;

export const BillingStatus = styled.div<{ noMaxWidth: boolean; red: boolean; blue: boolean; green: boolean; gray: boolean }>`
  height: 21px;
  border-radius: 11px;
  display: flex;
  padding: 0 20px;
  ${({ noMaxWidth }) => !noMaxWidth && 'max-width: 40px;'}
  align-items: center;
  justify-content: center;
  font-size: 11px;
  color: #272727;
  background-color: ${({ red, blue, green, gray }) => {
    if (gray) return '#e6e6e6';
    if (red) return '#e6b3b3';
    if (blue) return '#b3e2e6';
    if (green) return '#b3e6b3';
    return '#e6b3b3';
  }};
`;

export const UserExtendButton = styled.div<{ noMargin?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 40px;
  border-radius: 12px;
  margin-bottom: 20px;
  background-color: ${Colors.primary};
  color: #fff;
  font-size: 14px;
  font-weight: 800;
  padding: 0 20px;
  border: 1px solid ${Colors.primary};
  transition: all 0.2s ease-in-out;
  ${({ noMargin }) =>
    noMargin &&
    css`
      margin-bottom: 0;
    `}
  &:hover {
    opacity: 0.8;
  }
  @media (max-width: 768px) {
    background-color: ${Colors.black};
    border: 1px solid ${Colors.black};
  }
`;
