import { ORDER_INVOICE_STATUS } from '../../../components/OrderInvoices/types';
import { Pet } from '../../../components/Pets/types';
import { Branch } from '../../../components/Profile/types';
import { ORDER_REPEAT_STATUS } from '../../Subscriptions/types';
import { BranchAppUserTag } from '../BranchTags/types';

export type CurrentPeriod = {
  start: string;
  end: string;
};

export type BranchAppUser = {
  id: string;
  name: string;
  Orders: { id: string; total: string }[];
  appUserCounts: {
    totalOrdersCount: number;
    totalProductOrdersCount: number;
    totalProductOrdersAmount: number;
    totalAppointmentsCount: number;
    totalAppointmentsAmount: number;
    totalSubscriptionOrdersCount: number;
    totalSubscriptionOrdersAmount: number;
    totalOrdersAmount: number;
    totalAppointmentsOrdersCount: number;
  };
  BranchAppUserTags: BranchAppUserTag[];
  profile_pic: string;
  payment: {
    method: string;
  };
  Pets: Pet[];
  Branch: Branch;
  addresses: { line1: string; line2: string; city: string; country: string }[];
  postcode: string;
  email: string;
  phone_numbers: { number: string; label: 'primary' | 'emergency'; country_code: string }[];
  ChappedPets: Pet[];
};

export enum BRANCH_BILLING_FILTERS {
  APP_USERS = 'APP_USERS',
  BOOKINGS = 'BOOKINGS',
  ORDERS = 'ORDERS',
  ORDERS_TOTAL = 'ORDERS_TOTAL'
}

export enum BRANCH_BILLING_INVOICES_FILTERS {
  INVOICES = 'INVOICES',
  INVOICES_BILLED = 'INVOICES_BILLED',
  INVOICES_PENDING_REJECTED = 'INVOICES_PENDING_REJECTED',
  INVOICES_UNBILLED = 'INVOICES_UNBILLED',
  INVOICES_ORDER_TOTAL = 'INVOICES_ORDER_TOTAL'
}

export enum BRANCH_BILLING_SUBSCRIPTIONS_FILTERS {
  SUBSCRIPTIONS = 'SUBSCRIPTIONS',
  SUBSCRIPTIONS_ISSUED_INVOICES = 'SUBSCRIPTIONS_ISSUED_INVOICES',
  SUBSCRIPTIONS_BILLED = 'BILLED',
  SUBSCRIPTIONS_UNBILLED = 'UNBILLED',
  SUBSCRIPTIONS_ORDER_TOTAL = 'SUBSCRIPTIONS_ORDER_TOTAL'
}

export enum BRANCH_BILLING_SUBSCRIPTION_INVOICES_FILTERS {
  // SUBSCRIPTION_INVOICES = 'SUBSCRIPTION_INVOICES',
  // SUBSCRIPTION_INVOICES_BILLED = 'SUBSCRIPTION_INVOICES_BILLED',
  // SUBSCRIPTION_INVOICES_PENDING_REJECTED = 'SUBSCRIPTION_INVOICES_PENDING_REJECTED',
  // SUBSCRIPTION_INVOICES_UNBILLED = 'SUBSCRIPTION_INVOICES_UNBILLED',
  SUBSCRIPTION_INVOICES_UNBILLED_ALL = 'SUBSCRIPTION_INVOICES_UNBILLED_ALL'
}

export const BRANCH_BILLING_FILTERS_LABELS = {
  [BRANCH_BILLING_FILTERS.APP_USERS]: 'Clients',
  [BRANCH_BILLING_FILTERS.BOOKINGS]: 'Bookings',
  [BRANCH_BILLING_FILTERS.ORDERS]: 'Orders',
  [BRANCH_BILLING_INVOICES_FILTERS.INVOICES]: 'Invoices',
  [BRANCH_BILLING_INVOICES_FILTERS.INVOICES_BILLED]: 'Paid',
  [BRANCH_BILLING_INVOICES_FILTERS.INVOICES_PENDING_REJECTED]: 'Pending/Rejected',
  [BRANCH_BILLING_INVOICES_FILTERS.INVOICES_UNBILLED]: 'Unpaid',
  [BRANCH_BILLING_SUBSCRIPTIONS_FILTERS.SUBSCRIPTIONS]: 'Memberships',
  [BRANCH_BILLING_SUBSCRIPTIONS_FILTERS.SUBSCRIPTIONS_ISSUED_INVOICES]: 'Issued Invoices',
  [BRANCH_BILLING_SUBSCRIPTIONS_FILTERS.SUBSCRIPTIONS_BILLED]: 'Paid',
  [BRANCH_BILLING_SUBSCRIPTIONS_FILTERS.SUBSCRIPTIONS_UNBILLED]: 'Unpaid',
  // [BRANCH_BILLING_SUBSCRIPTION_INVOICES_FILTERS.SUBSCRIPTION_INVOICES]: 'Memberships Invoices',
  // [BRANCH_BILLING_SUBSCRIPTION_INVOICES_FILTERS.SUBSCRIPTION_INVOICES_BILLED]: 'Paid',
  // [BRANCH_BILLING_SUBSCRIPTION_INVOICES_FILTERS.SUBSCRIPTION_INVOICES_PENDING_REJECTED]: 'Pending/Rejected',
  // [BRANCH_BILLING_SUBSCRIPTION_INVOICES_FILTERS.SUBSCRIPTION_INVOICES_UNBILLED]: 'Unpaid',
  [BRANCH_BILLING_SUBSCRIPTION_INVOICES_FILTERS.SUBSCRIPTION_INVOICES_UNBILLED_ALL]: 'All Unpaid',
  [BRANCH_BILLING_FILTERS.ORDERS_TOTAL]: 'Estimated billing',
  [BRANCH_BILLING_INVOICES_FILTERS.INVOICES_ORDER_TOTAL]: 'Total Invoices',
  [BRANCH_BILLING_SUBSCRIPTIONS_FILTERS.SUBSCRIPTIONS_ORDER_TOTAL]: 'Total Value'
} as const;

export type BILLING_FILTERS = BRANCH_BILLING_FILTERS | BRANCH_BILLING_INVOICES_FILTERS | BRANCH_BILLING_SUBSCRIPTIONS_FILTERS | BRANCH_BILLING_SUBSCRIPTION_INVOICES_FILTERS;

export const BRANCH_BILLING_FILTERS_TOTALS = {
  [BRANCH_BILLING_FILTERS.APP_USERS]: ['totalAppUsersCount'],
  [BRANCH_BILLING_FILTERS.BOOKINGS]: ['totalAppointmentsAmount'],
  [BRANCH_BILLING_FILTERS.ORDERS]: ['totalProductOrdersAmount'],
  [BRANCH_BILLING_INVOICES_FILTERS.INVOICES]: ['totalOrderInvoicesCount'],
  [BRANCH_BILLING_INVOICES_FILTERS.INVOICES_BILLED]: ['totalBilledOrderInvoicesAmount'],
  [BRANCH_BILLING_INVOICES_FILTERS.INVOICES_PENDING_REJECTED]: ['totalPendingOrderInvoicesAmount', 'totalRejectedOrderInvoicesAmount'],
  [BRANCH_BILLING_INVOICES_FILTERS.INVOICES_UNBILLED]: ['totalUnbilledOrderInvoicesAmount'],
  [BRANCH_BILLING_SUBSCRIPTIONS_FILTERS.SUBSCRIPTIONS]: ['totalSubscriptionOrderRepeatsAmount'],
  [BRANCH_BILLING_SUBSCRIPTIONS_FILTERS.SUBSCRIPTIONS_ISSUED_INVOICES]: ['totalSubscriptionOrderRepeatsAmount'],
  [BRANCH_BILLING_SUBSCRIPTIONS_FILTERS.SUBSCRIPTIONS_BILLED]: ['totalBilledSubscriptionOrderRepeatsAmount'],
  [BRANCH_BILLING_SUBSCRIPTIONS_FILTERS.SUBSCRIPTIONS_UNBILLED]: ['totalPendingSubscriptionOrderRepeatsAmount', 'totalRejectedSubscriptionOrderRepeatsAmount'],
  // [BRANCH_BILLING_SUBSCRIPTION_INVOICES_FILTERS.SUBSCRIPTION_INVOICES]: ['totalOrderSubscriptionRepeatsAmount'],
  // [BRANCH_BILLING_SUBSCRIPTION_INVOICES_FILTERS.SUBSCRIPTION_INVOICES_BILLED]: ['totalBilledOrderSubscriptionRepeatsAmount'],
  // [BRANCH_BILLING_SUBSCRIPTION_INVOICES_FILTERS.SUBSCRIPTION_INVOICES_PENDING_REJECTED]: ['totalPendingOrderSubscriptionRepeatsAmount', 'totalRejectedOrderSubscriptionRepeatsAmount'],
  // [BRANCH_BILLING_SUBSCRIPTION_INVOICES_FILTERS.SUBSCRIPTION_INVOICES_UNBILLED]: ['totalUnbilledOrderSubscriptionRepeatsAmount'],
  [BRANCH_BILLING_SUBSCRIPTION_INVOICES_FILTERS.SUBSCRIPTION_INVOICES_UNBILLED_ALL]: ['allTotalUnbilledOrderSubscriptionRepeatsAmount'],
  [BRANCH_BILLING_FILTERS.ORDERS_TOTAL]: ['totalOrdersAmount'],
  [BRANCH_BILLING_INVOICES_FILTERS.INVOICES_ORDER_TOTAL]: ['totalOrderInvoicesAmount'],
  [BRANCH_BILLING_SUBSCRIPTIONS_FILTERS.SUBSCRIPTIONS_ORDER_TOTAL]: ['totalActiveSubscriptionOrdersAmount']
};

export const BRANCH_BILLING_FILTERS_SECONDARY_TOTALS = {
  [BRANCH_BILLING_FILTERS.APP_USERS]: [],
  [BRANCH_BILLING_INVOICES_FILTERS.INVOICES]: [],
  [BRANCH_BILLING_SUBSCRIPTIONS_FILTERS.SUBSCRIPTIONS_ISSUED_INVOICES]: ['totalSubscriptionOrdersCount'],
  [BRANCH_BILLING_INVOICES_FILTERS.INVOICES_BILLED]: [],
  [BRANCH_BILLING_INVOICES_FILTERS.INVOICES_PENDING_REJECTED]: [],
  [BRANCH_BILLING_INVOICES_FILTERS.INVOICES_UNBILLED]: [],
  [BRANCH_BILLING_SUBSCRIPTIONS_FILTERS.SUBSCRIPTIONS_BILLED]: [],
  [BRANCH_BILLING_SUBSCRIPTIONS_FILTERS.SUBSCRIPTIONS_UNBILLED]: [],
  // [BRANCH_BILLING_SUBSCRIPTION_INVOICES_FILTERS.SUBSCRIPTION_INVOICES]: [],
  // [BRANCH_BILLING_SUBSCRIPTION_INVOICES_FILTERS.SUBSCRIPTION_INVOICES_BILLED]: [],
  // [BRANCH_BILLING_SUBSCRIPTION_INVOICES_FILTERS.SUBSCRIPTION_INVOICES_PENDING_REJECTED]: [],
  // [BRANCH_BILLING_SUBSCRIPTION_INVOICES_FILTERS.SUBSCRIPTION_INVOICES_UNBILLED]: [],
  [BRANCH_BILLING_FILTERS.BOOKINGS]: ['totalAppointmentsOrdersCount'],
  [BRANCH_BILLING_FILTERS.ORDERS]: ['totalProductOrdersCount'],
  [BRANCH_BILLING_SUBSCRIPTIONS_FILTERS.SUBSCRIPTIONS]: ['totalSubscriptionOrdersCount'],
  [BRANCH_BILLING_FILTERS.ORDERS_TOTAL]: [],
  [BRANCH_BILLING_INVOICES_FILTERS.INVOICES_ORDER_TOTAL]: [],
  [BRANCH_BILLING_SUBSCRIPTIONS_FILTERS.SUBSCRIPTIONS_ORDER_TOTAL]: [],
  [BRANCH_BILLING_SUBSCRIPTION_INVOICES_FILTERS.SUBSCRIPTION_INVOICES_UNBILLED_ALL]: ['allTotalUnbilledOrderSubscriptionRepeatsCount']
};

export const BRANCH_BILLING_FILTERS_FILTERABLE = {
  [BRANCH_BILLING_FILTERS.APP_USERS]: true,
  [BRANCH_BILLING_FILTERS.BOOKINGS]: true,
  [BRANCH_BILLING_FILTERS.ORDERS]: true,
  [BRANCH_BILLING_INVOICES_FILTERS.INVOICES]: true,
  [BRANCH_BILLING_INVOICES_FILTERS.INVOICES_BILLED]: true,
  [BRANCH_BILLING_INVOICES_FILTERS.INVOICES_PENDING_REJECTED]: true,
  [BRANCH_BILLING_INVOICES_FILTERS.INVOICES_UNBILLED]: true,
  [BRANCH_BILLING_SUBSCRIPTIONS_FILTERS.SUBSCRIPTIONS]: true,
  [BRANCH_BILLING_SUBSCRIPTIONS_FILTERS.SUBSCRIPTIONS_ISSUED_INVOICES]: true,
  [BRANCH_BILLING_SUBSCRIPTIONS_FILTERS.SUBSCRIPTIONS_BILLED]: true,
  [BRANCH_BILLING_SUBSCRIPTIONS_FILTERS.SUBSCRIPTIONS_UNBILLED]: true,
  // [BRANCH_BILLING_SUBSCRIPTION_INVOICES_FILTERS.SUBSCRIPTION_INVOICES]: true,
  // [BRANCH_BILLING_SUBSCRIPTION_INVOICES_FILTERS.SUBSCRIPTION_INVOICES_BILLED]: true,
  // [BRANCH_BILLING_SUBSCRIPTION_INVOICES_FILTERS.SUBSCRIPTION_INVOICES_PENDING_REJECTED]: true,
  // [BRANCH_BILLING_SUBSCRIPTION_INVOICES_FILTERS.SUBSCRIPTION_INVOICES_UNBILLED]: true,
  [BRANCH_BILLING_SUBSCRIPTION_INVOICES_FILTERS.SUBSCRIPTION_INVOICES_UNBILLED_ALL]: true,
  [BRANCH_BILLING_FILTERS.ORDERS_TOTAL]: false,
  [BRANCH_BILLING_INVOICES_FILTERS.INVOICES_ORDER_TOTAL]: false,
  [BRANCH_BILLING_SUBSCRIPTIONS_FILTERS.SUBSCRIPTIONS_ORDER_TOTAL]: false
};

export const defaultCounts = {
  totalProductOrdersCount: '',
  totalProductOrdersAmount: '',
  totalAppointmentsCount: '',
  totalAppointmentsAmount: '',
  totalAppointmentsOrdersCount: '',
  totalSubscriptionOrdersCount: '',
  totalSubscriptionOrdersAmount: '',
  totalOrdersCount: '',
  totalOrdersAmount: '',
  totalProductOrdersAppUsersCount: '',
  totalAppointmentsAppUsersCount: '',
  totalSubscriptionOrdersAppUsersCount: '',
  totalActiveSubscriptionOrderRepeatsAmount: '',
  totalBilledSubscriptionOrderRepeatsAmount: '',
  totalPendingSubscriptionOrderRepeatsAmount: '',
  totalRejectedSubscriptionOrderRepeatsAmount: '',
  totalUnbilledOrderSubscriptionRepeatsAmount: '',
  allTotalUnbilledOrderSubscriptionRepeatsAmount: '',
  allTotalUnbilledOrderSubscriptionRepeatsCount: '',
  totalUnbilledOrderSubscriptionRepeatsCount: '',
  totalOrderSubscriptionRepeatsAmount: '',
  totalBilledOrderSubscriptionRepeatsAmount: '',
  totalPendingOrderSubscriptionRepeatsAmount: '',
  totalRejectedOrderSubscriptionRepeatsAmount: '',
  totalActiveSubscriptionOrdersAmount: '',
  totalActiveSubscriptionOrdersCount: '',
  totalSubscriptionOrderRepeatsAmount: '',
  totalEstimatedSubscriptionOrdersAmount: '',
  totalAppUsersCount: '',
  totalOrderInvoicesCount: '',
  totalOrderInvoicesAmount: ''
};

export type ProviderCountsOutput = {
  getProviderBranchesCounts: {
    providerCounts: Branch['branchCounts'];
    branches: {
      Branch: Branch;
      counts: Branch['branchCounts'];
    }[];
  };
};

export const BRANCH_BILLING_SUBSCRIPTIONS_FILTERS_REPEAT_STATUS = {
  [BRANCH_BILLING_SUBSCRIPTIONS_FILTERS.SUBSCRIPTIONS]: [],
  [BRANCH_BILLING_SUBSCRIPTIONS_FILTERS.SUBSCRIPTIONS_BILLED]: [ORDER_REPEAT_STATUS.PAYMENT_SUCCEEDED],
  [BRANCH_BILLING_SUBSCRIPTIONS_FILTERS.SUBSCRIPTIONS_UNBILLED]: [ORDER_REPEAT_STATUS.PAYMENT_PENDING, ORDER_REPEAT_STATUS.PAYMENT_PENDING, ORDER_REPEAT_STATUS.PAYMENT_REJECTED],
  [BRANCH_BILLING_SUBSCRIPTIONS_FILTERS.SUBSCRIPTIONS_ISSUED_INVOICES]: [],
  [BRANCH_BILLING_SUBSCRIPTIONS_FILTERS.SUBSCRIPTIONS_ORDER_TOTAL]: []
};

export const BRANCH_BILLING_INVOICES_FILTERS_STATUS = {
  [BRANCH_BILLING_INVOICES_FILTERS.INVOICES]: [],
  [BRANCH_BILLING_INVOICES_FILTERS.INVOICES_BILLED]: [ORDER_INVOICE_STATUS.PAYMENT_SUCCEEDED],
  [BRANCH_BILLING_INVOICES_FILTERS.INVOICES_PENDING_REJECTED]: [ORDER_INVOICE_STATUS.PAYMENT_PENDING, ORDER_INVOICE_STATUS.PAYMENT_REJECTED],
  [BRANCH_BILLING_INVOICES_FILTERS.INVOICES_UNBILLED]: []
};

export const BRANCH_BILLING_SUBSCRIPTION_INVOICES_FILTERS_STATUS = {
  // [BRANCH_BILLING_SUBSCRIPTION_INVOICES_FILTERS.SUBSCRIPTION_INVOICES]: [],
  // [BRANCH_BILLING_SUBSCRIPTION_INVOICES_FILTERS.SUBSCRIPTION_INVOICES_BILLED]: [ORDER_REPEAT_STATUS.PAYMENT_SUCCEEDED],
  // [BRANCH_BILLING_SUBSCRIPTION_INVOICES_FILTERS.SUBSCRIPTION_INVOICES_PENDING_REJECTED]: [ORDER_REPEAT_STATUS.PAYMENT_PENDING, ORDER_REPEAT_STATUS.PAYMENT_REJECTED],
  // [BRANCH_BILLING_SUBSCRIPTION_INVOICES_FILTERS.SUBSCRIPTION_INVOICES_UNBILLED]: [ORDER_REPEAT_STATUS.CANCELED, ORDER_REPEAT_STATUS.REFUNDED],
  [BRANCH_BILLING_SUBSCRIPTION_INVOICES_FILTERS.SUBSCRIPTION_INVOICES_UNBILLED_ALL]: [ORDER_REPEAT_STATUS.PAYMENT_PENDING, ORDER_REPEAT_STATUS.PAYMENT_REJECTED]
};
