import { useQuery, useReactiveVar } from '@apollo/client';
import { useEffect, useState } from 'react';
import {
  BOOKINGS_FILTERS_QUERIES,
  BOOKINGS_FILTER_TYPES,
  BOOKINGS_MORE_OPTIONS_TYPES,
  CHATS_FILTERS_QUERIES,
  CHATS_FILTER_TYPES,
  CHATS_MORE_OPTIONS_TYPES,
  PETS_FILTERS_QUERIES,
  PETS_FILTER_TYPES,
  PETS_MORE_OPTIONS_TYPES,
  PRODUCTS_FILTERS_QUERIES,
  PRODUCTS_FILTER_TYPES,
  PRODUCTS_MORE_OPTIONS_TYPES,
  SUBSCRIPTIONS_FILTERS_QUERIES,
  SUBSCRIPTIONS_FILTER_TYPES,
  SUBSCRIPTIONS_MORE_OPTIONS_TYPES,
  SelectedFilter
} from '../components/Shared/Filters/config';
import {
  getBookingFilter,
  getBookingFilterMoreOptionsValues as getBookingMoreOptions,
  getChatFilter,
  getChatFilterMoreOptionsValues as getChatMoreOptions,
  getPetFilter,
  getPetFilterMoreOptionsValues as getPetsMoreOptions,
  getProductFilter,
  getProductsFilterMoreOptionsValues as getProductMoreOptions,
  getSubscriptionFilter,
  getSubscriptionFilterMoreOptionsValues as getSubscriptionMoreOptions
} from '../components/Shared/Filters/helpers';
import { requiredPetsFiltersData } from '../components/Shared/Filters/queries/filtersQueriesConfig';
import { vars } from '../reactive';
import { ageToBirthdate, dateInUTC, getPreviousYearDate } from '../utils/dates';
import { getBirthdateDateFromTo } from '../utils/helpers';

function useRefetchOnFiltersChange({
  limit,
  refetch,
  selectedFilters,
  setHasMoreItems,
  variables,
  previousVariables,
  apiNames
}: {
  limit: number;
  refetch: ReturnType<typeof useQuery>['refetch'];
  selectedFilters: SelectedFilter[];
  setHasMoreItems: (hasMoreItems: boolean) => void;
  variables: Record<string, any>;
  previousVariables?: Record<string, any>;
  apiNames: Record<string, string[]>;
}) {
  const [loading, setLoading] = useState(false);

  const memoizedVariables = JSON.stringify(selectedFilters);

  const flattenedApiNames = Object.values(apiNames).flat();

  useEffect(() => {
    if (!selectedFilters.length && !Object.keys(previousVariables || {}).some(key => flattenedApiNames.includes(key))) {
      return;
    }

    setLoading(true);

    refetch(variables)?.then(data => {
      setLoading(false);
      if (Object.values(data)?.[0]?.length < limit) {
        setHasMoreItems(false);
        return;
      }
      setHasMoreItems(true);
    });
  }, [memoizedVariables]);

  return loading;
}

type DefaultVariables = {
  refetch: ReturnType<typeof useQuery>['refetch'];
  defaultVariables: Record<string, any> & { limit: number };
  setHasMoreItems: (hasMoreItems: boolean) => void;
  previousVariables?: Record<string, any>;
};

export function useRefetchPetsOnFiltersChange({ refetch, defaultVariables, setHasMoreItems, previousVariables }: DefaultVariables) {
  const selectedFilters = useReactiveVar(vars.selectedPetsFilters);
  const getFilter = (filter: PETS_FILTER_TYPES) => getPetFilter(selectedFilters, filter);

  const dayCareFilterType = getFilter(PETS_FILTER_TYPES.DAYCARE);
  const dayCareFilter = getPetsMoreOptions(dayCareFilterType, PETS_MORE_OPTIONS_TYPES.DAY_CARE);
  const dayCareDayFilter = getPetsMoreOptions(dayCareFilterType, PETS_MORE_OPTIONS_TYPES.DAY);

  const breedFilterType = getFilter(PETS_FILTER_TYPES.BREED);
  const breedFilter = getPetsMoreOptions(breedFilterType, PETS_MORE_OPTIONS_TYPES.BREED);

  const ageFilterType = getFilter(PETS_FILTER_TYPES.AGE);
  const ageFilterFrom = getPetsMoreOptions(ageFilterType, PETS_MORE_OPTIONS_TYPES.FROM_NUMBER);
  const ageFilterTo = getPetsMoreOptions(ageFilterType, PETS_MORE_OPTIONS_TYPES.TO_NUMBER);

  const birthdateFilterType = getFilter(PETS_FILTER_TYPES.BIRTHDATE);
  const birthdateFilter = getPetsMoreOptions(birthdateFilterType, PETS_MORE_OPTIONS_TYPES.BIRTHDATE) as 'today' | 'inAWeek' | 'inThreeDays';

  const nuetrudedFilterType = getFilter(PETS_FILTER_TYPES.NEUTERING);
  const nuetrudedFilter = getPetsMoreOptions(nuetrudedFilterType, PETS_MORE_OPTIONS_TYPES.NEUTERED);

  const vaccinationFilterType = getFilter(PETS_FILTER_TYPES.VACCINATION);
  const vaccinationFilter = getPetsMoreOptions(vaccinationFilterType, PETS_MORE_OPTIONS_TYPES.VACC_TYPE, { name: true });
  const vaccinationDateFilter = getPetsMoreOptions(vaccinationFilterType, PETS_MORE_OPTIONS_TYPES.DATE);
  const vaccinationDueDateFilter = getPetsMoreOptions(vaccinationFilterType, PETS_MORE_OPTIONS_TYPES.DUE);

  const suppFilter = getFilter(PETS_FILTER_TYPES.TREATMENT);
  const suppFilterProductKind = getPetsMoreOptions(suppFilter, PETS_MORE_OPTIONS_TYPES.TREATMENT_TYPE, { name: true });
  const suppFilterProduct = getPetsMoreOptions(suppFilter, PETS_MORE_OPTIONS_TYPES.TREATMENT_NAME, { name: true });
  const suppFilterDate = getPetsMoreOptions(suppFilter, PETS_MORE_OPTIONS_TYPES.DATE);
  const suppFilterDueDate = getPetsMoreOptions(suppFilter, PETS_MORE_OPTIONS_TYPES.DUE);

  const weightFilterType = getFilter(PETS_FILTER_TYPES.WEIGHT);
  const weightFilterFrom = getPetsMoreOptions(weightFilterType, PETS_MORE_OPTIONS_TYPES.FROM_NUMBER);
  const weightFilterTo = getPetsMoreOptions(weightFilterType, PETS_MORE_OPTIONS_TYPES.TO_NUMBER);
  // search
  const searchFilter = getFilter(PETS_FILTER_TYPES.SEARCH);
  const searchFilterValue = getPetsMoreOptions(searchFilter, PETS_MORE_OPTIONS_TYPES.SEARCH);
  // tags
  const tagsFilter = getFilter(PETS_FILTER_TYPES.TAGS);
  const quickTagsFilter = getFilter(PETS_FILTER_TYPES.QUICK_TAGS);
  const tagsFilterValue = getPetsMoreOptions(tagsFilter, PETS_MORE_OPTIONS_TYPES.TAGS, { name: true });
  const quickTagsFilterValue = getPetsMoreOptions(quickTagsFilter, PETS_MORE_OPTIONS_TYPES.TAGS, { name: true });
  const allTags = [...new Set([...(tagsFilterValue || []), ...(quickTagsFilterValue || [])])];
  const fragmentVariables =
    selectedFilters
      ?.map(({ value }) => requiredPetsFiltersData[value])
      .flat()
      ?.reduce((acc, curr) => ({ ...acc, [curr]: true }), {} as Record<string, boolean>) || {};

  const variables = {
    ...defaultVariables,
    branchPetRecordItem_BranchId: dayCareFilter || null,
    branchPetRecordItem_days: dayCareDayFilter || null,
    branchPetRecordItem_type: dayCareFilter || dayCareDayFilter ? ['DAYCARE'] : null,
    pet_BreedId: breedFilter || null,
    pet_birthdate_from: getPreviousYearDate(ageToBirthdate(ageFilterTo as string)) || null,
    pet_birthdate_to: ageToBirthdate(ageFilterFrom as string) || null,
    pet_birthdate_month_from: getBirthdateDateFromTo(birthdateFilter)?.monthFrom || null,
    pet_birthdate_month_to: getBirthdateDateFromTo(birthdateFilter)?.monthTo || null,
    pet_birthdate_day_from: getBirthdateDateFromTo(birthdateFilter)?.dayFrom || null,
    pet_birthdate_day_to: getBirthdateDateFromTo(birthdateFilter)?.dayTo || null,
    petRecord_neutred: nuetrudedFilter === 'true' ? true : nuetrudedFilter === 'false' ? false : null,
    vaccRecordType_name: vaccinationFilter,
    vaccRecord_nextdate_from: String(vaccinationDueDateFilter) === 'true' ? dateInUTC(vaccinationDateFilter as string) : null,
    vaccRecord_nextdate_to: String(vaccinationDueDateFilter) === 'false' ? dateInUTC(vaccinationDateFilter as string) : null,
    treatment_productKind_name: suppFilterProductKind || null,
    treatment_product_name: suppFilterProduct || null,
    treatment_nextdate_from: String(suppFilterDueDate) === 'true' ? dateInUTC(suppFilterDate as string) : null,
    treatment_nextdate_to: String(suppFilterDueDate) === 'false' ? dateInUTC(suppFilterDate as string) : null,
    measurements_weight_from: weightFilterFrom || null,
    measurements_weight_to: weightFilterTo || null,
    pet_name: searchFilterValue ? [searchFilterValue] : null,
    branchPetRecordTag_name: allTags || null,
    ...fragmentVariables,
    requisite_queries: Object.values(PETS_FILTER_TYPES)
      .map(filter => {
        const filterType = getFilter(filter);
        return filterType?.requisite === 'true' ? PETS_FILTERS_QUERIES[filter] : null;
      })
      .filter(Boolean)
      .flat(),
    alternative_queries: Object.values(PETS_FILTER_TYPES)
      .map(filter => {
        const filterType = getFilter(filter);
        return filterType?.requisite === 'false' ? PETS_FILTERS_QUERIES[filter] : null;
      })
      .filter(Boolean)
      .flat()
  };

  const apiNames = PETS_FILTERS_QUERIES;

  return useRefetchOnFiltersChange({ refetch, variables, setHasMoreItems, selectedFilters, limit: defaultVariables.limit, previousVariables, apiNames });
}

export function useRefetchAppointmentsOnFiltersChange({ refetch, defaultVariables, setHasMoreItems, previousVariables }: DefaultVariables) {
  const selectedFilters = useReactiveVar(vars.selectedBookingsFilters);
  const getFilter = (filter: BOOKINGS_FILTER_TYPES) => getBookingFilter(selectedFilters, filter);

  // booking tags
  const tagsFilter = getFilter(BOOKINGS_FILTER_TYPES.BOOKING_TAGS);
  const quickTagsFilter = getFilter(BOOKINGS_FILTER_TYPES.BOOKING_QUICK_TAGS);
  const tagsFilterValue = getBookingMoreOptions(tagsFilter, BOOKINGS_MORE_OPTIONS_TYPES.BOOKING_TAGS, { name: true });
  const quickTagsFilterValue = getBookingMoreOptions(quickTagsFilter, BOOKINGS_MORE_OPTIONS_TYPES.BOOKING_TAGS, { name: true });
  const allTags = [...new Set([...(tagsFilterValue || []), ...(quickTagsFilterValue || [])])];

  // pet tags
  const petTagsFilter = getFilter(BOOKINGS_FILTER_TYPES.BOOKING_PETS_TAGS);
  const petTagsFilterValue = getBookingMoreOptions(petTagsFilter, BOOKINGS_MORE_OPTIONS_TYPES.BOOKING_PETS_TAGS, { name: true });
  const allPetTags = [...new Set([...(petTagsFilterValue || [])])];

  // Bus user assigned
  const busUserAssignedFilter = getFilter(BOOKINGS_FILTER_TYPES.BOOKING_BUS_USERS);
  const busUserAssignedFilterValue = getBookingMoreOptions(busUserAssignedFilter, BOOKINGS_MORE_OPTIONS_TYPES.BOOKING_BUS_USERS, { name: true });

  // Product name

  const productFilter = getFilter(BOOKINGS_FILTER_TYPES.BOOKING_PRODUCT_NAME);
  const productFilterValue = getBookingMoreOptions(productFilter, BOOKINGS_MORE_OPTIONS_TYPES.BOOKING_PRODUCT_NAME, { name: true });

  // Search
  const searchFilter = getFilter(BOOKINGS_FILTER_TYPES.BOOKING_SEARCH);
  const searchFilterValue = getBookingMoreOptions(searchFilter, BOOKINGS_MORE_OPTIONS_TYPES.BOOKING_SEARCH);

  // Weekday
  const weekdayFilter = getFilter(BOOKINGS_FILTER_TYPES.BOOKING_WEEKDAY);
  const weekdayFilterValue = getBookingMoreOptions(weekdayFilter, BOOKINGS_MORE_OPTIONS_TYPES.BOOKING_WEEKDAY);

  const variables = {
    ...defaultVariables,
    branchAppointmentTag_name: allTags?.length ? allTags : null,
    appointment_branchPetRecordTag_name: allPetTags?.length ? allPetTags : null,
    appointment_busUserAssigned_name: busUserAssignedFilterValue?.length ? busUserAssignedFilterValue : null,
    appointment_product_name: productFilterValue?.length ? productFilterValue : null,
    appointment_search: searchFilterValue?.length ? searchFilterValue : null,
    appointment_timestamp_day_of_week: weekdayFilterValue?.length ? weekdayFilterValue : null,
    requisite_queries: Object.values(BOOKINGS_FILTER_TYPES)
      .map(filter => {
        const filterType = getFilter(filter);
        return filterType?.requisite === 'true' ? BOOKINGS_FILTERS_QUERIES[filter] : null;
      })
      .filter(Boolean)
      .flat(),
    alternative_queries: Object.values(BOOKINGS_FILTER_TYPES)
      .map(filter => {
        const filterType = getFilter(filter);
        return filterType?.requisite === 'false' ? BOOKINGS_FILTERS_QUERIES[filter] : null;
      })
      .filter(Boolean)
      .flat()
  };

  const apiNames = BOOKINGS_FILTERS_QUERIES;

  return useRefetchOnFiltersChange({ refetch, variables, setHasMoreItems, selectedFilters, limit: defaultVariables.limit, previousVariables, apiNames });
}

export function useRefetchProductsOnFiltersChange({ refetch, defaultVariables, setHasMoreItems, previousVariables }: DefaultVariables) {
  const selectedFilters = useReactiveVar(vars.selectedProductsFilters);
  const getFilter = (filter: PRODUCTS_FILTER_TYPES) => getProductFilter(selectedFilters, filter);

  // search
  const searchFilter = getFilter(PRODUCTS_FILTER_TYPES.SEARCH);
  const searchFilterValue = getProductMoreOptions(searchFilter, PRODUCTS_MORE_OPTIONS_TYPES.SEARCH);

  const variables = {
    ...defaultVariables,
    name: searchFilterValue ? [searchFilterValue] : null
  };

  const apiNames = PRODUCTS_FILTERS_QUERIES;

  return useRefetchOnFiltersChange({ refetch, variables, setHasMoreItems, selectedFilters, limit: defaultVariables.limit, previousVariables, apiNames });
}

export function useRefetchSubscriptionsOnFiltersChange({ refetch, defaultVariables, setHasMoreItems, previousVariables }: DefaultVariables) {
  const selectedFilters = useReactiveVar(vars.selectedSubscriptionsFilters);
  const getFilter = (filter: SUBSCRIPTIONS_FILTER_TYPES) => getSubscriptionFilter(selectedFilters, filter);

  // search
  const searchFilter = getFilter(SUBSCRIPTIONS_FILTER_TYPES.SUBSCRIPTION_SEARCH);
  const searchFilterValue = getSubscriptionMoreOptions(searchFilter, SUBSCRIPTIONS_MORE_OPTIONS_TYPES.SUBSCRIPTION_SEARCH);

  // product name
  const productNameFilter = getFilter(SUBSCRIPTIONS_FILTER_TYPES.SUBSCRIPTION_PRODUCT_NAME);
  const productNameFilterValue = getSubscriptionMoreOptions(productNameFilter, SUBSCRIPTIONS_MORE_OPTIONS_TYPES.SUBSCRIPTION_PRODUCT_NAME, { name: true });

  // trial end
  const trialEndFilter = getFilter(SUBSCRIPTIONS_FILTER_TYPES.SUBSCRIPTION_TRIAL_END);
  const trialActiveFilterValue = getSubscriptionMoreOptions(trialEndFilter, SUBSCRIPTIONS_MORE_OPTIONS_TYPES.SUBSCRIPTION_TRIAL_ACTIVE);
  const trialEndFromFilterValue = getSubscriptionMoreOptions(trialEndFilter, SUBSCRIPTIONS_MORE_OPTIONS_TYPES.SUBSCRIPTION_TRIAL_END_FROM);
  const trialEndToFilterValue = getSubscriptionMoreOptions(trialEndFilter, SUBSCRIPTIONS_MORE_OPTIONS_TYPES.SUBSCRIPTION_TRIAL_END_TO);
  const trialEndedValue = trialActiveFilterValue === 'false' ? true : trialActiveFilterValue === 'true' ? false : null;
  const trialEndFromValue = trialActiveFilterValue === 'custom' && trialEndToFilterValue ? dateInUTC(trialEndFromFilterValue as string) : null;
  const trialEndToValue = trialActiveFilterValue === 'custom' && trialEndFromFilterValue ? dateInUTC(trialEndToFilterValue as string) : null;

  const variables = {
    ...defaultVariables,
    subscription_search: searchFilterValue ? [searchFilterValue] : null,
    subscription_product_name: productNameFilterValue?.length ? productNameFilterValue : null,
    subscription_trial_end_from: trialEndFromValue,
    subscription_trial_end_to: trialEndToValue,
    subscription_trial_ended: trialEndedValue,
    requisite_queries: Object.values(SUBSCRIPTIONS_FILTER_TYPES)
      .map(filter => {
        const filterType = getFilter(filter);
        return filterType?.requisite === 'true' ? SUBSCRIPTIONS_FILTERS_QUERIES[filter] : null;
      })
      .filter(Boolean)
      .flat(),
    alternative_queries: Object.values(SUBSCRIPTIONS_FILTER_TYPES)
      .map(filter => {
        const filterType = getFilter(filter);
        return filterType?.requisite === 'false' ? SUBSCRIPTIONS_FILTERS_QUERIES[filter] : null;
      })
      .filter(Boolean)
      .flat()
  };

  const apiNames = SUBSCRIPTIONS_FILTERS_QUERIES;

  return useRefetchOnFiltersChange({ refetch, variables, setHasMoreItems, selectedFilters, limit: defaultVariables.limit, previousVariables, apiNames });
}
// tags, quicktags, search
export function useRefetchChatsOnFiltersChange({ refetch, defaultVariables, setHasMoreItems, previousVariables }: DefaultVariables) {
  const selectedFilters = useReactiveVar(vars.selectedChatsFilters);
  const getFilter = (filter: CHATS_FILTER_TYPES) => getChatFilter(selectedFilters, filter);

  // tags
  const tagsFilter = getFilter(CHATS_FILTER_TYPES.CHAT_TAGS);
  const quickTagsFilter = getFilter(CHATS_FILTER_TYPES.CHAT_QUICK_TAGS);
  const tagsFilterValue = getChatMoreOptions(tagsFilter, CHATS_MORE_OPTIONS_TYPES.CHAT_TAGS, { name: true });
  const quickTagsFilterValue = getChatMoreOptions(quickTagsFilter, CHATS_MORE_OPTIONS_TYPES.CHAT_TAGS, { name: true });
  const allTags = [...new Set([...(tagsFilterValue || []), ...(quickTagsFilterValue || [])])];

  // search
  const searchFilter = getFilter(CHATS_FILTER_TYPES.CHAT_SEARCH);
  const searchFilterValue = getChatMoreOptions(searchFilter, CHATS_MORE_OPTIONS_TYPES.CHAT_SEARCH);

  const variables = {
    ...defaultVariables,
    chat_branchAppUserTag_name: allTags?.length ? allTags : null,
    chat_search: searchFilterValue?.length ? searchFilterValue : null,
    requisite_queries: [
      ...(defaultVariables.requisite_queries || []),
      ...(Object.values(CHATS_FILTER_TYPES)
        .map(filter => {
          const filterType = getFilter(filter);
          return filterType?.requisite === 'true' ? CHATS_FILTERS_QUERIES[filter] : null;
        })
        .filter(Boolean)
        .flat() || [])
    ],
    alternative_queries: [
      ...(defaultVariables.alternative_queries || []),
      ...(Object.values(CHATS_FILTER_TYPES)
        .map(filter => {
          const filterType = getFilter(filter);
          return filterType?.requisite === 'false' ? CHATS_FILTERS_QUERIES[filter] : null;
        })
        .filter(Boolean)
        .flat() || [])
    ]
  };

  const apiNames = CHATS_FILTERS_QUERIES;

  return useRefetchOnFiltersChange({ refetch, variables, setHasMoreItems, selectedFilters, limit: defaultVariables.limit, previousVariables, apiNames });
}
