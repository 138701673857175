import React, { FC, useCallback, useRef, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Controller, useForm } from 'react-hook-form';
import { ModalBody, ModalFooter } from '../../../../components/Modal/styled';
import { FormButtonsContainer, FormLabel, FormSubmitButton, InputsWrapper, WideInputGroup, FormInput, SectionLabel, FormSelect } from '../../../../components/Shared/Forms/Forms';
import { Container } from '../../../../components/Shared/Shared';
import { GetBusUserProfile, NoteAdd, NoteEdit } from '../../../../queries';
import { ActionBtn } from '../../../Store/styled';
import useIcons from '../../../../hooks/useIcons';
import { sentenceCase } from 'sentence-case';
import NotePhotos from '../NotePhotos';
import usePhotoUpload, { useDeletePhotos } from '../../../../hooks/usePhotoUpload';
import ModalDialog from '../../../../components/Modal/ModalDialog';
import { BusUserProfile } from '../../../../components/Profile/types';
import { FlexColumnCenterContainer } from '../../../../components/Pets/styled';
import NotesMarkupEditor from '../NotesMarkupEditor';

const POO_SCALES = ['normal', 'constipated', 'mild_diarrhoea', 'sever_diarrhoea'];

export enum NOTE_ACTION_TYPES {
  ADD = 'ADD',
  EDIT = 'EDIT'
}

const SittingReportNoteModal: FC<{
  selectedReport: Record<string, any>;
  selectedNote?: Record<string, any>;
  refetchNotes?: ReturnType<typeof useQuery>['refetch'];
}> = ({ selectedReport, selectedNote, refetchNotes }) => {
  const { data: { getBusUserProfile: { Branch: { BusUsers = [] } = {} } = {} } = {} } = useQuery<{
    getBusUserProfile: BusUserProfile;
  }>(GetBusUserProfile);
  const [handleAddNote, { loading: loadingAddNote, error: errorNoteAdd }] = useMutation(NoteAdd);
  const [handleEditNote, { loading: loadingEditNote, error: errorNoteEdit }] = useMutation(NoteEdit);
  const noteIdRef = useRef<string>('');
  const photosRef = useRef<Record<string, any>>({});

  const uploadPhotos = usePhotoUpload();
  const [_, { loading: loadingUploadPhotos, error: errorUploadPhotos }] = uploadPhotos;
  const deletePhotos = useDeletePhotos();

  const { control, handleSubmit } = useForm();

  const loading = loadingAddNote || loadingEditNote || loadingUploadPhotos;
  const error = !!errorNoteAdd?.message || !!errorNoteEdit?.message || !!errorUploadPhotos;

  const handleSave = handleSubmit(async form => {
    if (selectedNote) {
      noteIdRef.current = selectedNote.id;
    }

    if (!selectedNote) {
      const { data: { noteAdd: addedNote = {} } = {} } = await handleAddNote({
        variables: {
          body: form,
          timestamp: new Date(),
          sittingReportId: selectedReport.id
        }
      });
      noteIdRef.current = addedNote.id;
    }
    const photos = await photosRef.current?.getPhotos();
    const attachments = await photosRef.current?.getAttachments();

    await handleEditNote({
      variables: {
        id: noteIdRef.current,
        body: {
          ...form,
          photos: {
            primary: photos
          },
          attachments: {
            primary: attachments
          }
        },
        timestamp: new Date()
      }
    });

    ModalDialog.closeModal();
    refetchNotes?.();
  });

  return (
    <>
      <ModalBody>
        <Container width={500}>
          <NotePhotos uploadPhotos={uploadPhotos} deletePhotos={deletePhotos} ref={photosRef} selectedNote={selectedNote} noteIdRef={noteIdRef} />
          <InputsWrapper>
            <FlexColumnCenterContainer>
              <SectionLabel>Sitting Summary</SectionLabel>
              <FormLabel>Sitter</FormLabel>
            </FlexColumnCenterContainer>
            <WideInputGroup>
              <Controller
                render={({ onChange, value }) => (
                  <FormSelect
                    height={48}
                    fontSize={16}
                    name={'daySummary.sitter.details'}
                    onChange={e => {
                      onChange(e.target.value);
                    }}
                    value={value || ''}
                  >
                    <option value={''}>-- No Staff Member --</option>
                    {BusUsers.map(({ name }) => (
                      <option key={name} value={name}>
                        {name}
                      </option>
                    ))}
                  </FormSelect>
                )}
                control={control}
                name={'daySummary.sitter.details'}
                defaultValue={selectedNote?.body?.daySummary?.sitter?.details || ''}
              />
            </WideInputGroup>
            <WideInputGroup>
              <FormLabel>Walks</FormLabel>
              <Controller as={<FormInput type={'number'} />} name={`daySummary.walks.details`} control={control} defaultValue={selectedNote?.body?.daySummary?.walks?.details || ''} />
            </WideInputGroup>

            <WideInputGroup>
              <FormLabel>Meals</FormLabel>
              <Controller as={<FormInput type={'number'} />} name={`routine.meals.details`} control={control} defaultValue={selectedNote?.body?.routine?.meals?.details || ''} />
            </WideInputGroup>

            <WideInputGroup>
              <FormLabel>Poos</FormLabel>
              <Controller as={<FormInput type={'number'} />} name={`routine.poos.details`} control={control} defaultValue={selectedNote?.body?.routine?.poos?.details || ''} />
            </WideInputGroup>

            <WideInputGroup>
              <FormLabel>Poo Scale</FormLabel>
              <Controller
                render={({ onChange, value }) => (
                  <FormSelect name={'daySummary.pooScale.details'} onChange={e => onChange(e.target.value)} value={value} height={48} fontSize={16}>
                    {POO_SCALES.map(scale => (
                      <option key={scale} value={scale}>
                        {sentenceCase(scale)}
                      </option>
                    ))}
                  </FormSelect>
                )}
                control={control}
                name={'daySummary.pooScale.details'}
                defaultValue={selectedNote?.body?.daySummary?.pooScale?.details || ''}
              />
            </WideInputGroup>

            <WideInputGroup>
              <FormLabel>Sleep/Rest</FormLabel>
              <Controller as={<FormInput type={'number'} />} name={`routine.sleep.details`} control={control} defaultValue={selectedNote?.body?.routine?.sleep?.details || ''} />
            </WideInputGroup>

            <WideInputGroup>
              <FormLabel>Playmates</FormLabel>
              <Controller as={<FormInput />} name={`daySummary.playmates.details`} control={control} defaultValue={selectedNote?.body?.daySummary?.playmates?.details || ''} />
            </WideInputGroup>
          </InputsWrapper>

          <InputsWrapper>
            <SectionLabel>Notes</SectionLabel>
            <WideInputGroup>
              <FormLabel>Notes</FormLabel>
              <NotesMarkupEditor control={control} defaultValue={selectedNote?.body?.notes?.notes} name="notes.notes" />
            </WideInputGroup>
          </InputsWrapper>
        </Container>
      </ModalBody>
      <ModalFooter>
        <FormButtonsContainer>
          <FormSubmitButton error={error} loading={loading} onClick={handleSave}>
            Save note
          </FormSubmitButton>
        </FormButtonsContainer>
      </ModalFooter>
    </>
  );
};

const SittingReportNoteAction: FC<{
  type: NOTE_ACTION_TYPES;
  note?: Record<string, any>;
  selectedReport: Record<string, any>;
  refetchNotes?: ReturnType<typeof useQuery>['refetch'];
  autoOpenModal?: boolean;
}> = ({ type, note, selectedReport, refetchNotes, autoOpenModal }) => {
  const icons = useIcons();
  const icon = type === NOTE_ACTION_TYPES.ADD ? icons.add.childImageSharp.gatsbyImageData.images.fallback.src : icons.edit.childImageSharp.gatsbyImageData.images.fallback.src;

  const showModal = useCallback(
    () =>
      ModalDialog.openModal({
        content: () => <SittingReportNoteModal selectedNote={note} selectedReport={selectedReport} refetchNotes={refetchNotes} />,
        title: `${sentenceCase(type)} note`
      }),
    [note, selectedReport, refetchNotes, type]
  );

  useEffect(() => {
    if (autoOpenModal) {
      showModal();
    }
  }, [autoOpenModal, showModal]);

  return <ActionBtn bgImage={icon} onClick={showModal} />;
};

export default SittingReportNoteAction;
