import { GatsbyImage } from 'gatsby-plugin-image';
import styled, { css } from 'styled-components';
import Colors from '../../../../Colors';

export const MedicalNotesContainer = styled.div`
  padding-top: 8px;
  padding-right: 8px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
`;

export const MedicalNoteContainer = styled.div`
  padding: 16px;
  border-radius: 8px;
  &:hover {
    background-color: #ededed;
  }
  border: 2px solid #e9e9e9;
  margin-top: 8px;
`;

export const TempBPMContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50%;
  height: 30px;
`;

export const TempBPMIcon = styled(GatsbyImage)`
  border-radius: 15px;
  margin-right: 4px;
`;

export const MedicalCaseDetailsContainer = styled.div`
  overflow-y: scroll;
  padding-top: 8px;
  padding-right: 8px;
  width: 100%;
`;
export const ColorCircle = styled.div<{ color: string }>`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${props => props.color};
`;
export const TabContainer = styled.div`
  justify-content: space-between;
  align-items: center;
  display: flex;
  background-color: ${Colors.grey};
  border-radius: 20px;
  width: 100%;
`;

export const TabButton = styled.div<{ active: boolean; fontSize?: number; isFirst?: boolean; isLast?: boolean }>`
  background-color: ${({ active }) => (active ? Colors.black : Colors.placeholder)};
  color: ${({ active }) => (active ? Colors.white : Colors.black)};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  user-select: none;
  flex: 1;
  ${({ isFirst }) =>
    isFirst &&
    css`
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    `}
  ${({ isLast }) =>
    isLast &&
    css`
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    `}
  transition: all 0.1s ease-out;
  font-weight: bold;
  ${({ fontSize }) =>
    fontSize &&
    css`
      font-size: ${fontSize}px;
    `}

  @media (max-width: 768px) {
    width: auto;
    flex: 1;
  }
`;

export const OverdueTitle = styled.div<{ orange?: boolean }>`
  margin: 10px 0 0 1px;
  font-size: 14px;
  font-weight: bold;
  color: ${props => (props.orange ? Colors.orangeBorder : Colors.primary)};
`;

export const DeselectReportButton = styled.div<{ bgImg?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  border-radius: 50%;
  ${({ bgImg }) =>
    bgImg &&
    css`
      background-image: url(${bgImg});
      background-size: cover;
    `}
  cursor: pointer;
`;

export const AdministeredRecordContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const PetAdministeredRecordContainer = styled.div`
  display: flex;
  gap: 12px;
  overflow-x: scroll;
  padding: 8px 0;
  min-height: 100px;
  height: 100px;
`;

export const PrintPetAdministeredRecordContainer = styled.div<{ selectedSize: { width: string; height: string } }>`
  display: flex;
  gap: 12px;
  overflow-x: scroll;
  ${({ selectedSize }) =>
    css`
      min-height: ${selectedSize.height};
      height: ${selectedSize.height};
      padding: 20px 8px;
    `}
  gap: 20px;
  flex-direction: column;
`;

export const PrintSplitter = styled.div`
  width: 100%;
  min-height: 1px;
  background-color: ${Colors.black};
  margin: 20px 0;
`;

export const NoteNumbersSub = styled.sub`
  height: 1ch;
  font-size: 10px;
  margin-left: 2px;
  margin-bottom: 1ch;
`;
