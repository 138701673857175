import { useQuery, useReactiveVar } from '@apollo/client';
import React, { Fragment, useCallback } from 'react';
import ReactSelect from 'react-select';
import Colors from '../../Colors';
import useIcons from '../../hooks/useIcons';
import useMediaQuery from '../../hooks/useMediaQuery';
import useReduceAppointments from '../../hooks/useReduceAppointments';
import { GetBranchAppointments, GetBranchNotes, GetBranchTagCategoriesWithTags } from '../../queries';
import { vars } from '../../reactive';
import { addDrawerBar, setDrawerBar } from '../../reactive/actions';
import { GroupBookingsType } from '../../reactive/bookings';
import { toReadableDate } from '../../utils/dates';
import { BOOKING_STATUS_TYPES, BOOKING_TYPE, Booking, BookingOrder } from '../../views/Bookings/types';
import { BranchAppointmentTag, BranchPetRecordTag } from '../../views/Store/BranchTags/types';
import { ActionBtn } from '../../views/Store/styled';
import { TabGroupContainer } from '../Calendar/Modals/styled';
import { MobileCloseButton } from '../DrawerBar/DrawerButtons';
import { Divider, DrawerContainer, DrawerContent, DrawerFooter, DrawerLabel, DrawerList, DrawerSection, DrawerSubLabel } from '../DrawerBar/styled';
import { DRAWER_IDS } from '../DrawerBar/types';
import PetProfilesList from '../Pets/PetProfilesList';
import { Pet } from '../Pets/types';
import { Note } from '../Shared/BranchNotes/types';
import { OPTION_DROPDOWN_MENU_BUTTON_TYPES, OPTION_DROPDOWN_MENU_OVERLAY_POSITIONS } from '../Shared/Menus/OptionDropdown/types';
import { FlexRow, ToggleSwitch } from '../Shared/Shared';
import { ActivityIndicator, LoadingOverlay } from '../Shared/Spinner';
import TabGroup from '../Shared/TabGroup';
import BookingDrawerButtons from './BookingDrawerButtons';
import {
  BookingDrawerButtonsContainer,
  GroupDrawerContentContainer,
  GroupFilterSelectContainer,
  GroupFilterTitle,
  GroupFiltersContainer,
  GroupFiltersWrapper,
  GroupPetDetails,
  TagLoaderContainer
} from './styled';

const GroupBookingsDrawer = ({ data: allGroupOrders }: { data: BookingOrder[] }) => {
  const drawerBar = vars.drawerBars().find(drawerBar => drawerBar.drawerId === DRAWER_IDS.GROUP_BOOKINGS_DRAWER)!;
  const groupBookingsFilters = useReactiveVar(vars.groupBookingsFilters);
  const { type: groupType, showNotes, showAddress, filtersActive } = groupBookingsFilters;
  const selectedTagsGroupType = groupType && [GroupBookingsType.TAGS].includes(groupType);
  const icons = useIcons();
  const filterIcon = icons?.groupFilters?.childImageSharp?.gatsbyImageData?.images?.fallback?.src;
  const { mobile } = useMediaQuery({ mobile: true });

  const { data: { getBranchAppointments: bookingOrderAppointments = [] } = {}, loading: loadingAppointments } = useQuery<{ getBranchAppointments: BookingOrder }>(GetBranchAppointments, {
    variables: {
      appointment_id: allGroupOrders.flat().map(booking => booking.id),
      requisite_queries: ['appointment_id'],
      alternative_queries: [],
      offset: 0,
      limit: 1000,
      status: [BOOKING_STATUS_TYPES.CONFIRMED, BOOKING_STATUS_TYPES.CANCELED, BOOKING_STATUS_TYPES.REQUESTED],
      booking_type: [BOOKING_TYPE.SLOT, BOOKING_TYPE.MULTI_SLOT, BOOKING_TYPE.MULTI_DAY]
    },
    fetchPolicy: 'cache-and-network'
  });

  const { data: { getBranchTagCategories: tagCategories = [] } = {}, loading: loadingTagCategories } = useQuery<{
    getBranchTagCategories: { id: string; name: string; BranchAppointmentTags: BranchAppointmentTag[]; BranchPetRecordTags: BranchPetRecordTag[] }[];
  }>(GetBranchTagCategoriesWithTags, {
    skip: !bookingOrderAppointments?.length || !selectedTagsGroupType,
    variables: {
      BranchAppointmentTagId: bookingOrderAppointments?.map(booking => booking.BranchAppointmentTags?.map(tag => tag?.id)).flat(),
      BranchPetRecordTagId: bookingOrderAppointments?.map(booking => booking.PetRecord.Pet.PetRecord.BranchPetRecordTags?.map(tag => tag?.id)).flat()
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      vars.groupBookingsFilters({
        ...groupBookingsFilters,
        tagCategoryId: data?.getBranchTagCategories?.[0]?.id || null
      });
    }
  });

  const { data: { getBranchNotes: branchNotes = [] } = {} } = useQuery<{ getBranchNotes: Note[] }>(GetBranchNotes, {
    variables: {
      OrderId: bookingOrderAppointments?.map(booking => booking.OrderItem.Order.id),
      PetRecordId: bookingOrderAppointments?.map(booking => booking.PetRecord.id)
    },
    fetchPolicy: 'cache-and-network',
    skip: !bookingOrderAppointments?.length || !showNotes
  });

  const ordersNotes = branchNotes.filter(note => !!note.OrderId);
  const petsNotes = branchNotes.filter(note => !!note.PetRecordId);

  const selectedCategory = tagCategories.find(tagCategory => tagCategory.id === groupBookingsFilters.tagCategoryId);

  const selectedCategoryBranchAppointmentTags = selectedCategory?.BranchAppointmentTags?.map(tag => tag?.id);

  const selectedCategoryBranchPetRecordTags = selectedCategory?.BranchPetRecordTags?.map(tag => tag?.id);

  const loading = loadingAppointments;

  const orders = useReduceAppointments(bookingOrderAppointments, {
    uniqueByOrderIdAndTimestamp: true
  });

  const firstBookingOrder = orders[0];

  const orderItem = firstBookingOrder?.[0]?.OrderItem;
  const product = orderItem?.item;

  const pets = orders.map(bookings => bookings?.map(booking => booking.PetRecord.Pet)).flat();

  const isMultiDay = !!firstBookingOrder?.[0]?.timestamp_until;

  const allAppointmentsFromAllOrders = orders.flat();

  const allAppointmentsTags = orders?.flatMap(booking => booking.flatMap(appointment => appointment.BranchAppointmentTags));
  const allPetRecordTags = orders?.flatMap(booking => booking.flatMap(appointment => appointment.PetRecord.Pet.PetRecord.BranchPetRecordTags));
  const filteredAppointmentsTags = selectedCategoryBranchAppointmentTags ? allAppointmentsTags?.filter(tag => selectedCategoryBranchAppointmentTags.includes(tag?.id)) : allAppointmentsTags;
  const filteredPetRecordTags = selectedCategoryBranchPetRecordTags ? allPetRecordTags?.filter(tag => selectedCategoryBranchPetRecordTags.includes(tag?.id)) : allPetRecordTags;
  const allAppointmentsTagsUniqueByTagId = filteredAppointmentsTags.filter((tag, index, self) => self.findIndex(t => t?.id === tag?.id) === index);
  const allPetRecordTagsUniqueByTagId = filteredPetRecordTags.filter((tag, index, self) => self.findIndex(t => t?.id === tag?.id) === index);

  const untaggedAppointments = allAppointmentsFromAllOrders.filter(appointment => {
    const tagsIds = appointment.BranchAppointmentTags?.map(tag => tag?.id);

    return allAppointmentsTagsUniqueByTagId?.every(tag => !tagsIds?.includes(tag?.id));
  });

  const untaggedAppointmentsAndPets = untaggedAppointments.filter(appointment => {
    const petTagsIds = appointment.PetRecord.Pet.PetRecord.BranchPetRecordTags?.map(tag => tag?.id);

    return allPetRecordTagsUniqueByTagId?.every(tag => !petTagsIds?.includes(tag?.id));
  });

  const appointmentTagsWithCount = allAppointmentsTagsUniqueByTagId.map(tag => {
    const appointmentsWithThisTag = allAppointmentsFromAllOrders.filter(appointment => appointment.BranchAppointmentTags?.find(t => t?.id === tag?.id));
    const tagCount = filteredAppointmentsTags.filter(t => t?.id === tag?.id).length;
    return { tag, tagCount, appointmentsWithThisTag };
  });

  const appointmentsStaff = bookingOrderAppointments.flatMap(appointment => appointment.BusUsers);
  const appointmentsStaffUnique = appointmentsStaff.filter((staff, index, self) => self.findIndex(s => s?.id === staff?.id) === index);
  const appointementsSplitByStaff = appointmentsStaffUnique.map(staff => {
    const appointmentsWithThisStaff = allAppointmentsFromAllOrders.filter(appointment => appointment.BusUsers?.find(s => s?.id === staff?.id));
    const pets = appointmentsWithThisStaff.map(appointment => appointment.PetRecord.Pet);
    return { staff, appointmentsWithThisStaff, pets };
  });

  const unassignedAppointments = allAppointmentsFromAllOrders.filter(appointment => !appointment.BusUsers?.length);

  const renderUnderPet = useCallback(
    ({ pet, ordersIds }: { pet: Pet; ordersIds: string[] }) => {
      const petNotes = petsNotes.filter(note => note.PetRecordId === pet.PetRecord.id);
      const orderNotes = ordersNotes.filter(note => ordersIds.includes(note.OrderId!));
      const orders = bookingOrderAppointments.filter(appointment => ordersIds.includes(appointment.OrderItem.Order.id));
      const appUserAddress = orders?.[0]?.OrderItem?.Order?.AppUser?.addresses?.[0];
      const postcode = orders?.[0]?.OrderItem?.Order?.AppUser?.postcode;
      const line2 = appUserAddress?.line2 ? `, ${appUserAddress?.line2}` : '';
      const appUserAddressString = appUserAddress ? `${appUserAddress.line1}${line2}, ${postcode}, ${appUserAddress.country}` : '';

      if (!showNotes && !showAddress) {
        return null;
      }
      const petsContent = petNotes.map(note => note.body?.content).join(', ');
      const ordersContent = orderNotes.map(note => note.body?.content).join(', ');

      if ((showNotes && !!petsContent) || (showNotes && !!ordersContent) || (showAddress && !!appUserAddressString)) {
        return (
          <DrawerList>
            {showNotes && !!petsContent && <GroupPetDetails backgroundColor={Colors.info2}>{petsContent}</GroupPetDetails>}
            {showNotes && !!ordersContent && <GroupPetDetails backgroundColor={Colors.info3}>{ordersContent}</GroupPetDetails>}
            {showAddress && !!appUserAddressString && <GroupPetDetails backgroundColor={Colors.info}>{appUserAddressString}</GroupPetDetails>}
          </DrawerList>
        );
      }

      return null;
    },
    [ordersNotes, petsNotes]
  );

  const renderTags = useCallback(
    ({ title, pets, appointments, index }: { title: string; pets: Pet[]; appointments: BookingOrder | Booking[]; index: number }) => {
      const appointmentsAllGroupOrders = orders.filter(bookingGroup => bookingGroup.some(a => appointments.some(appointment => appointment.id === a.id)));

      const isLast = appointmentTagsWithCount.length > 1 && index === appointmentTagsWithCount.length - 1;

      return (
        <DrawerList>
          <DrawerSubLabel weight={800} size={14} marginBottom={12} color={Colors.black}>
            {title}
            <BookingDrawerButtonsContainer>
              <BookingDrawerButtons
                allGroupOrders={appointmentsAllGroupOrders}
                appointments={appointments}
                group
                subGroup
                menuButtonType={OPTION_DROPDOWN_MENU_BUTTON_TYPES.THREE_DOT}
                menuOverlayPosition={isLast ? OPTION_DROPDOWN_MENU_OVERLAY_POSITIONS.UP : OPTION_DROPDOWN_MENU_OVERLAY_POSITIONS.DOWN}
              />
            </BookingDrawerButtonsContainer>
          </DrawerSubLabel>
          <PetProfilesList
            pets={pets}
            drawerId={DRAWER_IDS.GROUP_BOOKINGS_DRAWER}
            renderUnderPetProfile={pet =>
              renderUnderPet({ pet, ordersIds: appointments.filter(appointment => appointment.PetRecord.Pet.id === pet.id).map(appointment => appointment.OrderItem.Order.id) })
            }
            onClickPetProfile={pet => {
              const newDrawer = {
                drawerId: DRAWER_IDS.BOOKING_DRAWER,
                recordData: appointments.filter(appointment => appointment.PetRecord.Pet.id === pet.id)
              };

              if (mobile) {
                setDrawerBar(newDrawer);
                return;
              }

              addDrawerBar(newDrawer);
            }}
          />
        </DrawerList>
      );
    },
    [orders, appointmentTagsWithCount, untaggedAppointmentsAndPets]
  );

  const renderStaff = useCallback(
    ({ title, pets, appointments, index }: { title: string; pets: Pet[]; appointments: BookingOrder | Booking[]; index: number }) => {
      const appointmentsAllGroupOrders = orders.filter(bookingGroup => bookingGroup.some(a => appointments.some(appointment => appointment.id === a.id)));

      const isLast = appointmentTagsWithCount.length > 1 && index === appointmentTagsWithCount.length - 1;

      return (
        <DrawerList>
          <DrawerSubLabel weight={800} size={14} marginBottom={12} color={Colors.black}>
            {title}
            <BookingDrawerButtonsContainer>
              <BookingDrawerButtons
                allGroupOrders={appointmentsAllGroupOrders}
                appointments={appointments}
                group
                subGroup
                menuButtonType={OPTION_DROPDOWN_MENU_BUTTON_TYPES.THREE_DOT}
                menuOverlayPosition={isLast ? OPTION_DROPDOWN_MENU_OVERLAY_POSITIONS.UP : OPTION_DROPDOWN_MENU_OVERLAY_POSITIONS.DOWN}
              />
            </BookingDrawerButtonsContainer>
          </DrawerSubLabel>
          <PetProfilesList
            pets={pets}
            drawerId={DRAWER_IDS.GROUP_BOOKINGS_DRAWER}
            renderUnderPetProfile={pet =>
              renderUnderPet({ pet, ordersIds: appointments.filter(appointment => appointment.PetRecord.Pet.id === pet.id).map(appointment => appointment.OrderItem.Order.id) })
            }
            onClickPetProfile={pet => {
              const newDrawer = {
                drawerId: DRAWER_IDS.BOOKING_DRAWER,
                recordData: appointments.filter(appointment => appointment.PetRecord.Pet.id === pet.id)
              };

              if (mobile) {
                setDrawerBar(newDrawer);
                return;
              }
              addDrawerBar(newDrawer);
            }}
          />
        </DrawerList>
      );
    },
    [orders, appointmentTagsWithCount, untaggedAppointmentsAndPets]
  );

  if (loading || !firstBookingOrder) {
    return <LoadingOverlay />;
  }

  return (
    <DrawerContainer>
      <DrawerSection paddingT={24} paddingB={20} isFixed flexRow center>
        <MobileCloseButton drawer={drawerBar} />
        <DrawerLabel>{product?.name}</DrawerLabel>
        <BookingDrawerButtonsContainer>
          <ActionBtn bgImage={filterIcon} onClick={() => vars.groupBookingsFilters({ ...groupBookingsFilters, filtersActive: !filtersActive })} />
          <BookingDrawerButtons
            allGroupOrders={orders}
            appointments={allAppointmentsFromAllOrders}
            group
            loadPetTags
            groupOptionsToShow={{
              shouldDisplayGroupAssign: true,
              shouldDisplayGroupCancel: true,
              shouldDisplayGroupTagsOptions: true,
              shouldDisplayGroupUpdateOrderBills: true,
              shouldDisplayReschedule: true,
              shouldDisplayGroupPetsOptions: true
            }}
          />
        </BookingDrawerButtonsContainer>
      </DrawerSection>
      <Divider />
      <DrawerContent style={{ paddingTop: 0, padding: 0 }}>
        <div>
          <GroupFiltersWrapper isActive={!!filtersActive} large={groupType === GroupBookingsType.TAGS && !!tagCategories?.length}>
            <GroupFiltersContainer>
              <FlexRow justify="space-between">
                <GroupFilterTitle>Group By</GroupFilterTitle>
                <TabGroupContainer fullWidth noMargin>
                  <TabGroup
                    tabs={Object.values(GroupBookingsType)}
                    active={groupType}
                    setActive={tab =>
                      vars.groupBookingsFilters({
                        ...groupBookingsFilters,
                        type: tab as GroupBookingsType
                      })
                    }
                    canUnselect
                    fontSize={14}
                  />
                </TabGroupContainer>
              </FlexRow>

              {!!selectedTagsGroupType && !loadingTagCategories && !!tagCategories?.length && (
                <FlexRow justify="space-between">
                  <GroupFilterTitle>Tags Category</GroupFilterTitle>
                  <GroupFilterSelectContainer>
                    <ReactSelect
                      theme={theme => ({
                        ...theme,
                        borderRadius: 6,
                        spacing: {
                          baseUnit: 2,
                          controlHeight: 34,
                          menuGutter: 4
                        },
                        colors: {
                          ...theme.colors,
                          primary25: Colors.black,
                          primary: Colors.black,
                          danger: Colors.error,
                          neutral0: Colors.black,
                          primary50: Colors.black,
                          neutral50: Colors.grey
                        }
                      })}
                      options={tagCategories.map(tagCategory => ({ value: tagCategory.id, label: tagCategory.name }))}
                      placeholder="Select..."
                      components={{
                        ClearIndicator: () => null
                      }}
                      styles={{
                        option: provided => ({
                          ...provided,
                          color: Colors.white,
                          fontWeight: 700,
                          fontSize: 12
                        }),
                        singleValue: provided => ({
                          ...provided,
                          color: Colors.white,
                          fontWeight: 700,
                          fontSize: 12
                        }),
                        placeholder: provided => ({
                          ...provided,
                          color: Colors.white,
                          fontWeight: 400,
                          fontSize: 12
                        }),
                        control: provided => ({
                          ...provided,
                          borderWidth: 0
                        }),
                        container: provided => ({
                          ...provided,
                          borderWidth: 0
                        })
                      }}
                      onChange={option => {
                        vars.groupBookingsFilters({
                          ...groupBookingsFilters,
                          tagCategoryId: option?.value || null
                        });
                      }}
                      value={selectedCategory ? { value: selectedCategory.id, label: selectedCategory.name } : null}
                      isClearable
                    />
                  </GroupFilterSelectContainer>
                </FlexRow>
              )}
              {loadingTagCategories && (
                <TagLoaderContainer>
                  <ActivityIndicator size={20} color={Colors.black} />
                </TagLoaderContainer>
              )}
              <FlexRow justify="space-between">
                <GroupFilterTitle>Show Pet & Booking Notes</GroupFilterTitle>

                <ToggleSwitch
                  checked={showNotes}
                  onChange={() => {
                    vars.groupBookingsFilters({
                      ...groupBookingsFilters,
                      showNotes: !showNotes
                    });
                  }}
                />
              </FlexRow>

              <FlexRow justify="space-between">
                <GroupFilterTitle>Show owner's address</GroupFilterTitle>

                <ToggleSwitch
                  checked={showAddress}
                  onChange={() => {
                    vars.groupBookingsFilters({
                      ...groupBookingsFilters,
                      showAddress: !showAddress
                    });
                  }}
                />
              </FlexRow>
            </GroupFiltersContainer>
          </GroupFiltersWrapper>
          <GroupDrawerContentContainer>
            <DrawerSubLabel marginBottom={6} weight={600} size={14}>
              {!isMultiDay && 'Booking Slot'}
              {isMultiDay && 'Booking From'}
            </DrawerSubLabel>

            <DrawerSubLabel marginBottom={12} weight={700} color={Colors.black}>
              {toReadableDate(orders[0]?.[0]?.timestamp, { noTime: isMultiDay })}
            </DrawerSubLabel>

            <Divider marginBottom={16} />

            {groupType === null && (
              <DrawerList>
                <PetProfilesList
                  pets={pets}
                  drawerId={DRAWER_IDS.GROUP_BOOKINGS_DRAWER}
                  renderUnderPetProfile={pet =>
                    renderUnderPet({ pet, ordersIds: bookingOrderAppointments.filter(appointment => appointment.PetRecord.Pet.id === pet.id).map(appointment => appointment.OrderItem.Order.id) })
                  }
                  onClickPetProfile={pet => {
                    const newDrawer = {
                      drawerId: DRAWER_IDS.BOOKING_DRAWER,
                      recordData: bookingOrderAppointments.filter(appointment => appointment.PetRecord.Pet.id === pet.id)
                    };
                    if (mobile) {
                      setDrawerBar(newDrawer);
                      return;
                    }
                    addDrawerBar(newDrawer);
                  }}
                />
              </DrawerList>
            )}

            {groupType === GroupBookingsType.TAGS && (
              <DrawerList>
                <PetProfilesList
                  pets={pets}
                  drawerId={DRAWER_IDS.GROUP_BOOKINGS_DRAWER}
                  hideUntagged
                  splitByTags
                  tagsToInclude={selectedCategoryBranchPetRecordTags}
                  renderTagHeader={({ pets, tag }) => {
                    const appointmentsAllGroupOrders = orders.filter(bookingGroup => bookingGroup.some(a => pets.some(pet => pet.id === a.PetRecord.Pet.id)));
                    const appointmentsWithThesePets = bookingOrderAppointments.filter(appointment => pets.some(pet => pet.id === appointment.PetRecord.Pet.id));
                    const isLast = pets.length === appointmentsWithThesePets.length;
                    return (
                      <DrawerSubLabel weight={800} size={14} marginBottom={12} key={tag.id} color={Colors.black}>
                        {tag?.name} ({pets.length})
                        <BookingDrawerButtonsContainer>
                          <BookingDrawerButtons
                            allGroupOrders={appointmentsAllGroupOrders}
                            appointments={appointmentsWithThesePets}
                            group
                            subGroup
                            menuButtonType={OPTION_DROPDOWN_MENU_BUTTON_TYPES.THREE_DOT}
                            menuOverlayPosition={isLast ? OPTION_DROPDOWN_MENU_OVERLAY_POSITIONS.UP : OPTION_DROPDOWN_MENU_OVERLAY_POSITIONS.DOWN}
                          />
                        </BookingDrawerButtonsContainer>
                      </DrawerSubLabel>
                    );
                  }}
                  renderUnderPetProfile={pet =>
                    renderUnderPet({ pet, ordersIds: bookingOrderAppointments.filter(appointment => appointment.PetRecord.Pet.id === pet.id).map(appointment => appointment.OrderItem.Order.id) })
                  }
                  onClickPetProfile={pet => {
                    const newDrawer = {
                      drawerId: DRAWER_IDS.BOOKING_DRAWER,
                      recordData: bookingOrderAppointments.filter(appointment => appointment.PetRecord.Pet.id === pet.id)
                    };

                    if (mobile) {
                      setDrawerBar(newDrawer);
                      return;
                    }

                    addDrawerBar(newDrawer);
                  }}
                />
              </DrawerList>
            )}

            {groupType === GroupBookingsType.TAGS && pets?.some(pet => !!pet.PetRecord.BranchPetRecordTags.length) && <Divider marginBottom={16} />}

            {groupType === GroupBookingsType.TAGS && (
              <>
                {!!allAppointmentsTagsUniqueByTagId?.length &&
                  appointmentTagsWithCount.map(({ tag, tagCount, appointmentsWithThisTag }, index) => (
                    <Fragment key={tag?.id}>
                      {renderTags({ pets: appointmentsWithThisTag.map(appointment => appointment.PetRecord.Pet), title: `${tag.name} (${tagCount})`, appointments: appointmentsWithThisTag, index })}

                      {index !== appointmentTagsWithCount.length - 1 && <Divider marginBottom={16} />}
                    </Fragment>
                  ))}

                {!!untaggedAppointmentsAndPets?.length && (
                  <Fragment>
                    {!!allAppointmentsTagsUniqueByTagId?.length && <Divider marginBottom={16} />}
                    {renderTags({
                      pets: untaggedAppointmentsAndPets.map(appointment => appointment.PetRecord.Pet),
                      title: `Untagged (${untaggedAppointmentsAndPets.length})`,
                      appointments: untaggedAppointmentsAndPets,
                      index: 0
                    })}
                  </Fragment>
                )}
              </>
            )}

            {groupType === GroupBookingsType.BUSUSER_ASSIGNED && (
              <>
                {!!appointmentsStaffUnique?.length &&
                  appointementsSplitByStaff.map(({ staff, appointmentsWithThisStaff, pets }, index) => (
                    <Fragment key={staff?.id}>
                      {renderStaff({ pets, title: `${staff?.name} (${appointmentsWithThisStaff.length})`, appointments: appointmentsWithThisStaff, index })}

                      {index !== appointementsSplitByStaff.length - 1 && <Divider marginBottom={16} />}
                    </Fragment>
                  ))}

                {!!unassignedAppointments?.length && (
                  <Fragment>
                    {!!appointmentsStaffUnique?.length && <Divider marginBottom={16} />}
                    {renderStaff({
                      pets: unassignedAppointments.map(appointment => appointment.PetRecord.Pet),
                      title: `Unassigned (${unassignedAppointments.length})`,
                      appointments: unassignedAppointments,
                      index: 0
                    })}
                  </Fragment>
                )}
              </>
            )}
          </GroupDrawerContentContainer>
        </div>
      </DrawerContent>
      <Divider />

      <DrawerFooter></DrawerFooter>
    </DrawerContainer>
  );
};

export default GroupBookingsDrawer;
