import React from 'react';
import CreatableSelect from 'react-select/creatable';
import styled, { css } from 'styled-components';
import Colors from '../../../Colors';
import { selectTheme } from '../../../components/Shared/Forms/Forms';
import { ValueOf } from '../../../utils/helpers';

export const Container = styled.div`
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
`;

export const SectionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
`;

export const SectionContainer = styled.div`
  --section-content-width: 500px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: calc(100vw - var(--section-content-width));
  box-sizing: border-box;

  @media (max-width: 1200px) {
    --section-content-width: 460px;
  }

  @media (max-width: 768px) {
    --section-content-width: 0;
  }
`;

export const HealthMeasurementWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  background-color: ${Colors.white};
  padding: 36px;
  border-radius: 20px;
`;

export const PetRecordEntitiesContainer = styled.div<{ column?: boolean; noPadding?: boolean }>`
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
  ${({ column }) =>
    column &&
    css`
      flex-direction: column;
      gap: 12px;
    `}
  width: 100%;
  background-color: ${Colors.white};
  padding: 36px;
  ${({ noPadding }) =>
    noPadding &&
    css`
      padding: 0;
    `}

  border-radius: 20px;
  overflow: hidden;
`;

export const SectionWrapper = styled.div`
  width: 100%;
`;

export const HealthMeasurementContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

export const PetRecordEntity = styled.div`
  align-items: center;
  margin-bottom: 23px;
`;

export const PetRecordEntityLabel = styled.span`
  display: block;
  color: ${Colors.black};
  font-weight: bold;
  font-size: 16px;

  @media (max-width: 1200px) {
    font-size: 14px;
  }
`;
export const PetRecordEntitySubLabel = styled.span`
  display: block;
  color: ${Colors.warmGrey};
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 4px;

  @media (max-width: 1200px) {
    font-size: 12px;
  }
`;

export const HealthMeasurementTitle = styled.span`
  font-size: 16px;
  font-weight: 800;
  color: ${Colors.secondaryText};

  @media (max-width: 1200px) {
    font-size: 14px;
  }
`;

export const HealthMeasurementValue = styled.span<{ color?: ValueOf<typeof Colors> }>`
  font-size: 35px;
  font-weight: 800;
  color: ${props => props.color || Colors.secondaryText};

  @media (max-width: 1200px) {
    font-size: 24px;
  }
`;

export const HealthMeasurementHeader = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HealthChartContainer = styled.div`
  height: 400px;
  width: 100%;
`;

export const ChartTooltip = styled.span`
  font-size: 16px;
  font-weight: 800;
  color: ${Colors.primary};
`;

export const Divider = styled.div<{ paddingHorizontal?: number; color?: string; height?: number }>`
  height: 1px;
  ${({ height }) =>
    height &&
    css`
      height: ${height}px;
    `}
  background-color: #dddddd;
  ${({ color }) =>
    color &&
    css`
      background-color: ${color};
    `}
  width: 100%;
  ${({ paddingHorizontal }) =>
    paddingHorizontal &&
    css`
      padding: 0 ${paddingHorizontal}px;
    `}
`;

export const PetRecordWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  background-color: ${Colors.white};
  padding: 18px;
  border-radius: 20px;
  max-width: 100%;
  box-sizing: border-box;
  min-width: 100%;
`;

export const PetRecordRecordsOptions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const RecordsSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  overflow-x: scroll;
  white-space: nowrap;
  padding: 12px;
`;

export const PetRecordsContainer = styled.div`
  display: flex;
  gap: 12px;
  width: 100%;
`;

export const PetRecordContainer = styled.div<{ due?: boolean }>`
  box-sizing: border-box;
  width: 300px;
  min-width: 300px;
  height: 84px;
  border-radius: 15px;
  border: solid 1px ${props => (props.due ? '#f0753e' : Colors.warmGrey)};
  background-color: #ffffff;
  user-select: none;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
`;

export const SuppRecordContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  max-width: calc(100% - 16px);
  min-width: calc(100% - 16px);
`;

export const ColorTag = styled.div<{ color: string }>`
  width: 8px;
  min-width: 8px;
  border-radius: 5px;
  background-color: ${props => props.color};
`;

export const VaccRecordContainer = styled.div`
  display: flex;
  padding: 8px;
  gap: 12px;
  max-width: calc(100% - 16px);
  min-width: calc(100% - 16px);
`;

export const PetReportName = styled.span`
  font-size: 16px;
  font-weight: 400;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  @media (max-width: 1200px) {
    font-size: 14px;
  }
`;

export const PetRecordInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: calc(100% - 16px);
`;

export const PetRecordDate = styled.span<{ due?: boolean; green?: boolean }>`
  font-size: 13px;
  font-weight: 700;
  color: ${props => (props.due ? '#f0753e' : props.green ? Colors.primary : Colors.black)};
`;

export const PetRecordSelect = ({ options, defaultValue, onChange }) => {
  return (
    <CreatableSelect
      styles={{ container: (provided, state) => ({ ...provided }), valueContainer: (provided, state) => ({ ...provided, height: 48, overflowY: 'scroll' }) }}
      options={options}
      isMulti
      isLoading={false}
      isDisabled={false}
      theme={selectTheme}
      defaultValue={defaultValue}
      onChange={onChange}
    />
  );
};

export const DaysWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const VetDaycareTitle = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 800;
`;

export const HealthPetRecordDietContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  width: 100%;
`;

export const DietsWrapper = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
`;

export const DietRecordsContainer = styled.div`
  display: flex;
  overflow-x: scroll;
  width: 100%;
  white-space: nowrap;
  gap: 40px;

  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;

export const DietRecordContainer = styled.div<{ minWidth?: number }>`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 12px;
  min-width: ${props => `${props.minWidth}px` || 'unset'};
`;

export const DietRecordHeader = styled.div`
  display: flex;
  gap: 3;
  opacity: 0.5;
  white-space: nowrap;
`;

export const DietRecordBody = styled.div<{ noWrap?: boolean }>`
  display: flex;
  gap: 3;
  ${({ noWrap }) =>
    noWrap &&
    css`
      white-space: nowrap;
    `}
`;

export const VaccRecordEndContainer = styled.div`
  display: flex;
  height: 40px;
  width: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${Colors.primary};
`;
