import React, { useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Controller, useForm } from 'react-hook-form';
import { ModalBody, ModalFooter } from '../../../components/Modal/styled';
import {
  FormButtonsContainer,
  FormError,
  FormLabel,
  FormSubmitButton,
  InputsWrapper,
  WideInputGroup,
  FormInput,
  FormSelect,
  RadioBtnsGroup,
  FormHeaderLabel
} from '../../../components/Shared/Forms/Forms';
import { Container } from '../../../components/Shared/Shared';
import { AddBranchPetRecordItem, DeleteBranchPetRecordItem, EditBranchPetRecordItem, EditPetRecord, GetBranchCategoryByName } from '../../../queries';
import { FULL_DAYS, FULL_WEEK_DAYS_MAP, SUN_THREE_DAYS, THREE_LETTER_WEEK_DAYS, WEEK_DAYS_MAP } from '../../../utils/dates';
import { CenteredLoader } from '../../../components/Shared/Spinner';
import { sentenceCase } from 'sentence-case';
import { DaysWrapper } from './styled';
import ModalDialog from '../../../components/Modal/ModalDialog';
import VetsList from '../../../components/Shared/Lists/VetsList';

const HealthBranchPetRecordItemModalForm = ({
  loading,
  error,
  pet,
  daycares,
  vets,
  loadingItems,
  onSubmit,
  formOptions
}: {
  loading: boolean;
  error: boolean;
  pet: Record<string, any>;
  daycares: Record<string, any>[];
  vets: Record<string, any>[];
  loadingItems: boolean;
  onSubmit: (data: any) => void;
  formOptions: ReturnType<typeof useForm>;
}) => {
  const { control, errors } = formOptions;
  const existingDaycare = pet?.PetRecord?.BranchPetRecordItems?.find(branchPetRecordItem => branchPetRecordItem.type === 'DAYCARE');

  const existingVet = pet?.PetRecord?.BranchId;

  return (
    <>
      <ModalBody>
        <Container>
          {loadingItems && <CenteredLoader />}
          {!loadingItems && (
            <>
              <InputsWrapper noFlex>
                <FormHeaderLabel fontSize={16} margin={'0 0 16px 0'}>
                  {pet?.name}'s Vet
                </FormHeaderLabel>
                <WideInputGroup>
                  <FormLabel>Practice Name</FormLabel>
                  <VetsList formOptions={formOptions} defaultVetId={existingVet} />
                  {errors.selectedVetId && <FormError>{errors.selectedVetId.message || 'Vet is required'}</FormError>}
                </WideInputGroup>

                <WideInputGroup>
                  <FormLabel>Veterinarian Name</FormLabel>
                  <Controller
                    as={<FormInput error={errors.vet_name} type={'text'} height={32} fontSize={16} flexBasis={78} />}
                    control={control}
                    name={'vet_name'}
                    defaultValue={pet?.PetRecord?.vet_name || ''}
                    rules={{ required: false }}
                  />
                  {errors.vet_name && <FormError>{errors.vet_name.message || 'vet name is required'}</FormError>}
                </WideInputGroup>
              </InputsWrapper>
              <InputsWrapper noFlex>
                <FormHeaderLabel fontSize={16} margin={'0 0 16px 0'}>
                  {pet?.name}'s Daycare
                </FormHeaderLabel>

                <WideInputGroup>
                  <FormLabel>Daycare name</FormLabel>
                  <Controller
                    render={({ onChange, value }) => (
                      <FormSelect
                        height={48}
                        fontSize={16}
                        name={'selectedDaycareId'}
                        onChange={e => {
                          onChange(e.target.value);
                        }}
                        defaultValue={existingDaycare?.Branch?.id || ''}
                      >
                        <option value={''}>-- Unassigned --</option>
                        {daycares.map(({ id, name }, index) => (
                          <option key={index} value={id}>
                            {name}
                          </option>
                        ))}
                      </FormSelect>
                    )}
                    control={control}
                    name={'selectedDaycareId'}
                    defaultValue={existingDaycare?.Branch?.id || ''}
                  />
                  {errors.selectedDaycareId && <FormError>{errors.selectedDaycareId.message || 'Daycare is required'}</FormError>}
                </WideInputGroup>

                <WideInputGroup>
                  <DaysWrapper>
                    <Controller
                      render={({ onChange, value }) => (
                        <RadioBtnsGroup
                          options={FULL_WEEK_DAYS_MAP}
                          defaultValue={value}
                          onChange={e => {
                            value.includes(e.target.value) ? onChange([...value].filter(v => v !== e.target.value)) : onChange([...value, e.target.value]);
                          }}
                          inputType={'checkbox'}
                          name={`selectedDays`}
                          noLabel
                          customLabel={SUN_THREE_DAYS.map(day => sentenceCase(day))}
                        />
                      )}
                      control={control}
                      name={`selectedDays`}
                      defaultValue={existingDaycare?.days || []}
                    />
                  </DaysWrapper>
                </WideInputGroup>
              </InputsWrapper>
            </>
          )}
        </Container>
      </ModalBody>
      <ModalFooter>
        <FormButtonsContainer>
          <FormSubmitButton error={error} loading={loading} onClick={onSubmit}>
            Update
          </FormSubmitButton>
        </FormButtonsContainer>
      </ModalFooter>
    </>
  );
};

type HealthBranchPetRecordItemModalProps = { pet: Record<string, any> };
const HealthBranchPetRecordItemModal = ({ pet }: HealthBranchPetRecordItemModalProps) => {
  const formOptions = useForm();
  const { handleSubmit, getValues } = formOptions;

  const existingDaycare = pet?.PetRecord?.BranchPetRecordItems?.find(branchPetRecordItem => branchPetRecordItem.type === 'DAYCARE');

  const [handleEditPetRecord, { data: editedPetRecord, loading: loadingEdit, error: error }] = useMutation(EditPetRecord);

  const { data: { branchCategoryGet: [{ Branches: daycares = [] } = {}] = [] } = {}, loading: isLoadingDaycares } = useQuery(GetBranchCategoryByName, {
    variables: {
      name: 'Daycare'
    }
  });

  const [addBranchPetRecordItem, { data: { branchPetRecordItemAdd: addedItem = {} } = {}, loading: loadingAddBranchItem }] = useMutation(AddBranchPetRecordItem);

  const [editBranchPetRecordItem, { data: { branchPetRecordItemEdit: editedItem = {} } = {}, loading: loadingEditBranchItem }] = useMutation(EditBranchPetRecordItem);

  const [deleteBranchPetRecordItem, { data: { deleteBranchPetRecordItem: deletedItem = {} } = {}, loading: loadingDeleteBranchItem }] = useMutation(DeleteBranchPetRecordItem);

  const daycareAction = ({ actionType, selectedDaycareId, selectedDays }: { actionType: 'ADD' | 'EDIT'; selectedDaycareId: string; selectedDays: string[] }) => {
    const variables = {
      PetRecordId: pet?.PetRecord?.id,
      branchId: selectedDaycareId,
      type: 'DAYCARE',
      days: selectedDays
        ?.slice()
        ?.sort((a, b) => FULL_DAYS.indexOf(a) - FULL_DAYS.indexOf(b))
        ?.map(day => day.toUpperCase())
    };

    if (actionType === 'ADD') {
      addBranchPetRecordItem({ variables });
    }

    if (actionType === 'EDIT') {
      editBranchPetRecordItem({
        variables: {
          ...variables,
          id: existingDaycare?.id
        }
      });
    }
  };

  const onSubmit = handleSubmit(form => {
    const { vet_name, selectedVetId, selectedDaycareId, selectedDays } = form;
    handleEditPetRecord({
      variables: {
        id: pet?.PetRecord?.id,
        vet_name: vet_name?.trim(),
        branchId: selectedVetId || null
      }
    });

    if (!selectedDaycareId && existingDaycare?.id) {
      deleteBranchPetRecordItem({ variables: { id: existingDaycare?.id } });
    }

    if (selectedDaycareId && !existingDaycare?.id) {
      daycareAction({ actionType: 'ADD', selectedDaycareId, selectedDays });
    }

    if (selectedDaycareId && existingDaycare?.id) {
      daycareAction({ actionType: 'EDIT', selectedDaycareId, selectedDays });
    }
  });

  useEffect(() => {
    if (addedItem?.id || editedItem?.id || deletedItem?.message || (!getValues().selectedDaycareId && !existingDaycare?.id && editedPetRecord)) {
      ModalDialog?.closeModal();
    }
  }, [addedItem, editedItem, deletedItem]);

  const loadingSubmit = loadingEdit || loadingAddBranchItem || loadingDeleteBranchItem || loadingEditBranchItem;
  const loadingItems = isLoadingDaycares;

  return <HealthBranchPetRecordItemModalForm {...{ loading: loadingSubmit, error: !!error, pet, daycares, loadingItems, onSubmit, formOptions }} />;
};

export default HealthBranchPetRecordItemModal;
