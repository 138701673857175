import { useMutation } from '@apollo/client';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ModalDialog from '../../../components/Modal/ModalDialog';
import { ModalBody, ModalFooter } from '../../../components/Modal/styled';
import { Pet } from '../../../components/Pets/types';
import { FormButtonsContainer, FormError, FormLabel, FormSubmitButton, FormTextArea, InputsWrapper, WideInputGroup } from '../../../components/Shared/Forms/Forms';
import { EditPetBehavior } from '../../../queries';
import { Container } from './styled';

type TreatmentRoutineFormState = {
  treatment_routine: string;
};

const HealthEditPetRecordTreatmentRoutineModal = ({ petProfileBehavior, options }: { petProfileBehavior: Pet['PetRecord']['PetBehavior']; options: { includeWeight: boolean } }) => {
  const formOptions = useForm<TreatmentRoutineFormState>({
    defaultValues: {
      treatment_routine: petProfileBehavior?.treatment_routine
    },
    shouldUnregister: false
  });
  const { handleSubmit, control, errors } = formOptions;

  const [editBehavior, { data, loading, error }] = useMutation(EditPetBehavior, {
    refetchQueries: ['getPetBehaviors'],
    awaitRefetchQueries: true
  });

  const onSubmit = handleSubmit(data => {
    editBehavior({
      variables: {
        id: petProfileBehavior?.id,
        treatment_routine: data.treatment_routine || null
      }
    });
  });

  useEffect(() => {
    if (data?.editPetBehavior) {
      ModalDialog.closeModal();
    }
  }, [data?.editPetBehavior]);

  return (
    <>
      <ModalBody>
        <Container>
          <InputsWrapper noWrap>
            <WideInputGroup>
              <FormLabel>Treatment Routine</FormLabel>
              <Controller
                name="treatment_routine"
                control={control}
                render={({ onChange, value }) => <FormTextArea value={value} onChange={e => onChange(e.target.value)} placeholder="Treatment Routine" />}
                rules={{ required: true }}
              />
              {errors?.treatment_routine && <FormError>{errors?.treatment_routine?.message || 'This field is required'}</FormError>}
            </WideInputGroup>
          </InputsWrapper>
        </Container>
      </ModalBody>
      <ModalFooter>
        <FormButtonsContainer>
          <FormSubmitButton error={!!error?.message} loading={loading} onClick={onSubmit}>
            Update
          </FormSubmitButton>
        </FormButtonsContainer>
      </ModalFooter>
    </>
  );
};

export default HealthEditPetRecordTreatmentRoutineModal;
