import { useMutation } from '@apollo/client';
import React, { useCallback } from 'react';
import { RetryOrderPayment } from '../../queries';
import { ORDER_STATUS, ProductOrder } from '../../views/Orders/types';
import { ButtonsContainer } from '../DrawerBar/styled';
import ModalDialog from '../Modal/ModalDialog';
import OptionDropdown, { OptionDropDownItem } from '../Shared/Menus/OptionDropdown/OptionDropdown';
import { OPTION_DROPDOWN_MENU_BUTTON_TYPES, OPTION_DROPDOWN_MENU_OVERLAY_POSITIONS, OPTION_DROPDOWN_TYPES } from '../Shared/Menus/OptionDropdown/types';
import RefundModal from '../Shared/Modals/RefundModal/RefundModal';
import { CenteredLoader } from '../Shared/Spinner';

const useProductOrderActions = ({ order, loading, refetch }: { order: ProductOrder; loading?: boolean; refetch?: () => {} }) => {
  const [retryPayment, { loading: loadingRetryPayment }] = useMutation(RetryOrderPayment);

  const shouldDisplayRetryPayment = !loading && [ORDER_STATUS.PAYMENT_PENDING, ORDER_STATUS.PAYMENT_REJECTED].includes(order.status);
  const shouldDisplayRefund =
    !loading && (([ORDER_STATUS.CONFIRMED].includes(order.status) && order?.payment?.status === 'PAYMENT_SUCCEEDED') || [ORDER_STATUS.PARTIALLY_REFUNDED].includes(order.status));
  // const shouldDisplayCancel
  // const shouldDisplayMarkAsPaid

  const handleRetryPayment = useCallback(async () => {
    return retryPayment({ variables: { orderId: order?.id } })?.then(() => {
      refetch?.();
    });
  }, [retryPayment, order]);

  const handleRefund = useCallback(() => {
    ModalDialog.openModal({
      content: () => <RefundModal order={order} />,
      title: 'Refund Payment',
      onClose() {
        setTimeout(() => {
          refetch?.();
        }, 3000);
      }
    });
  }, []);

  const actions = {
    handleRetryPayment,
    handleRefund
  };

  const shouldDisplay = {
    shouldDisplayRetryPayment,
    shouldDisplayRefund
  };

  const loadingAny = loadingRetryPayment;

  return {
    actions,
    shouldDisplay,
    loading: loadingAny
  };
};

const useProductOrderActionsList = ({ order, loading, refetch }: { order: ProductOrder; loading?: boolean; refetch?: () => {} }): { items: OptionDropDownItem[]; anyLoading: boolean } => {
  const {
    actions: productActions,
    shouldDisplay,
    loading: anyLoading
  } = useProductOrderActions({
    order: order,
    loading: loading,
    refetch: refetch
  });

  const items = [
    shouldDisplay.shouldDisplayRetryPayment && {
      name: 'Retry payment',
      value: 'retry',
      onClick: productActions.handleRetryPayment
    },
    shouldDisplay.shouldDisplayRefund && {
      name: 'Refund payment',
      value: 'refund',
      onClick: productActions.handleRefund
    }
  ].filter(Boolean) as OptionDropDownItem[];

  return {
    items,
    anyLoading: anyLoading
  };
};

const ProductOrderDrawerButtons = ({ order, loading, refetch }: { order: ProductOrder; loading?: boolean; refetch?: () => {} }) => {
  const { items, anyLoading } = useProductOrderActionsList({
    order: order,
    loading: loading,
    refetch: refetch
  });

  return (
    <ButtonsContainer>
      {loading && <CenteredLoader size={20} />}

      {!loading && (
        <OptionDropdown
          buttonLoading={anyLoading}
          menuButtonType={OPTION_DROPDOWN_MENU_BUTTON_TYPES.MENU}
          menuOverlayPosition={OPTION_DROPDOWN_MENU_OVERLAY_POSITIONS.DOWN}
          noApplyButton
          options={[
            {
              optionType: OPTION_DROPDOWN_TYPES.BUTTONS,
              id: 'productOrderOptions',
              items
            }
          ]}
        />
      )}
    </ButtonsContainer>
  );
};

export default ProductOrderDrawerButtons;
