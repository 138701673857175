import { useLazyQuery, useQuery, useReactiveVar } from '@apollo/client';
import React, { FC, Fragment, useCallback } from 'react';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { GetBranchUnreadItemsCountReturn } from '../../../hooks/useUnreadBranchItemsCount';
import { GetBranchAppUserTags, GetBranchUnreadItemsCount } from '../../../queries';
import { vars } from '../../../reactive';
import { manageChatsFilters } from '../../../reactive/actions';
import { areObjectsEqual } from '../../../utils/helpers';
import { BranchAppUserTag } from '../../../views/Store/BranchTags/types';

import { Divider } from '../../../views/Pets/Health/styled';
import Filters from '../../Shared/Filters/Filters';
import SearchBar from '../../Shared/Filters/SearchBar';
import { CHATS_FILTER_TYPES, CHATS_MORE_OPTIONS_TYPES, FILTERS_ID } from '../../Shared/Filters/config';
import useFilters from '../../Shared/Filters/useFilters';
import { OPTION_DROPDOWN_TYPES } from '../../Shared/Menus/OptionDropdown/types';
import { TabsHeaderContainer } from '../../Shared/Shared';
import TabsHeader from '../../Shared/TabsHeader';
import { CHAT_TABS_TYPES, CHAT_TABS_TYPES_LABELS } from '../types';
import ChatTagsQuickFilter from './ChatTagsQuickFilter';

const CHATS_FILTER_TYPES_NAMES: Record<CHATS_FILTER_TYPES, string> = {
  [CHATS_FILTER_TYPES.CHAT_TAGS]: 'Tags',
  [CHATS_FILTER_TYPES.CHAT_QUICK_TAGS]: '',
  [CHATS_FILTER_TYPES.CHAT_SEARCH]: 'Search'
};

const MORE_OPTIONS_TITLES: Record<CHATS_MORE_OPTIONS_TYPES, string> = {
  [CHATS_MORE_OPTIONS_TYPES.CHAT_TAGS]: 'Tags',
  [CHATS_MORE_OPTIONS_TYPES.CHAT_SEARCH]: 'Search'
};

const MORE_OPTIONS_DROPDOWN_TYPES: Record<CHATS_MORE_OPTIONS_TYPES, OPTION_DROPDOWN_TYPES> = {
  [CHATS_MORE_OPTIONS_TYPES.CHAT_TAGS]: OPTION_DROPDOWN_TYPES.MULTI_SELECT,
  [CHATS_MORE_OPTIONS_TYPES.CHAT_SEARCH]: OPTION_DROPDOWN_TYPES.TEXT_INPUT
};

const CHATS_FILTER_TYPES_UNITS: Record<CHATS_FILTER_TYPES, string> = {
  [CHATS_FILTER_TYPES.CHAT_TAGS]: '',
  [CHATS_FILTER_TYPES.CHAT_QUICK_TAGS]: '',
  [CHATS_FILTER_TYPES.CHAT_SEARCH]: ''
};

type ChatsHeaderProps = {
  selectedTabState: [CHAT_TABS_TYPES, React.Dispatch<React.SetStateAction<CHAT_TABS_TYPES>>];
  tabs: CHAT_TABS_TYPES[];
};

const ChatsHeader: FC<ChatsHeaderProps> = ({ selectedTabState, tabs }) => {
  const { addFilter, addHeaderAction, clearHeaderAction, removeFilter, setNewVisibleFilters } = manageChatsFilters;
  const [selected, setSelected] = selectedTabState;
  const { mobile } = useMediaQuery({ mobile: true });

  const [getAllTags, { data: { getBranchAppUserTags: allTags = [] } = {}, called: calledTags }] = useLazyQuery<{ getBranchAppUserTags: BranchAppUserTag[] }>(GetBranchAppUserTags, {
    fetchPolicy: 'cache-and-network',
    variables: { offset: 0, limit: 1000 }
  });

  const toCall = [{ called: calledTags, get: () => getAllTags({ variables: { offset: 0, limit: 1000 } }), id: CHATS_FILTER_TYPES.CHAT_TAGS }];

  const filtersControl = useFilters({
    setSelectedFilters: setNewVisibleFilters,
    toCall,
    addHeaderAction: addHeaderAction,
    clearHeaderAction: clearHeaderAction
  });

  const CHATS_FILTER_TYPES_MORE_OPTIONS: Record<CHATS_FILTER_TYPES, CHATS_MORE_OPTIONS_TYPES[]> = {
    [CHATS_FILTER_TYPES.CHAT_TAGS]: [CHATS_MORE_OPTIONS_TYPES.CHAT_TAGS],
    [CHATS_FILTER_TYPES.CHAT_QUICK_TAGS]: [CHATS_MORE_OPTIONS_TYPES.CHAT_TAGS],
    [CHATS_FILTER_TYPES.CHAT_SEARCH]: [CHATS_MORE_OPTIONS_TYPES.CHAT_SEARCH]
  };

  const MORE_OPTIONS_ITEMS: Record<CHATS_MORE_OPTIONS_TYPES, { value: string | boolean | number; name: string }[] | []> = {
    [CHATS_MORE_OPTIONS_TYPES.CHAT_TAGS]: allTags?.map(({ id, name }: { id: string; name: string }) => ({ value: id, name })),
    [CHATS_MORE_OPTIONS_TYPES.CHAT_SEARCH]: []
  };

  const filterItems = Object.values(CHATS_FILTER_TYPES).map(filter => ({
    name: CHATS_FILTER_TYPES_NAMES[filter],
    value: filter,
    unit: CHATS_FILTER_TYPES_UNITS[filter],
    moreOptions: CHATS_FILTER_TYPES_MORE_OPTIONS[filter].map(option => ({
      type: option,
      filterType: filter,
      id: `${filter}.${option}`,
      title: MORE_OPTIONS_TITLES[option] ? `${MORE_OPTIONS_TITLES[option]} Filter` : '',
      optionType: MORE_OPTIONS_DROPDOWN_TYPES[option],
      items: MORE_OPTIONS_ITEMS[option]
    }))
  }));

  const quickTagFilter = filterItems.find(({ value }) => value === CHATS_FILTER_TYPES.CHAT_QUICK_TAGS)!;

  const searchFilter = filterItems.find(({ value }) => value === CHATS_FILTER_TYPES.CHAT_SEARCH)!;
  const searchMoreOptions = searchFilter.moreOptions.find(({ type }) => type === CHATS_MORE_OPTIONS_TYPES.CHAT_SEARCH)!;

  const onChatSearch = (query: string) => {
    if (query) {
      addFilter({
        ...searchFilter,
        moreOptions: [{ ...searchMoreOptions, values: query }],
        requisite: 'true'
      });
      return;
    }
    removeFilter(searchFilter.value);
  };

  const Header = useCallback(() => {
    const headerActions = useReactiveVar(vars.chatsHeaderActions);

    const { data: unreadItemsCount } = useQuery<{ getBranchUnreadItemsCount: GetBranchUnreadItemsCountReturn }>(GetBranchUnreadItemsCount, {
      fetchPolicy: 'cache-only',
      notifyOnNetworkStatusChange: true
    });

    const { unreadChatRoomsCount } = unreadItemsCount?.getBranchUnreadItemsCount || {};

    const filterHeaderActions = headerActions.filter(({ id }) => id !== FILTERS_ID && id !== 'bulkSelectAll');
    const filtersHeaderAction = headerActions.find(({ id }) => id === FILTERS_ID)?.action;
    const actions = (
      <>
        {filterHeaderActions.map(({ action, id }) => (
          <Fragment key={id}>{action}</Fragment>
        ))}
        {!mobile && filtersHeaderAction}
      </>
    );
    return (
      <TabsHeader
        tabs={tabs}
        tabsTitles={CHAT_TABS_TYPES_LABELS}
        notifications={{
          [CHAT_TABS_TYPES.ALL]: unreadChatRoomsCount || 0,
          [CHAT_TABS_TYPES.READ]: 0,
          [CHAT_TABS_TYPES.UNREAD]: unreadChatRoomsCount || 0
        }}
        selected={selected}
        setSelected={setSelected}
        actions={
          <>
            {!mobile && <SearchBar onSearch={onChatSearch} defaultText="Search Chats" showAlways />}
            {actions}
          </>
        }
        actionsWidth={600}
      />
    );
  }, [selected, setSelected, tabs]);

  return (
    <>
      <Header />
      {!mobile && <Filters filtersControl={filtersControl} filterItems={filterItems} />}
      <TabsHeaderContainer padding="0 32px 0 32px" mobilePadding="10px 16px 0 16px" style={{ flexDirection: 'column' }}>
        {mobile && <Divider color="#F2F2F2" />}
        <ChatTagsQuickFilter quickTagFilter={quickTagFilter} />
        {mobile && <Divider color="#F2F2F2" />}
      </TabsHeaderContainer>
    </>
  );
};

export default React.memo(ChatsHeader, areObjectsEqual);
