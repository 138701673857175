import { useQuery } from '@apollo/client';
import React, { FC, useRef } from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import Modal, { ModalRef } from '../../../components/Modal/Modal';
import { FormError, FormInput, FormLabel, FormSelect, InputContainer, RemoveRowContainer } from '../../../components/Shared/Forms/Forms';
import { FlexRow } from '../../../components/Shared/Shared';
import useIcons from '../../../hooks/useIcons';
import { GetReducedBranchAppUsers } from '../../../queries';
import { BranchAppUser } from '../BranchBilling/types';
import { DiscountsFormContainer, VouchersContentContainer } from '../Operations/styled';
import { AddNewBtn, AddNewBtnIcon } from '../Products/styled';
import { RemoveAddedImage } from '../styled';
import DiscountRule from './DiscountRule';
import Voucher from './Voucher';
import VoucherModal from './VoucherModal';
import { DiscountFormProps, DiscountRule as DiscountRuleType, FormDiscount, Voucher as VoucherType } from './types';

const DiscountForm: FC<DiscountFormProps> = ({ discount, index, formOptions, handleRemoveRow, allowAddAndRemove = false }) => {
  const { control, errors, watch, setValue } = formOptions;
  const icons = useIcons();
  const watchedDiscount: FormDiscount = watch(`discounts[${index}]`);

  const vouchersField = useFieldArray<VoucherType, 'itemId'>({
    control,
    name: `discounts[${index}].vouchers`,
    keyName: 'itemId'
  });

  const rulesField = useFieldArray<DiscountRuleType, 'itemId'>({
    control,
    name: `discounts[${index}].rules`,
    keyName: 'itemId'
  });

  const vouchersModalRef = useRef<ModalRef>(null);

  const allAppUserIds = watchedDiscount?.vouchers?.flatMap(voucher => voucher?.appUsers) || [];

  const { data: { getBranchAppUsers: appUsers = [] } = {} } = useQuery<{ getBranchAppUsers: BranchAppUser[] }>(GetReducedBranchAppUsers, {
    variables: { id: allAppUserIds, skip: !allAppUserIds.length }
  });

  const onAddVoucher = () => {
    const otherVouchers = vouchersField.fields;
    vouchersModalRef.current?.openModal({ title: 'Add Voucher', otherVouchers });
  };

  const onEditVoucher = (voucherIndex: number) => {
    const voucher = vouchersField.fields[voucherIndex];
    const otherVouchers = vouchersField.fields.filter((_, i) => i !== voucherIndex);
    vouchersModalRef.current?.openModal({ voucher, title: 'Edit Voucher', voucherIndex, otherVouchers });
  };

  const handleRemoveVoucher = (voucherIndex: number) => {
    vouchersField.remove(voucherIndex);
    vouchersModalRef.current?.closeModal();
  };

  const handleAddRule = () => {
    rulesField.append({ rule: { type: 'none', amount: 0 }, level: 'MAIN', description: '' });
  };

  return (
    <DiscountsFormContainer>
      <FlexRow gap={8}>
        <InputContainer flex={1} gap={8}>
          <FormLabel>Discount Name</FormLabel>
          <Controller
            as={<FormInput error={!!errors?.discounts?.[index]?.name} height={20} fontSize={16} />}
            control={control}
            name={`discounts[${index}].name`}
            defaultValue={discount?.name || ''}
            rules={{ required: true }}
          />
          {!!errors?.discounts?.[index]?.name && <FormError>{errors?.discounts?.[index]?.name?.message || 'Name is required'}</FormError>}
          <FlexRow>
            <InputContainer>
              <FormLabel>Type</FormLabel>
              <Controller
                render={({ onChange, value }) => (
                  <FormSelect error={!!errors?.discounts?.[index]?.type} height={38} fontSize={16} onChange={onChange} value={value || 'none'} width={140}>
                    <option value="percentage">Percentage</option>
                    <option value="amount">Amount</option>
                  </FormSelect>
                )}
                control={control}
                name={`discounts[${index}].type`}
                defaultValue={discount?.type || 'percentage'}
                rules={{ required: true }}
              />
              {!!errors?.discounts?.[index]?.type && <FormError>{errors?.discounts?.[index]?.type?.message || 'Type is required'}</FormError>}
            </InputContainer>
            <InputContainer>
              <FormLabel>{watchedDiscount?.type === 'percentage' ? 'Percentage' : 'Amount'}</FormLabel>
              <Controller
                as={<FormInput error={!!errors?.discounts?.[index]?.amount} height={20} fontSize={16} width={60} />}
                control={control}
                name={`discounts[${index}].amount`}
                defaultValue={discount?.amount || 0}
                rules={{ required: true }}
              />
              {!!errors?.discounts?.[index]?.amount && <FormError>{errors?.discounts?.[index]?.amount?.message || 'Amount is required'}</FormError>}
            </InputContainer>
            {discount?.id && (
              <InputContainer>
                <FormLabel>Status</FormLabel>
                <Controller
                  render={({ onChange, value }) => (
                    <FormSelect error={!!errors?.discounts?.[index]?.status} height={38} fontSize={16} onChange={onChange} value={value || 'none'} width={160}>
                      <option value="ACTIVE">Active</option>
                      <option value="ARCHIVED">Archived</option>
                    </FormSelect>
                  )}
                  control={control}
                  name={`discounts[${index}].status`}
                  defaultValue={discount?.status || 'ACTIVE'}
                  rules={{ required: true }}
                />
                {!!errors?.discounts?.[index]?.status && <FormError>{errors?.discounts?.[index]?.status?.message || 'Status is required'}</FormError>}
              </InputContainer>
            )}
          </FlexRow>
        </InputContainer>
        {allowAddAndRemove && (
          <RemoveRowContainer noMargin>
            <RemoveAddedImage src={icons.delete.childImageSharp.gatsbyImageData.images.fallback.src} onClick={() => handleRemoveRow(index)} noMargin />
          </RemoveRowContainer>
        )}
      </FlexRow>

      <InputContainer>
        <FormLabel>Rules</FormLabel>
        {rulesField.fields.map((rule, ruleIndex) => (
          <DiscountRule key={rule.itemId} rule={rule} index={ruleIndex} formOptions={formOptions} discountIndex={index} handleRemoveRule={rulesField.remove} />
        ))}
        <AddNewBtn onClick={handleAddRule}>
          <AddNewBtnIcon src={icons?.addPhoto?.childImageSharp?.gatsbyImageData.images.fallback.src} />
          Add New Rule
        </AddNewBtn>
      </InputContainer>

      <InputContainer>
        <FormLabel>Vouchers</FormLabel>
        <VouchersContentContainer>
          {vouchersField.fields.map((voucher, voucherIndex) => (
            <Voucher key={voucher.itemId} voucher={voucher} handleEditVoucher={() => onEditVoucher(voucherIndex)} appUsers={appUsers} />
          ))}
        </VouchersContentContainer>
        <AddNewBtn onClick={() => onAddVoucher()}>
          <AddNewBtnIcon src={icons?.addPhoto?.childImageSharp?.gatsbyImageData.images.fallback.src} />
          Add New Voucher
        </AddNewBtn>
        <Modal
          title="Add Voucher"
          modalContent={props => (
            <VoucherModal
              onSubmit={voucher => {
                if (props?.voucher) {
                  setValue(`discounts[${index}].vouchers[${props?.voucherIndex}]`, {
                    ...voucher,
                    id: props?.voucher?.id
                  });
                  vouchersField.move(props?.voucherIndex, props?.voucherIndex);
                } else {
                  vouchersField.append({
                    ...voucher,
                    auto_apply: String(voucher?.auto_apply) === 'true'
                  });
                }
                vouchersModalRef.current?.closeModal();
              }}
              voucher={props?.voucher || null}
              discount={watchedDiscount}
              otherVouchers={props?.otherVouchers}
              handleRemoveVoucher={() => handleRemoveVoucher(props?.voucherIndex)}
            />
          )}
          ref={vouchersModalRef}
          noButton
        />
      </InputContainer>
    </DiscountsFormContainer>
  );
};

export default DiscountForm;
