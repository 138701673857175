import React from 'react';
import usePaginatedQuery from '../../hooks/usePaginatedQuery';
import { GetBranchSubmittedForms } from '../../queries';
import { SubmittedForm } from '../Store/BranchForms/types';
import InfiniteList from '../../components/Shared/InfiniteList/InfiniteList';
import PetsFormRecord from './PetsFormRecord';
import { RecordsContainer } from '../styled';

const PetsForms = () => {
  const [[hasMoreItems, setHasMoreItems], { data: { getBranchSubmittedForms: allForms = [] } = {}, loading: loadingPetFormsReports, fetchMore }] = usePaginatedQuery<SubmittedForm[]>({
    query: GetBranchSubmittedForms,
    limit: 20
  });
  return (
    <RecordsContainer>
      <InfiniteList
        fetchMore={fetchMore as any}
        hasMoreItems={hasMoreItems}
        setHasMoreItems={setHasMoreItems}
        itemRenderer={(form: SubmittedForm) => <PetsFormRecord key={form.id} submittedForm={form} />}
        list={allForms}
        loading={loadingPetFormsReports}
        offset={allForms?.length}
      />
    </RecordsContainer>
  );
};

export default PetsForms;
