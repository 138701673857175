export enum CHAT_TABS_TYPES {
  ALL = 'all',
  UNREAD = 'unread',
  READ = 'read'
}

export const CHAT_TABS_TYPES_LABELS = {
  [CHAT_TABS_TYPES.ALL]: 'All',
  [CHAT_TABS_TYPES.UNREAD]: 'Unread',
  [CHAT_TABS_TYPES.READ]: 'Read'
};
