import React, { useEffect } from 'react';
import { BusUserProfile } from '../../../components/Profile/types';
import InfiniteList from '../../../components/Shared/InfiniteList/InfiniteList';
import usePaginatedQuery from '../../../hooks/usePaginatedQuery';
import { GetBranchBusUsers } from '../../../queries';
import { RecordsContainer } from '../../styled';
import Common from '../Common';
import BusUserRecord from './BusUserRecord';

const BusUsers = () => {
  const [[hasMoreItems, setHasMoreItems], queryResult] = usePaginatedQuery<BusUserProfile[]>({ query: GetBranchBusUsers, otherParams: { fetchPolicy: 'cache-and-network' } });

  const { data: { getBranchBusUsers: busUsers = [] } = {}, loading, fetchMore, refetch } = queryResult;

  useEffect(() => {
    Common.set(`BusUsers.GetBranchBusUsers.refetch`, () => {
      refetch?.().then(() => setHasMoreItems(true));
    });
  }, []);

  return (
    <RecordsContainer>
      <InfiniteList
        list={busUsers}
        itemRenderer={(busUser: BusUserProfile) => <BusUserRecord busUser={busUser} />}
        hasMoreItems={hasMoreItems}
        loading={loading}
        fetchMore={fetchMore}
        offset={busUsers?.length}
        setHasMoreItems={setHasMoreItems}
      />
    </RecordsContainer>
  );
};

export default BusUsers;
