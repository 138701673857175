import { Branch } from '../../components/Profile/types';
import { Slots } from '../../hooks/useCheckBranchAvailability';
import { BOOKING_TYPE } from '../Bookings/types';
import Operations from './Operations/Operations';

export enum CHARGE_TYPE {
  PREPAID = 'PREPAID',
  POSTPAID = 'POSTPAID'
}

export enum CHARGE_STATUS {
  CHARGE_NOW = 'CHARGE_NOW',
  CHARGE_LATER = 'CHARGE_LATER',
  NO_CHARGE = 'NO_CHARGE'
}

export const chargeStatuses = {
  [CHARGE_STATUS.CHARGE_NOW]: { value: CHARGE_STATUS.CHARGE_NOW, label: 'Charge Now', charge_type: CHARGE_TYPE.PREPAID },
  [CHARGE_STATUS.CHARGE_LATER]: { value: CHARGE_STATUS.CHARGE_LATER, label: 'Charge Later', charge_type: CHARGE_TYPE.POSTPAID },
  [CHARGE_STATUS.NO_CHARGE]: { value: CHARGE_STATUS.NO_CHARGE, label: 'No Charge', charge_type: CHARGE_TYPE.PREPAID }
};

export type Customization = {
  id: string;
  name: string;
  price: number;
  Indicator: false | '' | JSX.Element;
  title: string;
  meta: Record<string, string>;
}[];

export type Product = {
  id: string;
  name: string;
  price: number;
  unit: string;
  weight: number;
  charge_type: CHARGE_TYPE;
  sessions: number;
  quantity: number;
  total: number;
  stock: number | null;
  original_price: number;
  automatically_pick_best_discount: boolean;
  show_customizations_price: boolean;
  use_pets_count: boolean;
  auto_confirm_order: boolean;
  allows_repeated_orders: boolean;
  allows_bulk_orders: boolean;
  max_bulk_orders: number;
  type: 'product' | 'service' | 'subscription';
  booking_type: BOOKING_TYPE;
  short_description: string | null;
  Branch: Branch;
  customizations: Record<string, Customization>;
  slots: { time: string; isAvailable: boolean }[];
  duration: number;
  user_can_cancel: boolean;
  allows_past_slots: boolean;
  user_can_reschedule: boolean;
  enable_customizations_quantity_factor: boolean;
  ProductKinds: { id: string; name: string }[];
  payment: {
    method_optional?: boolean;
  };
  PharmaItemProducts: {
    id: string;
    PharmaItem: {
      id: string;
      pack_size: string;
    };
  }[];
};

export type BranchAvailability = {
  slots: Slots;
};

export enum TABS_TYPES {
  SERVICES = 'services',
  SUBSCRIPTIONS = 'memberships',
  PRODUCTS = 'products',
  AUTO_MESSAGES = 'messages',
  BRANCH_TAGS = 'tags',
  REPORTS = 'reports',
  BILLING = 'billing',
  FORMS = 'forms',
  DISCOUNTS = 'discounts',
  BUS_USERS = 'staff',
  PAYOUTS = 'payouts'
}

export const MODAL_TABS_TYPES = [{ name: 'operations', modal: Operations, modalTitle: 'Store Operations' }] as const;

export const productStatuses = [
  { value: 'IN_STOCK', label: 'In stock', availablality: 'Available' },
  { value: 'OUT_OF_STOCK', label: 'Out of stock', availablality: 'Unavailable' },
  { value: 'HIDDEN', label: 'Hidden', availablality: 'Hidden' }
];

export type Country = {
  id: number;
  name: string;
};
