import { useQuery, useReactiveVar } from '@apollo/client';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import useDownload from '../../hooks/useDownload';
import useIcons from '../../hooks/useIcons';
import { GetBranchOrderInvoices, GetOrderInvoiceOrders } from '../../queries';
import { vars } from '../../reactive';
import { ORDER_STATUS, ProductOrder } from '../../views/Orders/types';
import { getOrderTitles } from '../../views/Orders/utils';
import { BranchSummaryCountMode } from '../../views/Store/BranchSummary/styled';
import { RecordBody } from '../../views/styled';
import { ButtonsContainer } from '../ChatDrawer/styled';
import { Divider, DrawerContainer, DrawerContent, DrawerLabel, DrawerSection } from '../DrawerBar/styled';
import { DRAWER_IDS } from '../DrawerBar/types';
import { OrderInvoice } from '../OrderInvoices/types';
import { getOrderInvoiceTitles } from '../OrderInvoices/utils';
import { CenteredLoader } from '../Shared/Spinner';
import { BillingStatus, BookingListItem, BookingsDivider, UserBookingContainer } from '../UserDrawer/styled';

const OrderInvoiceDrawer = ({ data: invoiceId }: { data: string }) => {
  const icons = useIcons();
  const exportIcon = icons?.export?.childImageSharp.gatsbyImageData;

  const { data: { getBranchOrderInvoices: orderInvoices = [] } = {}, loading: loadingOrderInvoice } = useQuery<{ getBranchOrderInvoices: OrderInvoice[] }>(GetBranchOrderInvoices, {
    variables: { OrderInvoiceId: [invoiceId] },
    fetchPolicy: 'cache-and-network'
  });

  const orderInvoice = orderInvoices[0];

  const { amountTitle, refundTitle } = getOrderInvoiceTitles(orderInvoice || {});

  const { data: { getOrderInvoiceOrders: orders = [] } = {}, loading: loadingOrders } = useQuery<{ getOrderInvoiceOrders: ProductOrder[] }>(GetOrderInvoiceOrders, {
    variables: { OrderInvoiceId: invoiceId },
    fetchPolicy: 'cache-and-network'
  });

  const drawerBars = useReactiveVar(vars.drawerBars);
  const drawerBar = drawerBars.find(drawer => drawer.drawerId === DRAWER_IDS.ORDER_INVOICE_DRAWER);
  const loading = loadingOrders || loadingOrderInvoice;
  const [downloadInvoice, { loading: loadingDownload, error }] = useDownload<
    Partial<{
      OrderInvoiceId: string;
    }>
  >({
    type: 'invoice',
    includeProviderBranchId: true,
    fileFormat: 'pdf'
  });

  return (
    <DrawerContainer drawerWidths={drawerBar?.drawerWidths}>
      {loading && <CenteredLoader size={60} />}
      {!loading && (
        <>
          <DrawerSection paddingT={24} paddingB={20} isFixed flexRow center>
            <DrawerLabel>{orderInvoice?.number}</DrawerLabel>
            <ButtonsContainer style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
              <BranchSummaryCountMode
                style={{ display: 'flex', gap: 10, paddingBottom: 10 }}
                onClick={() => {
                  downloadInvoice({
                    variables: {
                      OrderInvoiceId: invoiceId
                    },
                    fileName: `INV ${orderInvoice?.number} - ${orders?.[0]?.AppUser?.name}`
                  });
                }}
              >
                <span>Export</span>
                <GatsbyImage image={exportIcon} alt="export" />
              </BranchSummaryCountMode>
            </ButtonsContainer>
          </DrawerSection>
          <Divider />
          <DrawerContent>
            <div>
              {orders.map(order => {
                const { orderNumber, isBilled, total, time, statusTitle, isRed, isGreen, isBlue, status } = getOrderTitles(order);
                const appointments = (order?.OrderItems?.[0]?.Appointments || []).flat();
                const pets = [...new Set(appointments?.map(app => app.PetRecord.Pet.name))].join(', ');
                const timestamp = [...new Set(appointments?.map(app => new Date(app.timestamp).toDateString()))].join(', ');
                const itemName = order?.OrderItems?.[0]?.item?.name;
                return (
                  <BookingListItem key={order.id}>
                    <UserBookingContainer opacity={status === ORDER_STATUS.CANCELED ? 0.6 : 1}>
                      <RecordBody width="80" fontWeight={'800'}>
                        {orderNumber}
                      </RecordBody>
                      <RecordBody width="150" fontWeight={'600'}>
                        {itemName}
                      </RecordBody>
                      <RecordBody width="150" fontWeight={'600'}>
                        {pets}
                      </RecordBody>
                      <RecordBody width="auto" fontWeight="400">
                        <strong>{timestamp}</strong>
                      </RecordBody>
                      <RecordBody width="auto" fontWeight="800">
                        {total}
                      </RecordBody>
                      <RecordBody width="150" fontWeight="800" flexEnd noMargin>
                        <BillingStatus blue={isBlue} green={isGreen} red={isRed} gray={!isBlue && !isGreen && !isRed}>
                          {isBilled ? 'Paid' : 'Unpaid'}
                        </BillingStatus>
                      </RecordBody>
                    </UserBookingContainer>
                    <BookingsDivider />
                  </BookingListItem>
                );
              })}
            </div>
          </DrawerContent>
          <div>
            <Divider />
            <DrawerSection paddingT={24} paddingB={refundTitle ? 0 : 20} isFixed flexRow flexEnd>
              <DrawerLabel>Total: {amountTitle}</DrawerLabel>
            </DrawerSection>
            {refundTitle && (
              <DrawerSection paddingT={10} paddingB={20} isFixed flexRow flexEnd>
                <DrawerLabel>Total Refunds: {refundTitle}</DrawerLabel>
              </DrawerSection>
            )}
          </div>
        </>
      )}
    </DrawerContainer>
  );
};

export default OrderInvoiceDrawer;
