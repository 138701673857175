import { useQuery, useReactiveVar } from '@apollo/client';
import React, { FC, Fragment, useEffect } from 'react';
import useMediaQuery from '../../hooks/useMediaQuery';
import useNavigateDrawer from '../../hooks/useNavigateDrawer';
import usePaginatedQuery from '../../hooks/usePaginatedQuery';
import { useRefetchChatsOnFiltersChange } from '../../hooks/useRefetchOnFiltersChange';
import useSetDrawerOnNavigation from '../../hooks/useSetDrawerOnNavigation';
import { ChatSubscription, GetBranchAppUserTags, GetBranchChatRooms, GetBusUserProfile } from '../../queries';
import { vars } from '../../reactive';
import { manageChatsFilters } from '../../reactive/actions';
import { isChatRoomSeen } from '../../views/Chats/utils';
import { BranchAppUserTag } from '../../views/Store/BranchTags/types';
import Common from '../../views/Store/Common';
import { GroupHeader, RecordsContainer } from '../../views/styled';
import { DRAWER_IDS } from '../DrawerBar/types';
import RadioSwitch from '../Shared/Forms/RadioSwitch';
import InfiniteList from '../Shared/InfiniteList/InfiniteList';
import RefreshButton from '../Shared/RefreshButton';
import { CenteredLoader } from '../Shared/Spinner';
import ChatRecord, { Room } from './ChatRecord';
import { CHAT_TABS_TYPES } from './types';

type ChatsBodyProps = {
  selectedTabState: [CHAT_TABS_TYPES, React.Dispatch<React.SetStateAction<CHAT_TABS_TYPES>>];
};

const ChatsBody: FC<ChatsBodyProps> = ({ selectedTabState }) => {
  const { addHeaderAction, clearHeaderAction } = manageChatsFilters;
  const { data: { getBusUserProfile: profile = {} } = {} } = useQuery(GetBusUserProfile);

  const activeChatViewSettings = useReactiveVar(vars.activeChatViewSettings);
  const orderByIsRead = activeChatViewSettings?.orderByIsRead || false;

  const tabsData = {
    [CHAT_TABS_TYPES.ALL]: {
      defaultVariables: {
        chat_seen: undefined,
        requisite_queries: [],
        alternative_queries: []
      }
    },
    [CHAT_TABS_TYPES.UNREAD]: {
      defaultVariables: {
        chat_seen: false,
        requisite_queries: ['chat_seen'],
        alternative_queries: []
      }
    },
    [CHAT_TABS_TYPES.READ]: {
      defaultVariables: {
        chat_seen: true,
        requisite_queries: ['chat_seen'],
        alternative_queries: []
      }
    }
  };

  const [[hasMoreItems, setHasMoreItems], queryResult] = usePaginatedQuery<Room[]>({
    query: GetBranchChatRooms,
    otherVariables: { ...tabsData[selectedTabState[0]].defaultVariables, order_by_is_read: orderByIsRead }
  });

  const { subscribeToMore, data: { getBranchChatRooms: rooms } = { getBranchChatRooms: [] }, loading, fetchMore, refetch, variables: previousVariables, updateQuery } = queryResult;

  useSetDrawerOnNavigation({
    itemList: [],
    drawerId: DRAWER_IDS.CHAT_DRAWER
  });

  useEffect(() => {
    const sub = subscribeToMore({
      document: ChatSubscription,
      variables: { BranchId: [profile?.Branch?.id] },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData) {
          return prev;
        }
        const newMessage = subscriptionData?.data?.messageAdded;
        const currentRoom = prev?.getBranchChatRooms?.find(room => room.id === newMessage?.ChatRoomId);
        if (!currentRoom) {
          refetch();
          return prev;
        }
        const chatDrawer = vars.drawerBars()?.find(drawer => drawer.drawerId === DRAWER_IDS.CHAT_DRAWER);
        const chatRoomDrawerId = chatDrawer?.recordData as string;
        const newRooms = prev?.getBranchChatRooms?.map(room => {
          if (room.id === newMessage?.ChatRoomId) {
            return {
              ...room,
              last_message: newMessage,
              read_status: [
                { user_id: newMessage?.user_id, is_read: true },
                ...(newMessage?.user_id !== profile?.id
                  ? [
                      {
                        user_id: profile?.id,
                        is_read: chatRoomDrawerId === room.id || newMessage?.isSilent
                      }
                    ]
                  : [])
              ]
            };
          }
          return room;
        });

        return {
          getBranchChatRooms: newRooms
        };
      }
    });
    return () => {
      sub();
    };
  }, []);

  const { mobile } = useMediaQuery({ mobile: true });

  const SwitchAction = (
    <RadioSwitch
      defaultValue={orderByIsRead}
      onChange={(on: boolean) => {
        setHasMoreItems(true);
        vars.activeChatViewSettings({
          ...vars.activeChatViewSettings(),
          orderByIsRead: on
        });
      }}
    />
  );

  const action = !mobile ? (
    <Fragment>
      {SwitchAction}
      <RefreshButton
        checkSelectedTabAndRefetch={() => {
          refetch().then(() => setHasMoreItems(true));
        }}
        setHasMoreItems={setHasMoreItems}
      />
    </Fragment>
  ) : (
    <></>
  );

  useEffect(() => {
    addHeaderAction({ action, id: 'refresh' });
    return () => {
      clearHeaderAction({ id: 'refresh' });
    };
  }, []);

  const isRoomSeen = room => isChatRoomSeen(room, profile);

  const sortByMessageTimestamp = rooms => [...(rooms || [])].sort((a, b) => new Date(b.last_message?.timestamp).getTime() - new Date(a.last_message?.timestamp).getTime());

  const seenRooms = sortByMessageTimestamp(rooms?.filter(isRoomSeen)) || [];
  const unseenRooms = sortByMessageTimestamp(rooms?.filter(room => !isRoomSeen(room))) || [];
  const roomsSorted = orderByIsRead ? [...unseenRooms, ...seenRooms] : [...sortByMessageTimestamp(rooms)];

  const navigateDrawer = useNavigateDrawer({
    drawerData: {
      drawerId: DRAWER_IDS.CHAT_DRAWER
    }
  });

  const { data: { getBranchAppUserTags: allTags = [] } = {} } = useQuery<{
    getBranchAppUserTags: BranchAppUserTag[];
  }>(GetBranchAppUserTags, {
    fetchPolicy: 'cache-and-network',
    variables: { offset: 0, limit: 1000 }
  });

  const quickTags = allTags?.filter(tag => tag?.quick_action) || [];
  const tagsVisibleOnItem = allTags?.filter(tag => tag?.visibility?.showOnTaggedItem) || [];

  const loadingFilters = useRefetchChatsOnFiltersChange({
    refetch,
    defaultVariables: { offset: 0, limit: 20, ...tabsData[selectedTabState[0]].defaultVariables },
    setHasMoreItems,
    previousVariables
  });

  const updateChatRooms = (newRooms: Room[]) => {
    updateQuery(prev => {
      const updatedRooms = prev?.getBranchChatRooms?.map(room => {
        const updatedRoom = newRooms.find(newRoom => newRoom.id === room.id);
        if (updatedRoom) {
          return updatedRoom;
        }
        return room;
      });
      return {
        getBranchChatRooms: updatedRooms
      };
    });
  };

  useEffect(() => {
    Common.set(`Chats.GetChatRooms.updateChatRooms`, (args: Room[]) => {
      return updateChatRooms(args);
    });

    return () => {
      Common.clear(`Chats.GetChatRooms.updateChatRooms`);
    };
  }, []);

  return (
    <RecordsContainer style={mobile ? { background: '#f2f2f2' } : { background: '' }} mobilePadding="0 0">
      {loadingFilters && <CenteredLoader />}
      {!loadingFilters && (
        <InfiniteList
          list={roomsSorted}
          itemRenderer={item => (
            <ChatRecord
              room={item}
              key={item.id}
              navigateDrawer={navigateDrawer}
              isRoomSeen={isRoomSeen}
              appUserQuickTags={quickTags}
              profile={profile}
              tagsVisibleOnItem={tagsVisibleOnItem}
              updateChatRooms={updateChatRooms}
            />
          )}
          hasMoreItems={hasMoreItems}
          loading={loading}
          group={
            !mobile && orderByIsRead
              ? {
                  by: room => (isRoomSeen(room) ? 'true' : 'false'),
                  separator(separatedItems) {
                    return <GroupHeader>{isRoomSeen(separatedItems?.[0]) ? 'Read Messages' : 'New Messages'}</GroupHeader>;
                  }
                }
              : undefined
          }
          fetchMore={fetchMore}
          offset={rooms?.length}
          setHasMoreItems={setHasMoreItems}
        />
      )}
    </RecordsContainer>
  );
};

export default ChatsBody;
