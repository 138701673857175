import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import { sentenceCase } from 'sentence-case';
import useIcons from '../../hooks/useIcons';
import { BranchAppUser } from '../../views/Store/BranchBilling/types';
import { ProfileLabel, ProfileLink, ProfileSection, ProfileSubLabel } from '../Profile/styled';

const UserProfileInfo = ({ profile }: { profile: BranchAppUser }) => {
  const icons = useIcons();
  const { postcode, email, phone_numbers } = profile || {};

  const sections = [
    ...((profile?.addresses || [])?.map((address, index, self) => {
      const { line1, line2, city, country } = address || {};
      return {
        subLabel: `Address ${self.length > 1 ? index + 1 : ''}`,
        labels: [line1, line2, city, postcode, country].filter(Boolean),
        unit: ''
      };
    }) || []),
    !profile?.addresses?.length && { subLabel: 'Address', label: '-', unit: '' },
    ...((phone_numbers || [])?.map((phone, _, self) => {
      const { number, label, country_code } = phone || {};
      return {
        subLabel: `Phone ${self.length > 1 ? `(${sentenceCase(label || '')})` : ''}`,
        label: `${country_code} ${number}`,
        unit: ''
      };
    }) || []),
    !phone_numbers?.length && { subLabel: 'Phone', label: '-', unit: '' },
    { subLabel: 'Email', label: email, unit: '', href: `mailto:${email}` }
  ].filter(Boolean) as {
    subLabel: string;
    labels?: string[];
    label?: string;
    unit: string;
    href?: string;
  }[];

  const hasCreditCard = !!profile?.payment?.method;
  const creditCardImage = getImage(icons.creditCard);

  return (
    <div>
      {sections.map(({ subLabel, label, unit, labels, href }, i) => (
        <ProfileSection key={i}>
          <ProfileSubLabel>{subLabel}</ProfileSubLabel>
          {!href && !labels && <ProfileLabel>{label ? label + (unit || '') : '-'}</ProfileLabel>}
          {!href && !label && labels?.map((label, i) => <ProfileLabel key={i}>{label ? label + (unit || '') : '-'}</ProfileLabel>)}

          {href && <ProfileLink href={href}>{label}</ProfileLink>}
        </ProfileSection>
      ))}
      {hasCreditCard && <GatsbyImage image={creditCardImage!} alt={'User has credit card'} />}
    </div>
  );
};

export default UserProfileInfo;
